import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const useGetNewYearCustomMessages = ({
  numSessions,
  isHPIExclusive,
}: {
  numSessions?: string
  isHPIExclusive: boolean
}) => {
  const { formatMessage } = useIntl()

  const coverageHeroTextDefault = formatMessage(
    {
      defaultMessage:
        'With Lyra, you get {sessionCount} free therapy or coaching sessions, plus access to hundreds of on-demand resources.',
      description: 'Text summarizing lyra services',
    },
    { sessionCount: numSessions },
  )
  const coverageHeroTextHpiOnly = formatMessage({
    defaultMessage: 'Meet with top-tier providers, plus get access to hundreds of on-demand resources.',
    description: 'Text summarizing lyra services',
  })
  const coverageSectionDescriptionDefault = formatMessage(
    {
      defaultMessage:
        'You get {sessionCount} free therapy or coaching sessions, plus access to hundreds of on-demand resources.',
      description: 'Text summarizing lyra services',
    },
    { sessionCount: numSessions },
  )
  const coverageSectionHeaderDefault = formatMessage({
    defaultMessage: 'High-quality care at no cost to you',
    description: 'Text summarizing lyra services',
  })
  const coverageSectionHeaderHpiOnly = formatMessage({
    defaultMessage: 'High-quality care',
    description: 'Text summarizing lyra services',
  })
  const messagesHpi = {
    coverageHeroText: coverageHeroTextHpiOnly,
    coverageSectionHeader: coverageSectionHeaderHpiOnly,
    coverageSectionDescription: coverageHeroTextHpiOnly,
  }
  const messagesDefault = {
    coverageHeroText: coverageHeroTextDefault,
    coverageSectionHeader: coverageSectionHeaderDefault,
    coverageSectionDescription: coverageSectionDescriptionDefault,
  }
  const customMessages = isHPIExclusive ? messagesHpi : messagesDefault

  return useMemo(() => {
    return customMessages
  }, [customMessages])
}
