import React, { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputContentSizeChangeEventData,
  TextInputKeyPressEventData,
  TouchableOpacity,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { OSs, PrimaryButton, Tooltip, useOS } from '../..'
import { BaseInputField } from '../../atoms/baseInputField/BaseInputField'
import { SendIcon } from '../../atoms/icons/SendIcon'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'

type ChatInputProps = {
  onSendMessage: (text: string) => void
  onInputChange: (text: string) => void
  onInputHeightChange?: (height: number) => void
  previousInputValue?: string
  actions?: ReactNode[]
  sendOnEnter?: boolean
  attachments?: ReactNode[]
  expandedButton?: boolean
  disableSend?: boolean
}

const SendMessage = styled(Flex1View)({
  justifyContent: Platform.OS === 'web' ? 'flex-end' : 'center',
  paddingBottom: Platform.OS === 'web' ? '16px' : '0px',
  alignItems: 'center',
  flexGrow: 0,
})

const OuterContainer = styled(SafeAreaView)<{ hasActions: boolean }>(({ theme, hasActions }) => ({
  padding: hasActions ? theme.spacing['16px'] : theme.spacing['0px'],
}))

const Container = styled(SafeAreaView)<{ hasActions: boolean; focused: boolean }>(({ theme, hasActions, focused }) => ({
  flexDirection: 'row',
  paddingTop: theme.spacing['8px'],
  paddingLeft: theme.spacing['8px'],
  paddingRight: theme.spacing['8px'],
  paddingBottom: Platform.OS === 'web' ? theme.spacing['8px'] : '0px',
  maxHeight: '212px',
  ...(hasActions
    ? {
        borderColor: focused ? theme.colors.borderFocus : theme.colors.borderDefault,
        borderWidth: '1px',
        borderBottomWidth: 0,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }
    : {}),
  background: theme.breakpoints.isMobileSized ? theme.colors.backgroundPrimary : undefined,
}))

const InputScrollView = styled.ScrollView<{ focused: boolean; hasActions: boolean }>(
  ({ focused, theme, hasActions }) => ({
    borderColor: Platform.OS === 'web' && focused && !hasActions ? theme.colors.borderFocus : 'transparent',
    borderWidth: '1px',
    borderRadius: '4px',
    padding: Platform.OS === 'web' ? theme.spacing['4px'] : 0,
  }),
)

const ActionsContainer = styled.View<{ expandedButton: boolean; focused: boolean }>(
  ({ theme, expandedButton, focused }) => ({
    padding: theme.spacing['8px'],
    paddingRight: expandedButton ? theme.spacing['8px'] : theme.spacing['16px'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: '1px',
    borderTopWidth: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderColor: focused ? theme.colors.borderFocus : theme.colors.borderDefault,
  }),
)

const ActionsInnerContainer = styled.View({
  flexDirection: 'row',
})

export const ChatInput = forwardRef<TextInput, ChatInputProps>(
  (
    {
      onSendMessage,
      onInputChange,
      onInputHeightChange,
      previousInputValue,
      sendOnEnter = false,
      actions = [],
      attachments = [],
      expandedButton = false,
      disableSend = false,
    },
    ref,
  ) => {
    const { colors } = useTheme()
    const { chatInput } = colors.components
    const { formatMessage } = useIntl()
    const [keyMap, setKeyMap] = useState<{ [key: string]: number }>({})
    const inputMinHeight = Platform.OS === 'web' ? 45 : 60
    const [inputHeight, setInputHeight] = useState(inputMinHeight)
    const [inputValue, setInputValue] = useState(previousInputValue)
    const isValidInput = !isEmpty(inputValue?.trim())
    const [focused, setFocused] = useState(false)

    useEffect(() => {
      setInputValue(previousInputValue)
    }, [previousInputValue])
    const inputChanged = (text: string) => {
      setInputValue(text)
      onInputChange(text)
    }

    const send = () => {
      // on android the height is not reset, so make sure it is reset here after sending
      setInputHeight(inputMinHeight)
      setInputValue('')
      setKeyMap({})
      // Call send message asynchronously so the text field will reset immediately
      setTimeout(() => {
        inputValue && onSendMessage(inputValue)
      })
    }

    // When a user inputs text for multiline, the input should increase height for each line
    const onContentSizeChange = (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
      const newHeight = event.nativeEvent.contentSize.height
      if (newHeight !== inputHeight) {
        setInputHeight(newHeight)
        onInputHeightChange && onInputHeightChange(Math.max(inputMinHeight, newHeight))
      }
    }

    // taken from ui-core
    const KEYS = {
      ENTER: 'Enter',
      CONTROL: 'Control',
      META: 'Meta', // CMD key for mac,
    }
    /**
     * This logic is borrowed from ui-core.
     * Set up a key map and check if user has pressed ctrl or command (meta) + Enter to send
     */
    const handleKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.defaultPrevented || Platform.OS !== 'web') {
        return
      }
      const key = e.nativeEvent.key
      const timeStamp = e.timeStamp
      if (key) {
        // cache the key to check for a simutanous key on next event
        if ([KEYS.META, KEYS.CONTROL].indexOf(key) > -1) {
          setKeyMap({ ...keyMap, [key]: timeStamp })
        } else if ([KEYS.ENTER].indexOf(key) > -1) {
          const sendWithCommandKey = timeStamp - keyMap[KEYS.META]
          const sendWithControlKey = timeStamp - keyMap[KEYS.CONTROL]

          // working with on keypress instead of (keyUp and keyDown), so we detect time between key presses
          const shouldSend = sendOnEnter || sendWithCommandKey < 200 || sendWithControlKey < 200

          if (shouldSend && isValidInput) {
            e.preventDefault()
            send()
          } else {
            setKeyMap({})
          }
        } else {
          setKeyMap({})
        }
      }
    }

    const os = useOS()
    const tooltipContent = useMemo(() => {
      if (sendOnEnter) {
        return formatMessage({ defaultMessage: 'Send (↵ Enter)', description: 'Send message with enter key' })
      }

      if (os === OSs.MAC) {
        return formatMessage({
          defaultMessage: 'Send (⌘ + Enter)',
          description: 'Send message with enter and meta key on Mac',
        })
      }
      return formatMessage({
        defaultMessage: 'Send (Ctrl + Enter)',
        description: 'Send message with enter and meta key on windows',
      })
    }, [formatMessage, os, sendOnEnter])

    const innerButton = expandedButton ? (
      <PrimaryButton
        testID={tID('Chat-sendButton')}
        disabled={!isValidInput || disableSend}
        text={<FormattedMessage defaultMessage='Send' description='Send message button label' />}
        onPress={send}
        leftIcon={<SendIcon fillColor={colors.primaryButtonTextActive} isFilled />}
      />
    ) : (
      <TouchableOpacity onPress={send} disabled={!isValidInput || disableSend} testID={tID('Chat-sendButton')}>
        <SendIcon
          fillColor={isValidInput ? colors.iconActive : colors.iconInactive}
          isFilled
          testID={tID('Chat-sendIcon')}
        />
      </TouchableOpacity>
    )
    const sendMessageButton = (
      <Tooltip
        hoverEnabled
        content={tooltipContent}
        wrapperStyle={{ flexGrow: 0, alignItems: 'center' }}
        accessibilityLabel={formatMessage({ defaultMessage: 'Send Message', description: 'Send message button label' })}
        onPress={isValidInput ? send : undefined}
      >
        {innerButton}
      </Tooltip>
    )
    const hasActions = actions.length > 0
    return (
      <OuterContainer edges={['left', 'right']} hasActions={hasActions}>
        <Container edges={['left', 'right']} testID={tID('Chat-inputField')} hasActions={hasActions} focused={focused}>
          <InputScrollView focused={focused} hasActions={hasActions}>
            <BaseInputField
              ref={ref}
              borderColor={chatInput.border}
              onContentSizeChange={onContentSizeChange}
              onInputChange={inputChanged}
              inputValue={inputValue}
              placeholderText='Write a message...'
              height={Math.max(inputMinHeight, inputHeight)}
              width={hasActions ? '100%' : '85%'}
              handleKeyPress={Platform.OS === 'web' ? handleKeyPress : undefined}
              outlineColor={chatInput.border}
              onBlur={() => setFocused(false)}
              onFocus={() => setFocused(true)}
            />
            {attachments}
          </InputScrollView>
          {!hasActions && <SendMessage>{sendMessageButton}</SendMessage>}
        </Container>
        {hasActions && (
          <ActionsContainer expandedButton={expandedButton} focused={focused}>
            <ActionsInnerContainer>{actions}</ActionsInnerContainer>
            {sendMessageButton}
          </ActionsContainer>
        )}
      </OuterContainer>
    )
  },
)
