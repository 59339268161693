import React, { FunctionComponent } from 'react'

import { MentalHealthExpertiseIllustrationProps } from './MentalHealthExpertiseIllustrationSvg'
import { withSuspense } from '../../utils/withSuspense'

const MentalHealthExpertiseIllustrationSvg = withSuspense({
  component: React.lazy(() => import('./MentalHealthExpertiseIllustrationSvg')),
})

export const MentalHealthExpertiseIllustration: FunctionComponent<MentalHealthExpertiseIllustrationProps> = ({
  width,
  height,
}) => {
  return <MentalHealthExpertiseIllustrationSvg width={width} height={height} />
}
