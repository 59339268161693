import { uniq } from 'lodash-es'
import SumoLogger from 'sumo-logger'

import { sanitizeData } from './userUtils'
import {
  CARE_ADVISOR_EMAIL,
  DOMESTIC_PROVIDER_CONTACT_EMAIL,
  GOODNESS_PROD_SPANISH_URL,
  GOODNESS_PROD_URL,
  GOODNESS_STAGING_SPANISH_URL,
  GOODNESS_STAGING_URL,
  ICAS_CONTACT_EMAIL,
  INTERNATIONAL_PROVIDER_CONTACT_EMAIL,
  IS_PRODUCTION_WEB_OR_MOBILE,
} from '../constants/constants'

// checks if the document object exists and returns it, else undefined
export const getDocumentObject = () => {
  return typeof document !== 'undefined' ? document : undefined
}

export type SumoLogLevel = 'INFO' | 'WARN' | 'ERROR'

/**
 * Common function to handle logging to sumologic
 * expects user to pass instance of sumo logic
 */
export const logToSumoLogic = (
  sumoLogger: SumoLogger,
  options: SumoLogger.SumoLoggerOptions,
  sourceCategory: string,
  sourceName: string,
  userId: string,
  data: Dict,
) => {
  let logData = {}
  if (data instanceof FormData) {
    for (const entry in data) {
      logData[entry[0]] = entry[1]
    }
  } else {
    logData = data
  }
  logData = sanitizeData(logData)
  // Ignoring the updateConfig since the @types for sumo-logic don't include it :(
  ;(sumoLogger as $TSFixMe).updateConfig({ sourceCategory, sourceName })
  sumoLogger.log({ sessionKey: userId, ...options, ...logData })
}

// Finds and sorts matches in array. Used by typeAheads
export const matchFinder = (options: string[], text: string, locale: string) => {
  const collator = new Intl.Collator()
  const searchQueryFormatted = text.toLocaleLowerCase(locale)
  const optionsFormatted = options.map((option) => option.toLocaleLowerCase(locale))
  const startsWith = options.filter((option, index) => optionsFormatted[index].startsWith(searchQueryFormatted))
  const includes = options.filter((option, index) => optionsFormatted[index].includes(searchQueryFormatted))
  const results = uniq([...startsWith, ...includes])
  return results.sort((a, b) => collator.compare(a, b))
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const convertObjToFormData = (input: {}) => {
  return Object.keys(input).reduce((formData, key) => {
    formData.append(key, input[key])
    return formData
  }, new FormData())
}

export const getHelpEmailAddress = ({ isInternationalUser }: { isInternationalUser: boolean }) => {
  return isInternationalUser ? ICAS_CONTACT_EMAIL : CARE_ADVISOR_EMAIL
}

export const getProviderContactEmailAddress = ({ isInternationalUser }: { isInternationalUser: boolean }) => {
  return isInternationalUser ? INTERNATIONAL_PROVIDER_CONTACT_EMAIL : DOMESTIC_PROVIDER_CONTACT_EMAIL
}

/**
 * Determines whether the Essentials/Library navbar button should link to the Essentials or Work Hub external app
 */
export const shouldLinkToWorkHub = (essentialsDisabled: boolean, workHubDisabled: boolean) => {
  return essentialsDisabled && !workHubDisabled
}

export const isWorkHubSubPath = (subPath: string) => {
  return subPath.length > 0 && subPath.startsWith('/work-hub')
}

/**
 * Can be used when you need a timeout that you need to await for - ex. Waiting 3 seconds before auto-navigating
 */
export const promiseTimeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const shuffleArray = <T>(array: T[]): T[] => {
  const arrayCopy = [...array]
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = arrayCopy[i]
    arrayCopy[i] = arrayCopy[j]
    arrayCopy[j] = temp
  }
  return arrayCopy
}

export const getRandomItems = <T>(array: T[], n: number): T[] => {
  const arrayCopy = shuffleArray(array)
  return arrayCopy.slice(0, n)
}

export const getGoodnessBaseUrl = (displayLanguage?: string) => {
  const isSpanish = displayLanguage?.startsWith('es')
  let urls = {
    prod: GOODNESS_PROD_URL,
    staging: GOODNESS_STAGING_URL,
  }
  if (isSpanish) {
    urls = {
      prod: GOODNESS_PROD_SPANISH_URL,
      staging: GOODNESS_STAGING_SPANISH_URL,
    }
  }
  return IS_PRODUCTION_WEB_OR_MOBILE ? urls.prod : urls.staging
}

export const mapObjectValues = <T, U>(object: { [key: string]: T }, callback: (value: T, key: string) => U) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = callback(object[key], key)
    return acc
  }, {} as { [key: string]: U })
}

/**
 * Temporary util to convert strings to numbers for HPI cost information. Will be removed when the backend sends only numbers.
 */
export const convertValueToNumber = ({
  val,
  defaultReturnValue = -1,
}: {
  val: number | string | null | undefined
  defaultReturnValue?: any
}) => {
  if (val == null || (typeof val === 'string' && val?.length === 0)) {
    return defaultReturnValue
  }
  const numberValue = Number(val)
  if (isNaN(numberValue)) {
    return defaultReturnValue
  }
  return numberValue
}

// Get the current year primarily for program coverage breakdowns by year
export const getCurrentYear = () => new Date().getFullYear().toString()
