/********************************************************************************
 A wrapper for a standard bootstrap container
 ********************************************************************************/

import React from 'react'

import classnames from 'classnames'

interface BootstrapContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  col?: string
  className?: string
}

const BootstrapContainer = ({ children, col = 'col-md-12', className = '', ...props }: BootstrapContainerProps) => (
  <div className={classnames('container', className)} {...props}>
    <div className='row'>
      <div className={col}>{children}</div>
    </div>
  </div>
)

export default BootstrapContainer
