import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const greylock: GetCustomerCopyFunc = () => ({
  generateEligibilityQuestionTitle: () =>
    defineMessage({
      defaultMessage: 'Are you a Greylock employee, or an active CEO or Founder associated with Greylock?',
      description: 'Asking if the user has certain role in company Greylock',
    }),
})
