import { IntlShape } from 'react-intl'

import { find, get, isEmpty } from 'lodash-es'

import { notEnrolledHealthPlanOption } from './messages'
import { CUSTOMER_CONFIGURATION, MinorSessionUsage, ProgramCategory, SessionLimitByProgram } from '../../models'
import { EAPAndHPIProgramSupport, HealthPlansList, YearProgramCoverage } from '../../models/user/User'
import { ProgramCustomerPropertyId } from '../common/constants/customerProgram'
import {
  GOVERNMENT_HEALTH_PLANS,
  HEALTH_PLAN_TYPE,
  HealthPlanFormValues,
} from '../common/constants/healthPlanAndPaymentInformationConstants'
import { TREATMENT_OPTIONS, TREATMENT_OPTIONS_TYPE } from '../common/constants/treatmentOptions'
import { createRemainingSessionCountByProgram, isProgramAvailableForMinor } from '../common/utils/sessionLimit'
import { convertTriageTreatmentOptionToProgram } from '../searchForCare/utils'

// Checks if a program has EAP support
export const isCustomerProgramEAPSupported = (programConfiguration: EAPAndHPIProgramSupport): boolean => {
  return programConfiguration?.eapSupported
}

// Checks if a program has HPI support
export const isCustomerProgramHPISupported = (programConfiguration?: EAPAndHPIProgramSupport): boolean => {
  return programConfiguration ? programConfiguration.bhbSupported || programConfiguration.specialtyBhbSupported : false
}

// Checks if all the customer's programs are all HPI exclusive
export const areAllCustomerProgramsHPIExclusive = (customerPrograms?: YearProgramCoverage): boolean => {
  if (!customerPrograms) {
    return false
  } else {
    return Object.keys(customerPrograms)?.every(
      (configuration) => !isCustomerProgramEAPSupported(customerPrograms?.[configuration]),
    )
  }
}

export const areAllCustomerProgramsEAPExclusive = (customerPrograms?: YearProgramCoverage): boolean => {
  if (!customerPrograms) {
    return false
  } else {
    return Object.keys(customerPrograms)?.every(
      (configuration) => !isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}

export const isAnyCustomerProgramHPIAndEAPSupported = (customerPrograms?: YearProgramCoverage): boolean => {
  if (!customerPrograms) {
    return false
  } else {
    return !!Object.keys(customerPrograms)?.find(
      (configuration) =>
        isCustomerProgramEAPSupported(customerPrograms?.[configuration]) &&
        isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}

export const isCustomerEAPAndHPISupported = (customerPrograms?: YearProgramCoverage): boolean => {
  if (!customerPrograms) {
    return false
  } else {
    return (
      !areAllCustomerProgramsEAPExclusive(customerPrograms) && !areAllCustomerProgramsHPIExclusive(customerPrograms)
    )
  }
}

const filterCustomerProgram = (propertyIds: string[], customerPrograms: YearProgramCoverage) => {
  return Object.keys(customerPrograms)
    .filter((programPropertyId) => {
      return propertyIds.includes(programPropertyId)
    })
    .reduce((obj, key) => {
      obj[key] = customerPrograms[key]
      return obj
    }, {})
}

export const getIsAnyCustomerProgramHPIMeds = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    const filteredMedPrograms: YearProgramCoverage = filterCustomerProgram(
      [ProgramCustomerPropertyId.blendedCareMeds, ProgramCustomerPropertyId.considerMeds],
      customerPrograms,
    )

    return Object.keys(filteredMedPrograms)?.some((configuration) =>
      isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}

export const getIsAnyCustomerProgramEAPMeds = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    const filteredMedPrograms: YearProgramCoverage = filterCustomerProgram(
      [ProgramCustomerPropertyId.blendedCareMeds, ProgramCustomerPropertyId.considerMeds],
      customerPrograms,
    )

    return Object.keys(filteredMedPrograms)?.some((configuration) =>
      isCustomerProgramEAPSupported(customerPrograms?.[configuration]),
    )
  }
}

export const getIsAnyCustomerProgramEAPCoaching = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    const filteredCoachingPrograms: YearProgramCoverage = filterCustomerProgram(
      [ProgramCustomerPropertyId.stressManagement],
      customerPrograms,
    )

    return Object.keys(filteredCoachingPrograms)?.some(
      (configuration) =>
        isCustomerProgramEAPSupported(customerPrograms?.[configuration]) &&
        !isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}

export const getIsAnyCustomerProgramHPITherapy = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    const filteredTherapyPrograms: YearProgramCoverage = filterCustomerProgram(
      [ProgramCustomerPropertyId.considerLyraTherapy, ProgramCustomerPropertyId.directAccessTherapy],
      customerPrograms,
    )

    return Object.keys(filteredTherapyPrograms)?.some(
      (configuration) =>
        !isCustomerProgramEAPSupported(customerPrograms?.[configuration]) &&
        isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}
export const getIsAnyCustomerProgramEAPTherapy = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    const filteredTherapyPrograms: YearProgramCoverage = filterCustomerProgram(
      [ProgramCustomerPropertyId.considerLyraTherapy, ProgramCustomerPropertyId.directAccessTherapy],
      customerPrograms,
    )

    return Object.keys(filteredTherapyPrograms)?.some(
      (configuration) =>
        isCustomerProgramEAPSupported(customerPrograms?.[configuration]) &&
        !isCustomerProgramHPISupported(customerPrograms?.[configuration]),
    )
  }
}

export const areAllCustomerProgramsBHBExclusive = (customerPrograms: YearProgramCoverage) => {
  // Check if all programs are not EAP supported, which means at least some programs are BHB supported
  // Therefore, this customer is using a HPI-only configuration
  return Object.values(customerPrograms).every((configuration) => !isCustomerProgramEAPSupported(configuration))
}

export const getProviderBasedTreatmentOptionsUserIsEligibleFor = (
  treatmentOptionsFromTriage: TREATMENT_OPTIONS_TYPE[],
  customerPrograms: YearProgramCoverage,
) => {
  const eligibleOptions: string[] = []
  treatmentOptionsFromTriage.forEach((treatmentOption) => {
    if (
      !isTreatmentOptionHPIExclusive({ treatmentOptionName: treatmentOption, customerPrograms }) &&
      !!convertTriageTreatmentOptionToProgram({ treatmentOptionName: treatmentOption }) &&
      treatmentOption !== TREATMENT_OPTIONS.SELF_CARE_APPS
    ) {
      eligibleOptions.push(treatmentOption)
    }
  })

  return eligibleOptions
}

/**
 * Checks if any customer program is HPI only
 */
export const isAnyCustomerProgramHPIExclusive = (customerPrograms?: YearProgramCoverage) => {
  if (!customerPrograms) {
    return false
  } else {
    return Object.keys(customerPrograms)?.some((configuration) =>
      isCustomerProgramHPIExclusive(customerPrograms?.[configuration]),
    )
  }
}

// Checks if the customer program is HPI exclusive
export const isCustomerProgramHPIExclusive = (programConfiguration?: EAPAndHPIProgramSupport) => {
  return !!(isCustomerProgramHPISupported(programConfiguration) && !programConfiguration?.eapSupported)
}

// Returns a matching program based on the input treatment option.
// This can be used to check if a treatment option is supported at all by a customer
export const getProgramFromTreatmentOption = ({
  treatmentOptionName,
  customerPrograms,
  allowPartialTreatmentOptions,
}: {
  treatmentOptionName: TREATMENT_OPTIONS_TYPE | null
  customerPrograms: YearProgramCoverage
  allowPartialTreatmentOptions?: boolean
}) => {
  const frontendMedsTreatmentOptions: TREATMENT_OPTIONS_TYPE[] = [
    TREATMENT_OPTIONS.CONTROLLED_MEDICATION,
    TREATMENT_OPTIONS.MEDICATION_CONSULTATION,
  ]
  if (treatmentOptionName && frontendMedsTreatmentOptions.includes(treatmentOptionName)) {
    treatmentOptionName = TREATMENT_OPTIONS.BLENDED_CARE_MEDS
  }
  return find(
    customerPrograms,
    (_, programName) =>
      programName ===
      get(
        convertTriageTreatmentOptionToProgram({ treatmentOptionName, allowPartialTreatmentOptions }),
        'customerPropertyId',
      ),
  )
}

// Checks if the treatment option is HPI exclusive
export const isTreatmentOptionHPIExclusive = ({
  treatmentOptionName,
  customerPrograms,
  allowPartialTreatmentOptions,
}: {
  treatmentOptionName: TREATMENT_OPTIONS_TYPE | null
  customerPrograms: YearProgramCoverage
  allowPartialTreatmentOptions?: boolean
}) => {
  return isCustomerProgramHPIExclusive(
    getProgramFromTreatmentOption({ treatmentOptionName, allowPartialTreatmentOptions, customerPrograms }),
  )
}

export const getCustomerConfiguration = (
  customerPrograms: YearProgramCoverage,
  healthPlanExtensionEnabled: boolean,
) => {
  if (areAllCustomerProgramsHPIExclusive(customerPrograms)) {
    return CUSTOMER_CONFIGURATION.HPI_ONLY
  } else if (isAnyCustomerProgramHPIExclusive(customerPrograms) && healthPlanExtensionEnabled) {
    return CUSTOMER_CONFIGURATION.EAP_PLUS_HPI
  } else {
    return CUSTOMER_CONFIGURATION.EAP_ONLY
  }
}

export const isGivenCustomerProgramSupportedForUser = ({
  customerPrograms,
  isBHBEligible,
  customerProgramName,
}: {
  customerPrograms: YearProgramCoverage
  isBHBEligible: boolean | null | undefined
  customerProgramName: string
}) => {
  const programSupport = customerPrograms[customerProgramName]
  return isBHBEligible ? isCustomerProgramHPISupported(programSupport) : isCustomerProgramEAPSupported(programSupport)
}

export const showProfileHealthPlanAndPaymentSection = (
  isHealthPlanExtensionEnabled: boolean,
  costSharingCardLast4: string | undefined | null,
  isInternational: boolean,
  hasBcxProvider: boolean,
) => {
  return !isInternational && (isHealthPlanExtensionEnabled || !isEmpty(costSharingCardLast4) || hasBcxProvider)
}

export const shouldNavigateToHPIPage = ({
  treatmentOption,
  customerPrograms,
  isHealthPlanConfirmed,
  isEAPSessionCountExhausted,
  forChild,
  allowPartialTreatmentOptions,
}: {
  treatmentOption?: TREATMENT_OPTIONS_TYPE
  customerPrograms: YearProgramCoverage
  isHealthPlanConfirmed: boolean
  isEAPSessionCountExhausted: ConstrainBoolean
  forChild?: boolean
  allowPartialTreatmentOptions?: boolean
}) => {
  if (forChild) {
    return false
  }

  let hpiOnly = false
  if (treatmentOption) {
    hpiOnly = isTreatmentOptionHPIExclusive({
      treatmentOptionName: treatmentOption,
      customerPrograms: customerPrograms,
      allowPartialTreatmentOptions,
    })
  } else {
    hpiOnly = areAllCustomerProgramsHPIExclusive(customerPrograms)
  }

  return (
    !isHealthPlanConfirmed &&
    (hpiOnly || (isEAPSessionCountExhausted && isAnyCustomerProgramHPIAndEAPSupported(customerPrograms)))
  )
}

export const getHealthPlanType = (
  healthPlanName: string | undefined = '',
  integratedHealthPlans: HealthPlansList[],
  nonIntegratedHealthPlans?: HealthPlansList[],
): HEALTH_PLAN_TYPE => {
  if (GOVERNMENT_HEALTH_PLANS.some((plan: HealthPlansList) => plan.eligibility_name === healthPlanName)) {
    return HEALTH_PLAN_TYPE.GOVERNMENT
  } else if (integratedHealthPlans?.some((plan) => plan.eligibility_name === healthPlanName)) {
    return HEALTH_PLAN_TYPE.INTEGRATED
  } else if (nonIntegratedHealthPlans?.some((plan) => plan.eligibility_name === healthPlanName)) {
    return HEALTH_PLAN_TYPE.NON_INTEGRATED
  } else {
    return HEALTH_PLAN_TYPE.OTHER
  }
}

export const getIsSelectedHealthPlanIntegrated = (
  isSecondaryHealthPlanCollectionEnabled = false,
  healthPlan: HealthPlansList | undefined,
  integratedHealthPlans: HealthPlansList[],
): boolean =>
  isSecondaryHealthPlanCollectionEnabled &&
  healthPlan != null &&
  integratedHealthPlans.some((plan) => plan.eligibility_name === healthPlan?.eligibility_name)

export const getIsMinorEAPSessionExhausted = (
  minorsSessionUsages: MinorSessionUsage[],
  childLyraId: string,
  numberOfSessions?: string,
  numberOfSessionsByProgram?: SessionLimitByProgram,
  isProgramLevelSessionLimitEnabled?: boolean,
): boolean => {
  let isEAPExhausted = false
  const minorSessionUsage = minorsSessionUsages?.find((usage) => usage.lyraId === childLyraId)
  if (
    isProgramLevelSessionLimitEnabled &&
    minorSessionUsage &&
    minorSessionUsage.usedSessionCountByProgram &&
    numberOfSessionsByProgram
  ) {
    const remainingSessionCountByProgram = createRemainingSessionCountByProgram(
      numberOfSessionsByProgram,
      minorSessionUsage.usedSessionCountByProgram,
    )
    isEAPExhausted = Object.entries(remainingSessionCountByProgram).every(([program, count]) => {
      const programCategory = program as ProgramCategory
      if (!isProgramAvailableForMinor(programCategory)) return true
      return count <= 0
    })
  } else {
    const usedSessionCount = minorSessionUsage?.usedSessionCount || 0
    isEAPExhausted = numberOfSessions ? parseInt(numberOfSessions, 10) - usedSessionCount <= 0 : false
  }
  return isEAPExhausted
}

export const getInitialHealthPlanInfo = ({
  healthPlan,
  secondaryHealthPlan,
  hasExistingHealthPlan,
  isSecondaryHealthPlanCollectionEnabled = false,
  isSecondarySelection = false,
  allHealthPlanOptions,
}: {
  healthPlan?: HealthPlanFormValues
  secondaryHealthPlan?: HealthPlanFormValues
  hasExistingHealthPlan: boolean
  isSecondaryHealthPlanCollectionEnabled?: boolean
  isSecondarySelection?: boolean
  allHealthPlanOptions?: HealthPlansList[]
}): HealthPlanFormValues | undefined => {
  if (
    allHealthPlanOptions &&
    !allHealthPlanOptions.find(
      (plan) =>
        plan.eligibility_name === healthPlan?.healthPlanName ||
        (isSecondarySelection && plan.eligibility_name === secondaryHealthPlan?.healthPlanName),
    )
  ) {
    return undefined
  }

  if (isSecondaryHealthPlanCollectionEnabled && hasExistingHealthPlan) {
    if (isSecondarySelection) {
      return secondaryHealthPlan?.healthPlanName != null ? secondaryHealthPlan : undefined
    } else {
      return healthPlan?.healthPlanName != null ? healthPlan : undefined
    }
  } else {
    return hasExistingHealthPlan ? healthPlan : undefined
  }
}

export const addNoneOptionToHealthPlanList = ({
  healthPlansList,
  matchFor,
  customerInfo,
  formatMessage,
}: {
  healthPlansList: HealthPlansList[]
  matchFor: string
  customerInfo?: string
  formatMessage: IntlShape['formatMessage']
}): HealthPlansList[] => {
  if (!healthPlansList.find((healthPlan) => healthPlan.eligibility_name === 'none')) {
    healthPlansList.push({
      eligibility_name: 'none',
      name: formatMessage(notEnrolledHealthPlanOption, {
        searchFor: matchFor,
        hasCustomerName: !!customerInfo,
        customerName: customerInfo,
      }),
      enabled: false,
    } as HealthPlansList)
  }
  return healthPlansList
}
