import React from 'react'
import { defineMessage } from 'react-intl'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { Link } from '../atoms/link/Link'

const benefitsPortal = 'https://mybenefits.morganstanley.com'

/* eslint-disable formatjs/no-literal-string-in-jsx */
const benefitsLink = (
  <Link
    text='benefits portal'
    onPress={() => {
      Linking.openURL(benefitsPortal)
    }}
  />
)
/* eslint-enable formatjs/no-literal-string-in-jsx */

const servicesNotCovered = `Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care. Please check with your medical plan for coverage of these excluded services at the Morgan Stanley ${benefitsLink}.`

const customerName = 'Morgan Stanley'

export const morganstanley: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    customerName,
    displaySessionLimit: true,
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
    servicesNotCovered,
  }
}
