import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { WellnessCheckInDomain, WellnessCheckInResultsDomainProps } from '@lyrahealth-inc/shared-app-logic'
import {
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  WellnessCheckInResultsDomainCard,
  WellnessCheckInResultsDomainCardScore,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  getDomainDisplayName,
  getWellnessCheckInEssentialsContents,
  translateWellnessCheckInNumberScoreToDomainScore,
} from '../../utils/wellnessCheckInResults/utils'
import { WellnessCheckInResultsDomainContent } from '../wellnessCheckInResultsDomainContent/WellnessCheckInResultsDomainContent'

const DomainContainer = styled.View({
  margin: `80px auto 80px auto`,
  flexDirection: 'row',
  columnGap: '96px',
})

const DomainCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const EssentialsContainer = styled.View(() => ({
  flex: 1,
  maxWidth: '464px',
}))

const EssentialsHeaderTitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const EssentialsTitleContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const DomainContentContainer = styled.View<{ theme: ThemeType; isLastContent: boolean }>(
  ({ theme, isLastContent }) => ({
    marginBottom: isLastContent ? theme.spacing['0px'] : '36px',
  }),
)

export const WellnessCheckInResultsFullViewDomain: FunctionComponent<WellnessCheckInResultsDomainProps> = ({
  wellbeingResponse,
  onArticlePress,
  isPreview,
  onDomainPress,
  onLoad,
}) => {
  if (Object.keys(wellbeingResponse).length === 0) {
    throw new Error('Empty wellbeing response')
  }
  const [activeDomain, setActiveDomain] = useState(Object.keys(wellbeingResponse)[0])
  const essentialsContents = getWellnessCheckInEssentialsContents(
    wellbeingResponse[activeDomain as WellnessCheckInDomain].response,
  )

  useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <DomainContainer testID={tID(`WellnessCheckInResultsFullViewDomain`)}>
      <View>
        {Object.keys(wellbeingResponse).map((domain, index) => {
          return (
            <DomainCardContainer key={index}>
              <WellnessCheckInResultsDomainCard
                title={getDomainDisplayName(domain as WellnessCheckInDomain)}
                score={translateWellnessCheckInNumberScoreToDomainScore(
                  wellbeingResponse[domain as WellnessCheckInDomain].score,
                )}
                onPress={() => {
                  onDomainPress && onDomainPress(domain as WellnessCheckInDomain)
                  setActiveDomain(domain)
                }}
                isActive={activeDomain === domain}
                domain={domain}
                isPreview={isPreview && index > 0}
              />
            </DomainCardContainer>
          )
        })}
      </View>
      <EssentialsContainer>
        <EssentialsHeaderTitleContainer>
          <EssentialsTitleContainer
            text={<FormattedMessage {...getDomainDisplayName(activeDomain as WellnessCheckInDomain)} />}
            size={SubheadSize.MEDIUM}
          />
          <WellnessCheckInResultsDomainCardScore
            score={translateWellnessCheckInNumberScoreToDomainScore(
              wellbeingResponse[activeDomain as WellnessCheckInDomain].score,
            )}
          />
        </EssentialsHeaderTitleContainer>
        {essentialsContents.length > 0 && (
          <>
            {essentialsContents.map((content, index) => {
              return (
                <DomainContentContainer key={index} isLastContent={index === essentialsContents.length - 1}>
                  <WellnessCheckInResultsDomainContent
                    title={content.title}
                    body={content.body}
                    articleContents={content.articleContents}
                    key={activeDomain}
                    onArticlePress={onArticlePress}
                    isLastContent={index === essentialsContents.length - 1}
                  />
                </DomainContentContainer>
              )
            })}
          </>
        )}
      </EssentialsContainer>
    </DomainContainer>
  )
}
