import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Animated, { useAnimatedStyle, withSpring } from 'react-native-reanimated'

import styled, { useTheme } from 'styled-components/native'

import { ArrowIcon, ArrowIconDirection, BodyText, PressableOpacity } from '../../../atoms'
import { BodyTextSize } from '../../../styles'
import { ThemeType, tID } from '../../../utils'

export interface MicrositeExploreOnYourOwnProps {
  onExploreOnYourOwnCTAPress?: () => void
  testId?: string
}

const ExploreOnYourOwnTextComponent = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
  textAlign: 'center',
}))

const AnimatedArrowIconContainer = styled(Animated.View)({
  alignItems: 'center',
})

export const MicrositeExploreOnYourOwnCTA: FunctionComponent<MicrositeExploreOnYourOwnProps> = ({
  onExploreOnYourOwnCTAPress,
  testId,
}) => {
  const { colors } = useTheme()
  const [isExploreOnYourOwnCTAHovered, setIsExploreOnYourOwnCTAHovered] = useState(false)

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: isExploreOnYourOwnCTAHovered ? withSpring(12) : withSpring(0) }],
    }
  })

  const exploreOnYourOwnText = (
    <FormattedMessage defaultMessage='Explore on your own' description='call to action to see rest of microsite' />
  )

  const animatedArrowIcon = (
    <AnimatedArrowIconContainer style={animatedStyle}>
      <ArrowIcon size={24} fillColor={colors.primaryButtonBackgroundHover} direction={ArrowIconDirection.DOWN} />
    </AnimatedArrowIconContainer>
  )

  return (
    <PressableOpacity
      hitSlop={12}
      onPress={() => {
        onExploreOnYourOwnCTAPress && onExploreOnYourOwnCTAPress()
      }}
      onHoverIn={() => {
        setIsExploreOnYourOwnCTAHovered(true)
      }}
      onHoverOut={() => {
        setIsExploreOnYourOwnCTAHovered(false)
      }}
      testID={tID(testId)}
    >
      <ExploreOnYourOwnTextComponent
        text={exploreOnYourOwnText}
        size={BodyTextSize.LARGE}
        color={colors.primaryButtonBackgroundHover}
      />
      {animatedArrowIcon}
    </PressableOpacity>
  )
}
