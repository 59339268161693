import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  ALREADY_HAVE_AN_ACCOUNT_MSG,
  genericErrorMessage,
  LYRA_CONFIDENTIALITY_MSG,
  validateEmailForm,
} from '@lyrahealth-inc/shared-app-logic'
import { IS_WEB } from '@lyrahealth-inc/ui-core-crossplatform'

import { FormContainer, RegistrationEmailCaptureAnimation, SsoButton, TOOLTIP_TRIGGER_ICON } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { InputFieldRFF } from '../../atoms/formElements/InputField'
import { Link } from '../../atoms/link/Link'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { SSO_BUTTON_MESSAGE_TYPE, SSO_BUTTON_TYPE } from '../../atoms/ssoButton/SsoButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { useMediaQuerySize } from '../../hooks'
import { ConfidentialityNotice, InlineErrorBanner, InputAccessory } from '../../molecules'
import { NavBar } from '../../molecules/navBar/NavBar'
import { SubheadSize } from '../../styles/typeStyles'
import { StickyView, TwoColumnRegistrationLayout } from '../../templates'
import { openUrl, ThemeType, tID } from '../../utils'
import { LegalAndCopyrightFooter } from '../legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { RegistrationValueProps } from '../registrationValueProps/RegistrationValueProps'

export interface EmailCaptureFormValues {
  emailAddress: string
}

const EmailFormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMinWidthTablet
    ? 0
    : `${theme.spacing['0px']} ${theme.spacing['16px']} ${theme.spacing['16px']}`,
  ...(!IS_WEB && {
    width: '100%',
  }),
}))

const HeaderContainer = styled(Subhead)<{ theme: ThemeType; isMobileSized: boolean }>(({ theme, isMobileSized }) => ({
  marginBottom: theme.spacing['24px'],
  ...(!isMobileSized && { marginRight: theme.spacing['72px'] }),
  ...(isMobileSized && { textAlign: 'center' }),
}))

const DividerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginVertical: theme.spacing['24px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const DividerText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.spacing['24px'],
  color: theme.colors.textSecondary,
}))

const SplitDivider = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  height: 1,
  backgroundColor: theme.colors.dividerSecondary,
}))

const FooterTextContainer = styled.View({
  width: '100%',
  alignItems: 'center',
})

const ButtonGroupContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing['8px'],
}))

const ButtonContainer = styled.View<{ theme: ThemeType; addMarginBottom?: boolean }>(({ theme, addMarginBottom }) => ({
  width: '100%',
  ...(addMarginBottom && {
    marginBottom: theme.spacing['16px'],
  }),
}))

const AlreadyHaveAccountText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
}))

const LegalFooterContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['24px'],
}))

const ConfidentialityContainer = styled.View<{ theme: ThemeType; isMobileSized: boolean }>(
  ({ theme: { spacing }, isMobileSized }) => ({
    ...(!isMobileSized && {
      marginTop: spacing['48px'],
    }),
  }),
)

const RecaptchaContainer = styled.View<{ theme: ThemeType; lessMargin?: boolean }>(({ theme, lessMargin }) => ({
  marginTop: lessMargin ? theme.spacing['32px'] : theme.spacing['72px'],
}))

export interface RegistrationEmailCaptureFormV2Props {
  onSignUpButtonPress: (values: EmailCaptureFormValues) => void
  emailCapturePageExperimentV2: string
  onSignInLinkPress: () => void
  onBackButtonPress?: () => void
  appleSSOAvailable?: boolean
  googleSSOAvailable?: boolean
  onAppleSignUpButtonPress?: () => void
  onGoogleSignUpButtonPress?: () => void
  isGenerateVerificationTokenLoading?: boolean
  customAppleSSOContent?: React.ReactNode
  customGoogleSSOContent?: React.ReactNode
  reCaptchaContent?: React.ReactNode
  isEmailError?: boolean
  customerName?: string
  isInternationalRegistrationExperience?: boolean
}

export const RegistrationEmailCaptureFormV2: FunctionComponent<RegistrationEmailCaptureFormV2Props> = ({
  appleSSOAvailable,
  googleSSOAvailable,
  onAppleSignUpButtonPress = noop,
  onGoogleSignUpButtonPress = noop,
  onBackButtonPress,
  onSignInLinkPress,
  onSignUpButtonPress,
  isGenerateVerificationTokenLoading,
  customAppleSSOContent,
  customGoogleSSOContent,
  reCaptchaContent,
  isEmailError,
  isInternationalRegistrationExperience,
  emailCapturePageExperimentV2,
}) => {
  const name = 'RegistrationEmailCaptureForm'
  const isSsoAvailable = appleSSOAvailable || googleSSOAvailable || customAppleSSOContent || customGoogleSSOContent
  const { formatMessage } = useIntl()
  const { isMobileSized, isMinWidthDesktop } = useMediaQuerySize()
  const { colors } = useTheme() as ThemeType

  const isEmailCaptureExperimentB = emailCapturePageExperimentV2 === 'Experiment B'

  const confidentialityNotice = () => {
    return (
      <ConfidentialityContainer isMobileSized={isMobileSized}>
        <ConfidentialityNotice
          customStyle={isMobileSized ? { marginBottom: 0 } : undefined}
          text={<FormattedMessage {...LYRA_CONFIDENTIALITY_MSG} />}
          accessibilityLabel={formatMessage(LYRA_CONFIDENTIALITY_MSG)}
        />
      </ConfidentialityContainer>
    )
  }

  const footerLinkPressed = (url: string) => {
    openUrl(url)
  }

  const CopyrightFooter = (
    <LegalFooterContainer>
      <LegalAndCopyrightFooter
        footerLinkPressed={footerLinkPressed}
        isInternational={isInternationalRegistrationExperience}
        removeSpacing
      />
    </LegalFooterContainer>
  )

  const body = (
    <>
      <EmailFormContainer testID={tID(`${name}-formContainer`)}>
        <Form
          onSubmit={onSignUpButtonPress}
          initialValues={{ emailAddress: '' }}
          validate={(formValues) => validateEmailForm(formValues, formatMessage)}
          render={({ handleSubmit, errors, touched }) => (
            <FormContainer handleSubmit={handleSubmit}>
              <HeaderContainer
                isMobileSized={isMobileSized}
                size={SubheadSize.LARGE}
                text={
                  <FormattedMessage
                    defaultMessage='Sign up to activate your benefit'
                    description='A title indicating that a user can set up an account with this form'
                  />
                }
              />
              {isEmailError && <InlineErrorBanner text={formatMessage(genericErrorMessage)} />}
              <Field
                testID={`${name}-emailAddressField`}
                name='emailAddress'
                label={formatMessage({
                  defaultMessage: 'Personal email',
                  description: 'Label of field for the user to enter their personal email address',
                })}
                component={InputFieldRFF}
                toolTipTriggerConfig={{ icon: TOOLTIP_TRIGGER_ICON.INFO }}
                toolTipContentStyle={{ container: { maxWidth: 243 } }}
                baseInputStyle={{ marginBottom: 16 }}
                inputContainerStyle={{ borderRadius: 8 }}
                inputAccessoryViewID={name}
                returnKeyType='done'
                error={touched && errors?.emailAddress}
              />
              <ButtonGroupContainer>
                <PrimaryButton
                  loading={!!isGenerateVerificationTokenLoading}
                  fullWidth
                  testID={tID(`${name}-defaultSignupButton`)}
                  text={formatMessage({
                    defaultMessage: 'Claim your benefit',
                    description: 'Button that allows user to sign up with their email',
                  })}
                  onPress={handleSubmit}
                />
                {isSsoAvailable && (
                  <DividerContainer>
                    <SplitDivider />
                    <DividerText
                      text={<FormattedMessage defaultMessage='or' description='The word "or"' />}
                      size={BodyTextSize.SMALL}
                    />
                    <SplitDivider />
                  </DividerContainer>
                )}
                {googleSSOAvailable && (
                  <ButtonContainer addMarginBottom={appleSSOAvailable}>
                    <SsoButton
                      testId={tID('Registration-GoogleButton')}
                      type={SSO_BUTTON_TYPE.GOOGLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.CONTINUE}
                      onPress={onGoogleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customGoogleSSOContent}
                {appleSSOAvailable && (
                  <ButtonContainer>
                    <SsoButton
                      testId={tID('Registration-AppleButton')}
                      type={SSO_BUTTON_TYPE.APPLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.SIGN_UP}
                      onPress={onAppleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customAppleSSOContent}
              </ButtonGroupContainer>
            </FormContainer>
          )}
        />
      </EmailFormContainer>
      <FooterTextContainer>
        <AlreadyHaveAccountText
          text={
            <FormattedMessage
              {...ALREADY_HAVE_AN_ACCOUNT_MSG}
              values={{
                link: (text: string) => (
                  <Link testID={tID(`${name}-signInLink`)} onPress={onSignInLinkPress} text={text} underline />
                ),
              }}
            />
          }
        />
      </FooterTextContainer>
      {confidentialityNotice()}
      {!isMobileSized && <RecaptchaContainer lessMargin={!!appleSSOAvailable}>{reCaptchaContent}</RecaptchaContainer>}
      {!isMobileSized && CopyrightFooter}
    </>
  )

  const sideContent = () => {
    return (
      <>
        {isEmailCaptureExperimentB ? (
          <>
            {!isMobileSized && (
              <RegistrationEmailCaptureAnimation
                width={isMinWidthDesktop ? 500 : 400}
                height={isMinWidthDesktop ? 418 : 334}
                style={{ margin: 'auto' }}
              />
            )}
            <RegistrationValueProps isCondensedVersion />
            {isMobileSized && (
              <RegistrationEmailCaptureAnimation
                width={isMinWidthDesktop ? 500 : 400}
                height={isMinWidthDesktop ? 418 : 334}
                style={{ margin: 'auto' }}
              />
            )}
          </>
        ) : (
          <RegistrationValueProps />
        )}
        {isMobileSized && <RecaptchaContainer lessMargin={isMobileSized}>{reCaptchaContent}</RecaptchaContainer>}
        {isMobileSized && CopyrightFooter}
      </>
    )
  }

  return (
    <>
      <StickyView
        headerComponent={!!onBackButtonPress && <NavBar showBackButton onBack={onBackButtonPress} />}
        safeAreaInsets={{ bottom: 0 }}
      >
        <TwoColumnRegistrationLayout
          mainContent={body}
          sideContent={sideContent()}
          isMainContentCentered={false}
          showSideContent
          {...(isEmailCaptureExperimentB && { sideContentBackgroundColor: colors.backgroundSecondary })}
        />
      </StickyView>
      <InputAccessory nativeID={name} />
    </>
  )
}
