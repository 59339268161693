import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { ArrowIcon, ArrowIconDirection, SecondaryTertiaryIconButton } from '..'
import { tID } from '../../utils'
import { BaseButtonProps, ButtonSize } from '../baseButton/BaseButton'
import TertiaryIconButton from '../tertiaryIconButton/TertiaryIconButton'

export interface BackButtonV2Props {
  onPress: () => void
  isOatmealBackground?: boolean
  testID?: string
  size?: ButtonSize
}

export const BackButtonV2: FunctionComponent<BackButtonV2Props> = ({
  isOatmealBackground,
  testID = tID('BackButton'),
  onPress,
  size = ButtonSize.DEFAULT,
}) => {
  const { formatMessage } = useIntl()

  const accessibilityLabel = formatMessage({
    defaultMessage: 'Go back',
    description: 'Screen reader text informing the user this button navigates them to the previous page',
  })

  const backButtonProps: BaseButtonProps = {
    accessibilityLabel: accessibilityLabel,
    leftIcon: <ArrowIcon direction={ArrowIconDirection.LEFT} />,
    onPress,
    testID,
    size,
  }

  return isOatmealBackground ? (
    <SecondaryTertiaryIconButton {...backButtonProps} />
  ) : (
    <TertiaryIconButton {...backButtonProps} />
  )
}
