import React, { FunctionComponent, ReactChild, useContext, useEffect, useMemo, useState } from 'react'
import { Dimensions, useColorScheme } from 'react-native'

import { debounce } from 'lodash-es'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/native'

import {
  getColorTokensForTheme,
  MediaQuerySizes,
  spacing,
  Spacing,
  Theme,
  ThemeColors,
} from '@lyrahealth-inc/shared-app-logic'

import { breakpoints as breakpointsConstants } from '../../constants'
import { useThemeSettings } from '../../hooks/useThemeSettings'
import { ProductTypeContext } from '../productType/productTypeContext'

export interface ThemeType {
  breakpoints: MediaQuerySizes
  colors: ThemeColors
  spacing: Spacing
  themeType: Theme
  setThemeType: (theme: Theme) => void
  isDarkMode: boolean
  isLyraSelect: boolean
}

function calculateBreakpoints(width: number, height: number) {
  const { mobileXs, mobileXsHeight, mobileS, mobileM, mobileL, tablet, laptop, desktop, desktopL, mobileXxs } =
    breakpointsConstants
  return {
    isMinWidthMobileXxs: width >= mobileXxs,
    isMinWidthMobileXs: width >= mobileXs,
    isMinHeightMobileXs: height >= mobileXsHeight,
    isMinWidthMobileS: width >= mobileS,
    isMinWidthMobileM: width >= mobileM,
    isMinWidthMobileL: width >= mobileL,
    isMinWidthTablet: width >= tablet,
    isMinWidthLaptop: width >= laptop,
    isMinWidthDesktop: width >= desktop,
    isMinWidthDesktopL: width >= desktopL,
    isMobileSized: width < tablet,
  }
}

export const ThemeProvider: FunctionComponent<{
  children: ReactChild | ReactChild[]
  /**
   * Not needed in App.tsx but needed for Storybook to use the Theme selector
   * in libs/ui-core-crossplatform/.storybook/decorators.js
   */
  theme?: Theme
}> = ({ children, theme: providedTheme }) => {
  const { themeType, setThemeType } = useThemeSettings()
  const { isLyraSelect, productType } = useContext(ProductTypeContext)

  const [breakpoints, setBreakpoints] = useState(
    calculateBreakpoints(Dimensions.get('window').width, Dimensions.get('window').height),
  )

  const colorScheme = useColorScheme()

  useEffect(() => {
    const listener = Dimensions.addEventListener(
      'change',
      debounce(({ window }) => {
        setBreakpoints(calculateBreakpoints(window.width, window.height))
      }, 75),
    )
    return () => listener.remove()
  })

  const isDarkMode = useMemo(() => {
    if (providedTheme) {
      return providedTheme === Theme.DARK
    }

    return themeType === Theme.DARK || (themeType === Theme.SYSTEM && colorScheme === Theme.DARK)
  }, [colorScheme, providedTheme, themeType])

  const colorTheme = isDarkMode ? Theme.DARK : Theme.LIGHT

  const colors = useMemo(() => {
    return getColorTokensForTheme(colorTheme, productType)
  }, [colorTheme, productType]) as ThemeColors

  return (
    <StyledComponentsThemeProvider
      theme={{
        breakpoints,
        colors,
        spacing,
        themeType,
        setThemeType,
        isDarkMode,
        isLyraSelect,
      }}
    >
      {children}
    </StyledComponentsThemeProvider>
  )
}
