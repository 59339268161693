import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Session Icon currently used on the bottom nav
 */
export const SessionIcon: FunctionComponent<SessionIconProps> = ({
  size = 24,
  fillColor,
  isFilled,
  testID = 'SessionIcon',
}) => {
  const { colors } = useTheme()
  const { sessionIcon } = colors.icons

  return (
    <Svg testID={tID(testID)} width={size} height={size} viewBox='0 0 24 24' fill='none'>
      {isFilled ? (
        <>
          <Path
            d='M15.7492 10.25H2.24922V20C2.23032 20.5766 2.44073 21.1371 2.83435 21.5589C3.22797 21.9806 3.77271 22.2291 4.34922 22.25H19.6492C20.2257 22.2291 20.7705 21.9806 21.1641 21.5589C21.5577 21.1371 21.7681 20.5766 21.7492 20V10.25H15.7492ZM20.2492 20C20.2637 20.178 20.2097 20.3547 20.0981 20.4941C19.9866 20.6336 19.826 20.7251 19.6492 20.75H15.7492V16.25H20.2492V20Z'
            fill={fillColor || sessionIcon.fill}
          />
          <Path
            d='M19.6492 3.75H17.7492V2.5C17.7492 2.30109 17.6702 2.11032 17.5295 1.96967C17.3889 1.82902 17.1981 1.75 16.9992 1.75C16.8003 1.75 16.6095 1.82902 16.4689 1.96967C16.3282 2.11032 16.2492 2.30109 16.2492 2.5V3.75H7.74922V2.5C7.74922 2.30109 7.6702 2.11032 7.52955 1.96967C7.3889 1.82902 7.19813 1.75 6.99922 1.75C6.80031 1.75 6.60954 1.82902 6.46889 1.96967C6.32824 2.11032 6.24922 2.30109 6.24922 2.5V3.75H4.34922C3.77271 3.77086 3.22797 4.01938 2.83435 4.44112C2.44073 4.86286 2.23032 5.42342 2.24922 6V8.75H21.7492V6C21.7681 5.42342 21.5577 4.86286 21.1641 4.44112C20.7705 4.01938 20.2257 3.77086 19.6492 3.75Z'
            fill={fillColor || sessionIcon.fill}
          />
        </>
      ) : (
        <Path
          d='M19.6492 3.25H17.7492V2C17.7492 1.80109 17.6702 1.61032 17.5295 1.46967C17.3889 1.32902 17.1981 1.25 16.9992 1.25C16.8003 1.25 16.6095 1.32902 16.4689 1.46967C16.3282 1.61032 16.2492 1.80109 16.2492 2V3.25H7.74922V2C7.74922 1.80109 7.6702 1.61032 7.52955 1.46967C7.3889 1.32902 7.19813 1.25 6.99922 1.25C6.80031 1.25 6.60954 1.32902 6.46889 1.46967C6.32824 1.61032 6.24922 1.80109 6.24922 2V3.25H4.34922C3.77271 3.27086 3.22797 3.51938 2.83435 3.94112C2.44073 4.36286 2.23032 4.92342 2.24922 5.5V19.5C2.23032 20.0766 2.44073 20.6371 2.83435 21.0589C3.22797 21.4806 3.77271 21.7291 4.34922 21.75H19.6492C20.2257 21.7291 20.7705 21.4806 21.1641 21.0589C21.5577 20.6371 21.7681 20.0766 21.7492 19.5V5.5C21.7681 4.92342 21.5577 4.36286 21.1641 3.94112C20.7705 3.51938 20.2257 3.27086 19.6492 3.25ZM9.74922 9.75H14.2492V14.25H9.74922V9.75ZM8.24922 14.25H3.74922V9.75H8.24922V14.25ZM14.2492 15.75V20.25H9.74922V15.75H14.2492ZM15.7492 14.25V9.75H20.2492V14.25H15.7492ZM4.34922 4.75H6.24922V6C6.24922 6.19891 6.32824 6.38968 6.46889 6.53033C6.60954 6.67098 6.80031 6.75 6.99922 6.75C7.19813 6.75 7.3889 6.67098 7.52955 6.53033C7.6702 6.38968 7.74922 6.19891 7.74922 6V4.75H16.2492V6C16.2492 6.19891 16.3282 6.38968 16.4689 6.53033C16.6095 6.67098 16.8003 6.75 16.9992 6.75C17.1981 6.75 17.3889 6.67098 17.5295 6.53033C17.6702 6.38968 17.7492 6.19891 17.7492 6V4.75H19.6492C19.8271 4.77257 19.9891 4.86348 20.1011 5.00347C20.2131 5.14346 20.2662 5.32154 20.2492 5.5V8.25H3.74922V5.5C3.7322 5.32154 3.78531 5.14346 3.8973 5.00347C4.00929 4.86348 4.17137 4.77257 4.34922 4.75ZM3.74922 19.5V15.75H8.24922V20.25H4.34922C4.17137 20.2274 4.00929 20.1365 3.8973 19.9965C3.78531 19.8565 3.7322 19.6785 3.74922 19.5Z'
          fill={fillColor || sessionIcon.fill}
        />
      )}
    </Svg>
  )
}

type SessionIconProps = {
  size?: number | string
  fillColor?: string
  isFilled?: boolean
  testID?: string
}
