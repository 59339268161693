import { micrositesMetadataList } from '../CMSMicrosite/constants'
import { CMS_MICROSITE_MESSAGE_KEY } from '../CMSMicrosite/messages/messages'
import { getMicrositePathwayKey } from '../CMSMicrosite/utils'
import { ESSENTIALS_EVENTS_PAGE } from '../common/constants/constants'

/** Use this to set custom pathways such as from cms toolkits */
export type CustomPathwayOption = `CustomPathway${string}`

/**
 * Registration pathways from CMS microsite toolkits
 */
const toolkitPathways = micrositesMetadataList.reduce<{ key: string; redirectUrl: string }[]>((result, curMetadata) => {
  const webinarEvent = curMetadata.postMessageEvents.find(
    (item) => item.key === CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR,
  )
  if (webinarEvent) {
    const key = getMicrositePathwayKey(curMetadata.micrositeId, CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR)
    const pathway = { key, redirectUrl: webinarEvent.params?.webinarLink as string }
    result.push(pathway)
  }

  const webinarMainPageEvent = curMetadata.postMessageEvents.find(
    (item) => item.key === CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WEBINAR_EVENTS,
  )
  if (webinarMainPageEvent) {
    const key = getMicrositePathwayKey(curMetadata.micrositeId, CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WEBINAR_EVENTS)
    const pathway = { key, redirectUrl: ESSENTIALS_EVENTS_PAGE }
    result.push(pathway)
  }
  return result
}, [])

/** Registration pathways from the homepage microsite/forked triage */
export enum PathwayOptions {
  SELF_SEARCH = 'self-search',
  CHILD_SEARCH = 'child-search',
  COACHING_TOOLKIT = 'coaching-toolkit',
  PARENTING_TOOLKIT = 'parenting-toolkit',
  ESSENTIALS = 'essentials',
  MEDICATION_CONSULTATION = 'medication-consultation',
  COUPLES_THERAPY = 'couples-therapy',
  FAMILY_THERAPY = 'family-therapy',
  WELLNESS_CHECK_IN = 'wellness-check-in',
}

export type ExtendedPathwayOption = PathwayOptions | CustomPathwayOption

/** If the pathway option is available on forked triage. Maps pathway option to boolean. Pathway option `key` is required in this map */
export const IS_PATHWAY_OPTION_ON_FORKED_TRIAGE: { [key in ExtendedPathwayOption]: boolean } = {
  [PathwayOptions.SELF_SEARCH]: true,
  [PathwayOptions.CHILD_SEARCH]: true,
  [PathwayOptions.COACHING_TOOLKIT]: true,
  [PathwayOptions.PARENTING_TOOLKIT]: true,
  [PathwayOptions.ESSENTIALS]: false,
  [PathwayOptions.MEDICATION_CONSULTATION]: true,
  [PathwayOptions.COUPLES_THERAPY]: true,
  [PathwayOptions.FAMILY_THERAPY]: true,
  [PathwayOptions.WELLNESS_CHECK_IN]: false,
}

export enum PathwaysRedirectUrls {
  SELF_TRIAGE = '/secure/onboard/about',
  CHILD_TRIAGE = '/secure/onboard/about-child',
  COACHING_TOOLKIT = '/secure/wellness/toolkit/purpose',
  PARENTING_TOOLKIT = '/secure/wellness/toolkit/parent',
  ESSENTIALS = '/secure/index/essentials',
  MEDICATION_CONSULTATION = '/secure/onboard/match-location?directPath=true&clientele=Individual&treatment=MedicationManagement&partner=BlendedCare&offering=Default&directLinkIntent=DIRECT_LINK_MEDS_CONSULT',
  WELLNESS_CHECK_IN = '/secure/wellness-check-in/results',
  COUPLES_THERAPY = `/secure/onboard/match-location?directPath=true&clientele=Couples&treatment=Therapy&partner=DirectAccess&offering=Default&directLinkSource=microsite_forked_triage&directLinkIntent=DIRECT_LINK_INTENT_COUPLES`,
  FAMILY_THERAPY = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.FAMILY_THERAPY}`,
  PARENTING_TOOLKIT_V2 = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.PARENTING_TOOLKIT}`,
  MEDICATION_CONSULTATION_V2 = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.MEDICATION_CONSULTATION}`,
}

const BasePathwayOptionToRedirectUrlMapping: { [key in ExtendedPathwayOption]?: string } = {
  [PathwayOptions.SELF_SEARCH]: PathwaysRedirectUrls.SELF_TRIAGE,
  [PathwayOptions.CHILD_SEARCH]: PathwaysRedirectUrls.CHILD_TRIAGE,
  [PathwayOptions.COACHING_TOOLKIT]: PathwaysRedirectUrls.COACHING_TOOLKIT,
  [PathwayOptions.ESSENTIALS]: PathwaysRedirectUrls.ESSENTIALS,
  [PathwayOptions.FAMILY_THERAPY]: PathwaysRedirectUrls.FAMILY_THERAPY,
  [PathwayOptions.COUPLES_THERAPY]: PathwaysRedirectUrls.COUPLES_THERAPY,
  [PathwayOptions.WELLNESS_CHECK_IN]: PathwaysRedirectUrls.WELLNESS_CHECK_IN,
  ...Object.fromEntries(toolkitPathways.map((item) => [item.key, item.redirectUrl])),
}

export const PathwayOptionToRedirectUrlMapping: { [key in ExtendedPathwayOption]?: string } = {
  ...BasePathwayOptionToRedirectUrlMapping,
  [PathwayOptions.PARENTING_TOOLKIT]: PathwaysRedirectUrls.PARENTING_TOOLKIT,
  [PathwayOptions.MEDICATION_CONSULTATION]: PathwaysRedirectUrls.MEDICATION_CONSULTATION,
}

export const PathwayOptionToRedirectUrlMappingV2: { [key in ExtendedPathwayOption]?: string } = {
  ...BasePathwayOptionToRedirectUrlMapping,
  [PathwayOptions.PARENTING_TOOLKIT]: PathwaysRedirectUrls.PARENTING_TOOLKIT_V2,
  [PathwayOptions.MEDICATION_CONSULTATION]: PathwaysRedirectUrls.MEDICATION_CONSULTATION_V2,
}

/**
 * Care options that should be available to teens (ie teens should be directed to the care option once logged into the app)
 */
export const TEEN_ENABLED_PATHWAYS: ExtendedPathwayOption[] = [
  PathwayOptions.SELF_SEARCH,
  PathwayOptions.ESSENTIALS,
  PathwayOptions.WELLNESS_CHECK_IN,
]

export const PATHWAY_QUERY_PARAM = 'pathwayOption'
export const AUTO_ENROLLMENT_TOKEN = 'autoEnrollmentToken'
