import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { LegalAndCopyrightFooter as LegalAndCopyrightFooter_ } from '../../../organisms/legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'

export interface LegalAndCopyrightFooterProps {
  onFooterLinkPressed: (url: string) => void
  isInternational?: boolean
  legalNotice?: React.ReactNode
  showCopyright?: boolean
}

const LegalFooterContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  background: theme.colors.backgroundSecondary,
  padding: theme.breakpoints.isMobileSized ? '40px 24px' : '80px 124px',
}))

export const LegalAndCopyrightFooter: FunctionComponent<LegalAndCopyrightFooterProps> = ({
  onFooterLinkPressed,
  isInternational,
  legalNotice,
  showCopyright,
}) => {
  const { colors } = useTheme()

  return (
    <LegalFooterContainer testID={tID('LegalAndCopyrightFooter')}>
      <LegalAndCopyrightFooter_
        footerLinkPressed={onFooterLinkPressed}
        legalNotice={legalNotice}
        backgroundColor={colors.backgroundSecondary}
        isInternational={isInternational}
        showCopyright={showCopyright}
      />
    </LegalFooterContainer>
  )
}
