import { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { MicrositeConfig, MicrositeConfigSectionNames } from '@lyrahealth-inc/shared-app-logic'

import { LyraMembershipSummary } from '../organisms/lyraMembershipSummary/LyraMembershipSummary'
import { LyraOfferingsCarousel } from '../organisms/lyraOfferingsCarousel/LyraOfferingsCarousel'
import { LyraSelectHomepageHeroSection } from '../organisms/lyraSelectHomepageHeroSection/LyraSelectHomepageHeroSection'
import { MicrositeCustomerBrandedBanner } from '../organisms/micrositeCustomerBrandedBanner/MicrositeCustomerBrandedBanner'
import { MicrositeMobileAppPromoBanner } from '../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoBanner'
import { MicrositeMobileAppPromoSection } from '../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoSection'
import { PathwaysCareNavigatorSection } from '../organisms/pathwaysCareNavigatorSection/PathwaysCareNavigatorSection'
import { PathwaysFAQSection } from '../organisms/pathwaysFAQSection/PathwaysFAQSection'
import { PathwaysHomepageHeroSection } from '../organisms/PathwaysHomepageHeroSection/PathwaysHomepageHeroSection'
import { PathwaysSelfCareSection } from '../organisms/pathwaysSelfCareSection/PathwaysSelfCareSection'
import { PathwaysTestimonialsSection } from '../organisms/pathwaysTestimonialsSection/PathwaysTestimonialsSection'

export const micrositeSectionsComponentMap = {
  [MicrositeConfigSectionNames.HERO]: PathwaysHomepageHeroSection,
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: MicrositeMobileAppPromoBanner,
  [MicrositeConfigSectionNames.CUSTOMER_BRANDED_BANNER]: MicrositeCustomerBrandedBanner,
  [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: LyraOfferingsCarousel,
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: MicrositeMobileAppPromoSection,
  [MicrositeConfigSectionNames.SELF_CARE]: PathwaysSelfCareSection,
  [MicrositeConfigSectionNames.TESTIMONIALS]: PathwaysTestimonialsSection,
  [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: LyraMembershipSummary,
  [MicrositeConfigSectionNames.CARE_NAVIGATOR]: PathwaysCareNavigatorSection,
  [MicrositeConfigSectionNames.FAQ]: PathwaysFAQSection,
  [MicrositeConfigSectionNames.LYRA_SELECT_HERO]: LyraSelectHomepageHeroSection,
}

/**
 * each customer can have a different configuration of sections displayed on the microsite / coming soon microsite.
 * this hook builds the array of sections that are displayed along with info such as background color
 * examples:
 *   ebay: offerings carousel, mobile app promo, membership, self care, testimonials, care navigator
 *   netflix: offerings carousel, mobile app promo, membership, testimonials, care navigator
 *   starbucks: offerings carousel, membership, care navigator
 * @param micrositeConfig the customer's specific microsite config that tells us which sections to hide
 * @returns array of displayed sections with their corresponding component and background color
 */
export const useGetMicrositeSections = (micrositeConfig: MicrositeConfig) => {
  const { colors } = useTheme()

  const sectionsToDisplay = Object.keys(micrositeConfig).filter((section) => !micrositeConfig[section].hideSection)

  // the index of the offerings carousel changes depending on whether there is the mobile app promo banner.
  // we know the offerings carousel is always backgroundSecondary, so use the index of the offerings carousel to determine
  // the background of the subsequent sections
  const offeringsCarouselIndex = sectionsToDisplay.findIndex(
    (sectionName) => sectionName === MicrositeConfigSectionNames.OFFERINGS_CAROUSEL,
  )
  const getSectionBackgroundColor = (sectionIndex: number) => {
    if (offeringsCarouselIndex % 2 === 0) {
      return sectionIndex % 2 === 0 ? colors.backgroundSecondary : colors.backgroundPrimary
    }
    return sectionIndex % 2 === 1 ? colors.backgroundSecondary : colors.backgroundPrimary
  }

  const sectionsMap: Array<{
    name: MicrositeConfigSectionNames
    component: FunctionComponent
    backgroundColor?: string
  }> = sectionsToDisplay.reduce<
    Array<{ name: MicrositeConfigSectionNames; component: FunctionComponent; backgroundColor?: string }>
  >((sections, currentSectionName: MicrositeConfigSectionNames, index) => {
    const sectionData = {
      name: currentSectionName,
      component: micrositeSectionsComponentMap[currentSectionName],
      ...(![
        MicrositeConfigSectionNames.HERO,
        MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER,
        MicrositeConfigSectionNames.FAQ,
      ].includes(currentSectionName) && {
        backgroundColor: getSectionBackgroundColor(index),
      }),
    }
    sections.push(sectionData)
    return sections
  }, [])
  return sectionsMap
}
