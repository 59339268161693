import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'
import exploreOnYourOwnIllustration from './../../assets/ExploreOnYourOwnIllustration.png'

const ExploreOnYourOwnPNG = styled(Image)<{ width: number; height: number; size?: number }>(
  ({ width, height, size }) => ({
    ...(size !== undefined
      ? {
          width: `${size}px`,
          height: `${size}px`,
        }
      : {
          width: `${width}px`,
          height: `${height}px`,
        }),
  }),
)

/**
 * Since react-native-svg does not support Mask for native, use image for specifically mobile
 */
export const ExploreOnYourOwnIllustration: FunctionComponent<ImageComponentProps & { size?: number }> = ({
  width = 128,
  height = 128,
  size = undefined,
}) => {
  return (
    <ExploreOnYourOwnPNG
      source={exploreOnYourOwnIllustration as ImageSourcePropType}
      testID={tID(`ExploreOnYourOwnIllustration`)}
      width={width}
      height={height}
      size={size}
    />
  )
}
