import { useCallback, useMemo } from 'react'

import {
  CMS_MICROSITE_MESSAGE,
  CMS_MICROSITE_MESSAGE_KEY,
  ESSENTIALS_EVENTS_PAGE,
  EssentialsContentData,
} from '@lyrahealth-inc/shared-app-logic'

/**
 * Get post message event handlers based on the cms microsite's metadata
 */
export const useGetCMSMicrositeMessageHandlers = ({
  eventsList,
  isLoggedIn,
  navigateToCareOptions,
  navigateToWellnessCheckIn,
  navigateToEssentials,
  navigateToEssentialsHomepageWidget,
  navigateToWebinar,
  showRegistrationModal,
  navigateToRegistration,
  clickSelfCareContent,
  openExternalLink,
}: {
  eventsList: { key: CMS_MICROSITE_MESSAGE; params?: Dict }[]
  isLoggedIn: boolean
  navigateToCareOptions: () => void
  navigateToWellnessCheckIn?: () => void
  navigateToEssentials: () => void
  navigateToEssentialsHomepageWidget?: () => void
  navigateToWebinar: ({ webinarLink }: { webinarLink: string }) => void
  showRegistrationModal?: (messageKey: CMS_MICROSITE_MESSAGE) => void
  navigateToRegistration?: () => void
  clickSelfCareContent?: ({
    contentData,
    contentSlug,
  }: {
    contentData?: EssentialsContentData
    contentSlug?: string
  }) => void
  openExternalLink: ({ link }: { link: string }) => void
}): { [key in CMS_MICROSITE_MESSAGE]?: () => void } => {
  const getPostMessageEventData = (message: MessageEvent) => {
    // Nested under data for web, directly in message for mobile
    return message?.data || message
  }

  const goToWellnessCheckIn = useCallback(() => {
    navigateToWellnessCheckIn && navigateToWellnessCheckIn()
  }, [navigateToWellnessCheckIn])

  const goToEssentials = useCallback(() => {
    if (isLoggedIn) {
      navigateToEssentials()
    } else {
      navigateToEssentialsHomepageWidget && navigateToEssentialsHomepageWidget()
    }
  }, [isLoggedIn, navigateToEssentials, navigateToEssentialsHomepageWidget])

  const registerWebinar = useCallback(
    ({
      messageKey,
      webinarLink = ESSENTIALS_EVENTS_PAGE,
    }: {
      webinarLink?: string
      messageKey: CMS_MICROSITE_MESSAGE
    }) => {
      if (isLoggedIn) {
        // todo: See if goodness can support extra props without nesting them under mixPanelData
        navigateToWebinar({ webinarLink })
      } else {
        showRegistrationModal && showRegistrationModal(messageKey)
      }
    },
    [isLoggedIn, navigateToWebinar, showRegistrationModal],
  )

  const goToRegistration = useCallback(() => {
    if (!isLoggedIn) {
      navigateToRegistration && navigateToRegistration()
    } else {
      navigateToCareOptions()
    }
  }, [isLoggedIn, navigateToCareOptions, navigateToRegistration])

  const handleClickSelfCareContent = useCallback(
    ({ message }: { message: MessageEvent }) => {
      const eventData = getPostMessageEventData(message)
      clickSelfCareContent &&
        clickSelfCareContent({
          contentData: eventData?.contentData, // Event + content data sent automatically when using goodness's essentials carousel
          contentSlug: eventData?.mixPanelData?.content_slug, // If not using the carousel, can manually attach the event + content_slug in the CMS
        })
    },
    [clickSelfCareContent],
  )

  const handlers = useMemo(() => {
    return {
      [CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_FORKED_TRIAGE]: navigateToCareOptions,
      [CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WELLNESS_CHECK_IN]: goToWellnessCheckIn,
      [CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_ESSENTIALS]: goToEssentials,
      [CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR]: registerWebinar,
      [CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WEBINAR_EVENTS]: registerWebinar,
      [CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_REGISTRATION]: goToRegistration,
      [CMS_MICROSITE_MESSAGE_KEY.CLICK_SELF_CARE_CONTENT]: handleClickSelfCareContent,
      [CMS_MICROSITE_MESSAGE_KEY.OPEN_EXTERNAL_LINK]: openExternalLink,
    }
  }, [
    goToEssentials,
    goToRegistration,
    goToWellnessCheckIn,
    handleClickSelfCareContent,
    navigateToCareOptions,
    registerWebinar,
    openExternalLink,
  ])

  return useMemo(() => {
    return eventsList
      .map((item) => {
        return {
          [item.key]: (message: MessageEvent) => {
            handlers[item.key]({ message, ...(item.params as any), messageKey: item.key })
          },
        }
      })
      .reduce((result, currentEventAndHandler) => {
        return { ...result, ...currentEventAndHandler }
      }, {})
  }, [eventsList, handlers])
}
