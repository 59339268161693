import React from 'react'

import styled from 'styled-components/native'

import { Flex1SafeAreaView } from '..'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks'
import { ThemeType, tID } from '../../utils'

const StyledSafeAreaView = styled(Flex1SafeAreaView)({
  height: '100%',
  ...(!IS_WEB && { marginTop: -42 }),
})

const LayoutContainer = styled.View<{ theme: ThemeType; isMainContentCentered?: boolean; isBackground?: boolean }>(
  ({ theme: { spacing, breakpoints }, isMainContentCentered, isBackground }) => {
    const gap = 16
    const margin = breakpoints.isMinWidthDesktop
      ? 80
      : breakpoints.isMinWidthLaptop
      ? 40
      : breakpoints.isMinWidthTablet
      ? 24
      : 16
    const gridMargin = margin - gap
    return {
      ...(IS_WEB &&
        !isMainContentCentered &&
        breakpoints.isMinWidthLaptop && {
          display: 'grid',
          gridTemplateColumns: `[start] ${gridMargin}px repeat(12, [col-start] 1fr) [col-end] ${gridMargin}px [end]`,
          columnGap: spacing['16px'],
        }),
      ...(isBackground && {
        position: IS_WEB ? 'fixed' : 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
      }),
    }
  },
)

const FormBackground = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
  ...(IS_WEB && breakpoints.isMinWidthLaptop
    ? {
        gridColumnStart: 'start',
        gridColumnEnd: 'col-start 8',
      }
    : {
        width: '100%',
      }),
}))

const SideContentBackground = styled.View<{
  theme: ThemeType
  backgroundColor?: string
  background?: boolean
}>(({ theme: { breakpoints, isDarkMode }, background = true, backgroundColor }) => ({
  ...(IS_WEB && breakpoints.isMinWidthLaptop
    ? {
        gridColumnStart: 'col-start 8',
        gridColumnEnd: 'end',
      }
    : {
        width: '100%',
      }),
  ...(background && {
    backgroundColor: backgroundColor || (isDarkMode ? '#2A2A2A' : '#F8F5EF'),
    ...(IS_WEB && {
      backgroundImage: backgroundColor
        ? `linear-gradient(${backgroundColor}, ${backgroundColor})`
        : isDarkMode
        ? 'linear-gradient(165deg, #2A2A2A 8.31%, #2A2A2A 70.02%, #4A3828 105.69%)'
        : 'linear-gradient(165deg, #F8F5EF 8.31%, #F8F5EF 70.02%, #FFE0C8 105.69%)',
    }),
  }),
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => {
  const horizontalMargin = breakpoints.isMinWidthDesktop
    ? '80px'
    : breakpoints.isMinWidthLaptop
    ? spacing['48px']
    : breakpoints.isMinWidthTablet
    ? spacing['24px']
    : breakpoints.isMobileSized
    ? spacing['16px']
    : undefined
  const verticalMargin = breakpoints.isMinWidthTablet
    ? spacing['48px']
    : breakpoints.isMobileSized
    ? spacing['32px']
    : undefined

  return {
    alignItems: 'center',
    margin: `${verticalMargin} ${horizontalMargin}`,
  }
})

const FormContentContainer = styled(ContentContainer)<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  marginTop: breakpoints.isMinWidthLaptop
    ? spacing['48px']
    : breakpoints.isMinWidthTablet
    ? spacing['24px']
    : breakpoints.isMobileSized
    ? spacing['16px']
    : undefined,
}))

const SideContentContainer = styled(ContentContainer)<{ theme: ThemeType }>(() => ({}))

const ValuePropsContent = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints } }) => ({
  maxWidth: breakpoints.isMinWidthDesktopL
    ? '554px'
    : breakpoints.isMinWidthLaptop
    ? '460px'
    : breakpoints.isMinWidthTablet
    ? '472px'
    : breakpoints.isMobileSized
    ? '467px'
    : undefined,
  minWidth: breakpoints.isMinWidthLaptop ? '347px' : breakpoints.isMobileSized ? '288px' : undefined,
}))

interface TwoColumnLayoutProps {
  mainContent: React.ReactNode
  isMainContentCentered?: boolean
  showSideContent?: boolean
  sideContent?: React.ReactNode
  sideContentBackgroundColor?: string
}

export const TwoColumnRegistrationLayout: React.FC<TwoColumnLayoutProps> = ({
  mainContent,
  isMainContentCentered = false,
  showSideContent = true,
  sideContent,
  sideContentBackgroundColor,
}) => {
  const { isMinWidthLaptop } = useMediaQuerySize()
  return (
    <StyledSafeAreaView testID={tID('TwoColumnRegistrationLayout')}>
      {isMinWidthLaptop && (
        <LayoutContainer isMainContentCentered={isMainContentCentered} isBackground>
          <FormBackground />
          <SideContentBackground backgroundColor={sideContentBackgroundColor} />
        </LayoutContainer>
      )}
      <LayoutContainer isMainContentCentered={isMainContentCentered}>
        <FormBackground>
          <FormContentContainer>{mainContent}</FormContentContainer>
        </FormBackground>
        {showSideContent && (
          <SideContentBackground backgroundColor={sideContentBackgroundColor} background={!isMinWidthLaptop}>
            <SideContentContainer>
              <ValuePropsContent>{sideContent}</ValuePropsContent>
            </SideContentContainer>
          </SideContentBackground>
        )}
      </LayoutContainer>
    </StyledSafeAreaView>
  )
}
