import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const carhartt: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an associate of Carhartt?',
        description: 'Asking if the user is an associate of the company Carhartt',
      }),
  }
}
