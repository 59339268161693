import { IntlShape } from 'react-intl'

/**
 *
 * Get the Share content for React Native Share api. For IOS we can pass the url link but not for Android.
 * To remedy this, we pass the url into the message itself
 */
export const getShareContent = (url: string, formatMessage: IntlShape['formatMessage'], isIOSDevice: boolean) => {
  return isIOSDevice
    ? {
        message: formatMessage({
          defaultMessage:
            'Lyra Health is a benefit that connects you to mental health professionals, self-care tools, and more. Use this personalized link to create your account today.',
          description: 'Body message when sharing with referral link on mobile device for ios',
        }),
        url,
        title: 'Lyra Health',
      }
    : {
        message: `${formatMessage({
          defaultMessage:
            'Lyra Health is a benefit that connects you to mental health professionals, self-care tools, and more. Use this personalized link to create your account today.',
          description: 'Body message when sharing with referral link on mobile device for android',
        })} \n ${url}`,
        title: 'Lyra Health',
      }
}
