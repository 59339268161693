import { useMemo } from 'react'

import { MICROSITE_WITH_CUSTOM_MESSAGES } from '@lyrahealth-inc/shared-app-logic'

import { useGetNewYearCustomMessages } from './useGetNewYearCustomMessages'

/**
 * Get custom messages for microsite. This is not supported through the metadata since the messages have dynamic components to them, such as session count, and conditional logic.
 */
export const useGetCMSMicrositeCustomMessages = ({ micrositeId }: { micrositeId: string }) => {
  const newYearMessages = useGetNewYearCustomMessages()

  const messages = useMemo(() => {
    return {
      [MICROSITE_WITH_CUSTOM_MESSAGES.NEW_YEAR_TOOLKIT]: newYearMessages,
    }
  }, [newYearMessages])

  return useMemo(() => {
    return messages[micrositeId as MICROSITE_WITH_CUSTOM_MESSAGES]
  }, [messages, micrositeId])
}
