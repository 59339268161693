import { darkThemeColors, lightThemeColors, ThemeColors } from './colors'

const baseColors = {
  blueberry_80: '#00001F',
  blueberry_75: '#030330',
  blueberry_70: '#0A1440',
  blueberry_65: '#1E1E61',
  blueberry_60: '#2A2E78',
  blueberry_50: '#364096',
  blueberry_40: '#444FB4',
  blueberry_30: '#6E7FCC',
  blueberry_20: '#DBE7FF',
  blueberry_10: '#EDF1FC',
  blueberry_0: '#F8FAFF',
}

export const lyraSelectLightThemeColors: ThemeColors = {
  ...lightThemeColors,

  // Text
  textActive: baseColors.blueberry_40,

  // Link
  linkDefault: baseColors.blueberry_40,
  linkHover: baseColors.blueberry_50,

  // Background
  backgroundHighlightPrimary: baseColors.blueberry_10,
  backgroundActive: baseColors.blueberry_40,
  backgroundHover: baseColors.blueberry_50,
  backgroundPressed: baseColors.blueberry_60,
  backgroundLoading: baseColors.blueberry_60,
  backgroundTopMatch: baseColors.blueberry_20,

  // Border
  borderHover: baseColors.blueberry_40,
  borderFocus: baseColors.blueberry_40,
  borderInput: baseColors.blueberry_40,
  borderTopMatch: baseColors.blueberry_20,

  // Icon
  iconActive: baseColors.blueberry_40,

  // Primary button
  primaryButtonBackgroundDefault: baseColors.blueberry_40,
  primaryButtonBackgroundHover: baseColors.blueberry_50,
  primaryButtonBackgroundPressed: baseColors.blueberry_60,
  primaryButtonBackgroundLoading: baseColors.blueberry_60,
  primaryButtonBackgroundFocus: baseColors.blueberry_50,

  secondaryButtonBackgroundHover: baseColors.blueberry_10,
  secondaryButtonBackgroundPressed: baseColors.blueberry_20,
  secondaryButtonBackgroundFocus: baseColors.blueberry_10,
  secondaryButtonOutlineDefault: baseColors.blueberry_40, // confirm this change
  secondaryButtonTextActive: baseColors.blueberry_40, // confirm this change

  // Secondary button variants
  secondaryButtonInverseBackgroundHover: baseColors.blueberry_60,
  secondaryButtonInverseBackgroundPressed: baseColors.blueberry_60,
  secondaryButtonInverseBackgroundFocus: baseColors.blueberry_60,
  secondaryButtonInverseOutlineDefault: baseColors.blueberry_20,
  secondaryButtonInverseOutlinePressed: baseColors.blueberry_40,
  secondaryButtonInverseTextActive: baseColors.blueberry_20,

  wordCloudButtonBackgroundSelected: baseColors.blueberry_10,
  wordCloudButtonOutlineHover: baseColors.blueberry_40,
  wordCloudButtonOutlinePressed: baseColors.blueberry_40,
  wordCloudButtonOutlineSelected: baseColors.blueberry_40,
  wordCloudButtonTextPressed: baseColors.blueberry_50,
  wordCloudButtonTextSelected: baseColors.blueberry_50,

  // Input field
  inputOutlineSelected: baseColors.blueberry_20,
  inputOutlineHover: baseColors.blueberry_40,
  inputOutlineFocus: baseColors.blueberry_40,

  // Checkbox
  checkboxBackgroundSelected: baseColors.blueberry_40,
  checkboxBackgroundPressed: baseColors.blueberry_40,
  checkboxOutlineSelected: baseColors.blueberry_40,

  // Radio
  radioBackgroundSelected: baseColors.blueberry_40,
  radioBackgroundSelectedInactive: baseColors.blueberry_20,
  radioTextSelected: baseColors.blueberry_50,
  radioOutlineSelected: baseColors.blueberry_40,
  radioOutlineSelectedInactive: baseColors.blueberry_20,
  radioOutlineFocused: baseColors.blueberry_40,

  // Radio with outline
  radioWithOutlinePrimaryTextHighlight: baseColors.blueberry_60,
  radioWithOutlineBackgroundSelected: baseColors.blueberry_10,
  radioWithOutlineBorderSelected: baseColors.blueberry_40,
  radioWithOutlinePrimaryTextSelected: baseColors.blueberry_50,
  radioWithOutlineSecondaryTextSelected: baseColors.blueberry_40,
  radioWithOutlineBorderHovered: baseColors.blueberry_40,
  radioWithOutlineBorderInactive: baseColors.blueberry_20,

  // ScrollView Refresher
  scrollViewRefresherLoadingIcon: baseColors.blueberry_30,

  // Gradient Tokens
  gradientBubbleStart: baseColors.blueberry_10,
  gradientBubbleEnd: baseColors.blueberry_0,

  // Page background gradient tokens
  backgroundGradientFirst: baseColors.blueberry_10,

  components: {
    ...lightThemeColors.components,
    activityHeader: {
      headline: {
        border: baseColors.blueberry_30,
      },
    },
    alertBanner: {
      ...lightThemeColors.components.alertBanner,
      calm: {
        ...lightThemeColors.components.alertBanner.calm,
        background: baseColors.blueberry_60,
      },
    },
    avatar: {
      ...lightThemeColors.components.avatar,
      tealTheme: { background: baseColors.blueberry_10, text: baseColors.blueberry_40 },
    },
    chatBubble: {
      ...lightThemeColors.components.chatBubble,
      outOfOffice: {
        ...lightThemeColors.components.chatBubble.outOfOffice,
        background: baseColors.blueberry_40,
      },
    },
    coachMark: {
      background: baseColors.blueberry_60,
      arrow: {
        border: baseColors.blueberry_60,
      },
    },
    dateBlock: {
      border: baseColors.blueberry_40,
      text: baseColors.blueberry_40,
      background: baseColors.blueberry_10,
    },
    feedbackStarIcon: {
      ...lightThemeColors.components.feedbackStarIcon,
      default: {
        ...lightThemeColors.components.feedbackStarIcon.default,
        hover: {
          ...lightThemeColors.components.feedbackStarIcon.default.hover,
          border: baseColors.blueberry_40,
        },
        highlighted: {
          fill: baseColors.blueberry_40,
          border: baseColors.blueberry_40,
        },
      },
    },
    focusBoxShadow: {
      ...lightThemeColors.components.focusBoxShadow,
      outerBox: { fill: baseColors.blueberry_40 },
    },
    genericHeader: {
      background: baseColors.blueberry_60,
    },
    internationalCareNavigatorContactInfo: {
      tooltip: { text: baseColors.blueberry_30 },
    },
    lessonPreview: {
      ...lightThemeColors.components.lessonPreview,
      progressBar: {
        fill: baseColors.blueberry_40,
      },
    },
    lessonThumbnail: {
      iconDisplay: {
        background: baseColors.blueberry_20,
      },
      disabledIcon: {
        fill: baseColors.blueberry_30,
      },
      progressBar: {
        fill: baseColors.blueberry_40,
      },
    },
    messageAttachment: {
      innerContainer: {
        background: baseColors.blueberry_50,
      },
    },
    messageItem: {
      ...lightThemeColors.components.messageItem,
      chatBubbleSender: {
        background: baseColors.blueberry_50,
        border: baseColors.blueberry_50,
      },
    },
    aiStatusBadge: {
      ...lightThemeColors.components.aiStatusBadge,
      processing: {
        background: baseColors.blueberry_10,
        text: baseColors.blueberry_50,
      },
    },
    notificationsOverview: {
      notificationIcon: {
        fill: baseColors.blueberry_60,
      },
    },
    platformEngagementQuarterlyDetails: {
      text: baseColors.blueberry_60,
    },
    privacyScreen: {
      textContainer: { background: baseColors.blueberry_60 },
    },
    progressBar: {
      ...lightThemeColors.components.progressBar,
      fill: baseColors.blueberry_20,
    },
    progressBarSecondary: {
      fill: baseColors.blueberry_30,
      background: baseColors.blueberry_20,
    },
    providerCalendar: {
      ...lightThemeColors.components.providerCalendar,
      event: {
        ...lightThemeColors.components.providerCalendar.event,
        bookableHover: {
          background: baseColors.blueberry_10,
          border: baseColors.blueberry_40,
        },
        recurringSession: {
          background: baseColors.blueberry_20,
          border: baseColors.blueberry_20,
          text: baseColors.blueberry_60,
        },
        mirror: {
          background: baseColors.blueberry_10,
          border: baseColors.blueberry_40,
        },
      },
    },
    providerLanguagesSpoken: {
      ...lightThemeColors.components.providerLanguagesSpoken,
      textBadge: {
        text: baseColors.blueberry_50,
      },
    },
    providerBadges: {
      ...lightThemeColors.components.providerBadges,
      preferenceMatchBadge: {
        checkIcon: { fill: baseColors.blueberry_30 },
      },
    },
    s4cLoadingIndicator: {
      text: baseColors.blueberry_60,
    },
    s4cHeader: {
      background: baseColors.blueberry_10,
    },
    sliderField: {
      text: baseColors.blueberry_50,
      thumb: {
        ...lightThemeColors.components.sliderField.thumb,
        border: baseColors.blueberry_40,
      },
      track: {
        ...lightThemeColors.components.sliderField.track,
        minimumTint: {
          background: baseColors.blueberry_40,
        },
      },
      stepMarker: {
        ...lightThemeColors.components.sliderField.stepMarker,
        filled: {
          background: baseColors.blueberry_10,
        },
      },
      floatingLabel: {
        ...lightThemeColors.components.sliderField.floatingLabel,
        border: baseColors.blueberry_40,
      },
    },
    multicolorSliderField: {
      ...lightThemeColors.components.multicolorSliderField,
      text: baseColors.blueberry_50,
    },
    triageSummary: {
      ...lightThemeColors.components.triageSummary,
      badge: {
        background: baseColors.blueberry_20,
        fill: baseColors.blueberry_50,
      },
    },
    toolbar: {
      background: baseColors.blueberry_60,
    },
    treatmentCard: {
      ...lightThemeColors.components.treatmentCard,
      findCounsellorSection: {
        icon: {
          fill: baseColors.blueberry_60,
        },
      },
    },
    unsupportedLanguageDisclaimer: {
      text: baseColors.blueberry_50,
    },
    videoControlButton: {
      ...lightThemeColors.components.videoControlButton,
      disabledColor: {
        fill: baseColors.blueberry_30,
      },
    },
    videoLessonComplete: {
      ...lightThemeColors.components.videoLessonComplete,
      check: { fill: baseColors.blueberry_20 },
      completeText: { text: baseColors.blueberry_20 },
    },
    videoPlayer: {
      ...lightThemeColors.components.videoPlayer,
      minimumTrackTint: { background: baseColors.blueberry_20 },
    },
    welcomeSplashScreen: {
      backButton: { fill: baseColors.blueberry_60 },
    },
    wellnessCheckInOverview: {
      bulletPoint: {
        fill: baseColors.blueberry_40,
      },
    },
    wellnessToolkitEssentials: {
      text: baseColors.blueberry_50,
    },
    zoomParticipantStatus: {
      ...lightThemeColors.components.zoomParticipantStatus,
      ready: {
        ...lightThemeColors.components.zoomParticipantStatus.ready,
        background: baseColors.blueberry_40,
      },
    },
    zoomVideoControls: {
      ...lightThemeColors.components.zoomVideoControls,
      disabled: { background: baseColors.blueberry_40 },
    },
  },
  icons: {
    ...lightThemeColors.icons,
    appIcon: {
      ...lightThemeColors.icons.appIcon,
      background: baseColors.blueberry_60,
    },
    avatarIcon: {
      body: { fill: baseColors.blueberry_10 },
      shadow: { fill: baseColors.blueberry_20 },
      fill: baseColors.blueberry_30,
    },
    checkMarkIcon: {
      fill: baseColors.blueberry_30,
    },
    essentialsIcon: {
      fill: baseColors.blueberry_60,
    },
    fingerprintIcon: {
      fill: baseColors.blueberry_20,
    },
    linkIcon: {
      chain: { fill: baseColors.blueberry_30 },
    },
    liveChatMessagingIcon: {
      fill: baseColors.blueberry_10,
    },
    lockPromptIcon: {
      shackle: { fill: baseColors.blueberry_30 },
      lockBody: { fill: baseColors.blueberry_10 },
      rightBody: { fill: baseColors.blueberry_20 },
      keyHole: { fill: baseColors.blueberry_30 },
    },
    logoutIcon: {
      rectangle: { fill: baseColors.blueberry_20 },
      arrow: { fill: baseColors.blueberry_40 },
    },
    menuIcon: {
      fill: baseColors.blueberry_60,
    },
    messagePromptIcon: {
      backgroundMessageLight: { fill: baseColors.blueberry_20 },
      backgroundMessageDark: { fill: baseColors.blueberry_30 },
      foregroundMessage: { fill: baseColors.blueberry_10 },
    },
    messagingIcon: {
      border: baseColors.blueberry_60,
    },
    notificationIcon: {
      fill: baseColors.blueberry_30,
    },
    progressIcon: {
      fill: baseColors.blueberry_60,
    },
    scoringIcon: {
      ...lightThemeColors.icons.scoringIcon,
      neutral: {
        arrow: { fill: baseColors.blueberry_40 },
        stroke: { fill: baseColors.blueberry_20 },
        fill: baseColors.blueberry_10,
      },
    },
    selfCareIcon: {
      ...lightThemeColors.icons.selfCareIcon,
      fill: baseColors.blueberry_30,
    },
    sessionIcon: {
      fill: baseColors.blueberry_60,
    },
    sparklesAvatarIcon: {
      fill: baseColors.blueberry_30,
    },
    timeIcon: {
      background: baseColors.blueberry_10,
      clockHandBackground: {
        fill: baseColors.blueberry_20,
      },
      clockHandForeground: {
        fill: baseColors.blueberry_30,
      },
    },
  },
}

// TODO Wait for design for dark theme colors for lyra select
export const lyraSelectDarkThemeColors: ThemeColors = {
  ...darkThemeColors,
}
