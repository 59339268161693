import { NICOTINE_QUITLINE_URL, RESOURCE_COORDINATOR_URL } from '@lyrahealth-inc/shared-app-logic'

import { getLocationObj } from './page-navigation-actions'
import * as MixpanelActions from '../../../../data/mixpanel'
import { appRouter_DO_NOT_USE as appRouter } from '../../../routing/legacyRouterShim'

/**
 * @deprecated Please do not add any more new usages of this. Components should generally use
 * useNavigate() or <Navigate /> to navigate with react-router.
 */
export function changeRoute(route: string, base = '/secure/onboard/') {
  return function (dispatch: $TSFixMe) {
    appRouter.navigate({ pathname: base + route })
    dispatch(setMixpanelOnLoadPage())
  }
}

export function setMixpanelOnLoadPage() {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const state = getState()
    const locationObj = getLocationObj(state)
    switch (locationObj.route) {
      case PAGE_ROUTES.ABOUT:
      case PAGE_ROUTES.ABOUT_CHILD:
        break
      case PAGE_ROUTES.MATCHES:
        if (locationObj.subroute === '') {
          dispatch(routeMixpanelAction({ page: MINI_MATCHER_MAP.location }))
        } else {
          dispatch(routeMixpanelAction({ page: MINI_MATCHER_MAP[locationObj.subroute] }))
        }
        break
      case PAGE_ROUTES.VIDEO_THERAPY:
        dispatch(
          routeMixpanelAction({
            page: ONBOARD_PAGES[locationObj.route],
            action: MixpanelActions.MIXPANEL_ACTION.GO_TO_DOD,
          }),
        )
        break
      case (PAGE_ROUTES as $TSFixMe).RESULTS:
        dispatch(
          routeMixpanelAction({
            page: ONBOARD_PAGES[locationObj.route],
            action: MixpanelActions.MIXPANEL_ACTION.PROVIDERS_MATCHED,
          }),
        )
        break
      case PAGE_ROUTES.UNSUPPORTED_OPTION:
        dispatch(
          routeMixpanelAction({
            page: ONBOARD_PAGES[locationObj.route],
            action: MixpanelActions.MIXPANEL_ACTION.UNSUPPORTED_OPTION,
          }),
        )
        break
      case PAGE_ROUTES.COACHING.DEFAULT:
      case PAGE_ROUTES.MATCH_LOCATION:
      case PAGE_ROUTES.COACHING_SESSION_MODALITY_SELECTION:
        break // Defer Mixpanel page loads to per-component handling
      default:
        if (Object.keys(ONBOARD_PAGES).includes(locationObj.route)) {
          dispatch(routeMixpanelAction({ page: ONBOARD_PAGES[locationObj.route] }))
        }
    }
  }
}

export function routeMixpanelAction({ page, action }: $TSFixMe) {
  return function (dispatch: $TSFixMe) {
    dispatch(MixpanelActions.handleOnboardPageLoad(page, action))
  }
}
/* -------------------------------------
 |This is the actual route names
 --------------------------------------- */
export const PAGE_ROUTES = {
  SECURE_INDEX: '/secure/index',
  HOMEBASE: '/secure/index/search-care',
  SEARCH_CARE: { BENEFITS: '/secure/index/search-care/benefits' },
  REGISTRATION_ONBOARDING: '/secure/index/account-info/background-info',
  ABOUT: 'about',
  ABOUT_CHILD: 'about-child',
  ABOUT_OTHER_ADULT: 'about-other-adult',
  MATCHES: 'matches',
  COACHES: 'coaches',
  CLINICAL_DATA_SHARING: '/secure/index/clinical-data-sharing',
  COACHING: {
    COACHES: 'coaching/coaches',
    COACH: 'coaching/coach',
    DEFAULT: 'coaching',
    LOCATION_MATCHER: 'coaching/location',
  },
  SSO_REGISTRATION: 'ssoregistration',
  SSO_REGISTRATION_APPLE: 'ssoregistration/apple',
  SSO_REGISTRATION_GOOGLE: 'ssoregistration/google',
  CONTACT_CARE_TEAM: 'contact-care-team',
  CONTACT_CARE_TEAM_CONFIRMATION: 'contact-care-team/confirmation',
  CARE_TEAM_PHONE_NUMBERS: '/care-team-phone-numbers',
  WORKLIFE_SERVICES_CONTACT_CARE_INTERNATIONAL: '/worklife-care-team-numbers',
  CONTACT_CARE_ADVOCATE_CONFIRMATION: 'care-advocate-consultation/confirmation',
  ZERO_SEARCH_RESULTS_REDIRECT: 'zero-search-results-redirect',
  MATCH_LOCATION: 'match-location',
  T1_MULTI_PROVIDERS: 't1-multi-providers',
  LATEST_PROVIDER_MATCHES: 'latest-provider-matches',
  UNSUPPORTED_OPTION: 'unsupported',
  VIDEO_THERAPY: 'video-therapy',
  PROVIDER: 'provider',
  PROFILE: {
    DEFAULT: '/secure/profile',
    ELIGIBILITY: '/secure/profile/eligibility',
    SETTINGS: '/secure/profile/settings',
    SESSION_HISTORY: '/secure/profile/session-history',
    HEALTH_PLAN_AND_PAYMENTS: '/secure/profile/health-plan-and-payments',
    PRIVACY_AND_LEGAL: '/secure/profile/privacy-and-legal',
    CHANGE_EMAIL: '/secure/profile/settings/email/change',
    ENROLL_MFA: '/secure/profile/settings/enrollMFA',
    VERIFY_MFA: '/secure/profile/settings/verifyMFA',
    MFA_ENABLED: '/secure/profile/settings/MFAEnabled',
    UPDATE_LOCATION: '/secure/profile/settings/update-location',
    UPDATE_PHONE_NUMBER: '/secure/profile/settings/update-phone-number',
    UPDATE_LANGUAGE: '/secure/profile/settings/language/change',
    UPDATE_NOTIFICATION_SETTINGS: '/secure/profile/settings/update-notification-settings',
    UPDATE_LYRA_UPDATES_SETTINGS: '/secure/profile/settings/update-notification-settings/lyra-updates',
    UPDATE_PERSONAL_INFORMATION: '/secure/profile/update-personal-information',
    UPDATE_CHILD_INFORMATION: '/secure/profile/update-child-information',
    UPDATE_PERSONAL_INFORMATION_QUESTIONNAIRE: '/secure/profile/update-info-questionnaire',
    UPDATE_THEME: '/secure/profile/settings/theme/change',
    UPDATE_CUSTOM_NOTIFICATIONS: '/secure/profile/settings/customNotifications',
    COVERAGE_CHECK: '/secure/coverage-check/profile',
    ADD_FAMILY_MEMBER: '/secure/profile/add-dependent-information',
    DEPENDENT_CONFIRMATION: '/secure/profile/dependent-confirmation',
    SELF_MANAGED_CONFIRMATION: '/secure/profile/self-managed-dependent-confirmation',
  },

  COACHING_SESSION_MODALITY_SELECTION: 'session-modality',
  APPOINTMENT_SELECTION: function (id: string) {
    return `provider/${id}/schedule`
  },
  APPOINTMENT_LOGISTICS: function (id: string) {
    return `provider/${id}/appointment`
  },
  APPOINTMENT_REQUEST: function (id: string) {
    return `provider/${id}/request`
  },
  LOCATION_NOT_SUPPORTED: function (id: string) {
    return `provider/${id}/location-not-supported`
  },
  MORE_OPTIONS: 'more-options',
  HOME: '/secure/index',
  ASSIGNMENTS: 'assignments',
  SESSIONS: '/secure/index/sessions',
  SESSION_SELECTION: 'sessions/reschedule',
  SESSION_DETAILS: 'sessions/details',
  MODALITY_QUESTIONNAIRE: {
    INTRO: 'modality-questionnaire',
    SURVEY: 'modality-questionnaire/survey',
    RESULTS: 'modality-questionnaire/results',
  },
  ORIENTATION_ABOUT_US: (id: $TSFixMe) => `orientation/${id}/about-us`,
  ORIENTATION_ABOUT_YOU: (id: $TSFixMe) => `orientation/${id}/about-you`,
  MEDS_OPTIONS_EXPLAINED: {
    ONBOARD: '/secure/onboard/meds-options-explained',
    DIRECT_BOOKING: 'secure/index/meds-options-explained',
  },
  HPI_COVERAGE_CHECK: {
    ONBOARD: {
      HEALTH_PLAN_FORM: '/secure/coverage-check/onboard/plan-info',
      APPOINTMENT_HEALTH_PLAN_FORM: '/secure/coverage-check/onboard/appointment-plan-info',
      APPOINTMENT_PAYMENT_INFO_FORM: '/secure/coverage-check/onboard/appointment-payment-info',
      TREATMENT_HEALTH_PLAN_FORM: '/secure/coverage-check/onboard/treatment-plan-info',
      FOR_CHILD_HEALTH_PLAN_FORM: '/secure/coverage-check/onboard/for-child-plan-info',
    },
    OPTION: {
      HEALTH_PLAN_FORM: '/secure/coverage-check/option/plan-info',
    },
    PROFILE: {
      HEALTH_PLAN_FORM: '/secure/coverage-check/profile/plan-info',
      PAYMENT_INFO_FORM: '/secure/coverage-check/profile/payment-info',
      INFRACTION_PAYMENT_INFO_FORM: '/secure/coverage-check/profile/infraction-payment-info',
    },
    PROVIDER: {
      HEALTH_PLAN_FORM: '/secure/coverage-check/provider/plan-info',
      PAYMENT_INFO_FORM: '/secure/coverage-check/provider/payment-info',
    },
    REMINDER: {
      HEALTH_PLAN_FORM: '/secure/coverage-check/reminder/plan-info',
      INFRACTION_PAYMENT_INFO_FORM: '/secure/coverage-check/reminder/infraction-payment-info',
    },
    HEALTH_PLAN_AND_PAYMENT_INFO_OVERVIEW: 'health-plan-and-payment-information-overview',
    HEALTH_PLAN_FORM: 'plan-info',
    PAYMENT_INFO_FORM: 'payment-info',
    INFRACTION_PAYMENT_INFO_FORM: 'infraction-payment-info',
    HEALTH_PLAN_PENDING: 'pending',
    HEALTH_PLAN_INELIGIBLE: 'ineligible',
    HEALTH_PLAN_INELIGIBLE_DIRECT_PATH: 'ineligible-direct-path',
    VIEW_OTHER_OPTIONS: 'view-other-options',
    HIGH_ALERT_RESOURCES: 'resources',
    APPOINTMENT_HEALTH_PLAN_FORM: 'appointment-plan-info',
    APPOINTMENT_PAYMENT_INFO_FORM: 'appointment-payment-info',
    TREATMENT_HEALTH_PLAN_FORM: 'treatment-plan-info',
    FOR_CHILD_HEALTH_PLAN_FORM: 'for-child-plan-info',
  },
  CARE_NAVIGATOR_OPTIONS: {
    DEFAULT: 'care-navigator-options',
    SECURE_INDEX: '/secure/index/care-navigator-options',
    ONBOARD: '/secure/onboard/care-navigator-options',
  },
  ONBOARD: {
    SUMMARY: '/secure/onboard/summary',
    ABOUT: '/secure/onboard/about',
    ABOUT_CHILD: '/secure/onboard/about-child',
    ABOUT_CHILD_INFO: '/secure/onboard/about-child/info',
  },
  PATHWAYS: {
    FORKED_TRIAGE: '/pathways/care-options',
    SELF_CARE: '/pathways/self-care-options',
    ESSENTIALS_HOMEPAGE_WIDGET: '/pathways/essentials-homepage-widget',
  },
  WELLNESS_CHECK_IN: {
    DEFAULT: '/wellness-check-in',
    PREVIEW: '/wellness-check-in/preview',
    RESULTS: '/secure/wellness-check-in/results',
  },
  ESSENTIALS: '/secure/index/essentials',
  ESSENTIALS_UNGATED_CONTENT: '/essentials',
  LOGIN: '/login',
  APPOINTMENT_CONFIRMATION_PARENT_LED_TEEN: 'appointment/confirmation/parent-led-teen',
  COMPLEX_CARE: '/secure/index/complex-care',
  FAMILY_CARE_OPTIONS: '/secure/index/family-care-options',
  ELECTION: '/election',
  FAMILY_HUB: '/secure/wellness/toolkit/family',
  UNSUBSCRIBE: '/unsubscribe',
  CARE_INITIAL: '/secure/index/care/initial',
  SOCIAL_CARE: RESOURCE_COORDINATOR_URL,
  NICOTINE_QUITLINE: NICOTINE_QUITLINE_URL,
  REGISTER: '/register/connect',
  MICROSITE: '/',
}

export const CMS_MICROSITE_ROUTES = [PAGE_ROUTES.COMPLEX_CARE, PAGE_ROUTES.ELECTION, PAGE_ROUTES.SOCIAL_CARE]

export const HPI_ROUTES = [
  PAGE_ROUTES.HPI_COVERAGE_CHECK.HEALTH_PLAN_FORM,
  PAGE_ROUTES.HPI_COVERAGE_CHECK.PAYMENT_INFO_FORM,
  PAGE_ROUTES.HPI_COVERAGE_CHECK.INFRACTION_PAYMENT_INFO_FORM,
  PAGE_ROUTES.HPI_COVERAGE_CHECK.HEALTH_PLAN_PENDING,
  PAGE_ROUTES.HPI_COVERAGE_CHECK.HEALTH_PLAN_INELIGIBLE,
]

export const PROFILE_EDIT_PAGES = [
  PAGE_ROUTES.PROFILE.CHANGE_EMAIL,
  PAGE_ROUTES.PROFILE.ENROLL_MFA,
  PAGE_ROUTES.PROFILE.VERIFY_MFA,
  PAGE_ROUTES.PROFILE.UPDATE_LOCATION,
  PAGE_ROUTES.PROFILE.UPDATE_PERSONAL_INFORMATION,
  PAGE_ROUTES.PROFILE.UPDATE_CHILD_INFORMATION,
  PAGE_ROUTES.PROFILE.UPDATE_NOTIFICATION_SETTINGS,
  PAGE_ROUTES.PROFILE.UPDATE_LYRA_UPDATES_SETTINGS,
  PAGE_ROUTES.PROFILE.UPDATE_LANGUAGE,
  PAGE_ROUTES.PROFILE.UPDATE_PHONE_NUMBER,
  PAGE_ROUTES.PROFILE.UPDATE_THEME,
  PAGE_ROUTES.PROFILE.UPDATE_PERSONAL_INFORMATION_QUESTIONNAIRE,
  PAGE_ROUTES.PROFILE.UPDATE_CUSTOM_NOTIFICATIONS,
  PAGE_ROUTES.PROFILE.ADD_FAMILY_MEMBER,
  PAGE_ROUTES.PROFILE.DEPENDENT_CONFIRMATION,
]

export const PROFILE_CONFIRMATION_PAGES = [PAGE_ROUTES.PROFILE.SELF_MANAGED_CONFIRMATION]

export const ONE_TRUST_COOKIE_BANNER_ROUTES = [PAGE_ROUTES.LOGIN, PAGE_ROUTES.MICROSITE]

/* -------------------------------------
 |THIS MAPS to mixpanel pages
 --------------------------------------- */
export const ONBOARD_PAGES = {
  'about-child': 'Child Info',
  'about-other-adult': 'Other Adult Info',
  results: 'Provider Results',
  asd: 'CA Help',
  unsupported: 'Unsupported Medication Recommendation',
  devConcern: 'CA Help',
  'video-therapy': 'Video Therapy Intro',
  'match-location': 'Location',
  't1-provider': 'Onsite Option Provider',
}
export const TRIAGE_ELEMENT_MAP = {
  NEEDS: 'Needs',
  TRIAGEWORDS: 'Wordgrid',
  SEVERITY: 'Severity',
  TREATMENTRECS: 'Treatment Recommendations',
  TREATMENTTOPICS: 'Therapy Styles',
  TREATMENTPREFS: 'Treatment Preferences',
  MEDSOPTIONS: 'Meds Program Options',
}

export const MINI_MATCHER_MAP = {
  location: 'Location',
  insurance: 'Insurance',
}
