import { useSelector } from 'react-redux'

import {
  GetIsEAPSessionCountExhaustedOptions,
  useFlags,
  useGetIsEAPSessionCountExhaustedShared,
} from '@lyrahealth-inc/shared-app-logic'

import {
  getIsEAPSessionCountExhaustedByProgramGetter,
  getIsUserEAPSessionCountExhausted,
} from '../../data/user/userSelectors'

export function useGetIsEAPSessionCountExhausted() {
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  const isUserEAPSessionCountExhausted = useSelector(getIsUserEAPSessionCountExhausted)
  const getIsEAPSessionCountExhaustedByProgram = useSelector(getIsEAPSessionCountExhaustedByProgramGetter)

  return useGetIsEAPSessionCountExhaustedShared(
    isProgramLevelSessionLimitEnabled,
    isUserEAPSessionCountExhausted,
    getIsEAPSessionCountExhaustedByProgram,
  )
}

export function useIsEAPSessionCountExhausted(options: GetIsEAPSessionCountExhaustedOptions) {
  const getIsEAPSessionCountExhaustedByProgram = useGetIsEAPSessionCountExhausted()
  return getIsEAPSessionCountExhaustedByProgram(options)
}
