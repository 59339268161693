import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGetIsMobileWebBrowser } from '@lyrahealth-inc/ui-core-crossplatform'

import { getLwMobileEnabled } from '../../data/customer/customerSelectors'
import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'
import { MOBILE_PROMO_DISMISSED } from '../constants/appConstants'
import { AUTO_ENROLLMENT_REGISTRATION_PATH } from '../constants/registrationFormConstants'

export interface useShowAppPromoArgs {
  promoBottomSheetEnabled?: boolean
}

export const useShowAppPromo = (
  { promoBottomSheetEnabled }: useShowAppPromoArgs = { promoBottomSheetEnabled: false },
) => {
  const isMobileBrowser = useGetIsMobileWebBrowser()
  const isLwMobileAppEnabled = useSelector(getLwMobileEnabled)
  const [showAppPromoBanner, setShowAppPromoBanner] = useState(false)
  const [showAppPromoBottomSheet, setShowAppPromoBottomSheet] = useState(false)
  const { pathname } = useLocation()

  const EXCLUDED_PROMO_PATHS = [PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT, AUTO_ENROLLMENT_REGISTRATION_PATH]
  const isInExcludedPath = EXCLUDED_PROMO_PATHS.some((path) => pathname.includes(path))

  useEffect(() => {
    if (!isInExcludedPath && isMobileBrowser && isLwMobileAppEnabled) {
      const promoBottomSheetDismissed = window.localStorage.getItem(MOBILE_PROMO_DISMISSED) === 'true'
      setShowAppPromoBanner(!promoBottomSheetEnabled || (promoBottomSheetEnabled && promoBottomSheetDismissed))
      if (promoBottomSheetEnabled && !promoBottomSheetDismissed) {
        const timeout = setTimeout(() => {
          setShowAppPromoBottomSheet(true)
        }, 2000)
        return () => clearTimeout(timeout)
      }
    } else {
      setShowAppPromoBottomSheet(false)
      setShowAppPromoBanner(false)
    }
    return
  }, [
    isMobileBrowser,
    isLwMobileAppEnabled,
    promoBottomSheetEnabled,
    setShowAppPromoBanner,
    setShowAppPromoBottomSheet,
    isInExcludedPath,
  ])

  return {
    showAppPromoBanner,
    setShowAppPromoBanner,
    showAppPromoBottomSheet,
    setShowAppPromoBottomSheet,
  }
}
