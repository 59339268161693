import { useCallback, useEffect } from 'react'

import { LD_EXTENSION_ID } from '../constants'

const useHandleMessage = <T>(callback: (message: { name: string; type: string; payload: T }) => void) => {
  const verifyData = useCallback((data: any) => {
    if (data == null || typeof data !== 'object') {
      return false
    }

    if (!('domain' in data) || typeof data.domain !== 'string' || data.domain !== LD_EXTENSION_ID) {
      return false
    }

    if (!('type' in data) || typeof data.type !== 'string') {
      return false
    }

    if (!('name' in data) || typeof data.name !== 'string') {
      return false
    }

    if (!('sender' in data) || typeof data.sender !== 'string') {
      return false
    }

    return true
  }, [])

  useEffect(() => {
    const onMessageReceived = (message: MessageEvent) => {
      const data = message.data
      if (!verifyData(data)) {
        return
      }
      callback(data)
    }
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [callback, verifyData])
}

export default useHandleMessage
