import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CostMessagingModalContent,
  CoverageType,
  GetCostMessagingModalTextParams,
  isUnlimitedSessionLimit,
  ProgramNames,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

interface EAP_COST_MESSAGING_PARAMS {
  numberOfSessions: string
  customerDisplayName: string
  numberOfSessionsByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
}

export const therapyOrCoachingTitle = (
  <FormattedMessage
    defaultMessage='Therapy or coaching sessions'
    description='Cost messaging modal EAP title for yourself text'
  />
)

export const therapySessionsTitle = (
  <FormattedMessage defaultMessage='Therapy sessions' description='Cost messaging modal title for therapy sessions' />
)

export const coachingSessionsTitle = (
  <FormattedMessage defaultMessage='Coaching sessions' description='Cost messaging modal title for coaching sessions' />
)

const eapSessionForYourself = (numberOfSessions: string | number, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='Meet with a provider up to {numberOfSessions} times a year—covered by {customerDisplayName}.'
      description='Cost messaging modal session description text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapSessionForFamily = (numberOfSessions: string | number, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='Meet with a provider up to {numberOfSessions} times a year—covered by {customerDisplayName} for you and your dependents.'
      description='Cost messaging modal session description for family text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const coachingForYourself = (numberOfSessions: number, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='Work with a mental health coach {isUnlimitedCoaching, select, true {with unlimited sessions } other {up to {numberOfSessions} times a year—}}covered by {customerDisplayName}.'
      description='Cost messaging modal coaching session description text'
      values={{ numberOfSessions, customerDisplayName, isUnlimitedCoaching: isUnlimitedSessionLimit(numberOfSessions) }}
    />
  )
}

const coachingForFamily = (numberOfSessions: number, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='Work with a mental health coach {isUnlimitedCoaching, select, true {with unlimited sessions } other {up to {numberOfSessions} times a year—}}covered by {customerDisplayName} for you and your dependents (18+). '
      description='Cost messaging modal coaching session description for family text'
      values={{ numberOfSessions, customerDisplayName, isUnlimitedCoaching: isUnlimitedSessionLimit(numberOfSessions) }}
    />
  )
}

export const eapMedsMedicationConsulationDescription = (
  <FormattedMessage
    defaultMessage='Meet with a prescriber—covered under your total sessions for the year.'
    description='Cost messaging modal description for customers with EAP medication enabled'
  />
)

export const medicationConsulationDescription = (
  <FormattedMessage
    defaultMessage='Your health plan may share the cost to meet with a prescriber.'
    description='Cost messaging modal description for medication consulation text'
  />
)

const hpiTherapyDescriptionForYourself = (
  <FormattedMessage
    defaultMessage='Cost sharing may be available to meet with a provider—depending on your health plan.'
    description='Cost messaging modal HPI cost sharing description for yourself text'
  />
)

const hpiTherapyDescriptionForFamily = (
  <FormattedMessage
    defaultMessage='Cost sharing may be available for you and your dependents to meet with a provider—depending on your health plan.'
    description='Cost messaging modal HPI cost sharing description for family text'
  />
)

export const medicalConsultationTitle = (
  <FormattedMessage
    defaultMessage='Medication consultations'
    description='Cost messaging modal medication consultation section title text'
  />
)

export const additionalHealthPlanSupportTitle = (
  <FormattedMessage
    defaultMessage='Additional health plan support'
    description='Cost messaging modal additional health plan section title text'
  />
)

const additionalHealthPlanSupportDescription = (
  <FormattedMessage
    defaultMessage='Cost sharing may be available for more sessions—depending on your health plan.'
    description='Cost messaging modal for additional health plan sessions description text'
  />
)

const additionalHealthPlanSupportEAPCoachingHPITherapyDescription = (
  <FormattedMessage
    defaultMessage='Cost sharing may be available for more therapy sessions—depending on your health plan.'
    description='Cost messaging modal for additional health plan therapy sessions description text'
  />
)

export const eapCoachingAndHpiTherapyWithDependentsBaseContent = ({
  numberOfSessions,
  customerDisplayName,
}: EAP_COST_MESSAGING_PARAMS) => {
  return {
    title: coachingSessionsTitle,
    description: eapSessionForFamily(numberOfSessions, customerDisplayName),
    contentType: ProgramNames.Coaching,
  }
}

export const eapCoachingAndHpiTherapyNoDependentsBaseContent = ({
  numberOfSessions,
  customerDisplayName,
}: EAP_COST_MESSAGING_PARAMS) => {
  return {
    title: coachingSessionsTitle,
    description: eapSessionForYourself(numberOfSessions, customerDisplayName),
    contentType: ProgramNames.Coaching,
  }
}

export const eapOnlyNoDependentsBaseTexts = ({
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: EAP_COST_MESSAGING_PARAMS) => {
  if (numberOfSessionsByProgram && isProgramLevelSessionLimitEnabled) {
    return [
      {
        title: therapySessionsTitle,
        description: eapSessionForYourself(numberOfSessionsByProgram.therapy, customerDisplayName),
        contentType: ProgramNames.BlendedCareTherapy,
      },
      {
        title: coachingSessionsTitle,
        description: coachingForYourself(numberOfSessionsByProgram.coaching, customerDisplayName),
        contentType: ProgramNames.Coaching,
      },
    ]
  }
  return [
    {
      title: therapyOrCoachingTitle,
      description: eapSessionForYourself(numberOfSessions, customerDisplayName),
      contentType: ProgramNames.BlendedCareTherapy,
    },
  ]
}

export const eapOnlyWithDependentsBaseTexts = ({
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: EAP_COST_MESSAGING_PARAMS) => {
  if (numberOfSessionsByProgram && isProgramLevelSessionLimitEnabled) {
    return [
      {
        title: therapySessionsTitle,
        description: eapSessionForFamily(numberOfSessionsByProgram.therapy, customerDisplayName),
        contentType: ProgramNames.BlendedCareTherapy,
      },
      {
        title: coachingSessionsTitle,
        description: coachingForFamily(numberOfSessionsByProgram.coaching, customerDisplayName),
        contentType: ProgramNames.Coaching,
      },
    ]
  }
  return [
    {
      title: therapyOrCoachingTitle,
      description: eapSessionForFamily(numberOfSessions, customerDisplayName),
      contentType: ProgramNames.BlendedCareTherapy,
    },
  ]
}

export const additionalHealthPlanSupportText = {
  title: additionalHealthPlanSupportTitle,
  description: additionalHealthPlanSupportDescription,
  contentType: ProgramNames.ClinicalLeaveEvaluation,
}

export const additionalHealthPlanSupportEAPCoachingHPITherapyText = {
  title: additionalHealthPlanSupportTitle,
  description: additionalHealthPlanSupportEAPCoachingHPITherapyDescription,
  contentType: ProgramNames.ClinicalLeaveEvaluation,
}

export const hpiOnlyNoDependentsBaseTexts = [
  {
    title: therapySessionsTitle,
    description: hpiTherapyDescriptionForYourself,
    contentType: ProgramNames.BlendedCareTherapy,
  },
]

export const hpiWithDependentsBaseTexts = [
  {
    title: therapySessionsTitle,
    description: hpiTherapyDescriptionForFamily,
    contentType: ProgramNames.BlendedCareTherapy,
  },
]

export const hpiMedsBaseDescription = {
  title: medicalConsultationTitle,
  description: medicationConsulationDescription,
  contentType: ProgramNames.MedicationManagement,
}

export const eapMedsBaseDescription = {
  title: medicalConsultationTitle,
  description: eapMedsMedicationConsulationDescription,
  contentType: ProgramNames.MedicationManagement,
}

const getMedsTextIfEnabled = ({
  isCustomerEAPMeds,
  isCustomerHPIMeds,
  coverageType,
  isCustomerProgramEAPCoaching,
  isCustomerProgramHPITherapy,
  isCustomerProgramEAPTherapy,
}: GetCostMessagingModalTextParams & {
  coverageType: CoverageType
}): CostMessagingModalContent[] => {
  const medsTexts = []

  // Order of the if statements are important because we want to opt for the EAP copy if the program is both EAP and HPI supported
  if (isCustomerEAPMeds) {
    medsTexts.push(eapMedsBaseDescription)
  } else if (isCustomerHPIMeds) {
    medsTexts.push(hpiMedsBaseDescription)
  }

  if (coverageType === CoverageType.EAP_AND_HPI) {
    if (isCustomerProgramEAPCoaching && isCustomerProgramHPITherapy && !isCustomerProgramEAPTherapy) {
      medsTexts.push(additionalHealthPlanSupportEAPCoachingHPITherapyText)
    } else {
      medsTexts.push(additionalHealthPlanSupportText)
    }
  }

  return medsTexts
}

const getEapOnlyCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerEAPMeds,
  numberOfSessions,
  customerDisplayName,
  isCustomerProgramEAPCoaching,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: GetCostMessagingModalTextParams & EAP_COST_MESSAGING_PARAMS) => {
  const defaultMedsTextParams = {
    coverageType: CoverageType.EAP_ONLY,
    isCustomerEAPMeds,
    dependentsEnabled: !isDependentDisabled,
  }

  // Available copy/design only supports eap only customers with both therapy and coaching available. If needed,
  // will need to update logic and add new copy to support configurations that only have one or the other.
  if (!isCustomerProgramEAPCoaching) {
    return []
  }

  let eapOnlyModalContent: CostMessagingModalContent[] = []
  if (!isDependentDisabled) {
    eapOnlyModalContent = [
      ...eapOnlyWithDependentsBaseTexts({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
      }),
    ]
  } else {
    eapOnlyModalContent = [
      ...eapOnlyNoDependentsBaseTexts({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
      }),
    ]
  }

  const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
  if (medsText) {
    eapOnlyModalContent = [...eapOnlyModalContent, ...medsText]
  }

  return eapOnlyModalContent
}

const getHpiOnlyCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerHPIMeds,
}: GetCostMessagingModalTextParams) => {
  const defaultMedsTextParams = {
    coverageType: CoverageType.HPI_ONLY,
    isCustomerHPIMeds,
  }

  let hpiOnlyModalContent: CostMessagingModalContent[] = []
  if (!isDependentDisabled) {
    hpiOnlyModalContent = [...hpiWithDependentsBaseTexts]
  } else {
    hpiOnlyModalContent = [...hpiOnlyNoDependentsBaseTexts]
  }

  const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
  if (medsText) {
    hpiOnlyModalContent = [...hpiOnlyModalContent, ...medsText]
  }

  return hpiOnlyModalContent
}

const getEapAndHpiCostMessagingModalText = ({
  isDependentDisabled,
  isCustomerEAPMeds,
  isCustomerHPIMeds,
  isCustomerProgramEAPCoaching,
  isCustomerProgramHPITherapy,
  isCustomerProgramEAPTherapy,
  numberOfSessions,
  customerDisplayName,
  numberOfSessionsByProgram,
  isProgramLevelSessionLimitEnabled,
}: GetCostMessagingModalTextParams & Partial<EAP_COST_MESSAGING_PARAMS>) => {
  let eapAndHpiCostMessagingModalText: CostMessagingModalContent[] = []

  const eapCoachingAndHpiTherapyBaseContent = !isDependentDisabled
    ? eapCoachingAndHpiTherapyWithDependentsBaseContent
    : eapCoachingAndHpiTherapyNoDependentsBaseContent

  const eapCoachingAndEapTherapyBaseContent = !isDependentDisabled
    ? eapOnlyWithDependentsBaseTexts
    : eapOnlyNoDependentsBaseTexts

  const defaultMedsTextParams = {
    coverageType: CoverageType.EAP_AND_HPI,
    isCustomerEAPMeds,
    isCustomerHPIMeds,
    isCustomerProgramEAPCoaching,
    isCustomerProgramHPITherapy,
    isCustomerProgramEAPTherapy,
    dependentsEnabled: !isDependentDisabled,
  }

  const therapyIsOnlyHpiSupported = isCustomerProgramHPITherapy && !isCustomerProgramEAPTherapy

  if (isCustomerProgramEAPCoaching && numberOfSessions && customerDisplayName) {
    // a program can be both EAP and HPI supported we want to enter the if block only when therapy is strictly HPI only
    if (therapyIsOnlyHpiSupported) {
      eapAndHpiCostMessagingModalText = [
        eapCoachingAndHpiTherapyBaseContent({
          numberOfSessions,
          customerDisplayName,
        }),
      ]
    } else {
      eapAndHpiCostMessagingModalText = eapCoachingAndEapTherapyBaseContent({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
      })
    }

    const medsText = getMedsTextIfEnabled(defaultMedsTextParams)
    // there should at least be one other text in order to add the meds text, i.e. modal content can not only contain meds text
    if (eapAndHpiCostMessagingModalText.length > 0 && medsText) {
      eapAndHpiCostMessagingModalText = [...eapAndHpiCostMessagingModalText, ...medsText]
    }
  }

  return eapAndHpiCostMessagingModalText
}

export const getDefaultCostMessagingModalText = (
  numberOfSessions: string,
  customerDisplayName: string,
  isProgramLevelSessionLimitEnabled: boolean,
  numberOfSessionsByProgramStr?: string,
): {
  [key in CoverageType]: (params: GetCostMessagingModalTextParams) => CostMessagingModalContent[]
} => {
  const numberOfSessionsByProgram = numberOfSessionsByProgramStr
    ? (JSON.parse(numberOfSessionsByProgramStr) as SessionLimitByProgram)
    : undefined

  return {
    [CoverageType.EAP_ONLY]: (params: GetCostMessagingModalTextParams) => {
      return getEapOnlyCostMessagingModalText({
        numberOfSessions,
        customerDisplayName,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
        ...params,
      })
    },
    [CoverageType.HPI_ONLY]: (params: GetCostMessagingModalTextParams) => {
      return getHpiOnlyCostMessagingModalText({ ...params })
    },
    [CoverageType.EAP_AND_HPI]: (params: GetCostMessagingModalTextParams) => {
      return getEapAndHpiCostMessagingModalText({
        numberOfSessions,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
        customerDisplayName,
        ...params,
      })
    },
  }
}
