import React, { ReactNode } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { GestureResponderEvent } from 'react-native'

import { css, CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { getFontStyles } from '../../styles/typeStyles'
import { ThemeType } from '../../utils'
import { ActivityBadge } from '../activityBadge/ActivityBadge'
import { BodyText } from '../bodyText/BodyText'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'

export type GenericListItemProps = {
  title?: string | ReactNode
  subText?: string | ReactNode
  icon?: ReactNode
  badgeContent?: string | number | ReactNode | null
  badgeTextColor?: string
  listPressedBackgroundColor?: string
  badgeBackgroundColor?: string
  itemClickHandler?: () => void
  rightItems?: ReactNode[]
  fullBorder?: boolean
  onClick?: (event: GestureResponderEvent) => void
  inactive?: boolean
  isFirstItem?: boolean
  isLastItem?: boolean
  testID?: string
  largeText?: boolean
  boldText?: boolean
  height?: string
  leftContainerColumnFlex?: boolean
  containerStyle?: CSSObject
  innerContainerStyle?: CSSObject
  textStyle?: CSSObject
  showTextOverflow?: boolean
  disabled?: boolean
}

const GenericListItemContainer = styled(PressableOpacity)<{
  inactive?: boolean
  fullBorder?: boolean
  isFirstItem?: boolean
  isLastItem?: boolean
  containerStyle?: CSSObject
  theme: ThemeType
}>(({ inactive, fullBorder, isFirstItem, isLastItem, containerStyle, theme: { spacing, colors } }) => ({
  opacity: inactive ? '0.5' : '',
  border: fullBorder ? '1px solid' : '',
  borderColor: fullBorder ? colors.borderDefault : '',
  borderTopLeftRadius: isFirstItem ? spacing['12px'] : '',
  borderTopRightRadius: isFirstItem ? spacing['12px'] : '',
  borderBottomLeftRadius: isLastItem ? spacing['12px'] : '',
  borderBottomRightRadius: isLastItem ? spacing['12px'] : '',
  ...containerStyle,
}))

const GenericListItemInnerContainer = styled.View<{
  fullBorder?: boolean
  isMobileSized: boolean
  isLastItem?: boolean
  theme?: ThemeType
  height?: string
  largeText?: boolean
  innerContainerStyle?: CSSObject
}>(({ largeText, fullBorder, height, isMobileSized, isLastItem, innerContainerStyle, theme: { spacing, colors } }) => ({
  borderBottomWidth: !fullBorder && !isLastItem ? '1px' : '',
  borderBottomColor: !fullBorder && !isLastItem ? colors.borderDefault : '',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height,
  padding: largeText ? `${spacing['16px']} ${spacing['24px']}` : spacing['24px'],
  margin: isMobileSized ? '0 17px' : '',
  ...innerContainerStyle,
}))

const SubtextContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  textTransform: 'none',
  ...getFontStyles(theme.colors).body.small,
  lineHeight: theme.spacing['20px'],
  color: theme.colors.textSecondary,
}))

const LeftContainer = styled.View<{
  columnFlex?: boolean
}>(({ columnFlex }) => ({
  display: 'flex',
  alignItems: columnFlex ? 'start' : 'center',
  flexDirection: columnFlex ? 'column' : 'row',
}))

const TextContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled.View<{
  theme: ThemeType
  largeText?: boolean
  boldText?: boolean
  textStyle?: CSSObject
}>(({ largeText, boldText, textStyle, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'none',
  marginBottom: theme.spacing['4px'],
  ...(largeText ? getFontStyles(theme.colors).body.large : getFontStyles(theme.colors).subhead.xsmall),
  fontWeight: boldText ? '500' : '400',
  lineHeight: '22px',
  ...textStyle,
}))

const Icon = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
}))

const BadgeContainer = styled.View({
  marginLeft: '10px',
})

const RightContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: theme.spacing['12px'],
}))

export const GenericListItem: React.FC<GenericListItemProps> = ({
  title,
  icon,
  subText,
  badgeContent,
  badgeBackgroundColor,
  badgeTextColor,
  listPressedBackgroundColor,
  rightItems,
  fullBorder,
  onClick,
  inactive,
  isFirstItem,
  isLastItem,
  testID,
  largeText,
  boldText,
  height = '83px',
  leftContainerColumnFlex,
  containerStyle,
  innerContainerStyle,
  textStyle,
  showTextOverflow,
  disabled,
  ...props
}) => {
  const { breakpoints, colors } = useTheme()
  const onLinkClick = (event: GestureResponderEvent) => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <GenericListItemContainer
      pressedBackgroundColor={onClick ? listPressedBackgroundColor || colors.backgroundSecondary : ''}
      inactive={inactive}
      fullBorder={fullBorder}
      onPress={onLinkClick}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      testID={testID}
      containerStyle={containerStyle}
      disabled={disabled}
    >
      <GenericListItemInnerContainer
        height={height}
        isMobileSized={breakpoints.isMobileSized}
        fullBorder={fullBorder}
        isLastItem={isLastItem}
        largeText={largeText}
        innerContainerStyle={innerContainerStyle}
        {...props}
      >
        <LeftContainer columnFlex={leftContainerColumnFlex}>
          {icon ? <Icon>{icon}</Icon> : null}
          <TextContainer>
            <Title largeText={largeText} boldText={boldText} textStyle={textStyle}>
              {showTextOverflow ? <BodyText text={title} /> : <Dotdotdot clamp={1}>{title}</Dotdotdot>}
              {badgeContent ? (
                <BadgeContainer>
                  <ActivityBadge
                    color={badgeTextColor || colors.components.genericListItem.badge.text}
                    data-test-id='GenericListItem-badge'
                    backgroundColor={badgeBackgroundColor || colors.components.genericListItem.badge.background}
                    text={badgeContent}
                    activityBadgeCustomStyle={css`
                      padding: 0 10px;
                      height: 20px;
                      font-weight: 700;
                      width: 100%;
                    `}
                  />
                </BadgeContainer>
              ) : null}
            </Title>
            {subText ? <SubtextContainer data-test-id='GenericListItem-subText'>{subText}</SubtextContainer> : null}
          </TextContainer>
        </LeftContainer>
        {rightItems ? <RightContainer>{rightItems}</RightContainer> : null}
      </GenericListItemInnerContainer>
    </GenericListItemContainer>
  )
}
