import { COMMON_MIXPANEL_PAGE } from './mixpanelConstants'

export enum UTM_CAMPAIGN {
  ELECTION = 'election2024',
  REFERRAL = 'referral',
}

export enum UTM_MEDIUM {
  PRODUCT = 'product',
  ORGANIC = 'organic',
  PRODUCT_MOBILE = 'product-mobile',
  PRODUCT_DESKTOP = 'product-desktop',
  EMAIL_MOBILE = 'email-mobile',
  EMAIL_DESKTOP = 'email-desktop',
}

export enum UTM_SOURCE {
  HOMEBASE = COMMON_MIXPANEL_PAGE.HOME_BASE,
  LOGIN_PAGE = 'login-page',
  HAMBURGER_MENU = 'hamburger-menu',
  PATHWAYS_MICROSITE_BANNER = 'pathways-microsite-banner',
  COMING_SOON_MICROSITE_BANNER = 'coming-soon-microsite-banner',
  WELCOME_EMAIL = 'welcome-email',
}
