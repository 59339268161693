import React, { FunctionComponent } from 'react'

import { WelcomeBeachProps } from './WelcomeBeachSvg'
import { withSuspense } from '../../utils/withSuspense'

const WelcomeBeachSvg = withSuspense({
  component: React.lazy(() => import('./WelcomeBeachSvg')),
})

/**
 * illustration representing a person laying on the beach.
 */
export const WelcomeBeach: FunctionComponent<WelcomeBeachProps> = ({ width, height }) => {
  return <WelcomeBeachSvg width={width} height={height} />
}
