import React, { FunctionComponent } from 'react'

import { ActivityOnboardingIconProps } from './ActivityOnboardingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const ActivityOnboardingIconSvg = withSuspense({
  component: React.lazy(() => import('./ActivityOnboardingIconSvg')),
})

export const ActivityOnboardingIcon: FunctionComponent<ActivityOnboardingIconProps> = ({ width, height }) => {
  return <ActivityOnboardingIconSvg width={width} height={height} />
}
