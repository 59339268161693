import { EligibilityType } from '../../features'
import { ProgramCustomerPropertyId } from '../../features/common/constants/customerProgram'
import { HEALTH_PLAN_INFO_KEYS } from '../../features/common/constants/healthPlanAndPaymentInformationConstants'
import { SessionCountByProgram } from '../session/SessionUsage'
import { SessionHistory, User } from '../user/User'

export enum PAYMENT_CARD_TYPES {
  CREDIT_DEBIT = 'credit_debit',
  FSA_HSA = 'fsa_hsa',
  PREPAID = 'prepaid',
  UNKNOWN = 'unknown',
  OTHER = 'other',
}

export enum PAYMENT_CHARGE_TYPE {
  INFRACTION = 'infraction',
  COPAY = 'copay',
  PENDING_COPAY = 'pending_copay',
}

export enum HEALTH_PLAN_ELIGIBILITY_STATUS {
  ELIGIBLE = 'eligible',
  PENDING = 'pending',
  INELIGIBLE = 'ineligible',
}

export type MedicalPlanEligibilityStatus = HEALTH_PLAN_ELIGIBILITY_STATUS | null

export interface MinorSessionUsage {
  firstName: string
  lastName: string
  dob: string
  usedSessionCount: number
  usedSessionCountByProgram?: SessionCountByProgram
  lyraId: string
  sessionHistory: SessionHistory[]
}

export interface Patient {
  createDate: string
  updateDate: string
  id: number
  version: number
  insurance: string
  user: User
  firstname?: unknown
  lastname?: unknown
  eligibleLyraId?: unknown
  eligibleLyraIdStatus?: unknown
  gender?: unknown
  healthPlanName: string | null
  healthPlanMembershipId?: string | null
  medicalPlanEligibilityStatus?: MedicalPlanEligibilityStatus
  healthPlanLegalLyraId?: unknown
  healthPlanPaymentAccountToken?: unknown
  healthPlanCardType?: unknown
  healthPlanConsent?: unknown
  isEligibleForHealthPlanExtension?: boolean | null
  requestedSwitchToHealthPlan?: unknown
  usedSessionCount?: number | null
  usedSessionCountByProgram?: SessionCountByProgram
  sessionHistory?: SessionHistory[] | null
  legalFirstName?: string | null
  legalLastName?: string | null
  legalDob?: string | null
  isUsingHealthPlan?: boolean | null
  eligibilityType?: EligibilityType
  cardLast4?: unknown
  receiveHealthPlanPrompt?: boolean | null
  searchedFor?: unknown
  location?: unknown
  dob?: unknown
  dependentType?: unknown
  healthPlanEligibilityActivityId?: unknown
  healthPlanEligibilityCorrelationId?: unknown
  infractionPaymentAccountToken?: unknown
  infractionConsent?: boolean
  infractionNonFsaHsaAttestation?: boolean
  infractionCardLast4?: unknown
  minorsSessionUsageInfo?: MinorSessionUsage[] | null
  isHealthPlanIntegrated?: boolean
  secondaryHealthPlanName?: string
  secondaryHealthPlanMembershipId?: string
  secondaryHealthPlanMedicalPlanEligibilityStatus?: MedicalPlanEligibilityStatus
  secondaryHealthPlanLegalLyraId?: unknown
  secondaryHealthPlanEligibilityActivityId?: unknown
  secondaryHealthPlanEligibilityCorrelationId?: unknown
  isSecondaryHealthPlanIntegrated?: boolean
  secondaryHealthPlanLegalDob?: string | null
  secondaryHealthPlanLegalFirstName?: string | null
  secondaryHealthPlanLegalLastName?: string | null
}

export interface PaymentMethod {
  allowedForInfractions: boolean
  costSharingCardLast4: string | null
  costSharingConsent: boolean | null
  createDate: string
  expirationMonth: string
  expirationYear: string
  id: number
  infractionCardLast4: string | null
  infractionConsent: boolean | null
  brand: string | null
  nonFsaHsaAttestation: boolean
  patient?: Patient
  paymentIdentifier: string
  paymentType: PAYMENT_CARD_TYPES
  stripeToken: string | null
  updateDate: string
  valid: boolean
  version: number
}

export type PaymentMethods = PaymentMethod[]

export type HealthPlanInfo = {
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_NAME_TEXT_INPUT]?: string | null
  [HEALTH_PLAN_INFO_KEYS.LEGAL_FIRST_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.LEGAL_LAST_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.LEGAL_DOB]?: string | null
  [HEALTH_PLAN_INFO_KEYS.HEALTH_PLAN_MEMBERSHIP_ID]?: string | null
  [HEALTH_PLAN_INFO_KEYS.IS_HEALTH_PLAN_INTEGRATED]?: boolean | null
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_HEALTH_PLAN_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_LEGAL_FIRST_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_LEGAL_LAST_NAME]?: string | null
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_LEGAL_DOB]?: string | null
  [HEALTH_PLAN_INFO_KEYS.SECONDARY_HEALTH_PLAN_MEMBERSHIP_ID]?: string | null
  [HEALTH_PLAN_INFO_KEYS.IS_SECONDARY_HEALTH_PLAN_INTEGRATED]?: boolean | null
}

export interface PaymentHistory {
  date: string
  charge: number
  chargeType: PAYMENT_CHARGE_TYPE
  memberName: string
  provider: string
  providerName?: string
  typeOfService: string
  cardBrand?: string | null
  cardLast4?: string | null
  chargeDate?: string | null
}

export type HealthPlanEligibilityOptions = Partial<Record<ProgramCustomerPropertyId, string>>

export interface PostHealthPlanPaymentInfoBody {
  healthPlanConsent: boolean
  healthPlanPaymentCardIsHsa: boolean
  healthPlanPaymentMethodId: string
}

export interface PostInfractionPaymentInfoBody {
  infractionConsent: boolean
  infractionNonFsaHsaAttestation: boolean
  infractionPaymentMethodId: string
}
