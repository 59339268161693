import React, { FunctionComponent } from 'react'

import { SubheadSize } from '../../styles'
import { TextAlign } from '../../styles/typeStyles'
import { Size, Subhead } from '../subhead/Subhead'

/**
 * Simple Header text that is shown mostly in header components
 */
export const HeaderText: FunctionComponent<HeaderTextProps> = ({ color, size, text, wrap, textAlign }) => {
  return (
    <Subhead size={size ?? Size.SMALL} text={text} numberOfLines={1} color={color} wrap={wrap} textAlign={textAlign} />
  )
}

type HeaderTextProps = {
  text: string | React.ReactNode
  color?: string
  size?: SubheadSize
  wrap?: boolean
  textAlign?: TextAlign
}
