import React, { FunctionComponent } from 'react'

import { withSuspense } from '../../utils/withSuspense'

const SparklesAvatarIconSvg = withSuspense({
  component: React.lazy(() => import('./SparklesAvatarIconSvg')),
})

export interface SparklesAvatarIconProps {
  size?: number | string
  color?: string
}

// This will work with Avatars of height=77 and width=77, see SparklesAvatarIcon.stories.tsx for how to use
export const SparklesAvatarIcon: FunctionComponent<SparklesAvatarIconProps> = () => {
  return <SparklesAvatarIconSvg />
}
