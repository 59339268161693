import React, { FunctionComponent, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { TextStyle, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { MaskedInput } from './MaskedInput'
import { SelectField } from './SelectField'
import { tID } from '../../utils'

const TimeFieldContainer = styled.View({
  flexDirection: 'row',
})

const HourContainer = styled.View({
  flex: 1,
  marginBottom: '0px',
  marginRight: '8px',
})

const AMPMContainer = styled.View({
  flex: 1,
  marginTop: '32px',
  width: '105px',
})

export interface TimeFieldInputProps {
  baseInputStyle?: ViewStyle
  error?: string
  label?: string
  name?: string
  onBlur?: () => void
  onChange?: (val: string) => void
  onChangeAMorPM?: (val: string) => void
  onFocus?: () => void
  placeholder?: string
  readOnly?: boolean
  showErrorAsWarning?: boolean
  value: string
  style?: ViewStyle | TextStyle
  defaultAMorPM?: string
  onError?: (errorMessage: string) => void
}

export const TimeField: FunctionComponent<TimeFieldInputProps> = ({
  baseInputStyle,
  error,
  label,
  name,
  onBlur,
  onChange,
  onChangeAMorPM,
  onFocus,
  placeholder,
  readOnly,
  showErrorAsWarning,
  style,
  value,
  defaultAMorPM = '',
  onError,
}) => {
  const { formatMessage } = useIntl()
  const [selectedTime, setSelectedTime] = useState(`${value} ${defaultAMorPM}`)
  const [selectedAMorPM, setSelectedAMorPM] = useState(defaultAMorPM)
  const [errorMessage, setErrorMessage] = useState(error)
  const [isMilitaryTime, setIsMilitaryTime] = useState(false)

  const isValid = (value: string) => {
    const isValidRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
    const isProperLength = value.length === 5
    const chunks = isProperLength && value.split(':')
    const hours = (chunks && chunks[0]) ?? null
    const hoursLessThan24 = hours && (typeof hours === 'string' ? parseInt(hours) < 24 : hours < 24)

    return isValidRegex && isProperLength && hoursLessThan24
  }

  const onHourChange = (e: string) => {
    if (isValid(e)) {
      setErrorMessage('')
      onError && onError('')
      const chunks = e.split(':')
      parseInt(chunks[0]) > 12 || chunks[0] === '00' ? setIsMilitaryTime(true) : setIsMilitaryTime(false)
    } else {
      setErrorMessage(
        formatMessage({
          defaultMessage: 'Invalid time',
          description: 'Error message that tells the user the format they entered for the time is incorrect',
        }),
      )
    }
    setSelectedTime(e)
    onChange && onChange(`${e} ${selectedAMorPM}`)
  }

  const onAMPMChange = (value: string) => {
    setSelectedAMorPM(value)
    onChange && onChange(`${selectedTime} ${selectedAMorPM}`)
    onChangeAMorPM && onChangeAMorPM(value)
  }
  return (
    <TimeFieldContainer>
      <HourContainer testID={tID('TimeField-hourContainer')}>
        <MaskedInput
          baseInputStyle={baseInputStyle}
          error={errorMessage}
          key='hour'
          label={label}
          mask='HH:MM'
          name={name}
          onBlur={onBlur}
          onTextChange={onHourChange}
          onFocus={onFocus}
          readOnly={readOnly}
          placeholder={placeholder}
          type={'datetime'}
          value={selectedTime}
          style={style as ViewStyle}
        />
      </HourContainer>
      <AMPMContainer testID={tID('TimeField-amPmContainer')}>
        <SelectField
          baseInputStyle={baseInputStyle}
          customWebStyle={style as TextStyle}
          key='amOrPm'
          label=''
          name={`${name}-am-or-pm`}
          onChange={onAMPMChange}
          options={[
            { label: 'AM', value: 'AM' },
            { label: 'PM', value: 'PM' },
          ]}
          placeholder={placeholder}
          showErrorAsWarning={showErrorAsWarning}
          value={isMilitaryTime ? '' : selectedAMorPM}
          readOnly={readOnly}
          disabled={isMilitaryTime ? true : readOnly}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Choose AM or PM',
            description: 'Accessibility label for AM or PM select field',
          })}
        />
      </AMPMContainer>
    </TimeFieldContainer>
  )
}

export const TimeFieldRFF: FunctionComponent<FieldRenderProps<string>> = ({
  baseInputStyle,
  label,
  meta: { error, touched },
  name,
  placeholder,
  showErrorAsWarning,
  style,
  value,
  ...props
}) => {
  return (
    <TimeField
      baseInputStyle={baseInputStyle}
      error={touched && error}
      label={label}
      name={name}
      placeholder={placeholder}
      showErrorAsWarning={showErrorAsWarning}
      style={style}
      value={value}
      {...props}
    />
  )
}
