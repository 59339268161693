import { defineMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const vca: GetCustomerCopyFunc = () => {
  const subpopulations: CustomerSubpopulations = {
    header: defineMessage({
      defaultMessage: 'Which type of VCA member are you?',
      description: 'VCA subpopulation redirection header',
    }),
    options: [
      {
        label: defineMessage({
          defaultMessage: 'Associate',
          description: 'VCA Associate member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am an Associate of VCA',
          description: 'VCA Associate member description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'Dependent',
          description: 'VCA Dependent member label',
        }),
        description: defineMessage({
          defaultMessage:
            'I am a spouse/domestic partner or dependent (over the age of 18) of a VCA Associate and am enrolled in an Anthem Health Plan or I am an Associate of VCA seeking care for a dependent under 18 years old who is enrolled in an Anthem Health Plan.',
          description: 'VCA Dependent member description',
        }),
      },
    ],
  }
  const subpopulations_lctTeens: CustomerSubpopulations = {
    header: defineMessage({
      defaultMessage: 'Which type of VCA member are you?',
      description: 'VCA subpopulation redirection header',
    }),
    options: [
      {
        label: defineMessage({
          defaultMessage: 'Associate',
          description: 'VCA Associate member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am an Associate of VCA',
          description: 'VCA Associate member description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'Dependent',
          description: 'VCA Dependent member label',
        }),
        description: defineMessage({
          defaultMessage:
            'I am a spouse/domestic partner or dependent (over the age of 13) of a VCA Associate and am enrolled in an Anthem Health Plan or I am an Associate of VCA seeking care for a dependent under 18 years old who is enrolled in an Anthem Health Plan.',
          description: 'VCA Dependent member description',
        }),
      },
    ],
  }
  return {
    subpopulations,
    subpopulations_lctTeens,
  }
}
