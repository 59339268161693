import { MutableRefObject, useCallback } from 'react'

import { useBackToTriage } from './useBackToTriage'
import { useGetTreatmentOptions } from './useGetTreatmentOptions'
import {
  useSelectTreatmentOption,
  UseSelectTreatmentOptionArgs,
  NavigationRoutes as UseSelectTreatmentOptionNavigationRoutes,
} from './useSelectTreatmentOption'
import { unsupportedTreatmentOptionsConfigurations as defaultUnsupportedTreatmentOptionsConfigurations } from '../../../features/common/constants/baseTreatmentOptionConstants'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../../../features/searchForCare/constants'
import { CustomerCopy, INTERNATIONAL_CONSENT_STATUS } from '../../../models'
import {
  GetWordCloudWordsActionData,
  SearcherInfo,
  TriageSearch,
  WordCloudWords,
} from '../../../models/searchForCare/SearchForCare'
import { TREATMENT_OPTIONS } from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'
import { isAgeTeen, isAgeYoungerThanTeen } from '../../common/utils/userUtils'
import { getUnsupportedTreatmentOptionsConfiguration } from '../utils'

interface NavigationRoutes extends UseSelectTreatmentOptionNavigationRoutes {
  goBack: () => void
  onExit: () => void
  contactCareNavigatorConsolidated: () => void
  contactCareNavigatorAssertiveTriage: () => void
}

interface useTreatmentRecommendationsArgs extends Omit<UseSelectTreatmentOptionArgs, 'navigationRoutes'> {
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
  navigationRoutes: NavigationRoutes
  userAge: number
  backToTriageProps: {
    isCreatingSearch: boolean
    lyraSocialCareEnabled: boolean
    primaryNeedsWord: string
    searcherInfo?: SearcherInfo | null
    shouldCreateDuplicateInProgressSearch?: boolean
    country: string
    wordCloudWords: WordCloudWords | null
    getWordCloudWords: (data: GetWordCloudWordsActionData) => Promise<any>
    postTriageSearch: (arg: any) => Promise<any>
    fetchedMetadataVersion?: string
  }
  navBarProps: {
    a11yFocusedElementOnLoad?: MutableRefObject<any>
    shouldShowCareNavigatorHeaderEntrypoint: boolean
    careNavigatorModal?: React.ReactElement
    careNavigatorEntryPoint?: React.ReactElement
    showProgressBar?: boolean
  }
  getIsAssertiveTriage: (tier?: TREATMENT_RECOMMENDATION_TIER) => boolean
  /** Open CN from on the treatment recs page */
  openCareNavigatorModalOnPage: () => void
  careNavigatorModalOnPage: React.ReactElement
  // Booleans
  isUserInternational: boolean
  hasAccessToInternationallySupportedTreatmentOptions: boolean
  gdprInternationalConsent?: INTERNATIONAL_CONSENT_STATUS
  isDirectAccessMedsSupported: boolean
  shouldSeeCareAdvocateRequestForm: boolean
  isDedicatedCareNavigatorEnabled: boolean
  // Treatment rec props
  customerCopy?: CustomerCopy
  tollFreeNumbers?: string[]
  outOfCountryNumbers?: string[]
  onEligibilityTooltipPressSeeDetails: () => void
  onPrivacyPolicyLinkPress: () => void
  childName?: string
}

/**
 * Returns all the props needed to render the treatment recommendation v2 page
 */

export const useTreatmentRecommendations = ({
  treatmentRecs,
  navigationRoutes,
  backToTriageProps,
  userAge,
  navBarProps,
  currentProgramCoverageBreakdown,
  getIsAssertiveTriage,
  selectedSearch,
  patchTriageSearch,
  setMeetingFormat,
  setSelectedTreatmentOption,
  trackEvent,
  launchDarklyTrack,
  isUserInternational,
  hasAccessToInternationallySupportedTreatmentOptions,
  gdprInternationalConsent = INTERNATIONAL_CONSENT_STATUS.NONE,
  isHealthPlanConfirmed,
  shouldSeeCareAdvocateRequestForm,
  isDedicatedCareNavigatorEnabled,
  customerCopy,
  tollFreeNumbers,
  outOfCountryNumbers,
  onEligibilityTooltipPressSeeDetails,
  matchFor,
  onPrivacyPolicyLinkPress,
  childName,
  openCareNavigatorModalOnPage,
  careNavigatorModalOnPage,
}: useTreatmentRecommendationsArgs) => {
  const { age: searcherAge } = backToTriageProps.searcherInfo ?? { age: userAge }
  const treatmentOptions = useGetTreatmentOptions({
    treatmentRecs,
    searcherAge,
    shouldSeeCareAdvocateRequestForm,
    hasAccessToInternationallySupportedTreatmentOptions,
    gdprInternationalConsent,
    customerCopy,
    isUserInternational,
    matchFor,
  })

  const handleBackPress = useBackToTriage({
    goBack: navigationRoutes.goBack,
    search: selectedSearch as TriageSearch,
    ...backToTriageProps,
  })

  const selectTreatmentOption = useSelectTreatmentOption({
    navigationRoutes: {
      locationFinder: navigationRoutes.locationFinder,
      healthPlanInfo: navigationRoutes.healthPlanInfo,
    },
    selectedSearch,
    treatmentRecs,
    currentProgramCoverageBreakdown,
    setMeetingFormat,
    patchTriageSearch,
    setSelectedTreatmentOption,
    trackEvent,
    launchDarklyTrack,
    isHealthPlanConfirmed,
  })

  const isAssertiveTriage = useCallback(
    () => getIsAssertiveTriage(treatmentRecs?.shownTierPage),
    [getIsAssertiveTriage, treatmentRecs?.shownTierPage],
  )

  // This checks if customer has disabled DA meds.
  const unsupportedTreatmentOptionsConfiguration = getUnsupportedTreatmentOptionsConfiguration({
    unsupportedTreatmentOptionsConfigurations: defaultUnsupportedTreatmentOptionsConfigurations,
    excludedTreatmentOptionsReasons: treatmentRecs?.excludedTreatmentOptionsReasons,
    isUserInternational,
  })

  const onPressCareNavigator = () => {
    if (treatmentRecs?.shownTierPage === TREATMENT_RECOMMENDATION_TIER.TIER_1 && shouldSeeCareAdvocateRequestForm) {
      navigationRoutes.contactCareNavigatorAssertiveTriage()
    } else if (isDedicatedCareNavigatorEnabled) {
      openCareNavigatorModalOnPage()
    } else {
      navigationRoutes.contactCareNavigatorConsolidated()
    }
  }

  const showProgressBar =
    treatmentOptions.primaryTreatmentOption !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS &&
    treatmentOptions.primaryTreatmentOption !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE

  return {
    treatmentRecommendationProps: {
      onPrivacyPolicyLinkPress,
      onEligibilityTooltipPressSeeDetails,
      hasAccessToInternationallySupportedTreatmentOptions,
      primaryTreatmentOption: treatmentOptions.primaryTreatmentOption,
      alternateTreatmentOption: treatmentOptions.alternateTreatmentOption,
      secondaryTreatmentOptions: treatmentOptions.secondaryTreatmentOptions,
      configForSpecificTreatmentOptions: treatmentOptions.configForSpecificTreatmentOptions,
      showPairMeds: !!unsupportedTreatmentOptionsConfiguration?.title,
      programCoverage: currentProgramCoverageBreakdown,
      isInternational: isUserInternational,
      detailInfoPanelProps: {
        tier: treatmentRecs?.shownTierPage,
        isChild: isAgeYoungerThanTeen(searcherAge),
        isTeen: isAgeTeen(searcherAge),
        internationalHideCTA: isUserInternational,
        tollFreeNumbers,
        outOfCountryNumbers,
      },
      headerProps: {
        isInternational: isUserInternational,
        onBack: handleBackPress,
        onExit: navigationRoutes.onExit,
        testIdPrefix: 'NavBar',
        ...navBarProps,
        showProgressBar,
      },
      onTreatmentOptionButtonPress: selectTreatmentOption,
      onPressCareNavigator,
      tier: treatmentRecs?.shownTierPage,
      gdprInternationalConsent,
      matchFor,
      childName,
      careNavigatorModalOnPage,
    },
    logData: {
      treatmentOptions,
      configForSpecificTreatmentOptions: treatmentOptions.configForSpecificTreatmentOptions,
      isAssertiveTriage: isAssertiveTriage(),
    },
  }
}
