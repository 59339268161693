// @intl project:triage

import { defineMessages, MessageDescriptor } from 'react-intl'

import { PrimaryNeedsWords, TriageScreenerValue, TriageWords } from './constants'
import { MEMBER_PREFERENCE_IDS } from '../memberPreferences/constants'

export const DAYS_OF_WEEK_LONG_LABELS = defineMessages({
  Sunday: {
    defaultMessage: 'Sunday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Monday: {
    defaultMessage: 'Monday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Tuesday: {
    defaultMessage: 'Tuesday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Wednesday: {
    defaultMessage: 'Wednesday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Thursday: {
    defaultMessage: 'Thursday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Friday: {
    defaultMessage: 'Friday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
  Saturday: {
    defaultMessage: 'Saturday',
    description: 'Day of week display intended as button text for users to click on preferred days for appointment',
  },
})

export const DAYS_OF_WEEK_SHORT_LABELS = defineMessages({
  Sun: {
    defaultMessage: 'Sun',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Mon: {
    defaultMessage: 'Mon',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Tue: {
    defaultMessage: 'Tue',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Wed: {
    defaultMessage: 'Wed',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Thu: {
    defaultMessage: 'Thu',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Fri: {
    defaultMessage: 'Fri',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
  Sat: {
    defaultMessage: 'Sat',
    description:
      'Abbreviation for day of week intended as button text for users to click on preferred days for appointment',
  },
})

export const TIMES_OF_DAY_LABELS = defineMessages({
  Morning: {
    defaultMessage: 'Morning',
    description: 'Label for time of day',
  },
  Afternoon: {
    defaultMessage: 'Afternoon',
    description: 'Label for time of day',
  },
  Evening: {
    defaultMessage: 'Evening',
    description: 'Label for time of day',
  },
})

export const AVAILABILITY_TIMES_LONG_LABELS = defineMessages({
  MORNING_TIME_LONG: {
    defaultMessage: '7:00 AM - 12:00 PM',
    description: 'Label for time range',
  },
  AFTERNOON_TIME_LONG: {
    defaultMessage: '12:00 - 5:00 PM',
    description: 'Label for time range',
  },
  EVENING_TIME_LONG: {
    defaultMessage: '5:00 - 10:00 PM',
    description: 'Label for time range',
  },
})

export const AVAILABILITY_TIMES_SHORT_LABELS = defineMessages({
  MORNING_TIME_SHORT: {
    defaultMessage: '7 AM - 12 PM',
    description: 'Label for time range',
  },
  AFTERNOON_TIME_SHORT: {
    defaultMessage: '12 - 5 PM',
    description: 'Label for time range',
  },
  EVENING_TIME_SHORT: {
    defaultMessage: '5 - 10 PM',
    description: 'Label for time range',
  },
})

export const CHILD_SEARCH_BUTTON_LABELS = defineMessages({
  DOMESTIC: {
    defaultMessage: 'Find care for my child (0-17)',
    description: 'Text of button that leads to the start of domestic search for care for a child',
  },
  INTL: {
    defaultMessage: 'Find care for my teen',
    description: 'Text of button that leads to the start of international search for care for a child',
  },
})

export const PROVIDER_MODALITY_NOTES = defineMessages({
  LIVE_MESSAGING_INFO: {
    defaultMessage:
      'Offers flexibility of messaging on-the-go, and can be a good alternative to video if finding a private space is challenging',
    description: 'Live messaging modality benefits description',
  },
  LIVE_MESSAGING_OPTION: {
    defaultMessage: 'Option to switch to video after first session',
    description: 'Live messaging modality description of option to switch modalities',
  },
  VIDEO_COACHING_OPTION: {
    defaultMessage: 'Option to switch to messaging after first session, when available',
    description: 'Video modality description of option to switch modalities',
  },
  VIDEO_THERAPY_INFO: {
    defaultMessage: 'More convenient: Meet where you want, when you want',
    description: 'Video modality benefits description',
  },
  VIDEO_THERAPY_MORE_OPTIONS: {
    defaultMessage: 'More options: Even more access to diverse providers and specializations',
    description: 'Video modality provider network benefits description',
  },
})

// Messages orignally from `apps/lyrahealthwebapp/src/features/onboard/about-me/triageWords.ts`
export const TRIAGE_WORDS_MSG: Record<TriageWords, MessageDescriptor> = defineMessages({
  [TriageWords.ACCESS_TO_FOOD_OR_HOUSING]: {
    defaultMessage: 'Access to food or housing',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ACCESS_TO_TRANSPORTATION]: {
    defaultMessage: 'Access to transportation',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ACTS_YOUNGER_THAN_AGE]: {
    defaultMessage: 'Acts younger than age',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.AFFECTING_WORK]: {
    defaultMessage: 'Affecting work',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.AFRAID_OF_NEW_THINGS]: {
    defaultMessage: 'Afraid of new things',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.AGGRESSIVE]: {
    defaultMessage: 'Aggressive',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ANGER]: {
    defaultMessage: 'Anger',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ANXIETY]: {
    defaultMessage: 'Anxiety',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ARGUING_FIGHTING]: {
    defaultMessage: 'Arguing / Fighting',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ASHAMED]: {
    defaultMessage: 'Ashamed',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.AVOIDING_SOCIALIZING]: {
    defaultMessage: 'Avoiding socializing',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BAD_GRADES]: {
    defaultMessage: 'Bad grades',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BEHAVIOR_ISSUES]: {
    defaultMessage: 'Behavior issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BEING_BULLIED_TEASED]: {
    defaultMessage: 'Being bullied/teased',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BINGE_DRINKING]: {
    defaultMessage: 'Binge drinking',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BULLYING_TEASING_OTHERS]: {
    defaultMessage: 'Bullying/teasing others',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BURNED_OUT]: {
    defaultMessage: 'Burned out',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.CHRONIC_PAIN]: {
    defaultMessage: 'Chronic pain',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.COMPULSIVE_BEHAVIORS]: {
    defaultMessage: 'Compulsive behaviors',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DAYDREAMING]: {
    defaultMessage: 'Daydreaming',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DEALING_WITH_DIVORCE]: {
    defaultMessage: 'Dealing with divorce',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DEALING_WITH_MARRIAGE_REMARRIAGE]: {
    defaultMessage: 'Dealing with marriage/remarriage',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DEPRESSED]: {
    defaultMessage: 'Depressed',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DETACHED_NUMB]: {
    defaultMessage: 'Detached/Numb',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DIFFICULTY_CONCENTRATING]: {
    defaultMessage: 'Difficulty concentrating',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DIFFICULTY_CUTTING_BACK]: {
    defaultMessage: 'Difficulty cutting back',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DISCIPLINE_PROBLEMS]: {
    defaultMessage: 'Discipline problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DISTRACTED]: {
    defaultMessage: 'Distracted',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DOWN_IN_THE_DUMPS]: {
    defaultMessage: 'Down in the dumps',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DRINKING_TOO_MUCH_ALCOHOL]: {
    defaultMessage: 'Drinking too much alcohol',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.EMBARRASSED]: {
    defaultMessage: 'Embarrassed',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.EXCESSIVE_WORRY]: {
    defaultMessage: 'Excessive worry',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.EXCITABLE]: {
    defaultMessage: 'Excitable',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.EXHAUSTED]: {
    defaultMessage: 'Exhausted',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FAMILY_CONFLICT]: {
    defaultMessage: 'Family conflict',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.COMMUNITY_SAFETY]: {
    defaultMessage: 'Community safety',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEARFUL]: {
    defaultMessage: 'Fearful',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_CRITICIZED]: {
    defaultMessage: 'Feeling criticized',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_GUILTY]: {
    defaultMessage: 'Feeling guilty',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_HOPELESS]: {
    defaultMessage: 'Feeling hopeless',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_LIKE_A_BAD_PERSON]: {
    defaultMessage: 'Feeling like a bad person',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_LIKE_A_FAILURE]: {
    defaultMessage: 'Feeling like a failure',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_UNAPPRECIATED]: {
    defaultMessage: 'Feeling unappreciated',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FIDGETY]: {
    defaultMessage: 'Fidgety',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FIGHTING_WHEN_DRINKING]: {
    defaultMessage: 'Fighting when drinking',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FINANCIAL_PROBLEMS]: {
    defaultMessage: 'Financial problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FORGETFULNESS]: {
    defaultMessage: 'Forgetfulness',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FREQUENT_CRYING]: {
    defaultMessage: 'Frequent crying',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.GAMBLING]: {
    defaultMessage: 'Gambling',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.HEART_RACING]: {
    defaultMessage: 'Heart racing',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.HYPERACTIVITY]: {
    defaultMessage: 'Hyperactivity',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.IMPULSIVITY]: {
    defaultMessage: 'Impulsivity',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.INTIMACY_PROBLEMS]: {
    defaultMessage: 'Intimacy problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LACKING_JOY]: {
    defaultMessage: 'Lacking joy',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LACKING_RESTRAINT]: {
    defaultMessage: 'Lacking restraint',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LEARNING_DIFFERENCES]: {
    defaultMessage: 'Learning differences',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LESS_INTEREST_IN_FRIENDS_HOBBIES]: {
    defaultMessage: 'Less interest in friends/hobbies',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LESS_INTEREST_IN_SCHOOL]: {
    defaultMessage: 'Less interest in school',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LONELY]: {
    defaultMessage: 'Lonely',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LOW_PRODUCTIVITY]: {
    defaultMessage: 'Low productivity',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LOW_SELF_ESTEEM]: {
    defaultMessage: 'Low self-esteem',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MAJOR_HEALTH_ISSUE]: {
    defaultMessage: 'Chronic or major health issue',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MARITAL_DISTRESS]: {
    defaultMessage: 'Marital distress',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MISSING_SCHOOL]: {
    defaultMessage: 'Missing school',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MISUSING_OPIOIDS]: {
    defaultMessage: 'Misusing opioids',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MISUSING_OTHER_ILLICIT_DRUGS]: {
    defaultMessage: 'Misusing other illicit drugs',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MISUSING_PRESCRIPTION_DRUGS]: {
    defaultMessage: 'Misusing prescription drugs',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NEGATIVE_THOUGHTS]: {
    defaultMessage: 'Negative thoughts',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NEGLECTING_FAMILY]: {
    defaultMessage: 'Neglecting family',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NERVOUS]: {
    defaultMessage: 'Nervous',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NEW_SIBLING]: {
    defaultMessage: 'New sibling',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NOT_COMMUNICATING]: {
    defaultMessage: 'Not communicating',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.OBSESSIVE_THOUGHTS]: {
    defaultMessage: 'Obsessive thoughts',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.OVERWHELMED]: {
    defaultMessage: 'Overwhelmed',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PANIC_ATTACKS]: {
    defaultMessage: 'Panic attacks',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PARENTING_ISSUES]: {
    defaultMessage: 'Parenting issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PHOBIA]: {
    defaultMessage: 'Phobia',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PREGNANCY_NEW_CHILD]: {
    defaultMessage: 'Pregnancy / New child',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RACING_THOUGHTS_SPEECH]: {
    defaultMessage: 'Racing thoughts/speech',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RELATIONSHIP_PROBLEMS]: {
    defaultMessage: 'Relationship problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.REPEATING_WORDS_PHRASES]: {
    defaultMessage: 'Repeating words/phrases',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RESTLESS]: {
    defaultMessage: 'Restless',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RISKY_BEHAVIORS]: {
    defaultMessage: 'Risky behaviors',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SCHOOL_ABSENCES]: {
    defaultMessage: 'School absences',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SELF_HARM]: {
    defaultMessage: 'Self-harm',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SEPARATION_DIVORCE]: {
    defaultMessage: 'Separation / Divorce',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SEXUAL_PROBLEMS]: {
    defaultMessage: 'Sexual problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TAKES_UNNECESSARY_RISKS]: {
    defaultMessage: 'Takes unnecessary risks',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TEEN_PREGNANCY]: {
    defaultMessage: 'Teen pregnancy',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.THOUGHTS_OF_SUICIDE]: {
    defaultMessage: 'Thoughts of suicide',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TRAUMA]: {
    defaultMessage: 'Trauma',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TROUBLE_COPING_WITH_CHANGE]: {
    defaultMessage: 'Trouble coping with change',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TROUBLE_RELAXING]: {
    defaultMessage: 'Trouble relaxing',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TROUBLE_WITH_FRIENDS]: {
    defaultMessage: 'Trouble with friends',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TROUBLE_WITH_TEACHERS]: {
    defaultMessage: 'Trouble with teachers',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.UNUSUALLY_HIGH_ENERGY]: {
    defaultMessage: 'Unusually high energy',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.USING_HEROIN]: {
    defaultMessage: 'Using heroin',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.USING_STREET_DRUGS]: {
    defaultMessage: 'Using street drugs',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORK_CONFLICT]: {
    defaultMessage: 'Work conflict',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.POOR_EYE_CONTACT]: {
    defaultMessage: 'Poor eye contact',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.CANNOT_POINT_TO_THINGS]: {
    defaultMessage: 'Cannot point to things',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.POOR_SOCIAL_SKILLS]: {
    defaultMessage: 'Poor social skills',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.REPETITIVE_PHRASES]: {
    defaultMessage: 'Repetitive phrases',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.REPETITIVE_MOVEMENTS]: {
    defaultMessage: 'Repetitive movements',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.GETS_STUCK_ON_ROUTINES]: {
    defaultMessage: 'Gets stuck on routines',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.UNUSUAL_SKILLS_INTERESTS]: {
    defaultMessage: 'Unusual skills/interests',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SENSORY_SEEKING]: {
    defaultMessage: 'Sensory seeking',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SENSITIVE_TO_NOISE_OR_TOUCH]: {
    defaultMessage: 'Sensitive to noise or touch',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NONVERBAL]: {
    defaultMessage: 'Nonverbal',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LANGUAGE_DELAY]: {
    defaultMessage: 'Language delay',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LEARNING_PROBLEMS]: {
    defaultMessage: 'Learning problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ADD_ADHD]: {
    defaultMessage: 'ADD/ADHD',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORRIES]: {
    defaultMessage: 'Worries',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.INTELLECTUAL_DISABILITY]: {
    defaultMessage: 'Intellectual disability',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NOT_TOILET_TRAINED]: {
    defaultMessage: 'Not toilet trained',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.POOR_COORDINATION]: {
    defaultMessage: 'Poor coordination',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORRIES_A_LOT]: {
    defaultMessage: 'Worries a lot',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.VOCAL_OUTBURSTS_OR_CRYING]: {
    defaultMessage: 'Vocal outbursts or crying',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SEVERE_TANTRUMS]: {
    defaultMessage: 'Severe tantrums',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SENSITIVITY_TO_REJECTION]: {
    defaultMessage: 'Sensitivity to rejection',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SLEEP_PROBLEMS]: {
    defaultMessage: 'Sleep problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SOCIAL_WITHDRAWAL]: {
    defaultMessage: 'Social withdrawal',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.IRRITABILITY_OR_ANGER]: {
    defaultMessage: 'Irritability or anger',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_SAD_UNHAPPY]: {
    defaultMessage: 'Feeling sad, unhappy',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SCHOOL_PROBLEMS]: {
    defaultMessage: 'School problems',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MISUSING_ALCOHOL]: {
    defaultMessage: 'Misusing alcohol',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FREQUENT_PHYSICAL_COMPLAINTS]: {
    defaultMessage: 'Frequent physical complaints',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SADNESS]: {
    defaultMessage: 'Sadness',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.HOPELESSNESS]: {
    defaultMessage: 'Hopelessness',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BODY_IMAGE_ISSUES]: {
    defaultMessage: 'Body image issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LACK_OF_ENJOYMENT]: {
    defaultMessage: 'Lack of enjoyment',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.IRRITABILITY]: {
    defaultMessage: 'Irritability',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TRAUMATIC_MEMORIES]: {
    defaultMessage: 'Traumatic memories',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.GUILT]: {
    defaultMessage: 'Guilt',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FATIGUE]: {
    defaultMessage: 'Fatigue',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.GENDER_IDENTITY_ISSUES]: {
    defaultMessage: 'Gender identity issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SLEEPING_TOO_MUCH]: {
    defaultMessage: 'Sleeping too much',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SLEEPING_TOO_LITTLE]: {
    defaultMessage: 'Sleeping too little',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DRINKING_TOO_MUCH]: {
    defaultMessage: 'Drinking too much',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.USING_DRUGS]: {
    defaultMessage: 'Using drugs',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RELATIONSHIP_ISSUES]: {
    defaultMessage: 'Relationship issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NERVOUSNESS_OR_FEELING_ON_EDGE]: {
    defaultMessage: 'Nervousness or feeling on edge',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DIFFICULTY_SITTING_STILL]: {
    defaultMessage: 'Difficulty sitting still',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SOCIAL_ANXIETY]: {
    defaultMessage: 'Social anxiety',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEAR_OF_PUBLIC_SPEAKING]: {
    defaultMessage: 'Fear of public speaking',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORRYING_TOO_MUCH]: {
    defaultMessage: 'Worrying too much',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TENSION_OR_HEADACHES]: {
    defaultMessage: 'Tension or headaches',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PHOBIAS]: {
    defaultMessage: 'Phobias',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.EMBARRASSMENT]: {
    defaultMessage: 'Embarrassment',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ARGUING_OR_FIGHTING]: {
    defaultMessage: 'Arguing or fighting',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.CRAVINGS_TO_DRINK_OR_USE]: {
    defaultMessage: 'Cravings to drink or use',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PROBLEMS_AT_HOME_OR_WORK]: {
    defaultMessage: 'Problems at home or work',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NEGLECTING_RELATIONSHIPS]: {
    defaultMessage: 'Neglecting relationships',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.COUPLES_ISSUES]: {
    defaultMessage: 'Couples issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_ISOLATED_OR_ALONE]: {
    defaultMessage: 'Feeling isolated or alone',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.DIFFICULTY_SAYING_NO]: {
    defaultMessage: 'Difficulty saying no',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORK_RELATIONSHIPS]: {
    defaultMessage: 'Work relationships',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.IMPOSTER_SYNDROME]: {
    defaultMessage: 'Imposter syndrome',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.NERVOUS_OR_ON_EDGE]: {
    defaultMessage: 'Nervous or on edge',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.PERFECTIONISM]: {
    defaultMessage: 'Perfectionism',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RESTLESSNESS]: {
    defaultMessage: 'Restlessness',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TENSE_MUSCLES]: {
    defaultMessage: 'Tense muscles',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.WORK_CHALLENGES]: {
    defaultMessage: 'Work challenges',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.RACIAL_STRESS]: {
    defaultMessage: 'Racial stress',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FERTILITY_ISSUES]: {
    defaultMessage: 'Fertility issues',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.LONELINESS]: { defaultMessage: 'Loneliness', description: 'Search for care: Symptom or need' },
  [TriageWords.ALCOHOL_AND_SUBSTANCE_USE]: {
    defaultMessage: 'Alcohol and substance use',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.ALCOHOL_SUBSTANCE_USE]: {
    defaultMessage: 'Alcohol or substance use',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FINANCIAL_WORRIES]: {
    defaultMessage: 'Financial worries',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.JOB_CHANGE]: { defaultMessage: 'Job change', description: 'Search for care: Symptom or need' },
  [TriageWords.SERVING_AS_CAREGIVER]: {
    defaultMessage: 'Serving as a caregiver',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SEXUAL_ORIENTATION]: {
    defaultMessage: 'Sexual orientation',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TRAUMATIC_EXPERIENCES]: {
    defaultMessage: 'Traumatic experiences',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.TROUBLE_COMMUNICATING]: {
    defaultMessage: 'Trouble communicating',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.SLEEP_ISSUES]: { defaultMessage: 'Sleep issues', description: 'Search for care: Symptom or need' },
  [TriageWords.GRIEF_DEATH_LOVED_ONE]: {
    defaultMessage: 'Grief/death of a loved one',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.AVOIDANCE_SOCIAL]: {
    defaultMessage: 'Avoidance of social situations',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.BURNOUT]: { defaultMessage: 'Burnout', description: 'Search for care: Symptom or need' },
  [TriageWords.WORTHLESSNESS]: { defaultMessage: 'Worthlessness', description: 'Search for care: Symptom or need' },
  [TriageWords.BODY_IMAGE]: { defaultMessage: 'Body image', description: 'Search for care: Symptom or need' },
  [TriageWords.GENDER_IDENTITY]: { defaultMessage: 'Gender identity', description: 'Search for care: Symptom or need' },
  [TriageWords.PARENTING]: { defaultMessage: 'Parenting', description: 'Search for care: Symptom or need' },
  [TriageWords.FERTILITY]: { defaultMessage: 'Fertility', description: 'Search for care: Symptom or need' },
  [TriageWords.INTIMATE_RELATIONSHIPS]: {
    defaultMessage: 'Intimate relationships',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.MAJOR_HEALTH_CONDITIONS]: {
    defaultMessage: 'Chronic or major health conditions',
    description: 'Search for care: Symptom or need',
  },
  [TriageWords.FEELING_OVERWHELMED]: {
    defaultMessage: 'Feeling overwhelmed',
    description: 'Search for care: Symptom or need',
  },
})

export const MEMBER_PREFERENCE_LABELS = defineMessages({
  BIPOC: {
    defaultMessage: 'BIPOC',
    description: 'Text for indicating BIPOC provider preference',
  },
  BIPOC_FULL: {
    defaultMessage: 'Black, Indigenous, or Person of Color (BIPOC)',
    description: 'Text for indicating BIPOC provider preference',
  },
  'LGBTQIA+': {
    defaultMessage: 'LGBTQIA+',
    description: 'Text for indicating LGBTQIA+ provider preference',
  },
  live_messaging: {
    defaultMessage: 'Live messaging',
    description: 'Text for indicating live messaging modality preference',
  },
  video: {
    defaultMessage: 'By video',
    description: 'Text for indicating video modality preference',
  },
  inPerson: {
    defaultMessage: 'In person',
    description: 'Text for indicating in-person modality preference',
  },
  EMPTY_STATE_DEFAULT: {
    defaultMessage: 'Provider identity',
    description: 'Label text for when no preference have been selected',
  },
  EMPTY_STATE_WITH_MEETING_STYLE_OPTION: {
    defaultMessage: 'Provider identity, meeting setting',
    description: 'Label text for when no preference have been selected',
  },
  EMPTY_STATE_WITH_MEETING_STYLE_OPTION_V2: {
    defaultMessage: 'Provider identity, meeting setting, etc.',
    description: 'Label text for when no preferences have been selected',
  },
  CARE_LANGUAGE: {
    defaultMessage: 'Language',
    description: 'Text for indicating language preference',
  },
  DAY_AND_TIME: {
    defaultMessage: 'Day and time',
    description: 'Text for indicating availability preference',
  },
  // Therapy preference labels
  prefer_video: {
    defaultMessage: 'Prefer video',
    description: 'Text for indicating video modality preference for therapy',
  },
  video_or_inPerson: {
    defaultMessage: 'Video or in-person',
    description: 'Text for indicating neutral preference to video vs in person therapy sessions',
  },
  //Member Preference V2 popover labels
  LGBTQIA_MATCH_IDENTITY: {
    defaultMessage: 'Identifies as LGBTQIA+',
    description: 'Text for indicating provider identifies with LGBTQIA+ clients',
  },
  LGBTQIA_MATCH_EXPERIENCE: {
    defaultMessage: 'Experience with LGBTQIA+ clients',
    description: 'Text for indicating provider has experience with LGBTQIA+ clients',
  },
  EXPERIENCE_VETERAN: {
    defaultMessage: 'Experience with Veterans',
    description: 'Text for indicating provider has experience with veteran clients',
  },
  EXPERIENCE_RELIGION_BUDDHIST: {
    defaultMessage: 'Experience with Buddhist clients',
    description: 'Text for indicating provider has experience with Buddhist clients',
  },
  EXPERIENCE_RELIGION_JEWISH: {
    defaultMessage: 'Experience with Jewish clients',
    description: 'Text for indicating provider has experience with Jewish clients',
  },
  EXPERIENCE_RELIGION_CHRISTIAN: {
    defaultMessage: 'Experience with Christian clients',
    description: 'Text for indicating provider has experience with Christian clients',
  },
  EXPERIENCE_RELIGION_HINDU: {
    defaultMessage: 'Experience with Hindu clients',
    description: 'Text for indicating provider has experience with Hindu clients',
  },
  EXPERIENCE_RELIGION_MORMON: {
    defaultMessage: 'Experience with Mormon clients',
    description: 'Text for indicating provider has experience with Mormon clients',
  },
  EXPERIENCE_RELIGION_MUSLIM: {
    defaultMessage: 'Experience with Muslim clients',
    description: 'Text for indicating provider has experience with Muslim clients',
  },
  EXPERIENCE_RELIGION_NON_RELIGIOUS: {
    defaultMessage: 'Experience with non-religious clients',
    description: 'Text for indicating provider has experience with non-religious clients',
  },
  EXPERIENCE_RELIGION_CLIENTS_OF_FAITH: {
    defaultMessage: 'Experience with clients of faith',
    description: 'Text for indicating provider has experience with clients of faith',
  },
  // Member Preference V2 header titles
  ethnicities: {
    defaultMessage: 'Race/Ethnicity',
    description: 'Header text for section that displays available options for ethnicity information',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'Header text for section that displays available options for gender information',
  },
  religions: {
    defaultMessage: 'Religion',
    description: 'Header text for section that displays available options for religion information',
  },
  isExperiencedWithVeterans: {
    defaultMessage: 'Veterans',
    description: 'Header text for section that displays available options for veterans information',
  },
  meetingSetting: {
    defaultMessage: 'Meeting setting',
    description: 'Header text for section that displays available options for meeting setting',
  },
  // Race/ethnicity options
  [MEMBER_PREFERENCE_IDS.ASIAN]: {
    defaultMessage: 'Asian',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.BLACK_OR_AFRICAN_AMERICAN]: {
    defaultMessage: 'Black or African American',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.HISPANIC_OR_LATINX]: {
    defaultMessage: 'Hispanic or Latinx',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE]: {
    defaultMessage: 'Native American or Alaska Native',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER]: {
    defaultMessage: 'Native Hawaiian or Pacific Islander',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.WHITE]: {
    defaultMessage: 'White',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  [MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN]: {
    defaultMessage: 'Middle Eastern or North African',
    description: 'Option text for user’s preference for race/ethnicity',
  },
  // Gender options
  [MEMBER_PREFERENCE_IDS.MAN]: {
    defaultMessage: 'Man',
    description: 'Option text for user’s preference for gender',
  },
  [MEMBER_PREFERENCE_IDS.WOMAN]: {
    defaultMessage: 'Woman',
    description: 'Option text for user’s preference for gender',
  },
  [MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON]: {
    defaultMessage: 'Non-binary person',
    description: 'Option text for user’s preference for gender',
  },
  // Religion options
  [MEMBER_PREFERENCE_IDS.BUDDHIST]: {
    defaultMessage: 'Buddhist',
    description: 'Option text for user’s preference for religion',
  },
  [MEMBER_PREFERENCE_IDS.CHRISTIAN]: {
    defaultMessage: 'Christian',
    description: 'Option text for user’s preference for religion',
  },
  [MEMBER_PREFERENCE_IDS.HINDU]: {
    defaultMessage: 'Hindu',
    description: 'Option text for user’s preference for religion',
  },
  [MEMBER_PREFERENCE_IDS.JEWISH]: {
    defaultMessage: 'Jewish',
    description: 'Option text for user’s preference for religion',
  },
  [MEMBER_PREFERENCE_IDS.MUSLIM]: {
    defaultMessage: 'Muslim',
    description: 'Option text for user’s preference for religion',
  },
  [MEMBER_PREFERENCE_IDS.NON_RELIGIOUS_ATHEIST_AGNOSTIC]: {
    defaultMessage: 'Non-religious / atheist / agnostic',
    description: 'Option text for user’s preference for religion',
  },
  // Veterans options
  [MEMBER_PREFERENCE_IDS.EXPERIENCE_WORKING_WITH_VETERANS]: {
    defaultMessage: 'Experience working with clients who are veterans',
    description: 'Option text for user’s preference for veterans',
  },
  // Meeting setting options
  [MEMBER_PREFERENCE_IDS.HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS]: {
    defaultMessage: 'I prefer to attend sessions in person',
    description: 'Checkbox text to confirm preference for in person providers',
  },
  Video: {
    defaultMessage: 'Video',
    description: 'Option text for user’s preference for meeting setting',
  },
  prefersInPerson: {
    defaultMessage: 'Prefers in-person',
    description: 'Subtitle text for user’s preference for meeting setting',
  },
  // Availability options and labels
  [MEMBER_PREFERENCE_IDS.FLEXIBLE]: {
    defaultMessage: 'I’m flexible',
    description: 'Option text for user’s preference for availability',
  },
  [MEMBER_PREFERENCE_IDS.DAY_AND_TIME]: {
    defaultMessage: 'Specific day and time',
    description: 'Option text for user’s preference for availability',
  },
  DaysAndTimes: {
    defaultMessage: 'Days and times',
    description: 'Subtitle text for user’s preference for desired availabilities',
  },
  DayAndTime: {
    defaultMessage: 'Day and time',
    description: 'Subtitle text for user’s preference for single desired availability',
  },
  DaysAndTimesWithMatchCount: {
    defaultMessage:
      'Days and times: {matchCount} {matchCount, select, 1 {appointment matches} other {appointments match}} your availability',
    description: 'Subtitle text for user’s preference for desired availabilities',
  },
  DayAndTimeWithMatchCount: {
    defaultMessage:
      'Day and time: {matchCount} {matchCount, select, 1 {appointment matches} other {appointments match}} your availability',
    description: 'Subtitle text for user’s preference for single desired availability',
  },
})

export const ICAS_TREATMENT_REC_HEADERS = defineMessages({
  ADULT_MAIN: {
    defaultMessage: 'Call us to get help finding a counsellor',
    description: 'Main Header title for treatment recommendation',
  },
  ADULT_SMALL: {
    defaultMessage: 'Your care options',
    description: 'Small Header title for treatment recommendation',
  },
  CHILD_MAIN: {
    defaultMessage: 'Let’s find the right counsellor for your family',
    description: 'Main Header title for treatment recommendation',
  },
  CHILD_SMALL: {
    defaultMessage: 'Getting care for your teen',
    description: 'Small Header title for treatment recommendation',
  },
})

export const PRIMARY_NEEDS_WORDS_ADULT_MSG = defineMessages({
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: {
    defaultMessage: 'Depression',
    description: 'Search for care: Symptom or need - Depression / Sadness',
  },
  [PrimaryNeedsWords.ANXIETY]: { defaultMessage: 'Anxiety', description: 'Search for care: Symptom or need' },
  [PrimaryNeedsWords.STRESS]: { defaultMessage: 'Stress', description: 'Search for care: Symptom or need' },
  [PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY]: {
    defaultMessage: 'Relationship and family issues',
    description: 'Search for care: Symptom or need - Relationship and family issues, conflicts with loved ones',
  },
  [PrimaryNeedsWords.ALCOHOL_DRUG_USE]: {
    defaultMessage: 'Alcohol or substance use',
    description: 'Search for care: Symptom or need - Difficulty in cutting back on alcohol or substances',
  },
})

// Messages copied from apps/lyrahealthwebapp/src/features/onboard/about-me/triagePrimaryNeeds.ts to use existing translations.
export const PRIMARY_NEEDS_WORDS_CHILD_MSG = defineMessages({
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: {
    defaultMessage: 'Sadness',
    description: "Search for care: Child's primary need: Sadness",
  },
  [PrimaryNeedsWords.ANXIETY]: {
    defaultMessage: 'Anxiety',
    description: "Search for care: Child's primary need: Anxiety",
  },
  [PrimaryNeedsWords.STRESS]: {
    defaultMessage: 'Stress',
    description: "Search for care: Child's primary need: Stress",
  },
  [PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY]: {
    defaultMessage: 'Relationship and family issues',
    description: "Search for care: Child's primary need: Relationship and family issues",
  },
  [PrimaryNeedsWords.SCHOOL]: {
    defaultMessage: 'Social problems at school',
    description: "Search for care: Child's primary need: Social problems",
  },
  [PrimaryNeedsWords.ADHD]: {
    defaultMessage: 'Hyperactivity or problems focusing',
    description: "Search for care: Child's primary need: Hyperactivity",
  },
  [PrimaryNeedsWords.ALCOHOL_DRUG_USE]: {
    defaultMessage: 'Drinking or using',
    description: "Search for care: Child's primary need: Alcohol or drug use",
  },
  [PrimaryNeedsWords.AUTISM_SPECTRUM]: {
    defaultMessage: 'Signs of autism',
    description: "Search for care: Child's primary need: Autism",
  },
  [PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS]: {
    defaultMessage: 'Developmental delays',
    description: "Search for care: Child's primary need: Developmental delay",
  },
  // TODO: The keys in the block below are kept for backward compatibility with older triage screeners
  // metadata versions which use them. Can be cleaned up in Octobber 2024.
  // ========= START BLOCK =========
  hyperactivityOrProblemsFocusing: {
    defaultMessage: 'Hyperactivity or problems focusing',
    description: "Search for care: Child's primary need: Hyperactivity",
  },
  drinkingOrUsing: {
    defaultMessage: 'Drinking or using',
    description: "Search for care: Child's primary need: Alcohol or drug use",
  },
  signsOfAutism: {
    defaultMessage: 'Signs of autism',
    description: "Search for care: Child's primary need: Autism",
  },
  developmentalDelays: {
    defaultMessage: 'Developmental delays',
    description: "Search for care: Child's primary need: Developmental delay",
  },
  // ========= END BLOCK =========
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS]: {
    defaultMessage: 'Behavior problems',
    description: "Search for care: Child's primary need: Behavior problems",
  },
  [TriageScreenerValue.SOMETHING_ELSE]: {
    defaultMessage: 'Something else',
    description: "Search for care: Child's primary need: Other, not listed",
  },
  [TriageScreenerValue.IM_NOT_SURE]: {
    defaultMessage: 'I’m not sure and need help',
    description: "Search for care: Child's primary need: I'm not sure and need help",
  },
})

// https://github.com/LyraHealth/Arbiter/blob/main/src/main/resources/triage/optionalWeightedTriageWordGrid.txt
/**
 * Because Arbiter/PSS keeps it's own list of primary needs, triage terms and provider conditions and they always send us the same in all lowercase
 * we need mappings to make sure we can re-map to our translations
 *  */

// Mappings for all keys in PRIMARY_NEEDS_WORDS_ADULT_MSG and PRIMARY_NEEDS_WORDS_CHILD_MSG
export const PRIMARY_NEEDS_MAPPING_TO_TRANSLATION_MSG = {
  ...Object.fromEntries(
    Object.values(PrimaryNeedsWords).map((primaryNeed) => [primaryNeed.toLowerCase(), primaryNeed]),
  ),
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS.toLowerCase()]: PrimaryNeedsWords.BEHAVIOR_PROBLEMS,
  [TriageScreenerValue.SOMETHING_ELSE.toLowerCase()]: TriageScreenerValue.SOMETHING_ELSE,
  [TriageScreenerValue.IM_NOT_SURE.toLowerCase()]: TriageScreenerValue.IM_NOT_SURE,
}

const DEPRECATED_ARBITER_TRIAGE_WORDS = {
  anxious: TriageWords.ANXIETY,
  'bullying/teasing': TriageWords.BULLYING_TEASING_OTHERS,
  'death of a loved one': TriageWords.GRIEF_DEATH_LOVED_ONE,
  'fear of social situations': TriageWords.AVOIDING_SOCIALIZING,
  'feeling burned out': TriageWords.BURNOUT,
  'feeling worthless': TriageWords.WORTHLESSNESS,
  'low grades': TriageWords.BAD_GRADES,
  'major health issue': TriageWords.MAJOR_HEALTH_ISSUE,
}

// Mappings for all keys in TRIAGE_WORDS_MSG
export const TRIAGE_WORDS_MAPPING_TO_TRANSLATION_MSG = {
  ...DEPRECATED_ARBITER_TRIAGE_WORDS,
  ...Object.fromEntries(Object.values(TriageWords).map((triageWord) => [triageWord.toLowerCase(), triageWord])),
}

// Deprecated but including for backwards compatitibility
const DEPRECATED_PROVIDER_CONDITIONS = {
  'adoption and reunion issues': 'Adoption and Reunion Issues',
  'chronic illness and medical issues': 'Chronic Illness and Medical Issues',
  'domestic violence and abuse': 'Domestic Violence and Abuse',
  'military and veterans issues': 'Military and Veterans Issues',
  'obsessive-compulsive disorder (ocd)': 'Obsessive-Compulsive Disorder (OCD)',
  'pregnancy, prenatal and postpartum issues': 'Pregnancy, Prenatal and Postpartum Issues',
  'abortion and post abortion issues': 'Abortion and Post Abortion Issues',
  'acute stress disorder (asd)': 'Acute Stress Disorder (ASD)',
  'adult children of alcoholics': 'Adult Children of Alcoholics',
  'aging and geriatric issues': 'Aging and Geriatric Issues',
  'body dysmorphic disorder (bdd)': 'Body Dysmorphic Disorder (BDD)',
  'childhood-onset bipolar disorder': 'Childhood-Onset Bipolar Disorder',
  'dissociative, conversion, and factitious disorders': 'Dissociative, Conversion, and Factitious Disorders',
  'dissociative conversion and factitious disorders': 'Dissociative Conversion and Factitious Disorders',
  'dysthymia and cyclothymia (mood disorders)': 'Dysthymia and Cyclothymia (Mood Disorders)',
  'family of origin issues': 'Family of Origin Issues',
  'mood and adjustment disorder': 'Mood and Adjustment Disorder',
  'mood disorder due to a general medical condition': 'Mood Disorder Due to a General Medical Condition',
  'narcolepsy and cataplexy': 'Narcolepsy and Cataplexy',
  'obsessive-compulsive personality disorder (ocpd)': 'Obsessive-Compulsive Personality Disorder (OCPD)',
  'physical abuse of adult': 'Physical Abuse of Adult',
  'schizoid and schizotypal personality disorder': 'Schizoid and Schizotypal Personality Disorder',
  'self-esteem': 'Self-Esteem',
  'sleep-walking': 'Sleep-Walking',
  'speech language and learning disorders': 'Speech Language and Learning Disorders',
  'suspiciousness and paranoia': 'Suspiciousness and Paranoia',
}
// Can be deleted once moved over to Picked for you v3
// Mismatched Arbiter/PSS provider conditions with mixed case strings (in the order they appear in PROVIDER_CONDITIONS)
// This is used in for picked for you v2 - we try to use find a mapping here first(if it's a mixed case) then use the startCase
// Unfortunately no enum for all keys in PROVIDER_CONDITIONS so this was a manual mapping of lower case mixed strings to their exact value
export const PROVIDER_CONDITIONS_MAPPING_TO_TRANSLATION_MSG = {
  ...DEPRECATED_PROVIDER_CONDITIONS,
  'trauma and ptsd': 'Trauma and PTSD',
  'self-harm': 'Self-Harm',
  'attention deficit hyperactivity disorder (adhd)': 'Attention Deficit Hyperactivity Disorder (ADHD)',
  'obsessive-compulsive disorder': 'Obsessive-Compulsive Disorder',
  'breakup, separation, or divorce': 'Breakup, Separation, or Divorce',
  'child or adolescent issues': 'Child or Adolescent Issues',
  'domestic or intimate partner violence': 'Domestic or Intimate Partner Violence',
  'sexual assault and abuse': 'Sexual Assault and Abuse',
  'chronic illness or medical issues': 'Chronic Illness or Medical Issues',
  'pregnancy, prenatal, and postpartum challenges': 'Pregnancy, Prenatal, and Postpartum Challenges',
  'learning disabilities/differences': 'Learning Disabilities/Differences',
  'digital/gaming addiction': 'Digital/Gaming Addiction',
  'pregnancy prenatal and postpartum issues': 'Pregnancy Prenatal and Postpartum Issues',
  'sex/pornography addiction': 'Sex/Pornography Addiction',
  "tourette's/tic disorders": "Tourette's/Tic Disorders",
  'none of the above': 'None of the Above',
  'chronic impulsivity and addictions': 'Chronic Impulsivity and Addictions',
  'first-responders': 'First-Responders',
  'active military/veterans': 'Active Military/Veterans',
  'marital / premarital/partnering': 'Marital / Premarital/Partnering',
  'sexual behavioral health/sexual satisfaction': 'Sexual Behavioral Health/Sexual Satisfaction',
}

export const PRIMARY_AND_TRIAGE_WORDS_MAPPINGS_TO_TRANSLATION_MSG = {
  ...PRIMARY_NEEDS_MAPPING_TO_TRANSLATION_MSG,
  ...TRIAGE_WORDS_MAPPING_TO_TRANSLATION_MSG,
}
