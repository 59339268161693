import { MessageDescriptor } from 'react-intl'

import { getProviderCancellationMessages } from './messages'
import { ProviderTypeAbbreviated } from '../providers/constants'

export const getBookingCanellationConsentMessage = ({
  isHPIRequiredForAppointmentBooking,
  cancellationLimit,
  providerType,
  sessionLimit,
  cancellationsIncludedInSessionLimits,
}: {
  isHPIRequiredForAppointmentBooking: boolean
  cancellationLimit: string
  providerType?: ProviderTypeAbbreviated
  sessionLimit?: number
  cancellationsIncludedInSessionLimits: boolean
}): MessageDescriptor => {
  const PROVIDER_MESSAGES = getProviderCancellationMessages(providerType)

  if (isHPIRequiredForAppointmentBooking || !cancellationLimit || cancellationLimit === '0') {
    return PROVIDER_MESSAGES.NON_DEDUCTIBLE_SESSIONS.NO_INFRACTIONS_COVERED_OR_HPI
  } else if (parseInt(cancellationLimit) === (sessionLimit || 0) && cancellationsIncludedInSessionLimits) {
    return PROVIDER_MESSAGES.DEDUCTIBLE_SESSIONS.ALL_INFRACTIONS_COVERED
  }

  if (cancellationLimit === '1') {
    if (cancellationsIncludedInSessionLimits) {
      return PROVIDER_MESSAGES.DEDUCTIBLE_SESSIONS.ONLY_FIRST_INFRACTION_COVERED
    } else {
      return PROVIDER_MESSAGES.NON_DEDUCTIBLE_SESSIONS.ONLY_FIRST_INFRACTION_COVERED
    }
    // cancellationLimit > 1
  } else {
    if (cancellationsIncludedInSessionLimits) {
      return PROVIDER_MESSAGES.DEDUCTIBLE_SESSIONS.MULTIPLE_INFRACTIONS_COVERED
    } else {
      return PROVIDER_MESSAGES.NON_DEDUCTIBLE_SESSIONS.MULTIPLE_INFRACTIONS_COVERED
    }
  }
}
