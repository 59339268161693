import React, { FunctionComponent, useContext } from 'react'
import { defineMessage, defineMessages, useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { isUnlimitedSessionLimit, PATHWAYS_TYPE, SessionLimitByProgram } from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../../../atoms'
import {
  CareNavigatorValuePropSpotIllustration,
  IntelligentMatchingValuePropSpotIllustration,
  LyraSelectCareSessionIllustration,
  LyraSelectPersonalizedCareIllustration,
  LyraSelectSelfCareValueSpotIllustration,
  SelfCareValuePropSpotIllustration,
  SessionsValuePropSpotIllustration,
} from '../../../atoms/illustrations'
import { MembershipSummary } from '../../../molecules'
import { BodyTextSize } from '../../../styles'
import { CARE_OFFERING_DESCRIPTION_TYPE, ProductTypeContext, ThemeType } from '../../../utils'

export interface LyraMembershipSummaryProps {
  isMobileAppDisabled?: boolean
  isProgramLevelSessionLimitEnabled?: boolean
  customerDisplayName?: string
  numberOfSessions?: string
  numberOfSessionsByProgram?: SessionLimitByProgram
  careOfferingDescriptionType?: CARE_OFFERING_DESCRIPTION_TYPE
  isEssentialsDisabled?: boolean
  isDependentDisabled?: boolean
  language?: string
  pathwayType?: PATHWAYS_TYPE
  backgroundColor?: string
}

enum LyraMemberOfferings {
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_FAMILY = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_FAMILY',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_FAMILY = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_FAMILY',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_HPI = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_HPI',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI',
  ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI_CHILDREN_DISABLED = 'ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI_CHILDREN_DISABLED',
  SELF_GUIDED_WELLNESS_RESOURCES = 'SELF_GUIDED_WELLNESS_RESOURCES',
  DEDICATED_SUPPORT = 'DEDICATED_SUPPORT',
  CARE_MATCHED_TO_YOU = 'CARE_MATCHED_TO_YOU',
  LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES = 'LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES',
  LYRA_SELECT_PERSONALIZED_CARE = 'LYRA_SELECT_PERSONALIZED_CARE',
  LYRA_SELECT_DEDICATED_SUPPORT = 'LYRA_SELECT_DEDICATED_SUPPORT',
  LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS = 'LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS',
}

const lyraMemberOfferingsLabels = defineMessages({
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS]: {
    defaultMessage: '1-on-1 mental health sessions',
    description: 'Label indicating members of Lyra will receive personal mental health sessions',
  },
  [LyraMemberOfferings.SELF_GUIDED_WELLNESS_RESOURCES]: {
    defaultMessage: 'Self-guided wellness resources',
    description: 'Label indicating members of Lyra will receive access to self guided mental health resources',
  },
  [LyraMemberOfferings.DEDICATED_SUPPORT]: {
    defaultMessage: 'Dedicated support, anytime',
    description: 'Label indicating members of Lyra will have access to personal support',
  },
  [LyraMemberOfferings.CARE_MATCHED_TO_YOU]: {
    defaultMessage: 'Care matched to you',
    description: 'Label indicating members of Lyra will be matched to providers personalized to their needs',
  },
  [LyraMemberOfferings.LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES]: {
    defaultMessage: 'On-demand, self-care resources',
    description:
      'Lyra select label indicating members of Lyra will receive access to self guided mental health resources',
  },
  [LyraMemberOfferings.LYRA_SELECT_PERSONALIZED_CARE]: {
    defaultMessage: 'Personalized care',
    description:
      'Lyra select label indicating members of Lyra will be matched to providers personalized to their needs',
  },
  [LyraMemberOfferings.LYRA_SELECT_DEDICATED_SUPPORT]: {
    defaultMessage: 'Get in touch, anytime',
    description: 'Lyra select label indicating members of Lyra will have access to personal support',
  },
  [LyraMemberOfferings.LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS]: {
    defaultMessage: '1-on-1 care sessions',
    description: 'Lyra select label indicating members of Lyra will receive personal mental health sessions',
  },
})

const lyraMemberOfferingsDescriptions = defineMessages({
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS]: {
    defaultMessage: 'Get personalized care when you need support.',
    description: 'A description indicating that members of Lyra will receive mental health sessions',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_FAMILY]: {
    defaultMessage: 'Get personalized care when you need support—for yourself and your family.',
    description: 'A description indicating that members of Lyra and their families will receive mental health sessions',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP]: {
    defaultMessage:
      '<highlight>{showProgramLevelSessionText, select, true {{numberOfTherapySessions} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{numberOfCoachingSessions}}} coaching sessions per year} other {{numberOfSessions} included sessions per year}}</highlight> when you need support.',
    description:
      'A description indicating that members of Lyra will receive a certain amount of mental health sessions',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_FAMILY]: {
    defaultMessage:
      '<highlight>{showProgramLevelSessionText, select, true {{numberOfTherapySessions} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{numberOfCoachingSessions}}} coaching sessions per year} other {{numberOfSessions} included sessions per year}}</highlight> when you need support—for yourself and your family.',
    description:
      'A description indicating that members of Lyra and their families will receive a certain amount of mental health sessions',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_HPI]: {
    defaultMessage:
      '<highlight>{showProgramLevelSessionText, select, true {{numberOfTherapySessions} therapy and {isUnlimitedCoaching, select, true {unlimited} other {{numberOfCoachingSessions}}} coaching sessions per year} other {{numberOfSessions} included sessions per year}}</highlight>, then check with your health plan for additional coverage.',
    description:
      'A description indicating that members of Lyra will receive a certain amount of mental health sessions, then additional coverage through a health plan',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI]: {
    defaultMessage: 'Get personalized care through an eligible health plan for you, your partner, or your children.',
    description:
      'A description indicating that members of Lyra, their partner, and children will receive mental health sessions through their health plan coverage',
  },
  [LyraMemberOfferings.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI_CHILDREN_DISABLED]: {
    defaultMessage: 'Get personalized care through an eligible health plan for you and your loved ones.',
    description:
      'A description indicating that members of Lyra will receive mental health sessions through their health plan coverage',
  },
  [LyraMemberOfferings.SELF_GUIDED_WELLNESS_RESOURCES]: {
    defaultMessage: 'Browse meditations, courses, articles and more at your own pace.',
    description: 'A description describing the kinds of self guided resources available to the member',
  },
  [LyraMemberOfferings.DEDICATED_SUPPORT]: {
    defaultMessage: 'Reach out, day or night, for personal guidance that’s private and confidential.',
    description: 'A description indicating user can reach out at any time for private and personal support',
  },
  [LyraMemberOfferings.CARE_MATCHED_TO_YOU]: {
    defaultMessage: 'Providers intelligently matched to your needs and background.',
    description: 'A description describing how providers will be personally matched based on the needs of the member',
  },
  [LyraMemberOfferings.LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES]: {
    defaultMessage: 'Browse meditations, exercises, and articles to help with stress, sleep, burnout, and more.',
    description: 'Lyra select description describing the kinds of self guided resources available to the member',
  },
  [LyraMemberOfferings.LYRA_SELECT_PERSONALIZED_CARE]: {
    defaultMessage: 'Tell us about your goals, and we’ll provide resources to meet your needs.',
    description:
      "Lyra select description describing how providers will be personally matched based on the needs of the member'",
  },
  [LyraMemberOfferings.LYRA_SELECT_DEDICATED_SUPPORT]: {
    defaultMessage: 'Reach out, day or night, for personal guidance that’s private and confidential.',
    description: 'Lyra select description indicating user can reach out at any time for private and personal support',
  },
  [LyraMemberOfferings.LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS]: {
    defaultMessage: 'When you need more support, you get 6 coaching and 6 therapy sessions—fully covered.',
    description: 'Lyra select description indicating that members of Lyra will receive mental health sessions',
  },
})

const LYRA_MEMBERSHIP_SECTION_TITLE = defineMessage({
  defaultMessage: `What you get with Lyra`,
  description: 'Title for the lyra membership offerings section.',
})

export const LyraMembershipSummary: FunctionComponent<LyraMembershipSummaryProps> = ({
  customerDisplayName,
  numberOfSessions,
  careOfferingDescriptionType,
  isProgramLevelSessionLimitEnabled,
  numberOfSessionsByProgram,
  isEssentialsDisabled,
  isDependentDisabled,
  backgroundColor,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme() as ThemeType
  const { isMobileSized } = breakpoints
  const { isLyraSelect } = useContext(ProductTypeContext)
  const iconSize = isMobileSized ? 64 : 96

  const getMemberCareOfferingDescription = (careOfferingDescriptionType?: CARE_OFFERING_DESCRIPTION_TYPE) => {
    let selectedDescription
    switch (careOfferingDescriptionType) {
      case CARE_OFFERING_DESCRIPTION_TYPE.EAP_ONLY_DEPENDENT_DISABLED:
        selectedDescription = lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP
        break
      case CARE_OFFERING_DESCRIPTION_TYPE.EAP_AND_HPI:
        selectedDescription = lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_HPI
        break
      case CARE_OFFERING_DESCRIPTION_TYPE.EAP_ONLY:
        selectedDescription = lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_EAP_FAMILY
        break
      case CARE_OFFERING_DESCRIPTION_TYPE.HPI_ONLY:
        selectedDescription = lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI
        break
      case CARE_OFFERING_DESCRIPTION_TYPE.HPI_ONLY_CHILDREN_DISABLED:
        selectedDescription = lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_HPI_CHILDREN_DISABLED
        break
      default:
        selectedDescription = isDependentDisabled
          ? lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS
          : lyraMemberOfferingsDescriptions.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS_FAMILY
        break
    }

    return {
      text: formatMessage(selectedDescription, {
        customerDisplayName,
        numberOfSessions,
        showProgramLevelSessionText:
          isProgramLevelSessionLimitEnabled &&
          !!numberOfSessionsByProgram?.therapy &&
          !!numberOfSessionsByProgram?.coaching,
        numberOfTherapySessions: numberOfSessionsByProgram?.therapy,
        numberOfCoachingSessions: numberOfSessionsByProgram?.coaching,
        isUnlimitedCoaching: isUnlimitedSessionLimit(numberOfSessionsByProgram?.coaching ?? 0),
        highlight: (chunks: string) => (
          <BodyText
            color={colors.textActive}
            text={chunks}
            size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT}
          />
        ),
      }),
      testID: careOfferingDescriptionType
        ? `careSection-description-${careOfferingDescriptionType}`
        : 'careSection-description-default',
    }
  }

  const memberOfferings = [
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels.ONE_ON_ONE_MENTAL_HEALTH_SESSIONS),
        testID: 'careSection-label',
      },
      description: getMemberCareOfferingDescription(careOfferingDescriptionType),
      icon: <SessionsValuePropSpotIllustration size={iconSize} />,
      itemTestID: 'careSection',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels.SELF_GUIDED_WELLNESS_RESOURCES),
        testID: 'selfGuidedResources-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.SELF_GUIDED_WELLNESS_RESOURCES),
        testID: 'selfGuidedResources-description',
      },
      icon: <SelfCareValuePropSpotIllustration size={iconSize} />,
      hide: isEssentialsDisabled,
      itemTestID: 'selfGuidedResources',
    },
    {
      label: { text: formatMessage(lyraMemberOfferingsLabels.DEDICATED_SUPPORT), testID: 'dedicatedSupport-label' },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.DEDICATED_SUPPORT),
        testID: 'dedicatedSupport-description',
      },
      icon: <CareNavigatorValuePropSpotIllustration size={iconSize} />,
      itemTestID: 'dedicatedSupport',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels.CARE_MATCHED_TO_YOU),
        testID: 'careMatchedToYouSection-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.CARE_MATCHED_TO_YOU),
        testID: 'careMatchedToYouSection-description',
      },
      icon: <IntelligentMatchingValuePropSpotIllustration size={iconSize} />,
      itemTestID: 'careMatchedToYouSection',
    },
  ]

  const lyraSelectMembershipOffering = [
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels[LyraMemberOfferings.LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES]),
        testID: 'lyraSelect-selfGuidedResources-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.LYRA_SELECT_SELF_GUIDED_WELLNESS_RESOURCES),
        testID: 'lyraSelect-selfGuidedResources-description',
      },
      icon: <LyraSelectSelfCareValueSpotIllustration size={iconSize} />,
      hide: isEssentialsDisabled,
      itemTestID: 'lyraSelect-selfGuidedResourcesSection',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels[LyraMemberOfferings.LYRA_SELECT_PERSONALIZED_CARE]),
        testID: 'lyraSelect-personalizedCare-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions[LyraMemberOfferings.LYRA_SELECT_PERSONALIZED_CARE]),
        testID: 'lyraSelect-personalizedCare-description',
      },
      icon: <LyraSelectPersonalizedCareIllustration size={iconSize} />,
      itemTestID: 'lyraSelect-personalizedCareSection',
    },
    {
      label: {
        text: formatMessage(
          lyraMemberOfferingsLabels[LyraMemberOfferings.LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS],
        ),
        testID: 'lyraSelect-careSection-label',
      },
      description: {
        text: formatMessage(
          lyraMemberOfferingsDescriptions[LyraMemberOfferings.LYRA_SELECT_ONE_ON_ONE_MENTAL_HEALTH_SESSIONS],
        ),
        testID: 'lyraSelect-careSection-description',
      },
      icon: <LyraSelectCareSessionIllustration size={iconSize} />,
      itemTestID: 'lyraSelect-careSectionSection',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels[LyraMemberOfferings.LYRA_SELECT_DEDICATED_SUPPORT]),
        testID: 'lyraSelect-dedicatedSupport-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions[LyraMemberOfferings.LYRA_SELECT_DEDICATED_SUPPORT]),
        testID: 'lyraSelect-dedicatedSupport-description',
      },
      icon: <CareNavigatorValuePropSpotIllustration size={iconSize} />,
      itemTestID: 'lyraSelect-dedicatedSupportSection',
    },
  ]
  return (
    <MembershipSummary
      title={formatMessage(LYRA_MEMBERSHIP_SECTION_TITLE)}
      memberOfferings={isLyraSelect ? lyraSelectMembershipOffering : memberOfferings}
      backgroundColor={backgroundColor}
    />
  )
}
