import React, { FunctionComponent } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { isEmpty, noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CostMessagingModalContent,
  CostMessagingModalCoverageInfo,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Link } from '../../atoms'
import { BackButtonV2 } from '../../atoms/backButton/BackButtonV2'
import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { IS_WEB } from '../../constants'
import { CoverageMessagingLink } from '../../molecules/coverageMessagingLink/CoverageMessagingLink'
import { IntegratedBenefitsItemProps } from '../../molecules/integratedBenefitsItem/IntegratedBenefitsItem'
import { IntegratedBenefitsLabeledItemGroup } from '../../organisms/integratedBenefitsLabeledItemGroup/IntegratedBenefitsLabeledItemGroup'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface IntegratedBenefitsPageProps {
  recommendedBenefitsItems?: IntegratedBenefitsItemProps[]
  moreBenefitsItems?: IntegratedBenefitsItemProps[]
  lyraBenefitsItems?: IntegratedBenefitsItemProps[]
  additionalCareBenefitsItems?: IntegratedBenefitsItemProps[]
  onPressBackButton?: () => any
  onCareNavigatorPress: () => void
  coverageModalContents?: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  widerBenefitsItemTitleContainer?: boolean
  customerName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}

const BackgroundContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing, breakpoints } }) => ({
  backgroundColor: colors.backgroundSecondary,
  flexDirection: 'row',
  justifyContent: 'center',
  // Mobile web need extra padding since navbar is handled differently
  ...(IS_WEB && breakpoints.isMobileSized && { paddingTop: spacing['24px'] }),
}))

const LayoutContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthDesktop, isMinWidthTablet },
      spacing,
    },
  }) => ({
    paddingTop: isMinWidthTablet ? spacing['32px'] : 0,
    justifyContent: 'center',
    paddingHorizontal: isMinWidthTablet ? spacing['32px'] : spacing['16px'],
    flexDirection: isMinWidthDesktop ? 'row' : 'column',
    maxWidth: '1444px',
    flexGrow: 1,
    flexShrink: 1,
  }),
)

const MainFlexItem = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthDesktop },
    },
  }) => ({
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: isMinWidthDesktop ? '900px' : 'auto',
  }),
)

const HeadlineContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
  }) => ({
    marginTop: spacing['16px'],
    marginBottom: isMinWidthTablet ? spacing['64px'] : spacing['32px'],
    flexShrink: 1,
  }),
)

// side columns/top bottom row
const PeripheralFlexItem = styled.View({
  flexGrow: 1,
  flexBasis: '64px',
  maxWidth: '215px',
})

const CareNavBottomSection = styled(BodyText)({
  marginBottom: '60px',
})

const BenefitsGroupContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
  }) => ({
    marginBottom: isMinWidthTablet ? spacing['64px'] : spacing['32px'],
  }),
)

export const benefitsHeaderText = (
  <FormattedMessage
    defaultMessage='Explore your benefits'
    description='Suggestion for the user to view their benefits on this page'
  />
)

export const benefitsSectionLabels = defineMessages({
  LYRA_OFFERINGS: {
    defaultMessage: 'Lyra offerings',
    description: 'Label for a group of benefits offered by Lyra',
  },
  ADDITIONAL_CARE_OPTIONS: {
    defaultMessage: 'Additional care options',
    description: 'Label for a group of additional benefits offered through Lyra',
  },
  RECOMMENDED_FOR_YOU: {
    defaultMessage: 'Recommended for you',
    description: 'Label for a group of benefits recommended to the user',
  },
  MORE_BENEFITS: {
    defaultMessage: 'More benefits',
    description: 'Label for a group other benefits offered',
  },
})

export const IntegratedBenefitsPage: FunctionComponent<IntegratedBenefitsPageProps> = ({
  recommendedBenefitsItems,
  lyraBenefitsItems,
  moreBenefitsItems,
  additionalCareBenefitsItems,
  onPressBackButton = noop,
  onCareNavigatorPress,
  coverageModalContents = [],
  costMessagingModalCoverageInfo = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  widerBenefitsItemTitleContainer = false,
  customerName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}) => {
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme() as ThemeType

  const { formatMessage } = useIntl()

  const getCoverageLink = () => {
    return (
      <CoverageMessagingLink
        coverageModalContents={coverageModalContents}
        costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
        isCostMessagingEnabled={isCostMessagingEnabled}
        trackCoverageModalPress={trackCoverageModalPress}
        customerName={customerName}
        numSessions={numSessions}
        numberOfSessionByProgram={numberOfSessionByProgram}
        isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
        displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
      />
    )
  }

  return (
    <BackgroundContainer testID={tID('IntegratedBenefitsPage')}>
      <LayoutContainer>
        {isMinWidthTablet && (
          <PeripheralFlexItem testID={tID('IntegratedBenefitsPage-back_button')}>
            <BackButtonV2 onPress={onPressBackButton} isOatmealBackground />
          </PeripheralFlexItem>
        )}
        <MainFlexItem>
          {isMinWidthTablet && (
            <HeadlineContainer>
              <Headline
                level='1'
                color={colors.textPrimary}
                size={HeadlineSize.SMALL}
                textAlign='left'
                text={benefitsHeaderText}
              />
            </HeadlineContainer>
          )}
          {!isEmpty(lyraBenefitsItems) && (
            <BenefitsGroupContainer testID={tID('IntegratedBenefitsPage-LyraOfferingsSection')}>
              <IntegratedBenefitsLabeledItemGroup
                label={formatMessage(benefitsSectionLabels.LYRA_OFFERINGS)}
                items={lyraBenefitsItems ?? []}
                coverageMessageLinkNode={getCoverageLink()}
                widerBenefitsItemTitleContainer={widerBenefitsItemTitleContainer}
              />
            </BenefitsGroupContainer>
          )}
          {!isEmpty(additionalCareBenefitsItems) && (
            <BenefitsGroupContainer testID={tID('IntegratedBenefitsPage-AdditionalCareOptionsSection')}>
              <IntegratedBenefitsLabeledItemGroup
                label={formatMessage(benefitsSectionLabels.ADDITIONAL_CARE_OPTIONS)}
                items={additionalCareBenefitsItems ?? []}
                widerBenefitsItemTitleContainer={widerBenefitsItemTitleContainer}
              />
            </BenefitsGroupContainer>
          )}
          {!isEmpty(recommendedBenefitsItems) && (
            <BenefitsGroupContainer testID={tID('IntegratedBenefitsPage-RecommendedSection')}>
              <IntegratedBenefitsLabeledItemGroup
                label={formatMessage(benefitsSectionLabels.RECOMMENDED_FOR_YOU)}
                items={recommendedBenefitsItems ?? []}
                showRecommendedBenefitsTooltip={true}
                widerBenefitsItemTitleContainer={widerBenefitsItemTitleContainer}
              />
            </BenefitsGroupContainer>
          )}
          {!isEmpty(moreBenefitsItems) && (
            <BenefitsGroupContainer testID={tID('IntegratedBenefitsPage-MoreSection')}>
              <IntegratedBenefitsLabeledItemGroup
                label={formatMessage(benefitsSectionLabels.MORE_BENEFITS)}
                items={moreBenefitsItems ?? []}
                widerBenefitsItemTitleContainer={widerBenefitsItemTitleContainer}
              />
            </BenefitsGroupContainer>
          )}
          <CareNavBottomSection
            text={
              <FormattedMessage
                defaultMessage='Have questions? Your Care Navigator is here for you 24/7. <link>Get in touch</link>'
                description='Get help from a care navigator'
                values={{
                  link: (text: string) => (
                    <Link
                      underline={true}
                      text={text}
                      onPress={onCareNavigatorPress}
                      testID={tID('IBPage-contactCareNavigator')}
                    />
                  ),
                }}
              />
            }
          />
        </MainFlexItem>
        <PeripheralFlexItem />
      </LayoutContainer>
    </BackgroundContainer>
  )
}
