import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import {
  CMSMicrositeWithMetadataProps,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  getMicrositeUrl,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'

import CMSMicrositeIframe from '../../common/components/CMSMicrositeIFrame/CMSMicrositeIFrame'
import LegalFooter from '../../common/components/footer/LegalFooter'
import { useGetCMSMicrositeCustomMessages } from '../../common/hooks/CMSMicrosite/useGetCMSMicrositeCustomMessages'
import { useGetCMSMicrositeMessageHandlers } from '../../common/hooks/CMSMicrosite/useGetCMSMicrositeMessageHandlers'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import {
  getCustomerPropertiesMap,
  getIsWorkhubDisabled,
  getIsWorkhubEventsDisabled,
} from '../../data/customer/customerSelectors'
import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getIsUserInternational, getIsUserLoggedIn, getUserDisplayLanguage } from '../../data/user/userSelectors'
import { clearSelectedPathwayOption } from '../register/data/registerActions'
import RegistrationEmailCaptureContainer from '../register/setupAccount/RegistrationEmailCaptureContainer'

const CMSMicrositeWithMetadata: FunctionComponent<CMSMicrositeWithMetadataProps> = ({ micrositeMetadata }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { activeLanguage } = useLyraIntl()
  const ldFlags = useFlags()
  const isMicrositeEnabled = micrositeMetadata.featureFlag === undefined ? true : ldFlags[micrositeMetadata.featureFlag]
  const [searchParams, setSearchParams] = useSearchParams()
  const initialParams = useRef(searchParams)
  const initialSetSearchParams = useRef(setSearchParams) // setSearchParams setter changes when updating searchParams. Use ref to prevent unexpected side effects
  const addedUtmParams = useRef(false)
  const [isShowingRegistrationModal, setIsShowingRegistrationModal] = useState(false)
  const [checkedRedirect, setCheckedRedirect] = useState(false)

  const customerPropertiesMap = useSelector(getCustomerPropertiesMap)?.toJS()
  const isInternational = useSelector(getIsUserInternational)
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isWorkhubDisabled = useSelector(getIsWorkhubDisabled)
  const isWorkhubEventsDisabled = useSelector(getIsWorkhubEventsDisabled)
  const userDisplayLanguage = useSelector(getUserDisplayLanguage)

  const callbacks = useGetCMSMicrositeMessageHandlers({
    eventsList: micrositeMetadata.postMessageEvents,
    page: micrositeMetadata.page,
    searchParams,
    setSearchParams,
    setIsShowingRegistrationModal,
    micrositeId: micrositeMetadata.micrositeId,
  })

  const customMessages = useGetCMSMicrositeCustomMessages({ micrositeId: micrositeMetadata.micrositeId })

  const displayLanguage = isLoggedIn ? userDisplayLanguage : activeLanguage
  const micrositeUrl = getMicrositeUrl(
    micrositeMetadata.iFrameSrcUrl,
    isWorkhubDisabled,
    isWorkhubEventsDisabled,
    micrositeMetadata.shouldSupportSpanish ? displayLanguage : undefined,
  )

  const pageTitle = formatMessage(micrositeMetadata.title)

  const getCustomerProperty = (name: string) => {
    return customerPropertiesMap?.[name] === 'true'
  }

  const shouldHidePageBasedOnCustomerPropConditions = micrositeMetadata.customerPropertyConditions
    .map(
      (customerPropertyCondition) =>
        getCustomerProperty(customerPropertyCondition.key) === customerPropertyCondition.value,
    )
    .some((condition) => condition)

  const utmParams: { [key: string]: string } = useMemo(() => {
    const utmParamsFromMetadata = micrositeMetadata.trackUTMParams
    const utmCampaign = initialParams.current.get('utm_campaign') || utmParamsFromMetadata?.utm_campaign
    const utmSource = initialParams.current.get('utm_source') || utmParamsFromMetadata?.utm_source || document.referrer
    const utmMedium = initialParams.current.get('utm_medium') || utmParamsFromMetadata?.utm_medium
    return {
      ...(utmCampaign && { utm_campaign: utmCampaign }),
      ...(utmMedium && { utm_medium: utmMedium }),
      ...(utmSource && { utm_source: utmSource }),
    }
  }, [micrositeMetadata.trackUTMParams])

  useTrackViewPage(
    COMMON_MIXPANEL_PAGE.MARKETING_TOOLKIT,
    {
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: micrositeMetadata.page,
      ...utmParams,
      ...micrositeMetadata.customMixpanelEventProps,
      ...(micrositeMetadata.shouldSupportSpanish && {
        language: displayLanguage,
        pageLanguage: displayLanguage?.startsWith('es') ? 'spanish' : 'english',
      }),
    },
    { skipAppendPageToEventName: true },
  )

  useEffect(() => {
    if (
      !isMicrositeEnabled ||
      (micrositeMetadata.shouldHideForInternational && isInternational) ||
      shouldHidePageBasedOnCustomerPropConditions
    ) {
      navigate('/')
      return
    }
    setCheckedRedirect(true)
  }, [
    shouldHidePageBasedOnCustomerPropConditions,
    isInternational,
    isMicrositeEnabled,
    micrositeMetadata.shouldHideForInternational,
    navigate,
  ])

  useEffect(() => {
    if (!checkedRedirect || addedUtmParams.current) {
      return
    }
    addedUtmParams.current = true
    // Set default params to be tracked by mixpanel
    const newParams = new URLSearchParams(initialParams.current)
    for (const key in utmParams) {
      const value = utmParams[key]
      if (value && !initialParams.current.has(key)) {
        newParams.set(key, value)
      }
    }
    initialSetSearchParams.current(newParams)
    // Mixpanel doesn't always detect the new params so we should manually set it
    mixpanel.register(utmParams)
  }, [utmParams, checkedRedirect])

  const onCloseRegistrationModal = () => {
    const newParams = new URLSearchParams(searchParams)
    newParams.delete(COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT)
    setSearchParams(newParams)

    setIsShowingRegistrationModal(false)
    dispatch(clearSelectedPathwayOption())
  }

  return (
    <>
      <CMSMicrositeIframe
        testId={micrositeMetadata.micrositeId}
        iFrameTitle={pageTitle}
        micrositeUrl={micrositeUrl}
        documentTitle={pageTitle}
        messageHandlers={callbacks}
        customMessages={customMessages}
        eventProps={micrositeMetadata.customMixpanelEventProps}
      />
      <LegalFooter />
      {isShowingRegistrationModal && (
        <RegistrationEmailCaptureContainer
          isModalView
          enableModalClose
          onCloseRegistrationModal={onCloseRegistrationModal}
          registrationEntryPoint={micrositeMetadata.page}
        />
      )}
    </>
  )
}

export default CMSMicrositeWithMetadata
