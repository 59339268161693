import { NICOTINE_QUITLINE_SCHEDULE_CALL_LINK } from '../../common/constants/constants'
import { CMS_MICROSITE_MESSAGE_KEY } from '../messages/messages'
import { CMSMicrositeMetadata } from '../types'

export const nicotineQuitlineMetadata: CMSMicrositeMetadata = {
  micrositeId: 'NicotineQuitline',
  iFrameSrcUrl: '/nicotine-quitline',
  micrositeUrl: 'secure/index/nicotine-quitline',
  title: {
    id: 'nicotineQuitline.title',
    defaultMessage: 'Nicotine Quitline',
    description: 'Microsite page title',
  },
  customerPropertyConditions: [
    {
      key: 'lyraNicotineQuitlineEnabled',
      value: false,
    },
  ],
  postMessageEvents: [
    { key: CMS_MICROSITE_MESSAGE_KEY.OPEN_EXTERNAL_LINK, params: { link: NICOTINE_QUITLINE_SCHEDULE_CALL_LINK } },
  ],
  page: 'nicotine microsite',
  shouldSupportSpanish: false,
  shouldHideForInternational: false,
}
