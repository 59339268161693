export enum APP_SCREEN {
  PROFILE_SESSION_HISTORY = 'ProfileSessionHistory',
  PROFILE_ELIGIBILITY = 'ProfileEligibility',
}

export enum APP_ROUTE {
  PROFILE_SESSION_HISTORY = '/secure/profile/session-history',
  PROFILE_ELIGIBILITY = '/secure/profile/eligibility',
}

const routeToScreenMap: { [key in APP_ROUTE]: APP_SCREEN } = {
  [APP_ROUTE.PROFILE_SESSION_HISTORY]: APP_SCREEN.PROFILE_SESSION_HISTORY,
  [APP_ROUTE.PROFILE_ELIGIBILITY]: APP_SCREEN.PROFILE_ELIGIBILITY,
}

export const routesEnabledInNativeApp: string[] = [APP_ROUTE.PROFILE_SESSION_HISTORY, APP_ROUTE.PROFILE_ELIGIBILITY]

export const screensEnabledInNativeApp = routesEnabledInNativeApp.map((route) => routeToScreenMap[route as APP_ROUTE])
