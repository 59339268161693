import { COMMON_MIXPANEL_PROPERTIES, COUNTRY_CATEGORY } from '../constants/mixpanelConstants'

export const getCountryFlag = (isUserInternational?: boolean) => {
  return {
    [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational
      ? COUNTRY_CATEGORY.INTERNATIONAL
      : COUNTRY_CATEGORY.DOMESTIC,
  }
}

export const getCountryCategory = (isUserInternational?: boolean) => {
  return {
    [COMMON_MIXPANEL_PROPERTIES.COUNTRY_CATEGORY]: isUserInternational
      ? COUNTRY_CATEGORY.INTERNATIONAL
      : COUNTRY_CATEGORY.DOMESTIC,
  }
}
