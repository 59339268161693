import { type NavigateFunction } from 'react-router-dom'

import Immutable, { Map } from 'immutable'
import { get } from 'lodash-es'

import { type ProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import { providerHasAvailability } from '../../../../common/utils/providerUtils'
import { logToSumoLogic } from '../../../../common/utils/userUtils'
import { getGraphicContentGroup } from '../../../../data/customer/customerSelectors'
import { AppDispatch } from '../../../../data/storeConfiguration/store'
import { clearDirectPathState } from '../../../appointmentDashboard/data/appointmentDashboardActions'
import { getOnboardDirectLinkSource } from '../../../onboard/data/onboardSelectors'
import { resetCareLanguageState } from '../../carePreferences/data/carePreferencesActions'
import { clearDirectLinkSource, setLatestMatchesSearch, setMeetingFormat } from '../../data/onboardDataActions'
import { getOnboardProviderProfileData } from '../../data/onboardSelectors'
import { PAGE_ROUTES } from '../../data/page-navigation/location-actions'
import { clearFullLocationObj } from '../../locationMatcher/data/locationMatcherActions'

export const SHOW_APPOINTMENT_CONFLICT_MODAL = 'SHOW_APPOINTMENT_CONFLICT_MODAL'
export const HIDE_APPOINTMENT_CONFLICT_MODAL = 'HIDE_APPOINTMENT_CONFLICT_MODAL'
export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const CLEAR_APPOINTMENT = 'CLEAR_APPOINTMENT'

export const showAppointmentConflictModal = () => {
  return { type: SHOW_APPOINTMENT_CONFLICT_MODAL }
}
export const hideAppointmentConflictModal = () => {
  return { type: HIDE_APPOINTMENT_CONFLICT_MODAL }
}
export const setAppointment = ({ date, time, duration }: { date: string; time: string; duration?: number }) => {
  return { appointment: { date, time, duration }, type: SET_APPOINTMENT }
}
export const clearAppointment = () => {
  return { type: CLEAR_APPOINTMENT }
}

// ultimate action called from the book handler of AppointmentLogistics,
// which determines the "api" to call based on the workflow, and preps and passes the "data" here
// api = editAppointmentByUserId || createNewAppointmentByUserId
export const bookAppointment = ({ api, data, userId }: $TSFixMe) => {
  return function (dispatch: AppDispatch, getState: () => Map<string, any>) {
    const state = getState()
    const directLinkSource = getOnboardDirectLinkSource(state)

    logToSumoLogic('lyraWebInfo', userId, {
      message: `Booking appointment date: ${get(data, 'startDate', 'Unknown')} time: ${get(
        data,
        'startTime',
        'Unknown',
      )} duration: ${get(data, 'appointmentDuration', 'Unknown')}, isDirectPath: ${get(
        data,
        'isDirectPath',
        'Unknown',
      )}, taxonomy: ${get(data, 'programTaxonomy', 'Unknown')}, directLinkSource: ${directLinkSource}},`,
      type: 'info',
    })
    return api({ userId, data }).then((response: $TSFixMe) => {
      // Clear the appointment from the store if the booking was successful, so it can't be booked again.
      dispatch(clearAppointment())
      // Clear meeting format selected during Onboard or Standalone booking flow.
      dispatch(setMeetingFormat(null))
      dispatch(clearFullLocationObj())
      dispatch(resetCareLanguageState())
      dispatch(clearDirectPathState())
      dispatch(clearDirectLinkSource())
      dispatch(setLatestMatchesSearch(null))
      return response
    })
  }
}

export const goToAppointmentSelection = (
  provider: Immutable.Map<string, any> | ProviderInfo,
  navigate: NavigateFunction,
  outsideOnboarding = false,
  isCNTBooking = false,
) => {
  return () => {
    const providerLyraId = Immutable.Map.isMap(provider)
      ? (provider as $TSFixMe).get('lyra_id')
      : (provider as $TSFixMe).lyra_id
    const urlToNavigate = outsideOnboarding
      ? `${PAGE_ROUTES.HOME}/${PAGE_ROUTES.APPOINTMENT_SELECTION(providerLyraId)}`
      : `${PAGE_ROUTES.APPOINTMENT_SELECTION(providerLyraId)}`
    return navigate(urlToNavigate, { state: { isCNTBooking } })
  }
}

export const bookWithProvider = (
  options: { outsideOnboarding?: boolean; isAppointmentSet?: boolean; searchParams?: string },
  navigate: NavigateFunction,
) => {
  return (dispatch: AppDispatch, getState: $TSFixMe) => {
    const state = getState()
    const $$provider = getOnboardProviderProfileData(state)
    const graphicContentGroup = getGraphicContentGroup(state)
    const hasAvailability = providerHasAvailability($$provider)
    const providerLyraId = $$provider.get('lyra_id')

    if (options.isAppointmentSet) {
      if (options.outsideOnboarding) {
        return navigate({
          pathname: `/secure/index/provider/${providerLyraId}/appointment`,
        })
      } else {
        return navigate({
          pathname: `/secure/onboard/provider/${providerLyraId}/appointment`,
          search: options.searchParams,
        })
      }
    }
    if (hasAvailability) {
      // Facebook's Graphic Content Group Providers need to skip the location matcher since they are not technically
      // surfaced by the API, but should be bookable
      const isFGCprovider = graphicContentGroup?.toString().split(',')?.includes(providerLyraId)
      if (options.outsideOnboarding && !isFGCprovider) {
        return navigate({
          pathname: `${PAGE_ROUTES.HOME}/provider/${providerLyraId}/${PAGE_ROUTES.MATCH_LOCATION}`,
          search: options.searchParams,
        })
      } else {
        return navigate({
          pathname: `/secure/onboard/provider/${providerLyraId}/schedule`,
          search: options.searchParams,
        })
      }
    } else {
      if (options.outsideOnboarding) {
        return navigate({
          pathname: `${PAGE_ROUTES.HOME}/provider/${providerLyraId}/request`,
        })
      } else {
        return navigate({
          pathname: `/secure/onboard/provider/${providerLyraId}/request`,
          search: options.searchParams,
        })
      }
    }
  }
}

export const sendToHealthPlanAndPaymentInfoCollectionStepsOrContinueWithBooking = (
  options: { outsideOnboarding: boolean; searchParams?: string },
  navigate: NavigateFunction,
) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      bookWithProvider(
        {
          outsideOnboarding: options.outsideOnboarding,
          searchParams: options.searchParams,
        },
        navigate,
      ),
    )
  }
}
