import { useMemo } from 'react'

import { FieldSchema, LegacyUIMetadata, UiMetadata } from '@lyrahealth-inc/shared-app-logic'

/**
 * Pull data from the legacy UiMetadata file
 */
export const useGetLegacyMetadata = ({ uiSchema = {}, schema, name }: UseGetLegacyMetadataParams): UiMetadata => {
  return useMemo(() => {
    if (name) {
      const legacyContent = LegacyUIMetadata[name]
      const schemaVersion = schema.version || 'default'
      const legacySchema = legacyContent?.uiSchema?.[schemaVersion] || legacyContent?.uiSchema || {}
      return { ...legacySchema, ...uiSchema }
    }
  }, [uiSchema, name, schema.version])
}

type UseGetLegacyMetadataParams = {
  schema: FieldSchema
  uiSchema?: UiMetadata
  name?: string
}
