import { AxiosResponse } from 'axios'

import { WellnessCheckInRequestData, WellnessCheckInResultsData } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../../common/http/axiosInstance'

export type WellnessCheckInRequestV2Data = {
  token: string
}

export const getWellnessCheckInResults = (lyraId: string): Promise<AxiosResponse<WellnessCheckInResultsData[]>> => {
  return axiosInstance.get<WellnessCheckInResultsData[]>(`/v1/wellness-check-in/${lyraId}/results`)
}

export const postWellnessCheckInResult = (
  lyraId: string,
  requestBody: WellnessCheckInRequestData,
): Promise<AxiosResponse<WellnessCheckInResultsData>> => {
  return axiosInstance.post<WellnessCheckInResultsData>(`/v1/wellness-check-in/${lyraId}/results`, requestBody)
}

export const postV2WellnessCheckInResult = (
  lyraId: string,
  requestBody: WellnessCheckInRequestV2Data,
): Promise<AxiosResponse<WellnessCheckInResultsData>> => {
  return axiosInstance.post<WellnessCheckInResultsData>(`/v2/wellness-check-in/${lyraId}/results`, requestBody)
}
