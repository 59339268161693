import { CMS_MICROSITE_MESSAGE_KEY } from '../messages/messages'
import { CMSMicrositeMetadata } from '../types'

export const newYearToolkitMetadata: CMSMicrositeMetadata = {
  micrositeId: 'NewYearToolkit',
  iFrameSrcUrl: '/new-year-toolkit',
  micrositeUrl: 'members',
  title: {
    id: 'newYearToolkit.title',
    defaultMessage: 'Start the Year Off Right',
    description: 'Microsite page title',
  },
  featureFlag: 'isNewYearToolkitEnabled',
  customerPropertyConditions: [
    {
      key: 'stressManagement',
      value: false,
    },
    {
      key: 'hideDependentField',
      value: true,
    },
    {
      key: 'essentialsDisabled',
      value: true,
    },
  ],
  postMessageEvents: [
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_FORKED_TRIAGE,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WELLNESS_CHECK_IN,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_ESSENTIALS,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR,
      params: {
        webinarLink: '/secure/index/essentials/events',
      },
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_REGISTRATION,
    },
    { key: CMS_MICROSITE_MESSAGE_KEY.CLICK_SELF_CARE_CONTENT },
  ],
  page: 'new year toolkit',
  trackUTMParams: {
    utm_campaign: 'newyear2025',
  },
  customMixpanelEventProps: {
    toolkit_topic: 'new year',
  },
  shouldSupportSpanish: true,
  shouldHideForInternational: true,
}
