import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const LyraSelectCareSessionIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 88 83' fill='none'>
      <Path
        d='M51.9464 56.961C44.8279 69.0993 27.7033 66.8206 17 64.579C19.0845 64.0812 21.5538 63.204 21.9633 61.1018C22.221 59.7745 21.5168 58.4807 20.9908 57.2346C14.9208 42.8865 30.5839 36.2781 42.1803 39.9318C51.9446 43.0065 55.6195 50.7003 51.9464 56.9628V56.961Z'
        fill='#008295'
      />
      <Path
        d='M35.5644 40.0341C45.9571 48.9953 61.5055 40.9819 70.9997 33.4663C59.8375 34.6013 65.8881 23.3685 52.7861 17.7891C36.3852 10.8048 23.9698 30.0368 35.5644 40.0341Z'
        fill='#00A4B3'
      />
    </Svg>
  )
}
