import React from 'react'
import { MessageDescriptor } from 'react-intl'

import {
  customerCodeValidationError,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  EligibilityType,
  extendMetadata,
  GenerateCustomMetadata,
  GetCustomerCopyFunc,
  invalidEligibilityFirstNameMessage,
  invalidEligibilityLastNameMessage,
} from '@lyrahealth-inc/shared-app-logic'

import { generateEligibilityTypeQuestions } from '../formMetadata/eligibilityMetadata'
import { generateAppleDependentEligibilityMetadata } from './registration/apple/appleEligibilityDependentMetadata'
import { generateAppleEmployeeEligibilityMetadata } from './registration/apple/appleEligibilityEmployeeMetadata'
import { AppleEligibilityErrorBanner } from './registration/apple/AppleEligibilityErrorBanner'
import { AppleEligibilityErrorMessage } from './registration/apple/AppleEligibilityErrorMessage'
import { AppleEligibilityInformation } from './registration/apple/AppleEligibilityInformation'
import { BasicInfoField } from '../organisms/setUpAccountForm/basicInfoMetadata'

export const generateAppleEligibilityMetadata: GenerateCustomMetadata = ({
  eligibilityInfo,
  eligibilityType,
  intl,
  isMobileSized,
  isInternational,
  colors,
}) => {
  const eligibilityTypeQuestions = generateEligibilityTypeQuestions(eligibilityInfo, intl, {
    marginBottom: 8,
  })
  const employeeMetadata = extendMetadata(
    eligibilityTypeQuestions,
    generateAppleEmployeeEligibilityMetadata(intl, isInternational, eligibilityInfo),
  )
  let dependentMetadata = eligibilityTypeQuestions
  if (!isInternational) {
    // do not collect employee's eligibility info if it's international
    dependentMetadata = extendMetadata(
      eligibilityTypeQuestions,
      generateAppleDependentEligibilityMetadata(intl, eligibilityInfo, colors, isMobileSized),
    )
  }

  return eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? dependentMetadata : employeeMetadata
}

const getCustomFields = (eligibilityInfo: EligibilityInfo) => {
  const isPristineConfirmEligibility = eligibilityInfo.isPristineConfirmEligibility ?? false
  return {
    DependentEligibilityErrorBanner: () => (
      <AppleEligibilityErrorBanner isDependent isPristineConfirmEligibility={isPristineConfirmEligibility} />
    ),
    EligibilityInformation: () => (
      <AppleEligibilityInformation
        isConfirmEligibility={isPristineConfirmEligibility}
        onContactCNT={eligibilityInfo.onContactCNT}
      />
    ),
  }
}

export const apple: GetCustomerCopyFunc = () => {
  return {
    eligibilityQuestionSubtitle: null, // return null to not show
    showEligibilityQuestionForInternationalRegistration: true, // show eligibility metadata in registration form for international experience
    generateEmployeeEligibilityError: (isConfirmEligibility?: boolean) => (
      <AppleEligibilityErrorMessage isDependent={false} isPristineConfirmEligibility={isConfirmEligibility} />
    ),
    eligibilityMetadata: {
      generateMetadata: generateAppleEligibilityMetadata,
      getCustomFields,
    },
    generateEligibilityCheckSubmissionErrors: (
      eligibilityType?: EligibilityType,
    ): {
      [fieldName: string]: MessageDescriptor
    } => {
      const isDependent = eligibilityType === 'dependent_other'
      if (isDependent) {
        return {
          [EligibilityField.EMPLOYEE_FIRST_NAME]: invalidEligibilityFirstNameMessage,
          [EligibilityField.EMPLOYEE_LAST_NAME]: invalidEligibilityLastNameMessage,
          [EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      } else {
        return {
          [BasicInfoField.USER_FIRST_NAME]: invalidEligibilityFirstNameMessage,
          [BasicInfoField.USER_LAST_NAME]: invalidEligibilityLastNameMessage,
          [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      }
    },
    generateConfirmEligibilityFormDefaultError: () => (
      <AppleEligibilityErrorMessage isDependent={false} isPristineConfirmEligibility />
    ),
  }
}
