import React, { FunctionComponent, useContext } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ProductTypeContext, ThemeType } from '../../utils'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'
import lyraSelectMobilePromo from './../../assets/microsite/LyraSelectMobilePromo.png'
import lyraSelectPromo from './../../assets/microsite/LyraSelectPromo.png'
import mobileAppPromo from './../../assets/mobile-app-promo.png'

const MobileAppPromoIllustrationPNG = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
  resizeMode: 'contain',
}))

export const MobileAppPromoIllustration: FunctionComponent<ImageComponentProps> = ({ width = 448, height = 440 }) => {
  const { isLyraSelect } = useContext(ProductTypeContext)
  const {
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType

  const lyraSelectAppPromo = (isMobileSized ? lyraSelectMobilePromo : lyraSelectPromo) as ImageSourcePropType
  const imageSource = isLyraSelect ? lyraSelectAppPromo : (mobileAppPromo as ImageSourcePropType)
  return <MobileAppPromoIllustrationPNG source={imageSource} width={width} height={height} />
}
