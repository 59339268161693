import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { getDevFlags, LDFlags } from '@lyrahealth-inc/shared-app-logic'

export const getFeatureFlagState = (state: Map<string, any>) => state?.get('featureFlag')

export const getFeatureFlags = createSelector([getFeatureFlagState], (featureFlagState) => ({
  ...(featureFlagState.get('flags').toJS() as LDFlags),
  ...getDevFlags(),
}))

export const getAdultTriageDisabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.adultTriageDisabled,
)

export const getForceAdultFlowsToHPI = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.forceAdultFlowsToHPI,
)

export const getIsProgramLevelSessionLimitEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.isProgramLevelSessionLimitEnabled,
)

export const getIsAppleSwitchToDateOfBirthEligibilityEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState.isAppleSwitchToDateOfBirthEligibilityEnabled,
)

export const getIsExpandedProgressBarEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.isExpandedProgressBarEnabled,
)

export const getIsAutoEnrollmentMicrositeEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.isAutoEnrollmentMicrositeEnabled,
)
