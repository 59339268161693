import { MutableRefObject } from 'react'
import { AccessibilityPropsAndroid, LayoutChangeEvent, ViewStyle } from 'react-native'
import type { Edges } from 'react-native-safe-area-context'

export type ProgressBarProps = {
  progress?: number
  height?: number
  color?: string
  borderRadius?: number
  backgroundColor?: string | ArrayMinTwo<string>
  shouldAnimate?: boolean
  width?: number | string
  style?: ViewStyle
  duration?: number
  accessibilityLiveRegion?: AccessibilityPropsAndroid['accessibilityLiveRegion']
}

export type NavBarProps = {
  isTransparent?: boolean
  title?: string | JSX.Element | React.ReactNode | null
  backgroundColor?: string
  borderBottomColor?: string
  showBackButton?: boolean
  showCloseButton?: boolean
  showProgressBar?: boolean
  style?: ViewStyle
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  careNavigatorModal?: React.ReactElement
  careNavigatorEntryPoint?: React.ReactElement
  isInternational?: boolean
  testId?: string
  backIconColor?: string
  useTitle?: boolean
  alternateExitButtonText?: React.ReactNode
  showLogoMobileSized?: boolean
  customRightComponent?: React.ReactNode
  isFixed?: boolean
  progressBarProps?: ProgressBarProps
  onBack?: () => void
  onExit?: () => void
  onSkip?: () => void
  onLayout?: (e: LayoutChangeEvent) => void
  onCareNavigatorHeaderEntryPointButtonPress?: () => void
  onLogoPress?: () => void
}

export type GenericHeaderProps = {
  title?: React.ReactNode
  onBack?: () => void
  onExit?: () => void
  backgroundColor?: string
  testIdPrefix?: string
  isSmall?: boolean
  style?: ViewStyle
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  careNavigatorModal?: React.ReactElement
  careNavigatorEntryPoint?: React.ReactElement
  openCareNavigatorModal?: () => void
  isInternational?: boolean
  edges?: Edges
}

export enum HEADER_NAV_ITEM {
  BENEFITS = 'headerNavBenefitsButton',
  ACCOUNT = 'headerNavAccountButton',
  FAQS = 'headerNavFAQsButton',
  REFERRAL = 'headerNavReferralButton',
  LOG_OUT = 'headerNavLogOutButton',
  HOME = 'headerNavHomeButton',
  CARE = 'headerNavCareButton',
  SESSIONS = 'headerNavSessionsButton',
  PROGRESS = 'headerNavProgressButton',
  ESSENTIALS = 'headerNavEssentialsButton',
}
