import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

import { tID } from '../../utils'

type MobileAppQRCodeProps = {
  fillColor?: string
}

export const MobileAppQRCode: FunctionComponent<MobileAppQRCodeProps> = ({ fillColor = '#017A8D' }) => {
  return (
    <Svg width={80} height={81} fill='none' testID={tID('MobileAppQRCode')}>
      <Mask id='MobileAppQRCode' width={80} height={81} x={0} y={0} maskUnits='userSpaceOnUse'>
        <Path fill='#fff' d='M80 .5H0v80h80V.5Z' />
      </Mask>
      <G fill={fillColor} mask='url(#MobileAppQRCode)'>
        <Path d='M28.888.5h-3.353v3.353h3.353V.5Z' />
        <Path d='M32.103.5H28.75v3.353h3.353V.5Z' />
        <Path d='M35.287.5h-3.354v3.353h3.354V.5Z' />
        <Path d='M38.482.5H35.13v3.353h3.353V.5ZM48.064.5h-3.353v3.353h3.353V.5ZM28.888 3.693h-3.353v3.354h3.353V3.693Z' />
        <Path d='M38.482 3.693H35.13v3.354h3.353V3.693ZM32.103 6.877H28.75v3.353h3.353V6.877ZM44.88 6.877h-3.353v3.353h3.354V6.877ZM54.455 6.877h-3.353v3.353h3.353V6.877ZM38.482 10.09H35.13v3.353h3.353V10.09Z' />
        <Path d='M41.69 10.09h-3.354v3.353h3.353V10.09Z' />
        <Path d='M44.88 10.09h-3.353v3.353h3.354V10.09Z' />
        <Path d='M48.064 10.09h-3.353v3.353h3.353V10.09Z' />
        <Path d='M51.256 10.09h-3.354v3.353h3.354V10.09ZM28.888 13.273h-3.353v3.354h3.353v-3.354ZM38.482 13.273H35.13v3.354h3.353v-3.354Z' />
        <Path d='M41.69 13.273h-3.354v3.354h3.353v-3.354Z' />
        <Path d='M44.88 13.273h-3.353v3.354h3.354v-3.354Z' />
        <Path d='M48.064 13.273h-3.353v3.354h3.353v-3.354ZM28.888 16.467h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M32.103 16.467H28.75v3.353h3.353v-3.353Z' />
        <Path d='M35.287 16.467h-3.354v3.353h3.354v-3.353ZM41.69 16.467h-3.354v3.353h3.353v-3.353Z' />
        <Path d='M44.88 16.467h-3.353v3.353h3.354v-3.353Z' />
        <Path d='M48.064 16.467h-3.353v3.353h3.353v-3.353ZM54.455 16.467h-3.353v3.353h3.353v-3.353ZM28.888 19.66h-3.353v3.354h3.353V19.66ZM35.287 19.66h-3.354v3.354h3.354V19.66ZM41.69 19.66h-3.354v3.354h3.353V19.66Z' />
        <Path d='M48.064 19.66h-3.353v3.354h3.353V19.66ZM54.455 19.66h-3.353v3.354h3.353V19.66ZM28.888 22.854h-3.353v3.353h3.353v-3.354Z' />
        <Path d='M32.103 22.854H28.75v3.353h3.353v-3.354ZM3.353 26.057H0v3.353h3.353v-3.353Z' />
        <Path d='M6.537 26.057H3.184v3.353h3.353v-3.353Z' />
        <Path d='M9.752 26.057H6.398v3.353h3.354v-3.353ZM19.314 26.057h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M22.517 26.057h-3.353v3.353h3.353v-3.353ZM28.888 26.057h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M32.103 26.057H28.75v3.353h3.353v-3.353ZM38.482 26.057H35.13v3.353h3.353v-3.353Z' />
        <Path d='M41.69 26.057h-3.354v3.353h3.353v-3.353ZM48.064 26.057h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M51.256 26.057h-3.354v3.353h3.354v-3.353ZM57.639 26.057h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M60.83 26.057h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M64.037 26.057h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M67.232 26.057H63.88v3.353h3.353v-3.353ZM76.814 26.057h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M80.006 26.057h-3.354v3.353h3.354v-3.353ZM3.353 29.24H0v3.353h3.353V29.24Z' />
        <Path d='M6.537 29.24H3.184v3.353h3.353V29.24ZM25.705 29.24h-3.353v3.353h3.353V29.24ZM41.69 29.24h-3.354v3.353h3.353V29.24ZM48.064 29.24h-3.353v3.353h3.353V29.24Z' />
        <Path d='M51.256 29.24h-3.354v3.353h3.354V29.24ZM57.639 29.24h-3.354v3.353h3.354V29.24Z' />
        <Path d='M60.83 29.24h-3.353v3.353h3.353V29.24Z' />
        <Path d='M64.037 29.24h-3.354v3.353h3.354V29.24ZM70.416 29.24h-3.353v3.353h3.353V29.24ZM76.814 29.24h-3.353v3.353h3.353V29.24Z' />
        <Path d='M80.006 29.24h-3.354v3.353h3.354V29.24ZM3.353 32.443H0v3.354h3.353v-3.354ZM9.752 32.443H6.398v3.354h3.354v-3.354Z' />
        <Path d='M12.94 32.443H9.585v3.354h3.353v-3.354ZM22.517 32.443h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M25.705 32.443h-3.353v3.354h3.353v-3.354ZM32.103 32.443H28.75v3.354h3.353v-3.354Z' />
        <Path d='M35.287 32.443h-3.354v3.354h3.354v-3.354ZM41.69 32.443h-3.354v3.354h3.353v-3.354Z' />
        <Path d='M44.88 32.443h-3.353v3.354h3.354v-3.354ZM51.256 32.443h-3.354v3.354h3.354v-3.354ZM57.639 32.443h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M60.83 32.443h-3.353v3.354h3.353v-3.354ZM67.232 32.443H63.88v3.354h3.353v-3.354Z' />
        <Path d='M70.416 32.443h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M73.607 32.443h-3.353v3.354h3.353v-3.354ZM80.006 32.443h-3.354v3.354h3.354v-3.354ZM3.353 35.627H0v3.353h3.353v-3.353Z' />
        <Path d='M6.537 35.627H3.184v3.353h3.353v-3.353ZM16.13 35.627h-3.353v3.353h3.354v-3.353Z' />
        <Path d='M19.314 35.627h-3.353v3.353h3.353v-3.353ZM35.287 35.627h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M38.482 35.627H35.13v3.353h3.353v-3.353Z' />
        <Path d='M41.69 35.627h-3.354v3.353h3.353v-3.353ZM48.064 35.627h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M51.256 35.627h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M54.455 35.627h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M57.639 35.627h-3.354v3.353h3.354v-3.353ZM64.037 35.627h-3.354v3.353h3.354v-3.353ZM70.416 35.627h-3.353v3.353h3.353v-3.353ZM9.752 38.82H6.398v3.354h3.354V38.82Z' />
        <Path d='M12.94 38.82H9.585v3.354h3.353V38.82ZM19.314 38.82h-3.353v3.354h3.353V38.82Z' />
        <Path d='M22.517 38.82h-3.353v3.354h3.353V38.82Z' />
        <Path d='M25.705 38.82h-3.353v3.354h3.353V38.82ZM32.103 38.82H28.75v3.354h3.353V38.82Z' />
        <Path d='M35.287 38.82h-3.354v3.354h3.354V38.82ZM44.88 38.82h-3.353v3.354h3.354V38.82Z' />
        <Path d='M48.064 38.82h-3.353v3.354h3.353V38.82ZM54.455 38.82h-3.353v3.354h3.353V38.82ZM60.83 38.82h-3.353v3.354h3.353V38.82ZM80.006 38.82h-3.354v3.354h3.354V38.82ZM6.537 42.023H3.184v3.354h3.353v-3.354ZM12.94 42.023H9.585v3.354h3.353v-3.354ZM25.705 42.023h-3.353v3.354h3.353v-3.354ZM35.287 42.023h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M44.88 42.023h-3.353v3.354h3.354v-3.354ZM51.256 42.023h-3.354v3.354h3.354v-3.354ZM57.639 42.023h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M60.83 42.023h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M64.037 42.023h-3.354v3.354h3.354v-3.354ZM76.814 42.023h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M80.006 42.023h-3.354v3.354h3.354v-3.354ZM3.353 45.217H0v3.353h3.353v-3.353Z' />
        <Path d='M6.537 45.217H3.184v3.353h3.353v-3.353ZM12.94 45.217H9.585v3.353h3.353v-3.353ZM19.314 45.217h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M22.517 45.217h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M25.705 45.217h-3.353v3.353h3.353v-3.353ZM38.482 45.217H35.13v3.353h3.353v-3.353ZM51.256 45.217h-3.354v3.353h3.354v-3.353ZM57.639 45.217h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M60.83 45.217h-3.353v3.353h3.353v-3.353ZM70.416 45.217h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M73.607 45.217h-3.353v3.353h3.353v-3.353ZM80.006 45.217h-3.354v3.353h3.354v-3.353ZM9.752 48.41H6.398v3.354h3.354V48.41ZM19.314 48.41h-3.353v3.354h3.353V48.41ZM25.705 48.41h-3.353v3.354h3.353V48.41Z' />
        <Path d='M28.888 48.41h-3.353v3.354h3.353V48.41ZM44.88 48.41h-3.353v3.354h3.354V48.41ZM51.256 48.41h-3.354v3.354h3.354V48.41Z' />
        <Path d='M54.455 48.41h-3.353v3.354h3.353V48.41ZM60.83 48.41h-3.353v3.354h3.353V48.41Z' />
        <Path d='M64.037 48.41h-3.354v3.354h3.354V48.41Z' />
        <Path d='M67.232 48.41H63.88v3.354h3.353V48.41Z' />
        <Path d='M70.416 48.41h-3.353v3.354h3.353V48.41ZM3.353 51.594H0v3.353h3.353v-3.353Z' />
        <Path d='M6.537 51.594H3.184v3.353h3.353v-3.353ZM12.94 51.594H9.585v3.353h3.353v-3.353Z' />
        <Path d='M16.13 51.594h-3.353v3.353h3.354v-3.353ZM22.517 51.594h-3.353v3.353h3.353v-3.353ZM35.287 51.594h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M38.482 51.594H35.13v3.353h3.353v-3.353Z' />
        <Path d='M41.69 51.594h-3.354v3.353h3.353v-3.353Z' />
        <Path d='M44.88 51.594h-3.353v3.353h3.354v-3.353ZM54.455 51.594h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M57.639 51.594h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M60.83 51.594h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M64.037 51.594h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M67.232 51.594H63.88v3.353h3.353v-3.353ZM76.814 51.594h-3.353v3.353h3.353v-3.353ZM28.888 54.787h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M32.103 54.787H28.75v3.353h3.353v-3.353ZM41.69 54.787h-3.354v3.353h3.353v-3.353ZM54.455 54.787h-3.353v3.353h3.353v-3.353ZM67.232 54.787H63.88v3.353h3.353v-3.353ZM80.006 54.787h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M32.103 57.99H28.75v3.354h3.353V57.99Z' />
        <Path d='M35.287 57.99h-3.354v3.354h3.354V57.99ZM41.69 57.99h-3.354v3.354h3.353V57.99Z' />
        <Path d='M44.88 57.99h-3.353v3.354h3.354V57.99Z' />
        <Path d='M48.064 57.99h-3.353v3.354h3.353V57.99ZM54.455 57.99h-3.353v3.354h3.353V57.99ZM60.83 57.99h-3.353v3.354h3.353V57.99ZM67.232 57.99H63.88v3.354h3.353V57.99ZM80.006 57.99h-3.354v3.354h3.354V57.99ZM28.888 61.174h-3.353v3.353h3.353v-3.353ZM35.287 61.174h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M38.482 61.174H35.13v3.353h3.353v-3.353Z' />
        <Path d='M41.69 61.174h-3.354v3.353h3.353v-3.353ZM48.064 61.174h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M51.256 61.174h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M54.455 61.174h-3.353v3.353h3.353v-3.353ZM67.232 61.174H63.88v3.353h3.353v-3.353ZM44.88 64.377h-3.353v3.353h3.354v-3.353Z' />
        <Path d='M48.064 64.377h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M51.256 64.377h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M54.455 64.377h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M57.639 64.377h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M60.83 64.377h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M64.037 64.377h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M67.232 64.377H63.88v3.353h3.353v-3.353ZM80.006 64.377h-3.354v3.353h3.354v-3.353ZM32.103 67.57H28.75v3.353h3.353V67.57ZM44.88 67.57h-3.353v3.353h3.354V67.57Z' />
        <Path d='M48.064 67.57h-3.353v3.353h3.353V67.57ZM67.232 67.57H63.88v3.353h3.353V67.57ZM73.607 67.57h-3.353v3.353h3.353V67.57Z' />
        <Path d='M76.814 67.57h-3.353v3.353h3.353V67.57ZM28.888 70.764h-3.353v3.353h3.353v-3.353ZM38.482 70.764H35.13v3.353h3.353v-3.353ZM54.455 70.764h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M57.639 70.764h-3.354v3.353h3.354v-3.353ZM64.037 70.764h-3.354v3.353h3.354v-3.353Z' />
        <Path d='M67.232 70.764H63.88v3.353h3.353v-3.353Z' />
        <Path d='M70.416 70.764h-3.353v3.353h3.353v-3.353ZM76.814 70.764h-3.353v3.353h3.353v-3.353Z' />
        <Path d='M80.006 70.764h-3.354v3.353h3.354v-3.353ZM28.888 73.947h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M32.103 73.947H28.75v3.354h3.353v-3.354ZM44.88 73.947h-3.353v3.354h3.354v-3.354ZM51.256 73.947h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M54.455 73.947h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M57.639 73.947h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M60.83 73.947h-3.353v3.354h3.353v-3.354Z' />
        <Path d='M64.037 73.947h-3.354v3.354h3.354v-3.354Z' />
        <Path d='M67.232 73.947H63.88v3.354h3.353v-3.354ZM28.888 77.14h-3.353v3.354h3.353v-3.353Z' />
        <Path d='M32.103 77.14H28.75v3.354h3.353v-3.353ZM38.482 77.14H35.13v3.354h3.353v-3.353Z' />
        <Path d='M41.69 77.14h-3.354v3.354h3.353v-3.353Z' />
        <Path d='M44.88 77.14h-3.353v3.354h3.354v-3.353ZM54.455 77.14h-3.353v3.354h3.353v-3.353Z' />
        <Path d='M57.639 77.14h-3.354v3.354h3.354v-3.353ZM70.416 77.14h-3.353v3.354h3.353v-3.353ZM80.006 77.14h-3.354v3.354h3.354v-3.353ZM19.002.5H0v22.355h22.355V.5h-3.353Zm0 19.002H3.353V3.853h15.649v15.649ZM76.479.5H57.477v22.355h22.355V.5h-3.353Zm0 19.002h-15.65V3.853h15.65v15.649ZM19.002 57.99H0v22.355h22.355V57.99h-3.353Zm0 19.002H3.353V61.344h15.649v15.648Z' />
        <Path d='M15.98 6.877H6.397v9.58h9.581v-9.58ZM73.46 6.877h-9.581v9.58h9.581v-9.58ZM15.98 64.377H6.397v9.581h9.581v-9.581Z' />
      </G>
    </Svg>
  )
}
