import React from 'react'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

const customerName = 'Netflix'

export const netflix: GetCustomerCopyFunc = ({ numberOfSessions, considerMeds }) => {
  return {
    customerName,
    displaySessionLimit: true,
    legalNotice: <CoverageNotice considerMeds={considerMeds} sessionLimit={numberOfSessions} />,
  }
}
