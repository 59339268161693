import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { ExtendedPathwayOption, IDENTITY_PROVIDERS, TRIAGE_ENTRY_POINTS } from '@lyrahealth-inc/shared-app-logic'
import { SetupAccountFormValues } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/setUpAccountForm/SetUpAccountForm'

import {
  CLEAR_SELECTED_PATHWAY_OPTION,
  CLEAR_TRIAGE_ENTRYPOINT,
  HIDE_REGISTRATION_TIPS,
  SET_ATTESTATIONS,
  SET_DEPENDENT_TYPES,
  SET_ETHNICITY_CATEGORIES,
  SET_REDIRECT_URL_ON_TRIAGE_EXIT,
  SET_REGISTRATION_FORM_VALUES,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_ESSENTIALS_TOPIC_IDS,
  SET_SELECTED_PATHWAY_OPTION,
  SET_SSO_IDENTITY_PROVIDER,
  SET_SSO_TOKEN,
  SET_TRIAGE_ENTRYPOINT,
  SHOW_REGISTRATION_TIPS,
  UPDATE_REGISTRATION_FORM_VALUES,
} from './registerActions'

const initialState = fromJS({
  showRegistrationTips: false,
})

const optionalFormValues = fromJS({
  userPreferredFirstName: '',
  userPreferredLastName: '',
})

export default createReducer(initialState, {
  [SET_ATTESTATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('attestations', action.data)
    return $$state
  },
  [SET_DEPENDENT_TYPES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('dependentTypes', action.dependentTypes)
  },
  [SET_ETHNICITY_CATEGORIES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('ethnicityInformation', fromJS(action.data))
  },
  [UPDATE_REGISTRATION_FORM_VALUES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const data = optionalFormValues.merge(action.data)
    const formValues = $$state.get('formValues', fromJS({})).merge(fromJS(data))
    return $$state.set('formValues', formValues)
  },
  [SET_REGISTRATION_FORM_VALUES]: ($$state: $TSFixMe, action: { data: SetupAccountFormValues }) => {
    return $$state.set('formValues', fromJS(action.data))
  },
  [SHOW_REGISTRATION_TIPS]: ($$state: $TSFixMe) => {
    return $$state.set('showRegistrationTips', true)
  },
  [HIDE_REGISTRATION_TIPS]: ($$state: $TSFixMe) => {
    return $$state.set('showRegistrationTips', false)
  },
  [SET_SELECTED_COUNTRY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('selectedCountry', fromJS(action.data))
  },
  [SET_SSO_TOKEN]: ($$state: $TSFixMe, action: { value: string }) => {
    return $$state.set('ssoToken', action.value)
  },
  [SET_SSO_IDENTITY_PROVIDER]: ($$state: $TSFixMe, action: { value: IDENTITY_PROVIDERS }) => {
    return $$state.set('ssoIdentityProvider', action.value)
  },
  [SET_SELECTED_PATHWAY_OPTION]: ($$state: $TSFixMe, action: { data: ExtendedPathwayOption }) => {
    return $$state.set('selectedPathwayOption', action.data)
  },
  [CLEAR_SELECTED_PATHWAY_OPTION]: ($$state: $TSFixMe) => {
    $$state = $$state.remove('selectedPathwayOption')
    return $$state
  },
  [SET_TRIAGE_ENTRYPOINT]: ($$state: $TSFixMe, action: { data: TRIAGE_ENTRY_POINTS }) => {
    $$state = $$state.set('triageEntrypoint', action.data)
    return $$state
  },
  [SET_REDIRECT_URL_ON_TRIAGE_EXIT]: ($$state: $TSFixMe, action: { data: string }) => {
    $$state = $$state.set('redirectUrlOnTriageExit', action.data)
    return $$state
  },
  [CLEAR_TRIAGE_ENTRYPOINT]: ($$state: $TSFixMe) => {
    $$state = $$state.remove('triageEntrypoint')
    $$state = $$state.remove('redirectUrlOnTriageExit')
    return $$state
  },
  [SET_SELECTED_ESSENTIALS_TOPIC_IDS]: ($$state: $TSFixMe, action: { data: ExtendedPathwayOption }) => {
    return $$state.set('selectedEssentialsTopicIds', action.data)
  },
})
