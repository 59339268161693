import React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const VideoSessionIcon: React.FC<VideoSessionIconProps> = ({ backgroundColor, fillColor, size = 24 }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='2 2 20 20' fill='none'>
      <Circle cx={12} cy={12} r={10} fill={backgroundColor || colors.backgroundHighlightPrimary} />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 9h4.75c.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5H8c-.83 0-1.5-.67-1.5-1.5v-3C6.5 9.67 7.17 9 8 9Zm10 5.375v-4.75a.378.378 0 0 0-.185-.325.389.389 0 0 0-.37-.005l-2.25 1.25a.385.385 0 0 0-.195.33v2.25c0 .135.075.26.195.33l2.25 1.25a.37.37 0 0 0 .18.045.373.373 0 0 0 .375-.375Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type VideoSessionIconProps = {
  backgroundColor?: string
  fillColor?: string
  size?: number | string
}
