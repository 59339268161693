import React, { FunctionComponent } from 'react'

import { ExploreOptionsIllustrationIconProps } from './ExploreOptionsIllustrationIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const ExploreOptionsIllustrationIconSvg = withSuspense({
  component: React.lazy(() => import('./ExploreOptionsIllustrationIconSvg')),
})

export const ExploreOptionsIllustrationIcon: FunctionComponent<ExploreOptionsIllustrationIconProps> = ({ size }) => {
  return <ExploreOptionsIllustrationIconSvg size={size} />
}
