import React, { Suspense } from 'react'

export const withSuspense = <P extends object>({
  component: Component,
}: {
  component: React.LazyExoticComponent<React.ComponentType<P>>
}) => {
  return (props: any) => (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  )
}
