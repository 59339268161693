import { CMS_MICROSITE_MESSAGE_KEY } from '../messages/messages'
import { CMSMicrositeMetadata } from '../types'

export const neurodiversityToolkitMetadata: CMSMicrositeMetadata = {
  micrositeId: 'NeurodiversityToolkit',
  iFrameSrcUrl: '/neurodiversity',
  micrositeUrl: 'neurodiversity',
  title: {
    id: 'neurodiversityToolkit.title',
    defaultMessage: 'Understanding Neurodiversity',
    description: 'Microsite page title',
  },
  featureFlag: 'isNeurodiversityToolkitEnabled',
  customerPropertyConditions: [
    {
      key: 'essentialsDisabled',
      value: true,
    },
  ],
  postMessageEvents: [
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_FORKED_TRIAGE,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR,
      params: {
        webinarLink: '/secure/index/essentials/events/neurodiversity-and-workplace-well-being',
      },
    },
    { key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WEBINAR_EVENTS },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_REGISTRATION,
    },
    { key: CMS_MICROSITE_MESSAGE_KEY.CLICK_SELF_CARE_CONTENT },
  ],
  page: '202504 neurodiversity',
  trackUTMParams: {
    utm_campaign: 'neurodiversity202504',
  },
  customMixpanelEventProps: {
    toolkit_topic: 'understanding neurodiversity',
  },
  shouldSupportSpanish: false,
  shouldHideForInternational: true,
}
