import React, { FunctionComponent } from 'react'

import { EssentialsOnboardingIconProps } from './EssentialsOnboardingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const EssentialsOnboardingIconSvg = withSuspense({
  component: React.lazy(() => import('./EssentialsOnboardingIconSvg')),
})

export const EssentialsOnboardingIcon: FunctionComponent<EssentialsOnboardingIconProps> = ({ width, height }) => {
  return <EssentialsOnboardingIconSvg width={width} height={height} />
}
