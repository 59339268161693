import React from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { View } from 'react-native'

import { CSSObject } from 'styled-components'
import styled from 'styled-components/native'

import { getFormattedMessageSafe } from '@lyrahealth-inc/shared-app-logic/src/features/intl'
import { IS_WEB } from '@lyrahealth-inc/ui-core-crossplatform'

import { BodyText } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { ThemeType } from '../../utils'

const ValuePropsListContainer = styled.View<{ theme: ThemeType; isCondensedVersion?: boolean }>(
  ({ theme: { spacing, breakpoints }, isCondensedVersion }) => ({
    flexDirection: breakpoints.isMinWidthLaptop ? 'column' : 'row',
    alignItems: breakpoints.isMinWidthLaptop ? 'flex-start' : 'center',
    gap: isCondensedVersion ? spacing['8px'] : spacing['32px'],
    flexWrap: 'wrap',
    ...(isCondensedVersion && { margin: 'auto', marginBottom: spacing['32px'] }),
  }),
)

const ValuePropsItem = styled.View<{ theme: ThemeType; isCondensedVersion?: boolean }>(
  ({ theme: { spacing, breakpoints }, isCondensedVersion }) => {
    const baseStyles: CSSObject = {
      flexDirection: 'row',
      gap: spacing['12px'],
      alignItems: 'center',
    }

    if (breakpoints.isMinWidthLaptop && !isCondensedVersion) {
      return {
        ...baseStyles,
        flexGrow: 1,
        width: '100%',
      }
    }

    if (breakpoints.isMobileSized) {
      return {
        ...baseStyles,
        ...(isCondensedVersion
          ? {
              ...(IS_WEB ? { margin: 'auto' } : { width: '100%', justifyContent: 'center' }),
            }
          : { width: 'fit-content' }),
      }
    }

    return {
      ...baseStyles,
      ...(isCondensedVersion ? { margin: 'auto' } : { width: `calc(50% - ${spacing['16px']})` }),
    }
  },
)

const ValuePropsItemTextContainer = styled.View({
  flexShrink: 1,
  ...(IS_WEB && {
    flexWrap: 'wrap',
  }),
})

const ValuePropsItemIcon = styled.View<{ theme: ThemeType }>(({ theme: { spacing, colors } }) => ({
  height: spacing['56px'],
  width: spacing['56px'],
}))

export type ValuePropsItemType = {
  icon: React.ReactNode
  title: string | MessageDescriptor
}

export type ValuePropsIconListProps = {
  items: ValuePropsItemType[]
  isCondensedVersion?: boolean
}

export const ValuePropsIconList: React.FC<ValuePropsIconListProps> = ({ items, isCondensedVersion }) => {
  const { formatMessage } = useIntl()
  return (
    <ValuePropsListContainer isCondensedVersion={isCondensedVersion}>
      {items.map(({ title, icon }, index) => (
        <ValuePropsItem key={index} isCondensedVersion={isCondensedVersion}>
          {isCondensedVersion ? <View>{icon}</View> : <ValuePropsItemIcon>{icon}</ValuePropsItemIcon>}
          <ValuePropsItemTextContainer>
            <BodyText size={BodyTextSize.LARGE} text={getFormattedMessageSafe(title, formatMessage)} />
          </ValuePropsItemTextContainer>
        </ValuePropsItem>
      ))}
    </ValuePropsListContainer>
  )
}
