import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const SideBarIcon: FunctionComponent<SideBarIconProps> = ({ size = 24, color }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('FilterIcon')}>
      <Path
        d='M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19L3 5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3L19 3C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5L21 19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21L5 21ZM16 19H19L19 5H16V19ZM14 19L14 5L5 5L5 19H14Z'
        fill={color ?? colors.iconDefault}
      />
    </Svg>
  )
}

type SideBarIconProps = {
  size?: string | number
  color?: string
}
