import { useCallback, useEffect, useRef } from 'react'

/**
 * Credit to Dani Gámez Franco aka Danziger
 */

/**
 * @param delay If the throttle delay value is expected to change, pass in a ref object to make sure the callback uses the most up to date delay value.
 */
export function useThrottledCallback<A extends any[]>(
  callback: (...args: A) => void,
  delay: number | null | React.MutableRefObject<number>,
  ignoreThrottledCallback?: boolean,
): [(...args: A) => void, () => void] {
  const timeoutRef = useRef<number>()
  const callbackRef = useRef(callback)
  const lastCalledRef = useRef(0)
  const getDelay = useCallback(() => (typeof delay === 'object' ? delay?.current : delay), [delay])

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setTimeout kicks in, it
  // will still call your old callback.

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // The cancel function is crucial for handling race conditions in async
  // operations. For example, consider a form with auto-save:
  // 1. User selects response, triggering throttled auto-save (status: 'draft')
  // 2. User clicks submit button (status: 'completed')
  // 3. Without cancellation, pending throttled auto-saves could execute after
  //    submission, incorrectly setting status back to 'draft'
  //
  // The cancel function prevents these race conditions by clearing any pending
  // throttled callbacks when we need immediate execution (like form submission).
  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
    }
  }, [])

  // Clear timeout if the components is unmounted or the delay changes:
  useEffect(cancel, [delay, cancel])

  return [
    useCallback(
      (...args: A) => {
        const currentDelay = getDelay()
        // Clear previous timer:
        window.clearTimeout(timeoutRef.current)
        function invoke() {
          callbackRef.current(...args)
          lastCalledRef.current = Date.now()
        }

        // Calculate elapsed time:
        const elapsed = Date.now() - lastCalledRef.current
        if (currentDelay !== undefined) {
          if (elapsed >= currentDelay) {
            // If already waited enough, call callback:
            invoke()
          } else {
            // Otherwise, ignore the callback if needed or wait a bit more:
            if (ignoreThrottledCallback) {
              return
            }
            timeoutRef.current = window.setTimeout(invoke, currentDelay - elapsed)
          }
        }
      },
      [getDelay, ignoreThrottledCallback],
    ),
    cancel,
  ]
}
