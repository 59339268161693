import React, { FunctionComponent, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import {
  COMMON_MIXPANEL_PROPERTIES,
  PATHWAYS_EVENTS,
  PATHWAYS_PAGE,
  PATHWAYS_PROPERTIES,
  PATHWAYS_TYPE,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { openUrl, ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'
import { ComingSoonMicrositeHomePage } from '@lyrahealth-inc/ui-core-crossplatform/src/microsite'

import { LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK } from '../../../../common/constants/appConstants'
import { useCustomerCostProperties } from '../../../../common/hooks/useCustomerCostProperties'
import { useIsCostMessagingEnabled } from '../../../../common/hooks/useIsCostMessagingEnabled'
import { useMicrositeCareOfferingDescriptionType } from '../../../../common/hooks/useMicrositeCareOfferingDescriptionType'
import { useNavigateToEssentialsContent } from '../../../../common/hooks/useNavigateToEssentialsContent'
import { useUpdatedMicrositeConfig } from '../../../../common/hooks/useUpdatedMicrositeConfig'
import { doesUserHaveMembershipInCustomer } from '../../../../common/utils/customerUtils'
import { setToastContent } from '../../../../data/appGlobals/appGlobalsActions'
import { getDeviceUUID } from '../../../../data/appGlobals/appGlobalsSelectors'
import {
  getBeforeLaunch,
  getCustomerInfo,
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPropertiesMap,
  getEssentialsDisabled,
  getLegalNotice,
  getNumberOfSessions,
  getNumberOfSessionsByProgram,
  getProgramMembershipType,
} from '../../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../../data/mixpanel'
import { TrackEventWithObjAction } from '../../../../data/mixpanel/types'
import { getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer } from '../../../../data/user/userSelectors'
import { doPageLoad } from '../../data/micrositeActions'

interface ComingSoonMicrositeHomeContainerProps {
  actions: {
    doPageLoad: (customerName: string, eventProps?: Dict, page?: string) => void
    trackEventWithObj: TrackEventWithObjAction
    setToastContent: (content: ToastContent) => void
  }
}

const ComingSoonMicrositeHomeContainer: FunctionComponent<ComingSoonMicrositeHomeContainerProps> = ({
  actions: { doPageLoad, trackEventWithObj },
}) => {
  const { isRegistrationAndLoginDisabled, isProgramLevelSessionLimitEnabled } = useFlags()
  const { activeLanguage } = useLyraIntl()
  const startDate = useSelector(getCustomerLaunchDate)
  const customerName = useSelector(getCustomerName)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  const membershipType = useSelector(getProgramMembershipType)
  const beforeLaunch = useSelector(getBeforeLaunch)
  const legalNotice = useSelector(getLegalNotice)
  const customerPropertiesMap = useSelector(getCustomerPropertiesMap)?.toJS()
  const hasMembershipInCustomer = doesUserHaveMembershipInCustomer({ membershipType })
  const deviceUUID = useSelector(getDeviceUUID)
  const essentialsDisabled = useSelector(getEssentialsDisabled)
  const numberOfSessions = useSelector(getNumberOfSessions)
  const numberOfSessionsByProgram = useSelector(getNumberOfSessionsByProgram)
  const customerDisplayName = useSelector(getCustomerInfo)

  const updatedMicrositeConfig = useUpdatedMicrositeConfig(PATHWAYS_TYPE.COMING_SOON)
  const careOfferingDescriptionType = useMicrositeCareOfferingDescriptionType(PATHWAYS_TYPE.COMING_SOON)

  const customerCostProperties = useCustomerCostProperties()
  const isCostMessagingEnabled = useIsCostMessagingEnabled()
  const navigateToEssentialsContent = useNavigateToEssentialsContent()

  useEffect(() => {
    if (customerName) {
      doPageLoad(
        customerName,
        {
          [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
          ...(customerName !== 'care' && {
            [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_NAME]: customerName,
          }),
        },
        PATHWAYS_PAGE.COMING_SOON,
      )
    }
  }, [customerName, doPageLoad, deviceUUID])

  const onLegalFooterLinkPress = (url: string) => {
    openUrl(url)
  }
  const onNotifyMeOnLaunchPress = () => {
    window.location.href = `${LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK}?customer=${customerName}&lang=${activeLanguage}`
    trackEventWithObj({
      [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
      event: PATHWAYS_EVENTS.CLICK_NOTIFY_ME_ON_LAUNCH,
      page: PATHWAYS_PAGE.COMING_SOON,
    })
  }

  return (
    <ComingSoonMicrositeHomePage
      micrositeConfig={updatedMicrositeConfig}
      heroSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        startDate,
        onNotifyMeOnLaunchPress,
        isRegistrationAndLoginDisabled,
        onExploreOnYourOwnCTAPress: () => {
          document
            .querySelector('[data-testid="HeroSection-pathwaysHomepage"]')
            ?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_EXPLORE_ON_YOUR_OWN,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
      }}
      lyraOfferingsSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        customerPropertiesMap,
        isInternational: isUserAssumedInternational,
        meetWithAnExpertTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_MEET_WITH_AN_EXPERT_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
        wellnessResourcesTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_CONTENT_LIBRARY_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
        lyraOfferingsTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_ALL_OFFERINGS_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
      }}
      selfCareSectionProps={{
        language: activeLanguage,
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        onContentClicked: (data) => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_SELF_CARE_RESOURCE,
            page: PATHWAYS_PAGE.COMING_SOON,
            article_name: data.title,
          })
          navigateToEssentialsContent({ slug: data.slug, newWindow: true })
        },
      }}
      careNavigatorSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
      }}
      faqSectionProps={{
        membershipType,
        beforeLaunch,
        isRegistrationAndLoginDisabled,
        startDate,
        hasMembershipInCustomer,
        isProgramLevelSessionLimitEnabled,
        onNotifyMeOnLaunchPress,
        onQuestionPress: (title: string) => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_FAQS,
            page: PATHWAYS_PAGE.COMING_SOON,
            [PATHWAYS_PROPERTIES.FAQ_QUESTION]: title,
          })
        },
        customerCostProperties,
        isCostMessagingEnabled,
      }}
      legalAndCopyrightFooterProps={{
        onFooterLinkPressed: onLegalFooterLinkPress,
        isInternational: isUserAssumedInternational,
        legalNotice,
      }}
      membershipSectionProps={{
        customerDisplayName,
        numberOfSessions,
        numberOfSessionsByProgram,
        isProgramLevelSessionLimitEnabled,
        careOfferingDescriptionType,
        isEssentialsDisabled: essentialsDisabled,
        language: activeLanguage,
        pathwayType: PATHWAYS_TYPE.COMING_SOON,
      }}
      mobileAppPromoSectionProps={{ pathwaysType: PATHWAYS_TYPE.COMING_SOON }}
    />
  )
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  return {
    actions: bindActionCreators({ doPageLoad, trackEventWithObj, setToastContent }, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ComingSoonMicrositeHomeContainer)
