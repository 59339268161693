import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const NewWindowIcon: FunctionComponent<NewWindowIconProps> = ({ size = 24, color }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('NewWindowIcon')}>
      <Path
        stroke={color ?? colors.iconDefault}
        strokeLinecap='round'
        strokeWidth={2}
        d='M7.125 8H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-2.241'
      />
      <Path
        stroke={color ?? colors.iconDefault}
        strokeLinecap='round'
        strokeWidth={2}
        d='M11 5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5Z'
      />
    </Svg>
  )
}

type NewWindowIconProps = {
  size?: string | number
  color?: string
}
