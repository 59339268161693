import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { routesEnabledInNativeApp, SESSION_STORAGE_KEY } from '@lyrahealth-inc/shared-app-logic'
import { useGetIsMobileWebBrowser } from '@lyrahealth-inc/ui-core-crossplatform'

import { getIsUserLoggedIn } from '../../data/user/userSelectors'

const usePathChanged = () => {
  const { pathname } = useLocation()
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isMobileBrowser = useGetIsMobileWebBrowser()
  const prevPathname = useRef('')

  // Fix scroll issues on mobile chrome. Contain this to the logged out site only, in case anything in app needs to maintain scroll position.
  useEffect(() => {
    if (isMobileBrowser && !isLoggedIn && prevPathname.current !== pathname) {
      scroll(0, 0)
    }
    prevPathname.current = pathname
  }, [isLoggedIn, isMobileBrowser, pathname])

  useEffect(() => {
    if (routesEnabledInNativeApp.includes(pathname)) {
      window.sessionStorage.setItem(SESSION_STORAGE_KEY.NATIVE_APP_FROM_LW, 'true')
      window.location.reload()
    }
  }, [pathname])
}

export default usePathChanged
