import React from 'react'
import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { servicesCovered as typesOfServices } from './default'
import { excludedServicesNotice } from './defaultWithoutMeds'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

export const amgen: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  const servicesCovered = `${typesOfServices} Lyra has partnered with Amgen to cover the cost of outpatient therapy for as many in-person or live video sessions as are clinically indicated by your Lyra provider, up to ${numberOfSessions} sessions, as long as you are eligible for benefits from your employer.`
  return {
    servicesCovered,
    legalNotice: (
      <CoverageNotice
        excludedServices={excludedServicesNotice}
        sessionLimit={numberOfSessions}
        sessionCopy={defineMessage({
          defaultMessage:
            'Your Lyra benefit covers the cost of as many in-person or live video sessions as are clinically indicated by your Lyra provider, up to {sessionLimit} sessions per person, per issue, per year, as long as you are eligible for benefits from your employer.',
          description: 'Text for describing the session limit, displayed as part of the coverage notice',
        })}
      />
    ),
  }
}
