import React, { ComponentType, FunctionComponent, useCallback } from 'react'
import { connect } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  BenefitGroup,
  CallTimeParams,
  SEARCHER_TYPE_TO_MATCH_FOR,
  useFlags,
  useGetBenefitsEventProps,
} from '@lyrahealth-inc/shared-app-logic'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getAllBenefitsForUser, getRecommendedBenefitsForUser } from '../../data/benefits/benefitsSelector'
import { getCustomerId, getIsLyraNicotineQuitlineEnabled } from '../../data/customer/customerSelectors'
import { MixpanelEvent, trackEventWithObj } from '../../data/mixpanel/index'
import { getId, getUserCountryName } from '../../data/user/userSelectors'

export type TrackIntegratedBenefits = (params: CallTimeParams) => void

export interface InjectedProps {
  _userLyraId: string
  _customerLyraId: number
  _registeredCountry: string
  _matchFor: SEARCHER_TYPE_TO_MATCH_FOR
  _recommendedBenefitGroupsForUser: BenefitGroup[]
  _allBenefitGroupsForUser: BenefitGroup[]
  _lyraNicotineQuitlineEnabled: boolean
  _trackEventWithObj: (event: MixpanelEvent) => void
}

export const withTrackIntegratedBenefits = <P extends object>(
  Component: ComponentType<P & { trackIntegratedBenefits: TrackIntegratedBenefits }>,
): ComponentType<Omit<P, 'trackIntegratedBenefits'>> => {
  const WithTrackIntegratedBenefits: FunctionComponent<P & InjectedProps> = ({
    _userLyraId,
    _customerLyraId,
    _registeredCountry,
    _matchFor,
    _recommendedBenefitGroupsForUser,
    _allBenefitGroupsForUser,
    _lyraNicotineQuitlineEnabled,
    _trackEventWithObj,
    ...rest
  }) => {
    const { maxBenefitsToShowInExplorer } = useFlags()
    const getBenefitsEventProps = useGetBenefitsEventProps({
      registeredCountry: _registeredCountry,
      matchFor: _matchFor,
      userLyraId: _userLyraId,
      customerLyraId: _customerLyraId,
      recommendedBenefitGroupsForUser: _recommendedBenefitGroupsForUser,
      allBenefitGroupsForUser: _allBenefitGroupsForUser,
      lyraNicotineQuitlineEnabled: _lyraNicotineQuitlineEnabled,
      maxBenefitsToShowInExplorer,
    })

    const trackIntegratedBenefits = useCallback(
      (params: CallTimeParams) => {
        const { event, entryPoint } = params
        _trackEventWithObj({
          event,
          page: entryPoint,
          ...getBenefitsEventProps(params),
        })
      },
      [getBenefitsEventProps, _trackEventWithObj],
    )

    return <Component trackIntegratedBenefits={trackIntegratedBenefits} {...(rest as P)} />
  }

  const mapStateToProps = ($$state: any) => {
    return {
      _userLyraId: getId($$state),
      _customerLyraId: getCustomerId($$state),
      _matchFor: $$state.getIn(['triageDemographics', 'matchFor']),
      _recommendedBenefitGroupsForUser: getRecommendedBenefitsForUser($$state),
      _allBenefitGroupsForUser: getAllBenefitsForUser($$state),
      _registeredCountry: getUserCountryName($$state),
      _lyraNicotineQuitlineEnabled: getIsLyraNicotineQuitlineEnabled($$state),
    }
  }

  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
      {
        _trackEventWithObj: trackEventWithObj,
      },
      dispatch,
    )
  }

  // @ts-ignore
  return connect(mapStateToProps, mapDispatchToProps)(toJS(WithTrackIntegratedBenefits))
}
