import { AUTO_ENROLLMENT_REGISTRATION_PATH } from '../../../common/constants/registrationFormConstants'

export function isInRegisterFlow(pathname: string) {
  return (
    isPathInAutoEnrollmentFlow(pathname) ||
    ['/register/account-setup', '/register/verify-account', '/register/verify', '/register/connect'].some((path) =>
      pathname.startsWith(path),
    )
  )
}

export function isPathInAutoEnrollmentFlow(pathname: string) {
  return pathname.startsWith(AUTO_ENROLLMENT_REGISTRATION_PATH)
}
