import React from 'react'
import { useIntl } from 'react-intl'

import ProviderCalendarCheckmarkIllustrationUrl from '../../assets/providerCalendarCheckmark.png'
import { Image } from '../image/Image'

interface ProviderCalendarCheckmarkIllustrationProps {
  width?: number
  height?: number
}

export function ProviderCalendarCheckmarkIllustration({
  width = 140,
  height = 122,
  ...props
}: ProviderCalendarCheckmarkIllustrationProps) {
  const { formatMessage } = useIntl()
  return (
    <Image
      source={ProviderCalendarCheckmarkIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      accessibilityLabel={formatMessage({
        defaultMessage: 'Illustration of a calendar with a checkmark',
        description: 'Accessibility label for calendar illustration',
      })}
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
