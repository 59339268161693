import React, { FunctionComponent } from 'react'
import Svg, { G, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export interface SparklesIconProps {
  size?: number | string
  color?: string
  filled?: boolean
}

export const SparkleSingleIcon: FunctionComponent<SparklesIconProps> = ({ size = 24, color, filled = true }) => {
  const { colors } = useTheme()
  const fillColor = filled ? color || colors.iconAccent : colors.backgroundPrimary

  return (
    <Svg testID={tID('SingleSparklesIcon')} width={size} height={size} viewBox='0 0 18 18' fill='none'>
      <G id='Group 1073712622'>
        <Path
          id='Vector'
          d='M9.07338 15.472C8.73793 15.472 8.45974 15.1938 8.45974 14.8583C8.45974 12.011 6.05429 9.61377 3.2152 9.61377C2.87974 9.61377 2.60156 9.33559 2.60156 9.00014C2.60156 8.66468 2.87974 8.3865 3.2152 8.3865C6.06247 8.3865 8.45974 5.98105 8.45974 3.14196C8.45974 2.8065 8.73793 2.52832 9.07338 2.52832C9.40883 2.52832 9.68702 2.8065 9.68702 3.14196C9.68702 5.98923 12.0925 8.3865 14.9316 8.3865C15.267 8.3865 15.5452 8.66468 15.5452 9.00014C15.5452 9.33559 15.267 9.61377 14.9316 9.61377C12.0843 9.61377 9.68702 12.0192 9.68702 14.8583C9.68702 15.1938 9.40883 15.472 9.07338 15.472Z'
          fill={fillColor || colors.iconAccent}
        />
      </G>
    </Svg>
  )
}
