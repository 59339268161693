/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { FunctionComponent } from 'react'

import { SparklesAlternateIconProps } from './SparklesAlternateIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const SparklesAlternateIconSvg = withSuspense({
  component: React.lazy(() => import('./SparklesAlternateIconSvg')),
})

export const SparklesAlternateIcon: FunctionComponent<SparklesAlternateIconProps> = ({ size, ...props }) => {
  return <SparklesAlternateIconSvg size={size} {...props} />
}
