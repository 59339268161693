import {
  PathwayOptionToRedirectUrlMapping,
  PathwaysRedirectUrls,
  TEEN_ENABLED_PATHWAYS,
} from '@lyrahealth-inc/shared-app-logic'

/**
 * Updates the url key in storage and returns the new redirect url. Override pathways options that aren't enabled for teens with the self search pathway
 */
export const updateRedirectPathForTeen = (
  redirectUrl: string,
  isTeenUser: boolean,
  updateStorage: (url: string) => void,
) => {
  const teenEnabledPaths = TEEN_ENABLED_PATHWAYS.map((pathway) => PathwayOptionToRedirectUrlMapping[pathway]).filter(
    (item) => item !== undefined,
  ) as string[]
  if (isTeenUser && !teenEnabledPaths.includes(redirectUrl)) {
    redirectUrl = PathwaysRedirectUrls.SELF_TRIAGE
    updateStorage(redirectUrl)
  }
  return redirectUrl
}
