import {
  AvailabilityPreferenceV3ExperimentPayloadParams,
  GenerateSearchForHeaderHomebaseExperimentPayloadParams,
  S4CHomebaseHeaderQ12025PayloadParams,
  UrgencyToBookExperimentPayloadParams,
} from './searchForCareExperimentTypes'
import { ExperimentPayload, ExperimentUUIDs } from './types'

export const generateLatestMatchesExperimentPayload = ({
  arm,
  client_lyra_id,
  device_uuid,
}: {
  arm: string
  client_lyra_id: string
  device_uuid: string
}): ExperimentPayload => ({
  experimentUUID: ExperimentUUIDs.LatestMatchesUUID,
  deviceUUID: device_uuid,
  experiments: {
    [ExperimentUUIDs.LatestMatchesUUID]: {
      assignment: [
        {
          arm,
          joinKeys: {
            client_lyra_id,
          },
        },
      ],
    },
  },
})

export const generateSearchForCareHomebaseHeaderExperimentPayload = ({
  arm,
  metric,
  customer_name,
  device_uuid,
  description,
  client_lyra_id,
}: GenerateSearchForHeaderHomebaseExperimentPayloadParams): ExperimentPayload => {
  return {
    experimentUUID: ExperimentUUIDs.SearchForCareHomebaseHeaderUUID,
    deviceUUID: device_uuid,
    experiments: {
      [ExperimentUUIDs.SearchForCareHomebaseHeaderUUID]: {
        assignment: [
          {
            arm,
            joinKeys: {
              metric,
              device_uuid: device_uuid,
              customer_name,
              description,
              client_lyra_id,
            },
          },
        ],
      },
    },
  }
}

export const generateTriageSummaryExperimentPayload = ({
  arm,
  client_lyra_id,
  device_uuid,
  customer_name,
}: {
  arm: 'control' | 'experiment'
  client_lyra_id: string
  device_uuid: string
  customer_name?: string
}): ExperimentPayload => ({
  experimentUUID: ExperimentUUIDs.TriageSummaryUUID,
  deviceUUID: device_uuid,
  experiments: {
    [ExperimentUUIDs.TriageSummaryUUID]: {
      assignment: [
        {
          arm,
          joinKeys: {
            client_lyra_id,
            device_uuid,
            customer_name,
          },
        },
      ],
    },
  },
})

export const generateAvailabiltyPreferenceV3ExperimentPayload = ({
  arm,
  client_lyra_id,
  device_uuid,
  search_id,
  page_location,
}: AvailabilityPreferenceV3ExperimentPayloadParams): ExperimentPayload => ({
  experimentUUID: ExperimentUUIDs.AvailabilityPreferenceV3UUID,
  deviceUUID: device_uuid,
  experiments: {
    [ExperimentUUIDs.AvailabilityPreferenceV3UUID]: {
      assignment: [
        {
          arm,
          joinKeys: {
            client_lyra_id,
            search_id,
            page_location,
          },
        },
      ],
    },
  },
})

export const generateUrgencyToBookExperimentPayload = ({
  arm,
  client_lyra_id,
  device_uuid,
  search_id,
  matcher_request_id,
  provider_id,
  page_location,
}: UrgencyToBookExperimentPayloadParams): ExperimentPayload => ({
  experimentUUID: ExperimentUUIDs.UrgencyToBookUUID,
  deviceUUID: device_uuid,
  experiments: {
    [ExperimentUUIDs.UrgencyToBookUUID]: {
      assignment: [
        {
          arm,
          joinKeys: {
            client_lyra_id,
            search_id,
            provider_id,
            page_location,
            matcher_request_id,
          },
        },
      ],
    },
  },
})

export const generateS4CHomebaseHeaderQ12025ExperimentPayload = ({
  arm,
  action,
  client_lyra_id,
  search_id,
  triage_need,
  toolkit_topic,
  device_uuid,
  patient,
  has_started_search_for_self,
}: S4CHomebaseHeaderQ12025PayloadParams): ExperimentPayload => ({
  experimentUUID: ExperimentUUIDs.S4CHomebaseHeaderQ12025UUID,
  deviceUUID: device_uuid,
  experiments: {
    [ExperimentUUIDs.S4CHomebaseHeaderQ12025UUID]: {
      assignment: [
        {
          arm,
          joinKeys: {
            client_lyra_id,
            action,
            search_id,
            triage_need,
            toolkit_topic,
            patient,
            has_started_search_for_self,
          },
        },
      ],
    },
  },
})

export const isEmailCapturePageExperimentV2InControlGroup = (flagValue: string) => {
  return flagValue === 'Control'
}
