import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { GestureResponderEvent } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { XIcon } from '../../atoms/icons'
import { TertiaryButton as TertiaryTextButton } from '../../atoms/tertiaryButton/TertiaryButton'
import TertiaryIconButton from '../../atoms/tertiaryIconButton/TertiaryIconButton'
import { tID } from '../../utils'

export interface XButtonProps {
  onPress: (event: GestureResponderEvent) => void
  fillColor?: string
  showExitText?: boolean
  testID?: string
  size?: number
  alternateText?: React.ReactNode
  accessibilityLabel?: string
}

const StyledPressable = styled.Pressable({
  alignItems: 'center',
  borderRadius: '50px',
  outlineWidth: 0,
})

const Exit = ({
  onPress,
  alternateText,
}: {
  onPress?: (e: GestureResponderEvent) => void
  alternateText?: React.ReactNode
}) => {
  return (
    <TertiaryTextButton
      isSmall
      text={alternateText ? alternateText : <FormattedMessage defaultMessage='Exit' description='exit button' />}
      onPress={onPress ?? noop}
    />
  )
}

export const XButton: FunctionComponent<XButtonProps> = ({
  onPress,
  fillColor,
  showExitText = true,
  testID = 'XButton',
  size = 18,
  alternateText,
  accessibilityLabel,
}) => {
  const { breakpoints, colors } = useTheme()

  return (
    <StyledPressable onPress={onPress} testID={tID(testID)}>
      {!breakpoints.isMobileSized && showExitText ? (
        <Exit onPress={onPress} alternateText={alternateText} />
      ) : (
        <TertiaryIconButton
          size={ButtonSize.SMALL}
          leftIcon={<XIcon fillColor={fillColor || colors.iconDefault} size={size} />}
          onPress={onPress}
          accessibilityLabel={accessibilityLabel}
        />
      )}
    </StyledPressable>
  )
}
