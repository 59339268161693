import React, { FunctionComponent, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  DEFAULT_LANGUAGE,
  ESSENTIALS_MESSAGE,
  EssentialsContentData,
  PATHWAYS_TYPE,
  useScript,
} from '@lyrahealth-inc/shared-app-logic'

import { Headline, Size as HeadlineSize } from '../../../atoms/headline/Headline'
import { PrimaryButton } from '../../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../../atoms/subhead/Subhead'
import { IS_WEB } from '../../../constants'
import { useMediaQuerySize } from '../../../hooks'
import { ThemeType, tID } from '../../../utils'

export interface PathwaysSelfCareSectionProps {
  onCTAPress?: () => void
  language: string
  pathwaysType?: PATHWAYS_TYPE
  onContentClicked?: (data: EssentialsContentData) => void
  backgroundColor?: string
}

const Container = styled.View<{ theme: ThemeType; backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.colors.backgroundPrimary,
  alignItems: 'center',
  paddingTop: '124px',
  paddingBottom: theme.breakpoints.isMobileSized ? theme.spacing['64px'] : '96px',
  zIndex: 10,
  ...(IS_WEB && { overflowX: 'hidden' }),
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && {
    paddingHorizontal: theme.spacing['16px'],
  }),
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginVertical: theme.spacing['64px'],
}))

const CreateAccountContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  maxWidth: '554px',
  zIndex: -1,
  ...(theme.breakpoints.isMobileSized && {
    paddingHorizontal: theme.spacing['16px'],
  }),
}))

const CTAButton = styled(PrimaryButton)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  alignSelf: 'center',
}))

export const PathwaysSelfCareSection: FunctionComponent<PathwaysSelfCareSectionProps> = ({
  onCTAPress = noop,
  language,
  pathwaysType,
  onContentClicked = noop,
  backgroundColor,
}) => {
  const { colors } = useTheme() as ThemeType
  const scriptStatus = useScript(
    'https://lyra-essentials-widgets.bukwild.com/resources-carousel-widget.js',
    false,
    undefined,
  )

  useEffect(() => {
    if (scriptStatus === 'ready') {
      const existingCarousel = document.querySelector('.widget.resource-carousel')
      if (existingCarousel) {
        existingCarousel.remove()
      }

      const divElement = document.createElement('div')
      divElement.setAttribute('data-resources-carousel-widget', '')
      divElement.setAttribute('data-handle', 'self-care-experience')
      divElement.setAttribute('data-locale', language === DEFAULT_LANGUAGE ? 'en' : language.toLowerCase())
      document.querySelector('[data-testid="ResourcesCarouselWidgetContainer"]')!.appendChild(divElement)
      window.ResourcesCarouselWidget.mount('[data-resources-carousel-widget]')
      const carousel = document.querySelector('.widget.resource-carousel') as HTMLDivElement
      carousel.style.backgroundColor = backgroundColor || colors.backgroundPrimary
    }
  }, [scriptStatus, language, backgroundColor, colors.backgroundPrimary])

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (
        message.origin === window.origin &&
        message.data.event === ESSENTIALS_MESSAGE.RESOURCE_CAROUSEL_WIDGET_CLICK
      ) {
        onContentClicked(message.data.data as EssentialsContentData)
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onContentClicked])

  const { isMobileSized } = useMediaQuerySize()

  const headerText = (
    <FormattedMessage
      defaultMessage='Discover meditations, articles, and more'
      description='Header text for section that shows examples of content that comes with Lyra'
    />
  )

  const subheadText = (
    <FormattedMessage
      defaultMessage='Create an account for more content'
      description='Header text that encourages user to create an account'
    />
  )

  return (
    <Container testID={tID('PathwaysSelfCareSection')} backgroundColor={backgroundColor}>
      <HeaderContainer>
        {isMobileSized ? (
          <Subhead size={SubheadSize.LARGE} text={headerText} textAlign='center' />
        ) : (
          <Headline size={HeadlineSize.SMALL} text={headerText} />
        )}
      </HeaderContainer>
      <ContentContainer testID={tID('ResourcesCarouselWidgetContainer')} />
      {pathwaysType !== PATHWAYS_TYPE.COMING_SOON && (
        <CreateAccountContainer>
          <Subhead
            size={isMobileSized ? SubheadSize.MEDIUM : SubheadSize.LARGE}
            text={subheadText}
            textAlign='center'
          />
          <CTAButton
            text={
              <FormattedMessage
                defaultMessage='Access resources'
                description='Button text that takes user to start of registration'
              />
            }
            onPress={onCTAPress}
            testID={tID('PathwaysSelfCareSection-CTAButton')}
          />
        </CreateAccountContainer>
      )}
    </Container>
  )
}
