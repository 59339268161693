import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'
import providerLedIllustrationImage from './../../assets/ProviderLedIllustration.png'

const ProviderLedIllustrationPNG = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

/**
 * Since react-native-svg does not support Mask for native, use image for specifically mobile
 */
export const ProviderLedIllustration: FunctionComponent<ImageComponentProps> = ({ width = 158, height = 158 }) => {
  return (
    <ProviderLedIllustrationPNG
      source={providerLedIllustrationImage as ImageSourcePropType}
      testID={tID(`ProviderLedIllustration`)}
      width={width}
      height={height}
    />
  )
}
