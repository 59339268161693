import React from 'react'
import { useIntl } from 'react-intl'
import { Pressable } from 'react-native'
import { useNavigate } from 'react-router-dom'

import { Map } from 'immutable'
import { useTheme } from 'styled-components/native'

import { LyraLogo } from '@lyrahealth-inc/ui-core-crossplatform'

import DefaultHeader from './DefaultHeader'
import styles from './header.module.scss'

const ErrorPageHeader = () => {
  const navigate = useNavigate()
  const customClass = styles['lt-header']
  const { colors } = useTheme()
  const intl = useIntl()

  const logoClickHandler = () => {
    navigate('/')
  }

  return (
    <DefaultHeader
      customClass={customClass}
      user={Map()}
      lyraLogo={
        <Pressable
          onPress={logoClickHandler}
          accessibilityRole='button'
          accessibilityLabel={intl.formatMessage({
            defaultMessage: 'Lyra Logo',
            description: 'Button that takes user to home page',
          })}
        >
          <LyraLogo width={53} height={30} fillColor={colors.icons.lyraLogoPrimaryIcon.fill} />
        </Pressable>
      }
      handleLogoClick={logoClickHandler}
      shouldHideLoginButton
    />
  )
}

export default ErrorPageHeader
