import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const WellnessCheckInBenefitIllustration: FunctionComponent<Props> = ({ size = 45 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fill='#fff'
        d='M22.658.152c-3.106 0-6.12.533-8.957 1.583C6.945 4.239 2.196 9.79.67 16.965c-1.009 4.68-.88 9.006.396 13.223 1.63 5.385 5.628 9.927 10.972 12.46 2.652 1.26 5.7 1.979 9.056 2.137.431.02.87.03 1.305.03 2.693 0 5.402-.369 8.052-1.096.203-.05.397-.103.602-.164 2.381-.697 4.524-1.8 6.358-3.273 1.99-1.591 3.63-3.608 4.866-5.991.243-.47.476-.964.733-1.555 1.414-3.283 2.097-6.959 1.976-10.633-.057-1.625-.298-3.352-.716-5.137-.689-2.897-1.81-5.622-3.246-7.884a21.913 21.913 0 0 0-1.112-1.577c-2.58-3.328-6.599-5.663-11.62-6.75A26.62 26.62 0 0 0 22.66.154V.152Z'
      />
      <Path
        fill='#FFE9E3'
        d='M43.056 22.163c-.055-1.557-.293-3.17-.668-4.773-.614-2.584-1.622-5.13-3.005-7.31-.321-.495-.65-.972-1.008-1.43-2.593-3.343-6.522-5.204-10.544-6.074-4.48-.971-9.244-.688-13.55.907C8.05 5.79 3.808 10.848 2.424 17.353c-.532 2.464-.687 5.25-.45 7.925h-.018c.138 1.521.413 3.005.824 4.37 1.53 5.057 5.25 9.07 9.987 11.315 2.565 1.218 5.432 1.823 8.346 1.96 2.977.139 6-.238 8.796-1.007.183-.045.357-.091.54-.146 2.19-.642 4.095-1.631 5.726-2.941 1.778-1.42 3.243-3.207 4.38-5.396.247-.477.467-.953.678-1.439 1.337-3.106 1.933-6.477 1.814-9.82l.008-.01Z'
      />
      <Mask
        id='a'
        width={43}
        height={41}
        x={1}
        y={2}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <Path
          fill='#FFE9E3'
          d='M43.056 22.163c-.055-1.556-.293-3.17-.668-4.773-.614-2.583-1.622-5.13-3.005-7.31-.321-.495-.65-.972-1.008-1.43-2.593-3.343-6.522-5.203-10.544-6.074-4.48-.971-9.244-.687-13.55.907C8.05 5.792 3.808 10.85 2.424 17.353c-.532 2.465-.687 5.25-.45 7.926h-.018a22.17 22.17 0 0 0 .824 4.37c1.53 5.056 5.25 9.07 9.987 11.314 2.565 1.218 5.432 1.823 8.346 1.961 2.977.138 6-.238 8.796-1.008.183-.045.357-.091.54-.146 2.19-.641 4.095-1.631 5.726-2.941 1.778-1.42 3.243-3.207 4.38-5.396.247-.477.467-.952.678-1.439 1.337-3.106 1.933-6.477 1.814-9.82l.008-.01Z'
        />
      </Mask>
      <G mask='url(#a)'>
        <Path
          fill='#85CCD5'
          d='M32.647 28.61c.065-.15.763-1.065 1.382-.715.843.476-2.915 7.51-6.767 8.139-3.852.629 5.385-7.424 5.385-7.424Z'
        />
        <Path
          fill='#85CCD5'
          d='M4.43 43.78c.132-.304 4.94-9.336 8.005-11.443 3.065-2.107 8.937-5.75 9.73-4.766.794.985-.601 2.294-2.41 4.118 0 0 5.12.24 6.351.316 1.231.076 4.821-4.014 5.681-4.407.86-.394 1.627-.054 1.299 1.045-.329 1.098-4.46 8.663-8.201 8.49 0 0-8.447 3.293-9.054 3.819-.607.527-3.218 3.821-3.218 3.821l-8.183-.992v-.001Z'
        />
        <Path
          fill='#85CCD5'
          d='M33.473 27.863c.026.323-.026.746-.279 1.231-.734 1.418-3.369 6.16-6.4 7.719-.91.47-1.697.666-2.351.705-1.64.096-2.49-.789-2.535-.84l.244-.227c.06.066 1.538 1.584 4.492.067 2.944-1.513 5.533-6.183 6.255-7.577.174-.336.236-.634.246-.878 0 0 .13-.124.328-.2ZM25.919 32.158h-.027c-4.004-.387-7.846-.206-7.884-.204a.166.166 0 0 1-.018-.332h.003c.039-.003 3.903-.184 7.932.204.092.01.159.091.15.182a.165.165 0 0 1-.156.15Z'
        />
        <Path
          fill='#85CCD5'
          d='M13.572 36.763s1.56-1.858 4.614-2.528c3.054-.67 5.363-.77 7.768-2.726 2.407-1.957 4.35-3.867 5.445-3.248 1.189.671-4.374 8.227-7.12 9.463-2.745 1.236-5.59 2.033-7.236 4.316a214.576 214.576 0 0 0-2.295 3.226l-7.06-1.363 5.883-7.139h.001Z'
        />
        <Path
          fill='#85CCD5'
          d='M14.76 45.43a.167.167 0 0 1-.147-.262c.007-.009.67-.968 2.298-3.227 1.352-1.877 3.464-2.756 5.701-3.686.526-.219 1.07-.445 1.601-.685 2.38-1.072 7.109-7.229 7.185-8.913.006-.136-.02-.221-.08-.254-.824-.465-2.37.827-4.157 2.322-.354.296-.721.603-1.1.91-1.985 1.615-3.934 1.992-6.19 2.428-.526.101-1.074.207-1.647.333-2.963.65-4.506 2.454-4.522 2.473a.166.166 0 1 1-.254-.214c.065-.077 1.631-1.91 4.705-2.584.577-.127 1.127-.233 1.654-.335 2.305-.445 4.123-.797 6.045-2.36.377-.306.743-.611 1.095-.907 1.963-1.64 3.511-2.935 4.536-2.356.122.068.263.221.247.558-.082 1.794-4.863 8.068-7.38 9.2-.538.242-1.084.47-1.611.689-2.192.912-4.262 1.772-5.559 3.573a217.558 217.558 0 0 0-2.293 3.223.167.167 0 0 1-.128.072l.002.003Z'
        />
        <Path
          fill='#DA9460'
          d='M32.647 28.61c.065-.15.763-1.065 1.382-.715.843.476-2.915 7.51-6.767 8.139-3.852.629 5.385-7.424 5.385-7.424Z'
        />
        <Path
          fill='#DA9460'
          d='M4.43 43.78c.132-.304 4.94-9.336 8.005-11.443 3.065-2.107 8.937-5.75 9.73-4.766.794.985-.601 2.294-2.41 4.118 0 0 5.12.24 6.351.316 1.231.076 4.821-4.014 5.681-4.407.86-.394 1.627-.054 1.299 1.045-.329 1.098-4.46 8.663-8.201 8.49 0 0-8.447 3.293-9.054 3.819-.607.527-3.218 3.821-3.218 3.821l-8.183-.992v-.001Z'
        />
        <Path
          fill='#BF7C4F'
          d='M33.473 27.863c.026.323-.026.746-.279 1.231-.734 1.418-3.369 6.16-6.4 7.719-.91.47-1.697.666-2.351.705-1.64.096-2.49-.789-2.535-.84l.244-.227c.06.066 1.538 1.584 4.492.067 2.944-1.513 5.533-6.183 6.255-7.577.174-.336.236-.634.246-.878 0 0 .13-.124.328-.2ZM25.919 32.158h-.027c-4.004-.387-7.846-.206-7.884-.204a.166.166 0 0 1-.018-.332h.003c.039-.003 3.903-.184 7.932.204.092.01.159.091.15.182a.165.165 0 0 1-.156.15Z'
        />
        <Path
          fill='#DA9460'
          d='M13.572 36.763s1.56-1.858 4.614-2.528c3.054-.67 5.363-.77 7.768-2.726 2.407-1.957 4.35-3.867 5.445-3.248 1.189.671-4.374 8.227-7.12 9.463-2.745 1.236-5.59 2.033-7.236 4.316a214.576 214.576 0 0 0-2.295 3.226l-7.06-1.363 5.883-7.139h.001Z'
        />
        <Path
          fill='#BF7C4F'
          d='M14.76 45.43a.167.167 0 0 1-.147-.262c.007-.009.67-.968 2.298-3.227 1.352-1.877 3.464-2.756 5.701-3.686.526-.219 1.07-.445 1.601-.685 2.38-1.072 7.109-7.229 7.185-8.913.006-.136-.02-.221-.08-.254-.824-.465-2.37.827-4.157 2.322-.354.296-.721.603-1.1.91-1.985 1.615-3.934 1.992-6.19 2.428-.526.101-1.074.207-1.647.333-2.963.65-4.506 2.454-4.522 2.473a.166.166 0 1 1-.254-.214c.065-.077 1.631-1.91 4.705-2.584.577-.127 1.127-.233 1.654-.335 2.305-.445 4.123-.797 6.045-2.36.377-.306.743-.611 1.095-.907 1.963-1.64 3.511-2.935 4.536-2.356.122.068.263.221.247.558-.082 1.794-4.863 8.068-7.38 9.2-.538.242-1.084.47-1.611.689-2.192.912-4.262 1.772-5.559 3.573a217.558 217.558 0 0 0-2.293 3.223.167.167 0 0 1-.128.072l.002.003Z'
        />
      </G>
      <Path
        fill='#96CF51'
        d='M27.535 9.36c-1.053.42-3.321 1.566-4.534 2.051v5.405a.365.365 0 0 1-.366.365h-.672a.365.365 0 0 1-.365-.365v-5.398a.34.34 0 0 1 .021-.12c-1.141-.448-3.23-1.27-4.455-1.758-.477-.19-.519-.838-.079-1.103.657-.394 1.654-.717 2.882-.247 1.426.545 2.029 1.76 2.28 2.586.23-.822.82-2.125 2.313-2.696 1.363-.523 2.443-.067 3.086.38.336.232.268.75-.11.9Z'
      />
      <Path
        fill='#F39172'
        d='M36.253 20.347c-1.091 1.805-3.695 4.34-4.596 4.483-.425.068-3.249-1.978-4.622-4.194-.769-1.235-1.087-2.53-.26-3.532.738-.894 1.548-1.086 2.293-.96 1.333.224 2.436 1.471 2.436 1.471s1.826-2.4 4.136-.906c1.155.744 1.373 2.386.614 3.638Z'
      />
      <Path
        fill='#FFC522'
        d='M15.981 15.77c-.96-.809-2.26-.999-3.47-.797-1.17.195-2.405.692-3.121 1.678a3.707 3.707 0 0 0-.684 1.913c-.067.922.129 1.893.646 2.667.509.762 1.862 1.379 1.661 2.423-.071.372.407.52.704.461.084.09.194.16.321.153l1.2-.068.845.087a.43.43 0 0 0 .395-.163c.235-.014.727.057.727-.226 0-.496-.073-1.149.115-1.568.186-.412.353-.601.662-.893.529-.5.939-1.097 1.141-1.8.407-1.408-.014-2.916-1.14-3.866h-.002Z'
      />
      <Path
        fill='#fff'
        d='M14.92 18.866c.031-.585-.571-.866-.99-.48-.262.242-.321.635-.372.975a9.162 9.162 0 0 0-.075.7c-.25.105-.52.175-.79.197a2.613 2.613 0 0 0-.244-.727c-.258-.491-.806-1.014-1.38-.724-.568.286-.415 1.003-.029 1.372.349.334.831.478 1.33.488.031.313.029.632.015.94a9.752 9.752 0 0 1-.305 1.997c-.063.243.288.384.353.139.204-.78.324-1.577.338-2.383.004-.233 0-.473-.022-.71.246-.027.49-.086.717-.167-.003.095-.01.19-.012.285-.02.945-.008 1.89-.008 2.836 0 .252.376.288.376.035 0-1.104-.037-2.22.028-3.324.076-.04.15-.082.219-.128.43-.285.822-.768.852-1.32l-.001-.001Zm-3.15 1.293a1.227 1.227 0 0 1-.5-.291c-.14-.147-.31-.448-.14-.635.417-.459.95.361 1.079.699.04.107.068.217.094.328a2.161 2.161 0 0 1-.533-.1v-.001Zm2.104-.306c.025-.437.092-1.312.518-1.257.463.06-.141.938-.288 1.074a1.83 1.83 0 0 1-.23.182Z'
      />
      <Path fill='#CDB8A6' d='M10.978 23.153h4.225v2.57a.279.279 0 0 1-.278.279h-3.67a.279.279 0 0 1-.278-.28v-2.57Z' />
    </Svg>
  )
}
