import axios from 'axios'

import {
  EssentialsArticleRaw,
  EssentialsCourseRaw,
  OnEssentialsCardPressFunction,
  SELF_CARE_CATEGORY_TYPE,
  WELLNESS_TOOLKIT_TYPE,
  WellnessToolkitAvailableConfig,
  WellnessToolkitEssentialsContent,
} from './types'
import {
  Episode,
  EpisodeStates,
  ProgramNames,
  RiskFactorsResponse,
  TriageScreenerNames,
  TriageSearch,
  TriageSearchStatus,
} from '../../models'
import { COMMON_MIXPANEL_PAGE } from '../common/constants/mixpanelConstants'
import { COACHING_PARAMS, PARENTING_PARAMS } from '../common/constants/speculativeConstant'
import { TREATMENT_RECOMMENDATION_TIER } from '../common/constants/treatmentRecommendationsConstants'
import { hasPastSI } from '../highAlert/utils'
import { translateToEssentialSiteCode } from '../pathways/utils'
import { PrimaryNeedsWords, TriageScreenerField, TriageWords } from '../searchForCare/constants'
import { isChildSearch } from '../searchForCare/utils'

/**
 * Conditions for when a toolkit is available: https://lyrahealth.atlassian.net/wiki/spaces/EN/pages/3448995850/Wellness+Toolkits+Coaching+Parenting+Family+Hub
 * @param type Toolkit type
 * @param page Where the toolkit link is being shown. Defaults to homebase carousel
 * @returns Config with a list of conditions mapped to if it applies or not to the given toolkit
 */
export const getWellnessToolkitAvailableConfig = (
  type: WELLNESS_TOOLKIT_TYPE,
  isLyraSelect: boolean,
  page?: COMMON_MIXPANEL_PAGE,
): WellnessToolkitAvailableConfig => {
  /** Default conditions for showing the toolkit card on homebase carousel */
  const defaultConfig: { [key in WELLNESS_TOOLKIT_TYPE]: WellnessToolkitAvailableConfig } = {
    [WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING]: {
      shouldNotBeTeen: true,
      shouldNotBeInternational: true,
      shouldEssentialsBeEnabled: true,
      shouldCoachingBeEnabled: true,
      shouldDependentsBeSupported: false,
      shouldNotBeSHOrSI: true,
      shouldHaveProviderSupply: true,
      shouldNotBeInActiveEpisode: true,
      shouldNotHaveT3Recommendation: true,
      shouldBeKnownParent: false,
      shouldFeatureFlagBeEnabled: true,
      shouldDisplayLanguageBeEnglish: true,
      configExtras: {
        programSearchRequestData: COACHING_PARAMS,
      },
    },
    [WELLNESS_TOOLKIT_TYPE.PARENT_COACHING]: {
      shouldNotBeTeen: true,
      shouldNotBeInternational: true,
      shouldEssentialsBeEnabled: true,
      shouldCoachingBeEnabled: true,
      shouldDependentsBeSupported: false,
      shouldNotBeSHOrSI: true,
      shouldHaveProviderSupply: true,
      shouldNotBeInActiveEpisode: true,
      shouldNotHaveT3Recommendation: true,
      shouldBeKnownParent: true,
      shouldFeatureFlagBeEnabled: true,
      shouldDisplayLanguageBeEnglish: true,
      configExtras: {
        programSearchRequestData: PARENTING_PARAMS,
        triageConditionsChecker: getTriageIndicatesParent,
        allowedProgramsForActiveEpisodes: [ProgramNames.BlendedCareTherapy],
      },
    },
    [WELLNESS_TOOLKIT_TYPE.FAMILY_HUB]: {
      shouldNotBeTeen: true,
      shouldNotBeInternational: true,
      shouldEssentialsBeEnabled: true,
      shouldCoachingBeEnabled: false,
      shouldDependentsBeSupported: true,
      shouldNotBeSHOrSI: false,
      shouldHaveProviderSupply: false,
      shouldNotBeInActiveEpisode: false,
      shouldNotHaveT3Recommendation: false,
      shouldBeKnownParent: true,
      shouldFeatureFlagBeEnabled: true,
      shouldDisplayLanguageBeEnglish: false,
      configExtras: {
        triageConditionsChecker: getTriageIndicatesFamily,
      },
    },
  }

  const lyraSelectConfig: { [key in WELLNESS_TOOLKIT_TYPE]: Partial<WellnessToolkitAvailableConfig> } = {
    [WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING]: {
      shouldNotBeTeen: false,
      shouldNotBeInternational: false,
      shouldEssentialsBeEnabled: false,
      shouldCoachingBeEnabled: false,
      shouldDependentsBeSupported: false,
      shouldNotBeSHOrSI: false,
      shouldHaveProviderSupply: false,
      shouldNotBeInActiveEpisode: false,
      shouldNotHaveT3Recommendation: false,
      shouldBeKnownParent: false,
      shouldFeatureFlagBeEnabled: false,
      shouldDisplayLanguageBeEnglish: false,
    },
    [WELLNESS_TOOLKIT_TYPE.PARENT_COACHING]: {
      configExtras: {
        forceHideToolkit: true,
      },
    },
    [WELLNESS_TOOLKIT_TYPE.FAMILY_HUB]: {
      configExtras: {
        forceHideToolkit: true,
      },
    },
  }

  /** Variations on the conditions depending on where the toolkit entry point is being shown */
  const configByPage: {
    [key in COMMON_MIXPANEL_PAGE]?: { [key in WELLNESS_TOOLKIT_TYPE]?: Partial<WellnessToolkitAvailableConfig> }
  } = {
    [COMMON_MIXPANEL_PAGE.FORKED_TRIAGE]: {
      [WELLNESS_TOOLKIT_TYPE.PARENT_COACHING]: {
        shouldBeKnownParent: false,
      },
    },
    [COMMON_MIXPANEL_PAGE.INTEGRATED_BENEFITS_PAGE]: {
      [WELLNESS_TOOLKIT_TYPE.FAMILY_HUB]: {
        /** Essentials carousel on the family hub page will be hidden */
        shouldEssentialsBeEnabled: false,
        shouldBeKnownParent: false,
      },
    },
  }

  const pageConfigForToolkits = page && configByPage[page]
  const pageConfig: Partial<WellnessToolkitAvailableConfig> = pageConfigForToolkits
    ? pageConfigForToolkits[type] ?? {}
    : {}

  const defaultConfigResult = { ...defaultConfig[type], ...pageConfig }
  const lyraSelectConfigResult = { ...defaultConfig[type], ...lyraSelectConfig[type] }

  return isLyraSelect ? lyraSelectConfigResult : defaultConfigResult
}

export const isNotSelfHarmOrSuicialIdeationIndicated = (risksFactors?: RiskFactorsResponse) => {
  // @todo Check for SI indicated in assessments
  return risksFactors ? !hasPastSI(risksFactors) : true
}

/**
 *  Verify if users triage searches is safe for wellness toolkit.
 *  If the latest completed triage that is not Tier 3, return true
 *
 * @param searches
 */
export const isLatestCompletedSearchNotTierThree = (searches: TriageSearch[]) => {
  const filteredSearches = searches.filter((s) => !isChildSearch(s))
  let latestCompletedSearch: TriageSearch | undefined = undefined

  for (let i = filteredSearches.length - 1; i >= 0; i--) {
    const search = filteredSearches[i]
    if (search.triage_status === TriageSearchStatus.COMPLETED) {
      latestCompletedSearch = search
      break
    }
  }

  return (
    filteredSearches.length === 0 ||
    !latestCompletedSearch ||
    latestCompletedSearch?.recommended_treatments?.shownTierPage !== TREATMENT_RECOMMENDATION_TIER.TIER_3
  )
}

/**
 * Checks that user isn't in an active episode
 * - Excludes dependent episodes
 * @param allowedPrograms - An episode in one of these programs is exempt. SELF_CARE_APPS program is always allowed.
 */
export const isNotInActiveEpisode = (
  episodes: Episode[],
  lyraId?: string,
  allowedPrograms: ProgramNames[] = [],
): boolean => {
  const programs = [...allowedPrograms, ProgramNames.SELF_CARE_APPS]
  const filteredEpisodes = getEpisodesForSelf(episodes, lyraId)
  return !filteredEpisodes.some(
    ({ program_name, state }) => !programs.includes(program_name) && state === EpisodeStates.IN_PROGRESS,
  )
}

const getEpisodesForSelf = (episodes: Episode[], lyraId?: string) => {
  if (!lyraId) {
    return episodes
  }
  return episodes.filter((e) => e.patient_id === lyraId)
}

const getWordCloudFromSearch = (
  search: TriageSearch,
  wordCloudKey: string,
  experienceKey: TriageScreenerField,
): string[] => {
  const questionnaires = search?.body?.questionnaires
  if (questionnaires) {
    const wordCloudData = questionnaires[wordCloudKey] as Dict
    if (wordCloudData) {
      return (wordCloudData[experienceKey] as string[]) ?? []
    }
  }
  return []
}

const checkForWordInWordClouds = (wordsList: TriageWords[], search: TriageSearch) => {
  const wordCloudWords = [
    ...getWordCloudFromSearch(search, TriageScreenerNames.WORD_CLOUD_1, TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING),
    ...getWordCloudFromSearch(
      search,
      TriageScreenerNames.WORD_CLOUD_2,
      TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING_2,
    ),
  ]
  return wordsList.some((word) => wordCloudWords.includes(word))
}

/**
 * Returns true if user has indicated they are a parent based on their triage responses.
 *  1. wordCloud1 or wordCloud2 contains 'Parenting Issues'
 *
 * @param triageSearches - Users triage search
 */
export const getTriageIndicatesParent = (triageSearches: TriageSearch[]): boolean => {
  for (const search of triageSearches) {
    const words = [TriageWords.PARENTING_ISSUES, TriageWords.PARENTING]
    const isWellnessPromotionParentEnabled = checkForWordInWordClouds(words, search)

    if (isWellnessPromotionParentEnabled) {
      return true
    }
  }

  return false
}

/**
 * Returns true if user has indicated they are a parent/caretaker based on their triage responses.
 *  1. Family/relationship issues selected for primary need
 *  2. Parenting/parenting issues, pregnancy/new child, or serving as caregiver selected in the word clouds
 */
export const getTriageIndicatesFamily = (triageSearches: TriageSearch[]): boolean => {
  for (const search of triageSearches) {
    const questionnaires = search?.body?.questionnaires
    let foundFamilyIndicatorPrimaryNeeds = false
    if (questionnaires) {
      const primaryNeeds = questionnaires[TriageScreenerNames.PRIMARY_NEEDS] as Dict
      if (primaryNeeds) {
        foundFamilyIndicatorPrimaryNeeds =
          primaryNeeds[TriageScreenerField.WHATS_ON_YOUR_MIND] === PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY
      }
    }

    const words = [
      TriageWords.PREGNANCY_NEW_CHILD,
      TriageWords.SERVING_AS_CAREGIVER,
      TriageWords.PARENTING_ISSUES,
      TriageWords.PARENTING,
    ]
    const foundFamilyIndicatorWordClouds = checkForWordInWordClouds(words, search)

    if (foundFamilyIndicatorPrimaryNeeds || foundFamilyIndicatorWordClouds) {
      return true
    }
  }

  return false
}

export const getIsCoachingPromotionAvailable = (
  isParentToolkitAvailable: boolean,
  isIndividualCoachingToolkitsAvailable: boolean,
) => {
  return isParentToolkitAvailable || isIndividualCoachingToolkitsAvailable
}

const parseContentType = (contentType: string) => {
  return contentType.split('_').join(' ')
}

export const formatEssentialsArticle = (
  data: EssentialsArticleRaw,
  onEssentialsCardPress: OnEssentialsCardPressFunction,
  locale: string,
) => {
  const uriArr = data.uri.split('/')
  const destinationSlug = uriArr.length > 0 ? uriArr[uriArr.length - 1] : ''
  const essentialContent: WellnessToolkitEssentialsContent = {
    title: data.title,
    imageUrl: data.image && data.image.length > 0 ? `https:${data.image[0].url}` : '',
    contentType: parseContentType(data.contentType),
    id: data.id,
    readTime: data.readTime,
    contentFormat: data.contentFormat,
    onPress: () => {
      onEssentialsCardPress(data.uri, data.title, data.contentFormat, data.contentType, destinationSlug, locale)
    },
  }
  return essentialContent
}

export const formatEssentialsCourse = (
  data: EssentialsCourseRaw,
  onEssentialsCardPress: OnEssentialsCardPressFunction,
  locale: string,
) => {
  const uriArr = data.uri.split('/')
  const destinationSlug = uriArr.length > 0 ? uriArr[uriArr.length - 1] : ''
  const essentialContent: WellnessToolkitEssentialsContent = {
    title: data.title,
    imageUrl: data.foreground && data.foreground.length > 0 ? `https:${data.foreground[0].url}` : '',
    contentType: '',
    id: data.id,
    readTime: `${data.subcategories.length} lessons`,
    contentFormat: '',
    categoryType: SELF_CARE_CATEGORY_TYPE.COURSE,
    onPress: () => {
      onEssentialsCardPress(data.uri, data.title, '', '', destinationSlug, locale)
    },
  }
  return essentialContent
}

export const runEssentialsQuery = async (
  locale: string,
  slug: string,
  query: string,
  essentialsCmsUrl: string,
  logError?: (error: Error) => void,
) => {
  const payload = {
    query,
    variables: {
      slug,
      siteCategory: 'essentials',
      site: translateToEssentialSiteCode(locale),
    },
  }

  try {
    if (!essentialsCmsUrl) {
      throw new Error('CMS URL is empty')
    }

    // Use new axios for essential csm external domain
    const instance = axios.create()
    const response = await instance.post(essentialsCmsUrl, payload)

    if (response.status !== 200) {
      throw new Error('Invalid response for essentials query')
    } else {
      return response.data?.data?.entries
    }
  } catch (e) {
    console.error('Failed to retrieve data for essentials query ' + e)
    if (logError) {
      logError(e as Error)
    }
    return []
  }
}
