import React, { FunctionComponent } from 'react'

import { WelcomeHomeProps } from './WelcomeHomeSvg'
import { withSuspense } from '../../utils/withSuspense'

const WelcomeHomeSvg = withSuspense({
  component: React.lazy(() => import('./WelcomeHomeSvg')),
})

/**
 * illustration representing a person laying at home.
 */
export const WelcomeHome: FunctionComponent<WelcomeHomeProps> = ({ width, height }) => {
  return <WelcomeHomeSvg width={width} height={height} />
}
