import React, { FunctionComponent, useMemo } from 'react'
import { View } from 'react-native'

import { LyraSelectMicrositeConfig, MicrositeConfigSectionNames } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../../utils/utils'
import { micrositeSectionsComponentMap } from '../../hooks/useGetMicrositeSections'
import {
  LegalAndCopyrightFooter,
  LegalAndCopyrightFooterProps,
} from '../../organisms/legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { LyraMembershipSummaryProps } from '../../organisms/lyraMembershipSummary/LyraMembershipSummary'
import { LyraSelectHomepageHeroSectionProps } from '../../organisms/lyraSelectHomepageHeroSection/LyraSelectHomepageHeroSection'
import { MicrositeMobileAppPromoBannerProps } from '../../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoBanner'
import { MicrositeMobileAppPromoSectionProps } from '../../organisms/micrositeMobileAppPromo/MicrositeMobileAppPromoSection'
import { PathwaysCareNavigatorSectionProps } from '../../organisms/pathwaysCareNavigatorSection/PathwaysCareNavigatorSection'
import { PathwaysFAQSectionProps } from '../../organisms/pathwaysFAQSection/PathwaysFAQSection'
import { PathwaysSelfCareSectionProps } from '../../organisms/pathwaysSelfCareSection/PathwaysSelfCareSection'

export interface LyraSelectMicrositeHomePageProps {
  faqSectionProps: PathwaysFAQSectionProps
  selfCareSectionProps: PathwaysSelfCareSectionProps
  careNavigatorSectionProps: PathwaysCareNavigatorSectionProps
  legalAndCopyrightFooterProps: LegalAndCopyrightFooterProps
  membershipSectionProps: LyraMembershipSummaryProps
  mobileAppPromoSectionProps: MicrositeMobileAppPromoSectionProps
  mobileAppPromoBannerProps: MicrositeMobileAppPromoBannerProps
  micrositeConfig: LyraSelectMicrositeConfig
  selectHomepageHeroSectionProps: LyraSelectHomepageHeroSectionProps
}

export const LyraSelectMicrositeHomePage: FunctionComponent<LyraSelectMicrositeHomePageProps> = ({
  selectHomepageHeroSectionProps,
  faqSectionProps,
  selfCareSectionProps,
  careNavigatorSectionProps,
  legalAndCopyrightFooterProps,
  micrositeConfig,
  membershipSectionProps,
  mobileAppPromoSectionProps,
  mobileAppPromoBannerProps,
}) => {
  const sectionToPropsMap = {
    [MicrositeConfigSectionNames.LYRA_SELECT_HERO]: selectHomepageHeroSectionProps,
    [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: mobileAppPromoBannerProps,
    [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: mobileAppPromoSectionProps,
    [MicrositeConfigSectionNames.SELF_CARE]: selfCareSectionProps,
    [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: membershipSectionProps,
    [MicrositeConfigSectionNames.CARE_NAVIGATOR]: careNavigatorSectionProps,
    [MicrositeConfigSectionNames.FAQ]: faqSectionProps,
  }

  const displaySections = useMemo(() => {
    const sectionsToDisplay = Object.keys(micrositeConfig).filter((section) => !micrositeConfig[section].hideSection)

    return sectionsToDisplay.reduce<
      Array<{ name: MicrositeConfigSectionNames; component: FunctionComponent; backgroundColor?: string }>
    >((sections, currentSectionName: MicrositeConfigSectionNames) => {
      const sectionData = {
        name: currentSectionName,
        component: micrositeSectionsComponentMap[currentSectionName],
        backgroundColor: micrositeConfig[currentSectionName].backgroundColor,
      }
      sections.push(sectionData)
      return sections
    }, [])
  }, [micrositeConfig])

  return (
    <View testID={tID('LyraSelectMicrositeHomePage')}>
      {displaySections.map((section) => {
        const { name: sectionName, component: Component, backgroundColor } = section
        const sectionProps = { ...sectionToPropsMap[sectionName], ...(backgroundColor && { backgroundColor }) }
        return <Component key={sectionName} {...sectionProps} />
      })}
      <LegalAndCopyrightFooter {...legalAndCopyrightFooterProps} />
    </View>
  )
}
