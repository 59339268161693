import { PayloadAction } from '@reduxjs/toolkit'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { WELLNESS_TOOLKIT_PROMOTION } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_DIRECT_LINK_INTENT,
  CLEAR_DIRECT_LINK_SOURCE,
  CLEAR_TOOLKIT_PROMOTION,
  GET_LATEST_USER_FAVORITE_PROVIDERS,
  GET_SEARCH_COUNT,
  SET_DIRECT_LINK_INTENT,
  SET_DIRECT_LINK_SOURCE,
  SET_IS_CURRENT_SEARCH_DIRECT_PATH,
  SET_LATEST_MATCHES_SEARCH,
  SET_LOADING_LATEST_PROVIDER_MATCHES,
  SET_MEETING_FORMAT,
  SET_ONBOARD_PROGRESS,
  SET_TIMEZONE_FOR_AVAILABILITY_REQUEST,
  SET_TOOLKIT_PROMOTION,
  SET_USER_FAVORITE_PROVIDER_REQUEST_ID,
} from './onboardDataActions'

const initialState = fromJS({
  progress: 0,
  timeZoneForAvailabilityRequest: undefined,
  triageInfoSavedId: undefined,
  latestUserProviders: [],
  isLatestMatches: false,
  isCurrentSearchDirectPath: false,
  directLinkIntent: undefined,
  directLinkSource: undefined,
  toolkitPromotion: undefined,
})

export default createReducer(initialState, {
  [SET_ONBOARD_PROGRESS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('progress', action.payload)
    return $$state
  },
  [GET_SEARCH_COUNT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('searchCount', action.payload)
    return $$state
  },
  [SET_TIMEZONE_FOR_AVAILABILITY_REQUEST]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('timeZoneForAvailabilityRequest', action.timeZone)
    return $$state
  },
  [SET_MEETING_FORMAT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('meetingFormat', action.value)
    return $$state
  },
  [SET_USER_FAVORITE_PROVIDER_REQUEST_ID]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('userFavoriteProviderRequestId', action.payload)
    return $$state
  },
  [GET_LATEST_USER_FAVORITE_PROVIDERS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('latestUserProviders', action.payload)
    return $$state
  },
  [SET_LATEST_MATCHES_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('latestMatchesSearch', action.value)
    return $$state
  },
  [SET_LOADING_LATEST_PROVIDER_MATCHES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('loadingLatestProviderMatches', action.value)
    return $$state
  },
  [SET_IS_CURRENT_SEARCH_DIRECT_PATH]: ($$state: $TSFixMe, action: PayloadAction<boolean>) => {
    $$state = $$state.set('isCurrentSearchDirectPath', action.payload)
    return $$state
  },
  [SET_DIRECT_LINK_INTENT]: ($$state: $TSFixMe, action: PayloadAction<string>) => {
    $$state = $$state.set('directLinkIntent', action.payload)
    return $$state
  },
  [CLEAR_DIRECT_LINK_INTENT]: ($$state: $TSFixMe) => {
    $$state = $$state.set('directLinkIntent', undefined)
    return $$state
  },
  [SET_DIRECT_LINK_SOURCE]: ($$state: $TSFixMe, action: PayloadAction<string>) => {
    $$state = $$state.set('directLinkSource', action.payload)
    return $$state
  },
  [CLEAR_DIRECT_LINK_SOURCE]: ($$state: $TSFixMe) => {
    $$state = $$state.set('directLinkSource', undefined)
    return $$state
  },
  [SET_TOOLKIT_PROMOTION]: ($$state: $TSFixMe, action: PayloadAction<WELLNESS_TOOLKIT_PROMOTION>) => {
    $$state = $$state.set('toolkitPromotion', action.payload)
    return $$state
  },
  [CLEAR_TOOLKIT_PROMOTION]: ($$state: $TSFixMe) => {
    $$state = $$state.set('toolkitPromotion', undefined)
    return $$state
  },
})
