/**
 * @deprecated We want to use colorsV2 from the useTheme hook instead of
 * directly accessing the color values here. The reason for this is because
 * we plan on adding dark theme and possibly other themes in the future
 * which requires using color tokens so that the color can change through
 * the ThemeProvider when the user chooses a different theme.
 *
 * You can also access the theme colors outside of the useTheme hook when
 * making custom styled components as it will be provided. Ex.
 *
 * const ButtonContainer = styled.View(({ theme }) => ({
 *     backgroundColor: theme.colors.primaryButtonBackgroundDefault,
 * }))
 *
 * Please don't add any new usages.
 */
export const colors = {
  brand: '#35596D',
  white: '#FFFFFF',
  black: '#000000',
  yellow: '#ffc909',
  semiDarkGray: '#6F7780',
  dateFont: '#768088',
  nero1: '#1d1d1d',
  nero2: '#1c1c1c',
  porcelain: '#E7EAEC',
  logo: '#133D54',
  lightGray: '#fdfdfd',
  modalityBullet: '#F0805B',

  // Dev colors
  darkGrayBlue: '#263238',
  grayBlue: '#303C41',
  mediumGrayBlue: '#39484F',
  valueGreen: '#C3E88D',
  variablePurple: '#C18EE3',
  devBlue: '#2178A7',
  devDarkBlue: '#17587C',

  // signal indicator colors
  signalHigh: '#91E769',
  signalMed: '#FFC42C',
  signalLow: '#FF3C3C',

  /** * New colors (Jun 2022) ****/
  // We'll use the convention purpose_color for the new design system to avoid conflicts with old colors of the same name (e.g. old teal vs new teal)
  // L&F Oatmeal
  ui_oatmeal1: '#FBFAF9',
  ui_oatmeal2: '#F5F2EE',
  ui_oatmeal3: '#DFDBD6',
  ui_oatmeal4: '#8F8A85',
  ui_oatmeal4_5: '#7A7670',
  ui_oatmeal5: '#4F4D4A',
  ui_oatmeal6: '#1A1918',

  // L&F Teal - Primary
  primary_teal1: '#E7F8F8',
  primary_teal2: '#85CCD5',
  primary_teal3: '#3BA5B5',
  primary_teal4: '#017A8F',
  primary_teal5: '#09586E',
  primary_teal6: '#073345',

  // L&F Peach - Secondary
  secondary_peach1: '#FFF3F0',
  secondary_peach2: '#FCD0C2',
  secondary_peach3: '#FCB097',
  secondary_peach4: '#D96D4A',
  secondary_peach5: '#B84C33',
  secondary_peach6: '#8F3424',
  secondary_peach2_50: 'rgba(252, 208, 194, 0.5)', // opacity 50%

  // L&F Green - Success
  success_green1: '#EDF5DC',
  success_green2: '#C2E599',
  success_green3: '#8CC94B',
  success_green4: '#5F9C22',
  success_green5: '#357806',
  success_green6: '#00520A',

  // L&F Gold - Warning
  warning_gold1: '#FAF3D6',
  warning_gold2: '#FFD793',
  warning_gold3: '#FFB84D',
  warning_gold4: '#D67200',
  warning_gold5: '#B55112',
  warning_gold6: '#8C351C',

  // L&F Red - Error
  error_red1: '#FAE9EA',
  error_red2: '#FFBFC0',
  error_red3: '#FFA1A4',
  error_red4: '#E0656F',
  error_red5: '#BA455B',
  error_red6: '#942944',
  /* Design System Colors
   * https://www.figma.com/file/vC8oSelPC4LYwvmzNcXYMz/The-Lyra-Design-System?node-id=25%3A548
   */

  // Teal
  teal1: '#D6EAED',
  teal2: '#82C1CA',
  teal3: '#4CA9B7',
  teal4: '#008599',
  teal5: '#007081',
  teal6: '#004F5B',

  // Navy
  navy1: '#D6E3EB',
  navy2: '#80A2B4',
  navy3: '#5D7B8A',
  navy4: '#35596D',
  navy5: '#22485C',
  navy6: '#123243',

  // Purple
  purple1: '#F2DCE8',
  purple2: '#E2B1CC',
  purple3: '#BE7CA0',
  purple4: '#AD5B89',
  purple5: '#9E4275',
  purple6: '#852E5E',

  // Charcoal
  charcoal1: '#F1F3F5',
  charcoal2: '#D1D4D7',
  charcoal3: '#BABEC3',
  charcoal4: '#878D97',
  charcoal5: '#687078',
  charcoal6: '#343A40',

  // Green
  green1: '#EDF4F1',
  green2: '#B9D5C9',
  green3: '#84B4A0',
  green4: '#429672',
  green5: '#2A7A58',
  green6: '#127049',

  // Orange - Warning
  orange1: '#F9F1E5',
  orange2: '#EAC89A',
  orange3: '#D99E4D',
  orange4: '#CA7602',
  orange5: '#AD5700',
  orange6: '#954C00',

  // Red - Error
  red1: '#F9EBEB',
  red2: '#EAAFAF',
  red3: '#e08888',
  red4: '#C64E4E',
  red5: '#B63131',
  red6: '#982a2a',

  // Shadows - Black with opacity
  shadow05: 'rgba(0, 0, 0, 0.05)',
  shadow10: 'rgba(0, 0, 0, 0.1)',
  shadow20: 'rgba(0, 0, 0, 0.2)',
  shadow40: 'rgba(0, 0, 0, 0.4)',
  shadow60: 'rgba(0, 0, 0, 0.6)',
  shadow80: 'rgba(65, 68, 78, 0.8)',

  // Highlights - White with opacity
  highlight20: 'rgba(255, 255, 255, 0.2)',

  // Teal5 with opacity
  teal5_60: 'rgba(0, 112, 129, 0.6)',

  rgbaTransparent: 'rgba(255,255,255,0)',

  // For primary teal 1 linear gradient
  tealAlpha79: 'rgba(231, 248, 248, 0.79)',
  tealAlpha32: 'rgba(231, 248, 248, 0.32)',
}
