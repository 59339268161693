import { defineMessages } from 'react-intl'

import { ProviderProgramTaxonomy } from '../../models'
import { SearcherType } from '../../models/searchForCare/SearchForCare'
import { TriageScreenerNames } from '../../models/triageScreener/TriageScreener'
import { DirectLinkIntent } from '../common/constants/customerProgram'
import { TRIAGE_EVENTS } from '../common/constants/mixpanelConstants'
import { CARE_OPTION } from '../common/constants/treatmentOptions'

export type AgeBucket = {
  bucket: string
  bucketDisplay?: string
  min: number
  max: number
  type: SearcherType
}

export const ageBuckets = [
  {
    bucket: 'Under 5 years',
    bucketDisplay: '<5',
    min: 0,
    max: 4,
    type: SearcherType.YOUNGER_CHILD,
  },
  {
    bucket: '5 to 11 years',
    bucketDisplay: '5-11',
    min: 5,
    max: 11,
    type: SearcherType.MIDDLE_CHILD,
  },
  {
    bucket: '12 years or over',
    bucketDisplay: '12-17',
    min: 12,
    max: 17,
    type: SearcherType.OLDER_CHILD,
  },
  {
    bucket: '18-24',
    min: 18,
    max: 24,
    type: SearcherType.ADULT,
  },
  {
    bucket: '25-34',
    min: 25,
    max: 34,
    type: SearcherType.ADULT,
  },
  {
    bucket: '35-44',
    min: 35,
    max: 44,
    type: SearcherType.ADULT,
  },
  {
    bucket: '45-54',
    min: 45,
    max: 54,
    type: SearcherType.ADULT,
  },
  {
    bucket: '55-64',
    min: 55,
    max: 64,
    type: SearcherType.ADULT,
  },
  {
    bucket: '65+',
    min: 65,
    max: 1000,
    type: SearcherType.ADULT,
  },
]

/**
 * NOTE: These enum values are not meant for displaying to the user.
 * Please see mappings `PRIMARY_NEEDS_WORDS_CHILD_MSG` and `PRIMARY_NEEDS_WORDS_ADULT_MSG`
 */
export enum PrimaryNeedsWords {
  DEPRESSION_SADNESS = 'Depression/Sadness',
  ANXIETY = 'Anxiety',
  STRESS = 'Stress',
  RELATIONSHIPS_AND_FAMILY = 'Relationships and Family',
  SCHOOL = 'School',
  ADHD = 'ADHD',
  ALCOHOL_DRUG_USE = 'Alcohol/Drug Use',
  PSYCHOSIS_SCHIZOPHRENIA = 'Psychosis/Schizophrenia',
  BIPOLAR = 'Bipolar',
  AUTISM_SPECTRUM = 'Autism spectrum',
  DEVELOPMENTAL_CONCERNS = 'DEVELOPMENTAL CONCERNS',
  BEHAVIOR_PROBLEMS = 'Behavior Problems',
}

export enum TriageScreenerField {
  PRIMARY_NEED = 'primaryNeed',
  WHATS_ON_YOUR_MIND = 'whatsOnYourMind',
  WHAT_ELSE_ON_YOUR_MIND = 'whatElseOnYourMind',
  WHAT_ARE_YOU_EXPERIENCING = 'whatAreYouExperiencing',
  WHAT_ARE_YOU_EXPERIENCING_2 = 'whatAreYouExperiencing2',
  DO_YOU_HAVE_HISTORY = 'doYouHaveHistory',
  WORK_LIFE_BALANCE_DIFFICULTY_IN_BALANCING = 'difficultyInBalancing',
  LIFE_CHANGES_SIGNFICANT_CHANGES = 'significantChanges',
  WHAT_MADE_YOU_COME_TO_LYRA = 'whatMadeYouComeToLyra',
  AUD_SUD_DRINK_FREQUENCY = 'audSud.drinkFrequencyInThePastYear',
  TRAUMA_REPEATED_MEMORIES = 'trauma.repeatedMemories',
  TRAUMA_UPSETTING_EXPERIENCE = 'trauma.upsettingExperience',
  TRAUMA_REMINDED_OF_EXPERIENCE = 'trauma.remindedOfExperience',
  SELF_HARM_SUICIDAL_THOUGHTS = 'selfHarm.suicidalThoughts',
  SELF_HARM_SUICIDAL_THOUGHTS_FREQUENCY = 'selfHarm.suicidalThoughtsFrequency',
  SELF_HARM_PLAN = 'selfHarm.selfHarmPlan',
  SELF_HARM_PLAN_INTENT = 'selfHarm.selfHarmPlanIntent',
  SELF_HARM_STEPS = 'selfHarm.selfHarmSteps',
  CLINICAL_SCREENER_HEADER = 'clinicalScreenerHeader',
  ANXIETY = 'anxiety',
  SLIDERS_TRIAGE_HEADER = 'slidersTriageHeader',
  SLIDERS_TRIAGE_SUB_HEADER = 'slidersTriageSubHeader',
  FRIENDS_AND_FAMILY = 'Friends and family',
  SCHOOL = 'School',
  HEALTH = 'Health',
  CARE_EXPERIENCES_HEADER = 'careExperiencesHeader',
  PAST_MENTAL_CARE = 'mentalHealthCareHistory',
  PAST_MEDICATION = 'mentalHealthMedsHistory',
  MENTAL_HEALTH_TREATMENT_RECENCY = 'mentalHealthTreatmentRecency',
  NON_SPECIFIED_PRIMARY_NEED = 'nonSpecifiedPrimaryNeed',
}

export enum TriageScreenerValue {
  SOMETHING_ELSE = 'somethingElse',
  IM_NOT_SURE = 'notSure',
  SEEING_A_THERAPIST_FOR_MYSELF = 'seeingATherapistForMyself',
  LESS_THAN_MONTHLY = 'lessThanMonthly',
}

/**
 * Triage words are now served from the BE
 * These enum values may still be used in cypress tests
 */
export enum TriageWords {
  ACCESS_TO_FOOD_OR_HOUSING = 'Access to food or housing',
  ACCESS_TO_TRANSPORTATION = 'Access to transportation',
  ACTS_YOUNGER_THAN_AGE = 'Acts younger than age',
  AFFECTING_WORK = 'Affecting work',
  AFRAID_OF_NEW_THINGS = 'Afraid of new things',
  AGGRESSIVE = 'Aggressive',
  ANGER = 'Anger',
  ANXIETY = 'Anxiety',
  ARGUING_FIGHTING = 'Arguing / Fighting',
  ASHAMED = 'Ashamed',
  AVOIDING_SOCIALIZING = 'Avoiding socializing',
  BAD_GRADES = 'Bad grades',
  BEHAVIOR_ISSUES = 'Behavior issues',
  BEING_BULLIED_TEASED = 'Being bullied/teased',
  BINGE_DRINKING = 'Binge drinking',
  BULLYING_TEASING_OTHERS = 'Bullying/teasing others',
  BURNED_OUT = 'Burned out',
  CHRONIC_PAIN = 'Chronic pain',
  COMPULSIVE_BEHAVIORS = 'Compulsive behaviors',
  DAYDREAMING = 'Daydreaming',
  DEALING_WITH_DIVORCE = 'Dealing with divorce',
  DEALING_WITH_MARRIAGE_REMARRIAGE = 'Dealing with marriage/remarriage',
  DEPRESSED = 'Depressed',
  DETACHED_NUMB = 'Detached/Numb',
  DIFFICULTY_CONCENTRATING = 'Difficulty concentrating',
  DIFFICULTY_CUTTING_BACK = 'Difficulty cutting back',
  DISCIPLINE_PROBLEMS = 'Discipline problems',
  DISTRACTED = 'Distracted',
  DOWN_IN_THE_DUMPS = 'Down in the dumps',
  DRINKING_TOO_MUCH_ALCOHOL = 'Drinking too much alcohol',
  EMBARRASSED = 'Embarrassed',
  EXCESSIVE_WORRY = 'Excessive worry',
  EXCITABLE = 'Excitable',
  EXHAUSTED = 'Exhausted',
  FAMILY_CONFLICT = 'Family conflict',
  COMMUNITY_SAFETY = 'Community safety',
  FEARFUL = 'Fearful',
  FEELING_CRITICIZED = 'Feeling criticized',
  FEELING_GUILTY = 'Feeling guilty',
  FEELING_HOPELESS = 'Feeling hopeless',
  FEELING_LIKE_A_BAD_PERSON = 'Feeling like a bad person',
  FEELING_LIKE_A_FAILURE = 'Feeling like a failure',
  FEELING_UNAPPRECIATED = 'Feeling unappreciated',
  FERTILITY_ISSUES = 'Fertility issues',
  FIDGETY = 'Fidgety',
  FIGHTING_WHEN_DRINKING = 'Fighting when drinking',
  FINANCIAL_PROBLEMS = 'Financial problems',
  FORGETFULNESS = 'Forgetfulness',
  FREQUENT_CRYING = 'Frequent crying',
  GAMBLING = 'Gambling',
  HEART_RACING = 'Heart racing',
  HYPERACTIVITY = 'Hyperactivity',
  IMPULSIVITY = 'Impulsivity',
  INTIMACY_PROBLEMS = 'Intimacy problems',
  LACKING_JOY = 'Lacking joy',
  LACKING_RESTRAINT = 'Lacking restraint',
  LEARNING_DIFFERENCES = 'Learning differences',
  LESS_INTEREST_IN_FRIENDS_HOBBIES = 'Less interest in friends/hobbies',
  LESS_INTEREST_IN_SCHOOL = 'Less interest in school',
  LONELY = 'Lonely',
  LOW_PRODUCTIVITY = 'Low productivity',
  LOW_SELF_ESTEEM = 'Low self-esteem',
  MAJOR_HEALTH_ISSUE = 'Chronic or major health issue',
  MARITAL_DISTRESS = 'Marital distress',
  MISSING_SCHOOL = 'Missing school',
  MISUSING_OPIOIDS = 'Misusing opioids',
  MISUSING_OTHER_ILLICIT_DRUGS = 'Misusing other illicit drugs',
  MISUSING_PRESCRIPTION_DRUGS = 'Misusing prescription drugs',
  NEGATIVE_THOUGHTS = 'Negative thoughts',
  NEGLECTING_FAMILY = 'Neglecting family',
  NERVOUS = 'Nervous',
  NEW_SIBLING = 'New sibling',
  NOT_COMMUNICATING = 'Not communicating',
  OBSESSIVE_THOUGHTS = 'Obsessive thoughts',
  OVERWHELMED = 'Overwhelmed',
  PANIC_ATTACKS = 'Panic attacks',
  PARENTING_ISSUES = 'Parenting issues',
  PHOBIA = 'Phobia',
  PREGNANCY_NEW_CHILD = 'Pregnancy / New child',
  RACING_THOUGHTS_SPEECH = 'Racing thoughts/speech',
  RELATIONSHIP_PROBLEMS = 'Relationship problems',
  REPEATING_WORDS_PHRASES = 'Repeating words/phrases',
  RESTLESS = 'Restless',
  RISKY_BEHAVIORS = 'Risky behaviors',
  SCHOOL_ABSENCES = 'School absences',
  SELF_HARM = 'Self-harm',
  SEPARATION_DIVORCE = 'Separation / Divorce',
  SEXUAL_PROBLEMS = 'Sexual problems',
  TAKES_UNNECESSARY_RISKS = 'Takes unnecessary risks',
  TEEN_PREGNANCY = 'Teen pregnancy',
  THOUGHTS_OF_SUICIDE = 'Thoughts of suicide',
  TRAUMA = 'Trauma',
  TROUBLE_COPING_WITH_CHANGE = 'Trouble coping with change',
  TROUBLE_RELAXING = 'Trouble relaxing',
  TROUBLE_WITH_FRIENDS = 'Trouble with friends',
  TROUBLE_WITH_TEACHERS = 'Trouble with teachers',
  UNUSUALLY_HIGH_ENERGY = 'Unusually high energy',
  USING_HEROIN = 'Using heroin',
  USING_STREET_DRUGS = 'Using street drugs',
  WORK_CONFLICT = 'Work conflict',
  POOR_EYE_CONTACT = 'poor eye contact',
  CANNOT_POINT_TO_THINGS = 'cannot point to things',
  POOR_SOCIAL_SKILLS = 'poor social skills',
  REPETITIVE_PHRASES = 'repetitive phrases',
  REPETITIVE_MOVEMENTS = 'repetitive movements',
  GETS_STUCK_ON_ROUTINES = 'gets stuck on routines',
  UNUSUAL_SKILLS_INTERESTS = 'unusual skills/interests',
  SENSORY_SEEKING = 'sensory seeking',
  SENSITIVE_TO_NOISE_OR_TOUCH = 'sensitive to noise or touch',
  NONVERBAL = 'nonverbal',
  LANGUAGE_DELAY = 'language delay',
  LEARNING_PROBLEMS = 'learning problems',
  ADD_ADHD = 'ADD/ADHD',
  WORRIES = 'worries',
  INTELLECTUAL_DISABILITY = 'intellectual disability',
  NOT_TOILET_TRAINED = 'not toilet trained',
  POOR_COORDINATION = 'poor coordination',
  WORRIES_A_LOT = 'Worries a lot',
  VOCAL_OUTBURSTS_OR_CRYING = 'Vocal outbursts or crying',
  SEVERE_TANTRUMS = 'Severe tantrums',
  SENSITIVITY_TO_REJECTION = 'Sensitivity to rejection',
  SLEEP_PROBLEMS = 'Sleep problems',
  SOCIAL_WITHDRAWAL = 'Social withdrawal',
  IRRITABILITY_OR_ANGER = 'Irritability or anger',
  FEELING_SAD_UNHAPPY = 'Feeling sad, unhappy',
  SCHOOL_PROBLEMS = 'School problems',
  MISUSING_ALCOHOL = 'Misusing alcohol',
  FREQUENT_PHYSICAL_COMPLAINTS = 'Frequent physical complaints',
  SADNESS = 'Sadness',
  HOPELESSNESS = 'Hopelessness',
  BODY_IMAGE_ISSUES = 'Body image issues',
  LACK_OF_ENJOYMENT = 'Lack of enjoyment',
  IRRITABILITY = 'Irritability',
  TRAUMATIC_MEMORIES = 'Traumatic memories',
  GUILT = 'Guilt',
  FATIGUE = 'Fatigue',
  GENDER_IDENTITY_ISSUES = 'Gender identity issues',
  SLEEPING_TOO_MUCH = 'Sleeping too much',
  SLEEPING_TOO_LITTLE = 'Sleeping too little',
  DRINKING_TOO_MUCH = 'Drinking too much',
  USING_DRUGS = 'Using drugs',
  RELATIONSHIP_ISSUES = 'Relationship issues',
  NERVOUSNESS_OR_FEELING_ON_EDGE = 'Nervousness or feeling on edge',
  DIFFICULTY_SITTING_STILL = 'Difficulty sitting still',
  SOCIAL_ANXIETY = 'Social anxiety',
  FEAR_OF_PUBLIC_SPEAKING = 'Fear of public speaking',
  WORRYING_TOO_MUCH = 'Worrying too much',
  TENSION_OR_HEADACHES = 'Tension or headaches',
  PHOBIAS = 'Phobias',
  EMBARRASSMENT = 'Embarrassment',
  ARGUING_OR_FIGHTING = 'Arguing or fighting',
  CRAVINGS_TO_DRINK_OR_USE = 'Cravings to drink or use',
  PROBLEMS_AT_HOME_OR_WORK = 'Problems at home or work',
  NEGLECTING_RELATIONSHIPS = 'Neglecting relationships',
  COUPLES_ISSUES = 'Couples issues',
  FEELING_ISOLATED_OR_ALONE = 'Feeling isolated or alone',
  DIFFICULTY_SAYING_NO = 'Difficulty saying no',
  WORK_RELATIONSHIPS = 'Work relationships',
  IMPOSTER_SYNDROME = 'Imposter syndrome',
  NERVOUS_OR_ON_EDGE = 'Nervous or on edge',
  PERFECTIONISM = 'Perfectionism',
  RESTLESSNESS = 'Restlessness',
  TENSE_MUSCLES = 'Tense muscles',
  WORK_CHALLENGES = 'Work challenges',
  RACIAL_STRESS = 'Racial stress',
  LONELINESS = 'Loneliness',
  ALCOHOL_SUBSTANCE_USE = 'Alcohol or substance use',
  ALCOHOL_AND_SUBSTANCE_USE = 'Alcohol and substance use',
  FINANCIAL_WORRIES = 'Financial worries',
  JOB_CHANGE = 'Job change',
  SERVING_AS_CAREGIVER = 'Serving as a caregiver',
  SEXUAL_ORIENTATION = 'Sexual orientation',
  TRAUMATIC_EXPERIENCES = 'Traumatic experiences',
  TROUBLE_COMMUNICATING = 'Trouble communicating',
  SLEEP_ISSUES = 'Sleep issues',
  GRIEF_DEATH_LOVED_ONE = 'Grief/death of a loved one',
  AVOIDANCE_SOCIAL = 'Avoidance of social situations',
  BURNOUT = 'Burnout',
  WORTHLESSNESS = 'Worthlessness',
  // CPR-472
  BODY_IMAGE = 'Body image',
  GENDER_IDENTITY = 'Gender identity',
  PARENTING = 'Parenting',
  FERTILITY = 'Fertility',
  INTIMATE_RELATIONSHIPS = 'Intimate relationships',
  MAJOR_HEALTH_CONDITIONS = 'Chronic or major health conditions',
  FEELING_OVERWHELMED = 'Feeling overwhelmed',
}

// For Children age 0-4
export const youngChildPrimaryNeedsWordCloudMappings = {
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.WORRIES_A_LOT,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.SEVERE_TANTRUMS,
      TriageWords.SENSITIVITY_TO_REJECTION,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.BEING_BULLIED_TEASED,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.FEELING_LIKE_A_BAD_PERSON,
      TriageWords.LESS_INTEREST_IN_FRIENDS_HOBBIES,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.ANXIETY]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.BEING_BULLIED_TEASED,
      TriageWords.COMPULSIVE_BEHAVIORS,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.PHOBIA,
      TriageWords.WORRIES_A_LOT,
      TriageWords.SENSITIVITY_TO_REJECTION,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.OBSESSIVE_THOUGHTS,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.SEVERE_TANTRUMS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.ADHD]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.AGGRESSIVE,
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.DISTRACTED,
      TriageWords.EXCITABLE,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.FIDGETY,
      TriageWords.IMPULSIVITY,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.LEARNING_DIFFERENCES,
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.REPEATING_WORDS_PHRASES,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.AUTISM_SPECTRUM]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.POOR_EYE_CONTACT,
      TriageWords.CANNOT_POINT_TO_THINGS,
      TriageWords.POOR_SOCIAL_SKILLS,
      TriageWords.REPETITIVE_PHRASES,
      TriageWords.REPETITIVE_MOVEMENTS,
      TriageWords.GETS_STUCK_ON_ROUTINES,
      TriageWords.UNUSUAL_SKILLS_INTERESTS,
      TriageWords.SENSORY_SEEKING,
      TriageWords.SENSITIVE_TO_NOISE_OR_TOUCH,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.NONVERBAL,
      TriageWords.LANGUAGE_DELAY,
      TriageWords.LEARNING_PROBLEMS,
      TriageWords.ADD_ADHD,
      TriageWords.WORRIES,
      TriageWords.INTELLECTUAL_DISABILITY,
      TriageWords.NOT_TOILET_TRAINED,
      TriageWords.POOR_COORDINATION,
      TriageWords.SEVERE_TANTRUMS,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.AFRAID_OF_NEW_THINGS,
      TriageWords.DISCIPLINE_PROBLEMS,
      TriageWords.NEW_SIBLING,
      TriageWords.NOT_COMMUNICATING,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.SEVERE_TANTRUMS,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.AGGRESSIVE,
      TriageWords.ANXIETY,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.TROUBLE_WITH_FRIENDS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
}

// For Children age 5-11
export const middleChildPrimaryNeedsWordCloudMappings = {
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.WORRIES_A_LOT,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.FEELING_HOPELESS,
      TriageWords.FEELING_LIKE_A_BAD_PERSON,
      TriageWords.LESS_INTEREST_IN_FRIENDS_HOBBIES,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.PANIC_ATTACKS,
    ],
  },
  [PrimaryNeedsWords.ANXIETY]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.COMPULSIVE_BEHAVIORS,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.PANIC_ATTACKS,
      TriageWords.PHOBIA,
      TriageWords.WORRIES_A_LOT,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.SENSITIVITY_TO_REJECTION,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.BEING_BULLIED_TEASED,
      TriageWords.OBSESSIVE_THOUGHTS,
      TriageWords.TRAUMA,
    ],
  },
  [PrimaryNeedsWords.SCHOOL]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.LEARNING_DIFFERENCES,
      TriageWords.LESS_INTEREST_IN_SCHOOL,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.WORRIES_A_LOT,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.BULLYING_TEASING_OTHERS,
      TriageWords.DISCIPLINE_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
    ],
  },
  [PrimaryNeedsWords.ADHD]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.AGGRESSIVE,
      TriageWords.DISTRACTED,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.FIDGETY,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.LEARNING_DIFFERENCES,
      TriageWords.TROUBLE_WITH_TEACHERS,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.WORRIES_A_LOT,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.DAYDREAMING,
      TriageWords.REPEATING_WORDS_PHRASES,
    ],
  },
  [PrimaryNeedsWords.AUTISM_SPECTRUM]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.POOR_EYE_CONTACT,
      TriageWords.CANNOT_POINT_TO_THINGS,
      TriageWords.POOR_SOCIAL_SKILLS,
      TriageWords.REPETITIVE_PHRASES,
      TriageWords.REPETITIVE_MOVEMENTS,
      TriageWords.GETS_STUCK_ON_ROUTINES,
      TriageWords.UNUSUAL_SKILLS_INTERESTS,
      TriageWords.SENSORY_SEEKING,
      TriageWords.SENSITIVE_TO_NOISE_OR_TOUCH,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.NONVERBAL,
      TriageWords.LANGUAGE_DELAY,
      TriageWords.LEARNING_PROBLEMS,
      TriageWords.ADD_ADHD,
      TriageWords.WORRIES,
      TriageWords.INTELLECTUAL_DISABILITY,
      TriageWords.NOT_TOILET_TRAINED,
      TriageWords.POOR_COORDINATION,
      TriageWords.SEVERE_TANTRUMS,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.AFRAID_OF_NEW_THINGS,
      TriageWords.DISCIPLINE_PROBLEMS,
      TriageWords.NEW_SIBLING,
      TriageWords.NOT_COMMUNICATING,
      TriageWords.VOCAL_OUTBURSTS_OR_CRYING,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.AGGRESSIVE,
      TriageWords.ANXIETY,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.TROUBLE_WITH_FRIENDS,
      TriageWords.TROUBLE_WITH_TEACHERS,
      TriageWords.THOUGHTS_OF_SUICIDE,
    ],
  },
}

// For Children age 12-18
export const olderChildPrimaryNeedsWordCloudMappings = {
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ANXIETY,
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.PANIC_ATTACKS,
      TriageWords.RACING_THOUGHTS_SPEECH,
      TriageWords.RISKY_BEHAVIORS,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.UNUSUALLY_HIGH_ENERGY,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
    ],
    [TriageScreenerNames.WORD_CLOUD_2]: [
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.FEELING_HOPELESS,
      TriageWords.FEELING_LIKE_A_BAD_PERSON,
      TriageWords.LESS_INTEREST_IN_FRIENDS_HOBBIES,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.OVERWHELMED,
    ],
  },
  [PrimaryNeedsWords.ANXIETY]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.PANIC_ATTACKS,
      TriageWords.PHOBIA,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.OBSESSIVE_THOUGHTS,
      TriageWords.OVERWHELMED,
      TriageWords.TRAUMA,
    ],
  },
  [PrimaryNeedsWords.STRESS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ANGER,
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.OBSESSIVE_THOUGHTS,
      TriageWords.PANIC_ATTACKS,
      TriageWords.TRAUMA,
      TriageWords.SLEEP_PROBLEMS,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.FREQUENT_PHYSICAL_COMPLAINTS,
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.NEGATIVE_THOUGHTS,
      TriageWords.OVERWHELMED,
    ],
  },
  [PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ANXIETY,
      TriageWords.ARGUING_FIGHTING,
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.LESS_INTEREST_IN_FRIENDS_HOBBIES,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.NOT_COMMUNICATING,
      TriageWords.PARENTING_ISSUES,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.FEELING_CRITICIZED,
      TriageWords.SEPARATION_DIVORCE,
    ],
  },
  [PrimaryNeedsWords.SCHOOL]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.DIFFICULTY_CONCENTRATING,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.LEARNING_DIFFERENCES,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.WORRIES_A_LOT,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.BAD_GRADES,
      TriageWords.BEHAVIOR_ISSUES,
      TriageWords.BULLYING_TEASING_OTHERS,
      TriageWords.DISCIPLINE_PROBLEMS,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.IRRITABILITY_OR_ANGER,
    ],
  },
  [PrimaryNeedsWords.ADHD]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ACTS_YOUNGER_THAN_AGE,
      TriageWords.AGGRESSIVE,
      TriageWords.BAD_GRADES,
      TriageWords.DISTRACTED,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.FIDGETY,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.LEARNING_DIFFERENCES,
      TriageWords.TROUBLE_WITH_TEACHERS,
      TriageWords.WORRIES_A_LOT,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.DAYDREAMING,
      TriageWords.REPEATING_WORDS_PHRASES,
    ],
  },
  [PrimaryNeedsWords.ALCOHOL_DRUG_USE]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ARGUING_FIGHTING,
      TriageWords.DEPRESSED,
      TriageWords.FAMILY_CONFLICT,
      TriageWords.FEELING_LIKE_A_FAILURE,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.PANIC_ATTACKS,
      TriageWords.RACING_THOUGHTS_SPEECH,
      TriageWords.RISKY_BEHAVIORS,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.TAKES_UNNECESSARY_RISKS,
      TriageWords.WORRIES_A_LOT,
      TriageWords.SOCIAL_WITHDRAWAL,
    ],
    [TriageScreenerNames.WORD_CLOUD_2]: [
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.BINGE_DRINKING,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.MISUSING_OPIOIDS,
      TriageWords.MISUSING_PRESCRIPTION_DRUGS,
      TriageWords.TRAUMA,
      TriageWords.UNUSUALLY_HIGH_ENERGY,
      TriageWords.USING_HEROIN,
      TriageWords.USING_STREET_DRUGS,
    ],
  },
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS]: {
    [TriageScreenerNames.WORD_CLOUD_1]: [
      TriageWords.ARGUING_FIGHTING,
      TriageWords.BULLYING_TEASING_OTHERS,
      TriageWords.DISCIPLINE_PROBLEMS,
      TriageWords.HYPERACTIVITY,
      TriageWords.IMPULSIVITY,
      TriageWords.LACKING_RESTRAINT,
      TriageWords.NOT_COMMUNICATING,
      TriageWords.RISKY_BEHAVIORS,
      TriageWords.SELF_HARM,
      TriageWords.THOUGHTS_OF_SUICIDE,
      TriageWords.TRAUMA,
      TriageWords.UNUSUALLY_HIGH_ENERGY,
      TriageWords.WORRIES_A_LOT,
      TriageWords.SOCIAL_WITHDRAWAL,
      TriageWords.FEELING_SAD_UNHAPPY,
      TriageWords.SCHOOL_PROBLEMS,
      TriageWords.MISUSING_ALCOHOL,
      TriageWords.NEGATIVE_THOUGHTS,
    ],
  },
}

export const allChildPrimaryNeedsWordCloudMappings = {
  [SearcherType.YOUNGER_CHILD]: youngChildPrimaryNeedsWordCloudMappings,
  [SearcherType.MIDDLE_CHILD]: middleChildPrimaryNeedsWordCloudMappings,
  [SearcherType.OLDER_CHILD]: olderChildPrimaryNeedsWordCloudMappings,
}

export const currentToNextMetadataNamesDefault = {
  startingMetadata: TriageScreenerNames.PRIMARY_NEEDS,
  [TriageScreenerNames.PRIMARY_NEEDS]: TriageScreenerNames.WORD_CLOUD_1,
  [TriageScreenerNames.WORD_CLOUD_1]: TriageScreenerNames.WORD_CLOUD_2,
  [TriageScreenerNames.WORD_CLOUD_2]: TriageScreenerNames.INTENSITY,
  [TriageScreenerNames.INTENSITY]: TriageScreenerNames.WORK_LIFE,
  [TriageScreenerNames.WORK_LIFE]: TriageScreenerNames.LIFE_CHANGES,
  [TriageScreenerNames.LIFE_CHANGES]: TriageScreenerNames.CARE_EXPERIENCES,
  [TriageScreenerNames.CARE_EXPERIENCES]: TriageScreenerNames.CLINICAL_SCREENERS,
}

export const currentToNextMetadataNamesChildOlder = {
  startingMetadata: TriageScreenerNames.PRIMARY_NEEDS,
  primaryNeeds: TriageScreenerNames.WORD_CLOUD_1,
  wordCloud1: TriageScreenerNames.WORD_CLOUD_2,
  wordCloud2: TriageScreenerNames.INTENSITY,
}

export const currentToNextMetadataNamesChild = {
  startingMetadata: TriageScreenerNames.PRIMARY_NEEDS,
  primaryNeeds: TriageScreenerNames.WORD_CLOUD_1,
  wordCloud1: TriageScreenerNames.INTENSITY,
}

export const currentToNextMetadataNamesAll = {
  [SearcherType.ADULT]: { ...currentToNextMetadataNamesDefault },
  [SearcherType.YOUNGER_CHILD]: { ...currentToNextMetadataNamesChild },
  [SearcherType.MIDDLE_CHILD]: { ...currentToNextMetadataNamesChild },
  [SearcherType.OLDER_CHILD]: { ...currentToNextMetadataNamesChildOlder },
}

// NOTE: Order matters!
// see severity column for order in GenericWordCloud.tsv from https://github.com/LyraHealth/triage/tree/staging
export const slidersWords = [
  TriageWords.SELF_HARM,
  TriageWords.HOPELESSNESS,
  TriageWords.ALCOHOL_SUBSTANCE_USE,
  TriageWords.TRAUMATIC_EXPERIENCES,
  TriageWords.WORTHLESSNESS,
  TriageWords.PANIC_ATTACKS,
  PrimaryNeedsWords.ANXIETY,
  TriageWords.ANGER,
  TriageWords.OBSESSIVE_THOUGHTS,
  TriageWords.SADNESS,
  TriageWords.GAMBLING,
  TriageWords.SLEEP_ISSUES,
  TriageWords.CHRONIC_PAIN,
  TriageWords.IRRITABILITY,
  TriageWords.MAJOR_HEALTH_CONDITIONS,
  TriageWords.AVOIDANCE_SOCIAL,
  TriageWords.BODY_IMAGE_ISSUES,
  TriageWords.GUILT,
  TriageWords.GRIEF_DEATH_LOVED_ONE,
  TriageWords.WORRYING_TOO_MUCH,
  TriageWords.TROUBLE_COMMUNICATING,
  TriageWords.DIFFICULTY_CONCENTRATING,
  TriageWords.LACK_OF_ENJOYMENT,
  TriageWords.RESTLESSNESS,
  TriageWords.LONELINESS,
  TriageWords.TROUBLE_RELAXING,
  TriageWords.SLEEPING_TOO_MUCH,
  TriageWords.FEELING_OVERWHELMED,
  TriageWords.NERVOUSNESS_OR_FEELING_ON_EDGE,
  TriageWords.FATIGUE,
  TriageWords.INTIMATE_RELATIONSHIPS,
  TriageWords.PARENTING,
  TriageWords.SERVING_AS_CAREGIVER,
  TriageWords.PREGNANCY_NEW_CHILD,
  TriageWords.FERTILITY,
  TriageWords.GENDER_IDENTITY,
  TriageWords.RACIAL_STRESS,
  TriageWords.SEXUAL_ORIENTATION,
  TriageWords.FINANCIAL_WORRIES,
  TriageWords.JOB_CHANGE,
  TriageWords.WORK_CHALLENGES,
  TriageWords.BURNOUT,
  TriageWords.PERFECTIONISM,
  TriageWords.IMPOSTER_SYNDROME,
  TriageWords.LOW_SELF_ESTEEM,
  TriageWords.CRAVINGS_TO_DRINK_OR_USE,
  TriageWords.DIFFICULTY_CUTTING_BACK,
]

export const APPOINTMENT_CONFLICTS = {
  EXISTING_BLENDED_CARE_EPISODE_OF_SAME_TYPE_WITH_DIFFERENT_PROVIDER_BOOKED: 'different-provider-appointment-conflict',
  EXISTING_SINGLE_SESSION_COACHING_EPISODE: 'existing-single-session-coaching-episode',
  EXISTING_BLENDED_CARE_EPISODE_OF_SAME_TYPE_WITH_SAME_PROVIDER_BOOKED: 'same-provider-appointment-conflict',
}

export const triageScreenToEventMap: { [key in TriageScreenerNames]?: TRIAGE_EVENTS } = {
  [TriageScreenerNames.PRIMARY_NEEDS]: TRIAGE_EVENTS.VIEW_PRIMARY_NEED,
  [TriageScreenerNames.WORD_CLOUD_1]: TRIAGE_EVENTS.VIEW_WORD_CLOUD_0,
  [TriageScreenerNames.WORD_CLOUD_2]: TRIAGE_EVENTS.VIEW_WORD_CLOUD_1,
  [TriageScreenerNames.INTENSITY]: TRIAGE_EVENTS.VIEW_SEVERITY_SLIDERS,
  [TriageScreenerNames.WORK_LIFE]: TRIAGE_EVENTS.VIEW_IMPAIRMENT_OF_FUNCTIONING,
  [TriageScreenerNames.CLINICAL_SCREENERS]: TRIAGE_EVENTS.VIEW_CLINICAL_SCREENERS,
  [TriageScreenerNames.CARE_EXPERIENCES]: TRIAGE_EVENTS.VIEW_CARE_EXPERIENCES,
  [TriageScreenerNames.LIFE_CHANGES]: TRIAGE_EVENTS.VIEW_LIFE_CHANGES,
}

export enum SEARCHER_TYPE_TO_MATCH_FOR {
  SELF = 'self',
  OTHER_CHILD = 'otherChild',
}

export type ChildInfoFormValues = {
  childsFirstName: string
  childsLastName: string
  childsDateOfBirth: string
  childsGenderIdentity: string
  soughtCareInThePast: boolean
}

export const CHILD_INFO_FORM_FIELD_NAMES: Record<keyof ChildInfoFormValues, string> = {
  childsFirstName: 'childsFirstName',
  childsLastName: 'childsLastName',
  childsDateOfBirth: 'childsDateOfBirth',
  childsGenderIdentity: 'childsGenderIdentity',
  soughtCareInThePast: 'soughtCareInThePast',
}

export const SPECIALIZED_NEED_FORM_MSG = defineMessages({
  [PrimaryNeedsWords.AUTISM_SPECTRUM]: {
    defaultMessage: 'signs of autism',
    description: "Search for care: Child's primary need: Autism",
  },
  [PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS]: {
    defaultMessage: 'developmental delays',
    description: "Search for care: Child's primary need: Developmental delay",
  },
})

export interface ContactUsFormValues {
  emailChecked: boolean
  phoneChecked: boolean
  emailAddress: string
  phoneNumber: string
  textareanote: string
}

export enum SEARCH_FOR_CARE_USER_TYPE {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international',
}

export enum ContactCareFlowType {
  DEFAULT = 'default',
  CHILD_SEARCH_FOR_CARE = 'childSearchForCare',
}

export type ClinicalDataSharingFormValues = {
  userLastName: string
  dateOfBirth: string
  providedConsentToShare: boolean
  thirdPartyProviderLastName: string
}

export const CLINICAL_DATA_SHARING_FORM_FIELD_NAMES: Record<keyof ClinicalDataSharingFormValues, string> = {
  userLastName: 'userLastName',
  dateOfBirth: 'dateOfBirth',
  providedConsentToShare: 'providedConsentToShare',
  thirdPartyProviderLastName: 'thirdPartyProviderLastName',
}

export enum DirectLinkSource {
  REENGAGEMENT_EMAIL = 'reengagement_email',
  WORK_HUB = 'work_hub',
  MICROSITE_FORKED_TRIAGE = 'microsite_forked_triage',
  MICROSITE_BOOK_APPT_CTA = 'microsite_book_appt_cta',
  HOMEBASE_WELLNESS_TOOLKIT_CTA = 'homebase_wellness_toolkit_cta',
  HOMEBASE_FORKED_TRIAGE = 'homebase_forked_triage',
  INTEGRATED_BENEFITS_FORKED_TRIAGE = 'integrated_benefits_forked_triage',
  COMPLEX_CARE = 'complex_care',
  WELLNESS_TOOLKIT = 'wellness_toolkit',
  FORKED_TRIAGE = 'forked_triage',
  BENEFITS_PAGE = 'benefits_page',
  CARE_TAB = 'care_tab',
  INTEGRATED_BENEFITS_WELLNESS_TOOLKIT_CTA = 'integrated_benefits_wellness_toolkit_cta',
  SEARCH_FOR_CARE_MODAL = 'search_for_care_modal',
  SEARCH_FOR_CARE_MODAL_FORKED_TRIAGE = 'search_for_care_modal_forked_triage',
  INTEGRATED_BENEFITS_TRIAGE = 'integrated_benefits_triage',
  AUTO_ENROLLMENT_PATHWAYS = 'auto_enrollment_pathways',
}

export type DirectPathBookingApplinkParams = ProviderProgramTaxonomy & {
  directPath?: string // set to "true" for web only
  directLinkIntent?: DirectLinkIntent | string
  directLinkSource?: DirectLinkSource | string
}

export enum SearcherForTriageSummary {
  ADULT = 'adult',
  TEEN = 'teen',
  CHILD = 'child',
}

export interface OnCareOptionModalOpenCloseProps {
  treatmentOption: CARE_OPTION
  age?: number
}

export enum ZeroResultsIntendedProgram {
  COACHING = 'coaching',
  RENEW = 'renew',
}
