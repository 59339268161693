import { useEffect, useState } from 'react'

import { ProviderCapacity, ProviderProgramTaxonomy } from '../../../models'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { ExperimentPayload, UrgencyToBookArmMapping, UrgencyToBookV2PageLocation } from '../../experiments/types'
import { generateUrgencyToBookExperimentPayload } from '../../experiments/utils'
import { getShouldShowUrgencyToBookv2 } from '../../providers/utils'

export interface useUrgencyToBookV2ExperimentArgs {
  updateLDContext: (data: any) => Promise<void>
  selectedTreatmentOption?: TREATMENT_OPTIONS_TYPE | null
  providerCapacity?: ProviderCapacity
  programTaxonomy?: ProviderProgramTaxonomy[]
  renderSaveYourSpotV2?: boolean
  userId: string
  providerId: string
  searchId: string
  requestId: string
  page_location: UrgencyToBookV2PageLocation
  postExperimentEntrypoint: (payload: ExperimentPayload) => Promise<any>
  deviceUUID: string
  isFocused: boolean /* For mobile usage, so useEffect doesn't run on unfocused background screen */
  outsideOnboarding?: boolean /* Web only */
}

export const useUrgencyToBookV2Experiment = ({
  updateLDContext,
  selectedTreatmentOption,
  providerCapacity,
  programTaxonomy,
  renderSaveYourSpotV2,
  userId,
  providerId,
  searchId,
  requestId,
  page_location,
  deviceUUID,
  postExperimentEntrypoint,
  isFocused,
  outsideOnboarding = false,
}: useUrgencyToBookV2ExperimentArgs) => {
  const [isLDContextUpdated, setIsLDContextUpdated] = useState(false)
  const [isEligibleProvider, setIsEligibleProvider] = useState<boolean | null>(null)

  useEffect(() => {
    if (!isFocused || isEligibleProvider !== null) return
    if (!providerCapacity || !programTaxonomy || isLDContextUpdated) return
    const isEligibleUrgencyToBookV2Provider = getShouldShowUrgencyToBookv2({
      programTaxonomy,
      selectedTreatmentOption,
      providerCapacity,
    })
    setIsEligibleProvider(isEligibleUrgencyToBookV2Provider || false)
  }, [isFocused, isLDContextUpdated, selectedTreatmentOption, programTaxonomy, providerCapacity, isEligibleProvider])

  useEffect(() => {
    // Only run the LD context fetch if
    // If we haven't evaluated the provider's low capacity eligibility yet, don't run effect

    if (!isFocused || isLDContextUpdated || isEligibleProvider === null) return
    const fetchUpdatedLDContext = async () => {
      const hasRenderSaveYourSpotV2BeenFetched = renderSaveYourSpotV2 !== undefined
      // If this is the first time the user encounters a low capacity, eligible treatment option provider
      if (!hasRenderSaveYourSpotV2BeenFetched && isEligibleProvider) {
        // Set global state to true so that we only fetch the FF assignment once for the user's current session
        await updateLDContext({ hasViewedLowCapacityProvider: true })
      }
      setIsLDContextUpdated(true)
    }
    fetchUpdatedLDContext()
  }, [renderSaveYourSpotV2, isFocused, updateLDContext, isEligibleProvider, isLDContextUpdated])

  useEffect(() => {
    // Only post the experiment data if the LD context is updated and provider is eligible low capacity provider
    if (!isFocused || !isEligibleProvider || !isLDContextUpdated) return

    if (!providerId || !userId) return
    if (!outsideOnboarding && !searchId && !requestId) return
    const hasRenderSaveYourSpotV2BeenFetched = renderSaveYourSpotV2 !== undefined

    if (hasRenderSaveYourSpotV2BeenFetched) {
      const postExperimentSNSData = async () => {
        const urgencyToBookV2ExperimentPayload = generateUrgencyToBookExperimentPayload({
          arm: renderSaveYourSpotV2 ? UrgencyToBookArmMapping.CAN_SEE : UrgencyToBookArmMapping.CANNOT_SEE,
          client_lyra_id: userId,
          provider_id: providerId,
          device_uuid: deviceUUID,
          search_id: !outsideOnboarding ? searchId : '',
          matcher_request_id: !outsideOnboarding ? requestId : '',
          page_location,
        })

        try {
          await postExperimentEntrypoint(urgencyToBookV2ExperimentPayload)
        } catch (e) {
          console.error(`Unable to post urgency to book v2  experiment data from ${page_location}`, e)
        }
      }

      postExperimentSNSData()
    }
  }, [
    isFocused,
    postExperimentEntrypoint,
    isEligibleProvider,
    isLDContextUpdated,
    renderSaveYourSpotV2,
    userId,
    providerId,
    deviceUUID,
    searchId,
    requestId,
    page_location,
    outsideOnboarding,
  ])

  return
}
