import React from 'react'
import { defineMessage } from 'react-intl'

import { generateServicesNotCovered } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

/**
 * Services that Lyra does not cover for customers without psychiatry.
 * You should only be importing this if you need this list in a custom string, e.g. a custom "servicesNotCovered" string
 * that doesn't match the content of the servicesNotCovered string here.
 * Otherwise, you probably want to be importing servicesNotCovered instead.
 */
export const excludedServices =
  'psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, and non-evidence-based behavioral health care'

// Same as `excludedServices` but wrapping with defineMessage
export const excludedServicesNotice = defineMessage({
  defaultMessage:
    'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, and non-evidence-based behavioral health care.',
  description: 'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
})

export const servicesNotCovered = generateServicesNotCovered({
  excludedServices,
})

export const defaultCustomerWithoutMeds = {
  legalNotice: <CoverageNotice excludedServices={excludedServicesNotice} />,
  servicesNotCovered,
}
