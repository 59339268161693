import { useCallback } from 'react'

import { CountryCode as CountryIsoCode, parsePhoneNumber } from 'libphonenumber-js'

import {
  Address,
  CalendarBookingPayload,
  MEETING_FORMATS,
  PROVIDER_TYPES,
  ProviderEnums,
  ProviderInfo,
} from '../../../models'
import { ProviderProgramTaxonomy } from '../../../models/providers/Providers'
import { TREATMENT_MODE } from '../../common/constants/appointmentModalityConstants'
import { convertProviderTreatmentModalityToTreatmentOption } from '../../common/constants/providerTreatmentModalityConstants'
import {
  convertTreatmentOptionValueForBackend,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { formatAddressForSubmission } from '../../locationFinder/utils'
import { isProviderAnyTypeOf, isProviderBlendedCareType } from '../../providers/utils'
import { BOOKING_CHANNEL_TYPE, CalendarAppointment, PHONE_TYPES } from '../types'

interface UseBookingPayloadParam {
  appointment: CalendarAppointment
  provider: ProviderInfo
  appointmentType?: string
  isNear: boolean
  address?: Address | null
  treatmentType: TREATMENT_OPTIONS_TYPE | null
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
  fallbackTreatmentOption: TREATMENT_OPTIONS_TYPE | null
  timeZone: string
  userId: string
  outsideOnboarding: boolean
  appointmentDurationMinutes: number
  countryIsoCode: CountryIsoCode
  meetingFormat: MEETING_FORMATS | null
  phone: string | null
  phoneType: PHONE_TYPES
  smsConsent: boolean
  searchId?: string
  isDirectPath?: boolean
  isClientRebooking?: boolean
  isDirectBookingLink?: boolean
  isCNTBooking?: boolean
  episodeId?: string
  childLyraId?: string
  childEmail?: string
  providerPatientId?: string | null
  requestId: string
  isParentLedLctTeenBooking?: boolean
  programTaxonomy?: ProviderProgramTaxonomy
  directBookingLinkCreateDate?: string
}

/**
 * Hook that helps create the payload to create a new appointment used crossplatform
 */
export const useBookingPayload = () => {
  const makePayload = useCallback(
    ({
      appointment,
      provider,
      appointmentType,
      address,
      isNear,
      selectedTreatmentOption,
      fallbackTreatmentOption,
      timeZone,
      userId,
      treatmentType,
      appointmentDurationMinutes,
      countryIsoCode,
      meetingFormat,
      phone,
      phoneType,
      smsConsent,
      searchId,
      isDirectPath,
      isClientRebooking,
      isDirectBookingLink,
      outsideOnboarding,
      isCNTBooking = false,
      episodeId,
      providerPatientId,
      childLyraId,
      childEmail,
      requestId,
      isParentLedLctTeenBooking,
      programTaxonomy,
      directBookingLinkCreateDate,
    }: UseBookingPayloadParam): CalendarBookingPayload => {
      const isCoach = isProviderAnyTypeOf({ provider, providerTypes: [PROVIDER_TYPES[ProviderEnums.COACH]] })
      const isBlendedCareProvider = isProviderBlendedCareType({ provider })
      let locationId
      let addressDisplay = ''
      locationId = address?.id?.toString()
      if (isNear) {
        const visitType = provider.supportedVisitTypes
        addressDisplay =
          (visitType === TREATMENT_MODE.BOTH_VIDEO_INPERSON && meetingFormat !== MEETING_FORMATS.VIDEO) ||
          visitType === TREATMENT_MODE.ONLY_INPERSON // Off site providers that are in person or video/in person need their own address
            ? provider.nearestAddress
              ? formatAddressForSubmission(provider.nearestAddress)
              : ''
            : ''
        locationId = ''
      } else if (address && Object.keys(address).length) {
        addressDisplay = formatAddressForSubmission(address)
      }

      // Only bookings with a searchId present are triage searches
      const isBookingConnectedToTriageSearch = !!searchId

      if (isBookingConnectedToTriageSearch) {
        if (fallbackTreatmentOption) {
          treatmentType = fallbackTreatmentOption
        } else treatmentType = selectedTreatmentOption
      } else if (selectedTreatmentOption) {
        treatmentType = selectedTreatmentOption
      } else if (isCoach) {
        treatmentType = TREATMENT_OPTIONS.COACHING
      }
      const treatmentTypeForBE = treatmentType ? convertTreatmentOptionValueForBackend(treatmentType) : treatmentType
      const providerModalities = provider.modalities

      const selectedProviderModality = providerModalities?.find(
        (modality) => convertProviderTreatmentModalityToTreatmentOption(modality) === treatmentType,
      )

      const phoneNumberInfo = phone ? parsePhoneNumber(phone, countryIsoCode) : null

      let bookingChannel
      if (isDirectPath) {
        bookingChannel = BOOKING_CHANNEL_TYPE.DIRECT_PATH
      } else if (isCNTBooking) {
        bookingChannel = BOOKING_CHANNEL_TYPE.CNT_BOOKING
      } else if (isClientRebooking) {
        bookingChannel = BOOKING_CHANNEL_TYPE.CLIENT_REBOOKING
      } else if (isDirectBookingLink || outsideOnboarding) {
        bookingChannel = BOOKING_CHANNEL_TYPE.DIRECT_BOOKING_LINK
      } else if (isBookingConnectedToTriageSearch) {
        bookingChannel = BOOKING_CHANNEL_TYPE.TRIAGE
      } else {
        bookingChannel = undefined
      }

      let guardianLyraId
      if (isParentLedLctTeenBooking && childLyraId) {
        // This is parent-led lct teen initial booking. In this case, we:
        // 1. set guardianLyraId to be the userId as the userId passed is the guardian's Lyra ID for child bookings.
        // 2. set userId to be childLyraId so appointment is booked under the child instead of the guardian.
        // 3. clear the childLyraId field as it's not longer applicable
        guardianLyraId = userId
        userId = childLyraId
        childLyraId = undefined
      }

      return {
        address: addressDisplay,
        appointmentDuration: appointmentDurationMinutes ?? 50,
        appointmentType:
          appointmentType || provider.supportedVisitTypes === TREATMENT_MODE.ONLY_VIDEO ? 'video' : 'googleCalendar',
        bookingChannel,
        childLyraId,
        childEmail: !!childEmail ? encodeURIComponent(childEmail) : undefined,
        guardianLyraId,
        details: `${treatmentTypeForBE} https://care.lyrahealth.com/secure/care-manager/patient-detail?uid=${userId}`,
        episodeId,
        locationId: !isCoach ? locationId : undefined,
        lyraProviderId: provider.lyra_id,
        meetingFormat,
        phone: phoneNumberInfo?.number,
        phoneCountryCode: countryIsoCode,
        phoneType,
        providerPatientId,
        requestId,
        searchId,
        selectedProviderModality,
        smsConsent: isBlendedCareProvider ? smsConsent : false,
        startDate: appointment.date,
        startTime: appointment.time,
        supportedVisitTypes: provider.supportedVisitTypes,
        timeZone,
        title: 'New Appointment',
        treatmentType: treatmentTypeForBE,
        userId,
        ...(!!programTaxonomy && { programTaxonomy }),
        directBookingLinkCreateDate,
      }
    },
    [],
  )
  return { makePayload }
}
