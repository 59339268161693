import React from 'react'
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import {
  consentToSMSMessage,
  DEFAULT_WORKLIFE_SERVICES,
  ELIGIBILITY_TYPES,
  eligibilityDelayNotice,
  EligibilityField,
  EligibilityType,
  excludedServices,
  generateServicesNotCovered,
  GetCustomerCopyFunc,
  invalidEligibilityDobMessage,
  invalidEligibilityFirstNameMessage,
  invalidEligibilityLastNameMessage,
  LocationMatcherDisplayFormats,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Subhead } from '../atoms'
import { getDefaultCostMessagingModalText } from './util/DefaultCoverageTexts'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { BasicInfoField } from '../organisms/setUpAccountForm/basicInfoMetadata'
import { SubheadSize } from '../styles'

export const servicesNotCovered = generateServicesNotCovered({
  excludedServices,
})

export const servicesCovered =
  'Lyra can help with stress, anxiety, depression, relationship issues, sleep disorders, and other common behavioral issues that can be treated through short-term outpatient behavioral health services.'

export const onsiteDescription = (
  <FormattedMessage
    defaultMessage='For your convenience, you may have the option to meet with a provider at your office. To schedule an onsite appointment, select a location below. Be sure to select a location where you work or you can easily access.'
    description='Body text that explains that you can select a location below this text to meet with a provider onsite if it is available. The text also emphasizes that the user should choose a location where they work or can easily get to.'
  />
)

export const onsiteAlternativeDescription = (
  <FormattedMessage
    defaultMessage='If you don’t see providers in your area, you may not have an onsite provider at your office or the onsite provider may not have availability over the next two weeks. We suggest trying a different care option above.'
    description="Body text that explains if there is no offsite location below the text that is in the user's area, then they should try using different care option above."
  />
)

export const DefaultCustomerRedirectionHeaderText: React.FC<{ customerName: string; boldCustomerName?: boolean }> = ({
  customerName,
  boldCustomerName = false,
}) => (
  // customerName should not be translated
  <FormattedMessage
    defaultMessage='Your access through {customerName}'
    description='Header in a dialogue box that asks user select which employee subpopulation or specific health plan applies to them'
    values={{
      customerName: boldCustomerName ? (
        <Subhead bold={true} text={customerName} size={SubheadSize.LARGE} />
      ) : (
        customerName
      ),
    }}
  />
)

export const defaultCustomer: GetCustomerCopyFunc = ({
  numberOfSessions,
  numberOfSessionsByProgram,
  considerMeds,
  customerName,
  customerDisplayName,
  isProgramLevelSessionLimitEnabled = false,
}) => {
  return {
    displaySessionLimit: true,
    // TODO - Add faqQuestions: [],
    selfCareAppButtons: [],
    eligibilityQuestionSubtitle: eligibilityDelayNotice,
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage:
          'Are you {employeeType, select, associate {an associate} member {a member} partner {a partner} teammate {a teammate} teammember {a team member} employee {an employee} other {an employee}}{hasCustomerName, select, yes { of {customerName}} other {}}?',
        description: 'Asking the user if they are the employee of a company or organization or member of a plan',
      }),
    generateEligibilityDependentTitle: () =>
      defineMessage({
        defaultMessage: `Provide the {hasCustomerName, select, yes {{customerName} {employeeType, select, associate {associate's} member {member's} partner {partner's} teammate {teammate's} teammember {team member's} employee {employee's} other {employee's}}} other {{employeeType, select, associate {associate's} member {member's} teammate {teammate's} teammember {team member's} employee {employee's} other {employee's}}}} information`,
        description: 'Question for whom the benefit provider is',
      }),
    eligibilityCheckSubmissionErrors: {
      [EligibilityField.EMPLOYEE_FIRST_NAME]: invalidEligibilityFirstNameMessage,
      [EligibilityField.EMPLOYEE_LAST_NAME]: invalidEligibilityLastNameMessage,
      [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: invalidEligibilityDobMessage,
    },
    generateEligibilityCheckSubmissionErrors: (
      eligibilityType?: EligibilityType,
      checkAdultDependentEligibilityUsingDependentInfo?: boolean,
    ): {
      [fieldName: string]: MessageDescriptor
    } => {
      const isDependent = eligibilityType === ELIGIBILITY_TYPES.DEPENDENT
      if (isDependent && !checkAdultDependentEligibilityUsingDependentInfo) {
        return {
          [EligibilityField.EMPLOYEE_FIRST_NAME]: invalidEligibilityFirstNameMessage,
          [EligibilityField.EMPLOYEE_LAST_NAME]: invalidEligibilityLastNameMessage,
          [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: invalidEligibilityDobMessage,
        }
      } else {
        return {
          [BasicInfoField.USER_FIRST_NAME]: invalidEligibilityFirstNameMessage,
          [BasicInfoField.USER_LAST_NAME]: invalidEligibilityLastNameMessage,
          [BasicInfoField.USER_DATE_OF_BIRTH]: invalidEligibilityDobMessage,
        }
      }
    },
    generateConfirmEligibilityFormDefaultError: (isHealthPlanDirect: boolean) =>
      isHealthPlanDirect ? (
        <FormattedMessage
          defaultMessage='Your name and date of birth must exactly match the information on file with your sponsor. If you’re unsure, please contact a Lyra Care Navigator and they can help determine the problem.'
          description='Tips for confirming eligibility'
        />
      ) : (
        <FormattedMessage
          defaultMessage='Your name and date of birth must exactly match the information on file with your employer. If you’re not sure what’s wrong you can continue and confirm eligibility later.'
          description='Tips for confirming eligibility'
        />
      ),
    accountLockedText: defineMessage({
      defaultMessage: 'This account may be locked, or may not exist. Please try to reset your password.',
      description: 'Text displayed when user account is locked after failed attempts',
    }),
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I’m a dependent',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
    shouldDisplayBCTProviderBenefits: true,
    legalNotice: <CoverageNotice considerMeds={considerMeds} sessionLimit={numberOfSessions} />,
    modalityQuestionnaireText: {
      modalityLabelAddendums: {
        video: defineMessage({
          defaultMessage:
            'Due to concerns about COVID-19 and increased interest in meeting by video, we’ve expanded the availability of our video providers.',
          description:
            'Shown when client indicates their preference to meet by video or in person, when searching for a provider',
        }),
      },
    },
    passwordResetFailedText: defineMessage({
      defaultMessage:
        "Please try again. Your new password cannot be one you've previously used and cannot contain parts of your email.",
      description:
        'Banner text informing the user their attempt to reset their password has failed or did not meet the requirements',
    }),
    servicesNotCovered,
    servicesCovered:
      'Lyra can help with stress, anxiety, depression, relationship issues, sleep disorders, and other common behavioral issues that can be treated through short-term outpatient behavioral health services.',
    shouldDisplayCareTeamContactInfo: true,
    smsConsent: {
      primaryConsentText: <FormattedMessage {...consentToSMSMessage} />,
    },
    locationFinder: {
      onsiteFormat: LocationMatcherDisplayFormats.RADIO_BUTTONS_ONLY,
      onsiteDescription: (
        <>
          <BodyText>{onsiteDescription}</BodyText>
          <BodyText>{onsiteAlternativeDescription}</BodyText>
        </>
      ),
    },
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
    ],
    costMessagingModalText: getDefaultCostMessagingModalText(
      numberOfSessions ?? '',
      customerDisplayName ? customerDisplayName : customerName ?? '',
      isProgramLevelSessionLimitEnabled,
      numberOfSessionsByProgram,
    ),
  }
}
