import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { NICOTINE_QUITLINE_URL, RESOURCE_COORDINATOR_URL } from '@lyrahealth-inc/shared-app-logic'
import { openUrl } from '@lyrahealth-inc/ui-core-crossplatform'

export const useOpenUrl = () => {
  const navigate = useNavigate()

  return useCallback(
    (url: string) => {
      // Empty string url === no-op
      if (url === '') {
        return
      }

      const [urlBase, _queryParams] = url.split('?')
      switch (urlBase) {
        case RESOURCE_COORDINATOR_URL:
        case NICOTINE_QUITLINE_URL:
          return navigate(url)
        default:
          return openUrl(url)
      }
    },
    [navigate],
  )
}
