/**
 * This is a legacy constant and should not be added to. All new content should have the uiSchema
 * included along side the schema in the json metadata file.
 */

// update meta data so that it would not have to rely on this
export const LegacyUIMetadata = {
  // Exercises
  checkIn: {
    uiSchema: {
      'ui:order': ['*', 'notes'],
      depression: { 'ui:widget': 'range' },
      anxiety: { 'ui:widget': 'range' },
      anger: { 'ui:widget': 'range' },
      joy: { 'ui:widget': 'range' },
      sleep: { 'ui:widget': 'range' },
      loneliness: { 'ui:widget': 'range' },
      fear: { 'ui:widget': 'range' },
      shame: { 'ui:widget': 'range' },
      guilt: { 'ui:widget': 'range' },
      contentment: { 'ui:widget': 'range' },
      painLevel: { 'ui:widget': 'range' },
      conflictsWithOther: { 'ui:widget': 'range' },
      suicideIdeation: { 'ui:widget': 'range' },
      selfHarm: { 'ui:widget': 'range' },
      alcohol: { 'ui:widget': 'range' },
      activityLevel: { 'ui:widget': 'range' },
      eating: { 'ui:widget': 'range' },
      therapySkillsUsed: { 'ui:widget': 'radio' },
      therapySkillsComeToMind: { 'ui:widget': 'range' },
      mindfulnessSkills: { 'ui:widget': 'range' },
      interpersonalEffectiveness: { 'ui:widget': 'range' },
      emotionRegulation: { 'ui:widget': 'range' },
      distressTolerance: { 'ui:widget': 'range' },
      medicationAsPrescribed: { 'ui:widget': 'radio' },
      otherSubstances: { 'ui:widget': 'radio' },
      exercise: { 'ui:widget': 'radio' },
      notes: { 'ui:widget': 'textarea' },
    },
  },
  coachingCheckIn: {
    uiSchema: {
      'ui:order': ['*', 'notes'],
      depression: { 'ui:widget': 'range' },
      anxiety: { 'ui:widget': 'range' },
      anger: { 'ui:widget': 'range' },
      joy: { 'ui:widget': 'range' },
      sleep: { 'ui:widget': 'range' },
      loneliness: { 'ui:widget': 'range' },
      fear: { 'ui:widget': 'range' },
      shame: { 'ui:widget': 'range' },
      guilt: { 'ui:widget': 'range' },
      contentment: { 'ui:widget': 'range' },
      painLevel: { 'ui:widget': 'range' },
      conflictsWithOther: { 'ui:widget': 'range' },
      suicideIdeation: { 'ui:widget': 'range' },
      alcohol: { 'ui:widget': 'range' },
      activityLevel: { 'ui:widget': 'range' },
      eating: { 'ui:widget': 'range' },
      therapySkillsUsed: { 'ui:widget': 'radio' },
      therapySkillsComeToMind: { 'ui:widget': 'range' },
      medicationAsPrescribed: { 'ui:widget': 'radio' },
      otherSubstances: { 'ui:widget': 'radio' },
      exercise: { 'ui:widget': 'radio' },
      notes: { 'ui:widget': 'textarea' },
    },
  },
  thoughtRecord: {
    uiSchema: {
      'ui:order': [
        'situation',
        'unhelpfulThought',
        'emotions',
        'evidenceUnhelpfulThought',
        'thinkingTraps',
        'altThought',
        'newEmotions',
      ],
      percentBeliefAltThought: { 'ui:widget': 'range', 'ui:options': { percentage: true } },
      situation: { items: [{ 'ui:widget': 'date' }, { 'ui:widget': 'textarea' }] },
      unhelpfulThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      thinkingTraps: { 'ui:widget': 'checkbox' },
      emotions: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }] },
      evidenceUnhelpfulThought: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
      altThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      newEmotions: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
    },
    report: {
      fieldsToDisplay: ['response.situationDescription'],
    },
    initialValues: {
      percentBeliefAltThought: 0,
      emotionsIntensity: 0,
      newEmotionsIntensity: 0,
      percentBeliefThought: 0,
    },
  },
  awarenessLog: {
    uiSchema: {
      'ui:order': ['date', 'whatDidYouNotice'],
      date: { 'ui:widget': 'date' },
      whatDidYouNotice: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'range' },
        ],
      },
    },
    initialValues: {
      judge: 0,
    },
    report: {
      fieldsToDisplay: ['judge'],
    },
  },
  emotionsInContext: {
    uiSchema: {
      'ui:order': ['date', 'situation', 'thoughts', 'emotions', 'otherEmotions', 'behaviors', 'consequences'],
      date: { 'ui:widget': 'date' },
      situation: { 'ui:widget': 'textarea' },
      thoughts: { 'ui:widget': 'textarea' },
      emotions: { 'ui:widget': 'checkbox' },
      otherEmotions: { 'ui:widget': 'textarea' },
      behaviors: { 'ui:widget': 'textarea' },
      consequences: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  activityLog: {
    uiSchema: {
      'ui:order': ['activityName', 'activityDate', 'depression', 'pleasure', 'achievement', 'notes'],
      activityDate: { 'ui:widget': 'date' },
      depression: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      pleasure: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      achievement: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      notes: { 'ui:widget': 'textarea' },
    },
    initialValues: {
      depressionBefore: 0,
      depressionAfter: 0,
      pleasureBefore: 0,
      pleasureAfter: 0,
      achievementBefore: 0,
      achievementAfter: 0,
    },
    report: {
      fieldsToDisplay: ['activityName'],
    },
  },
  values: {
    uiSchema: {
      'ui:order': ['importantValue', 'generateGoals', 'actionSteps'],
      importantValue: { 'ui:widget': 'radio', 'ui:options': { styleType: 'three-col' } },
      generateGoals: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
      actionSteps: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['importantValue'],
    },
  },
  activitySchedule: {
    uiSchema: {
      'ui:order': ['date', 'activity'],
      date: { 'ui:widget': 'date' },
    },
    report: {
      fieldsToDisplay: ['activity'],
    },
  },
  prosConsChange: {
    uiSchema: {
      'ui:order': ['changeToMake', 'prosChange', 'consChange', 'changeToNotMake', 'prosNotChange', 'consNotChange'],
      prosChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      prosNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['changeToMake', 'changeToNotMake'],
    },
  },
  wellBeing: {
    uiSchema: {
      'ui:order': [
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
      ],
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
    },
  },
  exposureLog: {
    uiSchema: {
      default: {
        'ui:order': [
          'formIntro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        formIntro: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'stressScale',
          'intro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        stressScale: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
    },
    initialValues: {
      sudsBefore: 0,
      sudsAfter: 0,
      sudsPeak: 0,
    },
  },
  therapyTargets: {
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 10,
          minLabel: '0: Not a problem',
          maxLabel: '10: A very big problem',
          step: 1,
        },
      },
    },
  },
  exposureHierarchy: {
    uiSchema: {
      'ui:order': ['stressScale', 'intro', 'date', '*'],
      stressScale: { 'ui:field': 'SUDSTable' },
      date: { 'ui:widget': 'date' },
    },
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  coachingExposureHierarchy: {
    uiSchema: {
      'ui:order': ['stressScale', 'intro', 'date', '*'],
      stressScale: { 'ui:field': 'SUDSTable' },
      date: { 'ui:widget': 'date' },
    },
    customFields: {
      fields: {
        sliderField: {
          minValue: 0,
          maxValue: 100,
          minLabel: 'No Distress',
          maxLabel: 'Extreme Distress',
          step: 5,
        },
      },
    },
  },
  structuredProblemSolving: {
    uiSchema: {
      'ui:order': [
        'step1',
        'step2',
        'step3',
        'step4',
        'solution1Pros',
        'solution1Cons',
        'solution2Pros',
        'solution2Cons',
        'solution3Pros',
        'solution3Cons',
        'solution4Pros',
        'solution4Cons',
        'solution5Pros',
        'solution5Cons',
        'step5',
        'step5a',
        'step5b',
        'step6',
      ],
      step1: { 'ui:widget': 'textarea' },
      step2: { 'ui:widget': 'textarea' },
      step3: { 'ui:widget': 'textarea' },
      solution1Pros: { 'ui:widget': 'textarea' },
      solution1Cons: { 'ui:widget': 'textarea' },
      solution2Pros: { 'ui:widget': 'textarea' },
      solution2Cons: { 'ui:widget': 'textarea' },
      solution3Pros: { 'ui:widget': 'textarea' },
      solution3Cons: { 'ui:widget': 'textarea' },
      solution4Pros: { 'ui:widget': 'textarea' },
      solution4Cons: { 'ui:widget': 'textarea' },
      solution5Pros: { 'ui:widget': 'textarea' },
      solution5Cons: { 'ui:widget': 'textarea' },
      step5a: { 'ui:widget': 'textarea' },
      step5b: { 'ui:widget': 'textarea' },
      step6: { 'ui:widget': 'radio' },
    },
  },
  coachingPhqGad: {
    uiSchema: {
      'ui:order': [
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
      ],
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
    },
  },
  coachingActivityLog: {
    uiSchema: {
      'ui:order': ['activityName', 'activityDate', 'depression', 'pleasure', 'achievement', 'notes'],
      activityDate: { 'ui:widget': 'date' },
      depression: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      pleasure: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      achievement: { items: [{ 'ui:widget': 'range' }, { 'ui:widget': 'range' }] },
      notes: { 'ui:widget': 'textarea' },
    },
    initialValues: {
      depressionBefore: 0,
      depressionAfter: 0,
      pleasureBefore: 0,
      pleasureAfter: 0,
      achievementBefore: 0,
      achievementAfter: 0,
    },
    report: {
      fieldsToDisplay: ['activityName'],
    },
  },
  coachingActivitySchedule: {
    uiSchema: {
      'ui:order': ['date', 'activity'],
      date: { 'ui:widget': 'date' },
    },
    report: {
      fieldsToDisplay: ['activity'],
    },
  },
  coachingAssertiveCommunication: {
    uiSchema: {
      'ui:order': [
        'questionsLabel',
        'whatDidYouAskFor',
        'situation',
        'howYouFeel',
        'askForWhatYouWant',
        'reinforceYourPosition',
        'beMindful',
        'appearConfident',
        'beWillingToNegotitate',
      ],
      whatDidYouAskFor: { 'ui:widget': 'textarea' },
      situation: { 'ui:widget': 'textarea' },
      howYouFeel: { 'ui:widget': 'textarea' },
      askForWhatYouWant: { 'ui:widget': 'textarea' },
      reinforceYourPosition: { 'ui:widget': 'textarea' },
      beMindful: { 'ui:widget': 'textarea' },
      appearConfident: { 'ui:widget': 'textarea' },
      beWillingToNegotitate: { 'ui:widget': 'textarea' },
    },
  },
  coachingAwarenessLog: {
    uiSchema: {
      'ui:order': ['date', 'whatDidYouNotice'],
      date: { 'ui:widget': 'date' },
      whatDidYouNotice: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'range' },
        ],
      },
    },
    initialValues: {
      judge: 0,
    },
    report: {
      fieldsToDisplay: ['judge'],
    },
  },
  coachingEmotionsInContext: {
    uiSchema: {
      'ui:order': ['date', 'situation', 'thoughts', 'emotions', 'otherEmotions', 'behaviors', 'consequences'],
      date: { 'ui:widget': 'date' },
      situation: { 'ui:widget': 'textarea' },
      thoughts: { 'ui:widget': 'textarea' },
      emotions: { 'ui:widget': 'checkbox' },
      otherEmotions: { 'ui:widget': 'textarea' },
      behaviors: { 'ui:widget': 'textarea' },
      consequences: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  coachingExposureLog: {
    uiSchema: {
      default: {
        'ui:order': [
          'formIntro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        formIntro: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'stressScale',
          'intro',
          'exposureTask',
          'exposureDate',
          'situation',
          'sudsBefore',
          'sudsAfter',
          'sudsPeak',
          'takeaway',
        ],
        stressScale: { 'ui:field': 'SUDSTable' },
        exposureTask: { 'ui:widget': 'textarea' },
        exposureDate: { 'ui:widget': 'date' },
        situation: { 'ui:widget': 'textarea' },
        sudsBefore: { 'ui:widget': 'range' },
        sudsAfter: { 'ui:widget': 'range' },
        sudsPeak: { 'ui:widget': 'range' },
        takeaway: { 'ui:widget': 'textarea' },
      },
    },
    initialValues: {
      sudsBefore: 0,
      sudsAfter: 0,
      sudsPeak: 0,
    },
  },
  coachingProsConsChange: {
    uiSchema: {
      'ui:order': ['changeToMake', 'prosChange', 'consChange', 'changeToNotMake', 'prosNotChange', 'consNotChange'],
      prosChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      prosNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
      consNotChange: {
        items: [
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
          {},
          { 'ui:widget': 'radio', 'ui:options': { styleType: 'side-by-side' } },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['changeToMake', 'changeToNotMake'],
    },
  },
  coachingStructuredProblemSolving: {
    uiSchema: {
      'ui:order': [
        'step1',
        'step2',
        'step3',
        'step4',
        'solution1Pros',
        'solution1Cons',
        'solution2Pros',
        'solution2Cons',
        'solution3Pros',
        'solution3Cons',
        'solution4Pros',
        'solution4Cons',
        'solution5Pros',
        'solution5Cons',
        'step5',
        'step5a',
        'step5b',
        'step6',
        '*',
      ],
      step1: { 'ui:widget': 'textarea' },
      step2: { 'ui:widget': 'textarea' },
      step3: { 'ui:widget': 'textarea' },
      solution1Pros: { 'ui:widget': 'textarea' },
      solution1Cons: { 'ui:widget': 'textarea' },
      solution2Pros: { 'ui:widget': 'textarea' },
      solution2Cons: { 'ui:widget': 'textarea' },
      solution3Pros: { 'ui:widget': 'textarea' },
      solution3Cons: { 'ui:widget': 'textarea' },
      solution4Pros: { 'ui:widget': 'textarea' },
      solution4Cons: { 'ui:widget': 'textarea' },
      solution5Pros: { 'ui:widget': 'textarea' },
      solution5Cons: { 'ui:widget': 'textarea' },
      step5a: { 'ui:widget': 'textarea' },
      step5b: { 'ui:widget': 'textarea' },
      step6: { 'ui:widget': 'radio' },
    },
  },
  coachingThoughtRecord: {
    uiSchema: {
      'ui:order': [
        'situation',
        'unhelpfulThought',
        'emotions',
        'evidenceUnhelpfulThought',
        'thinkingTraps',
        'altThought',
        'newEmotions',
      ],
      percentBeliefAltThought: { 'ui:widget': 'range', 'ui:options': { percentage: true } },
      situation: { items: [{ 'ui:widget': 'date' }, { 'ui:widget': 'textarea' }] },
      unhelpfulThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      thinkingTraps: { 'ui:widget': 'checkbox' },
      emotions: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }] },
      evidenceUnhelpfulThought: { items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
      altThought: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
      newEmotions: {
        items: [{ 'ui:widget': 'textarea' }, { 'ui:widget': 'range', 'ui:options': { percentage: true } }],
      },
    },
    report: {
      fieldsToDisplay: ['response.situationDescription'],
    },
    initialValues: {
      percentBeliefAltThought: 0,
      emotionsIntensity: 0,
      newEmotionsIntensity: 0,
      percentBeliefThought: 0,
    },
  },
  coachingValidation: {
    uiSchema: {
      'ui:order': [
        'situation',
        'checkBoxesLabel',
        'payAttention',
        'payAttentionDescription',
        'refectBack',
        'reflectBackDescription',
        'understand',
        'understandDescription',
        'validate',
        'validateDescription',
        'beGenuine',
        'beGenuineDescription',
      ],
      situation: { 'ui:widget': 'textarea' },
      payAttention: { 'ui:widget': 'checkbox' },
      payAttentionDescription: { 'ui:widget': 'textarea' },
      refectBack: { 'ui:widget': 'checkbox' },
      reflectBackDescription: { 'ui:widget': 'textarea' },
      understand: { 'ui:widget': 'checkbox' },
      understandDescription: { 'ui:widget': 'textarea' },
      validate: { 'ui:widget': 'checkbox' },
      validateDescription: { 'ui:widget': 'textarea' },
      beGenuine: { 'ui:widget': 'checkbox' },
      beGenuineDescription: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  coachingValues: {
    uiSchema: {
      'ui:order': ['importantValue', 'generateGoals', 'actionSteps'],
      importantValue: { 'ui:widget': 'radio', 'ui:options': { styleType: 'three-col' } },
      generateGoals: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
      actionSteps: {
        items: [
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'textarea' },
          { 'ui:widget': 'select' },
        ],
      },
    },
    report: {
      fieldsToDisplay: ['importantValue'],
    },
  },

  // Lessons
  understandingEmotionsLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        question6: { 'ui:widget': 'radio' },
        question7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'question1',
          'question2',
          'question3',
          'question4',
          'question5',
          'question6',
          'question7',
          'feedback1',
          'feedback2',
        ],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        question6: { 'ui:widget': 'radio' },
        question7: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  introToCBTLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  valuesLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'question4', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  mindfulAwarenessLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  thinkingTrapsLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  challengingAvoidanceLessonReview: {
    uiSchema: {
      default: {
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        question4: { 'ui:widget': 'radio' },
        question5: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  relationshipSkillsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  improvingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingAnxietyLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  understandingDepressionLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  selfCompassionReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  communicationStylesLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingEmotionsLessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'question6',
        'question7',
        'feedback1',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      question6: { 'ui:widget': 'radio' },
      question7: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingIntroToCbcLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingValuesLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingMindfulAwarenessLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingThinkingTrapsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingChallengingAvoidanceLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingRelationshipSkillsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'question4', 'question5', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  stressManagementLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingSelfCompassionReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingAnxietyLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  coachingUnderstandingSleepLessonReview: {
    uiSchema: {
      default: {
        'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
        question1: { 'ui:widget': 'radio' },
        question2: { 'ui:widget': 'radio' },
        question3: { 'ui:widget': 'radio' },
        feedback1: { 'ui:widget': 'radio' },
        feedback2: { 'ui:widget': 'textarea' },
      },
    },
  },
  socialAnxietyHierarchyLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  medsSocialAnxietyHierarchyLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  socialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  medsSocialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  coachingSocialAnxietyExposuresLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  copingCovid19LessonReview: {
    uiSchema: {
      'ui:order': [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'question6',
        'feedback1',
        'feedback2',
      ],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      question4: { 'ui:widget': 'radio' },
      question5: { 'ui:widget': 'radio' },
      question6: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  decisionsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },
  socialAnxietyThinkingTrapsLessonReview: {
    uiSchema: {
      'ui:order': ['question1', 'question2', 'question3', 'feedback1', 'feedback2'],
      question1: { 'ui:widget': 'radio' },
      question2: { 'ui:widget': 'radio' },
      question3: { 'ui:widget': 'radio' },
      feedback1: { 'ui:widget': 'radio' },
      feedback2: { 'ui:widget': 'textarea' },
    },
  },

  // Assessments
  initialAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  mildOngoingAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
    },
  },
  ongoingAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
      },
    },
  },
  // DEPRECATED: The ui metadata for the finalAssessmentMetadata now lives in finalAssessmentMetadata.json
  finalAssessmentMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
      },
      v2: {
        'ui:order': [
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
      },
      v3: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'allianceLabel',
          'alliance_1',
          'alliance_2',
          'alliance_3',
          'satisfactionLabel',
          'satisfaction_1',
          'satisfaction_2',
          'satisfaction_3',
          'satisfaction_4',
          'satisfaction_5',
          'satisfaction_6',
          'satisfaction_7',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        alliance_1: { 'ui:widget': 'radio' },
        alliance_2: { 'ui:widget': 'radio' },
        alliance_3: { 'ui:widget': 'radio' },
        satisfaction_1: { 'ui:widget': 'radio' },
        satisfaction_2: { 'ui:widget': 'radio' },
        satisfaction_3: { 'ui:widget': 'radio' },
        satisfaction_4: { 'ui:widget': 'radio' },
        satisfaction_5: { 'ui:widget': 'radio' },
        satisfaction_6: { 'ui:widget': 'radio' },
        satisfaction_7: { 'ui:widget': 'textarea' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  // DEPRECATED: The ui metadata for the mildFinalAssessmentMetadata now lives in mildFinalAssessmentMetadata.json
  mildFinalAssessmentMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
        'allianceLabel',
        'alliance_1',
        'alliance_2',
        'alliance_3',
        'satisfaction_1',
        'satisfactionLabel',
        'satisfaction_2',
        'satisfaction_3',
        'satisfaction_4',
        'satisfaction_5',
        'satisfaction_6',
        'satisfaction_7',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
      alliance_1: { 'ui:widget': 'radio' },
      alliance_2: { 'ui:widget': 'radio' },
      alliance_3: { 'ui:widget': 'radio' },
      satisfaction_1: { 'ui:widget': 'radio' },
      satisfaction_2: { 'ui:widget': 'radio' },
      satisfaction_3: { 'ui:widget': 'radio' },
      satisfaction_4: { 'ui:widget': 'radio' },
      satisfaction_5: { 'ui:widget': 'radio' },
      satisfaction_6: { 'ui:widget': 'radio' },
    },
  },
  // DEPRECATED: The ui metadata for the clientIntake now lives in clientIntake.json
  clientIntake: {
    uiSchema: {
      default: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'legalProceedings',
          'treatmentGoals',
          'wellBeing',
          'informedConsent',
        ],
        personalInfo: {
          items: [
            {},
            {},
            {},
            {},
            { 'ui:widget': 'radio' },
            {},
            {},
            {},
            {},
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        wellBeing: { items: { 'ui:widget': 'radio' } },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
          ],
        },
        legalProceedings: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }] },
        treatmentGoals: { items: [{ 'ui:widget': 'textarea' }] },
      },
      v2: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'otherQuestions',
          'wellBeing',
          'informedConsent',
        ],
        emergencyContact: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '111-111-1111' } },
          ],
        },
        personalInfo: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'date' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        wellBeing: { items: { 'ui:widget': 'radio' } },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
          ],
        },
        otherQuestions: {
          items: [
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
          ],
        },
      },
      v3: {
        'ui:order': [
          'emergencyInfo',
          'emergencyContact',
          'personalInfo',
          'familyHistory',
          'educationHistory',
          'currentProblems',
          'otherQuestions',
          'informedConsent',
        ],
        emergencyContact: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '111-111-1111' } },
          ],
        },
        personalInfo: {
          items: [
            { 'ui:widget': 'text' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'date' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
          ],
        },
        ethnicity: { 'ui:widget': 'checkbox' },
        informedConsent: { 'ui:field': 'InformedConsent' },
        familyHistory: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
          ],
        },
        educationHistory: {
          items: [
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
          ],
        },
        emergencyInfo: { items: [{ 'ui:widget': 'radio' }, { 'ui:widget': 'textarea' }, { 'ui:widget': 'textarea' }] },
        currentProblems: {
          items: [
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'text' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'mask', 'ui:options': { mask: '1111-11' } },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'radio' },
          ],
        },
        otherQuestions: {
          items: [
            { 'ui:widget': 'radio' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'select' },
            { 'ui:widget': 'text' },
          ],
        },
      },
    },
  },
  assertiveCommunication: {
    uiSchema: {
      'ui:order': [
        'questionsLabel',
        'whatDidYouAskFor',
        'situation',
        'howYouFeel',
        'askForWhatYouWant',
        'reinforceYourPosition',
        'beMindful',
        'appearConfident',
        'beWillingToNegotitate',
      ],
      whatDidYouAskFor: { 'ui:widget': 'textarea' },
      situation: { 'ui:widget': 'textarea' },
      howYouFeel: { 'ui:widget': 'textarea' },
      askForWhatYouWant: { 'ui:widget': 'textarea' },
      reinforceYourPosition: { 'ui:widget': 'textarea' },
      beMindful: { 'ui:widget': 'textarea' },
      appearConfident: { 'ui:widget': 'textarea' },
      beWillingToNegotitate: { 'ui:widget': 'textarea' },
    },
  },
  validation: {
    uiSchema: {
      'ui:order': [
        'situation',
        'checkBoxesLabel',
        'payAttention',
        'payAttentionDescription',
        'refectBack',
        'reflectBackDescription',
        'understand',
        'understandDescription',
        'validate',
        'validateDescription',
        'beGenuine',
        'beGenuineDescription',
      ],
      situation: { 'ui:widget': 'textarea' },
      payAttention: { 'ui:widget': 'checkbox' },
      payAttentionDescription: { 'ui:widget': 'textarea' },
      refectBack: { 'ui:widget': 'checkbox' },
      reflectBackDescription: { 'ui:widget': 'textarea' },
      understand: { 'ui:widget': 'checkbox' },
      understandDescription: { 'ui:widget': 'textarea' },
      validate: { 'ui:widget': 'checkbox' },
      validateDescription: { 'ui:widget': 'textarea' },
      beGenuine: { 'ui:widget': 'checkbox' },
      beGenuineDescription: { 'ui:widget': 'textarea' },
    },
    report: {
      fieldsToDisplay: ['situation'],
    },
  },
  medsClientIntake: {
    uiSchema: {
      'ui:order': [
        'personalInformation_2_1',
        'your_name_2_2',
        'fullLegalName_3',
        'firstName_4',
        'middleName_5',
        'name_6',
        'preferredName_15',
        'preferredPronoun',
        'pronounOther',
        'contactInformationReach_7',
        'contactEmergency_7_1',
        'homeAddressPharmacy_9',
        'homeAddressStreet',
        'homeAddressCity',
        'homeAddressState',
        'homeAddressZipCode',
        'locationForSessions_9_1',
        'usualLocationDuring_10',
        'otherAddressStreet',
        'otherAddressCity',
        'otherAddressState',
        'otherAddressZipCode',
        'primaryPhone',
        'leaveVoicemailsPersonal_8',
        'emergencyContact',
        'emergencyContactFirst_11',
        'emergencyContactLast_11_1',
        'emergencyContactRelationship_12',
        'emergencyContactPhone_13',
        'currentProblemsTreatment_40',
        'problemBroughtSee_41',
        'returnToCare_96',
        'problemsStart_42',
        'goalsWorkingPrescriber_48',
        'checkSymptomsExperienced_81',
        'psychiatricHistory',
        'currentlySeeingMental_49',
        'currentlySeeingMental_50',
        'previouslyReceivedMental_51',
        'previouslyReceivedMental_52',
        'previousPsychiatricDiagnoses_45',
        'previousPsychiatricDiagnoses_46',
        'previousPsychiatricDiagnoses_47',
        'currentlyTakingPrescription_60',
        'takingMedicationsCurrently_61',
        'otherPsychiatricMedicationTaken',
        'currentlyTakingPrescription_62',
        'hereRefillToday_55',
        'hereRefillToday_56',
        'hereRefillToday_58',
        'hereRefillToday_57',
        'pastPsychiatricMedications',
        'psychiatricMedicationsTaken_53',
        'otherMedicationTaken',
        'hospitalizedInpatientPartial_78',
        'hospitalizedInpatientPartial_79',
        'sideEffectsAffected_54',
        'attemptedSuicide_72',
        'attemptedSuicide_73',
        'purposelySelfInjured_74',
        'purposelySelfInjured_75',
        'assaultedCommittedAct_76',
        'assaultedCommittedAct_77',
        'accessGun_80',
        'medicalHistorySection',
        'medicationAllergiesAll_63',
        'chronicOngoingMedical_64',
        'otherChronicMedicalConditions',
        'pregnantPlanningBecome_66',
        'havePainToday',
        'levelPainToday_67',
        'levelPainToday_68',
        'ratePhysicalHealth_71',
        'behaviorsSection',
        'fallAsleepAverage_83',
        'wakeUpAverage_84',
        'manyCaffeinatedBeverages_82',
        'oftenDrinkContaining_85',
        'oftenDrinkContaining_86',
        'oftenDrinkContaining_87',
        'oftenUseTobacco_88',
        'oftenUsePrescription_89',
        'oftenUseIllegal_90',
        'oftenUseCannabis_91',
        'oftenElectronicCigarette_92',
        'familySocialHistory_27',
        'raceEthnicityCheck_16',
        'raceEthnicityOther_17',
        'considerSpiritualReligious_18',
        'faithBelief_19',
        'household',
        'relationshipStatus_20',
        'numberPersonsLiving_26',
        'childrenGenderAge_25',
        'familySocialHistory_27_1',
        'agesMotherFather_28',
        'anyoneParticularSupport_30',
        'historyPsychiatricEmotional_31',
        'historyPsychiatricEmotional_32',
        'educationEmploymentHistory_34',
        'highestLevelEducation_35',
        'workingNow_36',
        'workingNow_37',
        'workingNow_38',
        'workingNow_39',
        'financialAndLegal',
        'rateFinancialStatus_33',
        'currentlyInvolvedLegal_94',
        'currentlyInvolvedLegal_95',
        'cancellationPolicyLabel',
        'cancellationPolicy',
        'informedConsent_98',
      ],
      firstName_4: {
        'ui:widget': 'text',
      },
      homeAddressStreet: {
        'ui:widget': 'text',
      },
      homeAddressCity: {
        'ui:widget': 'text',
      },
      homeAddressState: {
        'ui:widget': 'select',
      },
      homeAddressZipCode: {
        'ui:widget': 'mask',
        'ui:options': { mask: '11111' },
      },
      middleName_5: {
        'ui:widget': 'text',
      },
      name_6: {
        'ui:widget': 'text',
      },
      leaveVoicemailsPersonal_8: {
        'ui:widget': 'radio',
      },
      locationForSessions_9_1: {
        'ui:widget': 'radio',
      },
      otherAddressStreet: {
        'ui:widget': 'text',
      },
      otherAddressCity: {
        'ui:widget': 'text',
      },
      otherAddressState: {
        'ui:widget': 'select',
      },
      otherAddressZipCode: {
        'ui:widget': 'mask',
        'ui:options': { mask: '11111' },
      },
      primaryPhone: {
        'ui:widget': 'mask',
        'ui:options': { mask: '111-111-1111' },
      },
      emergencyContactFirst_11: {
        'ui:widget': 'text',
      },
      emergencyContactLast_11_1: {
        'ui:widget': 'text',
      },
      emergencyContactRelationship_12: {
        'ui:widget': 'text',
      },
      emergencyContactPhone_13: {
        'ui:widget': 'mask',
        'ui:options': { mask: '111-111-1111' },
      },
      preferredName_15: {
        'ui:widget': 'text',
      },
      preferredPronoun: {
        'ui:widget': 'checkbox',
      },
      pronounOther: {
        'ui:widget': 'text',
      },
      raceEthnicityCheck_16: {
        'ui:widget': 'checkbox',
      },
      raceEthnicityOther_17: {
        'ui:widget': 'text',
      },
      considerSpiritualReligious_18: {
        'ui:widget': 'radio',
      },
      faithBelief_19: {
        'ui:widget': 'text',
      },
      relationshipStatus_20: {
        'ui:widget': 'select',
      },
      childrenGenderAge_25: {
        'ui:widget': 'textarea',
      },
      numberPersonsLiving_26: {
        'ui:widget': 'textarea',
      },
      agesMotherFather_28: {
        'ui:widget': 'textarea',
      },
      anyoneParticularSupport_30: {
        'ui:widget': 'textarea',
      },
      historyPsychiatricEmotional_31: {
        'ui:widget': 'radio',
      },
      historyPsychiatricEmotional_32: {
        'ui:widget': 'textarea',
      },
      rateFinancialStatus_33: {
        'ui:widget': 'radio',
      },
      highestLevelEducation_35: {
        'ui:widget': 'select',
      },
      workingNow_36: {
        'ui:widget': 'radio',
      },
      workingNow_37: {
        'ui:widget': 'textarea',
      },
      workingNow_38: {
        'ui:widget': 'radio',
      },
      workingNow_39: {
        'ui:widget': 'textarea',
      },
      problemBroughtSee_41: {
        'ui:widget': 'textarea',
      },
      returnToCare_96: {
        'ui:widget': 'select',
      },
      problemsStart_42: {
        'ui:widget': 'text',
      },
      previousPsychiatricDiagnoses_45: {
        'ui:widget': 'radio',
      },
      previousPsychiatricDiagnoses_46: {
        'ui:widget': 'checkbox',
      },
      previousPsychiatricDiagnoses_47: {
        'ui:widget': 'textarea',
      },
      goalsWorkingPrescriber_48: {
        'ui:widget': 'textarea',
      },
      currentlySeeingMental_49: {
        'ui:widget': 'radio',
      },
      currentlySeeingMental_50: {
        'ui:widget': 'textarea',
      },
      previouslyReceivedMental_51: {
        'ui:widget': 'radio',
      },
      previouslyReceivedMental_52: {
        'ui:widget': 'textarea',
      },
      pastPsychiatricMedications: {
        'ui:widget': 'radio',
      },
      psychiatricMedicationsTaken_53: {
        'ui:widget': 'checkbox',
      },
      otherMedicationTaken: {
        'ui:widget': 'text',
      },
      sideEffectsAffected_54: {
        'ui:widget': 'checkbox',
      },
      hereRefillToday_55: {
        'ui:widget': 'radio',
      },
      hereRefillToday_56: {
        'ui:widget': 'textarea',
      },
      hereRefillToday_57: {
        'ui:widget': 'text',
      },
      hereRefillToday_58: {
        'ui:widget': 'radio',
      },
      currentlyTakingPrescription_60: {
        'ui:widget': 'radio',
      },
      takingMedicationsCurrently_61: {
        'ui:widget': 'checkbox',
      },
      otherPsychiatricMedicationTaken: {
        'ui:widget': 'text',
      },
      currentlyTakingPrescription_62: {
        'ui:widget': 'textarea',
      },
      medicationAllergiesAll_63: {
        'ui:widget': 'textarea',
      },
      chronicOngoingMedical_64: {
        'ui:widget': 'checkbox',
      },
      otherChronicMedicalConditions: {
        'ui:widget': 'text',
      },
      pregnantPlanningBecome_66: {
        'ui:widget': 'radio',
      },
      havePainToday: {
        'ui:widget': 'radio',
      },
      levelPainToday_67: {
        'ui:widget': 'range',
      },
      levelPainToday_68: {
        'ui:widget': 'text',
      },
      ratePhysicalHealth_71: {
        'ui:widget': 'select',
      },
      attemptedSuicide_72: {
        'ui:widget': 'radio',
      },
      attemptedSuicide_73: {
        'ui:widget': 'text',
      },
      purposelySelfInjured_74: {
        'ui:widget': 'radio',
      },
      purposelySelfInjured_75: {
        'ui:widget': 'text',
      },
      assaultedCommittedAct_76: {
        'ui:widget': 'radio',
      },
      assaultedCommittedAct_77: {
        'ui:widget': 'text',
      },
      hospitalizedInpatientPartial_78: {
        'ui:widget': 'radio',
      },
      hospitalizedInpatientPartial_79: {
        'ui:widget': 'text',
      },
      accessGun_80: {
        'ui:widget': 'radio',
      },
      checkSymptomsExperienced_81: {
        'ui:widget': 'checkbox',
      },
      manyCaffeinatedBeverages_82: {
        'ui:widget': 'text',
      },
      fallAsleepAverage_83: {
        'ui:widget': 'text',
      },
      wakeUpAverage_84: {
        'ui:widget': 'text',
      },
      oftenDrinkContaining_85: {
        'ui:widget': 'select',
      },
      oftenDrinkContaining_86: {
        'ui:widget': 'radio',
      },
      oftenDrinkContaining_87: {
        'ui:widget': 'radio',
      },
      oftenUseTobacco_88: {
        'ui:widget': 'select',
      },
      oftenUsePrescription_89: {
        'ui:widget': 'select',
      },
      oftenUseIllegal_90: {
        'ui:widget': 'select',
      },
      oftenUseCannabis_91: {
        'ui:widget': 'select',
      },
      oftenElectronicCigarette_92: {
        'ui:widget': 'select',
      },
      currentlyInvolvedLegal_94: {
        'ui:widget': 'radio',
      },
      currentlyInvolvedLegal_95: {
        'ui:widget': 'textarea',
      },
      informedConsent_98: {
        'ui:field': 'MedsInformedConsent',
      },
    },
    initialValues: {
      levelPainToday_67: 1,
    },
  },
  medsBaselineExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsBaselineMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'swls_label',
          'swls_1',
          'swls_2',
          'swls_3',
          'swls_4',
          'swls_5',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        swls_1: { 'ui:widget': 'radio' },
        swls_2: { 'ui:widget': 'radio' },
        swls_3: { 'ui:widget': 'radio' },
        swls_4: { 'ui:widget': 'radio' },
        swls_5: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsPreSessionBamMetadata: {
    uiSchema: {
      'ui:order': [
        'standardSetQuestions_1',
        'past30Days_2',
        'past30Days_3',
        'past30Days_4',
        'past30Days_5',
        'past30Days_6',
        'past30Days_7',
        'past30Days_8',
        'marijuanaCannabisPot_9',
        'sedativesTranquilizersBenzos_10',
        'cocaineCrack_11',
        'otherStimulantsAmphetamine_12',
        'opiatesHeroinMorphine_13',
        'inhalantsGluesAdhesives_14',
        'otherDrugsSteroids_15',
        'past30Days_16',
        'confidentNotUse_17',
        'past30Days_18',
        'past30Days_19',
        'doesReligionSpirituality_20',
        'past30Days_21',
        'enoughIncomeFrom_22',
        'past30Days_23',
        'past30Days_24',
        'satisfiedProgressToward_25',
      ],
      past30Days_2: { 'ui:widget': 'radio' },
      past30Days_3: { 'ui:widget': 'radio' },
      past30Days_4: { 'ui:widget': 'radio' },
      past30Days_5: { 'ui:widget': 'radio' },
      past30Days_6: { 'ui:widget': 'radio' },
      past30Days_7: { 'ui:widget': 'radio' },
      marijuanaCannabisPot_9: { 'ui:widget': 'radio' },
      sedativesTranquilizersBenzos_10: { 'ui:widget': 'radio' },
      cocaineCrack_11: { 'ui:widget': 'radio' },
      otherStimulantsAmphetamine_12: { 'ui:widget': 'radio' },
      opiatesHeroinMorphine_13: { 'ui:widget': 'radio' },
      inhalantsGluesAdhesives_14: { 'ui:widget': 'radio' },
      otherDrugsSteroids_15: { 'ui:widget': 'radio' },
      past30Days_16: { 'ui:widget': 'radio' },
      confidentNotUse_17: { 'ui:widget': 'radio' },
      past30Days_18: { 'ui:widget': 'radio' },
      past30Days_19: { 'ui:widget': 'radio' },
      doesReligionSpirituality_20: { 'ui:widget': 'radio' },
      past30Days_21: { 'ui:widget': 'radio' },
      enoughIncomeFrom_22: { 'ui:widget': 'radio' },
      past30Days_23: { 'ui:widget': 'radio' },
      past30Days_24: { 'ui:widget': 'radio' },
      satisfiedProgressToward_25: { 'ui:widget': 'radio' },
    },
  },
  medsPreSessionEddsMetadata: {
    uiSchema: {
      'ui:order': [
        'overPast3_1',
        'feltFat_2',
        'definiteFearMight_3',
        'hasWeightInfluenced_4',
        'hasShapeInfluenced_5',
        'duringPast6_6',
        'duringTimesAte_7',
        'manyDaysPer_8',
        'manyTimesPer_9',
        'duringEpisodesOvereating_10',
        'eatMuchRapidly_11',
        'eatUntilFelt_12',
        'eatLargeAmounts_13',
        'eatAloneBecause_14',
        'feelDisgustedDepressed_15',
        'feelVeryUpset_16',
        'manyTimesPer_17',
        'manyTimesPer_18',
        'manyTimesPer_19',
        'manyTimesPer_20',
        'muchWeighLbs_21',
        'tallSpecifyInches_22',
        'overPast3_23',
        'takingBirthControl_24',
      ],
      feltFat_2: { 'ui:widget': 'range' },
      definiteFearMight_3: { 'ui:widget': 'range' },
      hasWeightInfluenced_4: { 'ui:widget': 'range' },
      hasShapeInfluenced_5: { 'ui:widget': 'range' },
      duringPast6_6: { 'ui:widget': 'radio' },
      duringTimesAte_7: { 'ui:widget': 'radio' },
      eatMuchRapidly_11: { 'ui:widget': 'radio' },
      eatUntilFelt_12: { 'ui:widget': 'radio' },
      eatLargeAmounts_13: { 'ui:widget': 'radio' },
      eatAloneBecause_14: { 'ui:widget': 'radio' },
      feelDisgustedDepressed_15: { 'ui:widget': 'radio' },
      feelVeryUpset_16: { 'ui:widget': 'radio' },
      manyDaysPer_8: { 'ui:widget': 'range' },
      manyTimesPer_9: { 'ui:widget': 'range' },
      manyTimesPer_17: { 'ui:widget': 'range' },
      manyTimesPer_18: { 'ui:widget': 'range' },
      manyTimesPer_19: { 'ui:widget': 'range' },
      manyTimesPer_20: { 'ui:widget': 'range' },
      overPast3_23: { 'ui:widget': 'radio' },
      takingBirthControl_24: { 'ui:widget': 'radio' },
    },
    initialValues: {
      definiteFearMight_3: 0,
      hasWeightInfluenced_4: 0,
      feltFat_2: 0,
      hasShapeInfluenced_5: 0,
      manyDaysPer_8: 0,
      manyTimesPer_9: 0,
      manyTimesPer_17: 0,
      manyTimesPer_18: 0,
      manyTimesPer_19: 0,
      manyTimesPer_20: 0,
    },
  },
  medsPreSessionRos1Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos2Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos3Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos4Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos5Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos6Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos7Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos8Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos9Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsPreSessionRos10Metadata: {
    uiSchema: {
      'ui:order': ['twoWeeksExperienced_1'],
    },
  },
  medsOngoingAltmanMetadata: {
    uiSchema: {
      'ui:order': ['altman_label1', 'altman_1', 'altman_2', 'altman_3', 'altman_4', 'altman_5'],
      altman_1: { 'ui:widget': 'radio' },
      altman_2: { 'ui:widget': 'radio' },
      altman_3: { 'ui:widget': 'radio' },
      altman_4: { 'ui:widget': 'radio' },
      altman_5: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingBOCSMetadata: {
    uiSchema: {
      'ui:order': [
        'bocs_label1',
        'bocs_1',
        'bocs_2',
        'bocs_3',
        'bocs_4',
        'bocs_5',
        'bocs_label2',
        'bocs_6',
        'bocs_7',
        'bocs_8',
        'bocs_9',
        'bocs_10',
      ],
      bocs_1: { 'ui:widget': 'radio' },
      bocs_2: { 'ui:widget': 'radio' },
      bocs_3: { 'ui:widget': 'radio' },
      bocs_4: { 'ui:widget': 'radio' },
      bocs_5: { 'ui:widget': 'radio' },
      bocs_6: { 'ui:widget': 'radio' },
      bocs_7: { 'ui:widget': 'radio' },
      bocs_8: { 'ui:widget': 'radio' },
      bocs_9: { 'ui:widget': 'radio' },
      bocs_10: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingExtendedMetadata: {
    uiSchema: {
      'ui:order': [
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'swls_label',
        'swls_1',
        'swls_2',
        'swls_3',
        'swls_4',
        'swls_5',
        'adherenceLabel',
        'adherence',
        'fibser_label',
        'fibser_1',
        'fibser_2',
        'fibser_3',
        'asec_1',
      ],
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      swls_1: { 'ui:widget': 'radio' },
      swls_2: { 'ui:widget': 'radio' },
      swls_3: { 'ui:widget': 'radio' },
      swls_4: { 'ui:widget': 'radio' },
      swls_5: { 'ui:widget': 'radio' },
      adherence: { 'ui:widget': 'radio' },
      fibser_1: { 'ui:widget': 'radio' },
      fibser_2: { 'ui:widget': 'radio' },
      fibser_3: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingMetadata: {
    uiSchema: {
      'ui:order': [
        'swls_label',
        'swls_1',
        'swls_2',
        'swls_3',
        'swls_4',
        'swls_5',
        'adherenceLabel',
        'adherence',
        'fibser_label',
        'fibser_1',
        'fibser_2',
        'fibser_3',
        'asec_1',
      ],
      swls_1: { 'ui:widget': 'radio' },
      swls_2: { 'ui:widget': 'radio' },
      swls_3: { 'ui:widget': 'radio' },
      swls_4: { 'ui:widget': 'radio' },
      swls_5: { 'ui:widget': 'radio' },
      adherence: { 'ui:widget': 'radio' },
      fibser_1: { 'ui:widget': 'radio' },
      fibser_2: { 'ui:widget': 'radio' },
      fibser_3: { 'ui:widget': 'radio' },
    },
  },
  medsOngoingPCL5Metadata: {
    uiSchema: {
      'ui:order': [
        'pcl_label',
        'pcl5_1',
        'pcl5_2',
        'pcl5_3',
        'pcl5_4',
        'pcl5_5',
        'pcl5_6',
        'pcl5_7',
        'pcl5_8',
        'pcl5_9',
        'pcl5_10',
        'pcl5_11',
        'pcl5_12',
        'pcl5_13',
        'pcl5_14',
        'pcl5_15',
        'pcl5_16',
        'pcl5_17',
        'pcl5_18',
        'pcl5_19',
        'pcl5_20',
      ],
      pcl5_1: { 'ui:widget': 'radio' },
      pcl5_2: { 'ui:widget': 'radio' },
      pcl5_3: { 'ui:widget': 'radio' },
      pcl5_4: { 'ui:widget': 'radio' },
      pcl5_5: { 'ui:widget': 'radio' },
      pcl5_6: { 'ui:widget': 'radio' },
      pcl5_7: { 'ui:widget': 'radio' },
      pcl5_8: { 'ui:widget': 'radio' },
      pcl5_9: { 'ui:widget': 'radio' },
      pcl5_10: { 'ui:widget': 'radio' },
      pcl5_11: { 'ui:widget': 'radio' },
      pcl5_12: { 'ui:widget': 'radio' },
      pcl5_13: { 'ui:widget': 'radio' },
      pcl5_14: { 'ui:widget': 'radio' },
      pcl5_15: { 'ui:widget': 'radio' },
      pcl5_16: { 'ui:widget': 'radio' },
      pcl5_17: { 'ui:widget': 'radio' },
      pcl5_18: { 'ui:widget': 'radio' },
      pcl5_19: { 'ui:widget': 'radio' },
      pcl5_20: { 'ui:widget': 'radio' },
    },
  },
  medsPostSessionExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsFinalMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
        lyraSatisfaction_8: { 'ui:widget': 'textarea' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
        lyraSatisfaction_8: { 'ui:widget': 'textarea' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  medsPostSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'wai_label',
        'wai_1',
        'wai_2',
        'wai_3',
        'wai_4',
        'wai_5',
        'wai_6',
        'wai_7',
        'wai_8',
        'wai_9',
        'wai_10',
        'wai_11',
        'wai_12',
      ],
      wai_1: { 'ui:widget': 'radio' },
      wai_2: { 'ui:widget': 'radio' },
      wai_3: { 'ui:widget': 'radio' },
      wai_4: { 'ui:widget': 'radio' },
      wai_5: { 'ui:widget': 'radio' },
      wai_6: { 'ui:widget': 'radio' },
      wai_7: { 'ui:widget': 'radio' },
      wai_8: { 'ui:widget': 'radio' },
      wai_9: { 'ui:widget': 'radio' },
      wai_10: { 'ui:widget': 'radio' },
      wai_11: { 'ui:widget': 'radio' },
      wai_12: { 'ui:widget': 'radio' },
    },
  },
  coachingPostSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'wai_label',
        'wai_1',
        'wai_2',
        'wai_3',
        'wai_4',
        'wai_5',
        'wai_6',
        'wai_7',
        'wai_8',
        'wai_9',
        'wai_10',
        'wai_11',
        'wai_12',
      ],
      wai_1: { 'ui:widget': 'radio' },
      wai_2: { 'ui:widget': 'radio' },
      wai_3: { 'ui:widget': 'radio' },
      wai_4: { 'ui:widget': 'radio' },
      wai_5: { 'ui:widget': 'radio' },
      wai_6: { 'ui:widget': 'radio' },
      wai_7: { 'ui:widget': 'radio' },
      wai_8: { 'ui:widget': 'radio' },
      wai_9: { 'ui:widget': 'radio' },
      wai_10: { 'ui:widget': 'radio' },
      wai_11: { 'ui:widget': 'radio' },
      wai_12: { 'ui:widget': 'radio' },
    },
  },
  coachingPostSessionExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'wai_label',
          'wai_1',
          'wai_2',
          'wai_3',
          'wai_4',
          'wai_5',
          'wai_6',
          'wai_7',
          'wai_8',
          'wai_9',
          'wai_10',
          'wai_11',
          'wai_12',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        wai_1: { 'ui:widget': 'radio' },
        wai_2: { 'ui:widget': 'radio' },
        wai_3: { 'ui:widget': 'radio' },
        wai_4: { 'ui:widget': 'radio' },
        wai_5: { 'ui:widget': 'radio' },
        wai_6: { 'ui:widget': 'radio' },
        wai_7: { 'ui:widget': 'radio' },
        wai_8: { 'ui:widget': 'radio' },
        wai_9: { 'ui:widget': 'radio' },
        wai_10: { 'ui:widget': 'radio' },
        wai_11: { 'ui:widget': 'radio' },
        wai_12: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMildExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMildMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
        'lyraSatisfaction_label',
        'lyraSatisfaction_1',
        'lyraSatisfaction_2',
        'lyraSatisfaction_3',
        'lyraSatisfaction_4',
        'lyraSatisfaction_5',
        'lyraSatisfaction_6',
        'lyraSatisfaction_coachContent_label',
        'lyraSatisfaction_coachContent',
        'lyraSatisfaction_coachContent_specify',
        'lyraSatisfaction_skills_label',
        'lyraSatisfaction_skills',
        'lyraSatisfaction_skills_specify',
        'lyraSatisfaction_7',
        'lyraSatisfaction_8',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
      lyraSatisfaction_1: { 'ui:widget': 'radio' },
      lyraSatisfaction_2: { 'ui:widget': 'radio' },
      lyraSatisfaction_3: { 'ui:widget': 'radio' },
      lyraSatisfaction_4: { 'ui:widget': 'radio' },
      lyraSatisfaction_5: { 'ui:widget': 'radio' },
      lyraSatisfaction_6: { 'ui:widget': 'radio' },
      lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_7: { 'ui:widget': 'radio' },
    },
  },
  coachingFinalExtendedMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'pastWeeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        pastWeeks_9: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
        nextQuestionsAsk_15: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
          'lyraSatisfaction_label',
          'lyraSatisfaction_1',
          'lyraSatisfaction_2',
          'lyraSatisfaction_3',
          'lyraSatisfaction_4',
          'lyraSatisfaction_5',
          'lyraSatisfaction_6',
          'lyraSatisfaction_coachContent_label',
          'lyraSatisfaction_coachContent',
          'lyraSatisfaction_coachContent_specify',
          'lyraSatisfaction_skills_label',
          'lyraSatisfaction_skills',
          'lyraSatisfaction_skills_specify',
          'lyraSatisfaction_7',
          'lyraSatisfaction_8',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        questions1Through_2: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        nextTwoQuestions_5: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        questionAsksAbout_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        nextQuestionAsks_10: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        questionsAskAbout_12: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
        nextQuestionsAsk_15: { 'ui:widget': 'radio' },
        lyraSatisfaction_1: { 'ui:widget': 'radio' },
        lyraSatisfaction_2: { 'ui:widget': 'radio' },
        lyraSatisfaction_3: { 'ui:widget': 'radio' },
        lyraSatisfaction_4: { 'ui:widget': 'radio' },
        lyraSatisfaction_5: { 'ui:widget': 'radio' },
        lyraSatisfaction_6: { 'ui:widget': 'radio' },
        lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
        lyraSatisfaction_7: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingFinalMetadata: {
    uiSchema: {
      'ui:order': [
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
        'lyraSatisfaction_label',
        'lyraSatisfaction_1',
        'lyraSatisfaction_2',
        'lyraSatisfaction_3',
        'lyraSatisfaction_4',
        'lyraSatisfaction_5',
        'lyraSatisfaction_6',
        'lyraSatisfaction_coachContent_label',
        'lyraSatisfaction_coachContent',
        'lyraSatisfaction_coachContent_specify',
        'lyraSatisfaction_skills_label',
        'lyraSatisfaction_skills',
        'lyraSatisfaction_skills_specify',
        'lyraSatisfaction_7',
        'lyraSatisfaction_8',
      ],
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
      lyraSatisfaction_1: { 'ui:widget': 'radio' },
      lyraSatisfaction_2: { 'ui:widget': 'radio' },
      lyraSatisfaction_3: { 'ui:widget': 'radio' },
      lyraSatisfaction_4: { 'ui:widget': 'radio' },
      lyraSatisfaction_5: { 'ui:widget': 'radio' },
      lyraSatisfaction_6: { 'ui:widget': 'radio' },
      lyraSatisfaction_coachContent: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_skills: { 'ui:widget': 'checkbox' },
      lyraSatisfaction_7: { 'ui:widget': 'radio' },
    },
  },
  coachingBaselineMetadata: {
    uiSchema: {
      default: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'past2Weeks_3',
          'past2Weeks_4',
          'nextTwoQuestions_5',
          'past2Weeks_6',
          'past2Weeks_7',
          'questionAsksAbout_8',
          'past2Weeks_9',
          'nextQuestionAsks_10',
          'past2Weeks_11',
          'questionsAskAbout_12',
          'past2Weeks_13',
          'past2Weeks_14',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        past2Weeks_3: { 'ui:widget': 'radio' },
        past2Weeks_4: { 'ui:widget': 'radio' },
        past2Weeks_6: { 'ui:widget': 'radio' },
        past2Weeks_7: { 'ui:widget': 'radio' },
        past2Weeks_9: { 'ui:widget': 'radio' },
        past2Weeks_11: { 'ui:widget': 'radio' },
        past2Weeks_13: { 'ui:widget': 'radio' },
        past2Weeks_14: { 'ui:widget': 'radio' },
      },
      v2: {
        'ui:order': [
          'gadLabel',
          'gad7_1',
          'gad7_2',
          'gad7_3',
          'gad7_4',
          'gad7_5',
          'gad7_6',
          'gad7_7',
          'phqLabel',
          'phq9_1',
          'phq9_2',
          'phq9_3',
          'phq9_4',
          'phq9_5',
          'phq9_6',
          'phq9_7',
          'phq9_8',
          'phq9_9',
          'phq9_9_1',
          'phq9_9_2',
          'phq9_10',
          'pssLabel',
          'pss_1',
          'pss_2',
          'pss_3',
          'pss_4',
          'pss_5',
          'pss_6',
          'pss_7',
          'pss_8',
          'pss_9',
          'pss_10',
          'wellBeingLabel',
          'wellBeing_1',
          'wellBeing_2',
          'wellBeing_3',
          'wellBeing_4',
          'wellBeing_5',
          'wellBeing_6',
          'wellBeing_7',
          'wellBeing_8',
          'wellBeing_9',
          'wellBeing_10',
          'wellBeing_11',
          'wellBeing_12',
          'wellBeing_13',
          'wellBeing_14',
          'productivity_label',
          'wlq_label1',
          'questions1Through_2',
          'wlq_1',
          'wlq_2',
          'nextTwoQuestions_5',
          'wlq_7',
          'wlq_8',
          'questionAsksAbout_8',
          'wlq_3',
          'nextQuestionAsks_10',
          'wlq_4',
          'questionsAskAbout_12',
          'wlq_5',
          'wlq_6',
          'wlq_label2',
          'nextQuestionsAsk_15',
          'aboutManyHours_16',
          'manyHoursDoes_17',
        ],
        gad7_1: { 'ui:widget': 'radio' },
        gad7_2: { 'ui:widget': 'radio' },
        gad7_3: { 'ui:widget': 'radio' },
        gad7_4: { 'ui:widget': 'radio' },
        gad7_5: { 'ui:widget': 'radio' },
        gad7_6: { 'ui:widget': 'radio' },
        gad7_7: { 'ui:widget': 'radio' },
        phq9_1: { 'ui:widget': 'radio' },
        phq9_2: { 'ui:widget': 'radio' },
        phq9_3: { 'ui:widget': 'radio' },
        phq9_4: { 'ui:widget': 'radio' },
        phq9_5: { 'ui:widget': 'radio' },
        phq9_6: { 'ui:widget': 'radio' },
        phq9_7: { 'ui:widget': 'radio' },
        phq9_8: { 'ui:widget': 'radio' },
        phq9_9: { 'ui:widget': 'radio' },
        phq9_9_1: { 'ui:widget': 'radio' },
        phq9_9_2: { 'ui:widget': 'radio' },
        phq9_10: { 'ui:widget': 'radio' },
        pss_1: { 'ui:widget': 'radio' },
        pss_2: { 'ui:widget': 'radio' },
        pss_3: { 'ui:widget': 'radio' },
        pss_4: { 'ui:widget': 'radio' },
        pss_5: { 'ui:widget': 'radio' },
        pss_6: { 'ui:widget': 'radio' },
        pss_7: { 'ui:widget': 'radio' },
        pss_8: { 'ui:widget': 'radio' },
        pss_9: { 'ui:widget': 'radio' },
        pss_10: { 'ui:widget': 'radio' },
        wellBeing_1: { 'ui:widget': 'radio' },
        wellBeing_2: { 'ui:widget': 'radio' },
        wellBeing_3: { 'ui:widget': 'radio' },
        wellBeing_4: { 'ui:widget': 'radio' },
        wellBeing_5: { 'ui:widget': 'radio' },
        wellBeing_6: { 'ui:widget': 'radio' },
        wellBeing_7: { 'ui:widget': 'radio' },
        wellBeing_8: { 'ui:widget': 'radio' },
        wellBeing_9: { 'ui:widget': 'radio' },
        wellBeing_10: { 'ui:widget': 'radio' },
        wellBeing_11: { 'ui:widget': 'radio' },
        wellBeing_12: { 'ui:widget': 'radio' },
        wellBeing_13: { 'ui:widget': 'radio' },
        wellBeing_14: { 'ui:widget': 'radio' },
        wlq_1: { 'ui:widget': 'radio' },
        wlq_2: { 'ui:widget': 'radio' },
        wlq_7: { 'ui:widget': 'radio' },
        wlq_8: { 'ui:widget': 'radio' },
        wlq_3: { 'ui:widget': 'radio' },
        wlq_4: { 'ui:widget': 'radio' },
        wlq_5: { 'ui:widget': 'radio' },
        wlq_6: { 'ui:widget': 'radio' },
      },
    },
  },
  coachingMildPreSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'pssLabel',
        'pss_1',
        'pss_2',
        'pss_3',
        'pss_4',
        'pss_5',
        'pss_6',
        'pss_7',
        'pss_8',
        'pss_9',
        'pss_10',
        'wellBeingLabel',
        'wellBeing_1',
        'wellBeing_2',
        'wellBeing_3',
        'wellBeing_4',
        'wellBeing_5',
        'wellBeing_6',
        'wellBeing_7',
        'wellBeing_8',
        'wellBeing_9',
        'wellBeing_10',
        'wellBeing_11',
        'wellBeing_12',
        'wellBeing_13',
        'wellBeing_14',
      ],
      pss_1: { 'ui:widget': 'radio' },
      pss_2: { 'ui:widget': 'radio' },
      pss_3: { 'ui:widget': 'radio' },
      pss_4: { 'ui:widget': 'radio' },
      pss_5: { 'ui:widget': 'radio' },
      pss_6: { 'ui:widget': 'radio' },
      pss_7: { 'ui:widget': 'radio' },
      pss_8: { 'ui:widget': 'radio' },
      pss_9: { 'ui:widget': 'radio' },
      pss_10: { 'ui:widget': 'radio' },
      wellBeing_1: { 'ui:widget': 'radio' },
      wellBeing_2: { 'ui:widget': 'radio' },
      wellBeing_3: { 'ui:widget': 'radio' },
      wellBeing_4: { 'ui:widget': 'radio' },
      wellBeing_5: { 'ui:widget': 'radio' },
      wellBeing_6: { 'ui:widget': 'radio' },
      wellBeing_7: { 'ui:widget': 'radio' },
      wellBeing_8: { 'ui:widget': 'radio' },
      wellBeing_9: { 'ui:widget': 'radio' },
      wellBeing_10: { 'ui:widget': 'radio' },
      wellBeing_11: { 'ui:widget': 'radio' },
      wellBeing_12: { 'ui:widget': 'radio' },
      wellBeing_13: { 'ui:widget': 'radio' },
      wellBeing_14: { 'ui:widget': 'radio' },
    },
  },
  coachingPreSessionMetadata: {
    uiSchema: {
      'ui:order': [
        'gadLabel',
        'gad7_1',
        'gad7_2',
        'gad7_3',
        'gad7_4',
        'gad7_5',
        'gad7_6',
        'gad7_7',
        'phqLabel',
        'phq9_1',
        'phq9_2',
        'phq9_3',
        'phq9_4',
        'phq9_5',
        'phq9_6',
        'phq9_7',
        'phq9_8',
        'phq9_9',
        'phq9_9_1',
        'phq9_9_2',
        'phq9_10',
      ],
      gad7_1: { 'ui:widget': 'radio' },
      gad7_2: { 'ui:widget': 'radio' },
      gad7_3: { 'ui:widget': 'radio' },
      gad7_4: { 'ui:widget': 'radio' },
      gad7_5: { 'ui:widget': 'radio' },
      gad7_6: { 'ui:widget': 'radio' },
      gad7_7: { 'ui:widget': 'radio' },
      phq9_1: { 'ui:widget': 'radio' },
      phq9_2: { 'ui:widget': 'radio' },
      phq9_3: { 'ui:widget': 'radio' },
      phq9_4: { 'ui:widget': 'radio' },
      phq9_5: { 'ui:widget': 'radio' },
      phq9_6: { 'ui:widget': 'radio' },
      phq9_7: { 'ui:widget': 'radio' },
      phq9_8: { 'ui:widget': 'radio' },
      phq9_9: { 'ui:widget': 'radio' },
      phq9_9_1: { 'ui:widget': 'radio' },
      phq9_9_2: { 'ui:widget': 'radio' },
      phq9_10: { 'ui:widget': 'radio' },
    },
  },

  // Notes
  generalNote: {
    uiSchema: {
      'ui:order': ['subject', 'details', 'accurateAndComplete'],
      details: { 'ui:widget': 'textarea' },
      accurateAndComplete: { 'ui:widget': 'checkbox' },
    },
  },
  initialNote: {
    uiSchema: {
      default: {
        'ui:order': [
          'clientConsent',
          'identifyingInformationTitle',
          'age',
          'gender',
          'ethnicity',
          'maritalStatus',
          'employed',
          'otherIdInfo',
          'intakeSessionComponentsTitle',
          'consentSessionRecording',
          'orientedToTreatmentApproach',
          'discussedTentativeExpectedTreatmentDuration',
          'obtainedCommitmentTreatmentPlan',
          'orientedToDigitalPractices',
          'orientedToDigitalLessons',
          'orientedToOutcomesAssessments',
          'orientedToReminderNotifications',
          'orientedToMessenger',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'elicitedAndAdressedFeedbackOnSession',
          'intakeSessionComponentsAdditionalDetails',
          'currentMentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'otherMentalHealthObs',
          'safetyIssuesTitle',
          'safetyRiskAssessmentPerformed',
          'safetyRiskAssessmentWhyNot',
          'safetyRiskAssessmentWhen',
          'safetyIssuesIdentified',
          'suicidalIdeation',
          'homicidalIdeation',
          'otherSafetyIssues',
          'completeRiskAssessmentLabel',
          'detailedRiskAssessmentTitle',
          'riskFactors',
          'additionalDetailsOnSafetyPlan',
          'psychiatricHistoryAndTreatment',
          'priorDiagnoses',
          'historySuicidalBehavior',
          'historySelfHarmBehavior',
          'historyPsychiatricHospitalization',
          'historyTrauma',
          'historyMentalHealthServices',
          'historyViolentBehaviors',
          'currentMedicalConditions',
          'currentMedications',
          'substanceUse',
          'psychologicalHistoryTitle',
          'familySocialhistory',
          'familyPsychiatricHistory',
          'developmentalHistory',
          'occupationalHistory',
          'historyOtherInformationTitle',
          'strenghtsLiabilities',
          'otherSymptomsEndorsed',
          'otherBackgroundInformation',
          'caseFormulationTreatmentPlanTitle',
          'precipitantsPresentingProblem',
          'crossSectionalCognitions',
          'longitudinalViewCognitions',
          'workingHypothesis',
          'problemList',
          'problem1',
          'problem2',
          'problem3',
          'otherProblems',
          'treatmentGoals',
          'goal1',
          'goal2',
          'goal3',
          'otherGoals',
          'treatmentPlan',
          'interventionUsedTitle',
          'cbt',
          'additionalSkillsInterventionUsed',
          'additionalSkillsMoreDetails',
          'digitalPracticeAssigned',
          'digitalPracticeAssignedMoreDetails',
          'digitalLessonAssigned',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'substanceAbuseDiagnosis',
          'noteBridgeToNextSession',
          'planForNextSession',
          'sign',
          'declareInfoAccurateAndComplete',
        ],
        clientConsent: { 'ui:widget': 'radio' },
        employed: { 'ui:widget': 'radio' },
        otherIdInfo: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        otherMentalHealthObs: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentPerformed: { 'ui:widget': 'radio' },
        safetyRiskAssessmentWhyNot: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentWhen: { 'ui:widget': 'text' },
        safetyIssuesIdentified: { 'ui:widget': 'radio' },
        suicidalIdeation: { 'ui:widget': 'select' },
        homicidalIdeation: { 'ui:widget': 'select' },
        otherSafetyIssues: { 'ui:widget': 'textarea' },
        riskFactors: {
          items: [
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            // otherRiskFactors
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Protective Factors
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Overall assessment of risk
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            // Safety Plan
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'checkbox' },
            {},
            {},
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
          ],
        },
        additionalDetailsOnSafetyPlan: { 'ui:widget': 'textarea' },
        priorDiagnoses: { 'ui:widget': 'textarea' },
        historySuicidalBehavior: { 'ui:widget': 'textarea' },
        historySelfHarmBehavior: { 'ui:widget': 'textarea' },
        historyPsychiatricHospitalization: { 'ui:widget': 'textarea' },
        historyTrauma: { 'ui:widget': 'textarea' },
        historyMentalHealthServices: { 'ui:widget': 'textarea' },
        historyViolentBehaviors: { 'ui:widget': 'textarea' },
        currentMedicalConditions: { 'ui:widget': 'textarea' },
        currentMedications: { 'ui:widget': 'textarea' },
        substanceUse: { 'ui:widget': 'textarea' },
        familySocialhistory: { 'ui:widget': 'textarea' },
        familyPsychiatricHistory: { 'ui:widget': 'textarea' },
        developmentalHistory: { 'ui:widget': 'textarea' },
        occupationalHistory: { 'ui:widget': 'textarea' },
        strenghtsLiabilities: { 'ui:widget': 'textarea' },
        otherSymptomsEndorsed: { 'ui:widget': 'textarea' },
        otherBackgroundInformation: { 'ui:widget': 'textarea' },
        precipitantsPresentingProblem: { 'ui:widget': 'textarea' },
        crossSectionalCognitions: { 'ui:widget': 'textarea' },
        longitudinalViewCognitions: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        problem1: { 'ui:widget': 'text' },
        problem2: { 'ui:widget': 'text' },
        problem3: { 'ui:widget': 'text' },
        otherProblems: { 'ui:widget': 'text' },
        goal1: { 'ui:widget': 'text' },
        goal2: { 'ui:widget': 'text' },
        goal3: { 'ui:widget': 'text' },
        otherGoals: { 'ui:widget': 'text' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        consentSessionRecording: { 'ui:widget': 'checkbox' },
        orientedToTreatmentApproach: { 'ui:widget': 'checkbox' },
        discussedTentativeExpectedTreatmentDuration: { 'ui:widget': 'checkbox' },
        obtainedCommitmentTreatmentPlan: { 'ui:widget': 'checkbox' },
        orientedToDigitalPractices: { 'ui:widget': 'checkbox' },
        orientedToDigitalLessons: { 'ui:widget': 'checkbox' },
        orientedToOutcomesAssessments: { 'ui:widget': 'checkbox' },
        orientedToReminderNotifications: { 'ui:widget': 'checkbox' },
        orientedToMessenger: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        elicitedAndAdressedFeedbackOnSession: { 'ui:widget': 'checkbox' },
        intakeSessionComponentsAdditionalDetails: { 'ui:widget': 'textarea' },
        cbt: { 'ui:widget': 'checkbox' },
        additionalSkillsInterventionUsed: { 'ui:widget': 'typeAhead' },
        additionalSkillsMoreDetails: { 'ui:widget': 'text' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        digitalPracticeAssignedMoreDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        noteBridgeToNextSession: { 'ui:widget': 'textarea' },
        planForNextSession: { 'ui:widget': 'textarea' },
        declareInfoAccurateAndComplete: { 'ui:widget': 'checkbox' },
      },
      v2: {
        'ui:order': [
          'clientConsent',
          'isBoosterSession',
          'identifyingInformationTitle',
          'age',
          'gender',
          'ethnicity',
          'maritalStatus',
          'employed',
          'otherIdInfo',
          'intakeSessionComponentsTitle',
          'consentSessionRecording',
          'orientedToTreatmentApproach',
          'discussedTentativeExpectedTreatmentDuration',
          'obtainedCommitmentTreatmentPlan',
          'orientedToDigitalPractices',
          'orientedToDigitalLessons',
          'orientedToOutcomesAssessments',
          'orientedToReminderNotifications',
          'orientedToMessenger',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'elicitedAndAdressedFeedbackOnSession',
          'currentMentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'additionalMentalStatus',
          'otherMentalHealthObs',
          'safetyIssuesTitle',
          'safetyRiskAssessmentPerformed',
          'safetyRiskAssessmentWhyNot',
          'safetyRiskAssessmentWhen',
          'safetyIssuesIdentified',
          'suicidalIdeation',
          'homicidalIdeation',
          'completeRiskAssessmentLabel',
          'detailedRiskAssessmentTitle',
          'riskFactors',
          'additionalDetailsOnSafetyPlan',
          'psychiatricHistoryAndTreatment',
          'priorDiagnoses',
          'historySuicidalBehavior',
          'historySelfHarmBehavior',
          'historyPsychiatricHospitalization',
          'historyTrauma',
          'historyMentalHealthServices',
          'historyViolentBehaviors',
          'currentMedicalConditions',
          'currentMedications',
          'substanceUse',
          'psychologicalHistoryTitle',
          'familySocialhistory',
          'familyPsychiatricHistory',
          'developmentalHistory',
          'occupationalHistory',
          'historyOtherInformationTitle',
          'strenghtsLiabilities',
          'otherSymptomsEndorsed',
          'otherBackgroundInformation',
          'isClientCaregiverForChildren',
          'caseFormulationTreatmentPlanTitle',
          'precipitantsPresentingProblem',
          'crossSectionalCognitions',
          'longitudinalViewCognitions',
          'workingHypothesis',
          'problemList',
          'problem1',
          'problem2',
          'problem3',
          'otherProblems',
          'addressedThisSession',
          'treatmentGoals',
          'goal1',
          'goal2',
          'goal3',
          'otherGoals',
          'goalsAddressedThisSession',
          'treatmentPlan',
          'interventionUsedTitle',
          'cbt',
          'additionalSkillsInterventionUsed',
          'additionalSkillsMoreDetails',
          'digitalPracticeAssigned',
          'digitalPracticeAssignedMoreDetails',
          'digitalLessonAssigned',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'substanceAbuseDiagnosis',
          'noteBridgeToNextSession',
          'sign',
          'declareInfoAccurateAndComplete',
        ],
        clientConsent: { 'ui:widget': 'radio' },
        isBoosterSession: { 'ui:widget': 'radio' },
        employed: { 'ui:widget': 'radio' },
        otherIdInfo: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        additionalMentalStatus: { 'ui:widget': 'textarea' },
        otherMentalHealthObs: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentPerformed: { 'ui:widget': 'radio' },
        safetyRiskAssessmentWhyNot: { 'ui:widget': 'textarea' },
        safetyRiskAssessmentWhen: { 'ui:widget': 'text' },
        safetyIssuesIdentified: { 'ui:widget': 'radio' },
        suicidalIdeation: { 'ui:widget': 'select' },
        homicidalIdeation: { 'ui:widget': 'select' },
        riskFactors: {
          items: [
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            // otherRiskFactors
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Protective Factors
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            // Overall assessment of risk
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'typeAhead' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'text' },
            // Safety Plan
            {},
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'textarea' },
            {},
            {},
            { 'ui:widget': 'checkbox' },
            {},
            {},
            {},
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'checkbox' },
            { 'ui:widget': 'textarea' },
          ],
        },
        additionalDetailsOnSafetyPlan: { 'ui:widget': 'textarea' },
        priorDiagnoses: { 'ui:widget': 'textarea' },
        historySuicidalBehavior: { 'ui:widget': 'textarea' },
        historySelfHarmBehavior: { 'ui:widget': 'textarea' },
        historyPsychiatricHospitalization: { 'ui:widget': 'textarea' },
        historyTrauma: { 'ui:widget': 'textarea' },
        historyMentalHealthServices: { 'ui:widget': 'textarea' },
        historyViolentBehaviors: { 'ui:widget': 'textarea' },
        currentMedicalConditions: { 'ui:widget': 'textarea' },
        currentMedications: { 'ui:widget': 'textarea' },
        substanceUse: { 'ui:widget': 'textarea' },
        familySocialhistory: { 'ui:widget': 'textarea' },
        familyPsychiatricHistory: { 'ui:widget': 'textarea' },
        developmentalHistory: { 'ui:widget': 'textarea' },
        occupationalHistory: { 'ui:widget': 'textarea' },
        strenghtsLiabilities: { 'ui:widget': 'textarea' },
        otherSymptomsEndorsed: { 'ui:widget': 'textarea' },
        otherBackgroundInformation: { 'ui:widget': 'textarea' },
        isClientCaregiverForChildren: { 'ui:widget': 'select' },
        precipitantsPresentingProblem: { 'ui:widget': 'textarea' },
        crossSectionalCognitions: { 'ui:widget': 'textarea' },
        longitudinalViewCognitions: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        problem1: { 'ui:widget': 'text' },
        problem2: { 'ui:widget': 'text' },
        problem3: { 'ui:widget': 'text' },
        otherProblems: { 'ui:widget': 'text' },
        addressedThisSession: { 'ui:widget': 'checkbox' },
        goal1: { 'ui:widget': 'text' },
        goal2: { 'ui:widget': 'text' },
        goal3: { 'ui:widget': 'text' },
        otherGoals: { 'ui:widget': 'text' },
        goalsAddressedThisSession: { 'ui:widget': 'checkbox' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        consentSessionRecording: { 'ui:widget': 'checkbox' },
        orientedToTreatmentApproach: { 'ui:widget': 'checkbox' },
        discussedTentativeExpectedTreatmentDuration: { 'ui:widget': 'checkbox' },
        obtainedCommitmentTreatmentPlan: { 'ui:widget': 'checkbox' },
        orientedToDigitalPractices: { 'ui:widget': 'checkbox' },
        orientedToDigitalLessons: { 'ui:widget': 'checkbox' },
        orientedToOutcomesAssessments: { 'ui:widget': 'checkbox' },
        orientedToReminderNotifications: { 'ui:widget': 'checkbox' },
        orientedToMessenger: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        elicitedAndAdressedFeedbackOnSession: { 'ui:widget': 'checkbox' },
        cbt: { 'ui:widget': 'checkbox' },
        additionalSkillsInterventionUsed: { 'ui:widget': 'typeAhead' },
        additionalSkillsMoreDetails: { 'ui:widget': 'text' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        digitalPracticeAssignedMoreDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        noteBridgeToNextSession: { 'ui:widget': 'textarea' },
        declareInfoAccurateAndComplete: { 'ui:widget': 'checkbox' },
      },
    },
  },
  sessionNote: {
    uiSchema: {
      default: {
        'ui:order': [
          'lastSession',
          'terminationReason',
          'additionalTerminationReason',
          'clientPresentation',
          'symptomDescription',
          'sessionComponents',
          'collectAndReviewOutcomes',
          'discussedOutcomes',
          'reviewedDigitalPractice',
          'reviewedDigitalLesson',
          'discussedTreatmentProgress',
          'discussedTherapyTermination',
          'assignedPractice',
          'assignedLesson',
          'obtainedAssignmentCommitment',
          'troubleshootAssignment',
          'assignedWellBeingScale',
          'elicitedClientFeedback',
          'notableClientFeedback',
          'additionalComponentDetails',
          'mentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'otherMentalStatus',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'otherDiagnosis',
          'substanceAbuseDiagnosis',
          'safetyIssuesLabel',
          'suicidalIdeation',
          'homicidalIdeation',
          'domesticViolence',
          'otherSafetyIssues',
          'additionalInfoSafetyIssues',
          'riskAssessmentLabel',
          'medicationChanges',
          'updateFormulationAndTreatmentLabel',
          'problemPrecipitants',
          'crossSectionalSymptoms',
          'longitudinalSymptoms',
          'workingHypothesis',
          'problemListLabel',
          'problemOne',
          'problemTwo',
          'problemThree',
          'otherProblems',
          'treatmentGoalsLabel',
          'treatmentGoalOne',
          'treatmentGoalTwo',
          'treatmentGoalThree',
          'otherTreatmentGoals',
          'treatmentPlan',
          'problemsAndTreatmentGoalsLabel',
          'problemOneAddressed',
          'problemTwoAddressed',
          'problemThreeAddressed',
          'otherProblemAddressed',
          'goalOneAddressed',
          'goalTwoAddressed',
          'goalThreeAddressed',
          'otherGoalsAddressed',
          'progressOnProblemsAndGoals',
          'clientMakingProgress',
          'progressBarriers',
          'sessionInterventions',
          'cbtCheckbox',
          'additionalIntervention',
          'symptomMonitoring',
          'thinkingTraps',
          'problemSolving',
          'assertivness',
          'interpersonalEffectiveness',
          'exposure',
          'behavioralActivation',
          'values',
          'mindfulness',
          'understandingEmotions',
          'prosCons',
          'distressTolerance',
          'sleepHygiene',
          'validation',
          'otherInterventions',
          'additionalInterventionDetails',
          'terminationSessionComponents',
          'collectedOutcomeMeasures',
          'discussedOutcomeMeasures',
          'reviewedCompletionOfPractice',
          'reviewedCompletionOfLesson',
          'discussedGoalsProgress',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'createdRelapsePreventionPlan',
          'consideredSchedulingBoosterSession',
          'elicitedTreatmentFeedbackFromClient',
          'elicitedPlatformFeedbackFromClient',
          'assignedWellBeingScaleAtTermination',
          'notableClientFeedbackOnTreatment',
          'additionalDetailsOnTermination',
          'digitalPracticeAssigned',
          'additionalPracticeAssignedDetails',
          'digitalLessonAssigned',
          'nextSessionPlan',
          'estimatedAdditionalSessions',
          'additionalDetailsOnAdditionalSessions',
          'sign',
          'accurateAndComplete',
        ],
        lastSession: { 'ui:widget': 'radio' },
        terminationReason: { 'ui:widget': 'typeAhead' },
        additionalTerminationReason: { 'ui:widget': 'textarea' },
        symptomDescription: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        otherMentalStatus: { 'ui:widget': 'textarea' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        otherDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        suicidalIdeation: { 'ui:widget': 'checkbox' },
        homicidalIdeation: { 'ui:widget': 'checkbox' },
        domesticViolence: { 'ui:widget': 'checkbox' },
        otherSafetyIssues: { 'ui:widget': 'textarea' },
        additionalInfoSafetyIssues: { 'ui:widget': 'textarea' },
        medicationChanges: { 'ui:widget': 'textarea' },
        problemPrecipitants: { 'ui:widget': 'textarea' },
        crossSectionalSymptoms: { 'ui:widget': 'textarea' },
        longitudinalSymptoms: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        otherProblems: { 'ui:widget': 'textarea' },
        treatmentGoalOne: { 'ui:widget': 'textarea' },
        treatmentGoalTwo: { 'ui:widget': 'textarea' },
        treatmentGoalThree: { 'ui:widget': 'textarea' },
        otherTreatmentGoals: { 'ui:widget': 'textarea' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        problemOneAddressed: { 'ui:widget': 'checkbox' },
        problemTwoAddressed: { 'ui:widget': 'checkbox' },
        problemThreeAddressed: { 'ui:widget': 'checkbox' },
        otherProblemAddressed: { 'ui:widget': 'textarea' },
        goalOneAddressed: { 'ui:widget': 'checkbox' },
        goalTwoAddressed: { 'ui:widget': 'checkbox' },
        goalThreeAddressed: { 'ui:widget': 'checkbox' },
        otherGoalsAddressed: { 'ui:widget': 'textarea' },
        progressOnProblemsAndGoals: { 'ui:widget': 'textarea' },
        clientMakingProgress: { 'ui:widget': 'radio' },
        progressBarriers: { 'ui:widget': 'textarea' },
        cbtCheckbox: { 'ui:widget': 'checkbox' },
        symptomMonitoring: { 'ui:widget': 'checkbox' },
        thinkingTraps: { 'ui:widget': 'checkbox' },
        problemSolving: { 'ui:widget': 'checkbox' },
        assertivness: { 'ui:widget': 'checkbox' },
        interpersonalEffectiveness: { 'ui:widget': 'checkbox' },
        exposure: { 'ui:widget': 'checkbox' },
        behavioralActivation: { 'ui:widget': 'checkbox' },
        values: { 'ui:widget': 'checkbox' },
        mindfulness: { 'ui:widget': 'checkbox' },
        understandingEmotions: { 'ui:widget': 'checkbox' },
        prosCons: { 'ui:widget': 'checkbox' },
        distressTolerance: { 'ui:widget': 'checkbox' },
        sleepHygiene: { 'ui:widget': 'checkbox' },
        validation: { 'ui:widget': 'checkbox' },
        otherInterventions: { 'ui:widget': 'textarea' },
        additionalInterventionDetails: { 'ui:widget': 'textarea' },
        collectedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        discussedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfPractice: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfLesson: { 'ui:widget': 'checkbox' },
        discussedGoalsProgress: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        createdRelapsePreventionPlan: { 'ui:widget': 'checkbox' },
        consideredSchedulingBoosterSession: { 'ui:widget': 'checkbox' },
        elicitedTreatmentFeedbackFromClient: { 'ui:widget': 'checkbox' },
        assignedWellBeingScaleAtTermination: { 'ui:widget': 'checkbox' },
        notableClientFeedbackOnTreatment: { 'ui:widget': 'textarea' },
        additionalDetailsOnTermination: { 'ui:widget': 'textarea' },
        collectAndReviewOutcomes: { 'ui:widget': 'checkbox' },
        discussedOutcomes: { 'ui:widget': 'checkbox' },
        reviewedDigitalPractice: { 'ui:widget': 'checkbox' },
        reviewedDigitalLesson: { 'ui:widget': 'checkbox' },
        discussedTreatmentProgress: { 'ui:widget': 'checkbox' },
        discussedTherapyTermination: { 'ui:widget': 'checkbox' },
        assignedPractice: { 'ui:widget': 'checkbox' },
        assignedLesson: { 'ui:widget': 'checkbox' },
        obtainedAssignmentCommitment: { 'ui:widget': 'checkbox' },
        troubleshootAssignment: { 'ui:widget': 'checkbox' },
        assignedWellBeingScale: { 'ui:widget': 'checkbox' },
        elicitedClientFeedback: { 'ui:widget': 'checkbox' },
        notableClientFeedback: { 'ui:widget': 'textarea' },
        additionalComponentDetails: { 'ui:widget': 'textarea' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        additionalPracticeAssignedDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        nextSessionPlan: { 'ui:widget': 'textarea' },
        additionalDetailsOnAdditionalSessions: { 'ui:widget': 'textarea' },
        accurateAndComplete: { 'ui:widget': 'checkbox' },
      },
      v2: {
        'ui:order': [
          'lastSession',
          'isBoosterSession',
          'terminationReason',
          'additionalTerminationReason',
          'clientPresentation',
          'symptomDescription',
          'sessionComponents',
          'collectAndReviewOutcomes',
          'askedVerbalInformedConsent',
          'discussedOutcomes',
          'reviewedDigitalPractice',
          'reviewedDigitalLesson',
          'discussedTreatmentProgress',
          'discussedTherapyTermination',
          'assignedPractice',
          'assignedLesson',
          'obtainedAssignmentCommitment',
          'troubleshootAssignment',
          'assignedWellBeingScale',
          'elicitedClientFeedback',
          'agreedToRecordSession',
          'mentalStatus',
          'generalAppearance',
          'cognitiveFunctioning',
          'mood',
          'affect',
          'judgment',
          'functionalStatus',
          'additionalMentalStatus',
          'otherMentalStatus',
          'primaryDiagnosis',
          'secondaryDiagnosis',
          'otherDiagnosis',
          'substanceAbuseDiagnosis',
          'safetyIssuesLabel',
          'suicidalIdeation',
          'homicidalIdeation',
          'domesticViolence',
          'additionalInfoSafetyIssues',
          'riskAssessmentLabel',
          'updateFormulationAndTreatmentLabel',
          'problemPrecipitants',
          'crossSectionalSymptoms',
          'longitudinalSymptoms',
          'workingHypothesis',
          'problemListLabel',
          'problemOne',
          'problemTwo',
          'problemThree',
          'otherProblems',
          'addressedThisSession',
          'treatmentGoalsLabel',
          'treatmentGoalOne',
          'treatmentGoalTwo',
          'treatmentGoalThree',
          'otherTreatmentGoals',
          'medicationChanges',
          'medicationChangesDetails',
          'treatmentPlan',
          'problemsAndTreatmentGoalsLabel',
          'problemOneAddressed',
          'problemTwoAddressed',
          'problemThreeAddressed',
          'otherProblemAddressed',
          'goalOneAddressed',
          'goalTwoAddressed',
          'goalThreeAddressed',
          'otherGoalsAddressed',
          'goalsAddressedThisSession',
          'clientMakingProgress',
          'progressBarriers',
          'sessionInterventions',
          'additionalIntervention',
          'assertivness',
          'behavioralActivation',
          'cbtCheckbox',
          'distressTolerance',
          'exposure',
          'interpersonalEffectiveness',
          'mindfulness',
          'problemSolving',
          'prosCons',
          'sleepHygiene',
          'symptomMonitoring',
          'thinkingTraps',
          'understandingEmotions',
          'validation',
          'values',
          'otherInterventions',
          'terminationSessionComponents',
          'collectedOutcomeMeasures',
          'askedVerbalInformedConsentTermination',
          'discussedOutcomeMeasures',
          'reviewedCompletionOfPractice',
          'reviewedCompletionOfLesson',
          'discussedGoalsProgress',
          'assignedDigitalPractice',
          'assignedDigitalLesson',
          'createdRelapsePreventionPlan',
          'consideredSchedulingBoosterSession',
          'elicitedTreatmentFeedbackFromClient',
          'elicitedPlatformFeedbackFromClient',
          'assignedWellBeingScaleAtTermination',
          'notableClientFeedbackOnTreatment',
          'additionalDetailsOnTermination',
          'digitalPracticeAssigned',
          'additionalPracticeAssignedDetails',
          'digitalLessonAssigned',
          'nextSessionPlan',
          'estimatedAdditionalSessions',
          'additionalDetailsOnAdditionalSessions',
          'sign',
          'accurateAndComplete',
        ],
        lastSession: { 'ui:widget': 'radio' },
        isBoosterSession: { 'ui:widget': 'radio' },
        terminationReason: { 'ui:widget': 'typeAhead' },
        additionalTerminationReason: { 'ui:widget': 'textarea' },
        symptomDescription: { 'ui:widget': 'textarea' },
        generalAppearance: { 'ui:widget': 'typeAhead' },
        cognitiveFunctioning: { 'ui:widget': 'typeAhead' },
        mood: { 'ui:widget': 'typeAhead' },
        affect: { 'ui:widget': 'typeAhead' },
        judgment: { 'ui:widget': 'typeAhead' },
        functionalStatus: { 'ui:widget': 'typeAhead' },
        additionalMentalStatus: { 'ui:widget': 'textarea' },
        otherMentalStatus: { 'ui:widget': 'textarea' },
        primaryDiagnosis: { 'ui:widget': 'typeAhead' },
        secondaryDiagnosis: { 'ui:widget': 'typeAhead' },
        otherDiagnosis: { 'ui:widget': 'typeAhead' },
        substanceAbuseDiagnosis: { 'ui:widget': 'select' },
        suicidalIdeation: { 'ui:widget': 'checkbox' },
        homicidalIdeation: { 'ui:widget': 'checkbox' },
        domesticViolence: { 'ui:widget': 'checkbox' },
        additionalInfoSafetyIssues: { 'ui:widget': 'textarea' },
        medicationChanges: { 'ui:widget': 'checkbox' },
        medicationChangesDetails: { 'ui:widget': 'textarea' },
        problemPrecipitants: { 'ui:widget': 'textarea' },
        crossSectionalSymptoms: { 'ui:widget': 'textarea' },
        longitudinalSymptoms: { 'ui:widget': 'textarea' },
        workingHypothesis: { 'ui:widget': 'textarea' },
        otherProblems: { 'ui:widget': 'text' },
        addressedThisSession: { 'ui:widget': 'checkbox' },
        treatmentGoalOne: { 'ui:widget': 'text' },
        treatmentGoalTwo: { 'ui:widget': 'text' },
        treatmentGoalThree: { 'ui:widget': 'text' },
        otherTreatmentGoals: { 'ui:widget': 'text' },
        treatmentPlan: { 'ui:widget': 'textarea' },
        problemOneAddressed: { 'ui:widget': 'checkbox' },
        problemTwoAddressed: { 'ui:widget': 'checkbox' },
        problemThreeAddressed: { 'ui:widget': 'checkbox' },
        otherProblemAddressed: { 'ui:widget': 'textarea' },
        goalOneAddressed: { 'ui:widget': 'checkbox' },
        goalTwoAddressed: { 'ui:widget': 'checkbox' },
        goalThreeAddressed: { 'ui:widget': 'checkbox' },
        otherGoalsAddressed: { 'ui:widget': 'textarea' },
        goalsAddressedThisSession: { 'ui:widget': 'checkbox' },
        clientMakingProgress: { 'ui:widget': 'radio' },
        progressBarriers: { 'ui:widget': 'textarea' },
        cbtCheckbox: { 'ui:widget': 'checkbox' },
        symptomMonitoring: { 'ui:widget': 'checkbox' },
        thinkingTraps: { 'ui:widget': 'checkbox' },
        problemSolving: { 'ui:widget': 'checkbox' },
        assertivness: { 'ui:widget': 'checkbox' },
        interpersonalEffectiveness: { 'ui:widget': 'checkbox' },
        exposure: { 'ui:widget': 'checkbox' },
        behavioralActivation: { 'ui:widget': 'checkbox' },
        values: { 'ui:widget': 'checkbox' },
        mindfulness: { 'ui:widget': 'checkbox' },
        understandingEmotions: { 'ui:widget': 'checkbox' },
        prosCons: { 'ui:widget': 'checkbox' },
        distressTolerance: { 'ui:widget': 'checkbox' },
        sleepHygiene: { 'ui:widget': 'checkbox' },
        validation: { 'ui:widget': 'checkbox' },
        otherInterventions: { 'ui:widget': 'checkbox' },
        collectedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        askedVerbalInformedConsentTermination: { 'ui:widget': 'checkbox' },
        discussedOutcomeMeasures: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfPractice: { 'ui:widget': 'checkbox' },
        reviewedCompletionOfLesson: { 'ui:widget': 'checkbox' },
        discussedGoalsProgress: { 'ui:widget': 'checkbox' },
        assignedDigitalPractice: { 'ui:widget': 'checkbox' },
        assignedDigitalLesson: { 'ui:widget': 'checkbox' },
        createdRelapsePreventionPlan: { 'ui:widget': 'checkbox' },
        consideredSchedulingBoosterSession: { 'ui:widget': 'checkbox' },
        elicitedTreatmentFeedbackFromClient: { 'ui:widget': 'checkbox' },
        assignedWellBeingScaleAtTermination: { 'ui:widget': 'checkbox' },
        notableClientFeedbackOnTreatment: { 'ui:widget': 'textarea' },
        additionalDetailsOnTermination: { 'ui:widget': 'textarea' },
        collectAndReviewOutcomes: { 'ui:widget': 'checkbox' },
        askedVerbalInformedConsent: { 'ui:widget': 'checkbox' },
        discussedOutcomes: { 'ui:widget': 'checkbox' },
        reviewedDigitalPractice: { 'ui:widget': 'checkbox' },
        reviewedDigitalLesson: { 'ui:widget': 'checkbox' },
        discussedTreatmentProgress: { 'ui:widget': 'checkbox' },
        discussedTherapyTermination: { 'ui:widget': 'checkbox' },
        assignedPractice: { 'ui:widget': 'checkbox' },
        assignedLesson: { 'ui:widget': 'checkbox' },
        obtainedAssignmentCommitment: { 'ui:widget': 'checkbox' },
        troubleshootAssignment: { 'ui:widget': 'checkbox' },
        assignedWellBeingScale: { 'ui:widget': 'checkbox' },
        elicitedClientFeedback: { 'ui:widget': 'checkbox' },
        agreedToRecordSession: { 'ui:widget': 'radio' },
        digitalPracticeAssigned: { 'ui:widget': 'typeAhead' },
        additionalPracticeAssignedDetails: { 'ui:widget': 'textarea' },
        digitalLessonAssigned: { 'ui:widget': 'typeAhead' },
        nextSessionPlan: { 'ui:widget': 'textarea' },
        additionalDetailsOnAdditionalSessions: { 'ui:widget': 'textarea' },
        accurateAndComplete: { 'ui:widget': 'checkbox' },
      },
    },
  },
  medsInitialNote: {
    uiSchema: {
      'ui:order': [
        'verbalInformedConsent_1',
        'isBoosterSession',
        'identifyingInformation_2',
        'identifyingInformation_3',
        'chiefComplaint_5',
        'hpi_8',
        'psychiatricReviewSystems_9',
        'symptomsEndorsedIntake_10',
        'depression_11',
        'depressedMood_12',
        'moodInformationFrom_13',
        'sleep_14',
        'sleepInformationFrom_15',
        'interest_16',
        'interestInformationFrom_17',
        'psychomotorAgitationRetardation_18',
        'psychomotorAgitationRetardation_18_2',
        'psychomotorAgitationRetardation_19',
        'guiltWorthlessness_20',
        'guiltWorthlessness_20_2',
        'guiltWorthlessnessInformation_21',
        'energy_22',
        'energyInformationFrom_23',
        'concentration_24',
        'concentrationInformationFrom_25',
        'appetite_26',
        'appetite_26_2',
        'appetiteInformationFrom_27',
        'si_28',
        'siInformationFrom_29',
        'hi_30',
        'hiInformationFrom_31',
        'bipolarMania_32',
        'mood_33',
        'moodInformationFrom_34',
        'irritability_35',
        'irritabilityInformationFrom_36',
        'noSleep_37',
        'noSleepInformation_38',
        'speech_39',
        'speechInformationFrom_40',
        'grandiosity_41',
        'grandiosityInformationFrom_42',
        'racingThoughts_43',
        'racingThoughtsInformation_44',
        'goalOrientedRisky_45',
        'goalOrientedRisky_46',
        'distractibilityConcentration_47',
        'distractibilityConcentrationInformation_48',
        'psychosis_49',
        'ah_50',
        'ahInformationFrom_51',
        'vh_52',
        'vhInformationFrom_53',
        'paranoia_54',
        'paranoiaInformationFrom_55',
        'delusionsInformationFrom_57',
        'anxiety_58',
        'irritability_59',
        'irritabilityInformationFrom_60',
        'concentration_61',
        'concentrationInformationFrom_62',
        'sleep_63',
        'sleepInformationFrom_64',
        'physicalSymptomsAnxiety_66',
        'socialAnxiety_67',
        'socialAnxietyInformation_68',
        'worry_69',
        'worryInformationFrom_70',
        'agoraphobia_71',
        'agoraphobiaInformationFrom_72',
        'panicAttacks_73',
        'panicAttacksInformation_74',
        'ptsd_75',
        'traumaInformationFrom_76',
        'avoidanceInformationFrom_77',
        'ocd_82',
        'obsessiveThoughts_83',
        'obsessiveThoughtsInformation_84',
        'compulsiveBehaviors_85',
        'compulsiveBehaviorsInformation_86',
        'impulseControl_87',
        'impulseControlInformation_88',
        'eatingDisorders_89',
        'restrictingEating_90',
        'restrictingEatingInformation_91',
        'intenseFearGaining_92',
        'intenseFearGaining_93',
        'bingeEating_94',
        'bingeEatingInformation_95',
        'bulimia_96',
        'bulimiaInformationFrom_97',
        'history_99',
        'psychiatricHistory_100',
        'suicideAttempts_101',
        'suicideAttempts_102',
        'suicideAttempts_103',
        'selfInjury_104',
        'selfInjury_105',
        'selfInjury_106',
        'violenceHistory_107',
        'violenceHistory_108',
        'violenceHistory_109',
        'psychiatricHospitalizations_110',
        'psychiatricHospitalizations_111',
        'psychiatricHospitalizations_112',
        'previousPsychiatricDiagnoses_46',
        'previousPsychiatricDiagnoses_47',
        'pastPsychiatricMedications_115',
        'currentPreviousProviders_117',
        'socialHistory_119',
        'detailsAboutChildhood_120',
        'relationshipStatus_122',
        'relationshipStatus_123',
        'relationshipStatus_124',
        'relationshipStatus_125',
        'children_126',
        'livingSituation_127',
        'familySocialSupports_128',
        'considerSpiritualReligious_130',
        'considerSpiritualReligious_131',
        'education_132',
        'workingNow_134',
        'workingNow_135',
        'legalHistory_136',
        'currentlyInvolvedLegal_137',
        'currentlyInvolvedLegal_138',
        'accessGun_139',
        'additionalSocialHistoryDetails_139_1',
        'substanceUseHistory_140',
        'clientDrinks_142',
        'clientDrinks_141',
        'clientBinges_143',
        'clientUsesTobacco_144',
        'clientUsesPrescription_145',
        'clientUsesIllicit_146',
        'clientUsesCannabis_147',
        'additionalSubstanceUse_148',
        'sexualPhysicalAbuse_149',
        'additionalSocialHistory_150',
        'familyHistory_151',
        'historyPsychiatricEmotional_152',
        'historyPsychiatricEmotional_153',
        'additionalDetailsFrom_154',
        'medicalHistory_155',
        'chronicOngoingMedical_156',
        'medicalConditionsOther_47',
        'additionalMedicalIssues_157',
        'snoringGaspingBreath_158',
        'painLevel_159',
        'painLevel_160',
        'additionalDetailsFrom_161',
        'pregnancy_162',
        'currentMedicationsAllergies_163',
        'currentlyTakingPrescription_164',
        'currentMedications_165',
        'allergies_166',
        'sideEffectConcerns_167',
        'currentMentalStatus_168',
        'generalAppearance_169',
        'cognitiveFunctioning_170',
        'mood_171',
        'affect_172',
        'judgment_173',
        'functionalStatus_174',
        'otherNotableCurrent_175',
        'riskAssessmentManagement_176',
        'safetyRiskAssessment_177',
        'safetyRiskAssessment_178',
        'safetyRiskAssessment_179',
        'safetyIssuesIdentified_180',
        'suicidalIdeation_181',
        'homicidalIdeation_182',
        'otherSafetyIssues_183',
        'safetyIssuesIdentified_184',
        'safetyIssuesIdentified_185',
        'safetyIssuesIdentified_186',
        'safetyIssuesIdentified_187',
        'safetyIssuesIdentified_188',
        'safetyIssuesIdentified_189',
        'safetyIssuesIdentified_190',
        'safetyIssuesIdentified_191',
        'safetyIssuesIdentified_192',
        'safetyIssuesIdentified_193',
        'safetyIssuesIdentified_194',
        'safetyIssuesIdentified_195',
        'safetyIssuesIdentified_196',
        'safetyIssuesIdentified_197',
        'safetyIssuesIdentified_198',
        'safetyIssuesIdentified_199',
        'safetyIssuesIdentified_200',
        'safetyIssuesIdentified_201',
        'safetyIssuesIdentified_202',
        'safetyIssuesIdentified_203',
        'safetyIssuesIdentified_204',
        'safetyIssuesIdentified_205',
        'safetyIssuesIdentified_206',
        'safetyIssuesIdentified_207',
        'safetyIssuesIdentified_208',
        'safetyIssuesIdentified_209',
        'safetyIssuesIdentified_210',
        'safetyIssuesIdentified_211',
        'safetyIssuesIdentified_212',
        'safetyIssuesIdentified_213',
        'safetyIssuesIdentified_214',
        'safetyIssuesIdentified_215',
        'safetyIssuesIdentified_216',
        'safetyIssuesIdentified_217',
        'safetyIssuesIdentified_218',
        'safetyIssuesIdentified_219',
        'safetyIssuesIdentified_220',
        'safetyIssuesIdentified_221',
        'safetyIssuesIdentified_222',
        'safetyIssuesIdentified_223',
        'safetyIssuesIdentified_224',
        'safetyIssuesIdentified_225',
        'safetyIssuesIdentified_226',
        'safetyIssuesIdentified_227',
        'safetyIssuesIdentified_228',
        'safetyIssuesIdentified_229',
        'safetyIssuesIdentified_230',
        'safetyIssuesIdentified_231',
        'safetyIssuesIdentified_232',
        'safetyIssuesIdentified_233',
        'safetyIssuesIdentified_234',
        'safetyIssuesIdentified_235',
        'safetyIssuesIdentified_236',
        'safetyIssuesIdentified_237',
        'safetyIssuesIdentified_238',
        'safetyIssuesIdentified_239',
        'safetyIssuesIdentified_240',
        'safetyIssuesIdentified_241',
        'safetyIssuesIdentified_242',
        'safetyIssuesIdentified_243',
        'safetyIssuesIdentified_244',
        'safetyIssuesIdentified_245',
        'safetyIssuesIdentified_246',
        'safetyIssuesIdentified_247',
        'safetyIssuesIdentified_248',
        'safetyIssuesIdentified_249',
        'safetyIssuesIdentified_250',
        'safetyIssuesIdentified_251',
        'safetyIssuesIdentified_252',
        'safetyIssuesIdentified_253',
        'safetyIssuesIdentified_254',
        'safetyIssuesIdentified_255',
        'safetyIssuesIdentified_256',
        'safetyIssuesIdentified_257',
        'safetyIssuesIdentified_258',
        'safetyIssuesIdentified_259',
        'safetyIssuesIdentified_260',
        'safetyIssuesIdentified_261',
        'safetyIssuesIdentified_262',
        'safetyIssuesIdentified_263',
        'safetyIssuesIdentified_264',
        'safetyIssuesIdentified_265',
        'safetyIssuesIdentified_266',
        'safetyIssuesIdentified_267',
        'safetyIssuesIdentified_268',
        'safetyIssuesIdentified_269',
        'safetyIssuesIdentified_270',
        'safetyIssuesIdentified_271',
        'safetyIssuesIdentified_272',
        'safetyIssuesIdentified_273',
        'safetyIssuesIdentified_274',
        'safetyIssuesIdentified_275',
        'safetyIssuesIdentified_276',
        'safetyIssuesIdentified_277',
        'safetyIssuesIdentified_278',
        'safetyIssuesIdentified_279',
        'safetyIssuesIdentified_280',
        'safetyIssuesIdentified_281',
        'safetyIssuesIdentified_282',
        'safetyIssuesIdentified_283',
        'safetyIssuesIdentified_284',
        'safetyIssuesIdentified_285',
        'safetyIssuesIdentified_286',
        'safetyIssuesIdentified_287',
        'safetyIssuesIdentified_288',
        'labsImaging_289',
        'labsImaging_290',
        'assessments_291',
        'caseFormulation_296',
        'bioGeneticDevelopmental_298',
        'psychoCognitiveStyle_299',
        'socialRelationshipsFamily_300',
        'summary_301',
        'dsmDiagnosis_304',
        'primaryDiagnosis_305',
        'secondaryDiagnosis_308',
        'coOccurringSubstance_311',
        'differentialConsiderations_314',
        'otherDsmDiagnoses_315',
        'treatmentPlan_316',
        'treatmentGoals_303',
        'depression_317',
        'depression_318',
        'depression_319',
        'depression_320',
        'depression_321',
        'depression_322',
        'depression_323',
        'anxiety_324',
        'anxiety_325',
        'anxiety_326',
        'anxiety_327',
        'anxiety_328',
        'anxiety_329',
        'anxiety_330',
        'otherDiagnoses_331',
        'otherDiagnoses_332',
        'otherDiagnoses_333',
        'otherDiagnoses_334',
        'otherDiagnoses_335',
        'otherDiagnoses_336',
        'sleepIssues_337',
        'sleepIssues_338',
        'sleepIssues_339',
        'sleepIssues_340',
        'sleepIssues_341',
        'sleepIssues_342',
        'sleepIssues_343',
        'sleepIssues_344',
        'substanceUse_346',
        'recommendationsSubstances_347',
        'generalMedicalCare_348',
        'labs_349',
        'generalHealthInformation_350',
        'chronicMedicationConditions_351',
        'sawPrimaryCare_352',
        'therapy_354',
        'roi_355',
        'medicationReconciliationDone_356',
        'intakeSessionComponents_357',
        'obtainedConsentSession_358',
        'obtainedConsentTelehealth_359',
        'orientedClientOutcomes_360',
        'orientedClientReminder_361',
        'orientedClientMessenger_362',
        'noAboveWhy_363',
        'followUpInformation_364',
        'returnClinic_365',
        'returnClinic_366',
        'recommendedIntervalMedication_367',
        'noFollowUp_368',
      ],
      verbalInformedConsent_1: {
        'ui:widget': 'radio',
      },
      isBoosterSession: { 'ui:widget': 'radio' },
      identifyingInformation_3: {
        'ui:widget': 'textarea',
      },
      chiefComplaint_5: {
        'ui:widget': 'textarea',
      },
      hpi_8: {
        'ui:widget': 'textarea',
      },
      depressedMood_12: {
        'ui:widget': 'checkbox',
      },
      moodInformationFrom_13: {
        'ui:widget': 'text',
      },
      sleep_14: {
        'ui:widget': 'checkbox',
      },
      sleepInformationFrom_15: {
        'ui:widget': 'text',
      },
      interest_16: {
        'ui:widget': 'checkbox',
      },
      interestInformationFrom_17: {
        'ui:widget': 'text',
      },
      psychomotorAgitationRetardation_18: {
        'ui:widget': 'checkbox',
      },
      psychomotorAgitationRetardation_18_2: {
        'ui:widget': 'checkbox',
      },
      psychomotorAgitationRetardation_19: {
        'ui:widget': 'text',
      },
      guiltWorthlessness_20: {
        'ui:widget': 'checkbox',
      },
      guiltWorthlessness_20_2: {
        'ui:widget': 'checkbox',
      },
      guiltWorthlessnessInformation_21: {
        'ui:widget': 'text',
      },
      energy_22: {
        'ui:widget': 'checkbox',
      },
      energyInformationFrom_23: {
        'ui:widget': 'text',
      },
      concentration_24: {
        'ui:widget': 'checkbox',
      },
      concentrationInformationFrom_25: {
        'ui:widget': 'text',
      },
      appetite_26: {
        'ui:widget': 'checkbox',
      },
      appetite_26_2: {
        'ui:widget': 'checkbox',
      },
      appetiteInformationFrom_27: {
        'ui:widget': 'text',
      },
      si_28: {
        'ui:widget': 'checkbox',
      },
      siInformationFrom_29: {
        'ui:widget': 'text',
      },
      hi_30: {
        'ui:widget': 'checkbox',
      },
      hiInformationFrom_31: {
        'ui:widget': 'text',
      },
      mood_33: {
        'ui:widget': 'checkbox',
      },
      moodInformationFrom_34: {
        'ui:widget': 'text',
      },
      irritability_35: {
        'ui:widget': 'checkbox',
      },
      irritabilityInformationFrom_36: {
        'ui:widget': 'text',
      },
      noSleep_37: {
        'ui:widget': 'checkbox',
      },
      noSleepInformation_38: {
        'ui:widget': 'text',
      },
      speech_39: {
        'ui:widget': 'checkbox',
      },
      speechInformationFrom_40: {
        'ui:widget': 'text',
      },
      grandiosity_41: {
        'ui:widget': 'checkbox',
      },
      grandiosityInformationFrom_42: {
        'ui:widget': 'text',
      },
      racingThoughts_43: {
        'ui:widget': 'checkbox',
      },
      racingThoughtsInformation_44: {
        'ui:widget': 'text',
      },
      goalOrientedRisky_45: {
        'ui:widget': 'checkbox',
      },
      goalOrientedRisky_46: {
        'ui:widget': 'text',
      },
      distractibilityConcentration_47: {
        'ui:widget': 'checkbox',
      },
      distractibilityConcentrationInformation_48: {
        'ui:widget': 'text',
      },
      ah_50: {
        'ui:widget': 'checkbox',
      },
      ahInformationFrom_51: {
        'ui:widget': 'text',
      },
      vh_52: {
        'ui:widget': 'checkbox',
      },
      vhInformationFrom_53: {
        'ui:widget': 'text',
      },
      paranoia_54: {
        'ui:widget': 'checkbox',
      },
      paranoiaInformationFrom_55: {
        'ui:widget': 'text',
      },
      delusionsInformationFrom_57: {
        'ui:widget': 'text',
      },
      irritability_59: {
        'ui:widget': 'checkbox',
      },
      irritabilityInformationFrom_60: {
        'ui:widget': 'text',
      },
      concentration_61: {
        'ui:widget': 'checkbox',
      },
      concentrationInformationFrom_62: {
        'ui:widget': 'text',
      },
      sleep_63: {
        'ui:widget': 'checkbox',
      },
      sleepInformationFrom_64: {
        'ui:widget': 'text',
      },
      physicalSymptomsAnxiety_66: {
        'ui:widget': 'text',
      },
      socialAnxiety_67: {
        'ui:widget': 'checkbox',
      },
      socialAnxietyInformation_68: {
        'ui:widget': 'text',
      },
      worry_69: {
        'ui:widget': 'checkbox',
      },
      worryInformationFrom_70: {
        'ui:widget': 'text',
      },
      agoraphobia_71: {
        'ui:widget': 'checkbox',
      },
      agoraphobiaInformationFrom_72: {
        'ui:widget': 'text',
      },
      panicAttacks_73: {
        'ui:widget': 'checkbox',
      },
      panicAttacksInformation_74: {
        'ui:widget': 'text',
      },
      traumaInformationFrom_76: {
        'ui:widget': 'textarea',
      },
      avoidanceInformationFrom_77: {
        'ui:widget': 'text',
      },
      obsessiveThoughts_83: {
        'ui:widget': 'checkbox',
      },
      obsessiveThoughtsInformation_84: {
        'ui:widget': 'text',
      },
      compulsiveBehaviors_85: {
        'ui:widget': 'checkbox',
      },
      compulsiveBehaviorsInformation_86: {
        'ui:widget': 'text',
      },
      impulseControl_87: {
        'ui:widget': 'checkbox',
      },
      impulseControlInformation_88: {
        'ui:widget': 'text',
      },
      restrictingEating_90: {
        'ui:widget': 'checkbox',
      },
      restrictingEatingInformation_91: {
        'ui:widget': 'text',
      },
      intenseFearGaining_92: {
        'ui:widget': 'checkbox',
      },
      intenseFearGaining_93: {
        'ui:widget': 'text',
      },
      bingeEating_94: {
        'ui:widget': 'checkbox',
      },
      bingeEatingInformation_95: {
        'ui:widget': 'text',
      },
      bulimia_96: {
        'ui:widget': 'checkbox',
      },
      bulimiaInformationFrom_97: {
        'ui:widget': 'text',
      },
      suicideAttempts_101: {
        'ui:widget': 'radio',
      },
      suicideAttempts_102: {
        'ui:widget': 'text',
      },
      suicideAttempts_103: {
        'ui:widget': 'textarea',
      },
      selfInjury_104: {
        'ui:widget': 'radio',
      },
      selfInjury_105: {
        'ui:widget': 'text',
      },
      selfInjury_106: {
        'ui:widget': 'textarea',
      },
      violenceHistory_107: {
        'ui:widget': 'radio',
      },
      violenceHistory_108: {
        'ui:widget': 'text',
      },
      violenceHistory_109: {
        'ui:widget': 'textarea',
      },
      psychiatricHospitalizations_110: {
        'ui:widget': 'radio',
      },
      psychiatricHospitalizations_111: {
        'ui:widget': 'text',
      },
      psychiatricHospitalizations_112: {
        'ui:widget': 'textarea',
      },
      previousDiagnoses_113: {
        'ui:widget': 'textarea',
      },
      pastPsychiatricMedications_115: {
        'ui:widget': 'textarea',
      },
      currentPreviousProviders_117: {
        'ui:widget': 'textarea',
      },
      detailsAboutChildhood_120: {
        'ui:widget': 'textarea',
      },
      relationshipStatus_122: {
        'ui:widget': 'select',
      },
      relationshipStatus_123: {
        'ui:widget': 'text',
      },
      relationshipStatus_124: {
        'ui:widget': 'text',
      },
      relationshipStatus_125: {
        'ui:widget': 'textarea',
      },
      children_126: {
        'ui:widget': 'textarea',
      },
      livingSituation_127: {
        'ui:widget': 'textarea',
      },
      familySocialSupports_128: {
        'ui:widget': 'textarea',
      },
      considerSpiritualReligious_130: {
        'ui:widget': 'radio',
      },
      considerSpiritualReligious_131: {
        'ui:widget': 'textarea',
      },
      education_132: {
        'ui:widget': 'textarea',
      },
      workingNow_134: {
        'ui:widget': 'radio',
      },
      workingNow_135: {
        'ui:widget': 'textarea',
      },
      currentlyInvolvedLegal_137: {
        'ui:widget': 'radio',
      },
      currentlyInvolvedLegal_138: {
        'ui:widget': 'textarea',
      },
      accessGun_139: {
        'ui:widget': 'radio',
      },
      additionalSocialHistoryDetails_139_1: {
        'ui:widget': 'textarea',
      },
      clientDrinks_141: {
        'ui:widget': 'select',
      },
      clientDrinks_142: {
        'ui:widget': 'select',
      },
      clientBinges_143: {
        'ui:widget': 'select',
      },
      clientUsesTobacco_144: {
        'ui:widget': 'select',
      },
      clientUsesPrescription_145: {
        'ui:widget': 'select',
      },
      clientUsesIllicit_146: {
        'ui:widget': 'select',
      },
      clientUsesCannabis_147: {
        'ui:widget': 'select',
      },
      additionalSubstanceUse_148: {
        'ui:widget': 'textarea',
      },
      sexualPhysicalAbuse_149: {
        'ui:widget': 'textarea',
      },
      additionalSocialHistory_150: {
        'ui:widget': 'textarea',
      },
      historyPsychiatricEmotional_152: {
        'ui:widget': 'radio',
      },
      historyPsychiatricEmotional_153: {
        'ui:widget': 'textarea',
      },
      additionalDetailsFrom_154: {
        'ui:widget': 'textarea',
      },
      chronicOngoingMedical_156: {
        'ui:widget': 'checkbox',
      },
      additionalMedicalIssues_157: {
        'ui:widget': 'checkbox',
      },
      snoringGaspingBreath_158: {
        'ui:widget': 'text',
      },
      painLevel_159: {
        'ui:widget': 'range',
      },
      painLevel_160: {
        'ui:widget': 'text',
      },
      additionalDetailsFrom_161: {
        'ui:widget': 'textarea',
      },
      pregnancy_162: {
        'ui:widget': 'textarea',
      },
      currentlyTakingPrescription_164: {
        'ui:widget': 'radio',
      },
      currentMedications_165: {
        'ui:widget': 'textarea',
      },
      allergies_166: {
        'ui:widget': 'text',
      },
      sideEffectConcerns_167: {
        'ui:widget': 'textarea',
      },
      generalAppearance_169: {
        'ui:widget': 'typeAhead',
      },
      cognitiveFunctioning_170: {
        'ui:widget': 'typeAhead',
      },
      mood_171: {
        'ui:widget': 'typeAhead',
      },
      affect_172: {
        'ui:widget': 'typeAhead',
      },
      judgment_173: {
        'ui:widget': 'typeAhead',
      },
      functionalStatus_174: {
        'ui:widget': 'typeAhead',
      },
      otherNotableCurrent_175: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_177: {
        'ui:widget': 'radio',
      },
      safetyRiskAssessment_178: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_179: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_180: {
        'ui:widget': 'radio',
      },
      suicidalIdeation_181: {
        'ui:widget': 'select',
      },
      homicidalIdeation_182: {
        'ui:widget': 'select',
      },
      otherSafetyIssues_183: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_187: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_188: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_189: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_190: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_191: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_192: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_193: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_194: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_195: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_196: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_197: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_198: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_199: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_200: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_201: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_202: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_203: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_204: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_205: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_206: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_207: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_208: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_209: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_210: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_211: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_212: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_213: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_214: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_216: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_217: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_218: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_219: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_220: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_221: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_222: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_223: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_224: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_225: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_227: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_228: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_229: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_230: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_231: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_232: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_233: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_234: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_235: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_236: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_237: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_238: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_239: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_240: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_241: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_242: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_243: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_244: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_245: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_246: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_247: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_248: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_249: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_250: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_251: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_252: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_253: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_254: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_255: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_256: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_257: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_258: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_261: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_262: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_263: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_264: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_265: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_267: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_268: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_269: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_272: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_276: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_277: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_278: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_279: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_280: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_281: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_282: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_283: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_284: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_285: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_286: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_287: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_288: {
        'ui:widget': 'textarea',
      },
      labsImaging_290: {
        'ui:widget': 'textarea',
      },
      assessments_291: {
        'ui:widget': 'textarea',
      },
      bioGeneticDevelopmental_298: {
        'ui:widget': 'textarea',
      },
      psychoCognitiveStyle_299: {
        'ui:widget': 'textarea',
      },
      socialRelationshipsFamily_300: {
        'ui:widget': 'textarea',
      },
      summary_301: {
        'ui:widget': 'textarea',
      },
      treatmentGoals_303: {
        'ui:widget': 'textarea',
      },
      primaryDiagnosis_305: {
        'ui:widget': 'typeAhead',
      },
      secondaryDiagnosis_308: {
        'ui:widget': 'typeAhead',
      },
      coOccurringSubstance_311: {
        'ui:widget': 'select',
      },
      differentialConsiderations_314: {
        'ui:widget': 'textarea',
      },
      otherDsmDiagnoses_315: {
        'ui:widget': 'textarea',
      },
      depression_317: {
        'ui:widget': 'checkbox',
      },
      depression_318: {
        'ui:widget': 'textarea',
      },
      depression_319: {
        'ui:widget': 'textarea',
      },
      depression_320: {
        'ui:widget': 'radio',
      },
      depression_321: {
        'ui:widget': 'radio',
      },
      depression_322: {
        'ui:widget': 'textarea',
      },
      depression_323: {
        'ui:widget': 'textarea',
      },
      anxiety_324: {
        'ui:widget': 'checkbox',
      },
      anxiety_325: {
        'ui:widget': 'textarea',
      },
      anxiety_326: {
        'ui:widget': 'textarea',
      },
      anxiety_327: {
        'ui:widget': 'radio',
      },
      anxiety_328: {
        'ui:widget': 'radio',
      },
      anxiety_329: {
        'ui:widget': 'textarea',
      },
      anxiety_330: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_331: {
        'ui:widget': 'checkbox',
      },
      otherDiagnoses_332: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_333: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_334: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_335: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_336: {
        'ui:widget': 'textarea',
      },
      sleepIssues_337: {
        'ui:widget': 'checkbox',
      },
      sleepIssues_338: {
        'ui:widget': 'textarea',
      },
      sleepIssues_339: {
        'ui:widget': 'textarea',
      },
      sleepIssues_340: {
        'ui:widget': 'radio',
      },
      sleepIssues_341: {
        'ui:widget': 'radio',
      },
      sleepIssues_342: {
        'ui:widget': 'textarea',
      },
      sleepIssues_344: {
        'ui:widget': 'textarea',
      },
      substanceUse_346: {
        'ui:widget': 'textarea',
      },
      recommendationsSubstances_347: {
        'ui:widget': 'textarea',
      },
      labs_349: {
        'ui:widget': 'textarea',
      },
      generalHealthInformation_350: {
        'ui:widget': 'textarea',
      },
      chronicMedicationConditions_351: {
        'ui:widget': 'textarea',
      },
      sawPrimaryCare_352: {
        'ui:widget': 'textarea',
      },
      therapy_354: {
        'ui:widget': 'textarea',
      },
      roi_355: {
        'ui:widget': 'textarea',
      },
      medicationReconciliationDone_356: {
        'ui:widget': 'radio',
      },
      obtainedConsentSession_358: {
        'ui:widget': 'checkbox',
      },
      obtainedConsentTelehealth_359: {
        'ui:widget': 'checkbox',
      },
      orientedClientOutcomes_360: {
        'ui:widget': 'checkbox',
      },
      orientedClientReminder_361: {
        'ui:widget': 'checkbox',
      },
      orientedClientMessenger_362: {
        'ui:widget': 'checkbox',
      },
      noAboveWhy_363: {
        'ui:widget': 'textarea',
      },
      returnClinic_365: {
        'ui:widget': 'checkbox',
      },
      returnClinic_366: {
        'ui:widget': 'text',
      },
      noFollowUp_368: {
        'ui:widget': 'checkbox',
      },
    },
    initialValues: {
      identifyingInformation_3:
        'The patient is a XX year old, EMPLOYMENT status, RELATIONSHIP status GENDER presenting for a medication consultation.',
      additionalSocialHistoryDetails_139_1: 'Reviewed details from intake. Other details if any include:',
      additionalSocialHistory_150: 'Reviewed details from intake. Other details if any include:',
      additionalDetailsFrom_154: 'Reviewed details from intake. Other details if any include:',
      painLevel_159: 0,
      additionalDetailsFrom_161: 'Reviewed details from intake. Other details if any include:',
      treatmentGoals_303: '1.\n2.\n3.\n',
      depression_319:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      depression_318: 'The patient scored an XX on PHQ9 most recently. They report the following clinical changes:',
      depression_322:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      anxiety_325: 'The patient scored a XX on GAD7 most recently. They report the following clinical changes:',
      anxiety_326:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      depression_329:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      otherDiagnoses_332: 'The patient reports',
      otherDiagnoses_333:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      substanceUse_346:
        '-Level of Alcohol consumption:\n-Does the patient use tobacco/nicotine products?\n-Does the patient use any other substances?',
      labs_349: 'Labs ordered MM/YY\nMost recent labs of interest:\n    TSH\n    Hemoglobin\n    Hematocrit\n    B12',
      generalHealthInformation_350: 'See H&P, no acute issues identified today',
      therapy_354: 'Patient is in therapy with',
      roi_355: 'Verbal permission obtained and Release of Information requests sent for:',
    },
  },
  medsSessionNote: {
    uiSchema: {
      'ui:order': [
        'verbalInformedConsent_1',
        'isBoosterSession',
        'identifyingInformation_2',
        'identifyingInformation_3',
        'visitDate_4',
        'chiefComplaint_5',
        'ccFromIntake_6',
        'ccFromVisit_7',
        'intervalHistory_8',
        'intervalHistory_9',
        'medicationSideEffects_10',
        'psychiatricReviewSystems_11',
        'whichCommonSymptoms_12',
        'otherRosInformation_13',
        'updatesPsychiatricSocial_14',
        'currentMedicationsAllergies_15',
        'currentMedications_16',
        'allergies_17',
        'currentMentalStatus_18',
        'generalAppearance_19',
        'cognitiveFunctioning_20',
        'mood_21',
        'affect_22',
        'judgment_23',
        'functionalStatus_24',
        'otherNotableCurrent_25',
        'riskAssessmentManagement_26',
        'safetyRiskAssessment_27',
        'safetyRiskAssessment_28',
        'safetyRiskAssessment_29',
        'safetyIssuesIdentified_30',
        'suicidalIdeation_31',
        'homicidalIdeation_32',
        'otherSafetyIssues_33',
        'safetyIssuesIdentified_34',
        'detailedRiskAssessment_35',
        'safetyIssuesIdentified_36',
        'safetyIssuesIdentified_37',
        'safetyIssuesIdentified_38',
        'safetyIssuesIdentified_39',
        'safetyIssuesIdentified_40',
        'safetyIssuesIdentified_41',
        'safetyIssuesIdentified_42',
        'safetyIssuesIdentified_43',
        'safetyIssuesIdentified_44',
        'safetyIssuesIdentified_45',
        'safetyIssuesIdentified_46',
        'safetyIssuesIdentified_47',
        'safetyIssuesIdentified_48',
        'safetyIssuesIdentified_49',
        'safetyIssuesIdentified_50',
        'safetyIssuesIdentified_51',
        'safetyIssuesIdentified_52',
        'safetyIssuesIdentified_53',
        'safetyIssuesIdentified_54',
        'safetyIssuesIdentified_55',
        'safetyIssuesIdentified_56',
        'safetyIssuesIdentified_57',
        'safetyIssuesIdentified_58',
        'safetyIssuesIdentified_59',
        'safetyIssuesIdentified_60',
        'safetyIssuesIdentified_61',
        'safetyIssuesIdentified_62',
        'safetyIssuesIdentified_63',
        'safetyIssuesIdentified_64',
        'safetyIssuesIdentified_65',
        'safetyIssuesIdentified_66',
        'safetyIssuesIdentified_67',
        'safetyIssuesIdentified_68',
        'safetyIssuesIdentified_69',
        'safetyIssuesIdentified_70',
        'safetyIssuesIdentified_71',
        'safetyIssuesIdentified_72',
        'safetyIssuesIdentified_73',
        'safetyIssuesIdentified_74',
        'safetyIssuesIdentified_75',
        'safetyIssuesIdentified_76',
        'safetyIssuesIdentified_77',
        'safetyIssuesIdentified_78',
        'safetyIssuesIdentified_79',
        'safetyIssuesIdentified_80',
        'safetyIssuesIdentified_81',
        'safetyIssuesIdentified_82',
        'safetyIssuesIdentified_83',
        'safetyIssuesIdentified_84',
        'safetyIssuesIdentified_85',
        'safetyIssuesIdentified_86',
        'safetyIssuesIdentified_87',
        'safetyIssuesIdentified_88',
        'safetyIssuesIdentified_89',
        'safetyIssuesIdentified_90',
        'safetyIssuesIdentified_91',
        'safetyIssuesIdentified_92',
        'safetyIssuesIdentified_93',
        'safetyIssuesIdentified_94',
        'safetyIssuesIdentified_95',
        'safetyIssuesIdentified_96',
        'safetyIssuesIdentified_97',
        'safetyIssuesIdentified_98',
        'safetyIssuesIdentified_99',
        'safetyIssuesIdentified_100',
        'safetyIssuesIdentified_101',
        'safetyIssuesIdentified_102',
        'safetyIssuesIdentified_103',
        'safetyIssuesIdentified_104',
        'safetyIssuesIdentified_105',
        'safetyIssuesIdentified_106',
        'safetyIssuesIdentified_107',
        'safetyIssuesIdentified_108',
        'safetyIssuesIdentified_109',
        'safetyIssuesIdentified_110',
        'safetyIssuesIdentified_111',
        'safetyIssuesIdentified_112',
        'safetyIssuesIdentified_113',
        'safetyIssuesIdentified_114',
        'safetyIssuesIdentified_115',
        'safetyIssuesIdentified_116',
        'safetyIssuesIdentified_117',
        'safetyIssuesIdentified_118',
        'safetyIssuesIdentified_119',
        'safetyIssuesIdentified_120',
        'safetyIssuesIdentified_121',
        'safetyIssuesIdentified_122',
        'safetyIssuesIdentified_123',
        'safetyIssuesIdentified_124',
        'safetyIssuesIdentified_125',
        'safetyIssuesIdentified_126',
        'safetyIssuesIdentified_127',
        'safetyIssuesIdentified_128',
        'safetyIssuesIdentified_129',
        'safetyIssuesIdentified_130',
        'safetyIssuesIdentified_131',
        'safetyIssuesIdentified_132',
        'safetyIssuesIdentified_133',
        'safetyIssuesIdentified_134',
        'safetyIssuesIdentified_135',
        'safetyIssuesIdentified_136',
        'safetyIssuesIdentified_137',
        'safetyIssuesIdentified_138',
        'labsImaging_139',
        'labsImaging_140',
        'assessments_141',
        'phq9Gad7Pcl_142',
        'caseFormulation_143',
        'bio_145',
        'psycho_146',
        'social_147',
        'summary_148',
        'dsmDiagnosis_152',
        'primaryDiagnosis_153',
        'secondaryDiagnosis_154',
        'coOccurringSubstance_155',
        'differentialConsiderations_156',
        'otherDsmDiagnoses_157',
        'treatmentGoals_150',
        'treatmentGoals_151',
        'depression_159',
        'depression_160',
        'depression_161',
        'depression_162',
        'depression_163',
        'depression_164',
        'depression_165',
        'anxiety_166',
        'anxiety_167',
        'anxiety_168',
        'anxiety_169',
        'anxiety_170',
        'anxiety_171',
        'anxiety_172',
        'otherDiagnoses_173',
        'otherDiagnoses_174',
        'otherDiagnoses_175',
        'otherDiagnoses_176',
        'otherDiagnoses_177',
        'otherDiagnoses_178',
        'sleepIssues_179',
        'sleepIssues_180',
        'sleepIssues_181',
        'sleepIssues_182',
        'sleepIssues_183',
        'sleepIssues_184',
        'sleepIssues_185',
        'sleepIssues_186',
        'substanceUse_187',
        'currentLevelsUse_188',
        'recommendationsSubstances_189',
        'generalMedicalCare_190',
        'labs_191',
        'acuteMedicalConditions_192',
        'chronicMedicationConditions_193',
        'sawPrimaryCare_194',
        'therapy_196',
        'roi_197',
        'followUpInformation_198',
        'returnClinic_199',
        'returnClinic_200',
        'recommendedIntervalMedication_201',
        'noFollowUp_202',
      ],
      verbalInformedConsent_1: {
        'ui:widget': 'radio',
      },
      isBoosterSession: { 'ui:widget': 'radio' },
      identifyingInformation_3: {
        'ui:widget': 'textarea',
      },
      visitDate_4: {
        'ui:widget': 'text',
      },
      ccFromIntake_6: {
        'ui:widget': 'textarea',
      },
      ccFromVisit_7: {
        'ui:widget': 'textarea',
      },
      intervalHistory_9: {
        'ui:widget': 'textarea',
      },
      medicationSideEffects_10: {
        'ui:widget': 'textarea',
      },
      whichCommonSymptoms_12: {
        'ui:widget': 'checkbox',
      },
      otherRosInformation_13: {
        'ui:widget': 'textarea',
      },
      updatesPsychiatricSocial_14: {
        'ui:widget': 'textarea',
      },
      currentMedications_16: {
        'ui:widget': 'textarea',
      },
      allergies_17: {
        'ui:widget': 'text',
      },
      generalAppearance_19: {
        'ui:widget': 'select',
      },
      cognitiveFunctioning_20: {
        'ui:widget': 'select',
      },
      mood_21: {
        'ui:widget': 'select',
      },
      affect_22: {
        'ui:widget': 'select',
      },
      judgment_23: {
        'ui:widget': 'select',
      },
      functionalStatus_24: {
        'ui:widget': 'select',
      },
      otherNotableCurrent_25: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_27: {
        'ui:widget': 'radio',
      },
      safetyRiskAssessment_28: {
        'ui:widget': 'textarea',
      },
      safetyRiskAssessment_29: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_30: {
        'ui:widget': 'radio',
      },
      suicidalIdeation_31: {
        'ui:widget': 'select',
      },
      homicidalIdeation_32: {
        'ui:widget': 'select',
      },
      otherSafetyIssues_33: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_37: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_38: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_39: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_40: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_41: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_42: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_43: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_44: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_45: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_46: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_47: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_48: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_49: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_50: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_51: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_52: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_53: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_54: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_55: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_56: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_57: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_58: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_59: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_60: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_61: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_62: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_63: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_64: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_66: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_67: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_68: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_69: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_70: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_71: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_72: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_73: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_74: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_75: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_77: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_78: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_79: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_80: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_81: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_82: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_83: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_84: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_85: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_86: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_87: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_88: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_89: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_90: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_91: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_92: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_93: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_94: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_95: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_96: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_97: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_98: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_99: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_100: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_101: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_102: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_103: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_104: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_105: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_106: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_107: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_108: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_111: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_112: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_113: {
        'ui:widget': 'typeAhead',
      },
      safetyIssuesIdentified_114: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_115: {
        'ui:widget': 'text',
      },
      safetyIssuesIdentified_117: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_118: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_119: {
        'ui:widget': 'textarea',
      },
      safetyIssuesIdentified_122: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_126: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_127: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_128: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_129: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_130: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_131: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_132: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_133: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_134: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_135: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_136: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_137: {
        'ui:widget': 'checkbox',
      },
      safetyIssuesIdentified_138: {
        'ui:widget': 'textarea',
      },
      labsImaging_140: {
        'ui:widget': 'textarea',
      },
      phq9Gad7Pcl_142: {
        'ui:widget': 'textarea',
      },
      bio_145: {
        'ui:widget': 'textarea',
      },
      psycho_146: {
        'ui:widget': 'textarea',
      },
      social_147: {
        'ui:widget': 'textarea',
      },
      summary_148: {
        'ui:widget': 'textarea',
      },
      treatmentGoals_151: {
        'ui:widget': 'textarea',
      },
      primaryDiagnosis_153: {
        'ui:widget': 'typeAhead',
      },
      secondaryDiagnosis_154: {
        'ui:widget': 'typeAhead',
      },
      coOccurringSubstance_155: {
        'ui:widget': 'select',
      },
      differentialConsiderations_156: {
        'ui:widget': 'textarea',
      },
      otherDsmDiagnoses_157: {
        'ui:widget': 'textarea',
      },
      depression_159: {
        'ui:widget': 'checkbox',
      },
      depression_160: {
        'ui:widget': 'textarea',
      },
      depression_161: {
        'ui:widget': 'textarea',
      },
      depression_162: {
        'ui:widget': 'radio',
      },
      depression_163: {
        'ui:widget': 'radio',
      },
      depression_164: {
        'ui:widget': 'textarea',
      },
      depression_165: {
        'ui:widget': 'textarea',
      },
      anxiety_166: {
        'ui:widget': 'checkbox',
      },
      anxiety_167: {
        'ui:widget': 'textarea',
      },
      anxiety_168: {
        'ui:widget': 'textarea',
      },
      anxiety_169: {
        'ui:widget': 'radio',
      },
      anxiety_170: {
        'ui:widget': 'radio',
      },
      anxiety_171: {
        'ui:widget': 'textarea',
      },
      anxiety_172: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_173: {
        'ui:widget': 'checkbox',
      },
      otherDiagnoses_174: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_175: {
        'ui:widget': 'textarea',
      },
      otherDiagnoses_176: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_177: {
        'ui:widget': 'radio',
      },
      otherDiagnoses_178: {
        'ui:widget': 'textarea',
      },
      sleepIssues_179: {
        'ui:widget': 'checkbox',
      },
      sleepIssues_180: {
        'ui:widget': 'textarea',
      },
      sleepIssues_181: {
        'ui:widget': 'textarea',
      },
      sleepIssues_182: {
        'ui:widget': 'radio',
      },
      sleepIssues_183: {
        'ui:widget': 'radio',
      },
      sleepIssues_184: {
        'ui:widget': 'textarea',
      },
      sleepIssues_186: {
        'ui:widget': 'textarea',
      },
      currentLevelsUse_188: {
        'ui:widget': 'textarea',
      },
      recommendationsSubstances_189: {
        'ui:widget': 'textarea',
      },
      labs_191: {
        'ui:widget': 'textarea',
      },
      acuteMedicalConditions_192: {
        'ui:widget': 'textarea',
      },
      chronicMedicationConditions_193: {
        'ui:widget': 'textarea',
      },
      sawPrimaryCare_194: {
        'ui:widget': 'textarea',
      },
      therapy_196: {
        'ui:widget': 'textarea',
      },
      roi_197: {
        'ui:widget': 'textarea',
      },
      returnClinic_199: {
        'ui:widget': 'checkbox',
      },
      returnClinic_200: {
        'ui:widget': 'text',
      },
      noFollowUp_202: {
        'ui:widget': 'checkbox',
      },
    },
    initialValues: {
      depression_160: 'The patient scored an XX on PHQ9 most recently. They report the following clinical changes:',
      depression_161:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      anxiety_167: 'The patient scored a XX on GAD7 most recently. They report the following clinical changes:',
      anxiety_171:
        'Non-pharmacological treatment strategies may include group or individual programming may include CBT, DBT, ACT, community reinforcement approach, motivational enhancement therapy, behavioral activation (daily structure), regular sleep and meals, and exercise as appropriate',
      otherDiagnoses_175:
        'Current medication dose and changes:\nDate of medication initiation:\nList of historical dose adjustments:',
      sleepIssues_180: 'The patient reports:',
      currentLevelsUse_188:
        '-Level of Alcohol consumption:\n-Does the patient use tobacco/nicotine products?\n-Does the patient use any other substances?',
    },
  },
}

export const careConsents = [
  'audCareConsent',
  'bccCareConsent',
  'bccCareConsentNonUS',
  'bcmCareConsent',
  'bctCareConsent',
  'cleCareConsent',
  'sscCareConsent',
  'lcttCareConsent',
]

const stateRegulationsContent = `
###### Alaska 
###### What You Should Know
You understand that your primary care provider may obtain a copy of your records of your telehealth encounter.

This document is intended to provide you with all of the information is required by the Board of Professional Counselors which regulates all licensed professional counselors. You may contact the Board with any questions or concerns.
###### Relevant Board Contact Information
Board of Professional Counselors

Division of Corporations, Business & Professional Licensing

P.O. Box 110806 Juneau, AK 99811-0806 Phone: (907) 465-2551

Email: ProfessionalCounselors@Alaska.Gov

###### Arizona 
###### What You Should Know
You are entitled to all existing confidentiality protections, including where a provider may only disclose all or part of your medical record and payment record as authorized by state or federal law or written authorization signed by you or your health care decision maker, pursuant to A.R.S. § 12-2292.  You also understand all medical reports resulting from the telemedicine consultation are part of your medical record as defined in A.R.S. § 12-2291. You also understand dissemination of any images or information identifiable to you for research or educational purposes shall not occur without your consent, unless authorized by state or federal law.  [Ariz. Rev. Stat. Ann. § 36-3602(D).](https://www.azleg.gov/ars/36/03602.htm)
###### Relevant Board Contact Information
Board of Behavioral Health Examiners

1740 West Adams Street, #3600

Phoenix, AZ 85007

Main Number: 602-542-1882

Fax Number: 602-364-0890

information@azbbhe.us

###### Colorado 
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to you, you may contact a board agency to assist you. You are entitled to the consent requirements outlined under 2 CO ADC 502-1:21.170.4. The confidentiality of your individual records, including all medical, mental health, substance use, psychological, and demographic information shall be protected with the applicable state and federal laws and regulations, as provided under 2 CO ADC 502-1:21.170.2.
###### Relevant Board Contact Information
State Board of Licensed Professional Counselor Examiners, State Board of Social Work Examiners, State Board of Marriage and Family Therapist Examiners, State Board of Addiction Counselor Examiners, and State Board of Psychologist Examiners

1560 Broadway, Suite 1350, Denver, Colorado 80202, (303) 894-7800

Email: DORA_Customercare@state.co.us

###### Connecticut 
###### What You Should Know
You understand that each telehealth provider shall, at the time of the initial telehealth interaction, ask you whether you consent to that provider’s disclosure of records concerting the telehealth interaction to your primary care provider. You further understand that your primary care provider may obtain a copy of your records of your telehealth encounter, upon your consent. [Conn. Gen. Stat. Ann. § 19a-906(d).](https://www.cga.ct.gov/current/pub/chap_368ll.htm#sec_19a-906)
###### Relevant Board Contact Information
Connecticut Department of Public Health

Professional Counselor Licensure

410 Capitol Ave., MS #12 APP

P.O. Box 340308

Hartford, CT 06134

Phone: (860) 509-7603

Fax: (860) 707-1980

Email:  dph.counselorsteam@ct.gov

###### Connecticut 
###### What You Should Know
You understand that each telehealth provider shall, at the time of the initial telehealth interaction, ask you whether you consent to that provider’s disclosure of records concerting the telehealth interaction to your primary care provider. You further understand that your primary care provider may obtain a copy of your records of your telehealth encounter, upon your consent. [Conn. Gen. Stat. Ann. § 19a-906(d).](https://www.cga.ct.gov/current/pub/chap_368ll.htm#sec_19a-906)
###### Relevant Board Contact Information
Connecticut Department of Public Health

Professional Counselor Licensure

410 Capitol Ave., MS #12 APP

P.O. Box 340308

Hartford, CT 06134

Phone: (860) 509-7603

Fax: (860) 707-1980

Email:  dph.counselorsteam@ct.gov

###### District of Columbia 
###### What You Should Know
You have been informed of alternate forms of communication between you and a physician for urgent matters.  [D.C. Mun. Regs. tit. 17, § 4618.10.](https://www.dcregs.dc.gov/Common/DCMR/SectionList.aspx?SectionNumber=17-4618)  Relevant communications with the physician, including those done via electronic methods shall be documented and filed in your medical record. [D.C. Mun. Regs. tit. 17, § 4618.9.](https://www.dcregs.dc.gov/Common/DCMR/SectionList.aspx?SectionNumber=17-4618)
###### Relevant Board Contact Information
Professional Counseling Licensing

899 North Capitol Street, NE, Washington, DC 20002

Phone: (202) 442-5955

Fax: (202) 442-4795

Department of Health Board of Medicine

899 North Capitol Street, NE

Washington DC, 20002

Email: doh@dc.gov

###### Georgia
###### What You Should Know
You have been given clear, appropriate, accurate instructions on follow-up in the event of needed emergent care related to the treatment. [Ga. Comp. R. & Regs. 360-3-.07(a)(7).](https://medicalboard.georgia.gov/sites/medicalboard.georgia.gov/files/related_files/site_page/Adopted%20Telemedicine%20Rule.pdf)
###### Relevant Board Contact Information
Georgia Composite Medical Board

2 Peachtree Street, NW

6th Floor

Atlanta, GA 30303-3465

Email: medbd@dch.ga.gov

###### Idaho
###### What You Should Know
If you need to register a formal complaint about a physician, you may visit the medical board’s website, [here.](https://elitepublic.bom.idaho.gov/IBOMPortal/Home.aspx)  You further understand that your informed consent for the use of telehealth services shall be obtained by applicable law. [Idaho Statutes 54-5708.](https://legislature.idaho.gov/statutesrules/idstat/title54/t54ch57/sect54-5708/#:~:text=Idaho%20Statutes&text=54%2D5708.,required%20by%20any%20applicable%20law.)
###### Relevant Board Contact Information
Board of Medicine: Logger Creek Plaza

345 Bobwhite Ct., Suite 150

Boise, ID 83706

info@bom.idaho.gov

Division of Professional Licenses: 11351 W. Chinden Blvd., Bldg. #6

Boise, ID 83714

###### Illinois
###### What You Should Know
If you need to register a formal complaint about a professional regulated under the Illinois Division of Professional Regulation, you may file a formal complaint here: [https://www.idfpr.com/admin/DPR/DPRcomplaint.asp](https://idfpr.illinois.gov/admin/DPR/DPRcomplaint.asp)
###### Relevant Board Contact Information
Chicago: 555 West Monroe St., 5th Floor Chicago, IL 60661

Springfield: 320 W. Washington Street, 3rd Floor, Springfield IL

Phone: 1 (888) 473-4858

###### Indiana
###### What You Should Know
If you have a concern or complaint about the non-physician mental health professionals providing care to you, you may contact a board agency to assist you. You may file a complaint here: [https://www.in.gov/pla/file-a-complaint/](https://www.in.gov/pla/file-a-complaint/)

If you want to register a formal complaint about a physician, you should visit the medical board’s website, here:

[https://www.in.gov/attorneygeneral/2434.htm](https://www.in.gov/attorneygeneral/2434.htm)
###### Relevant Board Contact Information
Indiana Professional Licensing Agency

402 W. Washington St., Room W072

Indianapolis, Indiana 46204

Staff Phone Number: (317) 234-2054

Staff E-mail: pla8@pla.IN.gov

Agency Fax: (317) 233-4236

###### Iowa
###### What You Should Know
If you have a concern or complaint about the non-physician mental health professionals providing care to you, you may contact a board agency to assist you. You may find information related to filing a complaint here: [https://idph.iowa.gov/Licensure/Iowa-Board-of-Behavioral-Science](https://idph.iowa.gov/Licensure/Iowa-Board-of-Behavioral-Science)

If you want to register a formal complaint about a physician, you should visit the medical board’s website, here:

[https://medicalboard.iowa.gov/consumers/filing-complaint.](https://medicalboard.iowa.gov/consumers/filing-complaint) To file a complaint, fill in the form below or fill out the [complaint form](https://medicalboard.iowa.gov/document/ibm-complaint-form)  and email it to the medical board at ibmcomplaints@iowa.gov.    
###### Relevant Board Contact Information
Iowa Board of Medicine

400 SW 8th St., Suite C

Des Moines, IA 50309

515-281-5171

Email: ibmcomplaints@iowa.gov.

###### Kansas
###### What You Should Know
You understand that if you have a primary care or other behavioral health treating provider and if you consent to us sharing your information with such provider, then we are obligated to send within three business days a report to such primary care or other treating physician of the treatment and services rendered by [PC] during the telemedicine encounter.  [Kan. Stat. Ann. § 40-2,212(2)(d)(2)(A).](https://www.ksrevisor.org/statutes/chapters/ch40/040_002_0212.html)

The process for filing a complaint may be found here: [http://www.ksbha.org/complaints.shtml](http://www.ksbha.org/complaints.shtml)

###### Relevant Board Contact Information
Kansas Board of the Healing Arts

800 SW Jackson, Lower Level – Suite A, Topeka, KS 66612

(785) 296-7413; Fax (785) 368-7102

###### Kentucky
###### What You Should Know
	
You have been informed that if you want to register a formal complaint about a provider, you should visit the medical board’s website, here:

[https://kbml.ky.gov/board/Pages/default.aspx.](https://kbml.ky.gov/board/Pages/default.aspx)

Information related to filing grievances may be found here: [https://kbml.ky.gov/grievances/Documents/Information%20on%20Filing%20A%20Grievance.pdf](https://kbml.ky.gov/grievances/Documents/Information%20on%20Filing%20A%20Grievance.pdf)
###### Relevant Board Contact Information
Kentucky Board of Medical Licensure

310 Whittington Parkway

Suite 1B

Louisville, KY 40222

###### Louisiana
###### What You Should Know
You understand the role of other health care providers that may be present during the consultation, other than the [PC] provider. [46 La. Admin. Code Pt XLV, § 7511.](https://www.doa.la.gov/media/japawb11/46v45.pdf)
###### Relevant Board Contact Information
Licensed Professional Counselors Board of Examiners

11410 Lake Sherwood Ave North Suite A

Baton Rouge, LA 70816

225-295-8444 (phone)

225-295-8448 (fax)

lpcboard@lpcboard.org

###### Maine
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to you, you may contact a board agency to assist you.

If you want to register a formal complaint about a physician, you should visit the medical board’s website, here:

[https://www.maine.gov/md/discipline/file-complaint.html](https://www.maine.gov/md/discipline/file-complaint.html)
###### Relevant Board Contact Information
Complaint Coordinator Office of Licensing and Registration

35 State House Station Augusta, ME 04333

Tel: (207) 624-8660

[www.maine.gov/professionallicensing](http://www.maine.gov/professionallicensing)

###### Maryland
###### What You Should Know
The knowledge, experiences, and qualifications of the consultant providing data and information to the provider of the telehealth services need not be completely known to and understood by the provider. The quality of transmitted data may affect the quality of services provided by the provider. Changes in the environment and test conditions could be impossible to make during delivery of telehealth services. Telehealth services may not be provided by correspondence only. [Md. Code Regs. 10.41.06.04.](http://www.dsd.state.md.us/comar/SubtitleSearch.aspx?search=10.41.06.%2a)

If you want to register a formal complaint about a physician, you should visit the medical board’s website, here: [https://www.mbp.state.md.us/resource_information/faqs/resource_faqs_complaints.aspx](https://www.mbp.state.md.us/resource_information/faqs/resource_faqs_complaints.aspx)
###### Relevant Board Contact Information
Maryland Board of Physicians

4201 Patterson Avenue

Baltimore, MD 21215

Tel: (410) 764-4777

###### Nebraska
###### What You Should Know
If you are a Medicaid recipient, you retain the option to refuse the telehealth consultation at any time without affecting your right to future care or treatment and without risking the loss or withdrawal of any program benefits to which the patient would otherwise be entitled. All existing confidentiality protections shall apply to the telehealth consultation. You shall have access to all medical information resulting from the telehealth consultation as provided by law for access to your medical records.

Dissemination of any patient identifiable images or information from the telehealth consultation to researchers or other entities shall not occur without your written consent. You understand that you have the right to request an in-person consult immediately after the telehealth consult and you will be informed if such consult is not available. [Neb. Rev. Stat. Ann. § 71-8505](https://nebraskalegislature.gov/laws/statutes.php?statute=71-8505#:~:text=71%2D8505.,statement%20or%20verbal%20consent%3B%20requirements.&text=(d)%20A%20statement%20that%20dissemination,written%20consent%20of%20the%20patient.); [471 Neb. Admin. Code § 1-006.05.](https://dhhs.ne.gov/Pages/Title-471.aspx)
###### Relevant Board Contact Information
Nebraska DHHS Licensure Unit

Attn: [insert relevant profession]

PO Box 94986

Lincoln NE 68509-4986

Complaints: [https://dhhs.ne.gov/Pages/Complaints.aspx](https://dhhs.ne.gov/Pages/Complaints.aspx)

###### New Hampshire
###### What You Should Know
You understand that the provider may forward your medical records to your primary care or treating provider.  [N.H. Rev. Stat. § 329:1-d.](https://www.gencourt.state.nh.us/rsa/html/XXX/329/329-1-d.htm)
###### Relevant Board Contact Information
Office of Professional Licensure & Certification

7 Eagle Square

Concord NH, 03301

Phone: 603-271-2152

###### New Jersey
###### What You Should Know
You understand that you have the right to request a copy of your medical information and you understand your medical information may be forwarded directly to your primary care provider or health care provider of record, or upon your request, to other health care providers. [N.J. Rev. Stat. Ann. § 45:1-62.](https://lis.njleg.state.nj.us/nxt/gateway.dll?xhitlist_vq=45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.&f=xhitlist&xhitlist_q=%5BRank+100%5D%5BDomain%3A+45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.%5D45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.&xhitlist_x=advanced&xhitlist_s=relevance-weight&xhitlist_mh=99999&xhitlist_d=&xhitlist_hc=%5BXML%5D%5BKwic%2C25%5D&xhitlist_xsl=xhitlist.xsl&xhitlist_vpc=first&xhitlist_vps=20&xhitlist_sel=title%3Bpath%3Brelevance-weight%3Bcontent-type%3Bhome-title%3Bitem-bookmark%3Btitle-path%3Bhit-context&vid=Publish:10.1048/Enu)
###### Relevant Board Contact Information
New Jersey Board of Medical Examiners

bme@dca.lps.state.nj.us

(609) 826-7100

Professional Counselors Examiners

MFTinquiries@dca.njoag.gov

(973) 504-6582

###### Ohio
###### What You Should Know
You understand that the provider may forward your medical records to your primary care or treating provider. [Ohio Admin. Code 4731-11-09(C).](https://codes.ohio.gov/ohio-administrative-code/rule-4731-11-09)
###### Relevant Board Contact Information
Ohio Medical Board

Complaints may be sent to: complaints@med.ohio.gov or call the Medical Board at 614-466-3934 and choose option 1 to speak to the complaint department.

You may also leave a message on the State Medical Board of Ohio’s Confidential Complaint Hotline at 1-833-333-SMBO (7626). 

###### Oklahoma
###### What You Should Know
The licensing website is [www.ok.gov/behavioralhealth](http://www.ok.gov/behavioralhealth) where you can access the laws and regulations that govern the practitioner’s license. The practitioner will furnish you with printed materials about the requirements of his/her licensure if you so desire.

You may also register a formal complaint about a physician by visiting: [http://www.okmedicalboard.org/complaint](http://www.okmedicalboard.org/complaint)  
###### Relevant Board Contact Information
You may contact (without giving your name), the State Board of Behavioral Health Licensure at:

State Board of Behavioral Health Licensure 3815 North Santa Fe, Suite 110, Oklahoma City, OK 73118 Telephone: (405) 522-3696

See above for physicians.

###### Oregon
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to you, you may contact a board agency to assist you. You understand that the provider may ask if you need more detail. [ORS 17-52-677.07](https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Foregon.public.law%2Fstatutes%2Fors_677.097&data=04%7C01%7CJgreco%40mwe.com%7C4bd08128740947ce46b408d9d2056d85%7C539c611a8032457bb371a99182228eef%7C0%7C0%7C637771743159648092%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=IkezifwJ46QCMmKJieHvXfldD4In04dMsL4AnU1QIkE%3D&reserved=0)
###### Relevant Board Contact Information
The Board of Licensed Professional Counselors and Therapists 3218 Pringle Rd SE, #120,

Salem, OR 97302-6312

Telephone: (503) 378-5499 Email: lpct.board@state.or.us

Website: www.oregon.gov/OBLPCT

###### Pennsylvania
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to you, you may contact a board agency to assist you.

You also understand that you may be asked to confirm your consent to behavioral health or telepsychiatry services. [40 PS §1303.504(b).](https://govt.westlaw.com/pac/Document/NE9BF7F30DE8511EB8F99D38EC757D1E4?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)&bhcp=1)
###### Relevant Board Contact Information
State Board of Social Workers, Marriage and Family Therapists and Professional Counselors

P.O. Box 2649, Harrisburg, PA 17105-2649 717-783-1389

###### South Carolina	
###### What You Should Know
	
The information you share in psychotherapy is protected health information and is generally considered confidential by both South Carolina state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena).  Your mental health practitioner is also mandated by standards – through Duties to Warn – to breach confidentiality if: (1) you are threatening self-harm or suicide; (2) you are threatening to harm another or homicide; (3) a child has been or is being abused or neglected; and/or (4) a vulnerable adult has been or is being abused or neglected.

You also understand that if you are a Medicaid beneficiary, you can withdraw your consent at any time.
###### Relevant Board Contact Information
South Carolina Board of Examiners for The Licensure of Professional Counselors, Marriage and Family Therapists, and Psycho-educational Specialists

P.O. Box 11329,

Columbia, South Carolina 29211-1329 Telephone: 803-896-4652

###### Tennessee
###### What You Should Know
You understand that you may request an in-person assessment before receiving a telehealth assessment if you are a telehealth recipient.

The information you share in psychotherapy is protected health information and is generally considered confidential by both Tennessee state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena).  Your mental health provider may also disclose information without consent: (1) if disclosure is necessary for other duties that the mental health provider is bound by, (2) if it is necessary to assure service or care is the least drastic means, (3) due to a court order, (4) if it is solely information to a residential service recipient, (5) to facilitate continuity of service to another health care provider, (6) if a custodial agent for another state agency that has legal custody of the service cannot perform the agent’s duties, or (7) it is necessary for the preparation of a post-mortem examination. [Tenn. Code Ann. §33-3-105.](https://advance.lexis.com/documentpage/?pdmfid=1000516&crid=ea330651-491b-4dec-aad1-e036de3fbeb0&pdistocdocslideraccess=true&config=025054JABlOTJjNmIyNi0wYjI0LTRjZGEtYWE5ZC0zNGFhOWNhMjFlNDgKAFBvZENhdGFsb2cDFQ14bX2GfyBTaI9WcPX5&pddocfullpath=%2Fshared%2Fdocument%2Fstatutes-legislation%2Furn%3AcontentItem%3A4X0F-X8C0-R03M-W3CM-00008-00&pdcomponentid=234180&pdtocnodeidentifier=ABHAADAABAAF&ecomp=rssdkkk&prid=18548fb4-4426-48a6-bf9b-fcd8be298c59)
###### Relevant Board Contact Information
Tennessee Department of Health

710 James Robertson Parkway

Nashville, TN 37243

tn.health@tn.gov

###### Texas
###### What You Should Know
You understand that your medical records may be sent to your primary care physician. [Tex. Occ. Code Ann. § 111.005.](https://statutes.capitol.texas.gov/Docs/OC/htm/OC.111.htm)

You have been informed of the following notice:

NOTICE CONCERNING COMPLAINTS -Complaints about physicians, as well as other licensees and registrants of the Texas Medical Board may be reported for investigation at the following address: Texas Medical Board, Attention: Investigations, 333 Guadalupe, Tower 3, Suite 610, P.O. Box 2018, MC-263, Austin, Texas 78768-2018, Assistance in filing a complaint is available by calling the following telephone number: 1-800-201-9353, For more information, please visit our website at www.tmb.state.tx.us. 

AVISO SOBRE LAS QUEJAS- Las quejas sobre médicos, asi como sobre otros profesionales acreditados e inscritos del Consejo Médico de Tejas, incluyendo asistentes de médicos, practicantes de acupuntura y asistentes de cirugia, se pueden presentar en la siguiente dirección para ser investigadas: Texas Medical Board, Attention: Investigations, 333 Guadalupe, Tower 3, Suite 610, P.O. Box 2018, MC-263, Austin, Texas 78768-2018, Si necesita ayuda para presentar una queja, llame al: 1-800-201-9353, Para obtener más información, visite nuestro sitio web en www.tmb.state.tx.us  
###### Relevant Board Contact Information
See above.

###### Utah
###### What You Should Know
You understand (i) the fees that may be charged to you for the telehealth service; (ii) to whom your health information may be disclosed and for what purpose, and have received information on any consent governing release of your patient-identifiable information to a third-party; (iii) your rights with respect to patient health information; (iv) appropriate uses and limitations of the site, including emergency health situations. You were warned of: potential risks to privacy notwithstanding the security measures and that information may be lost due to technical failures, and agree to hold the provider harmless for such loss. You have been provided with the location of [PC’s] website and contact information. You understand that you are able to select a provider of your choice, to the extent possible. You are able to select a pharmacy of choice. You are able to a (i) access, supplement, and amend your patient-provided personal health information; (ii) contact your provider for subsequent care; (iii) obtain upon request an electronic or hard copy of your medical record documenting the telemedicine services, including the informed consent provided; and (iv) request a transfer to another provider of your medical record documenting the telemedicine services. [Utah Admin. Code r. 156-1-602.](https://rules.utah.gov/wp-content/uploads/r156-01.pdf)
###### Relevant Board Contact Information
Utah Medical Board

(801) 530-6628

(866) 275-3675

b1@utah.gov

###### Vermont
###### What You Should Know
Upon the Office of Professional Regulation’s receipt of a complaint, an administrative review determines if the issues raised are covered by the applicable professional conduct statute. If so, a committee is assigned to investigate, collect information, and recommend action or closure to the appropriate governing body. All complaint investigations are confidential. Should the investigation conclude with a decision for disciplinary action against a professional’s license and ability to practice, the name of the license holder will then be made public.

If you want to register a formal complaint about a physician, you should visit the medical board’s website, here:

http://www.healthvermont.gov/health-professionals-systems/board-medical-practice/file-complaint 

Board of Osteopathic Examiners can be found at: [https://www.sec.state.vt.us/professional-regulation/file-a-complaint-employer-mandatory-reporting.aspx](https://www.sec.state.vt.us/professional-regulation/file-a-complaint-employer-mandatory-reporting.aspx%20%20) 
###### Relevant Board Contact Information
Consumers who have inquiries or wish to obtain a form to register a complaint regarding a professional counselor may do so by calling the Office of Professional Regulation at (802) 828-1505, or by writing to the Director of the Office, Secretary of State’s Office, 89 Main Street, 3rd Floor, Montpelier, VT 05620-3402.

For physicians, see above.

###### Washington
###### What You Should Know
You understand the purposes of and resources available to you surrounding this treatment, including the right to refuse treatment, and your responsibility in choosing a provider and treatment that best suits your needs. [RCW 18.19.060.](https://app.leg.wa.gov/RCW/default.aspx?cite=18.19.060)

The information you share in psychotherapy is protected health information and is generally considered confidential by both Washington state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena).  [RCW 18.19.180.](https://app.leg.wa.gov/RCW/default.aspx?cite=18.19.180)

Counselors practicing counseling for a fee must be credentialed with the department of health for the protection of the public health and safety. Credentialing of an individual with the department of health does not include a recognition of any practice standards, nor necessarily imply the effectiveness of any treatment. The purpose of the Counselor Credentialing Act, chapter 18.19 RCW, is to: (A) Provide protection for public health and safety; and (B) Empower the citizens of the state of Washington by providing a complaint process against those counselors who would commit acts of unprofessional conduct.  Clients have the right to choose counselors who best suit their needs and purposes.

A copy of the acts of unprofessional conduct in RCW 18.130.180 can be found on the Washington State Legislature’s website at this address [http://apps.leg.wa.gov/RCW/default.aspx?cite=18.130.180.](http://apps.leg.wa.gov/RCW/default.aspx?cite=18.130.180.)
###### Relevant Board Contact Information
Here is the name, address, and contact telephone number within the department of health for complaints.

Washington State Department of Health Health Professions Quality Assurance

P.O. Box 47865 Olympia, WA 98504-7865

(360) 236-4700

###### Wyoming
###### What You Should Know
Wyoming has implemented a privileged communication statute that states that, when involved in legal proceedings (civil, criminal or juvenile) clients retain the right to privacy, unless these specific circumstances exist: (a) abuse or harmful neglect of children, the elderly or disabled or incompetent individuals is known or reasonably suspected; (b) the validity of a will of a former client is contested; (c) information related to counseling is necessary to defend against a malpractice action brought by a client; (d) an immediate threat of physical violence against a readily identifiable victim is disclosed to the counselor; (e) in the context of civil commitment proceedings, where an immediate threat of self-inflicted harm is disclosed to the counselor; (f) the client alleges mental or emotional damages in civil litigation or his/her mental or emotional state becomes an issue in any court proceeding concerning child custody or visitation; (g) patient or client is examined pursuant to a court order; or (h) in the context of investigations and hearings brought by the client and conducted by the board, where violations of this act are at issue.  Providers will adhere to the Code of Ethics of the National Association of Social Workers; American Counseling Association; American Association of Marriage and Family Therapy; or National Association of Alcoholism and Drug Abuse Counselors, whichever is applicable for the provider’s profession.
###### Relevant Board Contact Information
Wyoming Mental Health Profession Licensing Board 2001 Capitol Ave, Room 105

Cheyenne, WY 82002

Tel: (307) 777-3628

Fax: (307) 777-3508

wyomhplb@wyo.gov
`

const teensStateRegulationsContent = `
###### Alaska 
###### What You Should Know
You understand that your child’s primary care provider may obtain a copy of your child’s records of your child’s telehealth encounter.

This document is intended to provide you with all of the information required by the Board of Professional Counselors which regulates all licensed professional counselors.  You may contact the Board with any questions or concerns.
###### Relevant Board Contact Information
Board of Professional Counselors

Division of Corporations, Business & Professional Licensing

P.O. Box 110806 Juneau, AK 99811-0806 Phone: (907) 465-2551

Email: ProfessionalCounselors@Alaska.Gov

###### Arizona 
###### What You Should Know
Your child is entitled to all existing confidentiality protections, including where a provider may only disclose all or part of your child’s medical record and payment record as authorized by state or federal law or written authorization signed by your child or their health care decision maker, pursuant to A.R.S. § 12-2292.  You also understand all medical reports resulting from the telemedicine consultation are part of your child’s medical record as defined in A.R.S. § 12-2291.  You also understand dissemination of any images or information identifiable to your child for research or educational purposes shall not occur without your child’s consent, unless authorized by state or federal law.  Ariz. Rev. Stat. Ann. § 36-3602(D).
###### Relevant Board Contact Information
Board of Behavioral Health Examiners

1740 West Adams Street, #3600

Phoenix, AZ 85007

Main Number: 602-542-1882

Fax Number: 602-364-0890

information@azbbhe.us

###### Colorado 
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to your child, you may contact a board agency to assist you. You are entitled to the consent requirements outlined under 2 CO ADC 502-1:21.170.4.  The confidentiality of your child’s individual records, including all medical, mental health, substance use, psychological, and demographic information shall be protected with the applicable state and federal laws and regulations, as provided under 2 CO ADC 502-1:21.170.2.
###### Relevant Board Contact Information
State Board of Licensed Professional Counselor Examiners, State Board of Social Work Examiners, State Board of Marriage and Family Therapist Examiners, State Board of Addiction Counselor Examiners, and State Board of Psychologist Examiners

1560 Broadway, Suite 1350, Denver, Colorado 80202, (303) 894-7800

Email: DORA_Customercare@state.co.us

###### Connecticut 
###### What You Should Know
You understand that each telehealth provider shall, at the time of the initial telehealth interaction, ask your child whether they consent to that provider’s disclosure of records concerning the telehealth interaction to your child’s primary care provider.  You further understand that your child’s primary care provider may obtain a copy of your child’s records of their telehealth encounter, upon their consent. [Conn. Gen. Stat. Ann. § 19a-906(d).](https://www.cga.ct.gov/current/pub/chap_368ll.htm#sec_19a-906)
###### Relevant Board Contact Information
Connecticut Department of Public Health

Professional Counselor Licensure

410 Capitol Ave., MS #12 APP

P.O. Box 340308

Hartford, CT 06134

Phone: (860) 509-7603

Fax: (860) 707-1980

Email:  dph.counselorsteam@ct.gov

###### District of Columbia 
###### What You Should Know
Your child has been informed of alternate forms of communication between your child and a physician for urgent matters. [D.C. Mun. Regs. tit. 17, § 4618.10.](https://www.dcregs.dc.gov/Common/DCMR/SectionList.aspx?SectionNumber=17-4618)  Relevant communications with the physician, including those done via electronic methods shall be documented and filed in your child’ s medical record. [D.C. Mun. Regs. tit. 17, § 4618.9.](https://www.dcregs.dc.gov/Common/DCMR/SectionList.aspx?SectionNumber=17-4618)
###### Relevant Board Contact Information
Professional Counseling Licensing

899 North Capitol Street, NE, Washington, DC 20002

Phone: (202) 442-5955

Fax: (202) 442-4795

###### Georgia
###### What You Should Know
Your child has been given clear, appropriate, accurate instructions on follow-up in the event of needed emergent care related to the treatment. [Ga. Comp. R. & Regs. 360-3-.07(a)(7).](https://medicalboard.georgia.gov/sites/medicalboard.georgia.gov/files/related_files/site_page/Adopted%20Telemedicine%20Rule.pdf)
###### Relevant Board Contact Information
GA Board of Professional Counselors, Social Workers, and Marriage and Family Therapists

214 State Capitol

Atlanta, Georgia 30334

###### Idaho
###### What You Should Know
You understand that you and your child’s informed consent for the use of telehealth services shall be obtained by applicable law. [Idaho Statutes 54-5708.](https://legislature.idaho.gov/statutesrules/idstat/title54/t54ch57/sect54-5708/#:~:text=Idaho%20Statutes&text=54%2D5708.,required%20by%20any%20applicable%20law.)
###### Relevant Board Contact Information
Division of Professional Licenses: 11351 W. Chinden Blvd., Bldg. #6

Boise, ID 83714

###### Illinois
###### What You Should Know
If you need to register a formal complaint about a professional regulated under the Illinois Division of Professional Regulation, you may file a formal complaint here: [https://www.idfpr.com/admin/DPR/DPRcomplaint.asp](https://idfpr.illinois.gov/admin/DPR/DPRcomplaint.asp)
###### Relevant Board Contact Information
Chicago: 555 West Monroe St., 5th Floor Chicago, IL 60661

Springfield: 320 W. Washington Street, 3rd Floor, Springfield IL

Phone: 1 (888) 473-4858

###### Indiana
###### What You Should Know
If you have a concern or complaint about the non-physician mental health professionals providing care to your child, you may contact a board agency to assist you. You may file a complaint here: [https://www.in.gov/pla/file-a-complaint/](https://www.in.gov/pla/file-a-complaint/)

###### Relevant Board Contact Information
Indiana Professional Licensing Agency

402 W. Washington St., Room W072

Indianapolis, Indiana 46204

Staff Phone Number: (317) 234-2054

Staff E-mail: pla8@pla.IN.gov

Agency Fax: (317) 233-4236

###### Iowa
###### What You Should Know
If you have a concern or complaint about the non-physician mental health professionals providing care to your child, you may contact a board agency to assist you. You may find information related to filing a complaint here: [https://idph.iowa.gov/Licensure/Iowa-Board-of-Behavioral-Science](https://idph.iowa.gov/Licensure/Iowa-Board-of-Behavioral-Science)
 
###### Relevant Board Contact Information


###### Kansas
###### What You Should Know
You understand that if your child has a primary care or other behavioral health treating provider and if your child consents to us sharing their information with such provider, then we are obligated to send within three business days a report to such primary care or other treating physician of the treatment and services rendered by LCA during the telemedicine encounter. [Kan. Stat. Ann. § 40-2,212(2)(d)(2)(A).](https://www.ksrevisor.org/statutes/chapters/ch40/040_002_0212.html)

The process for filing a complaint may be found here: [http://www.ksbha.org/complaints.shtml](http://www.ksbha.org/complaints.shtml)

###### Relevant Board Contact Information
Kansas Board of the Healing Arts

800 SW Jackson, Lower Level – Suite A, Topeka, KS 66612

(785) 296-7413; Fax (785) 368-7102

###### Kentucky
###### What You Should Know
	
You have been informed that if you want to register a formal complaint about a provider, you should visit the medical board’s website, here:

[https://kbml.ky.gov/board/Pages/default.aspx.](https://kbml.ky.gov/board/Pages/default.aspx)

Information related to filing grievances may be found here: [https://kbml.ky.gov/grievances/Documents/Information%20on%20Filing%20A%20Grievance.pdf](https://kbml.ky.gov/grievances/Documents/Information%20on%20Filing%20A%20Grievance.pdf)
###### Relevant Board Contact Information


###### Louisiana
###### What You Should Know
You understand the role of other health care providers that may be present during the consultation, other than the LCA provider. [46 La. Admin. Code Pt XLV, § 7511.](https://www.doa.la.gov/media/japawb11/46v45.pdf)
###### Relevant Board Contact Information
Licensed Professional Counselors Board of Examiners

11410 Lake Sherwood Ave North Suite A

Baton Rouge, LA 70816

225-295-8444 (phone)

225-295-8448 (fax)

lpcboard@lpcboard.org

###### Maine
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to your child, you may contact a board agency to assist you.

###### Relevant Board Contact Information
Complaint Coordinator Office of Licensing and Registration

35 State House Station Augusta, ME 04333

Tel: (207) 624-8660

[www.maine.gov/professionallicensing](http://www.maine.gov/professionallicensing)

###### Maryland
###### What You Should Know
The knowledge, experiences, and qualifications of the consultant providing data and information to the provider of the telehealth services need not be completely known to and understood by the provider. The quality of transmitted data may affect the quality of services provided by the provider. Changes in the environment and test conditions could be impossible to make during delivery of telehealth services. Telehealth services may not be provided by correspondence only. [Md. Code Regs. 10.41.06.04.](http://www.dsd.state.md.us/comar/SubtitleSearch.aspx?search=10.41.06.%2a)

###### Relevant Board Contact Information

###### Nebraska
###### What You Should Know
If your child is a Medicaid recipient, your child retains the option to refuse the telehealth consultation at any time without affecting their right to future care or treatment and without risking the loss or withdrawal of any program benefits to which the patient would otherwise be entitled. All existing confidentiality protections shall apply to the telehealth consultation. Your child shall have access to all medical information resulting from the telehealth consultation as provided by law for access to their medical records.

Dissemination of any patient identifiable images or information from the telehealth consultation to researchers or other entities shall not occur without you and your child’s written consent. You understand that your child has the right to request an in-person consult immediately after the telehealth consult and your child will be informed if such consult is not available. [Neb. Rev. Stat. Ann. § 71-8505](https://nebraskalegislature.gov/laws/statutes.php?statute=71-8505#:~:text=71%2D8505.,statement%20or%20verbal%20consent%3B%20requirements.&text=(d)%20A%20statement%20that%20dissemination,written%20consent%20of%20the%20patient.); [471 Neb. Admin. Code § 1-006.05.](https://dhhs.ne.gov/Pages/Title-471.aspx)
###### Relevant Board Contact Information
Nebraska DHHS Licensure Unit

Attn: [insert relevant profession]

PO Box 94986

Lincoln NE 68509-4986

Complaints: [https://dhhs.ne.gov/Pages/Complaints.aspx](https://dhhs.ne.gov/Pages/Complaints.aspx)

###### New Hampshire
###### What You Should Know
You understand that the provider may forward your child’s medical records to their primary care or treating provider. [N.H. Rev. Stat. § 329:1-d.](https://www.gencourt.state.nh.us/rsa/html/XXX/329/329-1-d.htm)
###### Relevant Board Contact Information
Office of Professional Licensure & Certification

7 Eagle Square

Concord NH, 03301

Phone: 603-271-2152

###### New Jersey
###### What You Should Know
You understand that your child has the right to request a copy of their medical information and you understand your child’s medical information may be forwarded directly to their primary care provider or health care provider of record, or upon you or your child’s request, to other health care providers. [N.J. Rev. Stat. Ann. § 45:1-62.](https://lis.njleg.state.nj.us/nxt/gateway.dll?xhitlist_vq=45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.&f=xhitlist&xhitlist_q=%5BRank+100%5D%5BDomain%3A+45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.%5D45%3A1-62+Provision+of+health+care+through+use+of+telemedicine%2C+telehealth%3B+requirements+for+provider.&xhitlist_x=advanced&xhitlist_s=relevance-weight&xhitlist_mh=99999&xhitlist_d=&xhitlist_hc=%5BXML%5D%5BKwic%2C25%5D&xhitlist_xsl=xhitlist.xsl&xhitlist_vpc=first&xhitlist_vps=20&xhitlist_sel=title%3Bpath%3Brelevance-weight%3Bcontent-type%3Bhome-title%3Bitem-bookmark%3Btitle-path%3Bhit-context&vid=Publish:10.1048/Enu)
###### Relevant Board Contact Information
New Jersey Board of Medical Examiners

bme@dca.lps.state.nj.us

(609) 826-7100

Professional Counselors Examiners

MFTinquiries@dca.njoag.gov

(973) 504-6582

###### Ohio
###### What You Should Know
You understand that the provider may forward your child’s medical records to their primary care or treating provider. [Ohio Admin. Code 4731-11-09(C).](https://codes.ohio.gov/ohio-administrative-code/rule-4731-11-09)
###### Relevant Board Contact Information


###### Oklahoma
###### What You Should Know
The licensing website is [www.ok.gov/behavioralhealth](http://www.ok.gov/behavioralhealth) where you can access the laws and regulations that govern the practitioner’s license. The practitioner will furnish you with printed materials about the requirements of his/her licensure if you so desire..

###### Relevant Board Contact Information
You may contact (without giving your name), the State Board of Behavioral Health Licensure at:

State Board of Behavioral Health Licensure 3815 North Santa Fe, Suite 110, Oklahoma City, OK 73118 Telephone: (405) 522-3696

###### Oregon
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to your child, you may contact a board agency to assist you. You understand that the provider may ask if you need more detail. [ORS 17-52-677.07](https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Foregon.public.law%2Fstatutes%2Fors_677.097&data=04%7C01%7CJgreco%40mwe.com%7C4bd08128740947ce46b408d9d2056d85%7C539c611a8032457bb371a99182228eef%7C0%7C0%7C637771743159648092%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=IkezifwJ46QCMmKJieHvXfldD4In04dMsL4AnU1QIkE%3D&reserved=0)
###### Relevant Board Contact Information
The Board of Licensed Professional Counselors and Therapists 3218 Pringle Rd SE, #120,

Salem, OR 97302-6312

Telephone: (503) 378-5499 Email: lpct.board@state.or.us

Website: www.oregon.gov/OBLPCT

###### Pennsylvania
###### What You Should Know
If you have a concern or complaint about the mental health professionals providing care to your child, you may contact a board agency to assist you.

You also understand that you may be asked to confirm your and your child’s consent to behavioral health or telepsychiatry services. [40 PS §1303.504(b).](https://govt.westlaw.com/pac/Document/NE9BF7F30DE8511EB8F99D38EC757D1E4?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)&bhcp=1)
###### Relevant Board Contact Information
State Board of Social Workers, Marriage and Family Therapists and Professional Counselors

P.O. Box 2649, Harrisburg, PA 17105-2649 717-783-1389

###### South Carolina	
###### What You Should Know
	
The information you share in psychotherapy is protected health information and is generally considered confidential by both South Carolina state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena).  Your child’s mental health practitioner is also mandated by standards – through Duties to Warn – to breach confidentiality if: (1) your child is threatening self-harm or suicide; (2) your child is threatening to harm another or homicide; (3) a child has been or is being abused or neglected; and/or (4) a vulnerable adult has been or is being abused or neglected.

You also understand that if your child is a Medicaid beneficiary, your and your child can withdraw your consent at any time.
###### Relevant Board Contact Information
South Carolina Board of Examiners for The Licensure of Professional Counselors, Marriage and Family Therapists, and Psycho-educational Specialists

P.O. Box 11329,

Columbia, South Carolina 29211-1329 Telephone: 803-896-4652

###### Tennessee
###### What You Should Know
You understand that your child may request an in-person assessment before receiving a telehealth assessment if your child is a telehealth recipient.

The information your child shares in psychotherapy is protected health information and is generally considered confidential by both Tennessee state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena).  Your child’s mental health provider may also disclose information without consent: (1) if disclosure is necessary for other duties that the mental health provider is bound by, (2) if it is necessary to assure service or care is the least drastic means, (3) due to a court order, (4) if it is solely information to a residential service recipient, (5) to facilitate continuity of service to another health care provider, (6) if a custodial agent for another state agency that has legal custody of the service cannot perform the agent’s duties, or (7) it is necessary for the preparation of a post-mortem examination. [Tenn. Code Ann. §33-3-105.](https://advance.lexis.com/documentpage/?pdmfid=1000516&crid=ea330651-491b-4dec-aad1-e036de3fbeb0&pdistocdocslideraccess=true&config=025054JABlOTJjNmIyNi0wYjI0LTRjZGEtYWE5ZC0zNGFhOWNhMjFlNDgKAFBvZENhdGFsb2cDFQ14bX2GfyBTaI9WcPX5&pddocfullpath=%2Fshared%2Fdocument%2Fstatutes-legislation%2Furn%3AcontentItem%3A4X0F-X8C0-R03M-W3CM-00008-00&pdcomponentid=234180&pdtocnodeidentifier=ABHAADAABAAF&ecomp=rssdkkk&prid=18548fb4-4426-48a6-bf9b-fcd8be298c59)
###### Relevant Board Contact Information
Tennessee Department of Health

710 James Robertson Parkway

Nashville, TN 37243

tn.health@tn.gov

###### Texas
###### What You Should Know
You understand that your child’s medical records may be sent to their primary care physician. [Tex. Occ. Code Ann. § 111.005.](https://statutes.capitol.texas.gov/Docs/OC/htm/OC.111.htm)

###### Relevant Board Contact Information
See column to left.

###### Utah
###### What You Should Know
You understand (i) the fees that may be charged to you for the telehealth service; (ii) to whom your child’s health information may be disclosed and for what purpose, and have received information on any consent governing release of your child’s patient-identifiable information to a third-party; (iii) your child’s rights with respect to patient health information; (iv) appropriate uses and limitations of the site, including emergency health situations. You were warned of: potential risks to privacy notwithstanding the security measures and that information may be lost due to technical failures, and agree to hold the provider harmless for such loss. You have been provided with the location of LCA’s website and contact information. You understand that you are able to select a provider of your child’s choice, to the extent possible. You are able to select a pharmacy of choice. Your child is able to a (i) access, supplement, and amend their patient-provided personal health information; (ii) contact their provider for subsequent care; (iii) obtain upon request an electronic or hard copy of their medical record documenting the telemedicine services, including the informed consent provided; and (iv) request a transfer to another provider of their medical record documenting the telemedicine services. [Utah Admin. Code r. 156-1-602.](https://rules.utah.gov/wp-content/uploads/r156-01.pdf)
###### Relevant Board Contact Information

###### Vermont
###### What You Should Know
Upon the Office of Professional Regulation’s receipt of a complaint, an administrative review determines if the issues raised are covered by the applicable professional conduct statute. If so, a committee is assigned to investigate, collect information, and recommend action or closure to the appropriate governing body. All complaint investigations are confidential. Should the investigation conclude with a decision for disciplinary action against a professional’s license and ability to practice, the name of the license holder will then be made public.

###### Relevant Board Contact Information
Consumers who have inquiries or wish to obtain a form to register a complaint regarding a professional counselor may do so by calling the Office of Professional Regulation at (802) 828-1505, or by writing to the Director of the Office, Secretary of State’s Office, 89 Main Street, 3rd Floor, Montpelier, VT 05620-3402.


###### Washington
###### What You Should Know
You understand the purposes of and resources available to you and your child surrounding this treatment, including the right to refuse treatment, and your responsibility in choosing a provider and treatment that best suits your child’s needs. [RCW 18.19.060.](https://app.leg.wa.gov/RCW/default.aspx?cite=18.19.060)

The information your child shares in psychotherapy is protected health information and is generally considered confidential by both Washington state law and federal regulations, with some limited exceptions (e.g., may be shared with another healthcare provider, required by subpoena). [RCW 18.19.180.](https://app.leg.wa.gov/RCW/default.aspx?cite=18.19.180)

Counselors practicing counseling for a fee must be credentialed with the department of health for the protection of the public health and safety. Credentialing of an individual with the department of health does not include a recognition of any practice standards, nor necessarily imply the effectiveness of any treatment. The purpose of the Counselor Credentialing Act, chapter 18.19 RCW, is to: (A) Provide protection for public health and safety; and (B) Empower the citizens of the state of Washington by providing a complaint process against those counselors who would commit acts of unprofessional conduct.  Clients have the right to choose counselors who best suit their needs and purposes.

A copy of the acts of unprofessional conduct in RCW 18.130.180 can be found on the Washington State Legislature’s website at this address [http://apps.leg.wa.gov/RCW/default.aspx?cite=18.130.180.](http://apps.leg.wa.gov/RCW/default.aspx?cite=18.130.180.)
###### Relevant Board Contact Information
Here is the name, address, and contact telephone number within the department of health for complaints.

Washington State Department of Health Health Professions Quality Assurance

P.O. Box 47865 Olympia, WA 98504-7865

(360) 236-4700

###### Wyoming
###### What You Should Know
Wyoming has implemented a privileged communication statute that states that, when involved in legal proceedings (civil, criminal or juvenile) clients retain the right to privacy, unless these specific circumstances exist: (a) abuse or harmful neglect of children, the elderly or disabled or incompetent individuals is known or reasonably suspected; (b) the validity of a will of a former client is contested; (c) information related to counseling is necessary to defend against a malpractice action brought by a client; (d) an immediate threat of physical violence against a readily identifiable victim is disclosed to the counselor; (e) in the context of civil commitment proceedings, where an immediate threat of self-inflicted harm is disclosed to the counselor; (f) the client alleges mental or emotional damages in civil litigation or his/her mental or emotional state becomes an issue in any court proceeding concerning child custody or visitation; (g) patient or client is examined pursuant to a court order; or (h) in the context of investigations and hearings brought by the client and conducted by the board, where violations of this act are at issue.  Providers will adhere to the Code of Ethics of the National Association of Social Workers; American Counseling Association; American Association of Marriage and Family Therapy; or National Association of Alcoholism and Drug Abuse Counselors, whichever is applicable for the provider’s profession.
###### Relevant Board Contact Information
Wyoming Mental Health Profession Licensing Board 2001 Capitol Ave, Room 105

Cheyenne, WY 82002

Tel: (307) 777-3628

Fax: (307) 777-3508

wyomhplb@wyo.gov
`

export const informedConsent = `
### Consent for Therapy and Other Participation Terms

This document contains important information about the professional services and business policies of both your Lyra Clinical Associates (LCA) therapist, and Lyra Clinical Associates P.C. Please read it carefully and discuss any questions you have with your therapist. *​​By signing this form, you are representing that you have read this document, understand the information found in it, and you agree to comply with it, as applicable.*

*Confidentiality:* Your therapist and LCA take your privacy and confidentiality very seriously. LCA complies with Federal and California laws regarding confidentiality of client information. LCA’s [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/) Practices details how we use any information we collect, including for treatment, coordination of care, payment and other business operations. We will minimize the amount of information we share without your express consent, however we want to bring your attention to certain circumstances in which we may disclose details of your care:
            
* Your therapist may share information about your assessment and treatment with the clinical team at LCA in a way that minimizes the sharing of your personal information to only that necessary to ensure that we are providing you with the most effective care possible and/or to improve your experience with our services.
* In certain circumstances, your therapist is required by law to inform legal authorities, or potentially impacted individuals, regarding the following possibilities:
    * If there is suspected abuse or neglect of an elder, incapacitated or dependent adult, or child.
    * If, in your therapist’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
    * If you communicate to your therapist a serious threat of physical violence against another person.
* If your therapist is ordered by a court to release information as part of a legal proceeding, your therapist may be required to share details of your care.
* As otherwise required by law and/or detailed in our [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/)

*The Process of Psychotherapy:* The process and outcome of psychotherapy may vary depending on the particular problems addressed, the personalities of the therapist and client, and various other factors. While we cannot predict exactly what your experience will be like, we are committed to providing you with the most professional and ethical treatment possible.

Participation in therapy can result in a number of benefits to you, including improved interpersonal relationships, resolution of specific problems, and positive personal change. In order for therapy to be successful, you will need to make an active effort both during and outside of your sessions. Since therapy often involves discussing unpleasant aspects of your life, you may experience uncomfortable and intense feelings like sadness, guilt, anxiety, frustration and anger. In addition, it may result in changes that were not originally intended (such as divorce or remaining in a relationship you believed you would leave). Change can often happen quickly, but may also occur more slowly. There are no guarantees about what you will experience. Your therapist’s role is to help guide you through the therapy process by offering emotional support, actively listening to your concerns, asking relevant questions, providing treatment plans and recommendations, and monitoring your treatment progress.

During your first session, you will discuss with your LCA therapist the reasons you are seeking therapy, goals you would like to achieve, and any background information related to your presenting concerns. In subsequent sessions, you and your therapist will work collaboratively towards achieving your goals of therapy. Subsequent sessions may focus on understanding thought patterns, feelings, and behaviors that are relevant to presenting concerns and work towards more effective ways of coping.

Throughout therapy, you will work with your therapist to tailor treatment to meet your goals and assess whether your goals are being met. If at any point you are unhappy about the progress, process, or outcome of the treatment, please discuss this with your therapist. There are many different methods your therapist may use to address the concerns that bring you to therapy. Your therapist strives for genuineness and a nonjudgmental stance in all of his/her patient relationships. However, if at any time you feel that you are not connecting or that he/she has misunderstood you in an important way, you should discuss them in session with your therapist. In addition, the Lyra Care Navigator Team is always available to work with you, and may also reach out to you to discuss your overall satisfaction with the therapy.

*Your Therapist’s Credentials.* Your therapist’s credentials were made available to you before scheduling an appointment. If you have any questions about these credentials, please direct them to your therapist. For those states that require it, you can find an explanation of the levels of regulation applicable to mental health clinicians under the *STATE REGULATIONS* section of this document. Please let your therapist know if you will be traveling to another state or if you move to another state. In some cases, therapists are not able to provide services to you while you are located in another state, even temporarily.

*Billing and Payments:* The fees for your sessions may be partially or fully paid for by your, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to you, as long as you are an eligible employee in good standing with the company, and as long as further sessions are considered clinically appropriate. *Lyra’s services are not covered by Medicare. If you have insurance coverage through Medicare, you are financially responsible for any costs that are not covered by your employer or another Health Plan in which you are enrolled.* Please note that certain professional services outside of the presenting problems may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future and may also change if you move to another state. If you have questions about the full cost of a session prior to any health plan cost coverage, you may contact the Lyra Care Navigator Team at *care@lyrahealth.com*

*The Lyra Website:* As part of therapy you will use the Lyra Website. You will need to access the Lyra Website to participate in the video sessions, submit assessments and practices in between sessions, as well as to connect with your therapist via secure messaging.

Your therapist will orient you to using the Lyra Website for the above purposes during your first therapy session. If you decide to have your sessions with your therapist by phone (without using the video option in the Lyra Website), your therapist will call you at the number you provided at registration. You will still need to use the Lyra Website to complete assessments and submit records of therapy practices.

*Therapy Assessments:* Before your first session, your therapist will ask you to answer a set of questions that will help them get to know you and better tailor treatment to respond to your situation. Throughout therapy, your therapist will also assign other assessments to be completed before your sessions to inform treatment.

*Therapeutic Focus:* LCA therapists may decline to make recommendations, write letters of recommendation or complete application forms related to medical leave, medical disability, fitness to work, child custody, or emotional support animals if they believe it is outside the scope of their competency or licensure, or otherwise not clinically appropriate.

*Video Therapy:* Please note that:

* As part of this program you may engage in telehealth sessions with your LCA therapist.
* All telehealth sessions are held by video using the Lyra Website.
* Your therapist will initiate the video session with you at the scheduled time using the Lyra Website.
* The video conferencing technology will not be the same as an in-person session with a therapist due to the fact that you will not be in the same room as your therapist.
* In order to have the best results for your video session, you should be in a quiet place with limited interruptions when you start the session.
* There are potential risks to this technology, including interruptions, unauthorized access and technical difficulties that are beyond the control of LCA. You agree to hold LCA harmless for delays in evaluation or for information lost due to such technical difficulties or failures.
* Generally speaking, telehealth offers benefits such as improved access to care by enabling patients to remain in their local site (e.g., home or work) while their clinician consults at distant/other sites, efficient mental health evaluation and management, and the expertise of specialists that patients otherwise might not have. There are potential risks associated with telehealth, which include, but may not be limited to: the therapist may determine that the transmitted information is of inadequate quality, thus necessitating a face-to-face meeting with you; delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment; security protocols could fail, causing a breach of privacy of personal medical information; lack of access to complete medical records, which could result in judgment errors in rare cases; and, it may become clear that telehealth is not an appropriate treatment format given your presenting symptoms or level of functioning, resulting in a recommendation that you obtain additional in-person care.
* Your Lyra therapist or yourself can discontinue the telehealth session if it is felt that the videoconferencing connections are not adequate for the situation.
* Your LCA therapist will inform you and obtain your consent if another person is present during the consultation, for any reason. For example, persons may be present during the consultation other than the LCA therapist in order to operate the telehealth technologies. You will be informed of their presence in the consultation and will have the right to request the following: (a) omit specific details of your medical history/examination that are personally sensitive to you; (b) ask non-medical personnel to leave the telehealth examination; and/or (c) terminate the consultation at any time.
* There are alternatives to a telehealth session available, including the option of finding an LCA therapist to see in-person.
* You can direct questions about your telehealth sessions at any time to your LCA therapist or Lyra Care Navigator Team member.
* This consent will last for the duration of the relationship with your LCA therapist, and it covers all telehealth sessions you may have.
* You can withdraw your consent for telehealth sessions in the course of your care at any time for any reason or no reason, without affecting your right to future care or treatment, and Lyra will work with you to find a suitable alternative.
* The same confidentiality protections, limits to confidentiality, and rules around your records apply to a telehealth session as they would to an in-person session.
* Your LCA therapist may decide to terminate telehealth services, if they deem it inappropriate for you to continue therapy through video sessions. Your LCA therapist will work with other team members to identify another LCA therapist for in-person care.
* You understand that while you may expect anticipated benefits from the use of telehealth in your care, no results can be guaranteed or assured.
* By signing this consent form you agree to work with your LCA therapist to come up with a safety plan, including identifying one or two emergency contacts, in the event of a crisis situation during your sessions.
          
*Video Recording:* Your therapist will ask you at the start of each session if you consent to the session being recorded. If you consent, your therapist will record the session for quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating the clinical record, and development of our services.  All content will be reviewed and approved by your therapist before inclusion in the clinical record. Video recordings are kept for 2 weeks and then deleted. No one other than the therapist (including you) has permission to record sessions. We may retain some information from the session in a de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

*Your Location During Video Sessions:* Like all health care providers, LCA’s therapists are licensed to offer services on a state by state basis. In order to ensure your LCA therapist is appropriately licensed, we ask that you please search for a provider based upon your planned location at the time your session takes place. If your location changes at any point from one state to another, please let your LCA therapist know as soon as possible. Please be aware that your LCA therapist may not be able to provide services when you travel to a different state and is not able to provide services when you travel outside of the U.S.

*Missed Appointments and Cancellations:* Your therapist requires at least 24 hours advance notice for cancellation of an appointment. If you are unable to attend your scheduled appointment due to circumstances beyond your control, please message your therapist in the Lyra Website as soon as possible so we can reschedule your appointment to a more suitable time. Once an appointment is scheduled, you will be expected to attend unless you contact your therapist to reschedule. You can also use email to reschedule; however, please note that receiving emails in your personal account may be unsecure. Failure to provide sufficient notice for cancellations and/or repeated no-shows for your appointment may impact your ability to schedule additional appointments with your therapist. If you cancel or reschedule within 24 hours of a scheduled session, or you do not attend a scheduled session you may be charged a $105.00 Late Cancellation Payment.

*In-Between Session Communication:* If you need to contact your LCA therapist between sessions, for non-urgent issues, please message them within the Lyra Website. Your therapist checks his/her messages in the Lyra Website regularly during business hours and will make every effort to return your message within 2 business days. These messages will remain available for thirteen (13) months and then will be deleted.

*Emergency Procedures:* In a crisis, please call 911 or go to the local emergency room. Please do not use the Lyra Care messaging or email if you are experiencing an emergency. If you would like to speak to someone for support prior to your therapy appointment, or if you experience distress prior to your appointment, please use the Lyra Care Navigator Team number (877-505-7147) for non-emergency situations. Your Lyra therapist may not be available to respond to you immediately due to variations in working hours. Your Lyra therapist will respond to messages within 48 business hours of receipt.

If you experience an emergency during your sessions with your LCA therapist, or if your LCA therapist becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your LCA therapist and LCA will do whatever possible within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, LCA or your LCA therapist may contact the person whose name you have provided on the biographical sheet as your emergency contact.

*Social Media Policy:* LCA and our therapists believe that adding clients as friends or contacts on social media sites (e.g., Facebook, LinkedIn, etc.) can compromise clients’ confidentiality and privacy. Consequently, given the importance of client confidentiality, your LCA therapist will not accept friend or contact requests from current or former clients on any social networking site. If you have questions about this, please bring them up during your session.

*Research, Writing, Teaching:* Your therapist and others at LCA conduct internal research to improve our services, training, and supervision, or publish information for professional and/or lay audiences. De-identified information about you and your treatment may be used to support these initiatives. Any use of information about your treatment would be only in an anonymized and/or de-identified way for these publications.

*Length of Therapy:* Evidence-based treatments, or treatments that have been rigorously tested and proven to be helpful, typically are shorter-term treatments than general counseling or the general provision of support. Most clients notice some initial changes or relief within the first few sessions of treatment. Your course of treatment will be individualized depending on the intensity and duration of your presenting concerns, your level of engagement and active participation in the treatment plan, and the specific nature of your concerns.

*Ending Treatment:* You may withdraw from treatment at any time. Your therapist recommends that you discuss your plan to end treatment with them before taking action, so that they have an opportunity to offer further recommendations or referral options.

If at any point during psychotherapy your therapist assesses that the sessions are not effective in helping you reach the therapeutic goals, he/she will discuss this with you and, if appropriate, end treatment. In such a case, they will work with you and the Lyra Care Navigator Team to identify alternative options.

If you do not keep your scheduled appointments and do not respond to communications from your therapist, we will assume you have elected to end your treatment and we will close your case. If this occurs, and you wish to resume your treatment, please contact the Lyra Care Navigator Team to reinitiate therapy.

*State Regulations:* Please read the disclosures set forth next to the state in which you are located at the time of the telehealth encounter, as set forth below.

${stateRegulationsContent}

*TREATMENT AGREEMENT*
If at any time you have questions about the details above, please ask your therapist or the Lyra Care Navigator Team. By continuing your sessions with your therapist you accept and agree to abide by the contents and terms of this agreement and consent to participate in evaluation and/or treatment.
`

export const medsInformedConsent = `
### Consent for Medication Management Care and Other Participation Terms

This document contains important information about the professional services and business policies of both your Lyra Clinical Associates P.C. (LCA) physician, and Lyra Clinical Associates P.C. Please read it carefully and discuss any questions you have with your physician. *By signing this form, you are representing that you have read these documents, understand the information found in them, and you agree to comply with them, as applicable.*

*Confidentiality:* Your physician and LCA take your privacy and confidentiality very seriously. LCA complies with Federal and state laws regarding confidentiality of client information. Our [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/) details how we use any information we collect, including for treatment, coordination of care, payment and other business operations. We will minimize the amount of information we share without your express consent, however there are certain circumstances in which we may disclose details of your care:

* Your physician may share information about your assessment and treatment in an anonymized way with members of the clinical team at LCA in order to improve your experience and guarantee that we are providing you with the most effective care possible.
* If there is suspected elder, dependent adult, or child abuse or neglect.
* If, in your physician’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
* If you communicate to your physician a serious threat of physical violence against another person; in these circumstances, your physician is required by law to inform both potential victims and legal authorities.
* If your physician is ordered by a court to release information as part of a legal proceeding.
* As otherwise required by law and detailed in our [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/).

*Confidentiality of Alcohol and Drug Abuse Records:*

As described in LCA’s Notice of Privacy Practices, patient medical records are protected by federal and state laws and regulations. Certain substance use disorder records are also protected by the federal regulations governing the Confidentiality of Substance Use Disorder Patient Records (42 CFR Part 2 (“Part 2”)). LCA works with certain Part 2 behavioral health providers who may provide therapeutic services involving substance use disorder (“Part 2 Program”). Records from any Part 2 Program that may be created in the course of your care through LCA are protected as described in this Notice. The following is a summary of the Part 2 regulations:

* Generally, a Part 2 Program may only acknowledge that an individual is present or disclose outside the Part 2 Program information identifying a patient as having or having had a substance use disorder in the following instances:
    - The patient’s written consent is obtained,
    - An authorizing court order is entered,
    - The patient’s records are disclosed to medical personnel to the extent necessary to meet a bona fide medical emergency,
    - The disclosure is for the purpose of conducting scientific research, or
    - The disclosure is for the purpose of an audit or evaluation.

Violation of the federal law and regulations at Part 2 is a crime and suspected violations may be reported as follows:

* Any violation of Part 2 may be reported:
    - To our Chief Privacy Officer at 270 East Lane, Burlingame, CA 94010; (800) 505-5972; or at privacy@lyrahealth.com. 
    - The report of any violation of the regulations in this part may be directed to the United States Attorney for the judicial district in which the violation occurs.
    - To the Substance Abuse and Mental Health Services Administration (SAMHSA) office responsible for opioid treatment program oversight at: SAMHSA Opioid Treatment Program Compliance Officer contact the SAMHSA Center for Substance Abuse Treatment (CSAT) at 866-BUP-CSAT (866-287-2728) or infobuprenorphine@samhsa.hhs.gov .
* If a patient commits a crime against personnel of the Part 2 Program, information related to the commission of that crime is not protected.
* Reports of suspected child abuse and neglect made under state law to appropriate state or local authorities are not protected.
* After receiving and reviewing this form, we will ask you to acknowledge that you have received it. If you have questions, or need to reach us for any other reason, you may contact the Chief Privacy Officer at 270 East Lane, Burlingame, CA 94010; (800) 505-5972; or at privacy@lyrahealth.com.

*The Process of Medication Consultations and Visits:* The process and outcome of medication visits may vary depending on the particular problems addressed, the symptoms of the client, and various other factors. While we cannot predict exactly what your experience will be like, we are committed to providing you with the most professional and ethical treatment possible.

Participation in a medication consultation and possible follow up visits can result in a number of benefits to you, including changes in your presenting symptoms. Since these visits often involve discussing unpleasant aspects of your life, you may experience uncomfortable and intense feelings like sadness, guilt, anxiety, frustration and anger. In addition, your physician may prescribe certain medications during your visit, and will discuss this with you directly, including possible side effects. There are no guarantees about what you will experience. Your physician’s role is to help guide you through care by asking relevant questions, providing treatment plans and recommendations, and monitoring your treatment progress.

During your first session, you will discuss with your LCA physician the reasons you are seeking a consultation, goals you would like to achieve, and any background information related to your presenting concerns. You may or may not be recommended medications as a result of this consultation. If you are recommended medications in our platform and choose to follow up, you will be asked to complete assessments between sessions to monitor and guide care. Follow up visits will assess the medication, effects and side effects. The physician will go over an informed consent regarding medications. Please be aware that the Federal Ryan Haight Online Pharmacy Consumer Protection Act of 2008 prevents physicians from prescribing controlled substances through telemedicine without an in-person medical evaluation, and your LCA physician cannot prescribe medications falling into this category.

*Clinical Leave Evaluations.* If applicable, participation in a clinical leave evaluation and possible follow up visits can result in a number of benefits to you, including changes in your presenting symptoms. Since these visits often involve discussing unpleasant aspects of your life, you may experience uncomfortable and intense feelings like sadness, guilt, anxiety, frustration and anger. There are no guarantees about what you will experience. Your provider’s role is to help guide you through care by asking relevant questions, providing treatment plans and recommendations, and monitoring your treatment progress. You acknowledge that an evaluation does not guarantee completion of paperwork or fulfillment of a leave.

*Your Physician's Credentials.* Your physician’s credentials were made available to you before scheduling an appointment. If you have any questions about these credentials, please direct them to your physician. For those states that require it, you can find an explanation of the levels of regulation applicable to mental health clinicians under the *STATE REGULATIONS* section of this document. Please let your physician know if you will be traveling to another state or if you move to another state. In some cases, physicians are not able to provide services to you while you are located in another state, even temporarily.


*Lyra Platform:* As part of care you will use Lyra Platform. You will need to access the Lyra Platform to participate in the video sessions, submit assessments and practices in between sessions, as well as to connect with your physician via secure messaging.

Your physician will orient you to using the Lyra Platform for the above purposes during your first session. If you decide to have your sessions with your physician by phone (without using the video option in the Lyra Platform), your physician will call you at the number you provided at registration. You will still need to use the Lyra Platform to complete and submit assessments.

*Care Assessments:* Before your first session, your physician will ask you to answer a set of questions that will help them get to know you and better tailor treatment to respond to your situation. Throughout your care, your physician will also assign other assessments to be completed before your sessions to inform treatment.

*Therapeutic Focus:* LCA providers do not make recommendations, write letters of recommendation or complete application forms related to medical leave, medical disability, fitness to work, child custody, or emotional support animals.

*Video Care:* Please note that:

* As part of this program you will engage in telehealth sessions with your LCA physician.
* You understand that LCA and its physicians offer telehealth-based medical services, but that these services do not replace the relationship between you and your primary care physician. 
* All telehealth sessions are held by video using the Lyra Website.
* Your physician will initiate the video session with you at the scheduled time using the Lyra Website.
* The video conferencing technology will not be the same as an in-person session with a physician due to the fact that you will not be in the same room as your physician.
* In order to have the best results for this session, you should be in a quiet place with limited interruptions when you start the session.
* There are potential risks to this technology, including interruptions, unauthorized access and technical difficulties that are beyond the control of LCA. You agree to hold CA harmless for delays in evaluation or for information lost due to such technical difficulties or failures.
* Generally speaking, telehealth offers benefits such as improved access to care by enabling patients to remain in their local site (e.g., home or work) while their clinician consults at distant/other sites, efficient mental health evaluation and management, and the expertise of specialists that patients otherwise might not have. There are potential risks associated with telehealth, which include, but may not be limited to: the therapist may determine that the transmitted information is of inadequate quality, thus necessitating a face-to-face meeting with you; delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment; security protocols could fail, causing a breach of privacy of personal medical information; lack of access to complete medical records, which could result in adverse drug interactions or allergic reactions or other judgment errors in rare cases; and, it may become clear that telehealth is not an appropriate treatment format given your presenting symptoms or level of functioning, resulting in a recommendation that you obtain additional in-person care.
* Your Lyra physician or yourself can discontinue the telehealth session if it is felt that the videoconferencing connections are not adequate for the situation.
* Your LCA therapist will inform you and obtain your consent if another person is present during the consultation, for any reason. For example, persons may be present during the consultation other than the LCA therapist in order to operate the telehealth technologies. You will be informed of their presence in the consultation and will have the right to request the following: (a) omit specific details of your medical history/examination that are personally sensitive to you; (b) ask non-medical personnel to leave the telehealth examination; and/or (c) terminate the consultation at any time.
* There are alternatives to a telehealth session available, and a member of our Care Navigator Team can assist you in accessing those options.
* You can direct questions about your telehealth sessions at any time to your LCA physician or Lyra Care Navigator Team member.
* This consent will last for the duration of the relationship with your LCA physician, and it covers all telehealth sessions you may have.
* You can withdraw your consent for telehealth sessions in the course of your care at any time for any reason or no reason, without affecting your right to future care or treatment, and Lyra will work with you to try to find a suitable alternative.
* The same confidentiality protections, limits to confidentiality, and rules around your records apply to a telehealth session as they would to an in-person session.
* Your LCA physician may decide to terminate telehealth services, if they deem it inappropriate for you to continue care through video sessions. Your LCA physician will work with other team members to identify another option for care. You understand that while you may expect anticipated benefits from the use of telehealth in your care, no results can be guaranteed or assured.
* By signing this consent you agree to work with your LCA physician to come up with a safety plan, including identifying one or two emergency contacts, in the event of a crisis situation during your sessions.

*Video Recording:* Your physician will record all video sessions using the Lyra Website for ongoing quality improvement, quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating the clinical record, and development of our services. All content will be reviewed and approved by your physician before inclusion in the clinical record. The video recordings are kept for 2 weeks and then deleted. If you do not agree to have your sessions recorded for quality purposes please let your physician know at your first appointment. We may retain some information from the session in a de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

*Your Location During Video Sessions:* Like all health care providers, LCA’s physicians are licensed to offer services on a state by state basis. In order to ensure your LCA physician is appropriately licensed, we ask that you please search for a provider based upon your planned location at the time your session takes place. If your location changes at any point from one state to another, please let your LCA physician know as soon as possible. Please be aware that your LCA physician may not be able to provide services when you travel to a different state and is not able to provide services when you travel outside of the U.S.

*Missed Appointments and Cancellations:* Your physician requires at least 24 hours advance notice for cancellation of an appointment. If you are unable to attend your scheduled appointment due to circumstances beyond your control, please message your physician in the Lyra Website as soon as possible so we can reschedule your appointment to a more suitable time. Once an appointment is scheduled, you will be expected to attend unless you contact your physician to reschedule. You can also use email to reschedule; however, please note that receiving emails in your personal account may be unsecure. Failure to provide sufficient notice for cancellations and/or repeated no-shows for your appointment may impact your ability to schedule additional appointments with your physician. If you cancel or reschedule within 24 hours of a scheduled session, or you do not attend a scheduled session, you may be charged a $350.00 Late Cancellation Payment for your Initial Visit and a $200.00 Late Cancellation Payment for a Follow-Up visit.

*In-Between Session Communication:* If you need to contact your LCA physician between sessions, for non-urgent issues, please message them within the Lyra Website and they will address them as soon as possible. Your physician checks his/her messages in the Lyra Website regularly during business hours and will make every effort to return your message within 2 business days. These messages will remain available for thirteen (13) months and then will be deleted.

#### Scope of Service

LCA physicians can only treat mental health related diagnoses. If you have a general medical concern, please address with your primary care physician.

Your LCA physicians may recommend medications as part of your consultation or visits. All of the medications prescribed are FDA approved for at least one indication and in most cases will be FDA approved for what is being addressed in your visits. On occasion, physicians may prescribe medications for uses that are not included in the FDA approval. Many medications have studies or uses that become apparent after their initial approval. Recommendations in these cases are offered with careful clinical consideration and use of evidence-based literature/research.

#### Release of Information

As part of continuity of care or if your LCA physician has a medical concern, you authorize them to contact your primary care physician. You also authorize your LCA physician to access your pharmacy and lab history. You may also be asked to sign a release of information for others who may be helpful in your care.

*Emergency Procedures:* In a crisis, please call 911, go to the local emergency room. Please do not use the Lyra Care messaging or email if you are experiencing an emergency.

If you experience an emergency during your sessions with your LCA physician, or if your LCA physician becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your LCA physician and LCA will work within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, LCA or your LCA physician may contact the person whose name you have provided on the biographical sheet as your emergency contact.

*Social Media Policy:* LCA and our physicians believe that adding clients as friends or contacts on social media sites (e.g., Facebook, LinkedIn, etc.) can compromise clients’ confidentiality and privacy. Consequently, given the importance of client confidentiality, your LCA physician will not accept friend or contact requests from current or former clients on any social networking site. If you have questions about this, please bring them up during your session.

*Billing and Payments:* The fees for your sessions may be partially or fully paid for by your, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to you, as long as you are eligible, and as long as further sessions are considered clinically appropriate. Lyra’s services are not covered by Medicare. If you have insurance coverage through Medicare, you are financially responsible for any costs that are not covered by your employer or another Health Plan in which you are enrolled. Please check with Lyra at 1-(877)505-7147 prior to your session if you have any questions about how your session will be covered. Please note that certain professional services outside of the presenting problems, such as chart preparation requests and participation in legal proceedings, may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future and may also change if you move to another state. If you have questions about the full cost of a session prior to any health plan cost coverage, you may contact the Lyra Care Navigator Team at care@lyrahealth.com

*Research, Writing, Teaching:* Your physician and others at LCA conduct internal research to improve our services, training, and supervision. Your physician may use information about you and your treatment in any of these ways. Your physician or others at LCA may also prepare publications for professional and/or lay audiences, and any use of information about your treatment would be only in an anonymized and/or de-identified way for these publications.

*Length of Treatment:* Your course of treatment will be individualized depending on the intensity and duration of your presenting concerns, your level of engagement and active participation in the treatment plan, and the specific nature of your concerns.

*Ending Treatment:* You may withdraw from treatment at any time. Your physician recommends that you discuss your plan to end treatment with them before taking action, so that they have an opportunity to offer further recommendations or referral options. Additionally, we recommend not discontinuing medications abruptly and without discussing with your physician.

If at any point during care your physician assesses that the sessions are not effective in helping you reach the therapeutic goals, he/she will discuss this with you and, if appropriate, end treatment. In such a case, they will work with you and the Lyra Care Navigator Team to identify alternative options.

If you do not keep your scheduled appointments and do not respond to communications from your physician, we will assume you have elected to end your treatment and we will close your case. If this occurs, and you wish to resume your treatment, please contact the Lyra Care Navigator Team to reinitiate care.

*State Regulations:* Please read the disclosures set forth next to the state in which you are located at the time of the telehealth encounter, as set forth below.

${stateRegulationsContent}

#### TREATMENT AGREEMENT
If at any time you have questions about the details above, please ask your physician or the Lyra Care Navigator Team. By continuing your sessions with your physician you accept and agree to abide by the contents and terms of this agreement and consent to participate in evaluation and/or treatment.

#### Notice of Open Payments Database
For informational purposes only, a link to the federal Centers for Medicare and Medicaid Services (CMS) Open Payments web page is provided [here](https://openpaymentsdata.cms.gov/). The federal Physician Payments Sunshine Act requires that detailed information about payment and other payments of value worth over ten dollars ($10) from manufacturers of drugs, medical devices, and biologics to physicians and teaching hospitals be made available to the public.
`

export const coachingInformedConsent = `
### Consent for Coaching and Other Participation Terms

#### Welcome!

We are excited to welcome you to Lyra Care Coaching. Please review the information below that provides an overview of the program and guidelines for how to make the best use of it.

#### Lyra Care Coaching

Lyra Clinical Associates P.C. (“LCA”) is making Lyra Care Coaching (also referred to as “Coaching” in the following) available to you. Coaching is a non-therapeutic, active and present focused intervention that helps people make desired changes in their lives and enhance their wellbeing. Coaching is driven by your goals, or what you want to achieve. By partnering with your coach you can strengthen and build skills to better address issues like stress management, excessive anxiety or sadness, relationship difficulties, adjusting to changes, clarifying your goals and values in life, making decisions, getting unstuck from a particular situation, navigating cross-cultural differences, exploring your identity, etc.

#### Teamwork

Coaching will be most effective if you work with your coach as a team. As with any team, each partner needs to contribute for the effort to be successful. Below are descriptions of these contributions.

#### Your contribution:

* Attend coaching sessions as agreed, except when you’re ill or during emergencies.
* Minimize rescheduling and cancellations of sessions and give 24 hours notice for any rescheduling or cancellation of sessions.
* Actively participate in sessions.
* Understand that you are free to reject any advice, suggestions, or skills from the coach. (In cases where our clinical policies require a transition to therapy, your coach will communicate this to you and help you connect to a new provider.)
* Do your best to follow-up on and implement any plans you develop in collaboration with your coach.
* Understand that the coach is not a psychotherapist.

#### Coach contribution:

* Attend all sessions on time and be well prepared to work with you.
* Collaborate with you in the development of coaching goals and a coaching plan.
* Be a good listener to your situation and concern.
* Present core skills for addressing your situation and collaborate on creating an action plan.
* Check in with you on the action plan and your progress on building skills.
* Help problem solve any barriers to applying the skills in your life.
* Maintain confidentiality as described below.
* Seek supervision or consultation as appropriate.

#### Length of Coaching

The coaching program is based upon evidence-based practices that have been proven to help clients address concerns like excessive anxiety or sadness. Each coaching session is 45 minutes in length. Most clients notice an initial positive impact of coaching within the first few sessions, and feel ready to complete within a few months. Your coach will support you to determine the length of coaching that will best meet your needs.

#### Coaching is Different from Therapy

Your coach is not trained as a psychotherapist. Coaching is not a substitute for any form of medically prescribed or therapeutic services (including psychiatric services, psychotherapy, or counseling). Coaching does not involve the diagnosis or treatment of mental health disorders as defined by the American Psychiatric Association. If, during the course of Coaching, psychotherapy is preferred or necessary to address your needs, your coach will connect you with the Lyra Care Navigator Team to identify an appropriate provider.

In most cases, we do not recommend participation in Coaching if: a) you are currently receiving individual therapy, or b) you have started a medication within the past 6 months for mental health concerns, with the exception of an antidepressant or ADHD/stimulant medication. If you are taking medication for a mental health concern, you are responsible for scheduling regular appointments with your medication provider and directing questions regarding your medication to that provider.

#### The Lyra Platform

As part of Coaching you will need to access the Lyra Platform to: a) participate in the video or live messaging coaching sessions, b) submit assessments and practices in between sessions, and c) connect with your coach via secure messaging. Your coach will orient you to using the Lyra Platform during your first session.

You can decide to have your coaching sessions by phone rather than by video. In this circumstance your coach will call you for the session at the number you provided at registration. You will still need to use the Lyra Platform to complete assessments, coaching learning activities, and practices. Your use of Lyra’s website is subject to our [Privacy Policy](https://www.lyrahealth.com/privacy-policy/) and [HIPAA Notice (U.S.).](https://www.lyrahealth.com/hipaa-notice/)

#### Coaching Assessments

Before your first session, your coach will ask you to answer some questions that will help them better understand your situation and how they can be most helpful. Throughout coaching, you will be encouraged to complete additional assessments between sessions to inform your work together and track progress.

#### Open Communication

It is important for your coach that your needs are being met in the program and that you are getting the most out of your coaching experience. If for any reason you consider this is not happening, please let the coach or the Lyra Care Navigator team know immediately so we can make adjustments or consider alternative ways to improve your care experience.

#### In-Between Session Communication

If you need to contact your coach between sessions, please message them within the Lyra Platform and they will address them as soon as possible. Your coach checks their messages in the Lyra Website regularly during business hours and will make every effort to return your message within 2 business days. These messages will remain available for thirteen (13) months and then will be deleted.

#### Emergency Procedures

In a crisis, please call 911, go to the local emergency room. Please do not use the Lyra Platform or email if you are experiencing an emergency.

If you experience an emergency during your sessions with your Coach, or if your Coach becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your Coach and LCA will do whatever possible within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, LCA or your Coach may contact the person whose name you have provided as your emergency contact.

#### Billing and Payments

The fees for your sessions may be partially or fully paid for by your, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to you, as long as you are eligible, and as long as further sessions are considered clinically appropriate. *Lyra’s services are not covered by Medicare. If you have insurance coverage through Medicare, you are financially responsible for any costs that are not covered by your employer or another Health Plan in which you are enrolled.* Please check with Lyra at 1-(877)505-7147 prior to your session if you have any questions about how your session will be covered. Please note that certain professional services outside of the presenting problems, such as chart preparation requests and participation in legal proceedings, may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future. If you have questions about the full cost of a session prior to any health plan cost coverage, you may contact the Lyra Care Navigator Team at care@lyrahealth.com

#### Rescheduling/Cancellations

Rescheduling and cancellations of existing sessions negatively impact our coaches availability to book new sessions and help clients. To minimize this impact we ask you to please keep rescheduling and cancellations to a minimum. Your coach also requires 24 hours notice to cancel or reschedule sessions.

*If you cancel or reschedule within 24 hours of a scheduled session, or you do not attend a scheduled session you may be charged a $65.00 Late Cancellation Payment.*

#### Confidentiality

Your privacy and confidentiality is important to us. We want to let you know that when care transitions are indicated, information regarding your care needs (e.g. difficulty sleeping, high stress) may be shared with other members of the Lyra Care Navigator Team in order to help coordinate care. Coaches on the team also consult with one another to make your care even more effective. Coaches may consult with their peer consultation group and/or the clinical team to make your care even more effective. During consultations coaches make every effort to protect the identity of their clients.

In certain instances your coach may disclose information about your sessions without requiring your consent.

These instances include:

* When there is suspected abuse or neglect of an elder, dependent adult, or child.
* When, in the coach’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
* If you communicate to the coach a serious threat of physical violence against another person, the coach is required by law to inform both potential victims and legal authorities.
* If the coach is ordered by a court to release information as part of a legal proceeding.
* As otherwise required by law.

If you experience an emergency during your sessions with your coach, your coach will take appropriate steps to ensure your safety and help get you to the appropriate care. In the cases where your coach becomes concerned about your personal safety or the possibility of you injuring someone else, the coach may need to disclose information to a third party in order to prevent you from injuring yourself or others and/or to refer you to appropriate medical care.

Given data security guidelines, you will not be eligible to have coaching sessions or message with your coach when you are outside of US territories. You will, however, still be able to access the platform to complete activities and assessments if that could be supportive for you.

For more information, see [Lyra’s Privacy Policy](https://www.lyrahealth.com/privacy-policy/) and [Lyra Clinical Associate P.C.’s HIPAA Notice](https://www.lyrahealth.com/hipaa-notice/). If you have additional questions about how Lyra protects your privacy, you can also email care@lyrahealth.com.

#### Video Coaching Technology

We make every effort to ensure a seamless and secure experience for your sessions, however there are potential risks to this video technology, including interruptions, unauthorized access, and technical difficulties. If you experience a situation where you are not confident of the continued security or quality of your session, such as interference with your signal, poor quality of the video, or continued interruptions, you or your coach can discontinue the session. In order to have the best results for coaching sessions, you should be in a quiet place with limited interruptions when you start the session.

#### Live Messaging Sessions

Some coaches offer sessions via live messaging. These sessions are scheduled and the messages are stored in your message section on the Lyra Platform for the duration of your episode. All session transcripts will be deleted 1 week after your episode ends. Any messages stored in your message section are available for thirteen (13) months and then deleted.

#### Video Recording

Your coach will record the coaching video sessions with your permission using the Lyra Platform for quality improvement, quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating session documentation, and development of our services. All content will be reviewed and approved by your coach before inclusion in the session documentation. If you do not want your session recorded, please let your coach know at any point during the session and the recording up to that point will be deleted. When you give your permission and the sessions are recorded, the video recordings are kept for 2 weeks and then deleted. We may retain some information from the session in a de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

#### Research, Writing, Teaching

Your coach and others at Lyra conduct internal research to improve our services, training, and supervision. Your coach may use de-identified information about you and your care in any of these ways. Your coach or others at Lyra may also prepare publications for professional and/or lay audiences; any information about your care would be used only in an anonymized and/or de-identified way for these publications.

#### Social Media

LCA believes that adding clients as friends or contacts on any social networking sites (e.g., Facebook, LinkedIn, etc.) can compromise your confidentiality and you and your coach’s respective privacy. As such coaches do not accept friend or contact requests from current or former clients on any social networking site (e.g., Facebook, LinkedIn, etc.). If you have questions about this, please discuss them with your coach.

By accepting, you are confirming that you have read and understood this agreement and have had your questions answered to your satisfaction. You accept, understand, and agree to abide by the contents and terms of this agreement and consent to participate.
`

export const coachingInformedConsentNonUS = `
### Consent for Coaching

#### Welcome!

We are excited to welcome you to Lyra Care Coaching. Please review the information below that provides an overview of the program and guidelines for how to make the best use of it.

#### Lyra Care Coaching

Lyra Health, Inc. (“Lyra”) is making Lyra Care Coaching (also referred to as “Coaching” in the following) available to you through its affiliates and contracted partners. Coaching is a non-therapeutic, active and present focused intervention that helps people make desired changes in their lives and enhance their wellbeing. Coaching is driven by your goals, or what you want to achieve. By partnering with your coach you can strengthen and build skills to better address issues like stress and burnout, anxiety or sadness, relationship difficulties, adjusting to changes, clarifying your goals and values in life, making decisions, getting unstuck from a particular situation, navigating cross-cultural differences, exploring your identity, etc.

#### Teamwork

Coaching will be most effective if you work with your coach as a team. As with any team, each partner needs to contribute for the effort to be successful. Below are descriptions of these contributions.

#### Your contribution:

* Commit to actively participate in the program, including completing forms and assessments shared with you.
* Attend coaching sessions as agreed, except when you’re ill or during emergencies.
* Minimize rescheduling and cancellations of sessions and give 24 hours notice for any rescheduling or cancellation of sessions.
* Actively participate in sessions.
* Understand that you are free to reject any advice, suggestions, or skills from the coach. (In cases where our clinical policies require a transition to therapy, your coach will communicate this to you and help you connect to a new provider.)
* Do your best to follow-up on and implement any plans you develop in collaboration with your coach.
* Understand that the coach is not a psychotherapist.

#### Coach contribution:

* Attend all sessions on time and be well prepared to work with you.
* Collaborate with you in the development of coaching goals and a coaching plan.
* Be a good listener to your situation and concern.
* Present core skills for addressing your situation and collaborate on creating an action plan.
* Check in with you on the action plan and your progress on building skills.
* Help problem solve any barriers to applying the skills in your life.
* Maintain confidentiality as described below.
* Seek supervision or consultation as appropriate.

#### Length of Coaching

The coaching program is based upon evidence-based practices that have been proven to help clients address concerns like excessive anxiety or sadness. Each coaching session is 45 minutes in length. Most clients notice an initial positive impact of coaching within the first few sessions, and feel ready to complete within a few months. Your Coach will support you to determine the length of coaching that will best meet your needs.

#### Coaching is Different from Therapy

Your coach is not trained as a psychotherapist. Coaching is not a substitute for any form of medically prescribed or therapeutic services (including psychiatric services, psychotherapy, or counseling). Coaching does not involve the diagnosis or treatment of mental health disorders as defined by the relevant authorities in your country. If, during the course of Coaching, psychotherapy or counseling is preferred or necessary to address your needs, your coach will provide you with instructions on how to connect with the Lyra care team to identify an appropriate provider that meets your needs and preferences.

#### The Lyra Platform 

As part of Coaching you will need to access the Lyra Platform to: a) participate in the video coaching sessions, b) submit assessments and practices in between sessions, and c) connect with your coach via secure messaging. During your first session, your coach can help answer any questions you might have about using the Lyra Platform.

You can decide to have your coaching sessions by phone rather than by video. In this circumstance your coach will call you for the session at the number you provided at registration. You will still need to use the Lyra Platform to complete assessments and complete coaching learning activities and practices.

#### Coaching Assessments

Before your first session, your coach will ask you to answer some questions that will help them better understand your situation and how they can be most helpful. Throughout coaching, your coach will also request that you complete additional assessments between sessions to inform your work together. You will have access to see your assessment results in the Lyra Platform.

#### Open Communication

It is important to your coach that your needs are being met in the program and that you are getting the most out of your coaching experience. If for any reason you believe that this is not happening, please let your coach or the local Lyra care team know immediately so we can make adjustments or consider alternative ways to improve your care experience.

#### Messaging and Email Communication

If you need to contact your coach between sessions, please message them within the Lyra Platform and they will address as soon as possible. Your coach checks their messages in the Lyra Platform regularly during business hours and will make every effort to return your message within 2 business days. 

Before your first session and between sessions, you will receive automated emails from Lyra to notify you of upcoming sessions, activities needing to be completed before your next session, and when your coach has sent you a message on the Lyra Platform.

#### Rescheduling/Cancellations

Rescheduling and cancellations of existing sessions negatively impact our coaches’ availability to book new sessions and help clients. To minimize this impact we ask you to please keep rescheduling and cancellations to a minimum. We also require 24 hours’ notice to cancel or reschedule sessions. 

#### Confidentiality

Your privacy and confidentiality is important to us. If a care transition to another care modality is recommended, information regarding your care needs (e.g. difficulty sleeping, high stress) may be shared with other members of the local Lyra care team or with Lyra’s care partners in order to help coordinate care. Coaches may consult with their peer consultation group and/or the clinical team to make your care even more effective. During consultations coaches make every effort to abide by applicable laws to ensure that your confidentiality is protected.

In certain instances as required by local law in your location, your coach may disclose information about you without requiring your consent. These instances may include:

* When there is suspected abuse or neglect of another (such as child abuse or elder abuse).
* When, in the coach’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
* If you communicate to the coach a serious threat of physical violence against another person.
* If the coach is ordered by a court to release information as part of a legal proceeding.
In such circumstances, Lyra will review local laws of your location before disclosing information to ascertain whether disclosure is required, in order to maintain its commitment to confidentiality to you.

If you experience an emergency during your sessions with your coach, your coach will take appropriate steps to ensure your safety and help get you to the appropriate care. In the cases where your coach becomes concerned about your personal safety or the possibility of you injuring someone else, the coach may need to disclose information to a third party in order to prevent you from injuring yourself or others and/or to refer you to appropriate medical care. Members of the local Lyra care team may also reach out to you proactively where they become aware of other high risk factors or emergency situations. Again this to ensure that you receive appropriate care. You may opt-out of this outreach at any time by contacting us. 

#### Privacy

Lyra is deeply committed to protecting your privacy and complies with all applicable privacy and data protection laws. Your information will only be used for the purposes explained here and in our privacy policies as referenced below, such as providing you with coaching services, improving the quality of those services, enabling care and fulfilling any legal obligations. 

By participating in Coaching, you are agreeing to the terms and conditions outlined in this document, which includes the handling of your personal information as described herein.

* For more information about how your personal information is collected and used in connection with Coaching, and information on your privacy rights, please see [Lyra’s US Privacy Policy](https://www.lyrahealth.com/privacy-policy/) if you are located in the United States, or the [Lyra Health International’s Privacy Policy](https://lyrahealthinternational.com/privacy-policy/) if you are located outside of the United States. If you have additional questions about how Lyra protects your privacy, you can email [privacy@lyrahealth.com](mailto:privacy@lyrahealth.com) in the United States or [globaldpo@lyrahealth.com](mailto:globaldpo@lyrahealth.com) outside of the United States.

_Consent for use of Sensitive Personal Information_

By agreeing to participate in the Coaching program, you consent to the collection, use and sharing of your sensitive personal information in connection with the program. This may include health-related information, mental health assessments, and video recordings of your coaching sessions (if applicable). This information is essential for your coach to provide effective support, tailor the coaching to your specific needs, ensure the overall quality and effectiveness of the services offered, and facilitate care. Lyra takes great care to protect this sensitive information and uses it only for the purposes outlined in this document and our privacy policies referred to above.

_Withdrawal of Consent_

You can withdraw your consent to the processing of your personal information at any time, subject to certain legal limitations. This means that if you decide at any point that you no longer wish to participate in the Lyra Care Coaching program or do not want your personal information to be processed as described, you can withdraw your consent by __sending an email to globaldpo@lyrahealth.com__. Upon withdrawing your consent, Lyra will stop processing your information for the specified purposes unless required by law or necessary to fulfill a contract. However, please be aware that withdrawing your consent may affect your ability to continue receiving coaching services.

_Parental Consent_

If you are under 18, your parent or legal guardian must provide their consent for your participation in the Coaching program, as well as for the collection, use, and processing of your personal information. If Lyra becomes aware that personal information has been collected from a minor without the appropriate consent, the information will be deleted in line with applicable privacy laws.

_Proactive Outreach_

As part of our commitment to your wellbeing, Lyra may use your personal information to reach out to you proactively if our coaches or other members of the local Lyra care team identify that you may benefit from additional care or support based on the information you have provided. This outreach to enable and facilitate care will be conducted in a manner which respects your privacy. You may opt-out of this proactive outreach at any time by contacting us. 


#### Emergency Procedures

In a crisis, please call your local emergency number, go to the local emergency room. Please do not use the Lyra Care messaging or email if you are experiencing an emergency.

If you experience an emergency during your sessions with your coach, or if your coach becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your coach, Lyra’s team, and affiliates will do whatever possible within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, Lyra or your coach may contact the person whose name you have provided as your emergency contact.

#### Video Coaching Technology

We make every effort to ensure a seamless and secure experience for your sessions, however there are potential risks to this video technology, including interruptions, unauthorized access, and technical difficulties. If you experience a situation where you are not confident of the continued security or quality of your session, such as interference with your signal, poor quality of the video, or continued interruptions, you or your coach can discontinue the session and switch to a more secure platform. In order to have the best results for coaching sessions, you should be in a quiet place with limited interruptions when you start the session.

#### Video Recording

Your coach will record the coaching video sessions with your permission using the Lyra Platform for ongoing quality improvement and for quality assurance of our services. If you do not want your session recorded, please let your coach know at any point during the session and the recording up to that point will be deleted. When you give your permission and the sessions are recorded, the recordings are kept for 2 weeks and then deleted.

#### Research, Writing, Teaching

Your coach and others at Lyra conduct internal research, training, and supervision. Your coach may use information about you and your care in any of these ways. Your coach or others at Lyra may also prepare publications for professional and/or lay audiences; any information about your care would be used only in an anonymized and/or de-identified way for these publications.

#### Social Media:

Lyra believes that adding clients as friends or contacts on any social networking sites (e.g., Facebook, LinkedIn, etc.) can compromise your confidentiality and the privacy of you and your coach. As such coaches do not accept friend or contact requests from current or former clients on any social networking site (e.g., Facebook, LinkedIn, etc.). If you have questions about this, please discuss them with your coach.
`
export const singleSessionCoachingInformedConsent = `
### Consent for Guided Self-Care

#### Welcome!

We are excited to welcome you to Guided Self-Care. Please review the information below that provides an overview of the program and guidelines for how to make the best use of it.

#### Guided Self-Care

Lyra Clinical Associates P.C. (“LCA”) is making Guided Self-Care (also referred to as “GSC” in the following) available to you. Guided Self-Care is a non-therapeutic, active and present focused intervention that helps people make desired changes in their lives and enhance their wellbeing. As part of GSC, LCA will pair you with a Coach to help craft your goals for the program based on what you want to achieve. By participating in the program and getting support from your coach, you can strengthen and build skills to better address issues like stress management, anxiety, sadness, relationship difficulties, adjusting to changes, clarifying your goals and values in life, making decisions, getting unstuck from a particular situation, etc.

#### Your contribution:

* Commit to actively participate in the program.
* Commit to weekly review of activities.
* Commit to taking assessments every 3 weeks.
* Attend the coaching session as agreed, unless you're ill or have an emergency.
* Actively participate within a session.
* Understand that you are free to reject any advice, suggestions, or skills from the coach.
* Do your best to follow-up on and implement any plans you develop in collaboration with your coach.
* Understand that the coach is not a psychotherapist.

#### Coach contribution:

* Collaborate with you in the development of coaching goals and a coaching plan.
* Review your activities and provide feedback.
* Review assessment results.
* Maintain confidentiality as described below.
* Seek supervision or consultation as appropriate.

#### Length of Guided Self-Care

The coaching program is based upon evidence-based practices that have been proven to help clients address concerns like excessive anxiety or sadness. GSC clients typically work with their coach for one session and then continue through the program with personalized activities for 6 weeks with support from the coach via the GSC messaging system. You may request an additional session during the 6 week program.

#### Guided Self-Care is Different from Therapy

Your coach is not trained as a psychotherapist. This program is not a substitute for any form of medically prescribed or therapeutic services (including psychiatric services, psychotherapy, or counseling). The program does not involve the diagnosis or treatment of mental health disorders as defined by the American Psychiatric Association. If, during the course of Guided Self-Care, psychotherapy is preferred or necessary to address your needs, your coach will connect you with the Lyra care team to identify an appropriate provider.

We do not recommend participation in Guided Self-Care if: a) you are currently receiving individual therapy, b) you have started a medication within the past 6 months for mental health concerns or c) you have a history of hospitalization for behavioral health concerns.

#### The Lyra Website

As part of Guided Self-Care you will need to access the Lyra Website to: a) participate in the video coaching session(s) (see more detail below about Video Sessions), b) submit assessments and practices in between sessions, and c) connect with your coach via secure messaging. Your coach will orient you to using the Lyra Website during your session.

You can decide to have your coaching session(s) by phone rather than by video. In this circumstance your coach will call you for the session at the number you provided at registration. You will still need to use the Lyra Website to complete assessments and assignments. Your use of Lyra’s website is subject to our [Privacy Policy](https://www.lyrahealth.com/privacy-policy/) and [HIPAA Notice (U.S.).](https://www.lyrahealth.com/hipaa-notice/)

#### Assessments

Before your first session, your coach will ask you to answer some questions that will help them better understand your situation and how they can be most helpful. Throughout the program, you will also be asked to fill out assessments to track your progress.

#### Messaging Communication

You are encouraged to contact your coach to ask questions and share progress. Please message them within the Lyra Website and they will address them as soon as possible. Your coach checks their messages in the Lyra Website regularly during business hours and will make every effort to return your message within 2 business days.

All messages will be deleted after 13 months.

#### Emergency Procedures

In a crisis, please call 911, go to the local emergency room. Please do not use the Lyra Care messaging or email if you are experiencing an emergency.

If you experience an emergency during your sessions with your coach, or if your coach becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your coach and LCA will do whatever possible within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, LCA or your coach may contact the person whose name you have provided as your emergency contact.

#### Rescheduling/Cancellations

Rescheduling and cancellations of existing sessions negatively impact our coaches availability to book new sessions and help clients. To minimize this impact we ask you to please keep rescheduling and cancellations to a minimum. We also require 24 hours notice to cancel or reschedule sessions.

*If you cancel or reschedule within 24 hours of a scheduled session, or you do not attend a scheduled session you may be charged a $65.00 Late Cancellation Payment.*

#### Confidentiality

Your privacy and confidentiality is important to us. We want to let you know that when care transitions are indicated, information regarding your care needs (e.g. difficulty sleeping, high stress) may be shared with other members of the Lyra care team in order to help coordinate care. Coaches on the team also consult with one another to make your care even more effective. During consultations coaches make every effort to protect the identity of their clients.

In certain instances your coach may disclose information about your sessions without requiring your consent. These instances include:

* When there is suspected elder, dependent adult, or child abuse or neglect.
* When, in the coach’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
* If you communicate to the coach a serious threat of physical violence against another person, the coach is required by law to inform both potential victims and legal authorities.
* If the coach is ordered by a court to release information as part of a legal proceeding.
* As otherwise required by law.

If you experience an emergency during your sessions with your coach, your coach will take appropriate steps to ensure your safety and help get you to the appropriate care. In the cases where your coach becomes concerned about your personal safety or the possibility of you injuring someone else, the coach may need to disclose information to a third party in order to prevent you from injuring yourself or others and/or to refer you to appropriate medical care.

For more information, see [Lyra’s Privacy Policy](https://www.lyrahealth.com/privacy-policy/) and [Lyra Clinical Associate P.C.’s HIPAA Notice](https://www.lyrahealth.com/hipaa-notice/). If you have additional questions about how Lyra protects your privacy, you can also email care@lyrahealth.com.

#### Video Sessions
#### Coaching Technology

We make every effort to ensure a seamless and secure experience for your session(s), however there are potential risks to this video technology, including interruptions, unauthorized access, and technical difficulties. If you experience a situation where you are not confident of the continued security or quality of your session, such as interference with your signal, poor quality of the video, or continued interruptions, you or your coach can discontinue the session. In order to have the best results for coaching sessions, you should be in a quiet place with limited interruptions when you start the session.

#### Recording

Your coach will record the coaching video sessions with your permission using the Lyra Website for ongoing quality improvement, quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating session documentation, and development of our services. All content will be reviewed and approved by your coach before inclusion in the session documentation. If you do not want your session recorded, please let your coach know at any point during the session and the recording up to that point will be deleted. When you give your permission and the sessions are recorded, the video recordings are kept for 2 weeks and then deleted. We may retain some information from the session in a fully de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

#### Research, Writing, Teaching

Your coach and others at LH conduct internal research to improve our services, training, and supervision. Your coach may use information about you and your care in any of these ways. Your coach or others at LH may also prepare publications for professional and/or lay audiences; any information about your care would be used only in an anonymized and/or de-identified way for these publications.

#### Open Communication

It is important for your coach that your needs are being met in the program and that you are getting the most out of your coaching experience. If for any reason you consider this is not happening, please let the coach or the Lyra care team know immediately so we can make adjustments or consider alternative ways to improve your care experience.

#### Social Media

LCA believes that adding clients as friends or contacts on any social networking sites (e.g., Facebook, LinkedIn, etc.) can compromise your confidentiality and you and your coach’s respective privacy. As such coaches do not accept friend or contact requests from current or former clients on any social networking site (e.g., Facebook, LinkedIn, etc.). If you have questions about this, please discuss them with your coach.

#### Billing and Payments

The fees for your sessions may be partially or fully paid for by your, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to you, as long as you are an eligible employee in good standing with the company, and as long as further sessions are considered clinically appropriate. *Lyra’s services are not covered by Medicare. If you have insurance coverage through Medicare, you are financially responsible for any costs that are not covered by your employer or another Health Plan in which you are enrolled.* Please check with Lyra at 1-(877) 505-7147 prior to your session if you have any questions about how your session will be covered. Please note that certain professional services outside of the presenting problems, such as chart preparation requests and participation in legal proceedings, may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future. If you have questions about the full cost of a session prior to any health plan cost coverage, you may contact the Lyra Care Team at care@lyrahealth.com

By accepting, you are confirming that you have read and understood this agreement and have had your questions answered to your satisfaction. You accept, understand, and agree to abide by the contents and terms of this agreement and consent to participate.
`

export const audInformedConsent = `
### Consent For Treatment

This document contains important information about the professional services and business policies of both your Lyra Clinical Associates (LCA) treatment team (which may include but is not limited to a therapist, peer support, physicians, clinicians, and operational support) , and Lyra Clinical Associates P.C. Please read it carefully and discuss any questions you have with your therapist.

*Confidentiality:* 

Your treatment team and LCA take your privacy and confidentiality very seriously. LCA complies with Federal and California laws regarding confidentiality of client information. LCA’s [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/) details how we use any information we collect, including for treatment, coordination of care, payment and other business operations. We will minimize the amount of information we share without your express consent, however we want to bring your attention to certain circumstances in which we may disclose details of your care:

* Your treatment team may share information about your assessment and treatment in an anonymized way with the clinical team at LCA in order to improve your experience and guarantee that we are providing you with the most effective care possible.
* If there is suspected elder, dependent adult, or child abuse or neglect.
* If, in your treatment team’s judgment, you are in danger of harming yourself or another person, or are unable to care for yourself.
* If you communicate to your treatment team a serious threat of physical violence against another person; in these circumstances, your treatment team is required by law to inform both potential victims and legal authorities.
* If your treatment team member is ordered by a court to release information as part of a legal proceeding.
* As otherwise required by law and/or detailed in our [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/).

*Confidentiality of Alcohol and Drug Abuse Records:*

As described in LCA’s Notice of Privacy Practices, patient medical records are protected by federal and state laws and regulations. Certain substance use disorder records are also protected by the federal regulations governing the Confidentiality of Substance Use Disorder Patient Records (42 CFR Part 2 (“Part 2”)). LCA works with certain Part 2 behavioral health providers who may provide therapeutic services involving substance use disorder (“Part 2 Program”). Records from any Part 2 Program that may be created in the course of your care through LCA are protected as described in this Notice. The following is a summary of the Part 2 regulations:

* Generally, a Part 2 Program may only acknowledge that an individual is present or disclose outside the Part 2 Program information identifying a patient as having or having had a substance use disorder in the following instances:
    - The patient’s written consent is obtained,
    - An authorizing court order is entered,
    - The patient’s records are disclosed to medical personnel to the extent necessary to meet a bona fide medical emergency,
    - The disclosure is for the purpose of conducting scientific research, or
    - The disclosure is for the purpose of an audit or evaluation.

Violation of the federal law and regulations at Part 2 is a crime and suspected violations may be reported as follows:

* Any violation of Part 2 may be reported:
    - To our Chief Privacy Officer at 270 East Lane, Burlingame, CA 94010; (800) 505-5972; or at privacy@lyrahealth.com. 
    - The report of any violation of the regulations in this part may be directed to the United States Attorney for the judicial district in which the violation occurs.
    - To the Substance Abuse and Mental Health Services Administration (SAMHSA) office responsible for opioid treatment program oversight at: SAMHSA Opioid Treatment Program Compliance Officer contact the SAMHSA Center for Substance Abuse Treatment (CSAT) at 866-BUP-CSAT (866-287-2728) or infobuprenorphine@samhsa.hhs.gov .
* If a patient commits a crime against personnel of the Part 2 Program, information related to the commission of that crime is not protected.
* Reports of suspected child abuse and neglect made under state law to appropriate state or local authorities are not protected.
* After receiving and reviewing this form, we will ask you to acknowledge that you have received it. If you have questions, or need to reach us for any other reason, you may contact the Chief Privacy Officer at 270 East Lane, Burlingame, CA 94010; (800) 505-5972; or at privacy@lyrahealth.com.

*The Process of Psychotherapy:* 

The process and outcome of psychotherapy may vary depending on the particular problems addressed, the personalities of the therapist and client, and various other factors. While we cannot predict exactly what your experience will be like, we are committed to providing you with the most professional and ethical treatment possible.

Participation in therapy can result in a number of benefits to you, including improved interpersonal relationships, resolution of specific problems, and positive personal change. In order for therapy to be successful, you will need to make an active effort both during and outside of your sessions. Since therapy often involves discussing unpleasant aspects of your life, you may experience uncomfortable and intense feelings like sadness, guilt, anxiety, frustration and anger. In addition, it may result in changes that were not originally intended (such as divorce or remaining in a relationship you believed you would leave). Change can often happen quickly, but may also occur more slowly. There are no guarantees about what you will experience. Your therapist’s role is to help guide you through the therapy process by offering emotional support, actively listening to your concerns, asking relevant questions, providing treatment plans and recommendations, and monitoring your treatment progress.

During your first session, you will discuss with your LCA therapist the reasons you are seeking therapy, goals you would like to achieve, and any background information related to your presenting concerns. In subsequent sessions, you and your therapist will work collaboratively towards achieving your goals of therapy. Subsequent sessions may focus on understanding thought patterns, feelings, and behaviors that are relevant to presenting concerns and work towards more effective ways of coping. If you are in care with another therapist or your symptoms are mild, you may do individual sessions with a peer support person.

Throughout therapy, you will work with your therapist to tailor treatment to meet your goals and assess whether your goals are being met. If at any point you are unhappy about the progress, process, or outcome of the treatment, please discuss this with your therapist. There are many different methods your therapist may use to address the concerns that bring you to therapy. Your therapist strives for genuineness and a nonjudgmental stance in all of his/her patient relationships. However, if at any time you feel that you are not connecting or that he/she has misunderstood you in an important way, you should discuss them in session with your therapist. In addition, the Lyra Care Team is always available to work with you, and may also reach out to you to discuss your overall satisfaction with the therapy.

*The Process of Groups:* 

Groups can be a helpful way to learn more, build skills and discuss progress between individual sessions. Groups may be facilitated by an unlicensed member of your care team. Topics will include learning about healthy habits, developing coping skills and managing difficult periods. Group sessions also allow members to learn from one another and receive/provide peer support. Your participation is voluntary and you may stop attending groups at any time (and should discuss with your individual treatment team member). If you violate any of the terms of the group parameters, as deemed by the group leader(s), you may be prevented from joining groups in the future. The following are parameters for group participation:

* Sharing information in groups can help yourself and others and is voluntary. You may choose to share as much or as little as you feel comfortable with. Do not share group information outside of the group setting.
* You agree to not disclose any information to people outside of the group that may reasonably be used to identify another member of the group. You agree to not record any voice conversations, videos, and/or create still images of any information shared in the group by the use of any recording device, application, or otherwise. You agree to join the group in a private location and take measures such as wearing a headset to prevent those outside of the group from seeing or hearing the group.
* LCA and its contracted partners reserve the right to make disclosures of any information gathered in the group in a manner which is consistent with our Notice of Privacy Practices and Privacy Policy and/or as otherwise required by applicable state and federal laws.
* You agree to hold harmless LCA, and its contracted partners, in any and all claims related to misuse and/or misappropriation of your personal and/or protected health information, shared during group sessions, by another group member or persons who obtain your information through another group member.

Your Group facilitator may share additional parameters from time to time. To the extent you have questions about these or any other Group parameters, or would like to receive a copy in writing, you should reach out to your care team. If you continue to attend Group sessions, we will assume that you have agreed and consented to the additional parameters.

*The Lyra Website:* 

As part of therapy you will use the Lyra Website. You will need to access the Lyra Website to participate in the video sessions, submit assessments and practices in between sessions, as well as to connect with your therapist via secure messaging.

Your therapist will orient you to using the Lyra Website for the above purposes during your first therapy session. If you decide to have your sessions with your therapist by phone (without using the video option in the Lyra Website), your therapist will call you at the number you provided at registration. You will still need to use the Lyra Website to complete assessments and submit records of therapy practices.

*Therapy Assessments:* 

Before your first session, your therapist will ask you to answer a set of questions that will help them get to know you and better tailor treatment to respond to your situation. Throughout treatment, your treatment team will also assign other assessments to be completed before your sessions to inform treatment.

*Therapeutic Focus:* 

LCA clinicians do not make recommendations, write letters of recommendation or complete application forms related to medical leave, medical disability, fitness to work, child custody, or emotional support animals.

Please note that substance use disorder treatment through Lyra primarily consists of psychotherapy and Group sessions. Medication consultation may be available to you through your employer-sponsored benefit or through your health plan. We recommend you seek medication consultation for substance use disorder where appropriate, although you are not required to do so to participate in other treatment through Lyra.

*Video Therapy:* Please note that:

* As part of this program you will engage in telehealth sessions with your LCA Treatment Team.
* All telehealth sessions are held by video using the Lyra Website. Group sessions will be held on a secure third-party HIPAA compliant platform. 
* Your treatment team member will initiate the video session with you at the scheduled time using the Lyra Website.
* The video conferencing technology will not be the same as an in-person session due to the fact that you will not be in the same room as your treatment team member.
* In order to have the best results for this session, you should be in a quiet and private place with limited interruptions when you start the session.
* There are potential risks to this technology, including interruptions, unauthorized access and technical difficulties.
* Your Lyra treatment team or yourself can discontinue the telehealth session if it is felt that the videoconferencing connections are not adequate for the situation.
* Your LCA treatment team will inform you and obtain your consent if another person is present during the session, for any reason.
* There are alternatives to a telehealth session available, including the option of finding an LCA provider to see in-person.
* You can direct questions about your telehealth sessions at any time to your LCA treatment team or Lyra Care Team.
* This consent will last for the duration of the relationship with your LCA treatment team and this program, and it covers all telehealth sessions you may have.
* You can withdraw your consent for telehealth sessions at any time, and Lyra will work with you to find a suitable alternative.
* The same confidentiality protections, limits to confidentiality, and rules around your records apply to a telehealth session as they would to an in-person session.
* Your LCA treatment team may decide to terminate telehealth services, if they deem it inappropriate for you to continue therapy through video sessions. Your LCA treatment team will work with other team members to identify another LCA provider for in-person care.
* By signing this consent you agree to work with your LCA therapist to come up with a safety plan, including identifying one or two emergency contacts, in the event of a crisis situation during your sessions.

*Video Recording:* Your clinician will record all video sessions using the Lyra Website for ongoing quality improvement, quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating the clinical record, and development of our services. All content will be reviewed and approved by your clinician before inclusion in the clinical record. The video recordings are kept for 2 weeks and then deleted. If you do not agree to have your sessions recorded, please let your clinician know at your first appointment. We may retain some information from the session in a de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

*Your Location During Video Sessions:* Like all health care providers, LCA’s clinicians are licensed to offer services on a state by state basis. In order to ensure your LCA clinician is appropriately licensed, we ask that you please search for a provider based upon your planned location at the time your session takes place. If your location changes at any point from one state to another, please let your LCA clinician know as soon as possible. Please be aware that your LCA clinician may not be able to provide services when you travel to a different state and is not able to provide services when you travel outside of the U.S.

*Missed Appointments and Cancellations:* Your clinician requires at least 24 hours advance notice for cancellation of an appointment. If you are unable to attend your scheduled appointment due to circumstances beyond your control, please message your clinician in the Lyra Website as soon as possible so we can reschedule your appointment to a more suitable time. Once an appointment is scheduled, you will be expected to attend unless you contact your clinician to reschedule. You can also use email to reschedule; however, please note that receiving emails in your personal account may be unsecure. Failure to provide sufficient notice for cancellations and/or repeated no-shows for your appointment may impact your ability to schedule additional appointments with your clinician. If you cancel or reschedule within 24 hours of a scheduled session, or you do not attend a scheduled session you may be charged a $105.00 Late Cancellation Payment.

*Messaging and Email Communication:* If you need to contact your LCA clinician between sessions, for non-urgent issues, please message them within the Lyra Website and they will address them as soon as possible. Your clinician checks his/her messages in the Lyra Website regularly during business hours and will make every effort to return your message by the next business day.

*Emergency Procedures:* In a crisis, please call 911 or go to the local emergency room. Please do not use the Lyra Care messaging or email if you are experiencing an emergency. If you are experiencing withdrawal symptoms requiring medical attention, please call 911 or be transported to the local emergency room. LCA is not able to provide emergency care or medical management of withdrawal.

If you experience an emergency during your sessions with your LCA treatment team, or if your LCA treatment team becomes concerned about your personal safety, the possibility of you injuring someone else, or about you receiving proper psychiatric care, your LCA treatment team and LCA will do whatever possible within the limits of the law to prevent you from injuring yourself or others and to ensure that you receive the proper medical care. For this purpose, LCA or your LCA treatment team may contact the person whose name you have provided on the biographical sheet as your emergency contact.

*Social Media Policy:* LCA and our treatment team members believe that adding clients as friends or contacts on social media sites (e.g., Facebook, LinkedIn, etc.) can compromise clients’ confidentiality and privacy. Consequently, given the importance of client confidentiality, your LCA treatment team will not accept friend or contact requests from current or former clients on any social networking site. If you have questions about this, please bring them up during your session.

*Billing and Payments:* The fees for your sessions may be partially or fully paid for by your, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to you, as long as you are an eligible employee in good standing with the company, and as long as further sessions are considered clinically appropriate. Please note that certain professional services outside of the presenting problems, such as chart preparation requests and participation in legal proceedings, may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future and may also change if you move to another state. We will notify you of any changes.

*Research, Writing, Teaching:* Your clinician and others at LCA conduct internal research to improve our services, training, and supervision. Your clinician may use information about you and your treatment in any of these ways. Your clinician or others at LCA may also prepare publications for professional and/or lay audiences; any use of information about your treatment would be only in an anonymized and/or de-identified way for these publications.

*Length of Therapy:* Evidence-based treatments, or treatments that have been rigorously tested and proven to be helpful, typically are shorter-term treatments than general counseling or the general provision of support. Most clients notice some initial changes or relief within the first few sessions of treatment. Your course of treatment will be individualized depending on the intensity and duration of your presenting concerns, your level of engagement and active participation in the treatment plan, and the specific nature of your concerns.

*Ending Treatment:* You may withdraw from treatment at any time. Your treatment team recommends that you discuss your plan to end treatment with them before taking action, so that they have an opportunity to offer further recommendations or referral options.

If at any point during treatment your treatment team member assesses that the sessions are not effective in helping you reach the therapeutic goals, he/she will discuss this with you and, if appropriate, end treatment. In such a case, they will work with you and the Lyra Care Team to identify alternative options.

If you do not keep your scheduled appointments and do not respond to communications from your therapist, we will assume you have elected to end your treatment and we will close your case. If this occurs, and you wish to resume your treatment, please contact the Lyra Care Team to reinitiate therapy.

##### TREATMENT AGREEMENT

If at any time you have questions about the details above, please ask your therapist, treatment team, or the Lyra Care Team. By continuing your sessions with your therapist and/or treatment team, you accept and agree to abide by the contents and terms of this agreement and consent to participate in evaluation and/or treatment.
`

export const teensTherapyInformedConsent = `
### Consent For Therapy

This document contains important information about the professional services and business policies of both your child’s Lyra Clinical Associates (LCA) therapist, and Lyra Clinical Associates P.C. related to the Lyra Care program. Please read it carefully and discuss any questions you have with your child’s/the minor’s therapist.

*Consent from Parent or Legal Guardian:* A parent or legal guardian will be required to consent to treatment provided to your child. If the parents of a child are separated, treatment is provided to the child only with the written consent of both parents unless one parent has a custody order authorizing the parent to make medical decisions for the child. If the parents of the child are divorced, consent for treatment of the child may be given by the parent authorized to make medical decisions for the child. If a court of law has ordered that medical decisions for the child are to be made jointly by the child’s parents, then consent of both parents is required for treatment of the child. If applicable, please provide your child’s therapist with a copy of the most recent custody decree that establishes custody rights of you and the other parent, or otherwise demonstrates that you have the right to authorize treatment for your child.

*Confidentiality:* Your child’s therapist and LCA take your child’s privacy and confidentiality very seriously. LCA complies with Federal and applicable state laws regarding confidentiality of client information. LCA’s [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/) explains the information we collect, including for treatment, coordination of care, payment and other business operations. We will minimize the amount of information we share without your or your child’s express consent, however we want to bring your attention to certain circumstances in which we may disclose details of your child’s care:

* Your child’s therapist may share information about your child’s assessment and treatment with the clinical team at LCA in a way that minimizes the sharing of their personal information to only that necessary to ensure that we are providing your child with the most effective care possible and/or to improve your experience with our services. 
* In certain circumstances, your child’s therapist may be required by law to inform you, legal authorities, or potentially impacted individuals, regarding the following possibilities:
  * If there is suspected elder, dependent adult, child abuse or neglect.
  * If, in the therapist’s judgment, your child is in danger of harming themself or another person.
  * If your child communicates to your therapist a serious threat of physical violence against another person.
* If your child’s therapist is ordered by a court to release information as part of a legal proceeding, your child’s therapist may be required to share details of their care.
* As otherwise required by law and/or detailed in our [Notice of Privacy Practices](https://www.lyrahealth.com/hipaa-notice/)

*The Process of Psychotherapy:* The process and outcome of psychotherapy may vary depending on the particular problems addressed, the dynamic between the therapist, client, parent or guardian and various other factors. We are committed to providing your child with the most professional and ethical treatment possible.

Participation in therapy can result in a number of benefits to your child, including improved interpersonal relationships, resolution of specific problems, and positive personal change. In order for therapy to be successful, your child will need to make an active effort both during and outside of your sessions. Since therapy often involves discussing unpleasant aspects of life, your child may experience uncomfortable and intense feelings like sadness, guilt, anxiety, frustration and anger. In addition, it may result in changes that were not originally intended (such as family conflict or other challenges). Change can often happen quickly, but may also occur more slowly. There are no guarantees about what your child will experience. Your therapist’s role is to help guide your child through the therapy process by offering emotional support, actively listening to their concerns, asking relevant questions, providing treatment plans and recommendations, and monitoring their treatment progress.

A parent or guardian must attend the first session. During the first session, you and your child will discuss with the LCA therapist the reasons your child is seeking therapy, goals your child would like to achieve, and any background information related to your child’s presenting concerns. In subsequent sessions, your child and their therapist will work collaboratively towards achieving their goals of therapy. Subsequent sessions may focus on understanding thought patterns, feelings, and behaviors that are relevant to presenting concerns and work towards more effective ways of coping. Your child’s LCA therapist may, in their professional discretion and subject to state law, request that you join subsequent sessions with your child, or they may request to hold clinical sessions with you alone to discuss how best to support your child’s mental health. 

Throughout therapy, your child will work with their LCA therapist to tailor treatment to meet their goals and assess whether the goals are being met. If at any point you or your child are unhappy about the progress, process, or outcome of the treatment, please discuss this with the therapist. There are many different methods your child’s therapist may use to address the concerns that bring them to therapy. Your child’s therapist strives for genuineness and a nonjudgmental stance in all patient relationships. However, if at any time you or your child feel that your child is not connecting or that the therapist has misunderstood your child in an important way, please discuss your concerns in session with your child’s therapist. In addition, the Lyra Care Navigator Team is always available to work with you and your child, and may also reach out to you to discuss your and your child’s overall satisfaction with the therapy provided to your child.     

Your Therapist’s Credentials. Your child’s therapist’s credentials were made available to you before scheduling an appointment. If you have any questions about these credentials, please direct them to your child’s therapist. For those states that require it, you can find an explanation of the levels of regulation applicable to mental health clinicians under the STATE REGULATIONS section of this document. Please let your child’s therapist know if your child will be traveling to another state or if your child moves to another state. In some cases, therapists are not able to provide services to your child while you are located in another state, even temporarily.

*Communication with Parent(s) or Guardian(s):* Generally information shared in session is confidential. As permitted by applicable federal and state law, your child’s therapist may share information about your child’s treatment with you. It is best that the process be a collaborative one involving you, your child, and clinician in order to maintain the rapport established between your child and clinician since rapport is vital to treatment success. Trust between your child and their therapist is built when information remains confidential. Therefore, unless there is a safety concern, your child would be consulted about the disclosure and encouraged to share the information with you first in order to establish better communications within the family structure. 

*Communication with Other Adults: *

School: Your child’s therapist will not share information with their school unless you and your child provide written permission to allow communication. Sometimes your child’s therapist may want to talk to people at your child’s school who interact with your child and may have information that is valuable for treatment and/or may be able to utilize strategies learned in therapy to help in the school environment. 

Doctors or other care providers: Your child’s therapist will not share information with their doctor or other care providers unless you and your child provide written permission to allow communication, unless otherwise required by state law. Your child’s therapist may need to speak with your child’s doctor or other care provider to confer on your child’s care. 

*The Lyra Website:* As part of therapy your child will use the Lyra Website. Your child will need to access the Lyra Website to participate in the video sessions, submit assessments and practices in between sessions, as well as to connect with their therapist via secure messaging.

Your child’s therapist will orient your child to using the Lyra Website for the above purposes during the first therapy session. You will not have access to the Lyra account for your child. Once you consent to your child’s treatment, you will be able to contact your child’s therapist by email or the Lyra Care Navigator team. If your child is unable to hold a video session, it is possible to have sessions with your child’s therapist by phone (without using the video option in the Lyra Website); your child’s therapist will call your child at the number provided at registration. Your child will still need to use the Lyra Website to complete assessments and submit records of therapy practices each week.

*Therapy Assessments:* Before your child’s first session, their therapist will ask them to answer a set of questions that will help them get to know your child and better tailor treatment to respond to your child’s situation. Parents or guardians will also complete assessments that evaluate your child’s progress. You may also provide information in the initial session and during the course of treatment as needed. Throughout therapy, your child’s therapist will assign other assessments to be completed before their sessions to inform treatment.

*Therapeutic Focus:* LCA therapists do not make recommendations, write letters of recommendation or complete application forms related to items outside the scope of treatment (e.g. medical leave, medical disability, fitness to work, child custody, or emotional support animals).

*Video Therapy:* Please note that:

* As part of this program your child will engage in telehealth sessions with their LCA therapist.
* All telehealth sessions are held by video using the Lyra Website.
* Your child’s therapist will initiate the video session with your child at the scheduled time using the Lyra Website.
* The video conferencing technology will not be the same as an in-person session with a therapist due to the fact that your child will not be in the same room as their therapist.
* In order to have the best results for your video session, your child should be in a quiet and private place with limited interruptions when they start the session.
* There are potential risks to this technology, including interruptions, unauthorized access and technical difficulties that are beyond the control of LCA. You agree to hold LCA harmless for delays in evaluation or for information lost due to such technical difficulties or failures.
* Generally speaking, telehealth offers benefits such as improved access to care by enabling patients and their families to remain in their local site (e.g., home or work) while their clinician consults at distant/other sites, efficient mental health evaluation and management, and the expertise of specialists that patients otherwise might not have. There are potential risks associated with telehealth, which include, but may not be limited to: the therapist may determine that the transmitted information is of inadequate quality, thus necessitating a face-to-face meeting with your child; delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment; security protocols could fail, causing a breach of privacy of personal medical information; lack of access to complete medical records, which could result in judgment errors in rare cases; and, it may become clear that telehealth is not an appropriate treatment format given your presenting symptoms or level of functioning, resulting in a recommendation that you obtain additional in-person care.
* Your child or their Lyra therapist can discontinue the telehealth session if it is felt that the videoconferencing connections are not adequate for the situation.
* Your child’s LCA therapist will inform your child and obtain their consent if another person who is not the parent or guardian is present during the session, for any reason. For example, persons may be present during the session other than the LCA therapist in order to operate the telehealth technologies. Your child will be informed of their presence in the session and they will have the right to request the following: (a) omit specific details of their medical history/examination that are personally sensitive to them; (b) ask non-medical personnel to leave the telehealth session; and/or (c) terminate the session at any time.
* There are alternatives to a telehealth session available, including the option of finding an LCA therapist to see your child in-person.
* You and your child can direct questions about your telehealth sessions at any time to your LCA therapist or Lyra Care Navigator Team member.
* This consent will last for the duration of the relationship with your child’s LCA therapist, and it covers all telehealth sessions your child may have.
* You or your child can withdraw consent for telehealth sessions in the course of your child’s care at any time for any reason or no reason, without affecting your child’s right to future care or treatment, and Lyra will work with you and your child to find a suitable alternative.
* The same confidentiality protections, limits to confidentiality, and rules around your records apply to a telehealth session as they would to an in-person session.
* Your child’s LCA therapist may decide to terminate telehealth services, if they deem it inappropriate for your child to continue therapy through video sessions. Your child’s LCA therapist will work with other team members to identify another LCA therapist for in-person care.
* By signing this consent form you agree for you and your child to work with your child’s LCA therapist to come up with a safety plan, including identifying one or two emergency contacts, in the event of a crisis situation during their sessions.

*Video Recording:* Your child’s therapist will ask them at the start of each session if they consent to the session being recorded. If your child consents, their therapist will record the session for ongoing quality improvement, quality assurance, training, use with our internally developed artificial intelligence tools for session summary and support in creating the clinical record, and development of our services. All content will be reviewed and approved by your child's therapist before inclusion in the clinical record. Video recordings are kept for 2 weeks and then deleted. No one other than the therapist (including you and your child) has permission to record sessions. We may retain some information from the session in a fully de-identified and anonymized format for longer than 2 weeks for ongoing quality assurance, training, and development of our services.

*Your Child’s Location During Video Sessions:* Like all health care providers, LCA’s therapists are licensed to offer services on a state by state basis. In order to ensure your child’s LCA therapist is appropriately licensed, we ask that you and your child please search for a provider based upon your child’s planned location at the time your child’s session takes place. If your child’s location changes at any point from one state to another, please let your child’s LCA therapist know as soon as possible. Please be aware that your child’s LCA therapist may not be able to provide services when your child travels to a different state and is not able to provide services when your child travels outside of the U.S.

*Missed Appointments and Cancellations:* LCA requires at least 24 hours advance notice for cancellation of an appointment. If your child is unable to attend their scheduled appointment due to circumstances beyond their control, please encourage your child to message their therapist in the Lyra Website as soon as possible so we can reschedule their appointment to a more suitable time. Once an appointment is scheduled, your child will be expected to attend unless they contact their therapist to reschedule. They can also use email to reschedule; however, please note that receiving emails in your child’s personal account may be unsecure. Failure to provide sufficient notice for cancellations and/or repeated no-shows for your child’s appointment may impact their ability to schedule additional appointments with their therapist. If a session is cancelled or rescheduled within 24 hours of a scheduled day/time, or the session is not attended, a $105.00 Cancellation Fee may apply. 

*In-Between Session Communication:* If your child needs to contact their LCA therapist between sessions, for non-urgent issues, please have your child message them within the Lyra Website. Your child’s therapist checks his/her messages in the Lyra Website regularly during business hours and will make every effort to return your child’s message within 2 business days. These messages will remain available for thirteen (13) months and then will be deleted.

*Emergency Procedures:* In a crisis, please call 911 or go to the local emergency room. Please do not use the Lyra Care messaging or email if your child is experiencing an emergency. If you or your child would like to speak to someone for support prior to the therapy appointment, or if your child experiences distress prior to the appointment, please use the Lyra Health Care Navigator Team number (877-505-7147) for non-emergency situations. Your child’s Lyra therapist may not be available to respond immediately due to variations in working hours. Your child’s Lyra therapist will respond to messages within 48 business hours of receipt.

If your child experiences an emergency during sessions with their LCA therapist, or if your child’s LCA therapist becomes concerned about your child’s personal safety, the possibility of your child injuring someone else, or about your child receiving proper psychiatric care, your LCA therapist and LCA will do whatever possible within the limits of the law to prevent your child from injuring self or others and to ensure that your child receives the proper medical care. For this purpose, LCA or your child’s LCA therapist may contact a parent, guardian, or other emergency contact listed on the biographical sheet. 

*Social Media Policy:* LCA and our therapists believe that adding clients as friends or contacts on social media sites (e.g., Facebook, LinkedIn, etc.) can compromise clients’ confidentiality and privacy. Consequently, given the importance of client confidentiality, your child’s LCA therapist will not accept friend or contact requests from current or former clients or their parents/guardians on any social networking site. If you have questions about this, please raise them with your child’s therapist.

*Billing and Payments:* The fees for your child’s sessions may be partially or fully paid for by you, or your eligible sponsor’s, employer or Health Plan, with or without any cost share to your child, as long as your child is an eligible employee in good standing with the company or eligible dependent, and as long as further sessions are considered clinically appropriate. Please note that certain professional services outside of the presenting problems may be outside the services paid for by your employer or Health Plan and may incur additional fees. Any additional fees will be discussed and agreed upon when they are requested. Fees for services may be subject to change in the future. If you have questions about the full cost of a session prior to any health plan cost coverage, you may contact the Lyra Care Navigator Team at care@lyrahealth.com

*Research, Writing, Teaching:* Your child’s therapist and others at LCA conduct internal research to improve our services, training, and supervision, or publish information for professional and/or lay audiences. De-identified information about your child and your child’s treatment may be used to support these initiatives. Any use of information about your child’s treatment would be only in an anonymized and/or de-identified way for these publications.

*Length of Therapy:* Evidence-based treatments, or treatments that have been rigorously tested and proven to be helpful, typically are shorter-term treatments than general counseling or the general provision of support. Most clients notice some initial changes or relief within the first few sessions of treatment. Your child’s course of treatment will be individualized depending on the intensity and duration of your child’s presenting concerns, your child’s level of engagement and active participation in the treatment plan, and the specific nature of your child’s concerns.

*Ending Treatment:* Your child may withdraw from treatment at any time. Your child’s therapist recommends that your child or you discuss any plans to end treatment with them before taking action, so that they have an opportunity to offer further recommendations or referral options.

If at any point during psychotherapy your child’s therapist assesses that the sessions are not effective in helping your child reach therapeutic goals, your child’s LCA therapist will discuss this with your child and, if appropriate, end treatment. In such a case, they will work with you and your child and the Lyra Care Navigator Team to identify alternative options.

If your child does not keep their scheduled appointments and does not respond to communications from their therapist, your child’s therapist will assume your child has elected to end treatment and we will close your child’s case. If this occurs, and your child wishes to resume treatment, please contact the Lyra Care Navigator Team to reinitiate therapy.

*State Regulations:* Please read the disclosures set forth next to the state in which your child is located at the time of the telehealth encounter, as set forth below.

${teensStateRegulationsContent}

##### TREATMENT AGREEMENT

If at any time you have questions about the details above, please ask your child’s therapist or the Lyra Care Navigator Team. As therapy continues with your child and their therapist, you accept and agree to abide by the contents and terms of this agreement and consent to participate in your child’s evaluation and/or treatment.
`
