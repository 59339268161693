import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Pressable, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { PATHWAYS_TYPE, PathwayTileMessages, registrationMessages } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  Headline,
  PressableOpacity,
  PrimaryButton,
  ProviderWavingLottie,
  SecondaryButton,
  Subhead,
} from '../../../atoms'
import { HeroSection } from '../../../molecules/heroSection/HeroSection'
import { BodyTextSize, HeadlineSize, SubheadSize } from '../../../styles'
import { ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'
import { PathwayCard } from '../../molecules/pathwayCard/PathwayCard'
import { PathwayListItem } from '../../molecules/pathwayListItem/PathwayListItem'
import { PATHWAY_ILLUSTRATIONS } from '../../utils'
import { MicrositeExploreOnYourOwnCTA } from '../micrositeExploreOnYourOwnCTA/MicrositeExploreOnYourOwnCTA'

export interface PathwaysHomepageHeroSectionProps {
  pathwaysType?: PATHWAYS_TYPE
  onCreateAccountButtonPress?: () => void
  onExploreOnYourOwnCTAPress?: () => void
  onProviderLedCardPress?: () => void
  onWellnessCheckinCardPress?: () => void
  onSelfCareCardPress?: () => void
  onNotifyMeOnLaunchPress?: () => void
  startDate?: string
  isMicrositeLookAndFeelUpdateEnabled?: boolean
  isRegistrationAndLoginDisabled?: boolean
  isAutoEnrollmentPathways?: boolean
  pathwayTileMessages?: PathwayTileMessages
  customNavHeader?: React.ReactElement
  coverageButton?: React.ReactNode
  essentialsDisabled?: boolean
}

const MainCTAContainer = styled.View<{ theme: ThemeType; isMicrositeLookAndFeelUpdateEnabled?: boolean }>(
  ({ theme }) => ({
    marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['16px'],
    marginBottom: '120px',
    ...(theme.breakpoints.isMinWidthTablet &&
      !theme.breakpoints.isMinWidthDesktop && {
        marginBottom: theme.spacing['40px'],
      }),
    ...(theme.breakpoints.isMinWidthMobileXxs &&
      !theme.breakpoints.isMinWidthMobileXs && {
        marginBottom: theme.spacing['56px'],
      }),
  }),
)

const MainCTAMultiPathwayContainer = styled.View<{ theme: ThemeType; isMicrositeLookAndFeelUpdateEnabled?: boolean }>(
  ({ theme, isMicrositeLookAndFeelUpdateEnabled }) => ({
    marginTop: theme.breakpoints.isMobileSized ? theme.spacing['40px'] : theme.spacing['48px'],
    ...(isMicrositeLookAndFeelUpdateEnabled &&
      theme.breakpoints.isMinWidthTablet && {
        marginTop: theme.spacing['40px'],
      }),
  }),
)

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const ProviderIllustrationContainer = styled.View<{ theme: ThemeType; isTabletSized: boolean }>(
  ({ theme, isTabletSized }) => ({
    marginBottom: isTabletSized ? theme.spacing['16px'] : theme.spacing['24px'],
    ...(isTabletSized && { height: '116px' }),
  }),
)

const SubheaderContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  textAlign: 'center',
}))

const MultiplePathwaySubheader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMinWidthTablet ? theme.spacing['16px'] : theme.spacing['12px'],
  textAlign: 'center',
}))

const CardContainer = styled.View<{ theme: ThemeType; isMicrositeLookAndFeelUpdateEnabled?: boolean }>(
  ({ theme, isMicrositeLookAndFeelUpdateEnabled }) => ({
    marginRight: isMicrositeLookAndFeelUpdateEnabled
      ? theme.breakpoints.isMinWidthDesktopL
        ? theme.spacing['24px']
        : theme.spacing['16px']
      : theme.breakpoints.isMobileSized
      ? !theme.breakpoints.isMinWidthMobileXs
        ? theme.spacing['8px']
        : theme.spacing['16px']
      : theme.spacing['32px'],
  }),
)

const PathwayCardsContainer = styled.View<{ theme: ThemeType; isMicrositeLookAndFeelUpdateEnabled?: boolean }>(
  ({ theme, isMicrositeLookAndFeelUpdateEnabled }) => ({
    flexDirection: 'row',
    marginTop: theme.spacing['40px'],
    ...(theme.breakpoints.isMinWidthTablet &&
      !theme.breakpoints.isMinWidthDesktop && {
        marginTop: theme.spacing['24px'],
      }),
    ...(isMicrositeLookAndFeelUpdateEnabled &&
      theme.breakpoints.isMinWidthTablet && {
        marginTop: theme.spacing['24px'],
      }),
  }),
)

const PathwayListContainer = styled.View<{ theme: ThemeType }>(() => ({
  flexDirection: 'column',
  minHeight: 288,
}))

const ProviderWavingLottieContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthLaptop && {
      marginBottom: theme.spacing['20px'],
    }),
}))

export const PathwaysHomepageHeroSection: FunctionComponent<PathwaysHomepageHeroSectionProps> = ({
  pathwaysType = PATHWAYS_TYPE.SINGLE,
  onCreateAccountButtonPress,
  onExploreOnYourOwnCTAPress,
  onProviderLedCardPress,
  onWellnessCheckinCardPress,
  onSelfCareCardPress,
  onNotifyMeOnLaunchPress,
  startDate,
  isMicrositeLookAndFeelUpdateEnabled,
  isRegistrationAndLoginDisabled,
  isAutoEnrollmentPathways,
  pathwayTileMessages,
  customNavHeader,
  coverageButton,
  essentialsDisabled = false,
}) => {
  const [isSinglePathwayLottieHovered, setIsSinglePathwayLottieHovered] = useState(false)
  const { breakpoints, spacing } = useTheme() as ThemeType
  const isTabletSized = breakpoints.isMinWidthTablet && !breakpoints.isMinWidthLaptop
  const isMobileSized = breakpoints.isMobileSized
  const isComingSoon = pathwaysType === PATHWAYS_TYPE.COMING_SOON
  const { formatDate, formatMessage } = useIntl()
  const isMultiplePathways = pathwaysType === PATHWAYS_TYPE.MULTIPLE

  const RegistrationButtonContainer = isMultiplePathways ? MainCTAMultiPathwayContainer : MainCTAContainer
  /** One off button sizing for microsite only */
  const registrationButtonTextSize = isMultiplePathways && !isMobileSized ? BodyTextSize.LARGE : undefined
  const registrationButtonStyle = isMultiplePathways && !isMobileSized ? ({ height: 60 } as ViewStyle) : undefined

  const registrationButton = (
    <RegistrationButtonContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
      {isMicrositeLookAndFeelUpdateEnabled && isMultiplePathways ? (
        <SecondaryButton
          text={formatMessage(registrationMessages.SIGN_UP_AND_EXPLORE)}
          onPress={() => {
            onCreateAccountButtonPress && onCreateAccountButtonPress()
          }}
          testID={tID('HeroSection-registrationButton')}
          customTextSize={registrationButtonTextSize}
          style={{ ...registrationButtonStyle, width: 234 }}
        />
      ) : (
        <PrimaryButton
          text={formatMessage(registrationMessages.GET_STARTED)}
          onPress={() => {
            onCreateAccountButtonPress && onCreateAccountButtonPress()
          }}
          testID={tID('HeroSection-registrationButton')}
          customTextSize={registrationButtonTextSize}
          style={registrationButtonStyle}
        />
      )}
    </RegistrationButtonContainer>
  )

  const understandYourCoverageButton = (
    <RegistrationButtonContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
      {coverageButton}
    </RegistrationButtonContainer>
  )

  const exploreOnYourOwnCTASinglePathway = (
    <MicrositeExploreOnYourOwnCTA
      onExploreOnYourOwnCTAPress={() => {
        onExploreOnYourOwnCTAPress && onExploreOnYourOwnCTAPress()
      }}
      testId={'HeroSection-exploreOnYourOwnCTA'}
    />
  )

  const onNotifyMeOnLaunch = (
    <MainCTAContainer>
      <PrimaryButton
        text={
          <FormattedMessage
            defaultMessage='Notify me on launch'
            description='Button to notify user on customer launch'
          />
        }
        onPress={() => {
          onNotifyMeOnLaunchPress && onNotifyMeOnLaunchPress()
        }}
        testID={tID('HeroSection-notifyMeOnLaunchButton')}
      />
    </MainCTAContainer>
  )

  const singlePathwaySubheader = (
    <SubheaderContainer
      text={
        <FormattedMessage
          defaultMessage='Meet with world-class mental health experts and discover our on demand wellness resources.'
          description='Summary of Lyra offerings'
        />
      }
    />
  )

  const multiplePathwaySubheader = (
    <MultiplePathwaySubheader
      size={
        breakpoints.isMinWidthDesktopL
          ? SubheadSize.LARGE
          : breakpoints.isMinWidthTablet
          ? SubheadSize.MEDIUM
          : SubheadSize.XSMALL
      }
      text={<FormattedMessage defaultMessage='Choose one to get started' description='Multiple pathway subheader' />}
      level='3'
    />
  )

  const pathwayCardProps = { isMicrositeLookAndFeelUpdateEnabled }

  const multiplePathwayCardSection = pathwayTileMessages && (
    <PathwayCardsContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
      <CardContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
        <PathwayCard
          illustration={PATHWAY_ILLUSTRATIONS.PROVIDER_LED_ILLUSTRATION}
          onPress={() => {
            onProviderLedCardPress && onProviderLedCardPress()
          }}
          title={pathwayTileMessages.providerLed}
          disableAnimation={isAutoEnrollmentPathways}
          {...pathwayCardProps}
        />
      </CardContainer>
      {onWellnessCheckinCardPress && (
        <CardContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
          <PathwayCard
            illustration={PATHWAY_ILLUSTRATIONS.WELLNESS_CHECKIN_ILLUSTRATION}
            onPress={() => {
              onWellnessCheckinCardPress()
            }}
            title={pathwayTileMessages.wellnessCheckin}
            {...pathwayCardProps}
          />
        </CardContainer>
      )}

      {isAutoEnrollmentPathways ? (
        <>
          {!essentialsDisabled && (
            <CardContainer isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}>
              <PathwayCard
                illustration={PATHWAY_ILLUSTRATIONS.SELF_CARE_ILLUSTRATION}
                onPress={() => {
                  onSelfCareCardPress && onSelfCareCardPress()
                }}
                title={pathwayTileMessages.selfCare}
                disableAnimation
                {...pathwayCardProps}
              />
            </CardContainer>
          )}
          <PathwayCard
            illustration={PATHWAY_ILLUSTRATIONS.EXPLORE_ON_YOUR_OWN_ILLUSTRATION}
            onPress={() => {
              onExploreOnYourOwnCTAPress && onExploreOnYourOwnCTAPress()
            }}
            title={pathwayTileMessages.exploreOnYourOwn}
            disableAnimation
            {...pathwayCardProps}
          />
        </>
      ) : (
        <>
          {!essentialsDisabled && (
            <PathwayCard
              illustration={PATHWAY_ILLUSTRATIONS.SELF_CARE_ILLUSTRATION}
              onPress={() => {
                onSelfCareCardPress && onSelfCareCardPress()
              }}
              title={pathwayTileMessages.selfCare}
              {...pathwayCardProps}
            />
          )}
        </>
      )}
    </PathwayCardsContainer>
  )

  const multiplePathwayListItemSection = pathwayTileMessages && (
    <PathwayListContainer>
      <PathwayListItem
        illustration={PATHWAY_ILLUSTRATIONS.PROVIDER_LED_ILLUSTRATION}
        onPress={() => {
          onProviderLedCardPress && onProviderLedCardPress()
        }}
        title={pathwayTileMessages.providerLed}
      />
      {onWellnessCheckinCardPress && (
        <PathwayListItem
          illustration={PATHWAY_ILLUSTRATIONS.WELLNESS_CHECKIN_ILLUSTRATION}
          onPress={() => {
            onWellnessCheckinCardPress && onWellnessCheckinCardPress()
          }}
          title={pathwayTileMessages.wellnessCheckin}
        />
      )}

      {isAutoEnrollmentPathways ? (
        <>
          {!essentialsDisabled && (
            <PathwayListItem
              illustration={PATHWAY_ILLUSTRATIONS.SELF_CARE_ILLUSTRATION}
              onPress={() => {
                onSelfCareCardPress && onSelfCareCardPress()
              }}
              title={pathwayTileMessages.selfCare}
            />
          )}
          <PathwayListItem
            illustration={PATHWAY_ILLUSTRATIONS.EXPLORE_ON_YOUR_OWN_ILLUSTRATION}
            onPress={() => {
              onExploreOnYourOwnCTAPress && onExploreOnYourOwnCTAPress()
            }}
            title={pathwayTileMessages.exploreOnYourOwn}
          />
        </>
      ) : (
        <>
          {!essentialsDisabled && (
            <PathwayListItem
              illustration={PATHWAY_ILLUSTRATIONS.SELF_CARE_ILLUSTRATION}
              onPress={() => {
                onSelfCareCardPress && onSelfCareCardPress()
              }}
              title={pathwayTileMessages.selfCare}
            />
          )}
        </>
      )}
    </PathwayListContainer>
  )

  const headerText =
    isComingSoon || isRegistrationAndLoginDisabled ? (
      <FormattedMessage
        defaultMessage='Get ready to feel your best'
        description='Header text, that encourages user to sign up to be notified'
      />
    ) : (
      <FormattedMessage
        defaultMessage='Let’s help you feel your best'
        description='Header text, that encourages user to find care by creating a Lyra account'
      />
    )
  const Header = ({ children, testId }: { children: React.ReactNode; testId: string }) => {
    const testID = tID(testId)
    return breakpoints.isMobileSized ? (
      <Subhead
        testID={testID}
        size={testId === 'HeroSection-multiPathwaysHeader' ? SubheadSize.LARGE : SubheadSize.MEDIUM}
        textAlign='center'
      >
        {children}
      </Subhead>
    ) : (
      <Headline
        testID={testID}
        size={
          isMicrositeLookAndFeelUpdateEnabled
            ? breakpoints.isMinWidthDesktopL
              ? HeadlineSize.MEDIUM
              : HeadlineSize.SMALL
            : breakpoints.isMinWidthLaptop
            ? HeadlineSize.MEDIUM
            : HeadlineSize.SMALL
        }
        textAlign='center'
      >
        {children}
      </Headline>
    )
  }

  const pathwaysContent = {
    [PATHWAYS_TYPE.SINGLE]: {
      header: (
        <>
          <ProviderWavingLottieContainer>
            <Pressable
              onHoverIn={() => {
                setIsSinglePathwayLottieHovered(true)
              }}
              onHoverOut={() => {
                setIsSinglePathwayLottieHovered(false)
              }}
            >
              <ProviderIllustrationContainer isTabletSized={isTabletSized}>
                <ProviderWavingLottie bgColor='white' play={isSinglePathwayLottieHovered} />
              </ProviderIllustrationContainer>
            </Pressable>
          </ProviderWavingLottieContainer>
          <HeaderContainer>
            <Header testId={'HeroSection-singlePathwayHeader'}>{headerText}</Header>
          </HeaderContainer>
        </>
      ),
      subHeader: singlePathwaySubheader,
      ctaSection: (
        <>
          {registrationButton}
          {exploreOnYourOwnCTASinglePathway}
        </>
      ),
    },
    [PATHWAYS_TYPE.MULTIPLE]: {
      header: <Header testId={'HeroSection-multiPathwaysHeader'}>{headerText}</Header>,
      subHeader: isMicrositeLookAndFeelUpdateEnabled ? multiplePathwaySubheader : undefined,
      ctaSection: (
        <>
          {(!!onWellnessCheckinCardPress && isMobileSized) || (isAutoEnrollmentPathways && isMobileSized)
            ? multiplePathwayListItemSection
            : multiplePathwayCardSection}
          {isAutoEnrollmentPathways ? understandYourCoverageButton : registrationButton}
        </>
      ),
    },
    [PATHWAYS_TYPE.COMING_SOON]: {
      header: (
        <>
          <ProviderWavingLottieContainer>
            <PressableOpacity
              aria-label={formatMessage({
                defaultMessage: 'Provider waving animation',
                description: 'Animation of a mental healthcare provider waving',
              })}
              onHoverIn={() => {
                setIsSinglePathwayLottieHovered(true)
              }}
              onHoverOut={() => {
                setIsSinglePathwayLottieHovered(false)
              }}
            >
              <ProviderIllustrationContainer isTabletSized={isTabletSized}>
                <ProviderWavingLottie bgColor='white' play={isSinglePathwayLottieHovered} />
              </ProviderIllustrationContainer>
            </PressableOpacity>
          </ProviderWavingLottieContainer>
          {!isRegistrationAndLoginDisabled && (
            <Subhead
              size={SubheadSize.MEDIUM}
              text={
                <FormattedMessage
                  defaultMessage='Launching {startDate}'
                  description='Subhead text indicating when date customer launches'
                  values={{
                    startDate: formatDate(startDate, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      timeZone: 'UTC',
                    }),
                  }}
                />
              }
            />
          )}
          <HeaderContainer>
            <Header testId={'HeroSection-comingSoonPathwaysHeader'}>{headerText}</Header>
          </HeaderContainer>
        </>
      ),
      subHeader: singlePathwaySubheader,
      ctaSection: isRegistrationAndLoginDisabled ? undefined : (
        <>
          {onNotifyMeOnLaunch}
          {exploreOnYourOwnCTASinglePathway}
        </>
      ),
    },
  }

  const { header, subHeader, ctaSection } = pathwaysContent[pathwaysType]

  const contentContainerStyle = {
    ...(breakpoints.isMobileSized && {
      paddingTop:
        spacing[pathwaysType === PATHWAYS_TYPE.SINGLE ? '48px' : isMicrositeLookAndFeelUpdateEnabled ? '72px' : '64px'],
    }),
    // Remove header spacing on AE pathways mobile
    ...(breakpoints.isMobileSized && isAutoEnrollmentPathways && { paddingTop: 0 }),
    ...(!breakpoints.isMobileSized && { justifyContent: 'center' }),
    ...(!breakpoints.isMinWidthMobileXxs && {
      flex: 1,
    }),
  } as ViewStyle

  return (
    <>
      <HeroSection
        testID={'HeroSection-pathwaysHomepage'}
        header={header}
        customNavHeader={isAutoEnrollmentPathways ? customNavHeader : undefined}
        subHeader={subHeader}
        ctaSection={ctaSection}
        useStaticBackground={isComingSoon}
        contentContainerStyle={contentContainerStyle}
        isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}
      />
    </>
  )
}
