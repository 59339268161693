import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { WELLNESS_CHECK_IN_ENTRY_POINTS, WELLNESS_CHECK_IN_INITIAL_VALUES } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_WELLNESS_CHECK_IN_ENTRY_POINT,
  CLEAR_WELLNESS_CHECK_IN_FORM,
  CLEAR_WELLNESS_CHECK_IN_RESULTS,
  SET_SHOULD_SAVE_WELLNESS_CHECK_IN_POST_LOGIN,
  SET_WELLNESS_CHECK_IN_ENTRY_POINT,
  SET_WELLNESS_CHECK_IN_FORM,
  SET_WELLNESS_CHECK_IN_RESULTS,
} from './wellnessCheckInActions'

const initialState = fromJS({
  wellnessCheckInResults: {},
})

export default createReducer(initialState, {
  [SET_WELLNESS_CHECK_IN_ENTRY_POINT]: ($$state: $TSFixMe, action: { data: WELLNESS_CHECK_IN_ENTRY_POINTS }) => {
    $$state = $$state.set('wellnessCheckInEntryPoint', action.data)
    return $$state
  },
  [CLEAR_WELLNESS_CHECK_IN_ENTRY_POINT]: ($$state: $TSFixMe) => {
    $$state = $$state.remove('wellnessCheckInEntryPoint')
    return $$state
  },
  [SET_WELLNESS_CHECK_IN_FORM]: ($$state: $TSFixMe, action: { data: object }) => {
    $$state = $$state.set('wellnessCheckInForm', action.data)
    return $$state
  },
  [CLEAR_WELLNESS_CHECK_IN_FORM]: ($$state: $TSFixMe) => {
    $$state = $$state.set('wellnessCheckInForm', WELLNESS_CHECK_IN_INITIAL_VALUES)
    return $$state
  },
  [SET_WELLNESS_CHECK_IN_RESULTS]: ($$state: $TSFixMe, action: { data: object }) => {
    $$state = $$state.set('wellnessCheckInResults', action.data)
    return $$state
  },
  [CLEAR_WELLNESS_CHECK_IN_RESULTS]: ($$state: $TSFixMe) => {
    $$state = $$state.set('wellnessCheckInResults', {})
    return $$state
  },
  [SET_SHOULD_SAVE_WELLNESS_CHECK_IN_POST_LOGIN]: ($$state: $TSFixMe, action: { data: boolean }) => {
    $$state = $$state.set('shouldSaveWellnessCheckInPostLogin', action.data)
    return $$state
  },
})
