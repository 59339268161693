import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { SessionLimitByProgram } from '@lyrahealth-inc/shared-app-logic'
import { CostMessagingModalContent, CostMessagingModalCoverageInfo } from '@lyrahealth-inc/shared-app-logic/build/src'

import {
  BodyText,
  LoadingIndicator,
  PrimaryButton,
  ShieldTriageIcon,
  Subhead,
  Tooltip,
  TOOLTIP_TRIGGER_ICON,
} from '../../atoms'
import { IS_WEB } from '../../constants'
import { CareNavigatorCareOptionsFooter } from '../../molecules'
import { CoverageMessagingLink } from '../../molecules/coverageMessagingLink/CoverageMessagingLink'
import { BodyTextSize, getFloatingStyles, SubheadSize } from '../../styles'
import { ColumnView, RowView } from '../../templates'
import { CareOptionCard, CareOptionCardProps } from '../../templates/careOptionCard/CareOptionCard'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CareOptionsProps {
  careOptionsList: CareOptionCardProps[]
  mainCareOption?: CareOptionCardProps
  header?: string
  showPrivacy?: boolean
  careNavigatorFooterEntryPointModal?: React.ReactNode
  careNavigatorFooterHeaderText?: React.ReactNode
  onContactCareNavigatorPress?: () => void
  careNavigatorLinkTestId?: string
  coverageModalContents?: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  isLoading?: boolean
  hideHeader?: boolean
  careOptionsTitleComponent?: React.ReactNode
  careOptionsCustomStyle?: { container?: CSSObject; cardContainer?: CSSObject }
  mainCareOptionCustomStyle?: { card?: CSSObject; body?: CSSObject; cta?: CSSObject; ctaContainer?: CSSObject }
  mainCareOptionConfig?: {
    isFloatingCard?: boolean
    headerTextSize?: SubheadSize
    headerTextBold?: boolean
    descriptionTextSize?: BodyTextSize
    coverageLinkOnBottom?: boolean
  }
  contentContainerCustomStyle?: CSSObject
  customerName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}

const Content = styled.View<{ theme: ThemeType; customStyle?: CSSObject }>(({ theme, customStyle }) => ({
  maxWidth: theme.breakpoints.isMobileSized ? 'auto' : '750px',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['32px'],
  paddingHorizontal: theme.spacing['16px'],
  marginHorizontal: 'auto',
  marginBottom: theme.spacing['64px'],
  width: '100%',
  ...customStyle,
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const PrivacyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing['12px'],
  alignItems: 'center',
  marginHorizontal: theme.breakpoints.isMobileSized ? 'auto' : 0,
}))

const StyledLockedIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const CareOptionsContainer = styled.View<{ theme: ThemeType; customStyle?: CSSObject }>(({ theme, customStyle }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['32px'],
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  flexWrap: 'wrap',
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['0px'] : '-16px',
  ...customStyle,
}))

const CardContainer = styled.View<{ theme: ThemeType; customStyle?: CSSObject }>(({ theme, customStyle }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
  width: theme.breakpoints.isMobileSized ? '100%' : '351px',
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['0px'] : theme.spacing['16px'],
  ...customStyle,
}))

const MainCareOptionCard = styled.View<{ theme: ThemeType; customStyle?: CSSObject }>(({ theme, customStyle }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: theme.spacing['20px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
  width: '100%',
  padding: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : '44px 24px',
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  ...customStyle,
}))

const MainCareOptionHeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const MainCareOptionBodyContainer = styled(ColumnView)<{ theme: ThemeType; customStyle?: CSSObject }>(
  ({ theme, customStyle }) => ({
    marginLeft: theme.breakpoints.isMobileSized ? 0 : theme.spacing['32px'],
    flex: 'auto',
    ...(theme.breakpoints.isMobileSized ? { marginTop: theme.spacing['16px'] } : { marginLeft: theme.spacing['32px'] }),
    alignItems: theme.breakpoints.isMobileSized ? 'center' : 'flex-start',
    justifyContent: 'center',
    ...customStyle,
  }),
)

const MainCareOptionIllustrationContainer = styled.View({
  alignSelf: 'center',
})

const MainCareOptionCta = styled(PrimaryButton)<{ theme: ThemeType; customStyle?: CSSObject }>(
  ({ theme, customStyle }) => ({
    marginTop: theme.spacing['12px'],
    width: '100%',
    ...(!theme.breakpoints.isMobileSized && { maxWidth: '203px' }),
    ...customStyle,
  }),
)

const MainCareOptionCTAContainer = styled.View<{ customStyle?: CSSObject }>(({ customStyle }) => ({
  width: '100%',
  ...customStyle,
}))

const CoverageLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
}))

const BottomCoverageLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
}))

const TooltipStyled = styled(Tooltip)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const LoadingContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['48px'],
  paddingBottom: theme.spacing['64px'],
}))

export const CareOptions: FunctionComponent<CareOptionsProps> = ({
  careOptionsList,
  mainCareOption,
  header,
  showPrivacy = true,
  careNavigatorFooterEntryPointModal,
  careNavigatorFooterHeaderText,
  onContactCareNavigatorPress = noop,
  careNavigatorLinkTestId = 'CareOptions-ContactCareNavigatorLink',
  coverageModalContents = [],
  costMessagingModalCoverageInfo = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  isLoading,
  hideHeader = false,
  careOptionsTitleComponent,
  careOptionsCustomStyle,
  mainCareOptionCustomStyle,
  mainCareOptionConfig = {
    isFloatingCard: false,
    headerTextSize: SubheadSize.MEDIUM,
    descriptionTextSize: BodyTextSize.DEFAULT,
    headerTextBold: false,
    coverageLinkOnBottom: false,
  },
  contentContainerCustomStyle,
  customerName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}) => {
  const { colors } = useTheme() as ThemeType
  const floatingStyles = getFloatingStyles({ simpleBoxShadow: true })
  const {
    isFloatingCard: mainCareOptionIsFloatingCard,
    headerTextSize: mainCareOptionHeaderTextSize,
    headerTextBold: mainCareOptionHeaderTextBold,
    descriptionTextSize: mainCareOptionDescriptionTextSize,
    coverageLinkOnBottom,
  } = mainCareOptionConfig

  return (
    <>
      <Content testID={tID('CareOptions')} customStyle={contentContainerCustomStyle}>
        {!hideHeader && (
          <HeaderContainer>
            <Subhead
              text={
                header || (
                  <FormattedMessage
                    defaultMessage='How can we help?'
                    description='Header text to ask user how we can help'
                  />
                )
              }
              size={SubheadSize.LARGE}
              textAlign='center'
            />
            {!coverageLinkOnBottom && isCostMessagingEnabled && (
              <CoverageLinkContainer>
                <CoverageMessagingLink
                  coverageModalContents={coverageModalContents}
                  costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
                  isCostMessagingEnabled={isCostMessagingEnabled}
                  trackCoverageModalPress={trackCoverageModalPress}
                  customerName={customerName}
                  numSessions={numSessions}
                  numberOfSessionByProgram={numberOfSessionByProgram}
                  isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
                  displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
                />
              </CoverageLinkContainer>
            )}
          </HeaderContainer>
        )}

        {mainCareOption && (
          <MainCareOptionCard
            testID={tID(mainCareOption.testId)}
            style={mainCareOptionIsFloatingCard ? floatingStyles.floatingCard : undefined}
            customStyle={mainCareOptionCustomStyle?.card}
          >
            <MainCareOptionIllustrationContainer>{mainCareOption.optionIcon}</MainCareOptionIllustrationContainer>
            <MainCareOptionBodyContainer customStyle={mainCareOptionCustomStyle?.body}>
              {mainCareOption.optionHeader && (
                <MainCareOptionHeaderContainer>
                  <Subhead
                    text={mainCareOption.optionHeader}
                    size={mainCareOptionHeaderTextSize ?? SubheadSize.MEDIUM}
                    bold={mainCareOptionHeaderTextBold}
                  />
                </MainCareOptionHeaderContainer>
              )}
              <RowView>
                {mainCareOption.optionDescription && (
                  <BodyText
                    textAlign='center'
                    text={mainCareOption.optionDescription}
                    size={mainCareOptionDescriptionTextSize ?? BodyTextSize.DEFAULT}
                    color={colors.textSecondary}
                  />
                )}
                {mainCareOption.tooltipContent && (
                  <TooltipStyled
                    triggerConfig={{
                      icon: TOOLTIP_TRIGGER_ICON.INFO,
                      size: 16,
                      isFilled: false,
                    }}
                    content={mainCareOption.tooltipContent}
                    hoverEnabled={IS_WEB}
                  />
                )}
              </RowView>
              <MainCareOptionCTAContainer customStyle={mainCareOptionCustomStyle?.ctaContainer}>
                <MainCareOptionCta
                  text={mainCareOption.ctaText}
                  onPress={mainCareOption.onPress || noop}
                  testID={tID(`${mainCareOption.testId}-cta`)}
                  customStyle={mainCareOptionCustomStyle?.cta}
                />
              </MainCareOptionCTAContainer>
              {coverageLinkOnBottom && isCostMessagingEnabled && (
                <BottomCoverageLinkContainer>
                  <CoverageMessagingLink
                    coverageModalContents={coverageModalContents}
                    costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
                    isCostMessagingEnabled={isCostMessagingEnabled}
                    trackCoverageModalPress={trackCoverageModalPress}
                    size={BodyTextSize.DEFAULT}
                    underline={false}
                    customerName={customerName}
                    numSessions={numSessions}
                    numberOfSessionByProgram={numberOfSessionByProgram}
                    isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
                    displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
                  />
                </BottomCoverageLinkContainer>
              )}
            </MainCareOptionBodyContainer>
          </MainCareOptionCard>
        )}

        {careOptionsList.length > 0 &&
          (isLoading ? (
            <LoadingContainer>
              <LoadingIndicator topPadding={0} />
            </LoadingContainer>
          ) : (
            <CareOptionsContainer customStyle={careOptionsCustomStyle?.container}>
              {careOptionsTitleComponent}
              {careOptionsList.map((item, index) => {
                return (
                  <CardContainer key={index} customStyle={careOptionsCustomStyle?.cardContainer}>
                    <CareOptionCard {...item} useBorderStyles />
                  </CardContainer>
                )
              })}
            </CareOptionsContainer>
          ))}
        <CareNavigatorCareOptionsFooter
          careNavigatorLinkTestId={careNavigatorLinkTestId}
          onContactCareNavigatorPress={onContactCareNavigatorPress}
          headerText={careNavigatorFooterHeaderText}
        />
        {showPrivacy && (
          <PrivacyContainer>
            <StyledLockedIconContainer>
              <ShieldTriageIcon size={24} />
            </StyledLockedIconContainer>
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Responses are confidential'
                  description='Description text explaining that responses are confidential'
                />
              }
              size={BodyTextSize.BADGE}
              textAlign='center'
              color={colors.textSecondary}
              bold
            />
          </PrivacyContainer>
        )}
      </Content>
      {careNavigatorFooterEntryPointModal}
    </>
  )
}
