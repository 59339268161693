import React, { FunctionComponent } from 'react'

import { NotificationIconProps } from './NotificationIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const NotificationIconSvg = withSuspense({
  component: React.lazy(() => import('./NotificationIconSvg')),
})

/**
 * Large horn notification icon
 */
export const NotificationIcon: FunctionComponent<NotificationIconProps> = ({ size }) => {
  return <NotificationIconSvg size={size} />
}
