import { MessageDescriptor } from 'react-intl'

import { CMS_MICROSITE_MESSAGE } from './messages/messages'

export const ELECTION_MICROSITE_URL = '/election-microsite'

export interface CMSMicrositeWithMetadataProps {
  micrositeMetadata: CMSMicrositeMetadata
}

export interface CMSMicrositeMetadata {
  micrositeId: string
  micrositeUrl: string
  /** Should match the Base Directory url (exclude /secure) from the CMS */
  iFrameSrcUrl: string
  title: MessageDescriptor
  featureFlag?: string
  /** If any of the conditions are true, the microsite should be hidden */
  customerPropertyConditions: { key: string; value: boolean }[]
  /** Post messages that can be sent through the CMS, attached to ctas. See available events in CMS_MICROSITE_MESSAGE */
  postMessageEvents: { key: CMS_MICROSITE_MESSAGE; params?: Dict }[]
  /** Mixpanel page string */
  page: string
  trackUTMParams?: { utm_campaign?: string; utm_source?: string; utm_medium?: string }
  /** Custom props that will be passed to all events */
  customMixpanelEventProps?: Dict
  /** Spanish version of the page should be created through the CMS */
  shouldSupportSpanish?: boolean
  shouldHideForInternational: boolean
}

export enum CMS_MICROSITE_IFRAME_MESSAGES {
  SET_CUSTOM_MESSAGES = 'setCustomMessages',
  DOCUMENT_OBJECT = 'Document Object',
  IFRAME_READY = 'iframeReady',
}

export enum MICROSITE_WITH_CUSTOM_MESSAGES {
  NEW_YEAR_TOOLKIT = 'NewYearToolkit',
}
