import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Pressable } from 'react-native'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { bindActionCreators } from 'redux'
import { useTheme } from 'styled-components/native'

import {
  CARE_ADVISOR_EMAIL,
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  CustomerPropertiesMap,
  DirectLinkSource,
  getPathwayTileMessages,
  IS_PRODUCTION,
  LYRIANS_SUPPORT_LINK,
  MicrositeConfig,
  PathwayOptions,
  PATHWAYS_ENTRY_POINTS,
  PATHWAYS_EVENTS,
  PATHWAYS_OPTIONS,
  PATHWAYS_PAGE,
  PATHWAYS_PROPERTIES,
  PATHWAYS_TYPE,
  PRODUCTION_REFER_ORGANIZATION_LINK,
  PROGRAM_MEMBERSHIP_TYPES,
  REGISTRATION_EVENTS,
  SessionLimitByProgram,
  useFlags,
  useGetPathwaysVersion,
  useLyraIntl,
  User,
  WELLNESS_CHECK_IN_ENTRY_POINTS,
} from '@lyrahealth-inc/shared-app-logic'
import { useLDClient } from '@lyrahealth-inc/shared-app-logic/src/features/flags/launchdarkly-client-sdk.web'
import { ContentLayout } from '@lyrahealth-inc/ui-core'
import {
  AppPromoBanner,
  AppPromoBottomSheet,
  CoverageMessagingButton,
  FindEmployer,
  FindEmployerFormValues,
  LyraLogo,
  mailTo,
  openUrl,
  ProductTypeContext,
  StickyView,
  TextButton,
  tID,
  ToastContent,
  toJS,
} from '@lyrahealth-inc/ui-core-crossplatform'
import {
  LyraSelectMicrositeHomePage,
  PathwaysMicrositeHomePage,
} from '@lyrahealth-inc/ui-core-crossplatform/src/microsite'
import { PathwaysMicrositeHomePageProps } from '@lyrahealth-inc/ui-core-crossplatform/src/microsite/pages/pathwaysMicrositeHomePage/PathwaysMicrositeHomePage'

import ChatLive from '../../../../common/components/chatLive/ChatLive'
import CookieBanner from '../../../../common/components/cookieBanner/CookieBanner'
import LegalFooter from '../../../../common/components/footer/LegalFooter'
import DefaultHeader from '../../../../common/components/header/DefaultHeader'
import Header from '../../../../common/components/header/Header'
import { MOBILE_PROMO_DISMISSED } from '../../../../common/constants/appConstants'
import { useCustomerCostProperties } from '../../../../common/hooks/useCustomerCostProperties'
import { useDefaultMixpanelEntryPoint } from '../../../../common/hooks/useDefaultMixpanelEntryPoint'
import { useGetCostMessagingInfo } from '../../../../common/hooks/useGetCostMessagingInfo'
import { useIsCostMessagingEnabled } from '../../../../common/hooks/useIsCostMessagingEnabled'
import { useIsMultiCtaPathwaysAvailable } from '../../../../common/hooks/useIsMultiCtaPathwaysAvailable'
import { useMicrositeCareOfferingDescriptionType } from '../../../../common/hooks/useMicrositeCareOfferingDescriptionType'
import { useNavigateToEssentialsContent } from '../../../../common/hooks/useNavigateToEssentialsContent'
import { useShowAppPromo } from '../../../../common/hooks/useShowAppPromo'
import { useUpdatedLyraSelectMicrositeConfig } from '../../../../common/hooks/useUpdatedLyraSelectMicrositeConfig'
import { useUpdatedMicrositeConfig } from '../../../../common/hooks/useUpdatedMicrositeConfig'
import { useWellnessCheckIn } from '../../../../common/hooks/useWellnessCheckIn'
import { doesUserHaveMembershipInCustomer } from '../../../../common/utils/customerUtils'
import { rerouteFromMicrosite } from '../../../../common/utils/micrositeUtils'
import { logToSumoLogic, SUMO_CATEGORY } from '../../../../common/utils/userUtils'
import { setToastContent } from '../../../../data/appGlobals/appGlobalsActions'
import { getDeviceUUID, getShouldShowLiveChat } from '../../../../data/appGlobals/appGlobalsSelectors'
import {
  getBeforeLaunch,
  getCustomerInfo,
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPropertiesMap,
  getEssentialsDisabled,
  getHideDependentField,
  getIsCoachingEnabled,
  getIsCustomerCareTeam,
  getIsCustomerLyra,
  getIsTherapyEnabled,
  getLegalNotice,
  getLwMobileEnabled,
  getNumberOfSessions,
  getNumberOfSessionsByProgram,
  getProgramMembershipType,
} from '../../../../data/customer/customerSelectors'
import { MIXPANEL_EVENTS, trackEventWithObj } from '../../../../data/mixpanel'
import { TrackEventWithObjAction } from '../../../../data/mixpanel/types'
import { useAppDispatch } from '../../../../data/storeConfiguration/store'
import {
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserLoggedIn,
  getUser,
} from '../../../../data/user/userSelectors'
import { setWellnessCheckInEntryPoint } from '../../../../data/wellnessCheckIn/wellnessCheckInActions'
import SecureIndexRedirector from '../../../indexRedirector/IndexRedirector'
import { setDirectLinkSource } from '../../../onboard/data/onboardDataActions'
import { PAGE_ROUTES } from '../../../onboard/data/page-navigation/location-actions'
import { setSelectedPathwayOption } from '../../../register/data/registerActions'
import { doPageLoad, postPrefilterCustomer } from '../../data/micrositeActions'
import { MaintenanceAlertBanner } from '../Banners/MaintenanceAlertBanner'

interface HomepageProps {
  isCareTeamPage: boolean
  isUserLoggedIn: boolean
  actions: {
    doPageLoad: (customerName: string, eventProps?: Dict, page?: string) => void
    trackEventWithObj: TrackEventWithObjAction
    setToastContent: (content: ToastContent) => void
    setDirectLinkSource: (source: DirectLinkSource) => void
  }
  customerName: string
  customerDisplayName: string
  membershipType: PROGRAM_MEMBERSHIP_TYPES
  user: User
  beforeLaunch: boolean
  startDate?: string
  isLyrian: boolean
  showLiveChat: boolean
  isUserAssumedInternational: boolean
  customerPropertiesMap?: CustomerPropertiesMap
  legalNotice?: React.ReactNode
  essentialsDisabled?: boolean
  micrositeConfig?: MicrositeConfig
  numberOfSessions?: string
  isMobileAppDisabled?: boolean
  isDependentDisabled?: boolean
  numberOfSessionsByProgram?: SessionLimitByProgram
  isCoachingEnabled: boolean
  isTherapyEnabled: boolean
  isAutoEnrollmentVariant?: boolean
}

const Homepage: React.FunctionComponent<HomepageProps> = ({
  isCareTeamPage,
  isUserLoggedIn,
  actions: { doPageLoad, trackEventWithObj, setDirectLinkSource },
  customerName,
  customerDisplayName,
  membershipType,
  user,
  beforeLaunch,
  startDate,
  isLyrian,
  showLiveChat,
  isUserAssumedInternational,
  customerPropertiesMap,
  legalNotice,
  essentialsDisabled,
  numberOfSessions,
  numberOfSessionsByProgram,
  isMobileAppDisabled,
  isDependentDisabled,
  isCoachingEnabled,
  isTherapyEnabled,
  isAutoEnrollmentVariant = false,
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    isPathwaysMicrositeMultiCTAEnabled,
    isMicrositeLookAndFeelUpdateEnabled,
    isProgramLevelSessionLimitEnabled,
    isAutoEnrollmentMicrositeEnabled,
  } = useFlags()

  const {
    coverageModalContents,
    costMessagingModalCoverageInfo,
    numSessions,
    numberOfSessionByProgram,
    displayVisitsPerIssuePerYear,
  } = useGetCostMessagingInfo()

  const pathwaysVersion = useGetPathwaysVersion()

  const { availableOptions, reasons, showMultiCTAPathways } = useIsMultiCtaPathwaysAvailable()
  const { colors, breakpoints } = useTheme()
  const { isLyraSelect } = useContext(ProductTypeContext)
  const { isMinWidthLaptop } = breakpoints
  const ldClient = useLDClient()

  const [startChat, setStartChat] = useState(false)

  const hasMembershipInCustomer = doesUserHaveMembershipInCustomer({ membershipType })

  const { formatMessage } = useIntl()
  const { activeLanguage } = useLyraIntl()

  const deviceUUID = useSelector(getDeviceUUID)

  const isAutoEnrollmentPathways = isAutoEnrollmentVariant && isAutoEnrollmentMicrositeEnabled

  const { search } = location

  const pathwaysType =
    (isPathwaysMicrositeMultiCTAEnabled && showMultiCTAPathways) || isAutoEnrollmentPathways
      ? PATHWAYS_TYPE.MULTIPLE
      : PATHWAYS_TYPE.SINGLE
  const updatedMicrositeConfig = useUpdatedMicrositeConfig(pathwaysType)
  const lyraSelectMicrositeConfig = useUpdatedLyraSelectMicrositeConfig()
  const careOfferingDescriptionType = useMicrositeCareOfferingDescriptionType(pathwaysType)

  const customerCostProperties = useCustomerCostProperties()
  const isCostMessagingEnabled = useIsCostMessagingEnabled()
  const { showWellnessCheckIn } = useWellnessCheckIn()
  const navigateToEssentialsContent = useNavigateToEssentialsContent()
  const defaultMixpanelEntryPoint = useDefaultMixpanelEntryPoint()

  // Reset pathway option if user navigates to homepage microsite
  useEffect(() => {
    dispatch(setSelectedPathwayOption(undefined))
  }, [dispatch])

  useEffect(() => {
    if (location.hash) {
      const timeout = setTimeout(() => {
        scroller.scrollTo(location.hash.replace('#', ''), { smooth: true })
      }, 500)
      return () => clearTimeout(timeout)
    }
    return
  }, [location])

  useEffect(() => {
    if (customerName && defaultMixpanelEntryPoint !== null) {
      doPageLoad(
        customerName,
        {
          ...pathwaysVersion,
          [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
          ...(customerName !== 'care' && {
            [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_NAME]: customerName,
          }),
          ...defaultMixpanelEntryPoint,
          ...(search.includes('entryPoint=FindEmployerPage') && {
            [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: `${COMMON_MIXPANEL_PAGE.FIND_EMPLOYER} page`,
          }),
        },
        PATHWAYS_PAGE.CUSTOMER_MICROSITE,
      )
    }
  }, [
    customerName,
    doPageLoad,
    pathwaysVersion,
    deviceUUID,
    search,
    trackEventWithObj,
    isPathwaysMicrositeMultiCTAEnabled,
    ldClient,
    defaultMixpanelEntryPoint,
  ])

  useEffect(() => {
    if (customerName) {
      console.debug(
        `Customer: ${customerName} has multi cta pathways: ${
          showMultiCTAPathways ? "'visible'" : "'hidden'"
        } with # of available options: ${availableOptions.join(',')}, reasons: ${JSON.stringify(reasons)}`,
      )
      logToSumoLogic(IS_PRODUCTION ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG, '', {
        message: 'pathways',
        customerName,
        showMultiCTAPathways,
        reasons,
        availableOptions,
      })
    }
  }, [customerName, showMultiCTAPathways, reasons, availableOptions])

  const { showAppPromoBanner, setShowAppPromoBanner, showAppPromoBottomSheet, setShowAppPromoBottomSheet } =
    useShowAppPromo({ promoBottomSheetEnabled: true })

  const onLegalFooterLinkPress = (url: string) => {
    openUrl(url)
  }

  const onPressLiveChat = () => {
    setStartChat(!startChat)
  }

  const onContactUsPress = () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_PHONE_CN,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.MICROSITE,
    })
    return navigate('/care-team-phone-numbers')
  }

  const handleFindEmployerSubmit = async (values: FindEmployerFormValues) => {
    const customerName = values.FindEmployerInputField
    trackEventWithObj({
      [COMMON_MIXPANEL_PROPERTIES.EVENT]: REGISTRATION_EVENTS.SUBMIT_ORG_NAME,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.FIND_EMPLOYER,
      [COMMON_MIXPANEL_PROPERTIES.TEXT_INPUT]: values.FindEmployerInputField,
    })

    const customerDomain = await dispatch(postPrefilterCustomer({ customerName }))

    if (customerDomain) {
      window.location.href = customerDomain + '?entryPoint=FindEmployerPage'
    } else {
      trackEventWithObj({
        [COMMON_MIXPANEL_PROPERTIES.EVENT]: REGISTRATION_EVENTS.INPUT_INCORRECT_COMPANY_NAME,
        [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.FIND_EMPLOYER,
        [COMMON_MIXPANEL_PROPERTIES.TEXT_INPUT]: values.FindEmployerInputField,
      })
      window.location.href = PRODUCTION_REFER_ORGANIZATION_LINK + `?organization=${customerName}`
    }
  }

  if (isCareTeamPage) {
    return (
      <StickyView
        headerComponent={<Header />}
        headerBackgroundColor={colors.backgroundTransparent}
        sticky={!isMinWidthLaptop}
        overlayHeader
      >
        <ContentLayout>
          <FindEmployer onSubmit={handleFindEmployerSubmit} />
          <LegalFooter />
        </ContentLayout>
      </StickyView>
    )
  }

  if (isUserLoggedIn && (!isAutoEnrollmentPathways || !isPathwaysMicrositeMultiCTAEnabled)) {
    // Redirect to secure/index if user is logged in
    return <SecureIndexRedirector />
  }

  const defaultWrapperProps = {
    headerComponent: (
      <>
        {showAppPromoBanner && <AppPromoBanner />}
        <Header />
      </>
    ),
    headerBackgroundColor: !isMinWidthLaptop ? colors.backgroundPrimary : colors.backgroundTransparent,
    sticky: !isMinWidthLaptop,
    overlayHeader: isMinWidthLaptop,
  }

  const onPressEmail = () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_EMAIL_CN,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.MICROSITE,
    })
    if (isLyrian) {
      openUrl(LYRIANS_SUPPORT_LINK)
    } else {
      mailTo(CARE_ADVISOR_EMAIL)
    }
  }

  const pathwayTileMessages = getPathwayTileMessages({
    coachingEnabled: isCoachingEnabled,
    therapyEnabled: isTherapyEnabled,
    internationalUser: isUserAssumedInternational,
  })

  const scrollToNextSibling = (testId: string) => {
    document.querySelector(`[data-testid=${testId}]`)?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
  }

  const micrositeSectionProps: Omit<PathwaysMicrositeHomePageProps, 'micrositeConfig'> = {
    heroSectionProps: {
      pathwaysType,
      onCreateAccountButtonPress: () => {
        navigate('/register/connect', {
          state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
        })
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_CREATE_ACCOUNT,
          [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: PATHWAYS_ENTRY_POINTS.MICROSITE,
          ...pathwaysVersion,
        })
      },
      onExploreOnYourOwnCTAPress: () => {
        document
          .querySelector('[data-testid="HeroSection-pathwaysHomepage"]')
          ?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_EXPLORE_ON_YOUR_OWN,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
          ...pathwaysVersion,
        })
      },
      onProviderLedCardPress: () => {
        setDirectLinkSource(DirectLinkSource.MICROSITE_FORKED_TRIAGE)
        navigate(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE, {
          state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
        })
      },
      onWellnessCheckinCardPress: showWellnessCheckIn
        ? () => {
            dispatch(setWellnessCheckInEntryPoint(WELLNESS_CHECK_IN_ENTRY_POINTS.MICROSITE))
            navigate(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)
          }
        : undefined,
      onSelfCareCardPress: () => {
        navigate(PAGE_ROUTES.PATHWAYS.SELF_CARE, {
          state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
        })
      },
      pathwayTileMessages,
      isMicrositeLookAndFeelUpdateEnabled,
    },
    faqSectionProps: {
      rerouteFromMicrosite: () => {
        rerouteFromMicrosite({
          user,
          navigate,
          trackEventWithObj,
          mixpanelEvent: MIXPANEL_EVENTS.FOOTER_CREATE_ACCOUNT,
          mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.FAQS,
        })
      },
      membershipType,
      beforeLaunch,
      startDate,
      hasMembershipInCustomer,
      customerCostProperties,
      isCostMessagingEnabled,
      isProgramLevelSessionLimitEnabled,
      onReachOutToCareNavTeamLinkPress: () => {
        navigate('/care-team-phone-numbers')
      },
      onQuestionPress: (title: string) => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_FAQS,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
          [PATHWAYS_PROPERTIES.FAQ_QUESTION]: title,
        })
      },
    },
    lyraOfferingsSectionProps: {
      customerPropertiesMap,
      pathwaysType,
      isInternational: isUserAssumedInternational,
      meetWithAnExpertTabPress: () => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.VIEW_MEET_WITH_AN_EXPERT_TAB,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
        })
      },
      wellnessResourcesTabPress: () => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.VIEW_CONTENT_LIBRARY_TAB,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
        })
      },
      lyraOfferingsTabPress: () => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.VIEW_ALL_OFFERINGS_TAB,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
        })
      },
      meetWithAnExpertCtaPress: () => {
        setDirectLinkSource(DirectLinkSource.MICROSITE_BOOK_APPT_CTA)
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_MEET_WITH_AN_EXPERT,
        })
        if (pathwaysType === PATHWAYS_TYPE.MULTIPLE) {
          navigate(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE, {
            state: { mixpanelEntryPoint: PATHWAYS_OPTIONS.EXPERT },
          })
        } else {
          navigate('/register/connect', {
            state: { mixpanelEntryPoint: PATHWAYS_OPTIONS.EXPERT },
          })
        }
      },
      wellnessResourcesCtaPress: () => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_ACCESS_FULL_LIBRARY,
          category: 'lyra offerings',
        })
        if (pathwaysType === PATHWAYS_TYPE.MULTIPLE) {
          navigate(PAGE_ROUTES.PATHWAYS.SELF_CARE, {
            state: { mixpanelEntryPoint: PATHWAYS_OPTIONS.CONTENT_LIBRARY },
          })
        } else {
          navigate('/register/connect', {
            state: { mixpanelEntryPoint: PATHWAYS_OPTIONS.CONTENT_LIBRARY },
          })
        }
      },
      lyraOfferingsCtaPress: () => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_CREATE_ACCOUNT,
          [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: PATHWAYS_ENTRY_POINTS.ALL_OFFERINGS,
        })
        navigate('/register/connect', {
          state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.ALL_OFFERINGS },
        })
      },
    },
    selfCareSectionProps: {
      onCTAPress: () => {
        navigate(PAGE_ROUTES.PATHWAYS.ESSENTIALS_HOMEPAGE_WIDGET)
        dispatch(setSelectedPathwayOption(PathwayOptions.ESSENTIALS))
      },
      language: activeLanguage,
      onContentClicked: (data) => {
        trackEventWithObj({
          event: PATHWAYS_EVENTS.CLICK_SELF_CARE_RESOURCE,
          page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
          article_name: data.title,
        })
        navigateToEssentialsContent({ slug: data.slug, newWindow: true })
      },
    },
    careNavigatorSectionProps: {
      onChatLivePress: onPressLiveChat,
      onPressEmail: onPressEmail,
      onContactUsPress: onContactUsPress,
      showLiveChat: showLiveChat || isUserAssumedInternational,
      isInternational: isUserAssumedInternational,
      contactEmail: !isLyrian ? CARE_ADVISOR_EMAIL : '',
      isLyrian: isLyrian,
    },
    legalAndCopyrightFooterProps: {
      onFooterLinkPressed: onLegalFooterLinkPress,
      isInternational: isUserAssumedInternational,
      legalNotice,
    },
    membershipSectionProps: {
      isMobileAppDisabled,
      customerDisplayName,
      numberOfSessions,
      numberOfSessionsByProgram,
      isProgramLevelSessionLimitEnabled,
      careOfferingDescriptionType,
      isEssentialsDisabled: essentialsDisabled,
      isDependentDisabled,
      language: activeLanguage,
    },
    mobileAppPromoSectionProps: { language: activeLanguage },
    mobileAppPromoBannerProps: {
      onGetAppLinkPress: () =>
        document.getElementById('lyra-mobile-app-promo-section')?.scrollIntoView({ behavior: 'smooth' }),
    },
    customerBrandedBannerProps: { customerName },
  }

  const isDefaultHomepage = !isLyraSelect && !isAutoEnrollmentVariant

  const autoEnrollmentPathwaysConfig = {
    hero: {
      pathwaysType: 'multiple' as PATHWAYS_TYPE,
      isAutoEnrollmentPathways,
    },
    mobileAppPromoBanner: {
      hideSection: true,
    },
    customerBrandedBanner: {
      hideSection: true,
    },
    offeringsCarousel: {
      hideSection: false,
      hideMeetWithExpert: false,
      hideWellnessResources: false,
      hideAllOfferings: false,
    },
    mobileAppPromo: {
      pathwaysType: 'multiple' as PATHWAYS_TYPE,
      hideSection: true,
    },
    lyraMembership: {
      hideSection: false,
      hideNumberOfSessions: false,
    },
    selfCare: {
      hideSection: true,
    },
    testimonials: {
      hideSection: true,
    },
    careNavigator: {
      hideSection: true,
    },
    faq: {
      hideSection: false,
    },
  }

  // TODO: ACCOUNT-2909: Update mixpanel
  const trackCoverageModalPress = () => {
    // (isOpen: boolean) => {
    // trackEventWithObj({
    //   event: isOpen ? COVERAGE_MESSAGING_EVENTS.OPEN_COVERAGE_MODAL : COVERAGE_MESSAGING_EVENTS.CLOSE_COVERAGE_MODAL,
    //   page: COVERAGE_MESSAGING_ENTRY_POINT.MY_BENEFITS,
    // })
  }

  const understandYourCoverageButton = (
    <CoverageMessagingButton
      coverageModalContents={coverageModalContents}
      costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
      isCostMessagingEnabled={isCostMessagingEnabled}
      customerName={customerDisplayName}
      numSessions={numSessions}
      numberOfSessionByProgram={numberOfSessionByProgram}
      isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
      displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
      trackCoverageModalPress={trackCoverageModalPress}
    />
  )

  const autoEnrollmentHeader = (
    <DefaultHeader
      lyraLogo={
        <Pressable
          onPress={() => {
            navigate(PAGE_ROUTES.HOMEBASE)
          }}
          accessibilityRole='button'
          accessibilityLabel={formatMessage({
            defaultMessage: 'Lyra Logo',
            description: 'Button that takes user to home page',
          })}
          testID={tID('Header-Lyra-Logo')}
        >
          <LyraLogo width={54} height={31} fillColor={colors.icons.lyraLogoPrimaryIcon.fill} />
        </Pressable>
      }
      handleLogoClick={() => {
        navigate(PAGE_ROUTES.HOMEBASE)
      }}
      shouldHideLoginButton={true}
      loggedOutButtons={[
        <TextButton
          key='skip-button'
          onPress={() => {
            navigate(PAGE_ROUTES.HOMEBASE)
          }}
          text={formatMessage({
            defaultMessage: 'Skip',
            description: 'Skip button text to skip to next page',
          })}
        />,
      ]}
      ghost={true}
      shouldShowCareNavigatorHeaderEntrypoint={false}
    />
  )

  const wrapperProps = isAutoEnrollmentPathways ? {} : defaultWrapperProps

  return (
    <StickyView {...wrapperProps}>
      <ContentLayout>
        {!isMinWidthLaptop && <CookieBanner />}
        <MaintenanceAlertBanner />
        <>
          {isDefaultHomepage && (
            <PathwaysMicrositeHomePage
              micrositeConfig={updatedMicrositeConfig}
              heroSectionProps={micrositeSectionProps.heroSectionProps}
              faqSectionProps={micrositeSectionProps.faqSectionProps}
              careNavigatorSectionProps={micrositeSectionProps.careNavigatorSectionProps}
              lyraOfferingsSectionProps={micrositeSectionProps.lyraOfferingsSectionProps}
              selfCareSectionProps={micrositeSectionProps.selfCareSectionProps}
              legalAndCopyrightFooterProps={micrositeSectionProps.legalAndCopyrightFooterProps}
              membershipSectionProps={micrositeSectionProps.membershipSectionProps}
              mobileAppPromoSectionProps={micrositeSectionProps.mobileAppPromoSectionProps}
              mobileAppPromoBannerProps={micrositeSectionProps.mobileAppPromoBannerProps}
              customerBrandedBannerProps={{ customerName }}
            />
          )}
          {isLyraSelect && (
            <LyraSelectMicrositeHomePage
              selectHomepageHeroSectionProps={{
                onExploreOnYourOwnCTAPress: () => {
                  scrollToNextSibling('LyraSelectHeroSection-heroContainer')
                },
                onGetStartedPress: () => {
                  navigate('/register/connect')
                },
              }}
              micrositeConfig={lyraSelectMicrositeConfig}
              faqSectionProps={micrositeSectionProps.faqSectionProps}
              legalAndCopyrightFooterProps={micrositeSectionProps.legalAndCopyrightFooterProps}
              careNavigatorSectionProps={micrositeSectionProps.careNavigatorSectionProps}
              membershipSectionProps={micrositeSectionProps.membershipSectionProps}
              selfCareSectionProps={micrositeSectionProps.selfCareSectionProps}
              mobileAppPromoSectionProps={micrositeSectionProps.mobileAppPromoSectionProps}
              mobileAppPromoBannerProps={micrositeSectionProps.mobileAppPromoBannerProps}
            />
          )}
          {isAutoEnrollmentVariant && (
            <PathwaysMicrositeHomePage
              micrositeConfig={autoEnrollmentPathwaysConfig}
              heroSectionProps={{
                ...micrositeSectionProps.heroSectionProps,
                isAutoEnrollmentPathways,
                coverageButton: understandYourCoverageButton,
                essentialsDisabled,
                onExploreOnYourOwnCTAPress: () => {
                  navigate(PAGE_ROUTES.HOMEBASE, {
                    // TODO: ACCOUNT-2909: Update mixpanel
                    // state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
                  })
                },
                onProviderLedCardPress: () => {
                  setDirectLinkSource(DirectLinkSource.AUTO_ENROLLMENT_PATHWAYS)
                  navigate(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE, {
                    // TODO: ACCOUNT-2909: Update mixpanel
                    // state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
                  })
                },
                onSelfCareCardPress: () => {
                  navigate(PAGE_ROUTES.PATHWAYS.SELF_CARE, {
                    // TODO: ACCOUNT-2909: Update mixpanel
                    // state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.MICROSITE },
                  })
                },
                customNavHeader: autoEnrollmentHeader,
                onWellnessCheckinCardPress: undefined,
              }}
              faqSectionProps={{
                ...micrositeSectionProps.faqSectionProps,
                onQuestionPress: () => {
                  // TODO: ACCOUNT-2909: Update mixpanel
                  // (title: string) => {
                  // trackEventWithObj({
                  //   event: PATHWAYS_EVENTS.CLICK_FAQS,
                  //   page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
                  //   [PATHWAYS_PROPERTIES.FAQ_QUESTION]: title,
                  // })
                },
                isAutoEnrollmentPathways,
              }}
              careNavigatorSectionProps={{}}
              lyraOfferingsSectionProps={{
                ...micrositeSectionProps.lyraOfferingsSectionProps,
                meetWithAnExpertTabPress: () => {
                  // TODO: ACCOUNT-2909: Update mixpanel
                  // trackEventWithObj({
                  //   event: PATHWAYS_EVENTS.VIEW_MEET_WITH_AN_EXPERT_TAB,
                  //   page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
                  // })
                },
                wellnessResourcesTabPress: () => {
                  // TODO: ACCOUNT-2909: Update mixpanel
                  // trackEventWithObj({
                  //   event: PATHWAYS_EVENTS.VIEW_CONTENT_LIBRARY_TAB,
                  //   page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
                  // })
                },
                lyraOfferingsTabPress: () => {
                  // TODO: ACCOUNT-2909: Update mixpanel
                  // trackEventWithObj({
                  //   event: PATHWAYS_EVENTS.VIEW_ALL_OFFERINGS_TAB,
                  //   page: PATHWAYS_PAGE.CUSTOMER_MICROSITE,
                  // })
                },
                lyraOfferingsCtaPress: undefined,
              }}
              selfCareSectionProps={{
                language: activeLanguage,
              }}
              legalAndCopyrightFooterProps={{
                ...micrositeSectionProps.legalAndCopyrightFooterProps,
                showCopyright: false,
              }}
              membershipSectionProps={micrositeSectionProps.membershipSectionProps}
              mobileAppPromoSectionProps={micrositeSectionProps.mobileAppPromoSectionProps}
              mobileAppPromoBannerProps={micrositeSectionProps.mobileAppPromoBannerProps}
              customerBrandedBannerProps={{ customerName }}
            />
          )}
          {showAppPromoBottomSheet && (
            <AppPromoBottomSheet
              onContinueInBrowserPress={() => {
                window.localStorage.setItem(MOBILE_PROMO_DISMISSED, 'true')
                setShowAppPromoBottomSheet(false)
                setShowAppPromoBanner(true)
              }}
            />
          )}
        </>
        <ChatLive
          hideButton
          startChat={startChat}
          mixpanelEntryPoint={PATHWAYS_ENTRY_POINTS.CARE_NAVIGATOR}
          mixpanelPage={COMMON_MIXPANEL_PAGE.MICROSITE}
        />
      </ContentLayout>
    </StickyView>
  )
}

const HomepageWithExternalCookieBanner: React.FC<HomepageProps> = (props) => {
  const { breakpoints } = useTheme()
  const { isMinWidthLaptop } = breakpoints

  return (
    <>
      {isMinWidthLaptop && <CookieBanner />}
      <Homepage {...props} />
    </>
  )
}

function mapStateToProps(state: $TSFixMe) {
  return {
    isCareTeamPage: getIsCustomerCareTeam(state),
    customerName: getCustomerName(state),
    customerDisplayName: getCustomerInfo(state),
    isUserLoggedIn: getIsUserLoggedIn(state),
    membershipType: getProgramMembershipType(state),
    beforeLaunch: getBeforeLaunch(state),
    startDate: getCustomerLaunchDate(state),
    user: getUser(state),
    isLyrian: getIsCustomerLyra(state),
    showLiveChat: getShouldShowLiveChat(state),
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer(state),
    customerPropertiesMap: getCustomerPropertiesMap(state),
    legalNotice: getLegalNotice(state),
    deviceUUID: getDeviceUUID(state),
    essentialsDisabled: getEssentialsDisabled(state),
    numberOfSessions: getNumberOfSessions(state),
    numberOfSessionsByProgram: getNumberOfSessionsByProgram(state),
    isMobileAppDisabled: !getLwMobileEnabled(state),
    isDependentDisabled: getHideDependentField(state),
    isCoachingEnabled: getIsCoachingEnabled(state),
    isTherapyEnabled: getIsTherapyEnabled(state),
  }
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  return {
    actions: bindActionCreators({ doPageLoad, trackEventWithObj, setToastContent, setDirectLinkSource }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(HomepageWithExternalCookieBanner))
