import React, { FunctionComponent, ReactChild } from 'react'
import { useSelector } from 'react-redux'

import { PRODUCT_TYPE, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ProductTypeContext } from '@lyrahealth-inc/ui-core-crossplatform'

import { getCustomerProductType } from '../../data/customer/customerSelectors'

export const ProductTypeProvider: FunctionComponent<{
  children: ReactChild | ReactChild[]
}> = ({ children }) => {
  const { isLyraSelectEnabled } = useFlags()
  const productType = useSelector(getCustomerProductType)
  const isLyraSelect = isLyraSelectEnabled && productType === PRODUCT_TYPE.SELECT

  return (
    <ProductTypeContext.Provider
      value={{
        isLyraSelect,
        productType: isLyraSelectEnabled ? productType : PRODUCT_TYPE.WFMH,
      }}
    >
      {children}
    </ProductTypeContext.Provider>
  )
}
