import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { PATHWAYS_TYPE, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { getMemberCareOfferingDescriptionType } from '@lyrahealth-inc/ui-core-crossplatform'

import { useUpdatedMicrositeConfig } from './useUpdatedMicrositeConfig'
import {
  getHideChildSearchCTA,
  getHideDependentField,
  getIsCustomerEAPAndHPISupported,
  getIsCustomerEAPExclusive,
  getIsCustomerHPIExclusive,
  getNumberOfSessions,
} from '../../data/customer/customerSelectors'
import { getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer } from '../../data/user/userSelectors'

/**
 * Hook to retrieve the correct type/variant of the microsite membership description
 */
export const useMicrositeCareOfferingDescriptionType = (pathwaysType: PATHWAYS_TYPE) => {
  const isHPIExclusive = useSelector(getIsCustomerHPIExclusive)
  const isEAPExclusive = useSelector(getIsCustomerEAPExclusive)
  const isDependentDisabled = useSelector(getHideDependentField)
  const isEAPAndHPISupported = useSelector(getIsCustomerEAPAndHPISupported)
  const shouldHideChildSearchCTA = useSelector(getHideChildSearchCTA)
  const updatedMicrositeConfig = useUpdatedMicrositeConfig(pathwaysType)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  const numberOfSessions = useSelector(getNumberOfSessions)
  const { forceAdultFlowsToHPI } = useFlags()

  return useMemo(
    () =>
      getMemberCareOfferingDescriptionType({
        isHPIExclusive,
        isDependentDisabled,
        isEAPExclusive,
        isEAPAndHPISupported,
        numberOfSessions,
        isUserAssumedInternational,
        shouldHideChildSearchCTA,
        hideNumberOfSessions: updatedMicrositeConfig.lyraMembership.hideNumberOfSessions,
        forceAdultFlowsToHPI,
      }),
    [
      isDependentDisabled,
      isEAPAndHPISupported,
      isEAPExclusive,
      isHPIExclusive,
      isUserAssumedInternational,
      numberOfSessions,
      shouldHideChildSearchCTA,
      updatedMicrositeConfig.lyraMembership.hideNumberOfSessions,
      forceAdultFlowsToHPI,
    ],
  )
}
