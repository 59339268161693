import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { ImageBackground, ImageSourcePropType } from 'react-native'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_PAGE,
  COVERAGE_MESSAGING_ENTRY_POINT,
  COVERAGE_MESSAGING_EVENTS,
  ExtendedPathwayOption,
  FORKED_TRIAGE_EVENTS,
  FORKED_TRIAGE_PROPERTIES,
  PATHWAYS_ENTRY_POINTS,
  S4C_EVENTS,
  S4C_PROPERTIES,
  useFlags,
  useGetCareOptionsEventHandlers,
  useInProgressSearch,
  WELLNESS_TOOLKIT_TYPE,
} from '@lyrahealth-inc/shared-app-logic'
import { ForkedTriagePage, ThemeType, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import constellationCloudsBackgroundDesktop from '@lyrahealth-inc/ui-core-crossplatform/src/assets/constellationCloudsBackgroundDesktop.png'
import constellationCloudsBackgroundMobile from '@lyrahealth-inc/ui-core-crossplatform/src/assets/constellationCloudsBackgroundMobile.png'

import { forkedTriageNavigationRoutes } from './types'
import { useCareNavigatorModal } from '../../common/hooks/useCareNavigatorModal'
import { useDefaultMixpanelEntryPoint } from '../../common/hooks/useDefaultMixpanelEntryPoint'
import { useGetCostMessagingInfo } from '../../common/hooks/useGetCostMessagingInfo'
import { useInitLatestMatchesAndGetProps } from '../../common/hooks/useInitLatestMatchesAndGetProps'
import { useIsWellnessToolkitAvailable } from '../../common/hooks/useIsWellnessToolkitAvailable'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import { useUpdateLDContext } from '../../common/hooks/useUpdateLDContext'
import { getDetectedCountryISO } from '../../data/appGlobals/appGlobalsSelectors'
import {
  getHideDependentField,
  getIsBlendedCareMedsAvailable,
  getIsDirectAccessMedsSupported,
  getIsDirectAccessTherapySupported,
} from '../../data/customer/customerSelectors'
import { getSessions } from '../../data/lyraTherapy/lyraTherapySelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'
import { postGuardianIndicated } from '../../data/user/userActions'
import {
  getId,
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
  getIsUserTeen,
  getSelfSearchAvailable,
  getUserAge,
  getUserHasIndicatedIsGuardian,
} from '../../data/user/userSelectors'
import { handleZeroViewSelect } from '../appointmentDashboard/data/appointmentDashboardActions'
import { fetchLatestUserFavoriteProviders, setLatestMatchesSearch } from '../onboard/data/onboardDataActions'
import { getOnboardDirectLinkSource } from '../onboard/data/onboardSelectors'
import { setSelectedSearchId } from '../triage/data/triageActions'
import { getSearches } from '../triage/data/triageSelectors'

interface ForkedTriageContainerProps extends ConnectedProps<typeof connector> {
  navigationRoutes: forkedTriageNavigationRoutes
  trackEventWithObj: TrackEventWithObjAction
}

const StyledImageBackground = styled(ImageBackground)({
  flexGrow: 1,
  marginTop: '-80px',
  zIndex: '-1',
  paddingTop: '80px',
})

const SolidBackground = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  flexGrow: 1,
  marginTop: '-80px',
  zIndex: '-1',
  paddingTop: '80px',
}))

const ForkedTriageContainer: FunctionComponent<ForkedTriageContainerProps> = ({
  hideDependentField,
  navigationRoutes,
  trackEventWithObj,
  detectedCountryIso,
  setLatestMatchesSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
  fetchLatestUserFavoriteProviders,
  providerSessions,
  searches,
  isLoggedIn,
  isUserAssumedInternational,
  isUserInternational,
  userAge,
  userId,
  isMedicationConsultationAvailable,
  isDirectAccessMedsSupported,
  isDirectAccessTherapySupported,
  hasIndicatedIsGuardian,
  postGuardianIndicated,
  isUserTeen,
}) => {
  const [searchParams] = useSearchParams()
  // used to open modal of selected pathway option when navigated from microsite to logged-in forked triage
  const selectedPathwayOption = (searchParams.get('selectedPathwayOption') ?? undefined) as
    | ExtendedPathwayOption
    | undefined
  const { forkedTriageV2Enabled: showNewForkedTriage } = useFlags()

  const careNavModalOpenedFromMedsConsultModal = useRef(false)
  const selfSearchAvailable = useSelector(getSelfSearchAvailable)
  const showCouplesTherapy = showNewForkedTriage && isDirectAccessTherapySupported && selfSearchAvailable
  const showFamilyTherapy = showNewForkedTriage && isDirectAccessTherapySupported && selfSearchAvailable

  const directLinkSource = useSelector(getOnboardDirectLinkSource)

  const { breakpoints } = useTheme()
  const updateLDContext = useUpdateLDContext()
  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const shouldShowChildSearchOption = !hideDependentField
  const {
    coverageModalContents,
    isCostMessagingEnabled,
    costMessagingModalCoverageInfo,
    customerDisplayName,
    numSessions,
    numberOfSessionByProgram,
    isProgramLevelSessionLimitEnabled,
    displayVisitsPerIssuePerYear,
  } = useGetCostMessagingInfo()
  const isIndividualCoachingToolkitAvailableResult = useIsWellnessToolkitAvailable(
    WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING,
    COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
  )
  const isParentToolkitAvailableResult = useIsWellnessToolkitAvailable(
    WELLNESS_TOOLKIT_TYPE.PARENT_COACHING,
    COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
  )
  const isLoadingToolkits =
    isIndividualCoachingToolkitAvailableResult.isLoading || isParentToolkitAvailableResult.isLoading
  const mixpanelEntryPoint = useDefaultMixpanelEntryPoint()
  const { careNavigatorModal, openCareNavigatorModal, isCareNavigatorModalOpen } = useCareNavigatorModal({
    getClickEntryPoint: () =>
      careNavModalOpenedFromMedsConsultModal.current
        ? PATHWAYS_ENTRY_POINTS.MEDS_CONSULT_MODAL
        : COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
    getCareNavigatorEntryPointClickEvent: () =>
      careNavModalOpenedFromMedsConsultModal.current
        ? FORKED_TRIAGE_EVENTS.CLICK_LEARN_MORE_LINK_IN_MEDS_CONSULT_MODAL
        : S4C_EVENTS.CLICK_TO_CONTACT_CN,
  })

  // todo: add back event prop to view event when latest matches entry point is finalized [LATEST_PROVIDER_PROPERTIES.PAST_SEARCH_AVAILABLE]: latestMatchesLinkProps.hasLatestMatches
  const latestMatchesLinkProps = useInitLatestMatchesAndGetProps({
    navigateToLatestProviderMatchesProps: {
      setLatestMatchesSearch,
      setSelectedSearchId,
      inProgressSearch,
      handleZeroViewSelect,
    },
    fetchLatestUserFavoriteProviders,
  })

  const indicateIsGuardian = () => {
    if (isLoggedIn && userId && !hasIndicatedIsGuardian) {
      postGuardianIndicated(userId)
    }
  }

  const trackPathwayOption = (eventName: FORKED_TRIAGE_EVENTS, props?: { [key: string]: string }) => {
    trackEventWithObj({
      event: eventName,
      [S4C_PROPERTIES.AGE]: userAge,
      ...mixpanelEntryPoint,
      ...props,
    })
  }

  const {
    handleSelfSearchPress,
    handleChildSearchPress,
    handleCoachingToolkitPress,
    handleParentingToolkitPress,
    handleMedsConsultPress,
    handleCouplesTherapyPress,
    handleFamilyTherapyPress,
    trackOnCareOptionModalOpen,
    trackOnCareOptionModalClose,
  } = useGetCareOptionsEventHandlers({
    directLinkSource,
    navigationRoutes,
    indicateIsGuardian,
    trackPathwayOption,
    shouldTrackDirectPathClickEvent: showNewForkedTriage && isLoggedIn,
  })

  useTrackViewPage(COMMON_MIXPANEL_PAGE.PROVIDER_CARE_PATHWAY, undefined, {
    entryPoint: mixpanelEntryPoint,
    skipAppendPageToEventName: true,
  })

  useEffect(() => {
    if (!isLoggedIn) {
      updateLDContext({ country: detectedCountryIso })
    }
  }, [detectedCountryIso, isLoggedIn, updateLDContext])

  const handleContactCareNavigatorPress = useCallback(
    (fromMedsConsultModal = false) => {
      careNavModalOpenedFromMedsConsultModal.current = fromMedsConsultModal
      openCareNavigatorModal()
    },
    [openCareNavigatorModal],
  )

  const trackCoverageModalPress = (isOpen: boolean) => {
    trackEventWithObj({
      event: isOpen ? COVERAGE_MESSAGING_EVENTS.OPEN_COVERAGE_MODAL : COVERAGE_MESSAGING_EVENTS.CLOSE_COVERAGE_MODAL,
      page: COVERAGE_MESSAGING_ENTRY_POINT.FORKED_TRIAGE,
    })
  }

  const trackAvailablePrograms = useCallback(
    (programs: string[]) => {
      trackEventWithObj({
        event: FORKED_TRIAGE_EVENTS.FORKED_TRIAGE_AVAILABLE_PROGRAMS,
        [FORKED_TRIAGE_PROPERTIES.AVAILABLE_PROGRAMS]: programs,
      })
    },
    [trackEventWithObj],
  )

  const Background: React.ComponentType<any> = showNewForkedTriage ? SolidBackground : StyledImageBackground

  const imageBackgroundProps = {
    source: breakpoints.isMobileSized
      ? (constellationCloudsBackgroundMobile as ImageSourcePropType)
      : (constellationCloudsBackgroundDesktop as ImageSourcePropType),
    accessibilityIgnoresInvertColors: true,
    resizeMode: 'stretch',
  }

  return (
    <Background {...(showNewForkedTriage ? {} : imageBackgroundProps)}>
      <ForkedTriagePage
        isUserLoggedIn={isLoggedIn}
        selectedPathwayOption={selectedPathwayOption}
        showNewForkedTriage={showNewForkedTriage}
        loading={isLoadingToolkits}
        showSelfSearch={selfSearchAvailable}
        showChildSearch={shouldShowChildSearchOption}
        showCoachingToolkit={isIndividualCoachingToolkitAvailableResult.isToolkitAvailable}
        showParentingToolkit={isParentToolkitAvailableResult.isToolkitAvailable}
        showMedsConsult={!isInternational && isMedicationConsultationAvailable}
        showCouplesTherapy={showCouplesTherapy}
        showFamilyTherapy={showFamilyTherapy}
        onSelfSearchPress={handleSelfSearchPress}
        onChildSearchPress={handleChildSearchPress}
        onCoachingToolkitPress={handleCoachingToolkitPress}
        onParentingToolkitPress={handleParentingToolkitPress}
        onCouplesTherapyPress={handleCouplesTherapyPress}
        onFamilyTherapyPress={handleFamilyTherapyPress}
        onLearnMorePress={useCallback(() => {
          handleContactCareNavigatorPress(true)
        }, [handleContactCareNavigatorPress])}
        isDirectAccessMedsSupported={isDirectAccessMedsSupported}
        onMedConsultPress={handleMedsConsultPress}
        isInternational={isInternational}
        onContactCareNavigatorPress={() => {
          handleContactCareNavigatorPress(false)
        }}
        careNavigatorFooterEntryPointModal={careNavigatorModal}
        latestMatchesLinkProps={latestMatchesLinkProps}
        isUserTeen={isUserTeen}
        coverageModalContents={coverageModalContents}
        costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
        isCostMessagingEnabled={isCostMessagingEnabled}
        trackCoverageModalPress={trackCoverageModalPress}
        trackAvailablePrograms={trackAvailablePrograms}
        hideCurrentOpenModal={isCareNavigatorModalOpen}
        resetSelectedPathwayOption={useCallback(() => {
          // clear the initial selectedPathwayOption so that modal won't open again on reload from a refresh or back navigation
          if (selectedPathwayOption) {
            window.history.replaceState({}, '', window.location.pathname)
          }
        }, [selectedPathwayOption])}
        onCareOptionModalOpen={trackOnCareOptionModalOpen}
        onCareOptionModalClose={trackOnCareOptionModalClose}
        customerName={customerDisplayName}
        numSessions={numSessions}
        numberOfSessionByProgram={numberOfSessionByProgram}
        isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
        displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
      />
    </Background>
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    hideDependentField: getHideDependentField($$state),
    detectedCountryIso: getDetectedCountryISO($$state),
    providerSessions: getSessions($$state),
    searches: getSearches($$state),
    isLoggedIn: getIsUserLoggedIn($$state),
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer($$state),
    isUserInternational: getIsUserInternational($$state),
    userAge: getUserAge($$state),
    userId: getId($$state),
    hasIndicatedIsGuardian: getUserHasIndicatedIsGuardian($$state),
    isUserTeen: getIsUserTeen($$state),
    isMedicationConsultationAvailable: getIsBlendedCareMedsAvailable($$state),
    isDirectAccessMedsSupported: getIsDirectAccessMedsSupported($$state),
    isDirectAccessTherapySupported: getIsDirectAccessTherapySupported($$state),
  }
}

const mapDispatchToProps = {
  trackEventWithObj,
  setLatestMatchesSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
  fetchLatestUserFavoriteProviders,
  postGuardianIndicated,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(ForkedTriageContainer))
