import React, { FunctionComponent, useEffect, useState } from 'react'

import LottieView, { AnimationObject } from 'lottie-react-native'
import styled, { useTheme } from 'styled-components/native'

import { isChromaticWeb, ThemeType, tID } from '../../utils'

const Container = styled.View<{ width: number; height: number }>(({ width, height }) => ({
  height: `${height}px`,
  width: `${width}px`,
}))

export interface RotatingProvidersLottieProps {
  width: number
  height: number
}

export const RotatingProvidersLottie: FunctionComponent<RotatingProvidersLottieProps> = ({ width, height }) => {
  const { isDarkMode } = useTheme() as ThemeType
  const [animationData, setAnimationData] = useState<AnimationObject | null>(null)

  useEffect(() => {
    const loadAnimation = async () => {
      const darkAnimation = await import('../../assets/lottie/rotatingProvidersLottieDarkMode.json')
      const lightAnimation = await import('../../assets/lottie/rotatingProvidersLottieLightMode.json')
      setAnimationData(isDarkMode ? darkAnimation : lightAnimation)
    }
    loadAnimation()
  }, [isDarkMode])

  return (
    <Container width={width} height={height} testID={tID('HomebaseHeader-rotatingProvidersLottie')}>
      {animationData && (
        <LottieView
          source={animationData}
          loop={!isChromaticWeb()}
          autoPlay={!isChromaticWeb()}
          style={{ width: width, height: height }}
        />
      )}
    </Container>
  )
}
