import { useEffect } from 'react'

import { getDocumentObject } from '@lyrahealth-inc/shared-app-logic'

export const useOnClickOutside = (containerId: string, ref: any, handler: any, triggerException?: string) => {
  useEffect(() => {
    const documentObject = getDocumentObject()
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || documentObject?.getElementById(containerId)!.contains(event.target as Node)) {
        return
      }
      if (triggerException && documentObject?.getElementById(triggerException)?.contains(event.target as Node)) {
        return
      }
      handler(event)
    }
    documentObject?.addEventListener('mousedown', listener)
    documentObject?.addEventListener('touchstart', listener)
    return () => {
      documentObject?.removeEventListener('mousedown', listener)
      documentObject?.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, containerId, triggerException])
}
