import { copingWithStressToolkitMetadata } from './metadata/copingWithStressToolkit'
import { neurodiversityToolkitMetadata } from './metadata/neurodiversityToolkit'
import { newYearToolkitMetadata } from './metadata/newYearToolkit'
import { nicotineQuitlineMetadata } from './metadata/nicotineQuitline'
import { CMSMicrositeMetadata } from './types'
import { UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE } from '../common/constants/utm'
export const ELECTION_UTM_CAMPAIGN = UTM_CAMPAIGN.ELECTION
export const ELECTION_UTM_SOURCE = UTM_SOURCE.HOMEBASE
export const ELECTION_UTM_MEDIUM = UTM_MEDIUM.PRODUCT

export const ELECTION_HOMEBASE_UTM_PARAMS = `utm_campaign=${ELECTION_UTM_CAMPAIGN}&utm_source=${ELECTION_UTM_SOURCE}&utm_medium=${ELECTION_UTM_MEDIUM}`

/** Complete list of all microsites that should use metadata format */
export const micrositesMetadataList: CMSMicrositeMetadata[] = [
  newYearToolkitMetadata,
  copingWithStressToolkitMetadata,
  nicotineQuitlineMetadata,
  neurodiversityToolkitMetadata,
]
