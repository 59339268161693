import React from 'react'

import { LDClient } from 'launchdarkly-react-client-sdk'

import LDOverrideEnabled from './LDOverrideEnabled'

type LDOverrideProps = {
  ldClient?: LDClient
  disable?: boolean
}

const LDOverride: React.FC<React.PropsWithChildren<LDOverrideProps>> = ({ ldClient, disable = false, children }) => {
  if (disable) {
    return <>{children}</>
  }

  return <LDOverrideEnabled ldClient={ldClient}>{children}</LDOverrideEnabled>
}

export default LDOverride
