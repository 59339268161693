import React, { FunctionComponent, ReactElement } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { AccessibilityInfo, findNodeHandle, Platform, TextStyle, View, ViewStyle } from 'react-native'
import RNPickerSelect, { PickerStyle } from 'react-native-picker-select'

import { omit } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import { getSelectStyles, SelectStyleType } from './styles/selectStyles'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { getCommonStyles } from '../../styles/commonStyles'
import { getFontStyles, getTypeStyles } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'
import { BaseInput } from '../formElements/BaseInput'
import { ToolTipTriggerConfig } from '../tooltip/Tooltip'

// This function converts number strings back to numbers because the onValueChange on RNPickerSelect
// transforms the value from a number into a string. This causes the picker to switch back to the default
// since the string value does not equal the numeric value.
export function selectFieldCast(val: string | number): string | number {
  if (typeof val === 'string') {
    const num = Number(val)
    if (val === '' || isNaN(num)) {
      return val
    } else {
      return num
    }
  }
  return val
}

export const SelectField = <T extends string | number>({
  accessibilityLabel,
  label,
  largeLabel,
  subLabel,
  largeSubLabel,
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  options,
  placeholder,
  name,
  readOnly,
  key,
  customWebStyle = {},
  customAndroidStyle = {},
  customIOSStyle = {},
  toolTipContent,
  toolTipTriggerConfig,
  active,
  decorativeIcon,
  baseInputStyle,
  useStringValue,
  useLegacyStyling = true,
  useTriageStyling,
  hideErrorMessage,
  showErrorAsWarning,
  disablePlaceholder,
  disabled,
  icon,
}: SelectFieldProps<T>) => {
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const { formatMessage } = useIntl()
  placeholder =
    placeholder ??
    formatMessage({
      defaultMessage: 'Select',
      description: 'Placeholder text indicating that the form field contains options where the user can select one',
    })

  const { colors, isDarkMode } = useTheme()
  const commonStyles = getCommonStyles(colors)
  const selectedStyle = useTriageStyling
    ? SelectStyleType.TRIAGE
    : useLegacyStyling
    ? SelectStyleType.LEGACY
    : SelectStyleType.DEFAULT
  const styles = getSelectStyles(colors)[selectedStyle]
  const errorStyles = error ? { borderColor: showErrorAsWarning ? colors.borderWarning : colors.inputOutlineError } : {}
  const prunedStyles = omit(
    {
      ...commonStyles.textInput,
      ...errorStyles,
      ...styles.select,
    },
    'lineHeight',
  )
  const handleValueChange = (value: T) => {
    onChange(value)
    /**
     * Since the native android picker does not have a done/close
     * button we need to call onBlur when the value changes
     */
    if (Platform.OS === 'android' && onBlur) {
      onBlur()
    }
  }

  const handleClose = () => {
    AccessibilityInfo.setAccessibilityFocus(findNodeHandle(focusRef.current)!)
    if (focusRef && focusRef.current) {
      if (Platform.OS === 'android' && onBlur) {
        onBlur()
      }
    }
  }

  const inputIOS = { ...prunedStyles, ...(customIOSStyle as object) } as TextStyle
  const inputAndroid = {
    ...getTypeStyles(colors).bodyDefault,
    height: '100%',
    ...(customAndroidStyle as object),
  } as TextStyle
  const inputWeb: PickerStyle['inputWeb'] = {
    ...getFontStyles(colors).body.default,
    borderWidth: 1,
    // @ts-ignore
    appearance: 'none',
    outlineColor: colors.inputOutlineFocus, // This should be set only on focused state
    backgroundColor: colors.inputBackgroundDefault,
    ...styles.select,
    ...(customWebStyle as object),
    ...errorStyles,
    // textIndent incompatible with TextStyle
    textIndent: decorativeIcon ? '30px' : '',
  } as unknown as TextStyle
  const iconContainerWeb =
    Platform.OS === 'web'
      ? {
          pointerEvents: 'none',
        }
      : {}
  const iconContainer = {
    top: 0,
    height: '100%',
    justifyContent: 'center',
    ...styles.iconContainer,
    ...iconContainerWeb,
  } as ViewStyle
  return (
    <BaseInput
      label={label}
      subLabel={subLabel}
      largeLabel={largeLabel}
      largeSubLabel={largeSubLabel}
      error={error}
      name={name}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      style={baseInputStyle}
      hideErrorMessage={hideErrorMessage}
      showErrorAsWarning={showErrorAsWarning}
    >
      {readOnly ? (
        <BodyText size={Size.DEFAULT}>{options.find((option) => option.value === value)?.label}</BodyText>
      ) : (
        <View testID={tID('SelectField-pickerSelect-container')}>
          {decorativeIcon && decorativeIcon}
          <RNPickerSelect
            key={key}
            ref={focusRef}
            disabled={disabled}
            onValueChange={handleValueChange}
            onOpen={onFocus}
            onClose={handleClose}
            useNativeAndroidPickerStyle={false}
            style={{
              /* stylelint-disable -- external RNPickerSelect lib will handle react native styling */
              inputIOS,
              inputAndroid,
              inputAndroidContainer: prunedStyles as ViewStyle,
              inputWeb,
              iconContainer,
            }}
            Icon={() =>
              icon ?? (
                <ChevronIcon
                  fillColor={colors.iconDefault}
                  direction={ChevronIconDirection.DOWN}
                  size={styles.icon?.size}
                />
              )
            }
            textInputProps={{ placeholderTextColor: colors.inputTextPlaceholder }}
            placeholder={
              disablePlaceholder
                ? {}
                : {
                    label: placeholder,
                    value: '',
                    key: '',
                  }
            }
            items={options}
            itemKey={value}
            value={!useStringValue && !isNaN(Number(value)) ? selectFieldCast(value) : value}
            pickerProps={{ accessibilityLabel: accessibilityLabel ?? label ?? subLabel }}
            touchableWrapperProps={{
              accessibilityLabel: `${accessibilityLabel ?? label ?? subLabel}, ${value}`,
              accessibilityRole: 'button',
            }}
            darkTheme={isDarkMode}
          />
        </View>
      )}
    </BaseInput>
  )
}

export const SelectFieldRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { value, onChange, onFocus, onBlur, name },
  placeholder,
  disablePlaceholder,
  meta: { touched, error, submitError, active },
  accessibilityLabel,
  label,
  subLabel,
  options,
  readOnly,
  decorativeIcon,
  customWebStyle = {},
  customAndroidStyle = {},
  customIOSStyle = {},
  toolTipContent,
  toolTipTriggerConfig,
  baseInputStyle,
  useStringValue,
  useLegacyStyling = false,
  useTriageStyling,
  showErrorAsWarning,
  disabled,
  icon,
}) => {
  return (
    <SelectField
      accessibilityLabel={accessibilityLabel}
      label={label}
      subLabel={subLabel}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      disablePlaceholder={disablePlaceholder}
      error={touched && (error || submitError)}
      options={options}
      name={name}
      readOnly={readOnly}
      decorativeIcon={decorativeIcon}
      customWebStyle={customWebStyle}
      customAndroidStyle={customAndroidStyle}
      customIOSStyle={customIOSStyle}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      active={active}
      baseInputStyle={baseInputStyle}
      useStringValue={useStringValue}
      useLegacyStyling={useLegacyStyling}
      useTriageStyling={useTriageStyling}
      showErrorAsWarning={showErrorAsWarning}
      disabled={disabled}
      icon={icon}
    />
  )
}

type SelectFieldProps<T = number | string> = {
  accessibilityLabel?: string
  label?: string
  largeLabel?: boolean
  subLabel?: string
  largeSubLabel?: boolean
  value: T
  key?: string
  onChange: (value: T) => void
  onFocus?: () => void
  onBlur?: () => void
  placeholder?: string
  error?: string
  name?: string
  readOnly?: boolean
  options: Array<{
    label: string
    value: T
  }>
  customWebStyle?: PickerStyle['inputWeb']
  customAndroidStyle?: PickerStyle['inputAndroid']
  customIOSStyle?: PickerStyle['inputIOS']
  toolTipContent?: string | ReactElement
  toolTipTriggerConfig?: ToolTipTriggerConfig
  active?: boolean
  decorativeIcon?: ReactElement
  baseInputStyle?: ViewStyle
  /** Set this as true to avoid numeric string values (e.g. "121") to  be treated as number (e.g. 121) */
  useStringValue?: boolean
  useLegacyStyling?: boolean
  useTriageStyling?: boolean
  hideErrorMessage?: boolean
  showErrorAsWarning?: boolean
  disablePlaceholder?: boolean
  disabled?: boolean
  icon?: ReactElement
}
