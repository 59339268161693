import { useCallback } from 'react'
import { NavigateOptions, useNavigate } from 'react-router-dom'

import { COMMON_MIXPANEL_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'
import { openUrl } from '@lyrahealth-inc/ui-core-crossplatform'

import { useDefaultMixpanelPage } from './useDefaultMixpanelPage'

export const useNavigateToEssentialsContent = () => {
  const navigate = useNavigate()
  const defaultMixpanelPage = useDefaultMixpanelPage()

  return useCallback(
    ({
      slug,
      entryPoint,
      newWindow = false,
      subPath,
      parentAppReferrer,
      activeTabOverride,
      locale,
      navigateOptions,
    }: {
      slug?: string
      entryPoint?: string
      newWindow?: boolean
      subPath?: string
      parentAppReferrer?: string
      activeTabOverride?: string
      locale?: string
      navigateOptions?: NavigateOptions
    }) => {
      const contentFormat = 'articles'
      const mixpanelEntryPoint = entryPoint || defaultMixpanelPage.page
      const subPathStr = slug ? `${contentFormat}/${slug}` : subPath

      const url = `/secure/index/essentials/${subPathStr}?${new URLSearchParams({
        ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
        ...(parentAppReferrer && { parentAppReferrer }),
        ...(activeTabOverride && { activeTabOverride }),
        ...(locale && { locale }),
      })}`
      if (newWindow) {
        openUrl(`${window.location.origin}${url}`)
      } else {
        navigate(url, navigateOptions)
      }
    },
    [defaultMixpanelPage, navigate],
  )
}
