import React, { FunctionComponent, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dimensions, View } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CostMessagingModalContent,
  CostMessagingModalCoverageInfo,
  CoverageType,
  isUnlimitedSessionLimit,
  ProgramNames,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

import {
  ActivityBadge,
  BodyText,
  CloseIcon,
  CoachingSecondaryRec,
  ControlledMedsSecondaryRec,
  IllustrationBadge,
  PressableOpacity,
  PrimaryButton,
  ProfileBookingShortTermDisabilityEvaluationIllustration,
  TherapySecondaryRec,
} from '../../atoms'
import { Subhead } from '../../atoms/subhead/Subhead'
import { useMediaQuerySize } from '../../hooks'
import { Modal } from '../../organisms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface CoverageMessagingModalProps {
  setIsModalOpen: (isModalOpen: boolean) => void
  isModalOpen: boolean
  coverageModalContents: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  trackCoverageModalPress: (isOpen: boolean) => void
  customerName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}

const ContentContainer = styled.View<{ theme: ThemeType; isMobileSized: boolean }>(({ theme, isMobileSized }) => ({
  padding: theme.breakpoints.isMobileSized ? `${theme.spacing['24px']} ${theme.spacing['16px']}` : '',
  height: isMobileSized ? Dimensions.get('window').height * 0.9 : undefined,
  justifyContent: 'space-between',
}))

const CoverageContainer = styled.View(({}) => ({
  width: 'auto',
}))

const CoverageContentRow = styled.View(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'top-center',
  paddingVertical: '16px',
}))

const CoverageContentText = styled.View(({}) => ({
  width: '80%',
  flexDirection: 'column',
  marginLeft: '12px',
}))

const CoverageContentHeader = styled.View<{ isMobileSized: boolean }>(({ isMobileSized }) => ({
  flexDirection: 'row',
  ...(!isMobileSized && { alignItems: 'baseline' }),
  flexWrap: 'wrap',
  gap: '8px',
  paddingBottom: 4,
}))

const TitleText = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
}))

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'flex-end',
  width: theme.breakpoints.isMobileSized ? '100%' : 'auto',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : 0,
}))

export interface CoverageContentProps {
  title: React.ReactNode
  description: React.ReactNode
}

const CloseIconContainer = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const hasHPIDescription = (costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo) => {
  return (
    costMessagingModalCoverageInfo && costMessagingModalCoverageInfo?.customerCoverageType === CoverageType.HPI_ONLY
  )
}

export const CoverageMessagingModal: FunctionComponent<CoverageMessagingModalProps> = ({
  setIsModalOpen,
  isModalOpen,
  coverageModalContents,
  costMessagingModalCoverageInfo,
  trackCoverageModalPress,
  customerName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}) => {
  const { isMobileSized } = useMediaQuerySize()
  const { colors } = useTheme() as ThemeType

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const hasCustomerName = !isEmpty(customerName)

  // If displayVisitsPerIssuePerYear present, then prioritize this over total session number
  let therapySessions: string | number | undefined = displayVisitsPerIssuePerYear
    ? displayVisitsPerIssuePerYear
    : numSessions
  let coachingSessions: string | number | undefined = numSessions

  if (isProgramLevelSessionLimitEnabled) {
    therapySessions = numberOfSessionByProgram?.therapy
    coachingSessions = numberOfSessionByProgram?.coaching
  }

  const closingModal = () => {
    trackCoverageModalPress(false)
    setIsModalOpen(false)
  }

  const openBottomSheet = () => {
    bottomSheetRef.current?.expand()
  }

  const closeBottomSheet = () => {
    closingModal()
    bottomSheetRef.current?.close()
  }

  const hpiOnly = hasHPIDescription(costMessagingModalCoverageInfo)

  const programsWithNoCostBadge = [ProgramNames.BlendedCareTherapy, ProgramNames.Coaching]

  const getCoverageIcon = (contentType: ProgramNames) => {
    switch (contentType) {
      case ProgramNames.BlendedCareTherapy:
        return therapySessions && therapySessions !== '0' ? (
          <IllustrationBadge
            text={<BodyText size={BodyTextSize.SMALL} text={therapySessions} />}
            topOffset={40}
            leftOffset={40}
            icon={<TherapySecondaryRec width={60} height={60} />}
          />
        ) : (
          <TherapySecondaryRec width={60} height={60} />
        )
      case ProgramNames.Coaching:
        return coachingSessions && coachingSessions !== 0 && !isUnlimitedSessionLimit(Number(coachingSessions)) ? (
          <IllustrationBadge
            text={<BodyText size={BodyTextSize.SMALL} text={coachingSessions} />}
            topOffset={40}
            leftOffset={40}
            icon={<CoachingSecondaryRec width={60} height={60} />}
          />
        ) : (
          <CoachingSecondaryRec width={60} height={60} />
        )
      case ProgramNames.MedicationManagement:
        return <ControlledMedsSecondaryRec width={60} height={60} />
      case ProgramNames.ClinicalLeaveEvaluation:
        return <ProfileBookingShortTermDisabilityEvaluationIllustration size={60} />
      default:
        return null
    }
  }

  return (
    <Modal
      modalContents={
        <ContentContainer testID={tID('CoverageMessagingModal')} isMobileSized={isMobileSized}>
          <View>
            <CloseIconContainer testID={tID('CoverageMessagingModal-closeIcon')} onPress={closeBottomSheet}>
              <CloseIcon size={16} fillColor={colors.iconDefault} />
            </CloseIconContainer>
            <TitleText
              text={
                <FormattedMessage
                  defaultMessage='Annual Lyra benefits {hasCustomerName, select, true {for {customerName}} other {}}'
                  description='Header text for coverage modal'
                  values={{ hasCustomerName, customerName }}
                />
              }
              size={SubheadSize.MEDIUM}
            />
            {coverageModalContents && coverageModalContents.length > 0 && (
              <CoverageContainer testID={tID('CoverageMessagingModal-contentContainer')}>
                {coverageModalContents.map((content: CostMessagingModalContent, index: number) => {
                  const { contentType, title } = content
                  const icon = getCoverageIcon(contentType)
                  const shouldDisplayActivityBadge = !hpiOnly && programsWithNoCostBadge.includes(contentType)

                  return (
                    <CoverageContentRow key={`coverageContent-${index}`}>
                      <View>{icon}</View>
                      <CoverageContentText>
                        <CoverageContentHeader isMobileSized={isMobileSized}>
                          <BodyText
                            size={BodyTextSize.DEFAULT}
                            color={colors.textPrimary}
                            text={title}
                            // Keep the activity badge on separate line for mobile
                            style={isMobileSized ? { flexBasis: '100%' } : {}}
                          />
                          {shouldDisplayActivityBadge && (
                            <ActivityBadge
                              text={
                                <FormattedMessage
                                  defaultMessage='No cost'
                                  description='Cost messaging modal badge to indicate therapy or coaching sessions are covered'
                                />
                              }
                              backgroundColor={colors.backgroundHighlightPrimary}
                              color={colors.textActive}
                              width={68}
                            />
                          )}
                        </CoverageContentHeader>
                        <BodyText
                          size={BodyTextSize.SMALL}
                          color={colors.textSecondary}
                          wrap
                          text={content.description}
                        />
                      </CoverageContentText>
                    </CoverageContentRow>
                  )
                })}
              </CoverageContainer>
            )}
          </View>

          <ButtonContainer>
            <PrimaryButton
              onPress={() => {
                closingModal()
              }}
              text={<FormattedMessage defaultMessage='Got it' description='Cost messaging modal button text' />}
              testID={tID('CoverageMessagingModal-confirmationButton')}
              fullWidth={isMobileSized}
            />
          </ButtonContainer>
        </ContentContainer>
      }
      onRequestClose={closeBottomSheet}
      visible={isModalOpen}
      bottomSheetRef={bottomSheetRef}
      snapPoints={['90%']}
      onCloseEnd={closeBottomSheet}
      disableBottomSheet={!isMobileSized}
      onLayout={openBottomSheet}
      scrollable={isMobileSized}
    />
  )
}
