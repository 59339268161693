import { SOCIAL_NEED_OPTIONS } from './constants'
import { WellbeingResponse, WellnessCheckInResultsData } from './types'
import { WELLNESS_CHECK_IN_INITIAL_VALUES, WELLNESS_CHECK_IN_NUM_DOMAINS } from './wellnessCheckInMetadata'

export const getWellnessCheckInTotalNumPages = (socialCareEnabled: boolean) => {
  const howAreYouFeelingPage = 1 // first page of the wellness check in flow
  const socialCarePage = 1 // last page of the wellness check in flow if customer has social care enabled
  const numPages = howAreYouFeelingPage + WELLNESS_CHECK_IN_NUM_DOMAINS
  return socialCareEnabled ? numPages + socialCarePage : numPages
}

export const getInitialWellnessCheckinValues = (socialCareEnabled: boolean) => {
  const initialValues = WELLNESS_CHECK_IN_INITIAL_VALUES
  if (!socialCareEnabled) {
    delete initialValues.socialCareSupport
  }
  return initialValues
}

export const getWellnessCheckInParsedBody = (
  wellnessCheckinResults: WellnessCheckInResultsData,
): { wellbeingResponse: WellbeingResponse; socialCareSupport: SOCIAL_NEED_OPTIONS[] } => {
  const latestWellbeingResponseStrWithForm = wellnessCheckinResults.body
  const response = JSON.parse(latestWellbeingResponseStrWithForm)
  return response
}

export const showSocialCareSupport = (socialCareOptions?: SOCIAL_NEED_OPTIONS[]): boolean => {
  return !(
    socialCareOptions === undefined ||
    socialCareOptions.length === 0 ||
    (socialCareOptions.length === 1 && socialCareOptions[0] === SOCIAL_NEED_OPTIONS.NONE_OF_THE_ABOVE)
  )
}
