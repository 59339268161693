import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { SEARCHER_TYPE_TO_MATCH_FOR } from '@lyrahealth-inc/shared-app-logic'

import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getIsUserInternational } from '../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../features/appointmentDashboard/data/appointmentDashboardActions'
import { TREATMENT_RECOMMENDATIONS_ROUTE } from '../constants/routingConstants'

export interface NavigateToSearchForCareChildProps {
  clickEventName?: string
  clickEventParams?: Dict
}

export const useNavigateToSearchForCareChild = ({
  clickEventName,
  clickEventParams,
}: NavigateToSearchForCareChildProps = {}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const isInternationalUser = useSelector(getIsUserInternational)

  const navigateToChildSearchDefault = useCallback(() => {
    dispatch(
      handleZeroViewSelect({
        whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD,
        navigate,
        currentLocation: pathname,
        showSummaryView: true,
      }),
    )
  }, [dispatch, navigate, pathname])

  const navigateToChildSearchInternational = useCallback(() => {
    navigate(TREATMENT_RECOMMENDATIONS_ROUTE, { state: { matchFor: SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD } })
  }, [navigate])

  const navigateToChildSearch = useCallback(() => {
    if (clickEventName) {
      dispatch(
        trackEventWithObj({
          event: clickEventName,
          ...clickEventParams,
        }),
      )
    }
    if (!isInternationalUser) {
      navigateToChildSearchDefault()
    } else {
      navigateToChildSearchInternational()
    }
  }, [
    clickEventName,
    clickEventParams,
    dispatch,
    isInternationalUser,
    navigateToChildSearchDefault,
    navigateToChildSearchInternational,
  ])

  return useMemo(() => {
    return {
      /** Handles if international or not */
      navigateToChildSearch,
      /** Non international */
      navigateToChildSearchDefault,
      /** International only */
      navigateToChildSearchInternational,
    }
  }, [navigateToChildSearch, navigateToChildSearchDefault, navigateToChildSearchInternational])
}
