export const ESSENTIALS_GRAPHQL_QUERY = `query getArticles($site:[String], $id:[QueryArgument]) {
    entries(
        section: "articles"
        id: $id
        site: $site
    ) {
        ... on articles_articles_Entry {
            uri
            title
            id
            readTime: text
            contentFormat
            contentType
            image { url }
        }
    }
}`

export const ESSENTIALS_GET_CATEGORIES_QUERY = `query getCategories($siteCategory: [String], $site: [String], $slug: [String]) {
    entries(
        relatedToCategories: {slug: $siteCategory}
        section: "categories"
        slug: $slug
        limit: 100
        site: $site
        level: 1
    ) {
        order: number
        subcategories: children {
            ...baseProps
        }
    }
}

fragment baseProps on EntryInterface {
    slug
}`

export const ESSENTIALS_GET_ARTICLES_BY_CATEGORY_QUERY = `query getArticles($siteCategory: [String], $slug: [String], $limit: Int, $orderBy: String, $visibility: [QueryArgument], $site: [String]) {
    entries(
        relatedToCategories: {slug: $siteCategory}
        section: "articles"
        relatedToEntries: {slug: $slug}
        limit: $limit
        orderBy: $orderBy
        orientationVisibility: $visibility
        site: $site
    ) {
        ...article
        visibility: orientationVisibility
    }
}

fragment article on articles_articles_Entry {
    uri
    title
    id
    readTime: text
    contentFormat
    contentType
    image { url }
}`

export const ESSENTIALS_GET_COURSES_QUERY = `query getCourses($siteCategory: [String], $site: [String], $slug: [String]) {
    entries(
        relatedToCategories: {slug: $siteCategory}
        slug: $slug
        section: "courses"
        limit: 100
        site: $site
    ) {
        ...course
    }
}

fragment course on courses_courses_Entry {
    ...baseProps
    foreground: image {
        url
    }
    subcategories {
        ...subcourses
    }
}

fragment baseProps on EntryInterface {
    id
    uri
    slug
    title
}

fragment subcourses on subcategories_BlockType {
    id
    title: heading
}`
