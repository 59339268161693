import { createContext } from 'react'

import { DEFAULT_PRODUCT_TYPE, PRODUCT_TYPE } from '@lyrahealth-inc/shared-app-logic'

export const ProductTypeContext = createContext<{
  isLyraSelect: boolean
  productType: PRODUCT_TYPE
}>({
  isLyraSelect: false,
  productType: DEFAULT_PRODUCT_TYPE,
})
