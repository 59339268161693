import { axiosInstance } from '../axiosInstance'

export function getCourseCompletionCertificate(lyraId: string, courseName: string, courseCompletionDate: Date) {
  axiosInstance(`/v1/user/${lyraId}/certificate/generate`, {
    method: 'POST',
    responseType: 'blob', // Force to receive data in a blob format
    data: {
      lyraId,
      courseName,
      courseCompletionDate,
    },
  })
    .then((response) => {
      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' })
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file)
      // Open the URL on new Window
      window.open(fileURL)
      return fileURL
    })
    .catch((error) => {
      throw new Error('Could not download course completion certificate at this time', error.message)
    })
}
