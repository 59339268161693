import { darkThemeColors, lightThemeColors, ThemeColors } from './theme/colors'
import { lyraSelectDarkThemeColors, lyraSelectLightThemeColors } from './theme/LyraSelectColors'
import { Theme } from './theme/theme'

export enum PRODUCT_TYPE {
  WFMH = 'WFMH',
  SELECT = 'SELECT',
}

export const DEFAULT_PRODUCT_TYPE = PRODUCT_TYPE.WFMH

type productTypeMetadata = {
  theme: {
    [Theme.DARK]: {
      colorTokens: ThemeColors
    }
    [Theme.LIGHT]: {
      colorTokens: ThemeColors
    }
  }
}

type productTypeToMetadata = {
  [key in PRODUCT_TYPE]: productTypeMetadata
}

export const defaultProductTypesToMetadata: productTypeToMetadata = {
  [PRODUCT_TYPE.WFMH]: {
    theme: {
      [Theme.DARK]: {
        colorTokens: darkThemeColors,
      },
      [Theme.LIGHT]: {
        colorTokens: lightThemeColors,
      },
    },
  },
  [PRODUCT_TYPE.SELECT]: {
    theme: {
      [Theme.DARK]: {
        colorTokens: lyraSelectDarkThemeColors,
      },
      [Theme.LIGHT]: {
        colorTokens: lyraSelectLightThemeColors,
      },
    },
  },
}
