import React, { useEffect, useMemo, useRef } from 'react'

import { LDFlagSet } from 'launchdarkly-react-client-sdk'
import { isEqual } from 'lodash-es'

import { LDOverrideContext, LDOverrideContextProps } from '@lyrahealth-inc/shared-app-logic'

import { useLDChromeExtension, useLocalStorage, useOverrideFlags } from './hooks'
import { LDOverrideFlags } from './types'

type StoredFlags = {
  flags?: LDOverrideFlags
  pinnedFlags?: string[]
}

const LDOverrideEnabled: React.FC<LDOverrideContextProps> = ({ ldClient, children }) => {
  const [flags, setFlags] = useLDChromeExtension<LDFlagSet>('LD_FLAGS')
  const [overrideFlags, setOverrideFlags] = useLDChromeExtension<LDOverrideFlags>('LD_OVERRIDE_FLAGS')
  const [pinnedFlags, setPinnedFlags] = useLDChromeExtension<string[]>('PINNED_FLAGS')
  const [storedFlags, setStoredFlags] = useLocalStorage<StoredFlags>('ld-flags', {})
  const isInitialized = useRef(false)
  useEffect(() => {
    ldClient?.waitUntilReady().then(() => {
      const flags = ldClient?.allFlags()
      if (flags) {
        setFlags(flags)
      }
    })
  }, [ldClient, setFlags])

  useEffect(() => {
    if (isInitialized.current) {
      return
    }
    isInitialized.current = true
    if (storedFlags.flags && !isEqual(storedFlags.flags, overrideFlags)) {
      setOverrideFlags(storedFlags.flags)
    }
    if (storedFlags.pinnedFlags && !isEqual(storedFlags.pinnedFlags, pinnedFlags)) {
      setPinnedFlags(storedFlags.pinnedFlags)
    }
  }, [storedFlags, setFlags, setOverrideFlags, setPinnedFlags, pinnedFlags, flags, overrideFlags])

  useEffect(() => {
    if (!isEqual(storedFlags.flags, overrideFlags) || !isEqual(storedFlags.pinnedFlags, pinnedFlags)) {
      setStoredFlags({ flags: overrideFlags, pinnedFlags })
    }
  }, [flags, overrideFlags, pinnedFlags, setStoredFlags, storedFlags.flags, storedFlags.pinnedFlags])
  useEffect(() => {
    if (!ldClient) {
      return
    }
    const listener = () => {
      setFlags(ldClient.allFlags())
    }
    ldClient.on('change', listener)
    return () => {
      ldClient.off('change', listener)
    }
  }, [ldClient, setFlags])

  const currentFlags = useOverrideFlags(flags, overrideFlags)
  const overridedFlags = useMemo(() => (overrideFlags?.enabled ? overrideFlags.flags : {}), [overrideFlags])
  const value = useMemo(() => ({ flags: currentFlags, overrideFlags: overridedFlags }), [currentFlags, overridedFlags])
  return <LDOverrideContext.Provider value={value}>{children}</LDOverrideContext.Provider>
}

export default LDOverrideEnabled
