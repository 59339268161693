import React, { FunctionComponent, ReactNode, useState } from 'react'
import {
  LayoutRectangle,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  ScrollViewProps,
  View,
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import styled, { useTheme } from 'styled-components/native'

import { IS_WEB } from '../../constants'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface StickyViewProps {
  headerComponent?: ReactNode
  footerComponent?: ReactNode
  sticky?: boolean
  safeAreaInsets?: {
    top?: number
    bottom: number
  }
  scrollViewProps?: ScrollViewProps
  overlayHeader?: boolean
  headerBackgroundColor?: string
  footerBackgroundColor?: string
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  scrollContainerRef?: any
  scrollContainerBackgroundColor?: string
  viewBackgroundColor?: string
  hasFloatingFooter?: boolean
  scrollContainerStyle?: Dict
  innerScrollContainerStyle?: { [key: string]: string }
  ignoreTopInset?: boolean
  headerHeightCompensation?: number
}

const HeaderContainer = styled.View<{ topInset?: number; headerBackgroundColor: string }>(
  ({ topInset = 0, headerBackgroundColor }) => ({
    position: IS_WEB ? 'fixed' : 'absolute',
    left: 0,
    right: 0,
    top: topInset,
    zIndex: 1,
    alignItems: 'center',
    backgroundColor: headerBackgroundColor,
  }),
)

const FooterContainer = styled.View<{
  bottomInset?: number
  hasFloatingFooter?: boolean
  footerBackgroundColor: string
}>(({ bottomInset = 0, hasFloatingFooter = false, footerBackgroundColor }) => ({
  position: IS_WEB ? (hasFloatingFooter ? 'sticky' : 'fixed') : 'absolute',
  left: 0,
  right: 0,
  bottom: bottomInset,
  zIndex: 1,
  alignItems: 'center',
  backgroundColor: footerBackgroundColor,
}))

const StickyViewContainer = styled.View<{ topInset?: number; bottomInset?: number; backgroundColor?: string }>(
  ({ topInset, bottomInset, backgroundColor }) => ({
    flexGrow: 1,
    height: '100%',
    ...(backgroundColor && { backgroundColor: backgroundColor }),
    paddingTop: topInset,
    paddingBottom: bottomInset,
  }),
)

const ScrollContainerWiderViewport = styled(IS_WEB ? View : KeyboardAwareScrollView)<{
  sticky: boolean
  footerHeight: number
  headerHeight: number
  overlayHeader?: boolean
  scrollContainerBackgroundColor?: string
}>(({ sticky, footerHeight, headerHeight, overlayHeader, scrollContainerBackgroundColor }) => ({
  flexGrow: 1,
  ...(!!scrollContainerBackgroundColor && { backgroundColor: scrollContainerBackgroundColor }),
  ...(IS_WEB && sticky && !overlayHeader ? { marginTop: headerHeight } : {}),
  ...(IS_WEB && sticky && !overlayHeader ? { marginBottom: footerHeight } : {}),
}))

const ScrollContainerNarrowViewport = styled(IS_WEB ? ScrollView : KeyboardAwareScrollView)<{
  sticky: boolean
  footerHeight: number
  headerHeight: number
  overlayHeader?: boolean
  scrollContainerBackgroundColor?: string
}>(({ sticky, footerHeight, headerHeight, overlayHeader, scrollContainerBackgroundColor }) => ({
  flexGrow: 1,
  ...(!!scrollContainerBackgroundColor && { backgroundColor: scrollContainerBackgroundColor }),
  ...(IS_WEB && sticky && !overlayHeader ? { marginTop: headerHeight } : {}),
  ...(IS_WEB && sticky && !overlayHeader ? { marginBottom: footerHeight } : {}),
}))

/**
 * Wraps children in a scroll view and appends a sticky header/footer at the top or bottom.
 * Can be toggled to be sticky and non-sticky
 *
 */
export const StickyView: FunctionComponent<StickyViewProps> = ({
  headerComponent,
  footerComponent,
  sticky = true,
  children,
  safeAreaInsets,
  scrollViewProps,
  overlayHeader,
  headerBackgroundColor,
  footerBackgroundColor,
  onScroll,
  scrollContainerRef,
  scrollContainerBackgroundColor,
  scrollContainerStyle = {},
  innerScrollContainerStyle = {},
  viewBackgroundColor,
  hasFloatingFooter = false,
  ignoreTopInset = false,
  // Used when a component at the top of the page is not rendered as part of the header passed here
  headerHeightCompensation = 0,
}) => {
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  const { top, bottom } = useSafeAreaInsets()
  const [footerLayout, setFooterLayout] = useState<LayoutRectangle>({ x: 0, y: 0, width: 0, height: 0 })
  const [headerLayout, setHeaderLayout] = useState<LayoutRectangle>({ x: 0, y: 0, width: 0, height: 0 })
  const contentContainerStyle = {
    paddingTop: sticky && !overlayHeader && !IS_WEB ? headerLayout?.height : 0,
    paddingBottom: sticky ? footerLayout?.height : 0,
  }

  const scrollContainerProps = {
    nestedScrollEnabled: true,
    testID: tID('StickyContainer'),
    sticky,
    innerRef: (ref: React.RefObject<any>) => {
      if (scrollContainerRef) {
        scrollContainerRef.current = ref
      }
    },
    headerHeight: headerLayout?.height + headerHeightCompensation,
    footerHeight: footerLayout?.height,
    overlayHeader,
    scrollContainerBackgroundColor,
    // for the content, pad from the bottom the height of the footer to make room for sticky view to not cover content
    contentContainerStyle,
    onScroll,
    scrollEventThrottle: 500,
    showsVerticalScrollIndicator: IS_WEB,
    // sticky footer cannot be hidden behind the keyboard on android so offset scrolling with footer's height
    enableOnAndroid: true,
    extraScrollHeight: Platform.OS === 'android' ? footerLayout?.height : 0,
    style: innerScrollContainerStyle,
    ...scrollViewProps,
  }

  return (
    <StickyViewContainer
      backgroundColor={viewBackgroundColor}
      topInset={ignoreTopInset ? 0 : safeAreaInsets?.top ?? top}
      bottomInset={safeAreaInsets?.bottom ?? bottom}
      style={scrollContainerStyle}
    >
      {sticky && (
        <HeaderContainer
          topInset={safeAreaInsets?.top ?? top}
          headerBackgroundColor={headerBackgroundColor || colors.backgroundPrimary}
          onLayout={(e) => {
            setHeaderLayout(e.nativeEvent.layout)
          }}
        >
          {headerComponent}
        </HeaderContainer>
      )}
      {isMobileSized ? (
        <ScrollContainerNarrowViewport {...scrollContainerProps}>
          {!sticky && headerComponent}
          {children}
          {!sticky && footerComponent}
        </ScrollContainerNarrowViewport>
      ) : (
        <ScrollContainerWiderViewport {...scrollContainerProps}>
          {!sticky && headerComponent}
          {children}
          {!sticky && footerComponent}
        </ScrollContainerWiderViewport>
      )}
      {sticky && (
        <FooterContainer
          bottomInset={safeAreaInsets?.bottom ?? bottom}
          footerBackgroundColor={footerBackgroundColor || colors.backgroundPrimary}
          hasFloatingFooter={hasFloatingFooter}
          onLayout={(e) => {
            setFooterLayout(e.nativeEvent.layout)
          }}
        >
          {footerComponent}
        </FooterContainer>
      )}
    </StickyViewContainer>
  )
}
