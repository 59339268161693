import { createContext } from 'react'

import { type LDClient, type LDFlagSet } from 'launchdarkly-react-client-sdk'

export const LDOverrideContext = createContext<{
  flags: LDFlagSet
  overrideFlags: LDFlagSet
}>({
  flags: {},
  overrideFlags: {},
})

export type LDOverrideContextProps = React.PropsWithChildren<{ ldClient?: LDClient }>
