import { useMemo } from 'react'

import {
  CostMessagingCoverageModalData,
  CostMessagingModalContent,
  CoverageType,
  GetCostMessagingModalTextParams,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

interface UseGetCostMessagingInfoProps {
  isCostMessagingEnabled: boolean
  costMessagingModalText?: {
    [key in CoverageType]: (params: GetCostMessagingModalTextParams) => CostMessagingModalContent[]
  }
  isHPIExclusive?: boolean
  isEAPExclusive?: boolean
  isDependentDisabled?: boolean
  isCustomerEAPMeds?: boolean
  isCustomerHPIMeds?: boolean
  isCustomerProgramEAPCoaching?: boolean
  isCustomerProgramHPITherapy?: boolean
  isCustomerProgramEAPTherapy?: boolean
  customerDisplayName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}
/**
 * Hook to retrieve cost messaging information related to a customer's provided coverage
 */
export const useGetCostMessagingInfo = ({
  isCostMessagingEnabled,
  costMessagingModalText,
  isHPIExclusive,
  isEAPExclusive,
  isDependentDisabled,
  isCustomerEAPMeds,
  isCustomerHPIMeds,
  isCustomerProgramEAPCoaching,
  isCustomerProgramHPITherapy,
  isCustomerProgramEAPTherapy,
  customerDisplayName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}: UseGetCostMessagingInfoProps): CostMessagingCoverageModalData => {
  return useMemo(() => {
    if (isCostMessagingEnabled) {
      const customerCoverageType = isHPIExclusive
        ? CoverageType.HPI_ONLY
        : isEAPExclusive
        ? CoverageType.EAP_ONLY
        : CoverageType.EAP_AND_HPI

      let coverageModalContents: CostMessagingModalContent[] = []

      if (costMessagingModalText) {
        coverageModalContents = costMessagingModalText[customerCoverageType]({
          isDependentDisabled,
          isCustomerEAPMeds,
          isCustomerHPIMeds,
          isCustomerProgramEAPCoaching,
          isCustomerProgramHPITherapy,
          isCustomerProgramEAPTherapy,
        })
      }

      console.debug(
        `Cost messaging modal - is supported configuration (coverageModalContents.length > 0): ${
          coverageModalContents.length > 0
        },
        [customerCoverageType: ${customerCoverageType}],
        [isDependentDisabled: ${isDependentDisabled}],
        [isCustomerEAPMeds: ${isCustomerEAPMeds}],
        [isCustomerHPIMeds: ${isCustomerHPIMeds}],
        [isCustomerProgramEAPCoaching: ${isCustomerProgramEAPCoaching}],
        [isCustomerProgramHPITherapy: ${isCustomerProgramHPITherapy}],
        [isCustomerProgramEAPTherapy: ${isCustomerProgramEAPTherapy}],
        `,
      )

      return {
        coverageModalContents,
        isCostMessagingEnabled,
        costMessagingModalCoverageInfo: { customerCoverageType },
        customerDisplayName,
        numSessions,
        numberOfSessionByProgram,
        isProgramLevelSessionLimitEnabled,
        displayVisitsPerIssuePerYear,
      }
    }

    return {
      coverageModalContents: [],
      isCostMessagingEnabled,
      costMessagingModalCoverageInfo: undefined,
      customerDisplayName,
      numSessions,
      numberOfSessionByProgram,
      isProgramLevelSessionLimitEnabled,
      displayVisitsPerIssuePerYear,
    }
  }, [
    costMessagingModalText,
    isCostMessagingEnabled,
    isCustomerEAPMeds,
    isCustomerHPIMeds,
    isCustomerProgramEAPCoaching,
    isCustomerProgramEAPTherapy,
    isCustomerProgramHPITherapy,
    isDependentDisabled,
    isEAPExclusive,
    isHPIExclusive,
    customerDisplayName,
    numSessions,
    numberOfSessionByProgram,
    isProgramLevelSessionLimitEnabled,
    displayVisitsPerIssuePerYear,
  ])
}
