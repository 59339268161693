// @intl project:microsite

import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { Headline, PressableOpacity, PrimaryButton } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { HeadlineSize } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { Modal } from '../modal/Modal'

const MobileSizedContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: `${theme.spacing['32px']}`,
  paddingLeft: `${theme.spacing['32px']}`,
  paddingRight: `${theme.spacing['32px']}`,
}))

const LegalCopyContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['32px']} 0px`,
}))

const TitleContainer = styled(Headline)<{ theme: ThemeType; isMinWidthTablet: boolean }>(
  ({ theme, isMinWidthTablet }) => ({
    paddingTop: !isMinWidthTablet ? `56px` : theme.spacing['32px'],
  }),
)

export type CoverageTermsContentProps = {
  legalLanguage: React.ReactNode
  fromLegalFooter?: boolean
}

export const CoverageTermsContent: FunctionComponent<CoverageTermsContentProps> = ({
  legalLanguage,
  fromLegalFooter = false,
}) => {
  const { isMinWidthTablet } = useMediaQuerySize()
  const [isCoverageModalOpen, setCoverageModalVisibility] = useState(false)
  const ContentContainer = !isMinWidthTablet ? MobileSizedContainer : React.Fragment
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  const modalContent = (
    <ContentContainer>
      <TitleContainer
        testID={'CoverageTermsContent-title'}
        isMinWidthTablet={isMinWidthTablet}
        size={HeadlineSize.SMALL}
        text={
          <FormattedMessage
            defaultMessage='Coverage'
            description='Title text for a modal dialog displaying legal language about service coverage'
          />
        }
      />
      <LegalCopyContainer testID={tID('CoverageTermsContent-modalContent')}>{legalLanguage}</LegalCopyContainer>
      <PrimaryButton
        text={
          <FormattedMessage
            defaultMessage='Close'
            description='Button to close a modal dialog displaying legal language about service coverage'
          />
        }
        testID={tID('CoverageTermsContent-closeButton')}
        onPress={() => setCoverageModalVisibility(false)}
      />
    </ContentContainer>
  )
  return (
    <View testID={tID('CoverageTermsContent')}>
      <Modal
        disableBottomSheet
        visible={isCoverageModalOpen}
        modalContents={modalContent}
        onRequestClose={() => setCoverageModalVisibility(false)}
        onCloseEnd={() => setCoverageModalVisibility(false)}
        scrollable
        scrollableModalHeight={'100%'}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Coverage terms modal',
          description: 'Accessibility label for coverage terms modal',
        })}
      />
      <PressableOpacity
        testID={tID('CoverageTermsContent-linkButton')}
        onPress={() => setCoverageModalVisibility(true)}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Coverage',
          description: 'Link to open the modal about customers coverage',
        })}
      >
        <BodyText
          text={
            <FormattedMessage defaultMessage='Coverage' description='Link to open the modal about customers coverage' />
          }
          color={fromLegalFooter ? colors.textSecondary : colors.textActive}
          size={fromLegalFooter ? BodyTextSize.SMALL : BodyTextSize.CAPTION}
          underline={fromLegalFooter}
        />
      </PressableOpacity>
    </View>
  )
}
