import { AnyAction } from 'redux'
import { reducer as formReducer } from 'redux-form/immutable'
import { combineReducers } from 'redux-immutablejs'

import appGlobals from './appGlobals/appGlobalsReducer'
import authReducer from './auth/authReducer'
import benefitsReducer from './benefits/benefitsReducer'
import bulkCopy from './bulk-copy/bulk-copy-reducer'
import clinicalDataSharingReducer from './clinicalDataSharing/clinicalDataSharingReducer'
import currentCountry from './currentCountry/currentCountryReducer'
import customerReducer from './customer/customerReducer'
import customerCountry from './customerCountry/customerCountryReducer'
import { SET_CUSTOMER_PROPERTIES } from './customerProperties/customerPropertiesActions'
import customerProperties from './customerProperties/customerPropertiesReducer'
import featureFlagReducer from './featureFlags/featureFlagReducer'
import goalsDataReducer from './goals/goalsReducer'
import graphqlTypeMapper from './graphql-type-mapper'
import loginReducer from './login/loginReducer'
import { RESET_HEALTHPLAN, RESET_STORE, RESET_TRIAGE } from './logout/logoutActions'
import logoutReducer from './logout/logoutReducer'
import minorsReducer from './minors/minorsReducer'
import userReducer from './user/userReducer'
import wellnessCheckInReducer from './wellnessCheckIn/wellnessCheckInReducer'
import banner from '../common/components/banner/banner-reducer'
import appointmentDashboardReducer from '../features/appointmentDashboard/data/appointmentDashboardReducer'
import directAccessProviderDetail from '../features/careManager/direct-access-provider-detail/direct-access-provider-reducer'
import googlePlacesTypeahead from '../features/careManager/list-view/matcher-search/google-places-typeahead/google-places-typeahead-reducer'
import matcherSearch from '../features/careManager/list-view/matcher-search/matcher-search-reducer'
import loadingIndicator from '../features/careManager/loading-indicator/loading-indicator-reducer'
import commonNotes from '../features/careManager/notes/notes-reducer'
import zendeskTickets from '../features/careManager/zendesk-tickets/zendesk-tickets'
import healthPlanReducer from '../features/healthPlan/data/healthPlanReducer'
import lyraTherapyReducer from '../features/lyraTherapy/data/LyraTherapyReducer'
import microsite from '../features/microsite/data/micrositeReducers'
import onboard from '../features/onboard/data/onboardReducers'
import t1Flow from '../features/onboard/data/t1-flow/t1-flow-reducer'
import triageDemographics from '../features/onboard/data/triage-demographics/triage-demographics'
import { results } from '../features/onboard/results/results'
import modal from '../features/page-components/modals/generic/modal-reducer'
import registerReducer from '../features/register/data/registerReducer'
import eligibility from '../features/register/eligibility/data/eligibilityReducer'
import triageReducer from '../features/triage/data/triageReducer'

export type DataAction<D = void, T extends string = string> = {
  data: D
  type: T
}

// TODO: Refactor this old registration flow reducer

const appReducer = combineReducers({
  form: formReducer,
  appointmentDashboard: appointmentDashboardReducer,
  lyraTherapy: lyraTherapyReducer,
  onboard,
  user: userReducer,
  clinicalDataSharing: clinicalDataSharingReducer,
  benefits: benefitsReducer,
  customer: customerReducer,
  appGlobals,
  modal,
  banner,
  results,
  triageDemographics,
  t1Flow,
  triage: triageReducer,
  healthPlan: healthPlanReducer,
  login: loginReducer,
  logout: logoutReducer,
  auth: authReducer,
  minors: minorsReducer,
  register: registerReducer,
  commonNotes,
  matcherSearch,
  directAccessProviderDetail,
  googlePlacesTypeahead,
  loadingIndicator,
  bulkCopy,
  graphqlTypeMapper,
  zendeskTickets,
  eligibility,
  currentCountry,
  customerCountry,
  customerProperties,
  microsite,
  featureFlag: featureFlagReducer,
  goals: goalsDataReducer,
  wellnessCheckIn: wellnessCheckInReducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  // When we set the customer properties, we will destroy the customerProperties slice before we reitialize it.
  // This is important to guarantee that each customer country customerProperties are clean of other possible customerProperties.
  if (action.type === SET_CUSTOMER_PROPERTIES) {
    state = state.filter((value: string, key: any) => key !== 'customerProperties')
  } else if (action.type === RESET_STORE) {
    // Reset state to default values except for logout to determine if user was signed out for inactivity
    state = state.filter((value: string, key: any) => key === 'logout')
  } else if (action.type === RESET_HEALTHPLAN) {
    // Reset the heathPlanReducer
    state = state.filter((value: string, key: any) => key !== 'healthPlan')
  } else if (action.type === RESET_TRIAGE) {
    // Reset the triageReducer
    state = state.filter((value: string, key: any) => key !== 'triage')
  }

  return appReducer(state, action)
}

export default rootReducer
