import { List, Map } from 'immutable'
import { isEmpty } from 'lodash-es'
import { createSelector } from 'reselect'

import { ELIGIBILITY_TYPES, HEALTH_PLAN_ELIGIBILITY_STATUS } from '@lyrahealth-inc/shared-app-logic'

export const getHealthPlan = (state: $TSFixMe) => state?.get('healthPlan')

export const getHealthPlanName = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('healthPlanName'))

export const getSecondaryHealthPlanName = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('secondaryHealthPlanName'),
)

export const getHealthPlansList = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('healthPlansList'))

export const getHealthPlanEligibilityType = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('eligibilityType', undefined),
)

export const getHealthPlanEligibilityStatus = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('medicalPlanEligibilityStatus'),
)

export const getSecondaryHealthPlanEligibilityStatus = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('secondaryHealthPlanMedicalPlanEligibilityStatus'),
)

export const getHealthPlanPaymentHistory = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('paymentHistory', List()),
)

export const getHealthPlanSessionHistory = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('sessionHistory', List()).toJS(),
)

export const getPatientEligibilityOptions = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('eligibilityOptions', Map()),
)

export const getHealthPlanMembershipId = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('healthPlanMembershipId'),
)

export const getSecondaryHealthPlanMembershipId = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('secondaryHealthPlanMembershipId'),
)

export const getIsHealthPlanProvided = createSelector(getHealthPlanName, (healthPlanName) => !isEmpty(healthPlanName))

export const getIsSecondaryHealthPlanProvided = createSelector(
  getSecondaryHealthPlanMembershipId,
  (secondaryHealthPlanMembershipId) => {
    return !isEmpty(secondaryHealthPlanMembershipId)
  },
)

export const getHealthPlanPaymentCardLast4 = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('cardLast4'))

export const getHealthPlanPaymentCardBrand = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('cardBrand'))

export const getHealthPlanPaymentCardLast4Type = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('cardLast4Type'),
)

export const getHealthPlanInfractionCardBrand = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('infractionCardBrand'),
)

export const getHealthPlanInfractionCardLast4 = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('infractionCardLast4'),
)

export const areAllHealthPlansZeroCostCopay = createSelector(getHealthPlansList, (healthPlansList) =>
  healthPlansList?.every(
    (healthPlan: Map<string, any>) => healthPlan.get('payment_type') === 'copay' && healthPlan.get('copay') === '0.0',
  ),
)

export const getIsUserDependent = createSelector(getHealthPlanEligibilityType, (healthPlanEligibilityType) => {
  return healthPlanEligibilityType === ELIGIBILITY_TYPES.DEPENDENT
})

export const getIsPatientDataFetched = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('isPatientDataFetched'),
)

export const getUser = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('user'))

export const geHealthPlanUnresolvedInfractions = createSelector(getHealthPlan, (healthPlan: any) =>
  healthPlan?.get('unresolvedInfractions'),
)

export const getUsername = createSelector(getUser, (user) => user?.get('username'))

export const getUsedSessionCount = createSelector(getHealthPlan, (healthPlan) => healthPlan?.get('usedSessionCount'))

export const getUsedSessionCountByProgram = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('usedSessionCountByProgram')?.toJS(),
)

const getSelectedHealthPlanConfig = createSelector(
  getHealthPlanName,
  getHealthPlansList,
  (healthPlanName, healthPlansList) =>
    healthPlansList?.find((healthPlan: Map<string, any>) => healthPlan.get('eligibility_name') === healthPlanName),
)

export const getHealthPlanDisplayName = createSelector(getSelectedHealthPlanConfig, (selectedHealthPlanConfig) =>
  selectedHealthPlanConfig?.get('name'),
)

export const getIsHealthPlanConfirmed = createSelector(
  [getHealthPlanEligibilityStatus, getSecondaryHealthPlanEligibilityStatus],
  (eligibilityStatus, secondaryEligibilityStatus) =>
    eligibilityStatus === HEALTH_PLAN_ELIGIBILITY_STATUS.ELIGIBLE ||
    secondaryEligibilityStatus === HEALTH_PLAN_ELIGIBILITY_STATUS.ELIGIBLE,
)

export const getIsPaymentInfoConfirmed = createSelector([getHealthPlanPaymentCardLast4], (cardLast4) => !!cardLast4)

export const getIsInfractionPaymentInfoConfirmed = createSelector(
  [getHealthPlanInfractionCardLast4],
  (cardLast4) => !!cardLast4,
)

export const getHealthPlanCostSharingType = createSelector(getSelectedHealthPlanConfig, (selectedHealthPlanConfig) =>
  selectedHealthPlanConfig?.get('payment_type'),
)

export const getHealthPlanCopayAmount = createSelector([getSelectedHealthPlanConfig], (selectedHealthPlanConfig) => {
  const amount = Number(selectedHealthPlanConfig?.get('copay'))
  return isNaN(amount) ? undefined : amount
})

export const getIsHealthPlanIntegrated = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('isHealthPlanIntegrated'),
)

export const getIsSecondaryHealthPlanIntegrated = createSelector(getHealthPlan, (healthPlan) =>
  healthPlan?.get('isSecondaryHealthPlanIntegrated'),
)

export const getHasExistingHealthPlan = createSelector(
  [getHealthPlanEligibilityStatus, getSecondaryHealthPlanEligibilityStatus],
  (eligibilityStatus, secondaryEligibilityStatus) => !!eligibilityStatus || !!secondaryEligibilityStatus,
)

export const getHealthPlanLegalInfo = createSelector(getHealthPlan, (healthPlan) => {
  return {
    legalFirstName: healthPlan?.get('legalFirstName'),
    legalLastName: healthPlan?.get('legalLastName'),
    legalDob: healthPlan?.get('legalDob'),
    healthPlanName: healthPlan?.get('healthPlanName'),
    healthPlanMembershipId: healthPlan?.get('healthPlanMembershipId'),
  }
})

export const getSecondaryHealthPlanLegalInfo = createSelector(getHealthPlan, (healthPlan) => {
  return {
    legalFirstName: healthPlan?.get('secondaryHealthPlanLegalFirstName'),
    legalLastName: healthPlan?.get('secondaryHealthPlanLegalLastName'),
    legalDob: healthPlan?.get('secondaryHealthPlanLegalDob'),
    healthPlanName: healthPlan?.get('secondaryHealthPlanName'),
    healthPlanMembershipId: healthPlan?.get('secondaryHealthPlanMembershipId'),
  }
})
