import { CMS_MICROSITE_MESSAGE_KEY } from '../messages/messages'
import { CMSMicrositeMetadata } from '../types'

export const copingWithStressToolkitMetadata: CMSMicrositeMetadata = {
  micrositeId: 'CopingWithStressToolkit',
  iFrameSrcUrl: '/stress',
  micrositeUrl: 'stress',
  title: {
    id: 'copingWithStressToolkit.title',
    defaultMessage: 'Coping with Stress',
    description: 'Microsite page title',
  },
  featureFlag: 'isStressToolkitEnabled',
  customerPropertyConditions: [
    {
      key: 'essentialsDisabled',
      value: true,
    },
  ],
  postMessageEvents: [
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_FORKED_TRIAGE,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_ESSENTIALS,
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR,
      params: {
        webinarLink: '/secure/index/essentials/events',
      },
    },
    {
      key: CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_REGISTRATION,
    },
    { key: CMS_MICROSITE_MESSAGE_KEY.CLICK_SELF_CARE_CONTENT },
  ],
  page: '202503 coping with stress',
  trackUTMParams: {
    utm_campaign: 'stress202503',
  },
  customMixpanelEventProps: {
    toolkit_topic: 'coping with stress',
  },
  shouldSupportSpanish: false,
  shouldHideForInternational: true,
}
