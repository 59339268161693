import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { LegalFooter } from '../../organisms/legalFooter/LegalFooter'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'

const CopyrightText = styled(BodyText)<{ theme: ThemeType; removeSpacing?: boolean }>(({ theme, removeSpacing }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  ...(!removeSpacing && { marginBottom: theme.spacing['16px'] }),
}))

type LegalAndCopyrightFooterProps = {
  footerLinkPressed: (url: string) => void
  showHIPAALink?: boolean
  backgroundColor?: string
  legalNotice?: React.ReactNode
  isInternational?: boolean
  removeSpacing?: boolean
  showCopyright?: boolean
}

export const LegalAndCopyrightFooter: React.FC<LegalAndCopyrightFooterProps> = ({
  footerLinkPressed,
  showHIPAALink,
  backgroundColor,
  legalNotice,
  isInternational,
  removeSpacing,
  showCopyright = true,
}) => {
  const { colors } = useTheme() as ThemeType
  return (
    <>
      {showCopyright && (
        <CopyrightText
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          text={`© ${new Date().getFullYear()} Lyra Health, INC • 270 East Ln, Burlingame, CA 94010`}
          size={BodyTextSize.SMALL}
          color={colors.textSecondary}
          removeSpacing={removeSpacing}
        />
      )}
      <LegalFooter
        footerLinkPressed={footerLinkPressed}
        legalNotice={legalNotice}
        showHIPAALink={showHIPAALink}
        backgroundColor={backgroundColor ?? colors.backgroundTransparent}
        removeBottomSpacing
        isInternational={isInternational}
      />
    </>
  )
}
