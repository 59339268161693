import React, { FunctionComponent } from 'react'

import { ProfileOnboardingIconProps } from './ProfileOnboardingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const ProfileOnboardingIconSvg = withSuspense({
  component: React.lazy(() => import('./ProfileOnboardingIconSvg')),
})

export const ProfileOnboardingIcon: FunctionComponent<ProfileOnboardingIconProps> = ({ width, height }) => {
  return <ProfileOnboardingIconSvg width={width} height={height} />
}
