import React, { FunctionComponent } from 'react'

import { LinkIconProps } from './LinkIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const LinkIconSvg = withSuspense({
  component: React.lazy(() => import('./LinkIconSvg')),
})

/**
 * Icon that displays a link
 */
export const LinkIcon: FunctionComponent<LinkIconProps> = ({ size }) => {
  return <LinkIconSvg size={size} />
}
