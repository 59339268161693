import React, { FunctionComponent } from 'react'
import { ImageSourcePropType, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  CloseQuotationIcon,
  OpenQuotationIcon,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { Image } from '../../../atoms/image/Image'
import { CarouselItem } from '../../organisms/pathwaysTestimonialsCarousel/PathwaysTestimonialsCarousel'

const CardContainer = styled(View)<{
  isOpaque: boolean
  width: string
}>(({ isOpaque, width }) => ({
  width: width,
  opacity: isOpaque ? '0.5' : 'inherit',
}))

const ImageContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing['32px'],
  overflow: 'hidden',
  borderRadius: '32px',
}))

const TitleContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  fontSize: '16px',
  flex: 1,
  padding: theme.breakpoints.isMobileSized ? '0 8px' : '0 12px',
}))

const TestimonialContent = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const ContentContainer = styled(View)(({}) => ({
  flexDirection: 'row',
}))

const IconContainer = styled(View)<{
  justifyContent: string
  marginTop?: string
  marginBottom?: string
}>(({ justifyContent, marginTop = '0', marginBottom = '0' }) => ({
  justifyContent: justifyContent,
  marginTop: marginTop,
  marginBottom: marginBottom,
}))

const ImageComponentContainer = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: width,
  height: height,
  borderRadius: '32px',
}))

export const PathwayTestimonialsCarouselCard: FunctionComponent<CarouselItem> = ({
  imageUrl,
  text,
  field,
  isOpaque,
  imageWidth,
  imageHeight,
  width,
}) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()
  const QUOTATION_MARGIN = '5%'

  return (
    <CardContainer testID={tID(`PathwayTestimonialsCarouselCard`)} isOpaque={isOpaque} width={width} tabIndex={0}>
      <ImageContainer>
        <ImageComponentContainer
          source={imageUrl as ImageSourcePropType}
          contentFit='cover'
          accessibilityIgnoresInvertColors
          width={imageWidth}
          height={imageHeight}
        />
      </ImageContainer>
      <ContentContainer>
        <IconContainer justifyContent='start' marginTop={QUOTATION_MARGIN}>
          <OpenQuotationIcon width={24} />
        </IconContainer>
        <TitleContainer>
          {isMobileSized ? (
            <TestimonialContent>
              <BodyText
                textAlign='center'
                noRole
                text={text}
                color={colors.textSecondary}
                size={BodyTextSize.DEFAULT}
              />
            </TestimonialContent>
          ) : (
            <TestimonialContent>
              <Subhead textAlign='center' noRole text={text} color={colors.textSecondary} size={SubheadSize.SMALL} />
            </TestimonialContent>
          )}
        </TitleContainer>
        <IconContainer justifyContent='end' marginBottom={QUOTATION_MARGIN}>
          <CloseQuotationIcon width={24} />
        </IconContainer>
      </ContentContainer>
      {isMobileSized ? (
        <>
          <BodyText textAlign='center' noRole text={field} color={colors.textSecondary} size={BodyTextSize.SMALL} />
        </>
      ) : (
        <>
          <Subhead textAlign='center' noRole text={field} color={colors.textSecondary} size={SubheadSize.SMALL} />
        </>
      )}
    </CardContainer>
  )
}
