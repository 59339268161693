import React from 'react'
import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

export const asurion: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    servicesNotCovered: `Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization(including partial), intensive outpatient treatment, emergent care, therapy or counseling sessions beyond the ${numberOfSessions} sessions offered, prescription medication, diagnosis for autism spectrum disorder, services for remedial education, executive coaching, and non-evidence-based behavioral health care. Please check with your medical plan for coverage of these excluded services.`,
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization(including partial), intensive outpatient treatment, emergent care, therapy or counseling sessions beyond the {sessionLimit} sessions offered, prescription medication, diagnosis for autism spectrum disorder, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  }
}
