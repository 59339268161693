import * as crypto from 'crypto'

import uuid from 'uuid'

import { getDocumentObject } from '../common/utils/commonUtils'

/** Launch Darkly device uuid key in local storage */
export const LAUNCH_DARKLY_DEVICE_UUID_LOCAL_STORAGE_KEY = 'ld:device'

/**
 * Get launch darkly user device uuid stored in local storage
 */
export const launchDarklyUserDeviceUUID = (() => {
  let deviceUUID = null
  if (getDocumentObject() && typeof localStorage !== undefined) {
    const currentDeviceUUID = localStorage.getItem(LAUNCH_DARKLY_DEVICE_UUID_LOCAL_STORAGE_KEY)
    if (currentDeviceUUID) {
      return currentDeviceUUID
    } else {
      const v1 = uuid?.v1?.()
      if (v1) {
        /**
         * Generating our own uuid v1 and hashing it rather than using
         * Launch Darkly's auto generated device uuid which is v1 and unhashed.
         * Making a hashed version prevents us from exposing a user's PII if someone were to get access to Launch Darkly
         * and had the user's device UUID
         */
        deviceUUID = crypto.createHash('sha256').update(v1).digest('hex')
        localStorage.setItem(LAUNCH_DARKLY_DEVICE_UUID_LOCAL_STORAGE_KEY, deviceUUID)
      } else {
        console.warn('Failed to set LaunchDarkly device uuid - Could not get v1 from uuid')
      }
    }
  }
  return deviceUUID
})()
