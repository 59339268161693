export const USER_FIRST_NAME = 'userFirstName'
export const USER_LAST_NAME = 'userLastName'
export const USER_PREFERRED_FIRST_NAME = 'userPreferredFirstName'
export const USER_PREFERRED_LAST_NAME = 'userPreferredLastName'
export const USER_DOB = 'userDOB'
export const PASSWORD = 'password'
export const USER_ELIGIBILITY_TYPE = 'eligibilityType'
export const EMPLOYEE_FIRST_NAME = 'employeeFirstName'
export const EMPLOYEE_LAST_NAME = 'employeeLastName'
export const EMPLOYEE_DOB = 'employeeDOB'
export const DEPENDENT_ACCOUNT_CREATION = 'dependentAccountCreation'
export const DEPENDENT_EMPLOYEE_RELATIONSHIP = 'dependentTypeId'
export const CUSTOMER_CODE_FIELD_NAME = 'customerCode'
export const COUNTRY_SELECTION_FIELD_NAME = 'countryInfo'

export const BASIC_INFO_FORM_PATH = '/register/account-setup/basic-info'
export const BACKGROUND_INFO_FORM_PATH = '/register/account-setup/background-info'
export const REGISTRATION_ONBOARDING = '/secure/index/account-info/background-info'
export const REGISTRATION_SUCCESS_PATH = '/secure/index/search-care'
export const HEALTH_PLAN_BASIC_INFO_FORM_PATH = '/register/account-setup/basic-info/healthplan'
export const HEALTH_PLAN_BACKGROUND_INFO_FORM_PATH = '/register/account-setup/background-info/healthplan'
export const CARE_TEAM_BASIC_INFO_FORM_PATH = '/register/verify-account/basic-info'
export const CARE_TEAM_BACKGROUND_INFO_FORM_PATH = '/register/verify-account/background-info'
export const PARENT_LED_TEEN_REGISTRATION_BASIC_INFO_FORM_PATH = '/register/verify-account/teen/basic-info'
export const CONFIRM_COVERAGE_BASIC_INFO_FORM_PATH = '/secure/index/confirm-coverage/basic-info'
export const REQUIRE_ELIGIBILITY_FORM_PATH = '/secure/index/confirm-coverage/require-eligibility'
export const CONFIRM_COVERAGE_REGISTRATION_SUCCESS_PATH = '/secure/index/search-care'
export const PARENT_LED_TEEN_WELCOME_PAGE_PATH = '/secure/index/welcome/teen'
export const AUTO_ENROLLMENT_REGISTRATION_PATH = '/register/auto'
export const AUTO_ENROLLMENT_PATHWAYS_PATH = '/secure/index/pathways'

export const ELIGIBILITY_EXPIRATION_IN_MINS = 30 * 24 * 60 // 30 days
export const ELIGIBILITY_EXPIRATION__WALMART_IN_MINS = 15 // 15 mins
