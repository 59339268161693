import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CostMessagingModalContent,
  CoverageType,
  GetCustomerCopyFunc,
  ProgramNames,
} from '@lyrahealth-inc/shared-app-logic'

import { medicalConsultationTitle, medicationConsulationDescription } from './util/DefaultCoverageTexts'

export const attCoachingOrTherapyTitle = (
  <FormattedMessage
    defaultMessage='Therapy or coaching sessions per issue, per year'
    description='Cost messaging modal session description text'
  />
)

const attCoachingOrTherapyDescriptionPerIssueForFamily = (numberOfSessions: string, customerName: string) => {
  return (
    <FormattedMessage
      defaultMessage='Meet with a provider up to {numberOfSessions} times per issue, per year—covered by {customerName} for you and your dependents.'
      description='Cost messaging modal session description text'
      values={{ numberOfSessions, customerName }}
    />
  )
}

export const getAttUnionCostMessagingModalText = (numberOfSessions: string, customerDisplayName: string) => [
  {
    title: attCoachingOrTherapyTitle,
    description: attCoachingOrTherapyDescriptionPerIssueForFamily(numberOfSessions, customerDisplayName),
    contentType: ProgramNames.BlendedCareTherapy,
  },
  {
    title: medicalConsultationTitle,
    description: medicationConsulationDescription,
    contentType: ProgramNames.MedicationManagement,
  },
]

const getCostMessagingModalText = (
  numberOfSessions: string,
  customerDisplayName: string,
): { [key in CoverageType]: () => CostMessagingModalContent[] } => {
  const attUnionCostMessagingModalText = getAttUnionCostMessagingModalText(numberOfSessions, customerDisplayName)
  return {
    [CoverageType.EAP_ONLY]: () => {
      return attUnionCostMessagingModalText
    },
    [CoverageType.HPI_ONLY]: () => {
      return attUnionCostMessagingModalText
    },
    [CoverageType.EAP_AND_HPI]: () => {
      return attUnionCostMessagingModalText
    },
  }
}

export const attunion: GetCustomerCopyFunc = ({
  numberOfSessions,
  customerName,
  customerDisplayName,
  displayVisitsPerIssuePerYear,
}) => {
  return {
    costMessagingModalText: getCostMessagingModalText(
      displayVisitsPerIssuePerYear != undefined ? displayVisitsPerIssuePerYear.toString() : numberOfSessions || '',
      customerDisplayName ? customerDisplayName : customerName ?? '',
    ),
  }
}
