import { useCallback, useEffect, useState } from 'react'

import { isEmpty, isNil } from 'lodash-es'

import { TriageScreener } from '../../../models'
import { getVisibleFields } from '../../assignments/utils'
import { usePrevious } from '../../common/hooks/usePrevious'

const autoAdvanceSupportedWidgets = ['radio', 'imageRadio']

interface useTriageAutoAdvanceArgs {
  submitForm?: () => void | null
  metadata: TriageScreener
  initialValues?: Dict
  context: Dict | null
  isLastScreener?: boolean
  forceShowContinue?: boolean
}

export const useTriageAutoAdvance = ({
  submitForm,
  metadata,
  initialValues,
  context,
  isLastScreener,
  forceShowContinue,
}: useTriageAutoAdvanceArgs) => {
  const DELAY_LENGTH = 300
  const metadataName = metadata.name
  const previousMetadataName = usePrevious(metadata.name)
  const [hideContinue, setHideContinue] = useState(true)
  const [hadInitialValues, setHadInitialValues] = useState(false)
  const [delayAfterSubmit, setDelayAfterSubmit] = useState(false)
  const [checkInitialState, setCheckInitialState] = useState(true)
  const { uiSchema, schema } = metadata.data
  let hideContinueButton = hideContinue && !hadInitialValues && !isLastScreener
  if (forceShowContinue) {
    hideContinueButton = false
  }

  // When the metadata name changes we need to check the initial state again
  useEffect(() => {
    if (metadataName !== previousMetadataName) {
      setCheckInitialState(true)
    }
  }, [metadataName, previousMetadataName])

  const getAutoAdvanceData = useCallback(
    (values: any) => {
      const visibleFields = getVisibleFields({
        uiSchema,
        schema,
        values: { ...values, ...(context?.externalValues || {}) },
      })
      // Filter out content fields
      const formFields = visibleFields?.filter((fieldName: string) => !!uiSchema[fieldName]['ui:widget'])
      const allFieldsSupportAutoAdvance = formFields?.every((fieldName: string) =>
        autoAdvanceSupportedWidgets.includes(uiSchema[fieldName]['ui:widget']),
      )
      return { formFields, allFieldsSupportAutoAdvance }
    },
    [context?.externalValues, schema, uiSchema],
  )

  useEffect(() => {
    if (checkInitialState && !isNil(initialValues)) {
      setHadInitialValues(!isEmpty(initialValues))
      setHideContinue(getAutoAdvanceData(initialValues).allFieldsSupportAutoAdvance)
      setCheckInitialState(false)
    }
  }, [checkInitialState, getAutoAdvanceData, initialValues])

  const handleFormChange = ({ values }: { values: any }) => {
    const { formFields, allFieldsSupportAutoAdvance } = getAutoAdvanceData(values)
    setHideContinue(allFieldsSupportAutoAdvance)
    if (
      !hadInitialValues &&
      !isLastScreener &&
      allFieldsSupportAutoAdvance &&
      formFields.every((fieldName: string) => {
        // Sometimes the fieldName is dot notation like 'selfHarm.suicidalThoughts'
        const fieldValue = fieldName.split('.').reduce((o, i) => o?.[i], values)
        return !isNil(fieldValue)
      })
    ) {
      if (!!submitForm) {
        setDelayAfterSubmit(true)
        submitForm()
        setTimeout(() => setDelayAfterSubmit(false), DELAY_LENGTH)
      }
    }
  }

  return { hideContinue: hideContinueButton && !!submitForm, handleFormChange, autoAdvanceDelay: delayAfterSubmit }
}
