import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { Size } from '../../atoms/bodyText/BodyText'
import { AlertQuietIcon } from '../../atoms/icons/AlertQuietIcon'
import { BodyTextSize } from '../../styles'
import { ThemeType } from '../../utils'
import { InlineBanner } from '../inlineBanner/InlineBanner'

export interface InlineWarningBannerProps {
  text: React.ReactNode | string
  testId?: string
  backgroundColor?: string
  iconColor?: string
  textColor?: string
  trailingLink?: React.ReactElement
  marginBottom?: string
  textSize?: BodyTextSize
}

export const InlineWarningBanner: FunctionComponent<InlineWarningBannerProps> = ({
  text,
  testId,
  backgroundColor,
  iconColor,
  textColor,
  trailingLink,
  marginBottom,
  textSize = Size.DEFAULT,
}) => {
  const { colors } = useTheme() as ThemeType
  const { inlineWarningBanner } = colors.components

  const icon = <AlertQuietIcon size={15} fillColor={iconColor || inlineWarningBanner.warningIcon.fill} />
  return (
    <InlineBanner
      text={text}
      icon={icon}
      testId={testId}
      backgroundColor={backgroundColor || inlineWarningBanner.background}
      textColor={textColor || inlineWarningBanner.text}
      trailingLink={trailingLink}
      marginBottom={marginBottom}
      textSize={textSize}
    />
  )
}
