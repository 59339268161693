import { ProviderProgramTaxonomy } from '../providers/Providers'

// handles types from the programs table in LT
export enum ProgramNames {
  'SingleSessionCoaching' = 'SingleSessionCoaching',
  'BlendedCareTherapy' = 'BlendedCareTherapy',
  'Coaching' = 'Coaching',
  'MedicationManagement' = 'MedicationManagement',
  'AlcoholUseDisorderTherapy' = 'AlcoholUseDisorderTherapy',
  // Non LT programs
  'DIRECT_ACCESS_MEDS' = 'DIRECT_ACCESS_MEDS',
  'SELF_CARE_APPS' = 'SELF_CARE_APPS',
  'DIRECT_ACCESS_THERAPY' = 'DIRECT_ACCESS_THERAPY',
  'ICASTherapy' = 'ICASTherapy',
  'ClinicalLeaveEvaluation' = 'ClinicalLeaveEvaluation',
  'TeensTherapy' = 'TeensTherapy',
}

export interface Program {
  appointment_change_window_hours: number
  appt_follow_up_ai_prompts?: string[]
  appt_initial_ai_prompts?: string[]
  appt_initial_mins: number
  appt_recurring_mins: number
  assessment_sets?: string[]
  compatible_programs: CompatibleProgram[]
  create_date: string
  curricula: unknown
  default_grace_period_hours: number
  employment_week_work_hours?: {
    [key: string]: number
  }
  gad_threshold?: number
  id: string
  late_cancel_charge_initial: number
  late_cancel_charge_recurring: number
  name: string
  phq_threshold?: number
  program_taxonomy: ProviderProgramTaxonomy[]
  provider_modality: string
  session_type_initial: string
  session_type_recurring: string
  utilization_target_percent?: {
    [key: string]: number
  }
  utilization_target_plus_percent?: {
    [key: string]: number
  }
}

interface CompatibleProgram {
  compatible_program_id: string
  id: string
  program_id: string
}

const ProgramNameLabels = new Map<ProgramNames, string>([
  [ProgramNames.SingleSessionCoaching, 'Guided self-care'],
  [ProgramNames.BlendedCareTherapy, 'Therapy'],
  [ProgramNames.TeensTherapy, 'TeensTherapy'],
  [ProgramNames.Coaching, 'Coaching'],
  [ProgramNames.MedicationManagement, 'Medication Management'],
  [ProgramNames.AlcoholUseDisorderTherapy, 'Renew'],
  [ProgramNames.ClinicalLeaveEvaluation, 'Clinical Leave Evaluation'],
])

const ProgramIdNames = new Map<string, ProgramNames>([
  ['7ec82ca3-843f-4996-bdf9-49d8c7a35ed5', ProgramNames.Coaching],
  ['c8cb4da4-8957-4cff-9d8e-1fa742f06771', ProgramNames.BlendedCareTherapy],
  ['b42a0c74-76c0-48c4-8a7e-a767418bd753', ProgramNames.TeensTherapy],
  ['deb574e4-6aa3-4b47-bd21-d8be3ba5d42b', ProgramNames.SingleSessionCoaching],
  ['edcea864-b6eb-4d16-8c4c-ce748c18025e', ProgramNames.AlcoholUseDisorderTherapy],
  ['1b2b8b47-4c0e-49ff-a65c-40e187360b70', ProgramNames.MedicationManagement],
  ['6f146c5b-ac3b-42e5-b2a2-aacb3035e6d8', ProgramNames.ClinicalLeaveEvaluation],
])

/**
 * Helper function to get the program name label from the ProgramNameLabels map
 */
export const getProgramNameLabel = (programName: ProgramNames) => {
  return ProgramNameLabels.get(programName) ?? ''
}

/**
 * Helper function to get the program name from the ProgramId
 */
export const getProgramNameFromId = (programId?: string | null) => {
  return programId ? ProgramIdNames.get(programId) ?? undefined : undefined
}

/**
 * Helper function to get the programId from programName
 */
export const getProgramIdFromName = (programName: ProgramNames) => {
  for (const [key, value] of ProgramIdNames.entries()) {
    if (value === programName) {
      return key
    }
  }
  return undefined
}
