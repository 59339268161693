import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Determines whether the current page can go back otherwise it will return to the provided default route
 */
export const useNavigateBackOrDefault = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return useCallback(
    (defaultBackLocationPath: string) => {
      if (location.key === 'default') {
        navigate(defaultBackLocationPath)
      } else {
        navigate(-1)
      }
    },
    [location, navigate],
  )
}
