import React from 'react'

import { excludedServices, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

const servicesNotCovered = `There are some services that are not covered by Lyra, which include ${excludedServices}. For coverage and plan information on these types of services, contact Collective Health.`

export const redbull: GetCustomerCopyFunc = () => {
  return {
    servicesNotCovered,
    legalNotice: <CoverageNotice />,
  }
}
