import React, { FunctionComponent } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { ImageSourcePropType } from 'react-native'

import { isEmpty, isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { PressableOpacity } from '../../atoms'
import { ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { ChevronV2Icon } from '../../atoms/icons/ChevronV2Icon'
import { Image } from '../../atoms/image/Image'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TruncatedText } from '../../atoms/truncate/TruncatedText'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface BenefitPreviewItemProps {
  title: string | MessageDescriptor
  image: React.ReactNode | string | number
  description: MessageDescriptor | string
  badges?: React.ReactNode[]
  testID?: string
  onPress: () => void
}

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  paddingTop: spacing['4px'],
  marginRight: spacing['16px'],
  borderRadius: spacing['8px'],
}))

const ImageComponent = styled(Image)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  height: spacing['48px'],
  width: spacing['48px'],
  borderRadius: spacing['8px'],
}))

const Row = styled.View({
  flexDirection: 'row',
  flexGrow: 1,
  flexShrink: 1,
})

const Column = styled.View({
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
})

const BodyContainer = styled.View({
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
})

const BenefitTitleContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexShrink: 1,
  paddingRight: spacing['8px'],
}))

const BadgesContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
  }) => ({
    flexDirection: 'row',
    marginBottom: isMinWidthTablet ? spacing['4px'] : 0,
    marginTop: !isMinWidthTablet ? spacing['4px'] : 0,
    marginRight: isMinWidthTablet ? spacing['24px'] : 0,
  }),
)

const ChevronContainer = styled.View<{ theme: ThemeType; badgesArePresent?: boolean }>(
  ({ theme: { spacing }, badgesArePresent }) => ({
    marginLeft: badgesArePresent ? 'auto' : spacing['24px'],
    alignSelf: badgesArePresent ? 'flex-start' : 'center',
    paddingTop: spacing['4px'],
  }),
)

const DescriptionContainer = styled.View({
  flexShrink: 1,
})

const Container = styled(PressableOpacity)({
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: '16px',
})

const Badges = ({ badges }: { badges?: React.ReactNode[] }) =>
  !isEmpty(badges) ? <BadgesContainer>{badges}</BadgesContainer> : null

type ResponsiveLayoutProps = Omit<BenefitPreviewItemProps, 'onPress' | 'image'>

const ResponsiveLayout = ({ title, description, badges }: ResponsiveLayoutProps) => {
  const { formatMessage } = useIntl()
  const {
    colors,
    breakpoints: { isMinWidthTablet },
  } = useTheme()

  const titleToDisplay = isString(title) ? title : formatMessage(title)

  let textToDisplay = ''
  if (description) {
    textToDisplay = isString(description) ? description : formatMessage(description, { br: '\n' })
  }

  const descriptionComponent = (
    <TruncatedText
      textSize={BodyTextSize.SMALL}
      fontColor={colors.textSecondary}
      maxNumberOfLines={2}
      collapseExpandColor={colors.textSecondary}
      collapseExpandText={[
        formatMessage({
          defaultMessage: '- Less',
          description: 'Read less for truncated benefit preview content',
        }),
        formatMessage({
          defaultMessage: '+ More',
          description: 'Read more for truncated benefit preview content',
        }),
      ]}
      collapseExpandTextSize={BodyTextSize.SMALL}
      toggleCollapseExpand={true}
      underline={true}
      text={textToDisplay}
    />
  )

  return (
    <BodyContainer>
      {!isEmpty(badges) ? (
        <>
          <Row>
            <BenefitTitleContainer>
              <Subhead numberOfLines={1} ellipsizeMode='tail' size={SubheadSize.XSMALL} text={titleToDisplay} />
            </BenefitTitleContainer>
            {isMinWidthTablet && <Badges badges={badges} />}
            <ChevronContainer badgesArePresent={!isEmpty(badges)}>
              <ChevronV2Icon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconInactive} />
            </ChevronContainer>
          </Row>
          <DescriptionContainer>{descriptionComponent}</DescriptionContainer>
          {!isMinWidthTablet && <Badges badges={badges} />}
        </>
      ) : (
        <Row>
          <Column>
            <Row>
              <BenefitTitleContainer>
                <Subhead numberOfLines={1} ellipsizeMode='tail' size={SubheadSize.XSMALL} text={titleToDisplay} />
              </BenefitTitleContainer>
            </Row>
            <DescriptionContainer>{descriptionComponent}</DescriptionContainer>
          </Column>
          <ChevronContainer badgesArePresent={!isEmpty(badges)}>
            <ChevronV2Icon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconInactive} />
          </ChevronContainer>
        </Row>
      )}
    </BodyContainer>
  )
}

export const BenefitPreviewItem: FunctionComponent<BenefitPreviewItemProps> = ({
  title,
  image,
  description,
  badges,
  testID,
  onPress,
}) => {
  const imageIsComponent = React.isValidElement(image)
  return (
    <Container testID={tID(`IntegratedBenefitPreviewItem${testID ? `-${testID}` : ''}`)} onPress={onPress}>
      <ImageContainer>
        {imageIsComponent ? image : <ImageComponent source={image as ImageSourcePropType} />}
      </ImageContainer>
      <ResponsiveLayout title={title} description={description} badges={badges} />
    </Container>
  )
}
