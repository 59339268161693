import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Pressable } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Button } from '../../atoms/button/Button'
import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { RefreshIcon } from '../../atoms/icons/RefreshIcon'
import { Image } from '../../atoms/image/Image'
import { Link } from '../../atoms/link/Link'
import { ThemeType } from '../../utils'
import { mailTo, tID } from '../../utils/utils'

export interface GenericErrorProps {
  onReloadPress?: () => void
  error?: Error
  onView?: (error: Error) => void
}

const Container = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  justifyContent: 'center',
  flexGrow: 1,
  alignItems: 'center',
  backgroundColor: colors.backgroundSecondary,
  padding: 16,
  margin: 0,
}))

const Illustration = styled(Image)(() => ({
  height: 252,
  width: '100%',
  marginBottom: 50,
}))

const ReloadButton = styled(Button)(() => ({
  width: '100%',
  maxWidth: 220,
  marginTop: 32,
}))

const ErrorContainer = styled.View(() => ({
  marginTop: 32,
}))

export const GenericError: FunctionComponent<GenericErrorProps> = ({ onReloadPress = noop, error, onView = noop }) => {
  const [showError, setShowError] = useState(false)
  const { colors } = useTheme()

  useEffect(() => {
    onView(error)
  }, [error, onView])

  const onCareLinkPress = () => mailTo('care@lyrahealth.com')
  return (
    <Container testID={tID('GenericError')}>
      <Illustration source={require('../../assets/map_illustration.png')} contentFit='contain' />
      <Pressable onLongPress={() => setShowError(!showError)} delayLongPress={3000}>
        <Headline
          text={
            <FormattedMessage
              defaultMessage='Oops!'
              description='Title of the screen shown when there is an error indicating something went wrong.'
            />
          }
          size={HeadlineSize.SMALL}
          textAlign='center'
        />
      </Pressable>
      <BodyText
        size={BodyTextSize.LARGE}
        textAlign='center'
        text={
          <FormattedMessage
            defaultMessage={`Something went wrong. If there are still errors, email <link>care@lyrahealth.com</link>`}
            description='A message shown to users when the error page is shown to direct the users to send an email if the problem persists.'
            values={{
              link: (chunks) => <Link text={chunks} onPress={onCareLinkPress} size={BodyTextSize.LARGE} />,
            }}
          />
        }
      ></BodyText>
      <ReloadButton
        title={
          <FormattedMessage
            defaultMessage='Reload'
            description='Text for button to tell the user to reload the page.'
          />
        }
        icon={<RefreshIcon />}
        onPress={onReloadPress}
      />
      {showError && !!error && (
        <ErrorContainer>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <BodyText text={`${error.name}: ${error.message}`} size={BodyTextSize.SMALL} color={colors.textError} />
        </ErrorContainer>
      )}
    </Container>
  )
}
