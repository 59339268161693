import { useCallback } from 'react'

import { DirectLinkSource } from '../../searchForCare/constants'
import { WELLNESS_TOOLKIT_PROMOTION } from '../../wellnessToolkit/types'

interface useNavigateToWellnessToolkitArgs {
  setToolkitPromotion: (toolkitPromotion: WELLNESS_TOOLKIT_PROMOTION) => void
  setDirectLinkSource: (directLinkSource: DirectLinkSource | string) => void
  navigateToToolkit: (
    toolkitPromotion: WELLNESS_TOOLKIT_PROMOTION,
    navigationParams?: { onBackPressed: () => void },
  ) => void
}

/**
 * Hook that handles setting state variable needed for toolkit navigation
 */
export const useNavigateToWellnessToolkit = ({
  setToolkitPromotion,
  setDirectLinkSource,
  navigateToToolkit,
}: useNavigateToWellnessToolkitArgs) => {
  return useCallback(
    ({
      toolkitPromotion,
      directLinkSource,
      navigationParams,
    }: {
      toolkitPromotion: WELLNESS_TOOLKIT_PROMOTION
      directLinkSource: DirectLinkSource | string
      navigationParams?: { onBackPressed: () => void }
    }) => {
      setToolkitPromotion(toolkitPromotion)
      setDirectLinkSource(directLinkSource)

      navigateToToolkit(toolkitPromotion, navigationParams)
    },
    [navigateToToolkit, setDirectLinkSource, setToolkitPromotion],
  )
}
