import { useIntl } from 'react-intl'
import { Share } from 'react-native'

import { useTheme } from 'styled-components/native'
import UAParser from 'ua-parser-js'

import { getShareContent, REFERRAL_EVENTS } from '@lyrahealth-inc/shared-app-logic'
import {
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
} from '@lyrahealth-inc/shared-app-logic/src/features/common/constants/utm'
import { BodyTextSize } from '@lyrahealth-inc/ui-core-crossplatform'

import { setToastContent } from '../../data/appGlobals/appGlobalsActions'
import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'

interface ShareReferralArgs {
  source: UTM_SOURCE
  entryPoint: string
}

const getReferralUrl = (source: UTM_SOURCE, medium: UTM_MEDIUM) =>
  `${window.location.origin}/register/connect?utm_source=${source}&utm_medium=${medium}&utm_campaign=${UTM_CAMPAIGN.REFERRAL}`

const getComingSoonReferralUrl = (source: UTM_SOURCE, medium: UTM_MEDIUM) =>
  `${window.location.origin}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${UTM_CAMPAIGN.REFERRAL}`

/**
 *
 * Handles showing the Share tray for Referrals in Web
 * 1. If user is on desktop size, copy the link and show a toast saying that the url is copied
 * 2. If user is in mobile size, try to open the user's Share tray from their phone. If that does
 *  not work then default to copying with toast
 */
export const useReferral = () => {
  const { formatMessage } = useIntl()
  const {
    breakpoints: { isMobileSized },
  } = useTheme()
  const dispatch = useAppDispatch()

  const shareReferral = async ({ entryPoint, source }: ShareReferralArgs) => {
    const medium =
      source === UTM_SOURCE.WELCOME_EMAIL
        ? isMobileSized
          ? UTM_MEDIUM.EMAIL_MOBILE
          : UTM_MEDIUM.EMAIL_DESKTOP
        : isMobileSized
        ? UTM_MEDIUM.PRODUCT_MOBILE
        : UTM_MEDIUM.PRODUCT_DESKTOP

    const extraEventObj = {
      entrypoint: entryPoint,
      utm_source: source,
      utm_medium: medium,
      utm_campaign: UTM_CAMPAIGN.REFERRAL,
    }

    dispatch(trackEventWithObj({ event: REFERRAL_EVENTS.CLICK_REFERRAL_CTA, ...extraEventObj }))

    const referralUrl =
      source === 'coming-soon-microsite-banner'
        ? getComingSoonReferralUrl(source, medium)
        : getReferralUrl(source, medium)

    const copyToClipboard = () => {
      navigator.clipboard.writeText(referralUrl)
      dispatch(
        setToastContent({
          text: formatMessage({
            defaultMessage: 'Link copied! Share with family members, dependents or colleagues.',
            description: 'Text displayed when user copied referral link',
          }),
          toastType: 'success',
          id: 'Referral-toast',
          textSize: BodyTextSize.SMALL,
        }),
      )
    }

    if (isMobileSized) {
      try {
        const userAgent = new UAParser(window.navigator.userAgent)
        const userOS = userAgent.getOS().name || ''
        const isIOSDevice = userOS.toLocaleLowerCase() === 'ios'
        const shareContent = getShareContent(referralUrl, formatMessage, isIOSDevice)
        await Share.share(shareContent, { dialogTitle: 'Lyra Health', subject: 'Lyra Health' })
        // Log successful sharing
        dispatch(trackEventWithObj({ event: REFERRAL_EVENTS.SHARE_REFERRAL_LINK, ...extraEventObj }))
      } catch (error: any) {
        // Log when sharing is dismissed
        if (error?.name === 'AbortError') {
          dispatch(trackEventWithObj({ event: REFERRAL_EVENTS.SHARE_REFERRAL_LINK_CANCELLED, ...extraEventObj }))
        } else {
          // If share menu doesn't open in web mobile, log the error and fallback
          // to copy to clipboard functionality
          console.error('Failed to open share menu on web mobile', error)
          copyToClipboard()
        }
      }
    } else {
      copyToClipboard()
    }
  }

  return { shareReferral }
}
