import { DependencyList, useEffect, useRef, useState } from 'react'

import axios, { Canceler } from 'axios'
import { every, isArray, isEmpty } from 'lodash-es'

/**
 * Hook to help fetch data in an effect.
 *
 * @param {Array} actions An array of actions with the following structure:
 * [<function>, <params object>, <shouldFetch boolean>]
 * It also support an array of arrays to make requests in parallel:
 * [[<function 1>, <params object 1>, <shouldFetch1>], [<function 2>, <params object 2>, <shouldFetch2>]]
 * @param {Array} deps The dependencies of the effect
 * @param {Object} options An options object
 * @param {Function} options.onSuccess Success callback called when the promise resolves.
 * @param {Function} options.onFail Error callback called when the promise is aborted.
 * @param {Function} options.onFinally Finally callback called when the promise is resolved or aborted.
 * @returns {Array} An array with the following stucture:
 *  [
 *    loading, // A boolean that indicates the loading state
 *    response, // The response from the request. `undefined` or `[]` while fetching respectively for 1 action or an array of actions
 *    hasFetched, // A boolean indicating if the data has been fetched at least once.
 *    cancelFunction // A function to cancel the request. By default, the request will be canceled when the component unmounts.
 * ]
 */

type ActionCreator = (...args: unknown[]) => Promise<unknown>

export type ActionList = Array<ActionCreator | Dict | boolean | unknown>

export interface Options {
  onSuccess?: (res: unknown) => void
  onFail?: (res: unknown) => void
  onFinally?: (res?: unknown) => void
}

export const useFetcher = (
  action: ActionList = [],
  deps: DependencyList = [],
  options: Options = {},
): [boolean, unknown[] | undefined, boolean, Canceler] => {
  const initialResponse = Array.isArray(action[0]) ? [] : undefined
  const actions = Array.isArray(action[0]) ? action : [action]
  const [loading, setLoading] = useState(false)
  const [hasFetched, setHasFetched] = useState(false)
  const [response, setResponse] = useState(initialResponse)
  const canceler = useRef(axios.CancelToken.source())
  useEffect(() => {
    const promises: Array<Promise<unknown>> = []
    actions.forEach(
      ([actionCreator = () => Promise.resolve({}), args = {}, shouldFetch = true, cancellable = true]: [
        ActionCreator,
        Dict,
        boolean,
        boolean,
      ]) => {
        if (!shouldFetch) {
          return
        }
        // keep track of which keys in the args are undefined
        const undefinedArgs: string[] = []
        const validArgs = every(args, (val, key) => {
          if (val === undefined) undefinedArgs.push(key)
          return val !== undefined
        })
        if (validArgs) {
          if (loading !== true) {
            setLoading(true)
          }
          const cancelToken = cancellable ? canceler.current.token : undefined
          if (isArray(args)) {
            promises.push(actionCreator(...args, cancelToken))
          } else {
            promises.push(actionCreator({ ...args, cancelToken }))
          }
        } else {
          /** An action can be called with params with undefined values. The action is not ready to be called.
           * This is expected and we will return a null value (representing a no-op) to the consumer until the call is completed.
           **/
          promises.push(Promise.resolve(null))
        }
      },
    )
    if (!isEmpty(promises)) {
      const request: Promise<unknown> = promises.length > 1 ? Promise.all(promises) : promises[0]
      request
        .then((res: any) => {
          setResponse(res)
          return options.onSuccess?.(res)
        })
        .catch((error: Error) => options.onFail?.(error))
        .finally(() => {
          setLoading(false)
          setHasFetched(true)
          return options.onFinally?.()
        })
    }
  }, deps)

  useEffect(() => () => canceler.current.cancel(), [])

  return [loading, response, hasFetched, canceler.current.cancel]
}

export default useFetcher
