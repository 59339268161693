import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  blankAddressSearch,
  getExplainClinicalMatchConditions,
  getShouldDisplayBCTProviderBenefits as getShouldDisplayBCTProviderBenefitsUtil,
  isBlendedCareProvider,
  isImmutable,
  MEETING_FORMATS,
  ProviderInfo,
  ProviderTypeAbbreviated,
  TREATMENT_OPTIONS,
} from '@lyrahealth-inc/shared-app-logic'

import { getTriageIsSearchingForAdult } from './triage-demographics/triageDemographicsSelectors'
import { formatAddressForDisplay } from '../../../common/utils/providerUtils'
import { getLiveMessagingEnabled, getShouldDisplayBCTProviderBenefits } from '../../../data/customer/customerSelectors'
import { getIsUserInternational } from '../../../data/user/userSelectors'

export const getOnboardState = (state: Map<string, any>) => state?.get('onboard')

export const getOnboardData = createSelector(getOnboardState, (onboardState) => onboardState?.get('data'))

export const getOnboardMeetingFormat = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('meetingFormat'),
)

export const getOnboardIsMeetingFormatLiveMessaging = createSelector(
  getOnboardMeetingFormat,
  (onboardMeetingFormat) => onboardMeetingFormat === MEETING_FORMATS.LIVE_MESSAGING,
)

export const getOnboardIsMeetingFormatVideo = createSelector(
  getOnboardMeetingFormat,
  (onboardMeetingFormat) => onboardMeetingFormat === MEETING_FORMATS.VIDEO,
)

export const getOnboardIsMeetingFormatVideoAndLiveMessaging = createSelector(
  getOnboardMeetingFormat,
  (onboardMeetingFormat) => onboardMeetingFormat === MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING,
)

export const getOnboardSearchCount = createSelector(getOnboardData, (onboardData) => onboardData?.get('searchCount', 0))

// If the user has not yet entered a search location, use the browser's guessed time zone.
export const getOnboardTimezoneForAvailabilityRequest = createSelector(
  getOnboardData,
  (onboardData) =>
    onboardData?.get('timeZoneForAvailabilityRequest') ?? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
)

export const getOnboardLocation = createSelector(getOnboardState, (onboardState) => onboardState?.get('location'))

export const getLatestUserFavoriteProviders = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('latestUserProviders'),
)

export const getLatestMatchesSearch = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('latestMatchesSearch'),
)

export const getLatestMatchesSearchDate = createSelector(getLatestMatchesSearch, (latestMatchesSearch) => {
  const selfSearchTriageUpdateDate = latestMatchesSearch?.update_date ?? latestMatchesSearch?.create_date
  return selfSearchTriageUpdateDate
})

export const getLatestUserFavoriteProviderTreatmentType = createSelector(
  [getLatestUserFavoriteProviders],
  (latestUserProviders) => latestUserProviders[latestUserProviders.length - 1]?.treatmentPreference,
)

export const getIsLatestMatches = createSelector(getLatestMatchesSearch, (latestMatchesSearch) => !!latestMatchesSearch)

export const getLoadingLatestProviderMatches = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('loadingLatestProviderMatches'),
)

export const getOnboardLocationSelectedAddressOption = createSelector(getOnboardLocation, (onboardLocation) =>
  onboardLocation?.get('selectedAddressOption'),
)

export const getOnboardLocationSelectedDropdownValue = createSelector(getOnboardLocation, (onboardLocation) =>
  onboardLocation?.get('selectedDropdownValue'),
)

export const getOnboardIsLocationOnsite = createSelector(getOnboardLocation, (onboardLocation) =>
  Boolean(onboardLocation?.get('onsite')),
)

export const getOnboardLocationAddress = createSelector(
  getOnboardLocation,
  (onboardLocation) => onboardLocation?.get('address') ?? Map(),
)

export const getOnboardLocationGeographyData = createSelector(
  getOnboardLocation,
  (onboardLocation) => onboardLocation?.get('geo') ?? Map(blankAddressSearch),
)

export const getOnboardLocationLat = createSelector(getOnboardLocationGeographyData, (geoData) =>
  geoData?.get('lat', ''),
)

export const getOnboardLocationLon = createSelector(getOnboardLocationGeographyData, (geoData) =>
  geoData?.get('lon', ''),
)

export const getOnboardLocationUserInput = createSelector(getOnboardLocationGeographyData, (geoData) =>
  geoData?.get('userInput'),
)

export const getOnboardLocationCountry = createSelector(
  getOnboardLocationGeographyData,
  (geoData) => geoData?.get('country') ?? '',
)

export const getOnboardLocationState = createSelector(
  getOnboardLocationGeographyData,
  (geoData) => geoData?.get('state') ?? '',
)

export const getOnboardFormattedAddress = createSelector(getOnboardLocationGeographyData, (geoData) =>
  geoData?.get('formattedAddress'),
)

export const getOnboardOnsiteName = createSelector(getOnboardLocation, (onboardLocation) =>
  onboardLocation?.get('onsiteName'),
)

export const getOnboardIsLocationNear = createSelector(getOnboardLocation, (onboardLocation) =>
  Boolean(onboardLocation?.get('near')),
)

export const getOnboardProviderProfile = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('providerProfile'),
)

export const getOnboardProviderProfileData = createSelector(
  getOnboardProviderProfile,
  (providerProfile) => providerProfile?.get('data') ?? Map(),
)

export const getOnboardProviderProfileDisplayName = createSelector(
  getOnboardProviderProfileData,
  (providerProfileData) => providerProfileData?.get('display_name', ''),
)

export const getIsOnboardProviderOnsite = createSelector(
  getOnboardProviderProfileData,
  (providerProfileData) => providerProfileData?.get('onsite', false) ?? false,
)

export const getOnboardProviderSupportedVisitTypes = createSelector(
  getOnboardProviderProfileData,
  (providerProfileData) => providerProfileData?.get('supportedVisitTypes'),
)

export const getOnboardProviderNearestAddress = createSelector(
  getOnboardProviderProfileData,
  (providerProfileData) => providerProfileData?.get('nearestAddress', Map()) ?? Map(),
)

export const getIndividualTherapyState = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('individualTherapy'),
)

export const getShouldShowIndividualTherapyModal = createSelector(getIndividualTherapyState, (individualTherapy) =>
  Boolean(individualTherapy?.get('showModal')),
)

export const getOnboardProviderProfileLanguages = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('languages_bcp47') ?? List(),
)

export const getOnboardProviderAdditionalSummaries = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('additional_provider_summaries'),
)

export const getOnboardProviderSummary = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.get('summary', '').trim(),
)

export const getOnboardProviderCapacity = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('providerCapacity') ?? Map(),
)

export const getOnboardProviderFirstName = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.get('first_name'),
)

export const getOnboardProviderDisplayName = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('display_name'),
)

export const getOnboardProviderModality = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('modalities') ?? List(),
)

export const getOnboardProviderLyraId = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.get('lyra_id'),
)

export const getOnboardProviderImageUrl = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.getIn(['lyraProviderImage', 'imageUrl'], ''),
)

export const getOnboardProviderTaxonomyData = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('program_taxonomy'),
)

export const getOnboardProviderType = createSelector([getOnboardProviderTaxonomyData], (programTaxonomyImmutable) => {
  const programTaxonomy = programTaxonomyImmutable?.toJS()
  return programTaxonomy
    ? isBlendedCareProvider({ programTaxonomy })
      ? ProviderTypeAbbreviated.BC
      : ProviderTypeAbbreviated.DA
    : undefined
})

export const getShouldDisplayBCTProviderBenefitsForProvider = createSelector(
  getOnboardProviderProfileData,
  (state: any) => getShouldDisplayBCTProviderBenefits(state),
  (provider: any, shouldDisplayBCTProviderBenefits: any) => {
    return getShouldDisplayBCTProviderBenefitsUtil({
      isCustomerDisplayingBCTBenefits: shouldDisplayBCTProviderBenefits,
      provider: isImmutable({ value: provider }) ? (provider as Map<string, any>).toJS() : provider,
    })
  },
)

export const getOnboardProviderEmail = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.get('email'),
)

export const getOnboardProviderSessionFormat = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('sessionFormat'),
)

export const getOnboardProviderSupportsLiveMessaging = createSelector(
  (state: Map<string, any>) => getLiveMessagingEnabled(state),
  getOnboardProviderSessionFormat,
  (liveMessagingEnabled, providerSessionFormat) =>
    liveMessagingEnabled && providerSessionFormat?.get('isLiveMessaging', false),
)

export const getOnboardProviderCostInfo = createSelector(getOnboardProviderProfileData, (onboardProviderProfileData) =>
  onboardProviderProfileData?.get('costInfo'),
)

export const getOnboardProviderProfileVideoData = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('profileVideo'),
)

export const getOnboardProviderProfileVideoLink = createSelector(
  getOnboardProviderProfileVideoData,
  (profileVideoData) => profileVideoData?.get('videoLink'),
)

export const getOnboardProviderExplainClinicalMatch = createSelector(
  getOnboardProviderProfileData,
  (providerProfileData) => {
    return providerProfileData?.get('explainClinicalMatch') ?? List()
  },
)

export const getOnboardProviderShownClinicalExperience = createSelector(
  getOnboardProviderExplainClinicalMatch,
  (providerExplainClinicalMatch) => {
    return getExplainClinicalMatchConditions(providerExplainClinicalMatch)
  },
)

export const getOnboardProviderTotalSessionCost = createSelector(getOnboardProviderCostInfo, (costInfo) =>
  costInfo?.get('total_session_cost'),
)

export const getOnboardTreatmentOptions = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('treatmentOptions'),
)

export const getOnboardSelectedTreatmentOption = createSelector(
  getOnboardTreatmentOptions,
  (onboardTreatmentOptions: $TSFixMe) => onboardTreatmentOptions?.get('selectedTreatmentOption'),
)

export const getOnboardFallbackTreatmentOption = createSelector(getOnboardTreatmentOptions, (onboardTreatmentOptions) =>
  onboardTreatmentOptions?.get('fallbackTreatmentOption'),
)

export const getOnboardSelectedTreatmentOptionCTPO = createSelector(
  getOnboardTreatmentOptions,
  (onboardTreatmentOptions) => onboardTreatmentOptions?.get('selectedTreatmentOptionCTPO'),
)

export const getOnboardIsSelectedTreatmentOptionAUD = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER,
)

export const getOnboardIsSelectedTreatmentOptionCoaching = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.COACHING,
)

export const getOnboardIsSelectedTreatmentOptionIndividualTherapy = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
)

export const getOnboardIsSelectedTreatmentOptionCouplesTherapy = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.COUPLES_THERAPY,
)

export const getOnboardIsSelectedTreatmentOptionFamilyTherapy = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.FAMILY_THERAPY,
)

export const getOnboardIsSelectedTreatmentOptionBlendedCareMeds = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
)

// This treatment option comes from the MedsOptionsExplained page
export const getOnboardIsSelectedTreatmentOptionMedicationConsultation = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.MEDICATION_CONSULTATION,
)

export const getOnboardIsSelectedTreatmentOptionDirectAccessMeds = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) =>
    treatmentOption === TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS ||
    treatmentOption === TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD,
)

// This treatment option comes from the MedsOptionsExplained page
export const getOnboardIsSelectedTreatmentOptionControlledMedication = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) => treatmentOption === TREATMENT_OPTIONS.CONTROLLED_MEDICATION,
)

export const getIsSearchingForTherapy = createSelector(
  getOnboardIsSelectedTreatmentOptionIndividualTherapy,
  getOnboardIsSelectedTreatmentOptionCouplesTherapy,
  getOnboardIsSelectedTreatmentOptionFamilyTherapy,
  (seekingIndividualTherapy, seekingCouplesTherapy, seekingFamilyTherapy) => {
    return seekingIndividualTherapy || seekingCouplesTherapy || seekingFamilyTherapy
  },
)

export const getIsSearchingForAdultTherapy = createSelector(
  getIsSearchingForTherapy,
  getTriageIsSearchingForAdult,
  (isSearchingForTherapy, isSearchingForAdult) => {
    return isSearchingForAdult && isSearchingForTherapy
  },
)

export const getIsSearchingForChildTherapy = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) =>
    treatmentOption === TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD ||
    treatmentOption === TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD,
)

export const getIsSearchingForAdultMeds = createSelector(
  getOnboardSelectedTreatmentOption,
  (treatmentOption) =>
    treatmentOption === TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS ||
    treatmentOption === TREATMENT_OPTIONS.BLENDED_CARE_MEDS ||
    treatmentOption === TREATMENT_OPTIONS.CONTROLLED_MEDICATION ||
    treatmentOption === TREATMENT_OPTIONS.MEDICATION_CONSULTATION,
)

export const getShouldUseRelevantClinicalSpecializationsHeader = createSelector(
  getIsSearchingForTherapy,
  getIsSearchingForChildTherapy,
  getOnboardIsSelectedTreatmentOptionCoaching,
  (isSearchingForTherapy, isSearchingForChildTherapy, isSelectedTreatmentOptionCoaching) => {
    return isSearchingForTherapy || isSearchingForChildTherapy || isSelectedTreatmentOptionCoaching
  },
)

export const getOnboardAppointmentBooking = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('appointmentBooking'),
)

export const getOnboardAppointment = createSelector(
  getOnboardAppointmentBooking,
  (appointmentBooking) => appointmentBooking?.get('appointment') ?? Map(),
)

export const getOnboardAppointmentDate = createSelector(getOnboardAppointment, (appointment) =>
  appointment?.get('date'),
)

export const getOnboardAppointmentTime = createSelector(getOnboardAppointment, (appointment) =>
  appointment?.get('time'),
)

export const getOnboardShouldShowAppointmentModal = createSelector(getOnboardAppointmentBooking, (appointmentBooking) =>
  Boolean(appointmentBooking?.get('showModal')),
)

export const getOnboardModalityQuestionnaire = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('modalityQuestionnaire'),
)

export const getOnboardModalityQuestionnaireRecommendations = createSelector(
  getOnboardModalityQuestionnaire,
  (modalityQuestionnaire) => modalityQuestionnaire?.get('recommendations') ?? List(),
)

export const getOnboardShouldShowSurveyRecommendations = createSelector(
  getOnboardModalityQuestionnaire,
  (modalityQuestionnaire) => Boolean(modalityQuestionnaire?.get('showSurveyRecommendations')),
)

export const getOnboardModalityQuestionnaireAnswers = createSelector(
  getOnboardModalityQuestionnaire,
  (modalityQuestionnaire) => modalityQuestionnaire?.get('answers') ?? List(),
)

export const getOnboardModalityQuestionnaireSurveyData = createSelector(
  getOnboardModalityQuestionnaire,
  (modalityQuestionnaire) => modalityQuestionnaire?.get('surveyData'),
)

export const getOnboardModalityQuestionGroups = createSelector(
  getOnboardModalityQuestionnaireSurveyData,
  (surveyData) => surveyData?.get('modalityQuestionGroups'),
)

export const createGetOnboardModalityQuestionGroupsWithIndex = ({ index }: { index: number }) => {
  return createSelector(getOnboardModalityQuestionGroups, (questionGroups) => questionGroups?.get(index) ?? Map())
}

export const getOnboardProviders = createSelector(getOnboardState, (onboardState) => onboardState?.get('providers'))

export const getOnboardProvidersDisplayed = createSelector(getOnboardProviders, (providers) => {
  const onboardProviders = providers?.get('providersDisplayed') ?? List()
  let providerList: ProviderInfo[] = []

  onboardProviders.forEach(
    (onboardProviderObj: { bucketName: string; providerList: ProviderInfo[] } | ProviderInfo) => {
      if ('bucketName' in onboardProviderObj) {
        providerList = providerList.concat(
          (onboardProviderObj as { bucketName: string; providerList: ProviderInfo[] }).providerList,
        )
      } else {
        providerList = providerList.concat(onboardProviderObj)
      }
    },
  )
  return providerList
})

export const getOnboardTotalNumberOfProviders = createSelector(getOnboardProviders, (providers) => {
  const total = providers?.get('totalNumberOfProviders')
  return parseInt(total ?? 0, 10)
})

export const getOnboardUserFavoriteProviderRequestId = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('userFavoriteProviderRequestId'),
)

export const getIsSelectedProviderTopProvider = createSelector(
  getOnboardProviderLyraId,
  getOnboardProvidersDisplayed,
  (selectedProviderLyraId, displayedProviders) => {
    if (!displayedProviders || displayedProviders?.length < 2) {
      return false
    }
    return displayedProviders.findIndex((provider) => provider.lyra_id === selectedProviderLyraId) === 0
  },
)

export const getOnboardTriageHealthPlan = createSelector(getOnboardState, (onboard) => onboard?.get('triageHealthPlan'))

export const getOnboardSelectedHealthPlan = createSelector(getOnboardTriageHealthPlan, (triageHealthPlan) =>
  triageHealthPlan?.get('selectedHealthPlan'),
)

export const getOnboardSelectedHealthPlanName = createSelector(getOnboardSelectedHealthPlan, (healthPlan) =>
  healthPlan?.get('planName'),
)

export const getOnboardAddressData = createSelector(
  getOnboardLocationAddress,
  getIsOnboardProviderOnsite,
  getOnboardProviderNearestAddress,
  (onsiteAddress, isProviderOnsite, providerNearestAddress) => {
    return isProviderOnsite && onsiteAddress.size > 0 ? onsiteAddress : providerNearestAddress
  },
)

export const getOnboardAddressForTracking = createSelector(getOnboardAddressData, (address) => {
  return formatAddressForDisplay(address)
})

export const getOnboardLocationAddressDisplay = createSelector(getOnboardAddressForTracking, (displayLocation) => {
  let address = displayLocation.addressLine1
  if (displayLocation.addressLine2) {
    address = address + ', ' + displayLocation.addressLine2
  }
  return address
})

export const getOnboardShouldDisplayCarePreferencesPage = createSelector(
  (state: $TSFixMe) => getIsUserInternational(state),
  getIsSearchingForAdultTherapy,
  getOnboardIsSelectedTreatmentOptionIndividualTherapy,
  (isUserInternational: $TSFixMe, isSearchingForAdultTherapy: $TSFixMe, searchingForIndividualTherapy: $TSFixMe) => {
    if (isUserInternational) {
      return searchingForIndividualTherapy
    }

    return isSearchingForAdultTherapy
  },
)

export const getOnboardIsValidMemberPreferencesTreatmentOption = createSelector(
  getIsSearchingForAdultTherapy,
  getOnboardIsSelectedTreatmentOptionCoaching,
  getIsSearchingForChildTherapy,
  (isSearchingForAdultTherapy: boolean, isSearchingForCoaching: boolean, isSearchingForChildTherapy: boolean) => {
    return isSearchingForAdultTherapy || isSearchingForCoaching || isSearchingForChildTherapy
  },
)

export const getOnboardCarePreferences = createSelector(getOnboardState, (onboardState) =>
  onboardState?.get('carePreferences'),
)

export const getOnboardAvailableCareLanguages = createSelector(
  getOnboardCarePreferences,
  (carePreferences) => carePreferences?.get('availableCareLanguages') ?? [],
)

export const getOnboardSelectedCareLanguage = createSelector(getOnboardCarePreferences, (carePreferences) =>
  carePreferences?.get('selectedCareLanguage'),
)

export const getOnboardProviderProfileAvailabilityCS = createSelector(
  getOnboardProviderProfileData,
  (onboardProviderProfileData) => onboardProviderProfileData?.get('availabilityCS'),
)

export const getOnboardProviderProfileAvailabilityCSFirstAvailabilityDateObject = createSelector(
  getOnboardProviderProfileAvailabilityCS,
  (onboardProviderProfileAvailabilityCS) => {
    const firstDate = onboardProviderProfileAvailabilityCS
      ?.get('provider_availability')
      ?.get(0)
      ?.get('availability')
      ?.get(0)
      ?.get('start')
    if (!firstDate) {
      return undefined
    }

    return new Date(firstDate)
  },
)

export const getUserMemberPreferencesV2 = createSelector(getOnboardCarePreferences, (carePreferences) =>
  carePreferences?.get('userMemberPreferencesV2'),
)

export const getIsParentInitiatedInPersonS4CPreferenceChecked = createSelector(
  getOnboardCarePreferences,
  (carePreferences) => carePreferences?.get('isParentInitiatedInPersonS4CPreferenceChecked'),
)

export const getIsParentInitiatedInPersonTeenS4CPreferenceChecked = createSelector(
  getOnboardCarePreferences,
  (carePreferences) => carePreferences?.get('isParentInitiatedInPersonTeenS4CPreferenceChecked'),
)

export const getOnboardIsCurrentSearchDirectPath = createSelector(getOnboardData, (onboardData: $TSFixMe) =>
  onboardData?.get('isCurrentSearchDirectPath'),
)

export const getOnboardDirectLinkIntent = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('directLinkIntent'),
)

export const getOnboardDirectLinkSource = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('directLinkSource'),
)

export const getOnboardToolkitPromotion = createSelector(getOnboardData, (onboardData) =>
  onboardData?.get('toolkitPromotion'),
)
