import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { isNil } from 'lodash-es'

import {
  COMMON_MIXPANEL_PAGE,
  createPageViewEventName,
  LD_CUSTOM_EVENTS,
  PAGES,
} from '@lyrahealth-inc/shared-app-logic'
import { useLDClient } from '@lyrahealth-inc/shared-app-logic/src/features/flags/launchdarkly-client-sdk.web'

import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'

export function useTrackViewPage(
  page: PAGES,
  properties?: { [key: string]: any },
  options?: {
    entryPoint?: {
      entryPoint?: COMMON_MIXPANEL_PAGE
    } | null
    skipAppendPageToEventName?: boolean
    requiredProperties?: string[]
    customEvent?: string
    launchDarklyTrackEvent?: {
      customEventName: LD_CUSTOM_EVENTS
      data?: any
      metricValue?: number
      requiredLDProperties?: string[]
    }
  },
) {
  const { entryPoint, skipAppendPageToEventName, requiredProperties, launchDarklyTrackEvent } = options || {}
  const dispatch = useAppDispatch()
  const [pageTracked, setPageTracked] = useState('')
  const [ldEventTracked, setLDEventTracked] = useState(false)
  const { search } = useLocation()

  const ldClient = useLDClient()
  const waitForRequiredFields =
    !!properties && requiredProperties?.length && !requiredProperties.every((key) => key in properties)

  const waitForLDRequiredProperties =
    !!launchDarklyTrackEvent?.data &&
    launchDarklyTrackEvent?.requiredLDProperties &&
    !launchDarklyTrackEvent.requiredLDProperties.every(
      (key) => key in launchDarklyTrackEvent.data && !isNil(launchDarklyTrackEvent.data[key]),
    )

  useEffect(() => {
    if (!launchDarklyTrackEvent || ldEventTracked || waitForLDRequiredProperties) return
    ldClient?.track(
      launchDarklyTrackEvent.customEventName,
      launchDarklyTrackEvent.data,
      launchDarklyTrackEvent.metricValue,
    )
    setLDEventTracked(true)
  }, [ldClient, launchDarklyTrackEvent, ldEventTracked, waitForLDRequiredProperties])

  useEffect(() => {
    if (entryPoint === null) {
      // Block until entry point is ready
      return
    }
    const track = () => {
      dispatch(
        trackEventWithObj({
          event: options?.customEvent ?? createPageViewEventName(page, skipAppendPageToEventName),
          ...entryPoint,
          ...properties,
        }),
      )
    }
    if (page !== pageTracked && !search.includes('utm_medium=tbd') && !waitForRequiredFields) {
      setPageTracked(page)
      track()
    }
  }, [
    dispatch,
    entryPoint,
    options?.customEvent,
    page,
    pageTracked,
    properties,
    search,
    skipAppendPageToEventName,
    waitForRequiredFields,
  ])
}
