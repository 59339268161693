import React, { FunctionComponent } from 'react'

import { EmptyActivityProps } from './EmptyActivitySvg'
import { withSuspense } from '../../utils/withSuspense'

const EmptyActivitySvg = withSuspense({
  component: React.lazy(() => import('./EmptyActivitySvg')),
})

export const EmptyActivity: FunctionComponent<EmptyActivityProps> = () => {
  return <EmptyActivitySvg />
}
