import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { LayoutChangeEvent, NativeSyntheticEvent, Platform, TextLayoutEventData, TextStyle } from 'react-native'
import TruncateMarkup from 'react-truncate-markup'

import ReadMore from '@fawazahmed/react-native-read-more'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB } from '../../constants'
import { BodyTextSize, TextAlign, TextSize, TextType } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { BaseText } from '../baseText/BaseText'

/**
 * This component renders only the renders the specified amount of lines defined by maxNumberOfLines prop.
 * Also allows toggling if the toggleCollapseExpand prop is set to true.
 */

const CollapsedTextContainer = styled(BaseText)<{
  fontColor?: string
  toggleCollapseExpand: boolean
  numberOfLines: number
  $noMargin?: boolean
  customBottomMargin?: string
}>(({ fontColor, toggleCollapseExpand, $noMargin, customBottomMargin }) => ({
  flexShrink: 1,
  flexWrap: 'wrap',
  marginRight: 14,
  color: fontColor,
  ...(!$noMargin && { marginBottom: toggleCollapseExpand ? 6 : 14 }),
  ...(customBottomMargin && { marginBottom: customBottomMargin }),
}))

const ExpandedTextContainer = styled(BaseText)<{
  fontColor?: string
  toggleCollapseExpand: boolean
  $noMargin?: boolean
  customBottomMargin?: string
}>(({ fontColor, toggleCollapseExpand, $noMargin, customBottomMargin }) => ({
  flexShrink: 1,
  flexWrap: 'wrap',
  marginRight: 14,
  color: fontColor,
  ...(!$noMargin && { marginBottom: toggleCollapseExpand ? 6 : 14 }),
  ...(customBottomMargin && { marginBottom: customBottomMargin }),
}))

const ToggleText = styled(BodyText)<{ theme: ThemeType; fontWeight: string; color?: string }>(
  ({ theme, fontWeight, color }) => ({
    fontWeight: fontWeight,
    color: Boolean(color) ? color : theme.colors.textActive,
  }),
)

const TextContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`
const ViewContainer = styled.View`
  ${IS_WEB && `width: 100%`}
`

type ReactElementWithTextProp = { props: { text: string; [key: string]: any } }

function extractTextFromElement(element: JSX.Element) {
  const textElements: ReactElementWithTextProp[] = element.props?.children ?? []
  return textElements.reduce((acc, currentTextElement) => acc + currentTextElement.props.text, '')
}

export const TruncatedText = forwardRef<TruncatedTextHandle, TruncatedTextProps>(
  (
    {
      text,
      fontColor,
      maxNumberOfLines = 2,
      toggleCollapseExpand = false,
      collapseExpandText = ['COLLAPSE', 'SEE ALL'],
      collapseExpandColor,
      collapseExpandFontWeight = 'bold',
      collapseExpandTextSize = BodyTextSize.DEFAULT,
      underline = false,
      isTruncatedCallback = noop,
      testIDPrefix,
      noMargin,
      textType = TextType.BODY,
      textSize = BodyTextSize.DEFAULT,
      textAlign = 'left',
      textDecorator,
      textOverride = '', // If you are truncating complex HTML, use this as the pure text to calculate truncation
      hasInlineEllipsisText = false,
      inlineTextStyle,
      customEllipsisStyle,
      customBottomMargin,
    },
    ref,
  ) => {
    const [canBeTruncated, setCanBeTruncated] = useState(false)
    const [isExpanded, setExpanded] = useState(false)
    const { colors } = useTheme()
    const normalizedText =
      textOverride.length > 0 ? textOverride : typeof text === 'string' ? text : extractTextFromElement(text)
    const collapsedContainerId = tID(`${testIDPrefix}-collapsed`)

    useImperativeHandle(ref, () => ({
      open: () => canBeTruncated && setExpanded(true),
      close: () => setExpanded(false),
    }))

    useEffect(() => {
      isTruncatedCallback(canBeTruncated)
    }, [canBeTruncated, isTruncatedCallback])

    const isTruncated = useCallback(
      (lines: TextLayoutEventData['lines']) => {
        // IOS handles truncation differently then Android does.
        // Android event.nativeEvent.lines.length is max number of lines that can be made with given text. But will only shows the n number of lines specified on numberOfLines prop of text.
        // IOS event.nativeEvent.lines.length is n number of lines specified on numberOfLines prop of text. Last lines i.e. line n is a concatenation of remaining text although not all may shown.
        // Example (assuming max 6 chars per line): text = 'alpha beta charlie' numberOfLines = 1
        // IOS-> event.nativeEvent.lines = [{text:'alpha beta charlie'}]    * only alpha shown
        // Android-> event.nativeEvent.lines = [{text:'alpha'}, {text:'beta'}, {text:'charlie'}}]   * only alpha shown
        if (Platform.OS !== 'ios') {
          return lines.length >= maxNumberOfLines
        }

        let avgNumberOfCharactersPerPixel = 0
        const numberOfLines = maxNumberOfLines > lines.length ? lines.length : maxNumberOfLines
        const lastLineNumberOfCharacters = lines[numberOfLines - 1].text.length
        const lastLineWidth = lines[numberOfLines - 1].width
        let maxLineWidth = 0

        for (let idx = 0; idx < numberOfLines - 1; ++idx) {
          const lineWidth = lines[idx].width
          if (lineWidth > 0) {
            const numberOfCharctersPerPixel = lines[idx].text.length / lineWidth
            avgNumberOfCharactersPerPixel += numberOfCharctersPerPixel
            maxLineWidth = lineWidth > maxLineWidth ? lineWidth : maxLineWidth
          }
        }

        avgNumberOfCharactersPerPixel /= numberOfLines - 1

        // This is used to handle case(s) where the passed in text fills the same number of lines as maxNumberOfLines.
        // We calculate the max width of the last line using avgNumberOfCharactersPerPixel to represent the possible width
        // of the last line (in case it is truncated). This is done because if the last line is truncated due to it being slightly over, the
        // width of the last line (line.width) can potentially be less than the maxLineWidth so we must calculate its non-truncated width.
        // If the potential width of the last line is less than the largest line width, then we can safelly assume that text
        // is not truncated and should return false.
        const lastLineMaxWidth = lines[numberOfLines - 1].text.length / avgNumberOfCharactersPerPixel

        // do not return false if text is multiple paragraphs which we detect by checking if text contains new line.
        const textContainsLineBreak = /\r|\n/.exec(normalizedText)
        if (!textContainsLineBreak && lastLineMaxWidth < maxLineWidth) return false

        const numberOfCharacters = avgNumberOfCharactersPerPixel * lastLineWidth
        return lastLineNumberOfCharacters > numberOfCharacters
      },
      [maxNumberOfLines, normalizedText],
    )

    const isTruncatedWeb = useCallback(
      (width: number) => {
        // onTextLayout does not trigger/work on web (i.e. no access to event.NativeEvent.lines), thus
        // alternative method for truncation check must be used. Follows same method as Truncate.js in ui-core.
        const avgTextPer100px = 15.1
        const textScale = Math.round((width / 100) * avgTextPer100px * maxNumberOfLines)
        return normalizedText.length > textScale
      },
      [maxNumberOfLines, normalizedText.length],
    )

    const onTextLayout = useCallback(
      (e: NativeSyntheticEvent<TextLayoutEventData>) => {
        setCanBeTruncated(e.nativeEvent.lines.length >= maxNumberOfLines && isTruncated(e.nativeEvent.lines))
      },
      [isTruncated, maxNumberOfLines],
    )

    const toggleText = useCallback(() => {
      setExpanded(!isExpanded)
    }, [isExpanded])

    const onLayout = useCallback(
      (e: LayoutChangeEvent) => {
        if (Platform.OS === 'web') {
          const { width } = e.nativeEvent.layout
          // isTruncatedWeb relies on a constant 'avgTextPer100px' which is not always correct (because of font size), we can also calculate if the text is truncated with the scrollHeight
          if (collapsedContainerId) {
            const ele = document.getElementById(collapsedContainerId)
            const eleIsTruncated = ele ? ele?.scrollHeight > ele?.clientHeight : false
            const isTruncated = isTruncatedWeb(width) || eleIsTruncated
            setCanBeTruncated(isTruncated)
          } else {
            setCanBeTruncated(isTruncatedWeb(width))
          }
        }
      },
      [isTruncatedWeb, collapsedContainerId],
    )
    return (
      <ViewContainer onLayout={onLayout}>
        <TextContainer>
          {!hasInlineEllipsisText &&
            (isExpanded ? (
              <ExpandedTextContainer
                type={textType}
                size={textSize}
                fontColor={fontColor || colors.textPrimary}
                toggleCollapseExpand={toggleCollapseExpand}
                onTextLayout={onTextLayout}
                testID={tID(`${testIDPrefix}-expanded`)}
                $noMargin={noMargin}
                textAlign={textAlign}
                customBottomMargin={customBottomMargin}
              >
                {text}
              </ExpandedTextContainer>
            ) : (
              <CollapsedTextContainer
                textAlign={textAlign}
                type={textType}
                size={textSize}
                fontColor={fontColor || colors.textPrimary}
                toggleCollapseExpand={toggleCollapseExpand}
                numberOfLines={maxNumberOfLines}
                onTextLayout={onTextLayout}
                testID={collapsedContainerId}
                id={collapsedContainerId}
                $noMargin={noMargin}
                customBottomMargin={customBottomMargin}
              >
                {text}
              </CollapsedTextContainer>
            ))}
          {hasInlineEllipsisText &&
            (IS_WEB ? (
              <BaseText
                textAlign={textAlign}
                type={textType}
                size={textSize}
                color={fontColor || colors.textPrimary}
                testID={tID(`${testIDPrefix}-inline`)}
              >
                <TruncateMarkup
                  lines={maxNumberOfLines}
                  ellipsis={
                    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                    <BodyText text={'... '}>
                      <BodyText text={collapseExpandText[1]} style={customEllipsisStyle} />
                    </BodyText>
                  }
                >
                  {/* This library requires a DOM element here */}
                  <span>{text}</span>
                </TruncateMarkup>
              </BaseText>
            ) : (
              <ReadMore
                numberOfLines={maxNumberOfLines}
                seeMoreText={collapseExpandText[1] + ''}
                seeMoreStyle={{
                  ...customEllipsisStyle,
                  color: fontColor || colors.textPrimary,
                  zIndex: 0, // Position the ellipsis text behind the main text, to make this unclickable (pointerEvents: none didn't work for android)
                }}
                // Cannot wrap or put <BodyText> or <BaseText> here since it doesn't work properly with this library @fawazahmed/react-native-read-more
                style={{ ...inlineTextStyle, zIndex: 1 }}
                collapsed={true}
                testID={tID(`${testIDPrefix}-inline`)}
              >
                {text}
              </ReadMore>
            ))}
          {textDecorator}
        </TextContainer>
        {toggleCollapseExpand && (canBeTruncated || isExpanded) && (
          <PressableOpacity testID={tID('text-toggle')} onPress={toggleText}>
            <ToggleText
              underline={underline}
              text={isExpanded ? collapseExpandText[0] : collapseExpandText[1]}
              size={collapseExpandTextSize}
              color={collapseExpandColor}
              fontWeight={collapseExpandFontWeight}
            />
          </PressableOpacity>
        )}
      </ViewContainer>
    )
  },
)

export interface TruncatedTextHandle {
  open: () => void
  close: () => void
}

type TruncatedTextProps = {
  text: string | JSX.Element
  fontColor?: string
  maxNumberOfLines?: number
  toggleCollapseExpand?: boolean
  collapseExpandColor?: string
  collapseExpandText?: [React.ReactNode, React.ReactNode]
  collapseExpandFontWeight?: string
  collapseExpandTextSize?: BodyTextSize
  isTruncatedCallback?: (canBeTruncated: boolean) => void
  testIDPrefix?: string
  noMargin?: boolean
  textType?: TextType
  textSize?: TextSize
  textAlign?: TextAlign
  underline?: boolean
  textDecorator?: JSX.Element
  textOverride?: string
  hasInlineEllipsisText?: boolean
  inlineTextStyle?: TextStyle
  customEllipsisStyle?: TextStyle
  customBottomMargin?: string
}
