import React, { FunctionComponent } from 'react'
import { ImageStyle, StyleProp } from 'react-native'

import styled from 'styled-components/native'

import { tID } from '../../utils/utils'

// TODO: Expo 51, move to Image component in ui-cc, gif is broken in Expo 50 for certain sizes
// eslint-disable-next-line no-restricted-properties
const ImageContainer = styled.Image<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const RegistrationEmailCaptureAnimation: FunctionComponent<RegistrationEmailCaptureAnimationProps> = ({
  width = 500,
  height = 418,
  style,
}) => {
  return (
    <ImageContainer
      dataSet={{ chromatic: 'ignore' }}
      source={require('../../assets/registrationEmailCaptureAnimatedIllustration.gif')}
      width={width}
      height={height}
      resizeMode='contain'
      style={style}
      testID={tID('RegistrationEmailCaptureAnimation')}
    />
  )
}

type RegistrationEmailCaptureAnimationProps = {
  width?: number
  height?: number
  style?: StyleProp<ImageStyle>
}
