import { useCallback, useEffect, useState } from 'react'

function dispatchStorageEvent(key: string, newValue: string | null) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setLocalStorageItem = <T>(key: string, value: T) => {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
}

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key)
  dispatchStorageEvent(key, null)
}

const getLocalStorageItem = (key: string) => {
  const value = window.localStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  }
  return null
}

const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(getLocalStorageItem(key) ?? initialValue)
  const setState = useCallback(
    (value: T | null) => {
      try {
        if (value == null) {
          removeLocalStorageItem(key)
        } else {
          setLocalStorageItem(key, value)
        }
      } catch (e) {
        console.warn(e)
      }
    },
    [key],
  )

  useEffect(() => {
    const callback = (event: StorageEvent) => {
      if (event.key === key) {
        setStoredValue(event.newValue ? JSON.parse(event.newValue) : initialValue)
      }
    }
    window.addEventListener('storage', callback)
    return () => {
      window.removeEventListener('storage', callback)
    }
  }, [initialValue, key, setState])

  useEffect(() => {
    if (getLocalStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setLocalStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  useEffect(() => {
    const value = getLocalStorageItem(key)
    if (value !== null) {
      setStoredValue(value)
    }
  }, [key])

  return [storedValue, setState] as const
}

export default useLocalStorage
