import React from 'react'
import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

const zoetisMedicalPlan =
  'If you are enrolled in a Zoetis medical plan, please contact United Healthcare at 1-888-470-8079, TTY 711 or Kaiser at 1-800-464-4000, TTY 711 for more information regarding these services.'

export const zoetis: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    servicesNotCovered: `Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care. ${zoetisMedicalPlan}`,
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  }
}
