import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CARE_OPTION,
  ExtendedPathwayOption,
  OnCareOptionModalOpenCloseProps,
  PathwayOptions,
  SessionLimitByProgram,
  TEEN_ENABLED_PATHWAYS,
} from '@lyrahealth-inc/shared-app-logic'

import { ChevronIcon, ChevronIconDirection } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import * as Illustrations from '../../atoms/illustrations/index'
import { ForkedTriageMainCareOptionIllustration } from '../../atoms/illustrations/index'
import {
  ForkedTriageCareOptionCard,
  ForkedTriageCareOptionsMetadata,
  ForkedTriageCareOptionsMetadataV2,
  ForkedTriageCareOptionsOUSMetadata,
} from '../../careOptionsMetadata/forkedTriageMetadata'
import { useGetCareOptionsModalContent } from '../../hooks/useGetCareOptionsModalContent'
import { MedsConsultModal } from '../../molecules'
import { LatestMatchesLinkProps } from '../../molecules/latestMatchesLink/LatestMatchesLink'
import { CareOptions } from '../../organisms'
import { CareOptionsProps } from '../../organisms/careOptions/CareOptions'
import { SubheadSize } from '../../styles'
import { CareOptionCardProps } from '../../templates/careOptionCard/CareOptionCard'
import { ThemeType } from '../../utils'

export interface ForkedTriagePageProps extends Omit<CareOptionsProps, 'careOptionsList'> {
  /** If showNewForkedTriage is enabled, and showSelfSearch is false, child search becomes the main option */
  showSelfSearch: boolean
  showChildSearch: boolean
  showCoachingToolkit: boolean
  showParentingToolkit: boolean
  showMedsConsult: boolean
  showCouplesTherapy: boolean
  showFamilyTherapy: boolean
  isInternational: boolean
  isDirectAccessMedsSupported: boolean
  userAge?: number
  // used to open modal of selected pathway option when navigating from microsite to logged-in forked triage
  selectedPathwayOption?: ExtendedPathwayOption
  isUserLoggedIn: boolean
  onSelfSearchPress: () => void
  onChildSearchPress?: () => void
  onCoachingToolkitPress?: () => void
  onParentingToolkitPress?: () => void
  onMedConsultPress?: () => void
  onCouplesTherapyPress?: () => void
  onFamilyTherapyPress?: () => void
  onCareOptionModalOpen?: (props: OnCareOptionModalOpenCloseProps) => void
  onCareOptionModalClose?: (props: OnCareOptionModalOpenCloseProps) => void
  onLearnMorePress?: () => void
  // used to reset selected pathway option so that modal is only opened on initial page load
  resetSelectedPathwayOption?: () => void
  trackAvailablePrograms?: (programs: string[]) => void
  latestMatchesLinkProps?: LatestMatchesLinkProps
  isUserTeen?: boolean
  showNewForkedTriage?: boolean
  loading: boolean
  // relevant to meds consult modal where a link to open care navigator modal exists
  hideCurrentOpenModal?: boolean
  customerName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}

const ExploreMoreOptionsTitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: theme.breakpoints.isMobileSized ? 'flex-start' : 'center',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
  width: '100%',
}))

const ChevronIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['12px'],
}))

const TriageOptionCustomHeaderContainer = styled.View({
  flexDirection: 'row',
})

export const ForkedTriagePage: FunctionComponent<ForkedTriagePageProps> = ({
  showSelfSearch,
  showChildSearch,
  showCoachingToolkit,
  showParentingToolkit,
  showMedsConsult,
  showCouplesTherapy,
  showFamilyTherapy,
  isInternational,
  isDirectAccessMedsSupported,
  userAge,
  selectedPathwayOption,
  isUserLoggedIn,
  onSelfSearchPress,
  onChildSearchPress = noop,
  onCoachingToolkitPress = noop,
  onParentingToolkitPress = noop,
  onMedConsultPress = noop,
  onCouplesTherapyPress,
  onFamilyTherapyPress,
  onCareOptionModalOpen = noop,
  onCareOptionModalClose = noop,
  onLearnMorePress = noop,
  resetSelectedPathwayOption = noop,
  latestMatchesLinkProps,
  onContactCareNavigatorPress = noop,
  careNavigatorFooterEntryPointModal,
  isUserTeen = false,
  coverageModalContents = [],
  costMessagingModalCoverageInfo = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  trackAvailablePrograms,
  showNewForkedTriage = false,
  loading,
  hideCurrentOpenModal = false,
  customerName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}) => {
  const { breakpoints, spacing, colors } = useTheme() as ThemeType
  const { isMobileSized } = breakpoints
  const { formatMessage } = useIntl()
  const hasTrackedAvailablePrograms = useRef(false)

  // If a care option has modal content, the card triggers the modal and the modal CTA handles the onPress for the selected care option.
  // To call the selected care option's onPress in a logged-out state (when the modal won't be shown), we invoke it in the modal's onOpen method.
  // In the logged-out flow, the care options' onPress methods handle navigation to registration/sign-in, so they must be called.
  const handleOnCareOptionModalOpen = (props: OnCareOptionModalOpenCloseProps) => {
    onCareOptionModalOpen(props)
    if (!isUserLoggedIn) {
      switch (props.treatmentOption) {
        case CARE_OPTION.PARENT_COACHING:
          onParentingToolkitPress()
          break
        case CARE_OPTION.FAMILY_THERAPY:
          onFamilyTherapyPress && onFamilyTherapyPress()
          break
        case CARE_OPTION.MEDICATION_CONSULTATION:
          onMedConsultPress()
          break
      }
    }
  }
  const careOptionsModalContent = useGetCareOptionsModalContent({
    selectedPathwayOption,
    userAge,
    onCareOptionModalOpen: handleOnCareOptionModalOpen,
    onCareOptionModalClose,
    onLearnMorePress,
    resetSelectedPathwayOption,
  })

  const careNavigatorLinkTestId = 'ForkedTriage-ContactCareNavigatorLink'

  const getTriageCareOptionMetadata: (
    option: ExtendedPathwayOption,
  ) => Partial<ForkedTriageCareOptionCard> | undefined = useCallback(
    (option) => {
      const ActiveForkedTriageCareOptionsMetadata = showNewForkedTriage
        ? ForkedTriageCareOptionsMetadataV2
        : ForkedTriageCareOptionsMetadata
      if (option === PathwayOptions.CHILD_SEARCH) {
        return isInternational
          ? ForkedTriageCareOptionsOUSMetadata[option]
          : ActiveForkedTriageCareOptionsMetadata[option]
      }
      return ActiveForkedTriageCareOptionsMetadata[option]
    },
    [isInternational, showNewForkedTriage],
  )

  const medicationConsultationOption = useMemo(() => {
    return {
      option: PathwayOptions.MEDICATION_CONSULTATION,
      shouldShow: showMedsConsult,
      cardProps: {
        onCardPress: onMedConsultPress,
        // prop used for v1 of forked triage
        descriptionFormattedMessageValues: {
          medsConsultModal: () => (
            <MedsConsultModal
              isDirectAccessMedsSupported={isDirectAccessMedsSupported}
              onBookConsultationPress={onMedConsultPress}
              onLearnMorePress={onLearnMorePress}
            />
          ),
        },
        // prop used for v2 of forked triage
        ...(showNewForkedTriage && {
          programDetailsModalContent: {
            ...careOptionsModalContent[CARE_OPTION.MEDICATION_CONSULTATION],
            closeModalIfOpen: hideCurrentOpenModal,
          },
        }),
      },
    }
  }, [
    careOptionsModalContent,
    hideCurrentOpenModal,
    isDirectAccessMedsSupported,
    onLearnMorePress,
    onMedConsultPress,
    showMedsConsult,
    showNewForkedTriage,
  ])

  const baseForkedTriageOptions: {
    option: ExtendedPathwayOption
    shouldShow: boolean
    cardProps: Partial<ForkedTriageCareOptionCard>
  }[] = useMemo(() => {
    return [
      {
        option: PathwayOptions.SELF_SEARCH,
        shouldShow: !showNewForkedTriage && showSelfSearch,
        cardProps: {
          onCardPress: onSelfSearchPress,
          latestMatchesLinkProps,
        },
      },
      {
        option: PathwayOptions.CHILD_SEARCH,
        shouldShow:
          showChildSearch &&
          // If showSelfSearch is false, child search becomes the main option, see getMainCareOption
          (!showNewForkedTriage || showSelfSearch),
        cardProps: { onCardPress: onChildSearchPress },
      },
      {
        option: PathwayOptions.COACHING_TOOLKIT,
        shouldShow: showCoachingToolkit,
        cardProps: { onCardPress: onCoachingToolkitPress },
      },
      {
        option: PathwayOptions.PARENTING_TOOLKIT,
        shouldShow: showParentingToolkit,
        cardProps: {
          onCardPress: onParentingToolkitPress,
          ...(showNewForkedTriage && {
            programDetailsModalContent: careOptionsModalContent[CARE_OPTION.PARENT_COACHING],
          }),
        },
      },
      medicationConsultationOption,
    ]
  }, [
    careOptionsModalContent,
    latestMatchesLinkProps,
    medicationConsultationOption,
    onChildSearchPress,
    onCoachingToolkitPress,
    onParentingToolkitPress,
    onSelfSearchPress,
    showChildSearch,
    showCoachingToolkit,
    showNewForkedTriage,
    showParentingToolkit,
    showSelfSearch,
  ])

  const additionalV2forkedTriageOptions: {
    option: ExtendedPathwayOption
    shouldShow: boolean
    cardProps: Partial<ForkedTriageCareOptionCard>
  }[] = useMemo(() => {
    return [
      {
        option: PathwayOptions.COUPLES_THERAPY,
        shouldShow: showCouplesTherapy,
        cardProps: { onCardPress: onCouplesTherapyPress },
      },
      {
        option: PathwayOptions.FAMILY_THERAPY,
        shouldShow: showFamilyTherapy,
        cardProps: {
          onCardPress: onFamilyTherapyPress,
          ...(showNewForkedTriage && {
            programDetailsModalContent: careOptionsModalContent[CARE_OPTION.FAMILY_THERAPY],
          }),
        },
      },
      medicationConsultationOption,
    ]
  }, [
    careOptionsModalContent,
    medicationConsultationOption,
    onCouplesTherapyPress,
    onFamilyTherapyPress,
    showCouplesTherapy,
    showFamilyTherapy,
    showNewForkedTriage,
  ])

  const shouldShowForTeen = useCallback(
    (option: ExtendedPathwayOption) => {
      return isUserTeen ? TEEN_ENABLED_PATHWAYS.includes(option) : true
    },
    [isUserTeen],
  )

  const forkedTriageOptions = useMemo(() => {
    return showNewForkedTriage
      ? // product requested that the medication consultation option always be shown last
        baseForkedTriageOptions.slice(0, -1).concat(additionalV2forkedTriageOptions)
      : baseForkedTriageOptions
  }, [additionalV2forkedTriageOptions, baseForkedTriageOptions, showNewForkedTriage])

  const forkedTriageOptionsDisplayed: CareOptionCardProps[] = useMemo(() => {
    return forkedTriageOptions
      .filter((item) => item.shouldShow && shouldShowForTeen(item.option))
      .map((item) => {
        const forkedTriageCardProps = {
          ...item.cardProps,
          ...getTriageCareOptionMetadata(item.option),
        } as ForkedTriageCareOptionCard
        const IconComponent = Illustrations[forkedTriageCardProps.icon]
        const shouldUseSmallerIcon = isMobileSized && !showNewForkedTriage

        return {
          optionKey: forkedTriageCardProps.testId,
          customHeaderText: showNewForkedTriage ? (
            <TriageOptionCustomHeaderContainer>
              <BodyText size={BodyTextSize.DEFAULT} text={formatMessage(forkedTriageCardProps.header)} />
              <ChevronIconContainer>
                <ChevronIcon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconDefault} size={24} />
              </ChevronIconContainer>
            </TriageOptionCustomHeaderContainer>
          ) : (
            <BodyText size={BodyTextSize.DEFAULT} text={formatMessage(forkedTriageCardProps.header)} />
          ),

          optionDescription:
            forkedTriageCardProps.description &&
            formatMessage(forkedTriageCardProps.description, {
              ...forkedTriageCardProps.descriptionFormattedMessageValues,
            }),

          optionIcon: (
            <IconComponent
              size={shouldUseSmallerIcon ? 48 : 56}
              width={shouldUseSmallerIcon ? 48 : 56}
              height={shouldUseSmallerIcon ? 48 : 56}
              {...(showNewForkedTriage
                ? {
                    innerCircleBgColor: 'transparent',
                    outerCircleBgColor: 'transparent',
                    backgroundFill: 'transparent',
                  }
                : {})}
            />
          ),
          // resetSelectedPathwayOption will handle removing selected treatment option query param which auto opens a modal on page load
          onPress: () => {
            resetSelectedPathwayOption()
            forkedTriageCardProps.onCardPress()
          },
          testId: `ForkedTriageCareOptionCard-${forkedTriageCardProps.testId}`,
          customStyle: {
            ...(showNewForkedTriage
              ? { border: 'none', backgroundColor: colors.backgroundTransparent, padding: '0px' }
              : {
                  borderRadius: spacing['16px'],
                  padding: isMobileSized
                    ? `${spacing['12px']} ${spacing['16px']}`
                    : `${spacing['20px']} ${spacing['16px']}`,
                }),
          },
          latestMatchesLinkProps: forkedTriageCardProps.latestMatchesLinkProps,
          hideTrailingIcon: showNewForkedTriage,
          chevronSize: 24,
          programDetailsModalContent: forkedTriageCardProps.programDetailsModalContent,
        }
      })
  }, [
    colors.backgroundTransparent,
    colors.iconDefault,
    forkedTriageOptions,
    formatMessage,
    getTriageCareOptionMetadata,
    isMobileSized,
    resetSelectedPathwayOption,
    shouldShowForTeen,
    showNewForkedTriage,
    spacing,
  ])

  useEffect(() => {
    if (!loading && !hasTrackedAvailablePrograms.current) {
      trackAvailablePrograms && trackAvailablePrograms(forkedTriageOptionsDisplayed.map((item) => item.optionKey ?? ''))
      hasTrackedAvailablePrograms.current = true
    }
  }, [forkedTriageOptionsDisplayed, loading, trackAvailablePrograms])

  const getMainCareOption = (): CareOptionCardProps | undefined => {
    if (!showNewForkedTriage) {
      return
    }
    const optionHeader = (
      <FormattedMessage defaultMessage='Let’s guide you to care' description='Link to find care for the user' />
    )
    const optionIcon = <ForkedTriageMainCareOptionIllustration {...(isMobileSized && { width: 224, height: 158 })} />

    if (!showSelfSearch) {
      return {
        optionHeader,
        optionIcon,
        ctaText: (
          <FormattedMessage
            defaultMessage='Find care for child or teen'
            description='CTA button text to start searching for care for a child or teen'
          />
        ),
        onPress: onChildSearchPress,
        testId: `ForkedTriageCareOptionCard-${PathwayOptions.CHILD_SEARCH}`,
      }
    }

    return {
      optionHeader,
      optionIcon,
      optionDescription: formatMessage({
        defaultMessage: 'Tell us a bit about yourself—we’ll match you with mental health experts',
        description: 'Label on link to find care for the user',
      }),
      ctaText: formatMessage({
        defaultMessage: 'Find care for myself',
        description: 'Text on cta button to start searching for care for the user',
      }),
      onPress: onSelfSearchPress,
      testId: `ForkedTriageCareOptionCard-${PathwayOptions.SELF_SEARCH}`,
    }
  }

  const exploreMoreOptionsTitleComponent = (
    <ExploreMoreOptionsTitleContainer>
      <BodyText
        text={formatMessage({
          defaultMessage: 'Explore more options',
          description: 'Title above section where user is shown more care options',
        })}
        size={BodyTextSize.LARGE}
      />
    </ExploreMoreOptionsTitleContainer>
  )

  return (
    <CareOptions
      isLoading={loading}
      careOptionsList={forkedTriageOptionsDisplayed}
      careNavigatorFooterEntryPointModal={careNavigatorFooterEntryPointModal}
      onContactCareNavigatorPress={onContactCareNavigatorPress}
      careNavigatorLinkTestId={careNavigatorLinkTestId}
      coverageModalContents={coverageModalContents}
      costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
      isCostMessagingEnabled={isCostMessagingEnabled}
      trackCoverageModalPress={trackCoverageModalPress}
      customerName={customerName}
      numSessions={numSessions}
      numberOfSessionByProgram={numberOfSessionByProgram}
      isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
      displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
      {...(showNewForkedTriage
        ? {
            hideHeader: true,
            mainCareOption: getMainCareOption(),
            mainCareOptionCustomStyle: {
              card: {
                flexDirection: 'column',
                marginBottom: isMobileSized ? spacing['32px'] : spacing['48px'],
                padding: `38px ${spacing['16px']} 30px`,
                ...(isMobileSized && { paddingBottom: spacing['16px'], paddingTop: spacing['24px'] }),
              },
              body: {
                alignItems: 'center',
                marginLeft: 0,
                marginTop: isMobileSized ? spacing['12px'] : spacing['16px'],
              },
              cta: {
                width: isMobileSized ? '100%' : '252px',
                marginTop: isMobileSized ? spacing['16px'] : spacing['24px'],
                ...(!isMobileSized && { maxWidth: '252px' }),
              },
              ctaContainer: { width: isMobileSized ? '100%' : 'auto' },
            },
            mainCareOptionConfig: {
              isFloatingCard: !isMobileSized,
              headerTextSize: isMobileSized ? SubheadSize.MEDIUM : SubheadSize.XLARGE,
              headerTextBold: !isMobileSized,
              descriptionTextSize: isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT,
              coverageLinkOnBottom: true,
            },
            careOptionsTitleComponent: exploreMoreOptionsTitleComponent,
            careOptionsCustomStyle: {
              cardContainer: {
                width: isMobileSized ? '100%' : '287px',
                margin: `${spacing['0px']} ${spacing['0px']} ${isMobileSized ? spacing['12px'] : spacing['24px']}`,
              },
              ...(!isMobileSized && {
                container: {
                  justifyContent: 'space-between',
                },
              }),
            },
            careNavigatorFooterHeaderText: (
              <FormattedMessage
                defaultMessage='Questions?'
                description='Header for section that takes user to contact a Care Navigator'
              />
            ),
            contentContainerCustomStyle: {
              marginTop: isMobileSized ? spacing['8px'] : spacing['12px'],
            },
          }
        : {})}
    />
  )
}
