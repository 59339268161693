import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType } from '../../utils'

/**
 * Pencil Icon
 */
export const EditIcon: FunctionComponent<EditIconProps> = ({ size = 24, fillColor, isFilled = true }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d={
          isFilled
            ? 'M19.4925 7.905L20.7025 6.695C21.7615 5.615 21.7615 3.875 20.7025 2.795C19.6225 1.735 17.8825 1.735 16.8025 2.795L15.5925 4.005L19.4925 7.905ZM3.01152 16.595L14.5325 5.065L18.4315 8.965L6.90252 20.485C6.86252 20.515 6.82252 20.535 6.77152 20.545L2.30252 21.485C2.28252 21.495 2.27152 21.495 2.25252 21.495C2.18152 21.495 2.12252 21.465 2.07252 21.425C2.01152 21.365 1.99252 21.275 2.00252 21.195L2.95252 16.725C2.96252 16.675 2.98252 16.635 3.01152 16.595Z'
            : 'M16.5697 2.84991C17.8397 1.67991 19.8197 1.71991 21.0497 2.94991C22.2797 4.17991 22.3197 6.15991 21.1507 7.42991C21.1479 7.43355 21.1451 7.43719 21.1423 7.44083C21.1142 7.47719 21.0861 7.51355 21.0497 7.54991L19.8097 8.78991L8.3097 20.2799C8.1997 20.4099 8.0597 20.5099 7.8797 20.5499H7.8697L3.4007 21.4809C3.3497 21.4999 3.2997 21.4999 3.2497 21.4999C3.0497 21.4999 2.8607 21.4199 2.7197 21.2799C2.5397 21.0999 2.4597 20.8399 2.5197 20.5999L3.4497 16.1299V16.1199C3.4897 15.9409 3.5897 15.7999 3.7197 15.6909L15.1997 4.19991L15.2097 4.19091L15.2197 4.17991L16.4497 2.94991C16.4897 2.90991 16.5297 2.87991 16.5697 2.84991ZM17.5097 4.00991C18.1907 3.32991 19.3097 3.32991 19.9897 4.00991C20.6697 4.69991 20.6697 5.79991 19.9897 6.48991L19.2797 7.19091L16.8097 4.71991L17.5097 4.00991ZM7.3397 19.1299L4.2197 19.7799L4.8697 16.6599L15.7397 5.77991L18.2197 8.25991L7.3397 19.1299Z'
        }
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type EditIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
}
