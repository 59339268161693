import React, { FunctionComponent } from 'react'
import { G, Mask, Path, Rect, Svg } from 'react-native-svg'

import { PrimaryNeedsWords } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

type DrinkingUsingIllustrationProps = {
  size?: string | number
  selected?: string
}

export const DrinkingUsingIllustration: FunctionComponent<DrinkingUsingIllustrationProps> = ({
  selected = false,
  size,
}) => (
  <Svg
    width={size}
    height={size}
    viewBox='0 0 48 48'
    fill='none'
    testID={tID(`${PrimaryNeedsWords.ALCOHOL_DRUG_USE}${selected ? '-Selected-' : '-'}Illustration`)}
  >
    <Path
      d='M18.5377 30.5871C17.0675 31.8035 15.5972 33.0199 14.1306 34.2362C12.8541 35.2907 11.7196 36.0062 10.039 35.4997C7.1852 34.6392 4.56014 32.5021 2.81132 30.0863C1.90187 28.8322 1.15106 27.4426 0.654824 25.9683C0.175192 24.5448 -0.250942 22.9424 0.175192 21.4493C0.488798 20.344 1.28572 19.5287 2.14537 18.8057C2.26528 18.704 2.38703 18.6061 2.50879 18.5063C2.75229 18.3067 3.00133 18.1127 3.23746 17.9188C3.25775 17.9037 3.27989 17.8849 3.30387 17.8661C4.60441 16.7947 5.90679 15.7252 7.23132 14.6858C10.1958 12.3623 13.1566 9.87493 16.3314 7.83948C16.3351 7.83948 16.3369 7.8376 16.3388 7.83572C17.6688 6.98463 19.0801 6.32749 20.6758 6.30301C22.0704 6.2823 23.5185 6.85471 24.8947 6.4593C26.7062 5.94149 28.1285 4.22803 29.5194 3.25079C30.6577 2.28485 31.7903 1.32455 32.9285 0.356727C33.4561 -0.134717 34.0538 -0.0688148 34.4836 0.255049C34.6201 0.330366 34.7511 0.439576 34.8692 0.586444L37.1235 3.40895C37.188 3.48992 37.2378 3.57277 37.2784 3.65562L37.284 3.66315C37.5404 3.9776 37.7562 4.34477 37.7913 4.72512C37.8521 4.96425 37.8484 5.21091 37.723 5.47264C37.343 6.25594 36.3321 6.89425 35.7049 7.47043C34.8637 8.24431 34.0778 9.04644 33.2366 9.82032C32.1593 10.9388 30.8089 12.5412 30.3865 14.2019C29.9696 15.8382 30.6613 17.6853 30.1762 19.3084C29.6486 21.0746 28.4015 22.3945 27.0438 23.5487C24.2361 25.9269 21.3768 28.2429 18.5451 30.5871H18.5377Z'
      fill='#00A4B3'
    />
    <Path
      d='M18.2192 23.6469C19.042 22.9408 19.8592 22.2309 20.6746 21.5192C21.5305 20.7717 22.5359 20.0486 23.2867 19.19C24.1833 18.1657 23.7368 17.2732 22.9971 16.4033C22.6835 15.7988 22.1817 15.2321 21.8091 14.7293C20.7705 13.3266 19.7042 11.9464 18.6085 10.5888C17.8614 9.66049 17.1013 8.74163 16.3265 7.83594C13.1517 9.87138 10.1909 12.3587 7.22644 14.6823C5.90191 15.7216 4.59953 16.7911 3.29899 17.8625C3.27685 17.8814 3.25471 17.9002 3.23258 17.9152C2.99645 18.1092 2.74926 18.305 2.50391 18.5027C3.02781 19.1956 3.61075 20.1616 4.14388 20.8281C5.58093 22.6301 6.68593 24.4509 8.19861 26.1832C9.39216 27.5521 10.8255 29.5988 12.7773 28.213C14.6866 26.8573 16.4372 25.1721 18.2174 23.6469H18.2192Z'
      fill='#85CCD5'
    />
    <Mask id='mask0_2697_89336' maskType='alpha' maskUnits='userSpaceOnUse' x='19' y='38' width='14' height='10'>
      <Path
        d='M21.8168 38.3431C19.6027 39.5165 19.3135 41.8812 20.3708 43.1997C21.4281 44.5181 27.2786 47.2278 29.2577 47.497C31.434 47.7922 33.1982 45.4655 32.962 43.3684C32.7257 41.2712 24.0308 37.1697 21.8168 38.3431Z'
        fill='#F69170'
      />
    </Mask>
    <G mask='url(#mask0_2697_89336)'>
      <Path
        d='M28.6128 38.4403C26.3415 37.5804 22.5446 36.743 20.1015 38.088C17.7833 39.3651 17.8719 41.7632 19.1424 43.3042C20.4129 44.8452 26.3457 47.5445 28.7274 47.8562C31.3462 48.198 34.7154 46.8357 34.5197 43.6926C34.3497 40.9998 30.829 39.2743 28.6169 38.4401L28.6128 38.4403Z'
        fill={selected ? '#3BA5B5' : '#F69170'}
      />
      <Rect
        width='8.21048'
        height='8.29916'
        transform='matrix(0.918397 0.395661 -0.382136 0.924106 28.0771 38.6719)'
        fill={selected ? '#85CCD5' : '#FFAF9B'}
      />
    </G>
    <Path
      d='M31.0628 24.5639C29.7254 22.806 27.2785 21.9386 25.1907 22.9108C24.8764 23.0581 24.5814 23.2414 24.3088 23.4542C21.2076 25.2971 20.6496 30.1025 24.4724 32.0992C29.1674 34.551 34.2377 28.744 31.0628 24.5671V24.5639Z'
      fill={selected ? '#85CCD5' : '#FFA338'}
    />
    <Mask id='mask1_2697_89336' maskType='alpha' maskUnits='userSpaceOnUse' x='35' y='18' width='12' height='21'>
      <Path
        d='M46.2055 22.6C47.9707 19.2503 43.5944 16.928 41.9379 20.5181C40.2814 24.1081 35.4109 34.6429 35.131 36.3675C34.8512 38.0921 37.1166 39.3404 38.4233 37.6857C39.7299 36.0309 44.4403 25.9496 46.2055 22.6Z'
        fill='#D7C7B5'
      />
    </Mask>
    <G mask='url(#mask1_2697_89336)'>
      <Path
        d='M48.5215 22.7214C50.2603 17.9359 43.3112 14.5638 40.5352 18.8703C37.7593 23.1767 33.8957 34.7614 34.3416 36.9726C34.7875 39.1838 37.2498 40.6863 39.414 38.9241C41.5782 37.1618 46.7828 27.5069 48.5215 22.7214Z'
        fill={selected ? '#3BA5B5' : '#D7C7B5'}
      />
      <Path
        d='M28.4214 31.5332C28.4214 31.5332 34.2344 31.8025 38.4065 33.6856C42.5786 35.5687 46.1351 38.8945 46.1351 38.8945L42.202 48.7548L24.4882 41.3935L28.4214 31.5332Z'
        fill={selected ? '#85CCD5' : '#EDE6DE'}
      />
    </G>
  </Svg>
)
