import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Caregiving Icon that can be used for elements related to Benefits
 */
export const CaregivingIcon: FunctionComponent<CaregivingIconProps> = ({
  size = 24,
  fillColor,
  testID = 'CaregivingIcon',
}) => {
  const { colors } = useTheme()

  return (
    <Svg testID={tID(testID)} width={size} height={size} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.853 11.912a.75.75 0 0 1 .504-.06l5.111 1.18a.364.364 0 0 1-.082.718H8.5a.75.75 0 0 0 0 1.5h3.886c.756 0 1.407-.45 1.7-1.097l6.477-1.214a.411.411 0 0 1 .246.778l-9.823 4.466a.75.75 0 0 1-.31.067H2a.75.75 0 0 0 0 1.5h8.675c.321 0 .639-.069.931-.202l9.824-4.465a1.911 1.911 0 0 0-1.143-3.619l-6.198 1.162a1.864 1.864 0 0 0-1.284-1.056l-5.11-1.18a2.25 2.25 0 0 0-1.513.18l-4.517 2.26a.75.75 0 1 0 .67 1.34l4.518-2.258Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='m12.148 4.531-.153.19-.203-.2a1.689 1.689 0 0 0-2.266-.12A1.585 1.585 0 0 0 9 5.53a1.555 1.555 0 0 0 .464 1.153l2.245 2.2a.397.397 0 0 0 .44.087.397.397 0 0 0 .13-.087l2.255-2.2A1.57 1.57 0 0 0 15 5.527a1.548 1.548 0 0 0-.535-1.125 1.678 1.678 0 0 0-2.316.13Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

export type CaregivingIconProps = {
  size?: string | number
  fillColor?: string
  testID?: string
}
