import React, { FunctionComponent } from 'react'

import { LiveChatMessagingIconProps } from './LiveChatMessagingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const LiveChatMessagingIconSvg = withSuspense({
  component: React.lazy(() => import('./LiveChatMessagingIconSvg')),
})

export const LiveChatMessagingIcon: FunctionComponent<LiveChatMessagingIconProps> = ({ fillColor }) => {
  return <LiveChatMessagingIconSvg fillColor={fillColor} />
}
