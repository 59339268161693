import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType, useWindowDimensions, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { Headline, PrimaryButton, Subhead } from '../../../atoms'
import { breakpoints as breakpointsConstant } from '../../../constants'
import { HeadlineSize, SubheadSize } from '../../../styles'
import { tID } from '../../../utils'
import { MicrositeExploreOnYourOwnCTA } from '../micrositeExploreOnYourOwnCTA/MicrositeExploreOnYourOwnCTA'

export interface LyraSelectHomepageHeroSectionProps {
  onExploreOnYourOwnCTAPress?: () => void
  onGetStartedPress?: () => void
}

const LyraSelectHeroContainer = styled.View<{ height: string; minHeight: string }>(({ height, minHeight }) => ({
  width: '100%',
  height: height,
  minHeight: minHeight,
}))

const TitleContainer = styled.View<{ padding: string; margin: string }>(({ padding, margin }) => ({
  padding: padding,
  margin: margin,
}))

const HeaderAndDescriptionTextContainer = styled.View<{ maxWidth: string }>(({ maxWidth }) => ({
  maxWidth: maxWidth,
}))

const StaticBackgroundContainer = styled.ImageBackground({
  width: '100%',
  height: '100%',
  position: 'absolute',
})

const DescriptionContainer = styled.View<{ margin: string }>(({ margin }) => ({
  margin: margin,
}))

const GetStartedButton = styled(PrimaryButton)<{ margin: string; isFullWidth: boolean }>(({ margin, isFullWidth }) => ({
  margin: margin,
  minWidth: '234px',
  ...(isFullWidth && {
    width: '100%',
  }),
}))

const MainContainer = styled.View({
  height: '100%',
  justifyContent: 'space-between',
})

const MicrositeExploreContainer = styled.View<{ margin: string }>(({ margin }) => ({
  margin: margin,
}))

type HeroSectionStylingMetadata = {
  backgroundImage: ImageSourcePropType
  buttonMargin: string
  descriptionMargin: string
  descriptionSubheadSize: SubheadSize
  exploreMargin: string
  headerHeadlineSize: HeadlineSize
  headerSubheadSize: SubheadSize
  imageHeight: number
  imageWidth: number
  maxTextWidth: string
  minHeight: string
  padding: string
  titleMargin: string
}

export const LyraSelectHomepageHeroSection: FunctionComponent<LyraSelectHomepageHeroSectionProps> = ({
  onExploreOnYourOwnCTAPress,
  onGetStartedPress,
}) => {
  const { breakpoints, spacing } = useTheme()
  const { width } = useWindowDimensions()
  const isMobileSmallSize = width < breakpointsConstant.mobileS

  const heroSectionStyling: HeroSectionStylingMetadata = {
    backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderMobile.png'),
    buttonMargin: '0 auto',
    descriptionMargin: `${spacing['8px']} 0 ${spacing['16px']} 0`,
    descriptionSubheadSize: SubheadSize.XSMALL,
    exploreMargin: `0 0 ${spacing['24px']} 0`,
    headerHeadlineSize: HeadlineSize.SMALL,
    headerSubheadSize: SubheadSize.LARGE,
    imageHeight: 601,
    imageWidth: 375,
    maxTextWidth: '583px',
    minHeight: '650px',
    padding: `${spacing['32px']} ${spacing['16px']} 0 ${spacing['16px']}`,
    titleMargin: '0 auto',
    ...(breakpoints.isMinWidthMobileXs && {
      backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderMobilePlus.png'),
      buttonMargin: '0 auto',
      imageHeight: 602,
      imageWidth: 458,
      padding: `${spacing['32px']} 60px 0 60px`,
      titleMargin: '0 auto',
    }),
    ...(breakpoints.isMinWidthMobileS && {
      backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderTablet.png'),
      buttonMargin: '0 auto',
      descriptionSubheadSize: SubheadSize.SMALL,
      imageHeight: 892,
      imageWidth: 768,
      headerHeadlineSize: HeadlineSize.MEDIUM,
      headerSubheadSize: SubheadSize.XLARGE,
      maxTextWidth: '550px',
      padding: `13% 60px 0 60px`,
      titleMargin: '0 auto',
    }),
    ...(breakpoints.isMinWidthTablet && {
      backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderLaptop.png'),
      buttonMargin: '0',
      descriptionMargin: `${spacing['16px']} 0 ${spacing['24px']} 0`,
      descriptionSubheadSize: SubheadSize.SMALL,
      exploreMargin: '0 0 42px 0',
      headerHeadlineSize: HeadlineSize.SMALL,
      headerSubheadSize: SubheadSize.XLARGE,
      imageHeight: 827,
      imageWidth: 992,
      maxTextWidth: '550px',
      minHeight: '',
      padding: '20% 0 0 5%',
      titleMargin: '0',
      verticalPadding: 0,
    }),
    ...(breakpoints.isMinWidthLaptop && {
      backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderDesktop.png'),
      buttonMargin: '0',
      descriptionMargin: `${spacing['16px']} 0 ${spacing['24px']} 0`,
      descriptionSubheadSize: SubheadSize.MEDIUM,
      exploreMargin: '0 0 74px 0',
      headerHeadlineSize: HeadlineSize.SMALL,
      headerSubheadSize: SubheadSize.XLARGE,
      imageHeight: 806,
      imageWidth: 1440,
      maxTextWidth: '583px',
      minHeight: '',
      padding: '20% 0 0 8.5%',
      titleMargin: '0',
      verticalPadding: 0,
    }),
    ...(breakpoints.isMinWidthDesktopL && {
      backgroundImage: require('../../../assets/microsite/LyraSelectMicrositeHeaderDesktopPlus.png'),
      buttonMargin: '0',
      descriptionMargin: `${spacing['16px']} 0 40px 0`,
      descriptionSubheadSize: SubheadSize.LARGE,
      exploreMargin: '0 0 175px 0',
      headerHeadlineSize: HeadlineSize.LARGE,
      headerSubheadSize: SubheadSize.XLARGE,
      imageWidth: 1920,
      imageHeight: 986,
      maxTextWidth: '738px',
      minHeight: '',
      padding: '17% 0 0 14%',
      titleMargin: '0',
      verticalPadding: 0,
    }),
  }

  const sectionHeight = useMemo(() => {
    const aspectRatio = heroSectionStyling.imageWidth / heroSectionStyling.imageHeight
    return Math.round(width * (1 / aspectRatio))
  }, [heroSectionStyling.imageHeight, heroSectionStyling.imageWidth, width])

  const headerTitleText = (
    <FormattedMessage defaultMessage='Elevate your everyday' description='Lyra Select hero section header title' />
  )

  return (
    <LyraSelectHeroContainer
      height={`${sectionHeight}px`}
      testID={'LyraSelectHeroSection-heroContainer'}
      minHeight={heroSectionStyling.minHeight}
    >
      <StaticBackgroundContainer source={heroSectionStyling.backgroundImage} />
      <MainContainer>
        <TitleContainer padding={heroSectionStyling.padding} margin={heroSectionStyling.titleMargin}>
          <HeaderAndDescriptionTextContainer maxWidth={heroSectionStyling.maxTextWidth}>
            <View>
              {breakpoints.isMobileSized ? (
                <Subhead size={heroSectionStyling.headerSubheadSize} textAlign='center' text={headerTitleText} />
              ) : (
                <Headline size={heroSectionStyling.headerHeadlineSize} text={headerTitleText} />
              )}
            </View>
            <DescriptionContainer margin={heroSectionStyling.descriptionMargin}>
              <Subhead
                size={heroSectionStyling.descriptionSubheadSize}
                text={
                  <FormattedMessage
                    defaultMessage='Get support with self-care resources, mindfulness practices, expert-led events, one-on-one care, and more.'
                    description='Lyra Select hero section header description'
                  />
                }
                textAlign={breakpoints.isMobileSized ? 'center' : 'left'}
              />
            </DescriptionContainer>
          </HeaderAndDescriptionTextContainer>
          <GetStartedButton
            margin={heroSectionStyling.buttonMargin}
            text={
              <FormattedMessage
                defaultMessage='Get started'
                description='Lyra Select hero section get started button'
              />
            }
            onPress={() => {
              onGetStartedPress && onGetStartedPress()
            }}
            testID={tID('LyraSelectHeroSection-getStartedButton')}
            fullWidth={isMobileSmallSize}
            isFullWidth={isMobileSmallSize}
          />
        </TitleContainer>
        <MicrositeExploreContainer margin={heroSectionStyling.exploreMargin}>
          <MicrositeExploreOnYourOwnCTA
            onExploreOnYourOwnCTAPress={onExploreOnYourOwnCTAPress}
            testId={'LyraSelectHeroSection-exploreOnYourOwnCTA'}
          />
        </MicrositeExploreContainer>
      </MainContainer>
    </LyraSelectHeroContainer>
  )
}
