import React from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { VALUE_PROPS_TITLES } from '@lyrahealth-inc/shared-app-logic'

import {
  CheckMarkIcon,
  ValuePropDedicatedSupport,
  ValuePropMeditationsCoursesArticles,
  ValuePropMentalHealthSessions,
  ValuePropProvidersIntelligentlyMatched,
} from '../../atoms'
import { Subhead } from '../../atoms/subhead/Subhead'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { ValuePropsIconList, ValuePropsItemType } from '../../molecules/valuePropsIconList/ValuePropsIconList'
import { SubheadSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'

const ValuePropsHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme: { breakpoints, spacing } }) => ({
  marginBottom: spacing['32px'],
  textAlign: breakpoints.isMinWidthLaptop ? undefined : 'center',
}))

const getValuePropsItems = (isCondensedVersion: boolean): ValuePropsItemType[] => {
  return [
    {
      icon: isCondensedVersion ? <CheckMarkIcon /> : <ValuePropMentalHealthSessions />,
      title: isCondensedVersion
        ? VALUE_PROPS_TITLES.condensedMentalHealthSessions
        : VALUE_PROPS_TITLES.mentalHealthSessions,
    },
    {
      icon: isCondensedVersion ? <CheckMarkIcon /> : <ValuePropMeditationsCoursesArticles />,
      title: isCondensedVersion
        ? VALUE_PROPS_TITLES.condensedMeditationsCoursesArticles
        : VALUE_PROPS_TITLES.meditationsCoursesArticles,
    },
    {
      icon: isCondensedVersion ? <CheckMarkIcon /> : <ValuePropDedicatedSupport />,
      title: isCondensedVersion ? VALUE_PROPS_TITLES.condensedDedicatedSupport : VALUE_PROPS_TITLES.dedicatedSupport,
    },
    ...(isCondensedVersion
      ? []
      : [
          {
            icon: <ValuePropProvidersIntelligentlyMatched />,
            title: VALUE_PROPS_TITLES.providersIntelligentlyMatched,
          },
        ]),
  ]
}

export const RegistrationValueProps: React.FC<{
  isCondensedVersion?: boolean
}> = ({ isCondensedVersion = false }) => {
  const { isMobileSized } = useMediaQuerySize()

  return (
    <>
      <ValuePropsHeader
        size={isCondensedVersion ? SubheadSize.LARGE : SubheadSize.MEDIUM}
        style={
          isCondensedVersion
            ? {
                textAlign: 'center',
                marginTop: isMobileSized ? undefined : 48,
                marginBottom: 24,
              }
            : undefined
        }
        text={
          <FormattedMessage
            defaultMessage='What you get with Lyra'
            description='Header for value props on sign-up page'
          />
        }
      />
      <ValuePropsIconList items={getValuePropsItems(isCondensedVersion)} isCondensedVersion={isCondensedVersion} />
    </>
  )
}
