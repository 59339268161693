import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  IS_PRODUCTION,
  useFlags,
  useWellnessCheckIn as useWellnessCheckInShared,
} from '@lyrahealth-inc/shared-app-logic'

import { getDeviceUUID } from '../../data/appGlobals/appGlobalsSelectors'
import { getEssentialsDisabled } from '../../data/customer/customerSelectors'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import {
  getId,
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
} from '../../data/user/userSelectors'
import {
  getWellnessCheckInResultsData,
  postWellnessCheckInResultsData,
} from '../../features/wellnessCheckIn/data/wellnessCheckInActions'
import { logToSumoLogic, SUMO_CATEGORY } from '../utils/userUtils'

export const useWellnessCheckIn = (): ReturnType<typeof useWellnessCheckInShared> => {
  const dispatch = useAppDispatch()
  const { isWellnessCheckInEnabled, isWellnessCheckInBenefitsEntryPointEnabled } = useFlags()

  const lyraId = useSelector(getId)
  const essentialsDisabled = useSelector(getEssentialsDisabled)
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isUserInternational = useSelector(getIsUserInternational)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  const deviceUUID = useSelector(getDeviceUUID)

  const logToSumo = useCallback(
    (data: { message: string; type: string }) => {
      logToSumoLogic(IS_PRODUCTION ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG, lyraId, data)
    },
    [lyraId],
  )

  return useWellnessCheckInShared({
    isWellnessCheckInEnabled,
    isWellnessCheckInBenefitsEntryPointEnabled,
    essentialsDisabled,
    isLoggedIn,
    isUserInternational,
    isUserAssumedInternational,
    getWellnessCheckInResultsData: async () => await dispatch(getWellnessCheckInResultsData({ lyraId })).unwrap(),
    logToSumo,
    postWellnessCheckInResult: async (values) =>
      await dispatch(
        postWellnessCheckInResultsData({ lyraId, requestBody: { deviceUUID, body: JSON.stringify(values) } }),
      ).unwrap(),
  })
}
