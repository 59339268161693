import { fromJS, List, Map } from 'immutable'
import findInCollection from 'lodash/find'
import get from 'lodash/get'

import {
  getCareNavigatorSchedulingLink,
  MEETING_FORMATS,
  ProviderSearchPreferences,
  SESSION_FORMAT,
  TREATMENT_OPTIONS,
  TriageData,
} from '@lyrahealth-inc/shared-app-logic'
import { openUrl } from '@lyrahealth-inc/ui-core-crossplatform'

import { getProviderTypeForSelectedTreatmentOption } from './providerUtils'
import {
  getOnboardFormattedAddress,
  getOnboardIsLocationOnsite,
  getOnboardLocationCountry,
  getOnboardLocationLat,
  getOnboardLocationLon,
  getOnboardLocationState,
  getOnboardMeetingFormat,
  getOnboardSelectedTreatmentOption,
} from '../../features/onboard/data/onboardSelectors'
import { PROGRAMS } from '../constants/customerProgramAndTreatmentOptionConstants'

export function getLocationDataFromStore($$state: $TSFixMe) {
  return {
    location:
      (getOnboardLocationLat($$state)
        ? `${getOnboardLocationLat($$state)}, ${getOnboardLocationLon($$state)}`
        : getOnboardFormattedAddress($$state)) ?? '',
    locationCountry: getOnboardLocationCountry($$state),
    locationText: getOnboardFormattedAddress($$state) ?? '',
    locationState: getOnboardLocationState($$state),
    onsite: getOnboardIsLocationOnsite($$state),
  }
}

// If inside onboarding, only display a single location specified by either:
// - The onsite address. A provider can be associated with any number of addresses that represent their own locations. However,
//   sometimes they are contracted to provide onsite therapy at a customer's location. The onsite address is given
//   to us separately and is not associated with the provider object, which means we have to reconcile when to use
//   the provider's listed address vs. when to use the onsite address. Since the onsite address is only provided
//   when the user specifically selected an onsite location, we prioritize showing the onsite address over the
//   provider's address.
// - The "nearestAddress" property if the above does not apply
// If outside onboarding, display all locations in the "addresses" list, sorted so that "nearestAddress"
// comes first if it's available
export const getDisplayedAddressesForProvider = ({
  outsideOnboarding,
  provider,
  isOnsite,
  onsiteAddress,
}: $TSFixMe) => {
  if (outsideOnboarding) {
    return provider.get('addresses', List()).sort(($$addressOne: $TSFixMe, $$addressTwo: $TSFixMe) => {
      const nearestStreet = provider.getIn(['nearestAddress', 'street1'], '')
      if ($$addressOne.get('street1') === nearestStreet) {
        return -1
      } else {
        return $$addressTwo.get('street1') === nearestStreet ? 1 : 0
      }
    })
  } else if (isOnsite && onsiteAddress.size > 0) {
    return fromJS([onsiteAddress])
  } else {
    return fromJS([provider.get('nearestAddress', Map())])
  }
}

/* Generates the Matcher request payload for getting a list of provider recommendations.
 * This is encapsulated in a functional utility because there are 2+ buttons that trigger a call to Matcher, and the
 * payload is full of values that live in separate areas of the store.
 */
export const generateProviderRecommendationsPayload = ({
  triageData,
  locationData,
  programSpecificData,
  $$state = Map(),
  preferredCareLanguageTags,
  providerSearchPreferences,
  ...rest
}: {
  triageData: TriageData
  locationData?: Dict
  programSpecificData?: any
  $$state: any
  preferredCareLanguageTags?: string[]
  providerSearchPreferences?: ProviderSearchPreferences | null
}) => {
  return Object.assign(
    {},
    triageData,
    locationData ?? getLocationDataFromStore($$state),
    programSpecificData ?? generateProgramSpecificDataForProviderRecommendations({ $$state, ...rest }),
    { preferredCareLanguageTags, providerSearchPreferences },
  )
}

export const generateProgramSpecificDataForProviderRecommendations = ({ $$state = Map(), ...rest }) => {
  // @ts-expect-error TS(2345): Argument of type 'Map<unknown, unknown>' is not as... Remove this comment to see the full error message
  const meetingFormat = rest?.meetingFormat ?? getOnboardMeetingFormat($$state)
  // @ts-expect-error TS(2345): Argument of type 'Map<unknown, unknown>' is not as... Remove this comment to see the full error message
  const selectedTreatmentOption = rest?.selectedTreatmentOption ?? getOnboardSelectedTreatmentOption($$state)
  const providerType = rest?.providerType ?? getProviderTypeForSelectedTreatmentOption(selectedTreatmentOption)

  const sessionFormat = convertMeetingFormatToSessionFormat([meetingFormat])
  const shouldSendSessionFormat = selectedTreatmentOption === TREATMENT_OPTIONS.COACHING && !!sessionFormat

  return {
    blendedCareProgramId:
      (convertTriageTreatmentOptionToProgram({ treatmentOptionName: selectedTreatmentOption }) as $TSFixMe)
        ?.blendedCareProgramId || PROGRAMS?.[providerType?.program]?.blendedCareProgramId,
    ...(shouldSendSessionFormat && { sessionFormat }),
  }
}

// Convert meetingFormats to a sessionFormat object of the form: { "All": ["Live-Messaging"] }
// The key of this object can also be "Any". This depends on the value of "areAllRequired" param.
// This is a new format that BE expects. Matcher is expecting this format from any new code written.
const convertMeetingFormatToSessionFormat = (meetingFormats: any[], areAllRequired = true) => {
  const sessionFormatOptions = {
    [MEETING_FORMATS.VIDEO]: SESSION_FORMAT[0].text,
    [MEETING_FORMATS.IN_PERSON]: SESSION_FORMAT[1].text,
    [MEETING_FORMATS.LIVE_MESSAGING]: SESSION_FORMAT[2].text,
  }

  // Member Preferences allows users to select both video and live messaging as a valid modality preference
  // This represents both the default none selected state and the both-selected state in what Arbiter expects
  if ([MEETING_FORMATS.NONE_SPECIFIED].includes(meetingFormats[0])) {
    return null
  } else if ([MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING, MEETING_FORMATS.NONE_SPECIFIED].includes(meetingFormats[0])) {
    return { All: [sessionFormatOptions[MEETING_FORMATS.VIDEO], sessionFormatOptions[MEETING_FORMATS.LIVE_MESSAGING]] }
  } else {
    const sessionFormats = meetingFormats.map((meetingFormat: string) => sessionFormatOptions[meetingFormat])
    return areAllRequired ? { All: sessionFormats } : { Any: sessionFormats }
  }
}

// Given a triage treatment option name, return the corresponding customer program.
// Note that we do not match treatment options to customer programs when the customer program is marked as "not a full
// treatment option". This means that the customer program is provided as part of another treatment option.
// An example of this is blended care therapy, which is provided as part of individual therapy. All blended care therapists
// are categorized under individual therapy, but not all therapists under individual therapy are part of blended care.
export const convertTriageTreatmentOptionToProgram = ({ treatmentOptionName }: $TSFixMe) => {
  const allPrograms = List(Object.values(PROGRAMS))
  return allPrograms.find(
    (program) =>
      // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      get(program, 'treatmentOptionProperties.associatedTriageTreatmentOptions', []).includes(treatmentOptionName) &&
      get(program, 'treatmentOptionProperties.isFullTreatmentOption'),
  )
}

export const convertTriageTaxonomyOptionToProgram = ({ programTaxonomy }: $TSFixMe) => {
  const allPrograms = List(Object.values(PROGRAMS))

  return allPrograms.find((program) => {
    const foundProgram = findInCollection(
      get(program, 'treatmentOptionProperties.programTaxonomyTreatmentOptions', []),
      (programOption) =>
        (programOption as $TSFixMe).clientele === programTaxonomy.clientele &&
        (programOption as $TSFixMe).treatment === programTaxonomy.treatment &&
        (programOption as $TSFixMe).partner === programTaxonomy.partner &&
        (programOption as $TSFixMe).offering === programTaxonomy.offering,
    )
    const isFullTreatmentOption = (program as $TSFixMe).treatmentOptionProperties?.isFullTreatmentOption
    return foundProgram && isFullTreatmentOption
  })
}

export const goToCareNavigatorSchedulingPage = ({
  defaultDirectCareNavigatorBookingLink,
  directDedicatedCareNavigatorLink,
  launchDate,
  name,
  email,
  isZeroProvidersFlow = false,
  isAssertiveTriageFlow = false,
  salesforceId,
  isLyrian,
  isProd = process.env.NODE_ENV === 'production',
}: $TSFixMe) => {
  const link = getCareNavigatorSchedulingLink({
    defaultDirectCareNavigatorBookingLink,
    directDedicatedCareNavigatorLink,
    launchDate,
    name,
    email,
    isZeroProvidersFlow,
    isAssertiveTriageFlow,
    salesforceId,
    isLyrian,
    isProd,
  })
  openUrl(link)
}
