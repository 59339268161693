import mixpanel from 'mixpanel-browser'

import { MixpanelMock } from './mixpanelMock'

declare global {
  interface Window {
    Cypress: any
    mixpanelMock: MixpanelMock
    spyTrack: any
  }
}
const mixpanelMock = new MixpanelMock('mockToken')

if (window.Cypress && window.Cypress.runner.getStartTime()) {
  window.mixpanelMock = mixpanelMock
  window.spyTrack = window.Cypress.cy.spy(mixpanelMock, 'track')
  window.Cypress.cy.spy(mixpanelMock, 'register').as('mixpanelRegister')
  window.Cypress.cy.spy(mixpanelMock, 'alias').as('mixpanelAlias')
  window.Cypress.log({ message: 'Now spying on Mixpanel track calls' })
}

const Mixpanel = window.Cypress ? mixpanelMock : mixpanel
export default Mixpanel
