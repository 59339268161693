import React, { FunctionComponent } from 'react'

import { Image } from '../image/Image'

type ProviderNoCapacityIllustrationProps = {
  height?: number
  width?: number
}

export const ProviderNoCapacityIllustration: FunctionComponent<ProviderNoCapacityIllustrationProps> = ({
  height = 114,
  width = 128,
  ...props
}) => {
  return (
    <Image
      {...props}
      style={{ height: height, width: width }}
      source={require('../../assets/provider_no_capacity_illustration.png')}
      contentFit='contain'
      accessibilityIgnoresInvertColors
    />
  )
}
