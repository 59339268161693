import React, { FunctionComponent } from 'react'

import { MessageAttachmentData } from '../..'
import { MessageAttachmentType } from '../../ui-models/messages/Messages'
import { DirectBookingLinkMessageAttachment } from '../directBookingLinkMessageAttachment/DirectBookingLinkMessageAttachment'

export type MessageAttachmentRendererProps = {
  attachment: MessageAttachmentData
  onPress?: (attachment: MessageAttachmentData) => void
}

export const MessageAttachmentRenderer: FunctionComponent<MessageAttachmentRendererProps> = ({
  onPress,
  attachment,
}) => {
  switch (attachment.type) {
    case MessageAttachmentType.DBL:
      return <DirectBookingLinkMessageAttachment onPress={() => onPress?.(attachment)} />
  }
}
