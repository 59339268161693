import {
  COMMON_BCX_SCREENS,
  COMMON_MIXPANEL_PAGE,
  COMMON_REGISTRATION_SCREENS,
  COMMON_SEARCH_FOR_CARE_SCREENS,
  micrositesMetadataList,
  PAGES,
  PROFILE_PAGES,
} from '@lyrahealth-inc/shared-app-logic'

import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'

export const routeToPageMap: { [key: string]: PAGES } = {
  '/': COMMON_MIXPANEL_PAGE.MICROSITE,
  [PAGE_ROUTES.LOGIN]: COMMON_MIXPANEL_PAGE.LOGIN,
  [PAGE_ROUTES.HOMEBASE]: COMMON_MIXPANEL_PAGE.HOME_BASE,
  [PAGE_ROUTES.SEARCH_CARE.BENEFITS]: COMMON_MIXPANEL_PAGE.INTEGRATED_BENEFITS_PAGE,
  [PAGE_ROUTES.FAMILY_HUB]: COMMON_MIXPANEL_PAGE.FAMILY_HUB,
  [PAGE_ROUTES.FAMILY_CARE_OPTIONS]: COMMON_MIXPANEL_PAGE.FAMILY_CARE_OPTIONS,
  [PAGE_ROUTES.CARE_INITIAL]: COMMON_MIXPANEL_PAGE.CARE_TAB,
  [PAGE_ROUTES.PATHWAYS.ESSENTIALS_HOMEPAGE_WIDGET]: COMMON_MIXPANEL_PAGE.ESSENTIALS_HOMEPAGE_WIDGET,
  [PAGE_ROUTES.ONBOARD.ABOUT_CHILD_INFO]: COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION,
  [PAGE_ROUTES.ONBOARD.ABOUT]: COMMON_SEARCH_FOR_CARE_SCREENS.TRIAGE,
  ['/secure/onboard/about/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.TRIAGE,
  ['/secure/onboard/care-advocate-consultation/confirmation']: COMMON_MIXPANEL_PAGE.CONTACT_CARE_TEAM_CONFIRMATION,
  [PAGE_ROUTES.CARE_NAVIGATOR_OPTIONS.ONBOARD]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
  ['/secure/onboard/coaches']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/coaching/coach/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/coaching/coaches']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/coaching/location']: COMMON_MIXPANEL_PAGE.LOCATION,
  ['/secure/onboard/contact-care-team']: COMMON_MIXPANEL_PAGE.CONTACT_CARE_TEAM_NAVIGATOR,
  ['/secure/onboard/contact-care-team/confirmation']: COMMON_MIXPANEL_PAGE.CONTACT_CARE_TEAM_CONFIRMATION,
  ['/secure/onboard/match-location']: COMMON_MIXPANEL_PAGE.LOCATION,
  [PAGE_ROUTES.MEDS_OPTIONS_EXPLAINED.ONBOARD]: COMMON_MIXPANEL_PAGE.MEDS_PROGRAM_OPTIONS,
  ['/secure/onboard/member-preferences']: COMMON_MIXPANEL_PAGE.RECOMMENDATIONS_EXPLAINED_PAGE,
  ['/secure/onboard/member-preferences/selection']: COMMON_MIXPANEL_PAGE.PREFERENCES_CATEGORY_PAGE,
  ['/secure/onboard/zero-search-results-redirect']: COMMON_MIXPANEL_PAGE.ZERO_SEARCH_RESULTS_REDIRECT,
  ['/secure/onboard/provider']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/provider/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/provider/:id/appointment']: COMMON_MIXPANEL_PAGE.APPOINTMENT_BOOKING,
  ['/secure/onboard/provider/:id/request']: COMMON_MIXPANEL_PAGE.APPOINTMENT_REQUEST,
  ['/secure/onboard/provider/:id/schedule']: COMMON_MIXPANEL_PAGE.PROVIDER_SCHEDULING_CALENDAR,
  ['/secure/onboard/t1-multi-providers']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/t1-provider/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/treatment-options']: COMMON_MIXPANEL_PAGE.TREATMENT_RECOMMENDATIONS,
  [PAGE_ROUTES.ESSENTIALS]: COMMON_MIXPANEL_PAGE.ESSENTIALS,
  ['/secure/index/assignments']: COMMON_MIXPANEL_PAGE.ASSIGNMENTS_HOME,
  ['/register/auto']: COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_AUTO_ENROLLMENT,
  [PAGE_ROUTES.WELLNESS_CHECK_IN.PREVIEW]: COMMON_MIXPANEL_PAGE.WELLNESS_CHECK_IN,
  [PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE]: COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
  [PAGE_ROUTES.SESSIONS]: COMMON_MIXPANEL_PAGE.SESSIONS,
  ['/secure/index/progress']: COMMON_BCX_SCREENS.PROGRESS,
  [PAGE_ROUTES.PROFILE.DEFAULT]: PROFILE_PAGES.ACCOUNT_HOME,
  ...Object.fromEntries(micrositesMetadataList.map((item) => [`/${item.micrositeUrl}`, item.page as PAGES])),
}
