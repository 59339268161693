import React, { FunctionComponent } from 'react'

import { WelcomeParkProps } from './WelcomeParkSvg'
import { withSuspense } from '../../utils/withSuspense'

const WelcomeParkSvg = withSuspense({
  component: React.lazy(() => import('./WelcomeParkSvg')),
})

/**
 * illustration representing a person laying at the park.
 */
export const WelcomePark: FunctionComponent<WelcomeParkProps> = ({ width, height }) => {
  return <WelcomeParkSvg width={width} height={height} />
}
