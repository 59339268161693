import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  Appointment,
  COMMON_MIXPANEL_PAGE,
  ProviderInfo,
  TREATMENT_OPTIONS,
  TRIAGE_ENTRY_POINTS,
  TriageSearch,
  useBackToTriage,
  useInProgressSearch,
} from '@lyrahealth-inc/shared-app-logic'
import { NavBar, OnboardingExitModal, ProgressBar, ThemeType, tID, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getIsLyraSocialCareEnabled } from '../../../data/customer/customerSelectors'
import { getAppointmentReschedule, getSessions } from '../../../data/lyraTherapy/lyraTherapySelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { getRedirectUrlOnTriageExit, getTriageEntryPoint } from '../../../data/register/registerSelectors'
import {
  getDirectDedicatedCareNavigatorInfo,
  getDirectDedicatedCareNavigatorLink,
} from '../../../data/user/userActions'
import { getUserCountryName, getUserLegalFirstName } from '../../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../../features/appointmentDashboard/data/appointmentDashboardActions'
import { markDashboardAsStale } from '../../../features/lyraTherapy/data/LyraTherapyActions'
import { setLatestMatchesSearch } from '../../../features/onboard/data/onboardDataActions'
import {
  getIsLatestMatches,
  getLatestMatchesSearch,
  getOnboardProviderProfileData,
} from '../../../features/onboard/data/onboardSelectors'
import {
  clearTriage,
  getWordCloudWords,
  postTriageSearch,
  setSelectedSearchId,
} from '../../../features/triage/data/triageActions'
import {
  getFetchedMetadataVersion,
  getIsCreatingSearch,
  getProgressBarPercentage,
  getSearcherInfo,
  getSearches,
  getSelectedSearch,
  getSelectedSearchPrimaryNeed,
  getTreatmentRecommendations,
  getWordCloud,
} from '../../../features/triage/data/triageSelectors'
import { NEGATIVE_PADDING_PATHS } from '../../constants/routingConstants'
import { useCareNavigatorModal } from '../../hooks/useCareNavigatorModal'
import { useNavigateToLatestProviderMatches } from '../../hooks/useNavigateToLatestProviderMatches'
import { matchPaths } from '../../utils/stringUtils'

type OnboardingNavBarProps = ConnectedProps<typeof connector> & {
  transparencyEnabled?: boolean
  hasConnectedCalendar: boolean
  skipExitModalWhenNoProviderConnectedCalendar?: boolean
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  isCreatingSearch: boolean
  percentProgress?: number
  showProgressBar?: boolean
  progressAnimationDuration?: number
  onBack?: () => void
  oatmealBackground?: boolean
  padBottom?: boolean
  setLatestMatchesSearch: () => void
  latestMatchSearch?: TriageSearch
  providerProfileData: ProviderInfo
  searches: TriageSearch[]
  providerSessions: { appointments: Appointment[] }
  isLatestMatches: boolean
  showBackButton?: boolean
  negativePadBottom?: boolean
  testId?: string
  containerWhichIsNotWindowHasScrolled?: boolean
}

const FixedContainer = styled.View<{
  backgroundColor: string
  isTransparent?: boolean
  showBorder?: boolean
  theme: ThemeType
}>(({ backgroundColor, theme, isTransparent, showBorder }) => ({
  position: 'sticky',
  top: '0px',
  left: '0px',
  right: '0px',
  zIndex: 100,
  backgroundColor: isTransparent ? 'transparent' : backgroundColor,
  borderBottomWidth: showBorder ? `1px` : undefined,
  borderBottomColor: showBorder ? theme.colors.borderDefault : undefined,
}))

const ProgressBarContainer = styled(ProgressBar)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.breakpoints.isMinWidthTablet ? theme.spacing['24px'] : theme.spacing['16px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
}))

const OnboardingNavBar: React.FC<OnboardingNavBarProps> = ({
  transparencyEnabled = true,
  showProgressBar = true,
  treatmentRecs,
  hasConnectedCalendar,
  skipExitModalWhenNoProviderConnectedCalendar = false,
  isCreatingSearch,
  shouldShowCareNavigatorHeaderEntrypoint = true,
  percentProgressFromStore,
  percentProgress: percentProgressFromProps,
  onBack,
  markDashboardAsStale,
  oatmealBackground,
  padBottom,
  clearTriage,
  setLatestMatchesSearch,
  latestMatchSearch,
  providerProfileData,
  searches,
  providerSessions,
  setSelectedSearchId,
  handleZeroViewSelect,
  isLatestMatches,
  showBackButton,
  negativePadBottom,
  testId,
  selectedSearch,
  searcherInfo,
  postTriageSearch,
  wordCloudWords,
  primaryNeed,
  getWordCloudWords,
  triageEntryPoint,
  redirectUrlOnTriageExit,
  progressAnimationDuration = 300,
  userCountryName,
  isLyraSocialCareEnabled,
  fetchedMetadataVersion,
  containerWhichIsNotWindowHasScrolled,
}) => {
  const navigate = useNavigate()
  const [hasScrolled, setHasScrolled] = useState(false)
  const containerScrollTrackingRef = useRef(containerWhichIsNotWindowHasScrolled)
  useEffect(() => {
    if (
      containerWhichIsNotWindowHasScrolled !== undefined &&
      containerWhichIsNotWindowHasScrolled != containerScrollTrackingRef.current
    ) {
      setHasScrolled(containerWhichIsNotWindowHasScrolled)
      containerScrollTrackingRef.current = containerWhichIsNotWindowHasScrolled
    }
  }, [containerWhichIsNotWindowHasScrolled, hasScrolled])

  const [containerHeight, setContainerHeight] = useState(96)
  const percentProgress = percentProgressFromProps ?? percentProgressFromStore

  const { careNavigatorModal, careNavigatorEntryPoint } = useCareNavigatorModal({
    clickEntryPoint: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
  })

  const navigateBackToTriage = useBackToTriage({
    goBack: () => {
      navigate(-1)
    },
    search: selectedSearch as TriageSearch,
    country: userCountryName,
    lyraSocialCareEnabled: isLyraSocialCareEnabled,
    searcherInfo,
    isCreatingSearch,
    postTriageSearch,
    getWordCloudWords,
    wordCloudWords,
    primaryNeedsWord: primaryNeed ?? 'N/A',
    fetchedMetadataVersion,
  })

  const setHeaderTransparency = useCallback(() => {
    if (window.scrollY === 0) {
      setHasScrolled(false)
    } else {
      setHasScrolled(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', setHeaderTransparency)
    return () => {
      window.removeEventListener('scroll', setHeaderTransparency)
    }
  }, [setHeaderTransparency])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const { pathname, state } = useLocation()
  const fromPathName = state?.from
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const previouslySelectedAppointment = useSelector(getAppointmentReschedule)
  const displayUpdatedConnectedCalendar = !previouslySelectedAppointment

  const navigateToLatestProviderMatches = useNavigateToLatestProviderMatches({
    setLatestMatchesSearch,
    setSelectedSearchId,
    inProgressSearch,
    handleZeroViewSelect,
  })

  // Hide the care navigator entrypoint for care navigator pages
  if (shouldShowCareNavigatorHeaderEntrypoint) {
    shouldShowCareNavigatorHeaderEntrypoint = !matchPaths(pathname, [
      '/secure/onboard/care-advocate-consultation',
      '/secure/onboard/care-advocate-consultation/confirmation',
      '/secure/onboard/care-navigator-options',
      '/secure/onboard/contact-care-team',
      '/secure/onboard/contact-care-team/confirmation',
    ])
  }

  // No exit modal for these pages.
  skipExitModalWhenNoProviderConnectedCalendar = matchPaths(pathname, [
    '/secure/onboard/provider/:id',
    '/secure/onboard/provider/:id/request',
    '/secure/wellness/toolkit/:id',
    'secure/onboard/contact-care-team/confirmation',
  ])

  // Oatmeal color pages
  oatmealBackground =
    oatmealBackground ||
    matchPaths(pathname, [
      '/secure/onboard/match-location',
      '/secure/onboard/member-preferences',
      '/secure/onboard/member-preferences/selection',
      '/secure/onboard/meds-options-explained',
      '/secure/onboard/more-options',
      '/secure/onboard/zero-search-results-redirect',
      '/secure/onboard/care-navigator-options',
      '/secure/onboard/child-care-navigator-options',
      '/secure/onboard/care-advocate-consultation',
      '/secure/onboard/care-advocate-consultation/confirmation',
      '/secure/onboard/contact-care-team/confirmation',
      '/secure/onboard/about-child',
      '/secure/onboard/about-child/info',
      '/secure/onboard/custom-child-care',
      '/secure/onboard/provider/:id/appointment',
      '/secure/onboard/provider/:id/request',
      ...(displayUpdatedConnectedCalendar ? ['/secure/onboard/provider/:id/schedule'] : []),
    ])

  // Need to add extra negative padding so that nav sits on top of image header
  negativePadBottom = matchPaths(pathname, NEGATIVE_PADDING_PATHS.concat('/secure/onboard/treatment-options'))

  transparencyEnabled = !matchPaths(pathname, [
    '/secure/onboard/member-preferences',
    '/secure/onboard/member-preferences/selection',
    '/secure/onboard/about-child',
    '/secure/onboard/about-child/info',
    '/secure/onboard/custom-child-care',
    '/secure/onboard/provider/:id/appointment',
    '/secure/onboard/provider/:id/request',
    '/secure/onboard/provider/:id',
  ])

  showProgressBar =
    !matchPaths(pathname, ['/secure/onboard/care-advocate-consultation', '/secure/onboard/care-navigator-options']) &&
    treatmentRecs?.primaryTreatmentOptions?.[0] !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS &&
    treatmentRecs?.primaryTreatmentOptions?.[0] !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE

  const hideBorder = matchPaths(pathname, [
    ...(displayUpdatedConnectedCalendar ? ['/secure/onboard/provider/:id/schedule'] : []),
  ])

  const resetTriageToHomebase = () => {
    const entry = triageEntryPoint
    clearTriage()
    markDashboardAsStale()
    setLatestMatchesSearch(null)
    const redirectTriagePoints = [TRIAGE_ENTRY_POINTS.ESSENTIALS, TRIAGE_ENTRY_POINTS.CARE_TAB]
    if (redirectTriagePoints.includes(entry)) {
      navigate(redirectUrlOnTriageExit)
    } else {
      navigate('/secure/index/search-care')
    }
  }

  const { colors } = useTheme()
  const backgroundColor = oatmealBackground && !hasScrolled ? colors.backgroundSecondary : colors.backgroundPrimary
  return (
    <>
      <FixedContainer
        testID={tID('OnboardingNavBar')}
        backgroundColor={backgroundColor}
        isTransparent={transparencyEnabled && !hasScrolled}
        showBorder={hideBorder ? false : hasScrolled}
        onLayout={(e) => {
          setContainerHeight(e.nativeEvent.layout.height)
        }}
      >
        <NavBar
          testId={testId}
          showBackButton={showBackButton}
          onBack={() => {
            if (latestMatchSearch && isEmpty(providerProfileData)) {
              resetTriageToHomebase()
            } else if (
              fromPathName &&
              matchPaths(fromPathName, [
                '/secure/onboard/t1-multi-providers/latest-provider-matches',
                '/secure/onboard/coaches/latest-provider-matches',
              ])
            ) {
              navigateToLatestProviderMatches()
            } else if (pathname && matchPaths(pathname, ['/secure/onboard/child-care-navigator-options'])) {
              navigateBackToTriage()
            } else {
              onBack ? onBack() : navigate(-1)
            }
          }}
          onExit={() => {
            if (skipExitModalWhenNoProviderConnectedCalendar && !hasConnectedCalendar) {
              navigate('/secure/index/search-care')
            } else {
              setIsModalVisible(true)
            }
          }}
          isTransparent
          shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
          careNavigatorModal={careNavigatorModal}
          careNavigatorEntryPoint={careNavigatorEntryPoint}
        />
        {showProgressBar && Boolean(percentProgress) && (
          <ProgressBarContainer
            progress={percentProgress}
            backgroundColor={[colors.progressIndicatorBackgroundTop, colors.progressIndicatorBackgroundBottom]}
            color={colors.progressIndicator}
            width={-1}
            height={4}
            borderRadius={4}
            duration={progressAnimationDuration}
          />
        )}
        <OnboardingExitModal
          isVisible={isModalVisible}
          onExit={() => {
            resetTriageToHomebase()
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          isLatestMatches={isLatestMatches}
        />
      </FixedContainer>
      {padBottom && <View style={{ marginBottom: containerHeight }} />}
      {negativePadBottom && <View style={{ marginBottom: -containerHeight }} />}
    </>
  )
}

const mapStateToProps = ($$state: any) => ({
  // If re-enabling this nav bar, we don't depend on connected calendar anymore so should reconsider logic here
  hasConnectedCalendar: false,
  name: getUserLegalFirstName($$state),
  latestMatchSearch: getLatestMatchesSearch($$state),
  providerProfileData: getOnboardProviderProfileData($$state),
  selectedSearch: getSelectedSearch($$state),
  searcherInfo: getSearcherInfo($$state),
  providerSessions: getSessions($$state),
  searches: getSearches($$state),
  isLatestMatches: getIsLatestMatches($$state),
  wordCloudWords: getWordCloud($$state),
  treatmentRecs: getTreatmentRecommendations($$state),
  primaryNeed: getSelectedSearchPrimaryNeed($$state),
  isCreatingSearch: getIsCreatingSearch($$state),
  triageEntryPoint: getTriageEntryPoint($$state),
  redirectUrlOnTriageExit: getRedirectUrlOnTriageExit($$state),
  isLyraSocialCareEnabled: getIsLyraSocialCareEnabled($$state),
  userCountryName: getUserCountryName($$state),
  fetchedMetadataVersion: getFetchedMetadataVersion($$state),
  percentProgressFromStore: getProgressBarPercentage($$state),
})

const mapDispatchToProps = {
  trackEventWithObj,
  getDirectDedicatedCareNavigatorLink,
  markDashboardAsStale,
  clearTriage,
  setLatestMatchesSearch,
  setSelectedSearchId,
  handleZeroViewSelect,
  postTriageSearch,
  getWordCloudWords,
  getDirectDedicatedCareNavigatorInfo,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(OnboardingNavBar))
