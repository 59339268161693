import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { Image } from '../image/Image'

export const EmptyActivityOverviewIllustration: FunctionComponent<EmptyActivityOverviewIllustrationProps> = ({
  width = 240,
  height = 240,
}) => {
  const { formatMessage } = useIntl()
  return (
    <View>
      {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-ignores-invert-colors */}
      <Image
        source={require('../../assets/Lyra_Therapy_EmptyState.png')}
        style={{ width: width, height: height }}
        ariaLabel={formatMessage({
          defaultMessage: 'Illustration of a two people sitting across from each other and talking',
          description: 'Accessibility label for illustration',
        })}
      />
    </View>
  )
}

type EmptyActivityOverviewIllustrationProps = {
  width?: string | number
  height?: string | number
}
