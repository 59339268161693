import { useCallback } from 'react'

import { LD_EXTENSION_ID, SENDER } from '../constants'

const useSendMessage = () => {
  return useCallback(<T>(message: { name: string; type: string; payload?: T }) => {
    const messageWithDomain = { ...message, domain: LD_EXTENSION_ID, sender: SENDER }
    return window.postMessage(messageWithDomain, '*')
  }, [])
}

export default useSendMessage
