/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { FunctionComponent, useContext } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import lyraSelectCareNavigator from '../../assets/microsite/LyraSelectCareNavigator.png'
import imageMobile from '../../assets/PathwaysCareNavigatorIllustration.png'
import imageWeb from '../../assets/PathwaysCareNavigatorIllustrationWeb.png'
import { IS_WEB } from '../../constants'
import { ProductTypeContext, tID } from '../../utils'
import { Image } from '../image/Image'

type PathwaysCareNavigatorIllustrationProps = {
  size?: number
}

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const PathwaysCareNavigatorIllustration: FunctionComponent<PathwaysCareNavigatorIllustrationProps> = ({
  size = 201,
  ...props
}) => {
  const sizeInPixels = `${size}px`
  const { isLyraSelect } = useContext(ProductTypeContext)

  const imageSource = isLyraSelect
    ? lyraSelectCareNavigator
    : ((IS_WEB ? imageWeb : imageMobile) as ImageSourcePropType)
  return (
    <ImageContainer size={sizeInPixels} testID={`${tID('LiveChatIllustrationIcon')}`} source={imageSource} {...props} />
  )
}
