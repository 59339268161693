import { useSelector } from 'react-redux'

import { useGetNewYearCustomMessages as useGetNewYearCustomMessagesShared } from '@lyrahealth-inc/shared-app-logic'

import { getIsCustomerHPIExclusive, getNumberOfSessions } from '../../../data/customer/customerSelectors'

export const useGetNewYearCustomMessages = () => {
  const numSessions = useSelector(getNumberOfSessions)
  const isHPIExclusive = useSelector(getIsCustomerHPIExclusive)

  return useGetNewYearCustomMessagesShared({ numSessions, isHPIExclusive })
}
