import { lightThemeColors, ThemeColors } from './colors'
import { DEFAULT_THEME, Theme } from './theme'
import { defaultProductTypesToMetadata, PRODUCT_TYPE } from '../productType'

export const getColorTokensForTheme = (
  theme: Theme = DEFAULT_THEME,
  productType: PRODUCT_TYPE = PRODUCT_TYPE.WFMH,
): ThemeColors | object => {
  const productTypeMetadata = defaultProductTypesToMetadata[productType]
  if (productTypeMetadata) {
    const themeColorTokens = productTypeMetadata.theme[theme]
    if (themeColorTokens) {
      return themeColorTokens.colorTokens
    }
  }

  return lightThemeColors
}
