import {
  AvailabilityPreferenceV3ExperimentJoinKeys,
  S4CHomebaseHeaderQ12025JoinKeys,
  SearchForCareHomebaseHeaderExperimentJoinKeys,
  UrgencyToBookExperimentJoinKeys,
} from './searchForCareExperimentTypes'
import { COMMON_MIXPANEL_PAGE } from '../common/constants/mixpanelConstants'

export enum ExperimentUUIDs {
  LatestMatchesUUID = '65658a7b-e7c2-4fbf-923f-5640ffe20954',
  SearchForCareHomebaseHeaderUUID = '7491d7ac-742d-4687-89ae-3f338fd27eba',
  TriageSummaryUUID = 'd03d7916-6f2e-4bdc-b7db-d6fba47f3a43',
  AvailabilityPreferenceV3UUID = '2f1eb991-f6fa-4387-9281-a335307cf277',
  UrgencyToBookUUID = '2979101c-6651-4b5c-9e78-54985bcdf955',
  S4CHomebaseHeaderQ12025UUID = 'd5c767aa-3590-4811-9e38-510004191267',
}

export const LatestMatchesArmMapping = {
  CAN_SEE: 'can see past provider result',
  CANNOT_SEE: 'cannot see past provider result',
}

export enum AvailabilityPreferencesV3ArmMapping {
  CAN_SEE = 'can see availability preference',
  CANNOT_SEE = 'cannot see availability preference',
}

export enum UrgencyToBookArmMapping {
  CAN_SEE = 'sees urgency to book v2',
  CANNOT_SEE = 'does not see urgency to book v2',
}

export enum S4CHomebaseHeaderQ12025ArmMapping {
  CONTROL = 'original s4c header: start search button',
  EXPERIMENT = 'new s4c header: primary need selection',
}

export enum MemberPreferencesPageLocation {
  ENTRYPOINT = 'member preferences entrypoint page',
  PREFERENCE_SELECTION = 'member preferences preference selection page',
}

export enum UrgencyToBookV2PageLocation {
  PROVIDER_PROFILE = COMMON_MIXPANEL_PAGE.PROVIDER_PROFILE,
  APPOINTMENT_SELECTION = COMMON_MIXPANEL_PAGE.APPOINTMENT_SELECTION,
}

interface LatestMatchesExperimentJoinKeys {
  client_lyra_id: string
}

type JoinKeys =
  | LatestMatchesExperimentJoinKeys
  | SearchForCareHomebaseHeaderExperimentJoinKeys
  | AvailabilityPreferenceV3ExperimentJoinKeys
  | UrgencyToBookExperimentJoinKeys
  | S4CHomebaseHeaderQ12025JoinKeys

interface Assignment {
  arm: string
  joinKeys: JoinKeys
}

interface Experiment {
  assignment: Assignment[]
}

interface Experiments {
  [key: string]: Experiment
}

export interface ExperimentPayload {
  experimentUUID: string
  deviceUUID: string
  experiments: Experiments
}

export enum S4CHomebaseHeaderQ12025Actions {
  LAND_ON_HOMEBASE = 'land on homebase',
  SELECT_PRIMARY_NEED_FROM_HOMEBASE = 'select primary need from homebase',
  CLICK_PRIMARY_NEED_DROPDOWN_LIST_ON_HOMEBASE = 'click primary need dropdown list on homebase',
  START_SEARCH_FROM_HOMEBASE = 'start search from homebase',
  CLICK_EXPLORE_CARE_OPTIONS_FROM_HOMEBASE = 'click explore care options from homebase',
  CLICK_TOOLKIT = 'click toolkit',
  CLICK_EXPLORE_BENEFITS = 'click explore benefits',
  LAND_ON_TRIAGE = 'land on triage',
  CLICK_CONTINUE_FROM_HOMEBASE = 'click continue from homebase',
  SELECT_PATIENT_FROM_DROPDOWN_LIST_ON_HOMEBASE = 'select patient from dropdown list on homebase',
  CLICK_PATIENT_DROPDOWN_LIST_ON_HOMEBASE = 'click patient dropdown list on homebase',
  APPOINTMENT_BOOKED = 'appointment booked',
  CLICK_QUESTION_MODAL_ON_SEARCH_HEADER = 'click question modal on search header',
  CLICK_FORKED_TRIAGE_IN_SEARCH_HEADER_QUESTION_MODAL = 'click forked triage in search header question modal',
  CLICK_CONTINUE_SEARCH_FOR_SELF_IN_SEARCH_HEADER_QUESTION_MODAL = 'click continue search for self in search header question modal',
}

export enum S4CHomebaseHeaderQ12025HeaderVariants {
  CONTROL = 'control',
  DROPDOWN = 'dropdown',
}
