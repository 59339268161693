import React, { FunctionComponent } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { WellnessCheckInResultsEssentialsArticleContent } from '@lyrahealth-inc/shared-app-logic/'
import { BodyText, BodyTextSize, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { WellnessCheckInResultsEssentialsCard } from '../wellnessCheckInResultsEssentialsCard/WellnessCheckInResultsEssentialsCard'

export interface WellnessCheckInResultsDomainContentProps {
  title: MessageDescriptor
  body: MessageDescriptor
  articleContents?: WellnessCheckInResultsEssentialsArticleContent[]
  onArticlePress: (subPath: string) => void
  isLastContent: boolean
}

const ContentDescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ContentTitleContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing['4px'],
}))

const EssentialsCardContainer = styled(View)<{ theme: ThemeType; isLastCard: boolean }>(({ theme, isLastCard }) => ({
  marginBottom: isLastCard ? '0px' : theme.spacing['12px'],
  width: '100%',
}))

export const WellnessCheckInResultsDomainContent: FunctionComponent<WellnessCheckInResultsDomainContentProps> = ({
  title,
  body,
  articleContents,
  onArticlePress,
  isLastContent,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <ContentTitleContainer text={formatMessage(title)} size={BodyTextSize.DEFAULT} />
      <ContentDescriptionContainer>
        <BodyText size={BodyTextSize.SMALL} text={formatMessage(body, { br: '\n' })} />
      </ContentDescriptionContainer>
      {articleContents &&
        articleContents.length > 0 &&
        articleContents.map((content, index) => (
          <EssentialsCardContainer key={index} isLastCard={isLastContent && index === articleContents.length - 1}>
            <WellnessCheckInResultsEssentialsCard articleContent={content} onArticlePress={onArticlePress} />
          </EssentialsCardContainer>
        ))}
    </>
  )
}
