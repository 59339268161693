import React, { FunctionComponent } from 'react'

import { BodyTextSize, TextType } from '../../styles/typeStyles'
import { BaseText, BaseTextProps } from '../baseText/BaseText'

export const BodyText: FunctionComponent<BodyTextProps> = (props) => (
  <BaseText {...props} size={props.size ?? BodyTextSize.DEFAULT} type={TextType.BODY} />
)
export const Size = BodyTextSize

export interface BodyTextProps extends Omit<BaseTextProps, 'size' | 'type'> {
  size?: BodyTextSize
}
