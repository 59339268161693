import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import { HEADER_NAV_ITEM, headerNavItemData } from '@lyrahealth-inc/shared-app-logic'

import { HeartDottedBorderIcon, MenuIcon } from '../../atoms'
import { IS_WEB } from '../../constants'
import { ThemeType } from '../../utils'
import { Menu, MenuItemProps } from '../menu/Menu'

export interface HeaderNavMenuProps {
  handleBenefitsPress: () => void
  handleAccountPress: () => void
  handleFAQsPress?: () => void
  handleReferralPress?: () => void
  handleLogOutPress: () => void
  handleHomePress?: () => void
  handleCarePress?: () => void
  handleSessionsPress?: () => void
  handleProgressPress?: () => void
  handleEssentialsPress?: () => void
  shouldShowFAQs?: () => boolean
  shouldShowReferral?: () => boolean
  shouldShowEssentials?: () => boolean
  shouldShowHome?: () => boolean
  shouldShowCare?: () => boolean
  shouldShowSessions?: () => boolean
  shouldShowProgress?: () => boolean
  currentPathname?: string
  currentScreen?: string
  trackMenuClick?: () => void
  showAccountBadge?: boolean
  animateAccountBadge?: boolean
  highlightTrigger?: boolean
  pressableTriggerStyle?: (pressed: boolean, focusVisible: boolean) => ViewStyle
}

interface HeaderNavMenuItemProps extends MenuItemProps {
  condition?: () => boolean
  activePathnames?: string[]
  activeScreens?: string[]
}

export const HeaderNavMenu: FunctionComponent<HeaderNavMenuProps> = ({
  handleBenefitsPress,
  handleAccountPress,
  handleFAQsPress = noop,
  handleReferralPress = noop,
  handleLogOutPress,
  handleHomePress = noop,
  handleCarePress = noop,
  handleSessionsPress = noop,
  handleProgressPress = noop,
  handleEssentialsPress = noop,
  shouldShowFAQs = () => true,
  shouldShowReferral,
  shouldShowEssentials,
  shouldShowHome,
  shouldShowCare,
  shouldShowSessions,
  shouldShowProgress,
  currentPathname,
  currentScreen,
  trackMenuClick,
  showAccountBadge = false,
  animateAccountBadge = true,
  highlightTrigger = false,
  pressableTriggerStyle,
}) => {
  const { formatMessage } = useIntl()
  const { colors, breakpoints } = useTheme() as ThemeType
  const [activeMenuItem, setActiveMenuItem] = useState<string | undefined>(undefined)
  const testId = 'HeaderNavMenu'
  const menuStyle = { marginTop: breakpoints.isMinWidthTablet ? 20 : 16 }
  const isLaptopWeb = !breakpoints.isMinWidthLaptop && IS_WEB

  const conditionForLaptopWeb = (condition?: () => boolean) => {
    return () => (condition && condition() && isLaptopWeb) ?? false
  }
  const conditionForWeb = (condition?: () => boolean) => {
    return () => (condition && condition() && IS_WEB) ?? false
  }

  const menuItemsList: HeaderNavMenuItemProps[] = [
    {
      id: HEADER_NAV_ITEM.HOME,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.HOME].label),
      onPressItem: handleHomePress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.HOME].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.HOME].activeScreenNames,
      condition: conditionForLaptopWeb(shouldShowHome),
    },
    {
      id: HEADER_NAV_ITEM.CARE,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.CARE].label),
      onPressItem: handleCarePress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.CARE].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.CARE].activeScreenNames,
      condition: conditionForLaptopWeb(shouldShowCare),
    },
    {
      id: HEADER_NAV_ITEM.SESSIONS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.SESSIONS].label),
      onPressItem: handleSessionsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.SESSIONS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.SESSIONS].activeScreenNames,
      condition: conditionForWeb(shouldShowSessions),
    },
    {
      id: HEADER_NAV_ITEM.PROGRESS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.PROGRESS].label),
      onPressItem: handleProgressPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.PROGRESS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.PROGRESS].activeScreenNames,
      condition: conditionForWeb(shouldShowProgress),
    },
    {
      id: HEADER_NAV_ITEM.ESSENTIALS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].label),
      onPressItem: handleEssentialsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.ESSENTIALS].activeScreenNames,
      condition: conditionForLaptopWeb(shouldShowEssentials),
    },
    {
      id: HEADER_NAV_ITEM.BENEFITS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.BENEFITS].label),
      onPressItem: handleBenefitsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.BENEFITS].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.BENEFITS].activeScreenNames,
      icon: <HeartDottedBorderIcon></HeartDottedBorderIcon>,
    },
    {
      id: HEADER_NAV_ITEM.ACCOUNT,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.ACCOUNT].label),
      onPressItem: handleAccountPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.ACCOUNT].activePathnames,
      activeScreens: headerNavItemData[HEADER_NAV_ITEM.ACCOUNT].activeScreenNames,
      icon: <MenuIcon fillColor={colors.iconDefault}></MenuIcon>,
      showBadge: IS_WEB ? showAccountBadge : undefined,
    },
    {
      id: HEADER_NAV_ITEM.FAQS,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.FAQS].label),
      condition: shouldShowFAQs,
      onPressItem: handleFAQsPress,
      activePathnames: headerNavItemData[HEADER_NAV_ITEM.FAQS].activePathnames,
    },
    {
      id: HEADER_NAV_ITEM.REFERRAL,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.REFERRAL].label),
      onPressItem: handleReferralPress,
      condition: shouldShowReferral,
    },
    {
      id: HEADER_NAV_ITEM.LOG_OUT,
      text: formatMessage(headerNavItemData[HEADER_NAV_ITEM.LOG_OUT].label),
      onPressItem: handleLogOutPress,
    },
  ]
  menuItemsList.forEach((item) => (item.testId = `${testId}-${item.id}`))
  const menuItemsListFiltered = menuItemsList.filter((item) => {
    return (item.condition && item.condition()) ?? true
  })

  useEffect(() => {
    const foundActivePath = menuItemsListFiltered.filter((item) => {
      if (currentPathname) {
        return (
          item.activePathnames &&
          item.activePathnames.some((activePath) => {
            return currentPathname.includes(activePath)
          })
        )
      }
      if (currentScreen) {
        return (
          item.activeScreens &&
          item.activeScreens.some((activePath) => {
            return currentScreen.includes(activePath)
          })
        )
      }
      return false
    })

    let activeItemText = ''
    if (foundActivePath.length > 0) {
      // Find longest match (eg being on /search-care/benefits should not match for the homepage)
      activeItemText = foundActivePath.reduce((max: HeaderNavMenuItemProps, item: HeaderNavMenuItemProps) => {
        return item.text.length > max.text.length ? item : max
      }, foundActivePath[0]).text
    }

    setActiveMenuItem(activeItemText)
  }, [currentPathname, currentScreen, menuItemsListFiltered])

  return (
    <Menu
      menuItems={menuItemsListFiltered}
      testID={testId}
      horizontalPosition='right'
      dropdownStyle={menuStyle}
      preselectedValue={activeMenuItem}
      showIcons={!IS_WEB}
      disableBottomSheetForWeb
      trackMenuClick={trackMenuClick}
      showMenuBadge={showAccountBadge}
      animateBadge={animateAccountBadge}
      highlightTrigger={highlightTrigger}
      pressableTriggerStyle={pressableTriggerStyle}
    />
  )
}
