import { Map } from 'immutable'
import { createSelector } from 'reselect'

const getWellnessCheckInState = (state: Map<string, any>) => state?.get('wellnessCheckIn')

export const getWellnessCheckInEntryPoint = createSelector(getWellnessCheckInState, (wellnessCheckInState) =>
  wellnessCheckInState?.get('wellnessCheckInEntryPoint'),
)

export const getWellnessCheckInForm = createSelector(getWellnessCheckInState, (wellnessCheckInState) =>
  wellnessCheckInState?.get('wellnessCheckInForm'),
)

export const getWellnessCheckInStateResults = createSelector(getWellnessCheckInState, (wellnessCheckInState) =>
  wellnessCheckInState?.get('wellnessCheckInResults'),
)

export const getShouldSaveWellnessCheckInPostLogin = createSelector(getWellnessCheckInState, (wellnessCheckInState) =>
  wellnessCheckInState?.get('shouldSaveWellnessCheckInPostLogin'),
)
