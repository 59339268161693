import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { SetURLSearchParams } from 'react-router-dom'

import {
  CMS_MICROSITE_MESSAGE,
  COMMON_MIXPANEL_PROPERTIES,
  EssentialsContentData,
  getMicrositePathwayKey,
  PathwayOptions,
  useGetCMSMicrositeMessageHandlers as useGetCMSMicrositeMessageHandlersShared,
} from '@lyrahealth-inc/shared-app-logic'

import { useAppDispatch } from '../../../data/storeConfiguration/store'
import { getIsUserLoggedIn } from '../../../data/user/userSelectors'
import { PAGE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'
import { setSelectedPathwayOption } from '../../../features/register/data/registerActions'
import { useOpenUrl } from '../../../hooks/useOpenUrl'
import { useNavigateToEssentialsContent } from '../useNavigateToEssentialsContent'

/**
 * Get post message event handlers based on the cms microsite's metadata
 */
export const useGetCMSMicrositeMessageHandlers = ({
  eventsList,
  page,
  searchParams,
  setSearchParams,
  setIsShowingRegistrationModal,
  micrositeId,
}: {
  eventsList: { key: CMS_MICROSITE_MESSAGE; params?: Dict }[]
  page: string
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  setIsShowingRegistrationModal: React.Dispatch<React.SetStateAction<boolean>>
  micrositeId: string
}): { [key in CMS_MICROSITE_MESSAGE]?: () => void } => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const openUrl = useOpenUrl()
  const navigateToEssentialsContent = useNavigateToEssentialsContent()

  const registrationEntryPointState = useMemo(() => {
    return {
      state: { registrationEntryPoint: page },
    }
  }, [page])

  const navigateToCareOptions = useCallback(() => {
    navigate(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE)
  }, [navigate])

  const navigateToWellnessCheckIn = useCallback(() => {
    navigate(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)
  }, [navigate])

  const navigateToEssentials = useCallback(() => {
    navigate(PAGE_ROUTES.ESSENTIALS)
  }, [navigate])

  const navigateToEssentialsHomepageWidget = useCallback(() => {
    navigate(PAGE_ROUTES.PATHWAYS.ESSENTIALS_HOMEPAGE_WIDGET, registrationEntryPointState)
    dispatch(setSelectedPathwayOption(PathwayOptions.ESSENTIALS))
  }, [dispatch, navigate, registrationEntryPointState])

  const navigateToWebinar = useCallback(
    ({ webinarLink }: { webinarLink: string }) => {
      navigate(webinarLink)
    },
    [navigate],
  )

  const showRegistrationModal = useCallback(
    (messageKey: CMS_MICROSITE_MESSAGE) => {
      // Since modal opens on the toolkit, 'page' defaults to the toolkit but 'entryPoint' needs to be set
      const newParams = new URLSearchParams(searchParams)
      newParams.set(COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT, page)
      setSearchParams(newParams)

      setIsShowingRegistrationModal(true)
      dispatch(setSelectedPathwayOption(getMicrositePathwayKey(micrositeId, messageKey)))
    },
    [dispatch, micrositeId, page, searchParams, setIsShowingRegistrationModal, setSearchParams],
  )

  const navigateToRegistration = useCallback(() => {
    navigate('/register/connect', registrationEntryPointState)
  }, [navigate, registrationEntryPointState])

  const clickSelfCareContent = useCallback(
    ({ contentData, contentSlug }: { contentData?: EssentialsContentData; contentSlug?: string }) => {
      const slug = contentData?.slug || contentSlug
      if (slug) {
        navigateToEssentialsContent({ slug, newWindow: true })
      }
    },
    [navigateToEssentialsContent],
  )

  return useGetCMSMicrositeMessageHandlersShared({
    eventsList,
    isLoggedIn,
    navigateToCareOptions,
    navigateToWellnessCheckIn,
    navigateToEssentials,
    navigateToEssentialsHomepageWidget,
    navigateToWebinar,
    navigateToRegistration,
    showRegistrationModal,
    clickSelfCareContent,
    openExternalLink: ({ link }) => openUrl(link),
  })
}
