import React, { FunctionComponent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import { includes, uniq, without } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { WordCloudCategory, WordCloudCategoryName } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../../src/utils'
import { ButtonModifier } from '../../atoms/baseButton/BaseButton'
import { BaseInput } from '../../atoms/formElements/BaseInput'
import { EmotionsFeelingsIcon } from '../../atoms/icons/EmotionsFeelingsIcon'
import { SourcesStressIcon } from '../../atoms/icons/SourcesStressIcon'
import { ThoughtsBehaviorsIcon } from '../../atoms/icons/ThoughtsBehaviorsIcon'
import { Size, Subhead } from '../../atoms/subhead/Subhead'
import { WordCloudButton as WordCloudButtonComponent } from '../../atoms/wordCloudButton/WordCloudButton'

import type * as CSS from 'csstype'

type WordCloudValue = Array<string>

export interface WordCloudProps {
  label?: string
  subLabel?: string
  labelAlignment?: CSS.Properties['alignItems']
  largeSubLabel?: boolean
  value: WordCloudValue
  onChange: (arg: WordCloudValue) => void
  error?: string
  name?: string
  options: WordCloudCategory[] | Array<string>
  showCategories?: boolean
  modifier?: ButtonModifier
}

interface CategorizedWordCloudProps {
  value: WordCloudValue
  onPress: (optionVal: string) => void
  name?: string
  options: WordCloudCategory[]
  showCategories?: boolean
}

interface BasicWordCloudProps {
  value: WordCloudValue
  onPress: (optionVal: string) => void
  name?: string
  options: Array<string>
  modifier?: ButtonModifier
}

const WordCloudContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  // use negative margin to offset margin on last button in row
  marginRight: theme.breakpoints.isMobileSized ? '-8px' : '-10px',
}))

const WordCloudButton = styled(WordCloudButtonComponent)(({ theme }) => ({
  margin: theme.breakpoints.isMobileSized ? '0 8px 8px 0' : '0 10px 10px 0',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
}))

const CategoryContainer = styled.View<{ isLastCategory?: boolean }>(({ theme, isLastCategory }) => ({
  // theme spacing minus 8px to account for button margin
  marginBottom: '-8px',
  ...(!isLastCategory && { marginBottom: theme.breakpoints.isMobileSized ? `${48 - 8}px` : `${64 - 8}px` }),
}))

const CategorySubheadContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing['12px'],
}))

const CategoryIconContainer = styled.View(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const categoryIconMap = {
  [WordCloudCategoryName.EMOTIONS_FEELINGS]: EmotionsFeelingsIcon,
  [WordCloudCategoryName.THOUGHTS_BEHAVIORS]: ThoughtsBehaviorsIcon,
  [WordCloudCategoryName.SOURCES_STRESS]: SourcesStressIcon,
}

export const WordCloud: FunctionComponent<WordCloudProps> = ({
  label,
  subLabel,
  labelAlignment,
  largeSubLabel,
  value,
  onChange,
  error,
  name,
  options,
  showCategories,
  modifier,
}) => {
  const handleWordCloudButtonPress = (optionVal: string) => {
    const wasSelected = includes(value, optionVal)
    const newValue = wasSelected ? without(value, optionVal) : uniq([...value, optionVal])
    onChange(newValue)
  }

  const displayBasicWordCloud = options.every((option) => typeof option === 'string')

  return (
    <BaseInput
      label={label}
      subLabel={subLabel}
      labelAlignment={labelAlignment}
      largeSubLabel={largeSubLabel}
      error={error}
      name={name}
    >
      {displayBasicWordCloud ? (
        <BasicWordCloud
          onPress={handleWordCloudButtonPress}
          value={value}
          name={name}
          options={options as Array<string>}
          modifier={modifier}
        />
      ) : (
        <CategorizedWordCloud
          onPress={handleWordCloudButtonPress}
          value={value}
          name={name}
          options={options as WordCloudCategory[]}
          showCategories={showCategories}
        />
      )}
    </BaseInput>
  )
}

const CategorizedWordCloud = ({ onPress, value, name, options, showCategories }: CategorizedWordCloudProps) => {
  const { colors } = useTheme()

  // If showCategories is disabled, group all elements into a single category without a title
  const allWords = options.reduce(
    (acc: Array<MessageDescriptor>, value: WordCloudCategory) => acc.concat(value.word_cloud_elements),
    [],
  )
  const singleCategoryOptions = [
    { word_cloud_elements: allWords, word_cloud_category_display_title: null, word_cloud_category_name: '' },
  ]
  // When showCategories is enabled hide uncategorized words
  const filteredOptions = options.filter(
    (category) => category.word_cloud_category_name !== WordCloudCategoryName.UNCATEGORIZED,
  )
  const categories = showCategories ? filteredOptions : singleCategoryOptions

  return (
    <>
      {categories.map(({ word_cloud_category_display_title, word_cloud_elements, word_cloud_category_name }, index) => {
        const isLastCategory = index === categories.length - 1
        const SelectedIcon = categoryIconMap[word_cloud_category_name]
        return (
          <CategoryContainer
            key={word_cloud_category_name}
            isLastCategory={isLastCategory}
            testID={tID(`categoryContainer-${word_cloud_category_name}`)}
          >
            {word_cloud_category_display_title && (
              <CategorySubheadContainer>
                {!!SelectedIcon && (
                  <CategoryIconContainer>
                    <SelectedIcon />
                  </CategoryIconContainer>
                )}
                <Subhead
                  text={word_cloud_category_display_title.defaultMessage}
                  key={word_cloud_category_display_title.id}
                  size={Size.XSMALL}
                  color={colors.textSecondary}
                  testID={tID(`categoryHeader-${word_cloud_category_name}`)}
                  level='2'
                />
              </CategorySubheadContainer>
            )}
            <WordCloudContainer>
              {word_cloud_elements.map((messageDescription: MessageDescriptor) => {
                const { defaultMessage } = messageDescription
                return (
                  <WordCloudButton
                    key={defaultMessage as string}
                    testID={tID(`${name}_${defaultMessage}`)}
                    isSelected={includes(value, defaultMessage)}
                    onPress={() => onPress(defaultMessage as string)}
                    text={<FormattedMessage {...messageDescription} />}
                  />
                )
              })}
            </WordCloudContainer>
          </CategoryContainer>
        )
      })}
    </>
  )
}

const BasicWordCloud = ({ onPress, value, name, options, modifier }: BasicWordCloudProps) => {
  return (
    <WordCloudContainer testID={tID('WordCloud')}>
      {options?.map((optionVal, idx) => {
        return (
          <WordCloudButton
            key={idx}
            testID={tID(`${name}_${idx}`)}
            isSelected={includes(value, optionVal)}
            onPress={() => onPress(optionVal)}
            text={optionVal}
            modifier={modifier}
          />
        )
      })}
    </WordCloudContainer>
  )
}

export const WordCloudRFF: FunctionComponent<FieldRenderProps<WordCloudValue>> = (props) => {
  const {
    input: { value, onChange, name },
    meta: { touched, error },
    label,
    subLabel,
    labelAlignment,
    largeSubLabel,
    options,
    showCategories,
    modifier,
  } = props
  return (
    <WordCloud
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      subLabel={subLabel}
      labelAlignment={labelAlignment}
      largeSubLabel={largeSubLabel}
      error={touched && error}
      options={options}
      showCategories={showCategories}
      modifier={modifier}
    />
  )
}
