import { useCallback, useEffect, useRef, useState } from 'react'

import useHandleMessage from './useHandleMessage'
import useSendMessage from './useSendMessage'

interface InitMessage {
  type: 'INIT'
}

interface SetStateMessage<T> {
  type: 'SET_STATE'
  payload: T
}

interface InitResponseMessage<T> {
  type: 'INIT_RESPONSE'
  payload: T
}
type Message<T> = InitMessage | SetStateMessage<T> | InitResponseMessage<T>

const useLDChromeExtension = <T>(name: string, initialState?: T) => {
  const [state, setStateInner] = useState<T | undefined>(initialState)
  const isInitialized = useRef(false)
  const sendMessage = useSendMessage()

  useEffect(() => {
    if (!isInitialized.current) {
      sendMessage({ name, type: 'INIT' })
    }
  }, [name, sendMessage])

  const setState = useCallback(
    (newState: T) => {
      setStateInner(newState)
      sendMessage({ name, type: 'SET_STATE', payload: newState })
    },
    [name, sendMessage],
  )

  useHandleMessage<T>((genericMessage) => {
    if (genericMessage.name !== name) {
      return
    }
    const message = genericMessage as Message<T>
    switch (message.type) {
      case 'INIT':
        sendMessage({ name, type: 'INIT_RESPONSE', payload: state })
        break
      case 'INIT_RESPONSE':
        isInitialized.current = true
        if (message.payload) {
          setStateInner(message.payload)
        }
        break
      case 'SET_STATE':
        setStateInner(message.payload)
        break
      default:
        break
    }
  })
  return [state, setState] as const
}

export default useLDChromeExtension
