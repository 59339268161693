// List of content slugs that can be viewed in preview mode by a user that is not authenticated
export const ALLOWABLE_ESSENTIALS_UNGATED_CONTENT = new Set([
  'when-to-consider-seeking-care-from-a-mental-health-professional',
  'how-to-assess-how-youre-really-doing',
  'sleep-101-how-sleep-works-how-to-sleep-better',
  'stress-101-causes-symptoms-and-coping',
  'coping-with-financial-anxiety',
  'fields',
  'finding-balance-in-the-present',
  'balance-circle',
  'summer-rain',
  'burnout-101-symptoms-and-coping',
  'understanding-and-celebrating-neurodiversity',
  'brown-noise',
  'create-this-really-beautiful-new-habit',
  'how-burnout-feels',
  'on-the-spot-meditation-smiling',
  'coping-with-election-and-political-anxiety',
  'navigating-politics-with-co-workers-during-elections-and-beyond',
  'managing-teams-through-elections-and-political-stress',
  'managing-distress-related-to-stressful-world-events',
  'seeking-understanding-in-difficult-conversations',
  'understanding-autism-signs-strengths-diagnosis-and-treatment',
  'adhd-explained-recognizing-symptoms-strengths-and-support',
  'how-to-help-someone-in-need-get-mental-health-care',
  'what-to-expect-from-mental-health-care-first-session-and-beyond',
  'how-to-work-with-mental-health-in-mind',
  'calming-your-inner-critic',
  'awakening-your-heart',
])

export const isURLPathForEssentialsPreviewContent = (): boolean => {
  const contentSlug = window.location.pathname.split('/').pop()

  if (contentSlug) {
    return ALLOWABLE_ESSENTIALS_UNGATED_CONTENT.has(contentSlug)
  } else {
    return false
  }
}
