import { AxiosRequestConfig } from 'axios'
import uuid from 'uuid'
const { v4: uuidv4 } = uuid

const EXCLUDE_CONTEXT_UUID_HEADER_URL_REGEX = /^https:\/\/vendorapi.*\.lyrahealth\.com/

export const API_REQUEST_HEADERS = {
  // Context UUID sent to our APIs
  X_CONTEXT_UUID: 'X-Context-UUID',
  // LD Flags
  X_FEATURES: 'X-Features',
  // Whether the health kit is enabled
  X_IS_HEALTH_KIT_ENABLED: 'X-Lyra-is-health-kit-enabled',
  // Needed for LWAPI controller to handle proxying correctly
  X_IS_MOBILE_APP: 'X-Is-Mobile-App',
  // The user's display language
  X_LYRA_LOCALE: 'X-Lyra-Locale',
}

export function setContextUUIDHeader(config: AxiosRequestConfig) {
  if (config.url && !EXCLUDE_CONTEXT_UUID_HEADER_URL_REGEX.test(config.url) && config.headers) {
    config.headers[API_REQUEST_HEADERS.X_CONTEXT_UUID] = uuidv4()
  }
}
