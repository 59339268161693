import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import {
  CostMessagingModalContent,
  CostMessagingModalCoverageInfo,
  SessionLimitByProgram,
} from '@lyrahealth-inc/shared-app-logic'

import { SecondaryButton } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { CoverageMessagingModal } from '../coverageMessagingModal/CoverageMessagingModal'

export interface CoverageMessagingButtonProps {
  coverageModalContents: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  customerName?: string
  numSessions?: string
  numberOfSessionByProgram?: SessionLimitByProgram
  isProgramLevelSessionLimitEnabled?: boolean
  displayVisitsPerIssuePerYear?: number
}

export const CoverageMessagingButton: FunctionComponent<CoverageMessagingButtonProps> = ({
  coverageModalContents = [],
  costMessagingModalCoverageInfo = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  customerName,
  numSessions,
  numberOfSessionByProgram,
  isProgramLevelSessionLimitEnabled,
  displayVisitsPerIssuePerYear,
}) => {
  const { formatMessage } = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { breakpoints } = useTheme() as ThemeType
  const isMobileSized = breakpoints.isMobileSized
  const showCoverageModal =
    isCostMessagingEnabled &&
    costMessagingModalCoverageInfo &&
    costMessagingModalCoverageInfo?.customerCoverageType &&
    coverageModalContents &&
    coverageModalContents.length > 0

  if (!showCoverageModal) {
    return <></>
  }

  return (
    <>
      <SecondaryButton
        text={formatMessage({
          defaultMessage: 'Understand your coverage',
          description: "Link to open a modal which explains the member's care coverage",
        })}
        onPress={() => {
          trackCoverageModalPress(true)
          setIsModalOpen(true)
        }}
        testID={tID('CoverageMessagingButton')}
        customTextSize={!isMobileSized ? BodyTextSize.LARGE : undefined}
        style={{ ...(!isMobileSized ? { height: 60 } : {}), width: 290 }}
      />
      <CoverageMessagingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={(isOpen) => setIsModalOpen(isOpen)}
        coverageModalContents={coverageModalContents}
        costMessagingModalCoverageInfo={costMessagingModalCoverageInfo}
        trackCoverageModalPress={trackCoverageModalPress}
        customerName={customerName}
        numSessions={numSessions}
        numberOfSessionByProgram={numberOfSessionByProgram}
        isProgramLevelSessionLimitEnabled={isProgramLevelSessionLimitEnabled}
        displayVisitsPerIssuePerYear={displayVisitsPerIssuePerYear}
      />
    </>
  )
}
