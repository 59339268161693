import React, { FunctionComponent, KeyboardEvent, ReactElement, ReactNode, useCallback, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { LayoutChangeEvent, TextStyle, View, ViewStyle } from 'react-native'

import { includes, noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { KEYS, RadioButtonType, TitleInfoPopover } from '@lyrahealth-inc/shared-app-logic'

import { FieldBadge } from './FieldBadge'
import { getRadioButtonStyles } from './styles/radioButtonStyles'
import { AccessibilityRolesNative, breakpoints, IS_WEB } from '../../constants'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { UICoreFormContext } from '../../organisms/formBody/types'
import { BodyTextSize, StyledText, TextType } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { BodyText, Size } from '../bodyText/BodyText'
import { BaseInput } from '../formElements/BaseInput'
import { SessionCompletedCheck } from '../icons'
import { CheckIcon } from '../icons/CheckIcon'
import { XIconRound } from '../icons/XIconRound'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'
import { Subhead, Size as SubheadSize } from '../subhead/Subhead'
import { ToolTipTriggerConfig } from '../tooltip/Tooltip'

import type * as CSS from 'csstype'

export type RadioGroupValue = number | string | boolean | null

type NestedComponentProps = {
  baseInputStyle?: ViewStyle
  error?: string
  value?: string | number | Date | boolean
  time?: string | Date
  label?: string
  options?: Array<{ label: string; value: string | number }>
  onBlur?: () => void
  onChange?: (value: any) => void
  onChangeAMorPM?: (value: any) => void
  onFocus?: () => void
  style?: ViewStyle
  defaultAMorPM?: string
  onError?: (value: any) => void
  name?: string
}

export type RadioButtonProps = {
  title: ReactNode
  description?: ReactNode
  badge?: ReactNode
  selected: boolean
  ImageComponent?: React.ComponentType<ImageComponentProps>
  value: RadioGroupValue
  onChange: (value: RadioGroupValue) => void
  readOnly?: boolean
  highlight?: boolean
  error?: string
  onFocus?: () => void
  focusable?: boolean
  onBlur?: () => void
  isYesNo?: boolean
  buttonType?: RadioButtonType
  parentContainerWidth: number
  gridButtonsGap: number
  widthWrapToContent?: boolean
  buttonStyle?: ViewStyle
  size?: RadioButtonSize
  NestedTimeField?: React.ComponentType<any>
  nestedTimeFieldProps?: NestedComponentProps
  NestedSelectField?: React.ComponentType<any>
  nestedSelectFieldProps?: NestedComponentProps
  selectedCheckmark?: boolean
  DecorativeIcon?: React.ComponentType<any>
  textColor?: string
  disabled?: boolean
  disableBadgeHeadline?: boolean
}

export type RadioGroupProps = {
  label?: string
  labelInfoPopover?: TitleInfoPopover
  subLabel?: string
  subLabelComponent?: string
  labelSecondary?: string
  largeSubLabel?: boolean
  labelAlignment?: CSS.Properties['alignItems']
  selectedValue?: RadioGroupValue
  name?: string
  error?: string
  buttons: Array<{
    label: string
    value: RadioGroupValue
    highlight?: boolean
    description?: ReactNode
    badge?: ReactNode
    inactive?: boolean
    imageComponent?: React.ComponentType<ImageComponentProps>
    schema?: any
    nestedTimeField?: React.ComponentType<any>
    nestedTimeFieldProps?: NestedComponentProps
    nestedSelectField?: React.ComponentType<any>
    nestedSelectFieldProps?: NestedComponentProps
    decorativeIcon?: React.ComponentType<any>
    selected?: boolean
    textColor?: string
    disableBadgeHeadline?: boolean
  }>
  onChange: (value: RadioGroupValue) => void
  readOnly?: boolean
  showDescription?: boolean
  correctValueDetails?: string
  correctValue?: RadioGroupValue
  onFocus?: () => void
  onBlur?: () => void
  isYesNo?: boolean
  buttonType?: RadioButtonType
  toolTipContent?: string | ReactElement
  toolTipTriggerConfig?: ToolTipTriggerConfig
  active?: boolean
  accessibilityLabelledBy?: string
  widthWrapToContent?: boolean
  parentContainerWidth?: number
  baseInputStyle?: ViewStyle
  buttonStyle?: ViewStyle
  contextProps?: UICoreFormContext
  size?: RadioButtonSize
  selectedCheckmark?: boolean
  disabled?: boolean
  minLabel?: string
  maxLabel?: string
  customTextStyle?: TextStyle
  labelContainerStyle?: ViewStyle
  isRadioButtonsFlexRowDirection?: boolean
}

export type GetButtonStylesArgs = {
  buttonType: RadioButtonType
  hovered?: boolean
  readOnly?: boolean
  selected?: boolean
  highlight?: boolean
  description?: string
  selectedDescriptionReadOnly?: boolean
}

export type ImageComponentProps = {
  width?: number
  height?: number
  selected?: boolean
}

export enum RadioButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

const CorrectDescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  backgroundColor: colors.backgroundSection,
  padding: `${spacing['12px']} ${spacing['16px']}`,
  flexDirection: 'column',
  borderRadius: '8px',
  marginTop: spacing['4px'],
}))

const TopCorrectDescriptionContainer = styled.View({
  flexDirection: 'row',
})

const BottomCorrectDescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['40px'],
}))

const CorrectDescriptionLabel = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.spacing['4px'],
}))

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingRight: theme.spacing['12px'],
}))

const RadioButtonsContainer = styled.View<{
  buttonType: RadioButtonType
  flexRowDirection?: boolean
  gridButtonsGap: number
}>(({ buttonType, flexRowDirection, gridButtonsGap }) => ({
  ...(buttonType === RadioButtonType.GRID && {
    margin: `-${gridButtonsGap / 2}px`,
    alignSelf: 'center',
  }),
  ...(buttonType === RadioButtonType.CIRCULAR_WITH_NUMBER && {
    justifyContent: 'space-between',
  }),
  ...(flexRowDirection && {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }),
}))

const RadioButtonContainer = styled(PressableOpacity)<{
  buttonType: RadioButtonType
  gridButtonsGap: number
  parentContainerWidth: number
  isYesNo?: boolean
  widthWrapToContent?: boolean
}>`
  flex-direction: row;
  ${({ buttonType, parentContainerWidth, isYesNo, gridButtonsGap, widthWrapToContent }) => {
    if (isYesNo) {
      if (buttonType === RadioButtonType.TEXT_OUTLINE) {
        return `
          width: 64px;
          justify-content: center;
          margin-right: 8px;
        `
      } else if (buttonType === RadioButtonType.CIRCLE_TEXT_OUTLINE) {
        return `
          min-width: 107.5px;
          justify-content: center;
          margin-right: 16px;
        `
      }
    } else if (widthWrapToContent) {
      return `
        align-self: flex-start;
      `
    } else {
      if (buttonType === RadioButtonType.GRID) {
        const width = getRadioButtonWidth(parentContainerWidth, gridButtonsGap)
        return `
          width: ${width}px;
          margin: ${gridButtonsGap / 2}px;
        `
      } else if (buttonType === RadioButtonType.CIRCULAR_WITH_NUMBER) {
        return `
          align-items: center;
        `
      }
    }
    return ``
  }}
`

const RadioButtonFocused = styled(View)<{ buttonType: RadioButtonType; focused: boolean; image: boolean }>(
  ({ image, focused, buttonType, theme }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    border: focused ? `1px solid ${theme.colors.radioOutlineFocused}` : 'none',
    ...(!image && { marginRight: '16px' }),
    ...(buttonType === RadioButtonType.GRID && { position: 'absolute', left: '16px', top: '16px' }),
    ...(buttonType === RadioButtonType.CIRCLE_TEXT_OUTLINE && { marginRight: '12px' }),
  }),
)

const RadioButtonCircle = styled(View)<{
  theme: ThemeType
  selected: boolean
  focused: boolean
  hovered: boolean
  readOnly?: boolean
  error?: string
  buttonType?: RadioButtonType
}>`
  ${({ theme }) => theme && `background-color: ${theme.colors.radioBackgroundPrimary}`};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: auto;
  ${({ theme, selected, readOnly, hovered, error, buttonType }) => {
    if (error) {
      return `border: 1px solid ${theme.colors.borderError};`
    }
    if (hovered && buttonType !== RadioButtonType.GRID) {
      return `border: 1px solid ${theme.colors.borderHover};`
    }
    return `border: 1px solid ${
      selected
        ? readOnly
          ? theme.colors.radioOutlineSelectedInactive
          : theme.colors.radioOutlineSelected
        : readOnly
        ? theme.colors.radioOutlineInactive
        : theme.colors.radioOutlineDefault
    };`
  }}
`

const RadioButtonFilledIn = styled(View)<{ theme: ThemeType; readOnly?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: auto;
  ${({ theme, readOnly }) =>
    `background-color: ${
      readOnly ? theme.colors.radioBackgroundSelectedInactive : theme.colors.radioBackgroundSelected
    };`}
`

const RadioButtonContent = styled(View)<{ buttonType: RadioButtonType }>`
  flex: 1;
  ${({ buttonType }) => {
    switch (buttonType) {
      case RadioButtonType.CIRCLE_TEXT_OUTLINE:
        return `
          flex-direction: row;
          flex-basis: auto;
          align-items: center;
        `
      case RadioButtonType.GRID:
        return `
          flex-shrink: 0;
          flex-direction: column;
          align-items: center;
          margin: auto;
        `
      default:
        return ``
    }
  }}
`

const RadioButtonImageContainer = styled(View)<{ buttonType: RadioButtonType; size?: RadioButtonSize }>(
  ({ theme: { spacing }, buttonType, size }) => {
    const imageSize = size === RadioButtonSize.SMALL ? '40px' : '48px'
    return {
      position: 'relative',
      height: imageSize,
      width: imageSize,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: spacing['8px'],
      marginRight: spacing['8px'],
      ...(buttonType === RadioButtonType.GRID && { marginBottom: '8px' }),
    }
  },
)

const RadioButtonImageTransition = styled(View)<{ scaled?: boolean }>(({ scaled }) => ({
  ...(IS_WEB && { transition: 'transform 0.3s' }),
  position: 'absolute',
  height: '100%',
  width: '100%',
  transform: scaled ? `scale(${53 / 48})` : 'scale(1)',
}))

const RadioButtonText = styled(StyledText)<{
  theme: ThemeType
  buttonType: RadioButtonType
  selected?: boolean
  highlight?: boolean
  readOnly?: boolean
  error?: string
  badge?: boolean
  textColor?: string
}>`
  ${({ badge }) => badge && `padding-right: 8px;`}
  ${IS_WEB ? 'flex-grow: 1' : 'flex-shrink: 1'};
  ${({ theme, buttonType, selected, highlight, readOnly, error, textColor }) => {
    switch (buttonType) {
      case RadioButtonType.CIRCLE_TEXT_OUTLINE:
        if (highlight) {
          return `color: ${theme.colors.radioWithOutlinePrimaryTextHighlight};`
        }
        return `
          color: ${
            selected
              ? theme.colors.radioWithOutlinePrimaryTextSelected
              : readOnly
              ? theme.colors.radioWithOutlinePrimaryTextInactive
              : theme.colors.radioWithOutlineSecondaryTextDefault
          };
        `
      case RadioButtonType.DESCRIPTION_OUTLINE:
      case RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE:
        if (highlight) {
          return `color: ${theme.colors.radioWithOutlinePrimaryTextHighlight};`
        }
        return `
            color: ${
              selected
                ? theme.colors.radioWithOutlinePrimaryTextSelected
                : theme.colors.radioWithOutlinePrimaryTextDefault
            };
          `
      case RadioButtonType.DESCRIPTION_NO_OUTLINE:
        if (error) {
          return `color: ${theme.colors.textError};`
        }
        return `
          color: ${
            readOnly
              ? theme.colors.radioWithOutlinePrimaryTextInactive
              : theme.colors.radioWithOutlinePrimaryTextDefault
          };
        `
      case RadioButtonType.GRID:
        return `
          flex-shrink: 0;
          align-self: center;
          text-align: center;
          color: ${selected ? theme.colors.radioTextSelected : theme.colors.radioTextDefault};
          `
      case RadioButtonType.CIRCULAR_WITH_NUMBER:
        return `
              align-self: center;
            `
      default:
        if (highlight) {
          return `color: ${selected ? theme.colors.radioTextSelected : theme.colors.radioTextHighlight};`
        } else if (textColor) {
          return `color: ${textColor}`
        } else if (selected) {
          return `color: ${theme.colors.radioTextSelected};`
        }
        return `color: ${theme.colors.radioTextDefault};`
    }
  }};
`

const RadioButtonDescription = styled(StyledText)<{
  theme: ThemeType
  readOnly?: boolean
  buttonType: RadioButtonType
  selected: boolean
}>`
  ${({ theme, readOnly, buttonType, selected }) => {
    if (
      buttonType === RadioButtonType.DESCRIPTION_OUTLINE ||
      buttonType === RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE
    ) {
      if (readOnly) {
        return `color: ${
          selected
            ? theme.colors.radioWithOutlineSecondaryTextSelected
            : theme.colors.radioWithOutlineSecondaryTextDefault
        };`
      }
      if (selected) {
        return `color: ${theme.colors.radioWithOutlineSecondaryTextSelected};`
      }
    }
    return `color: ${
      readOnly ? theme.colors.radioWithOutlineSecondaryTextInactive : theme.colors.radioWithOutlineSecondaryTextDefault
    };`
  }}
`

const HeadingContainer = styled(View)<{ useSelectedCheckmark?: boolean }>(({ useSelectedCheckmark }) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexDirection: useSelectedCheckmark ? 'row' : 'column',
  alignItems: 'flex-start',
}))

const DecorativeIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const NestedComponentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const SessionCompletedCheckContainer = styled.View({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 0,
  alignSelf: 'flex-end',
  justifyContent: 'flex-end',
})

const MinMaxLabelsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing['4px'],
}))

export const getRadioButtonWidth = (parentContainerWidth: number, gridButtonsGap: number) => {
  const itemsPerRow = parentContainerWidth > breakpoints.mobileS ? 3 : parentContainerWidth > 300 ? 2 : 1
  const totalGapSize = itemsPerRow * gridButtonsGap
  const buttonWidth = (parentContainerWidth - totalGapSize) / itemsPerRow
  return buttonWidth
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  title,
  description,
  badge,
  ImageComponent,
  selected,
  value,
  onChange,
  readOnly,
  highlight,
  error,
  onFocus = noop,
  focusable,
  onBlur = noop,
  isYesNo = false,
  buttonType = RadioButtonType.TEXT_OUTLINE,
  parentContainerWidth,
  gridButtonsGap,
  widthWrapToContent,
  buttonStyle = {},
  size = RadioButtonSize.DEFAULT,
  NestedTimeField,
  nestedTimeFieldProps,
  NestedSelectField,
  nestedSelectFieldProps,
  selectedCheckmark,
  DecorativeIcon,
  textColor,
  disabled,
  disableBadgeHeadline,
}) => {
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  parentContainerWidth = Math.floor(parentContainerWidth)
  const [focusVisible, setFocusVisible] = useState(false)
  const focusVisibleCallback = useCallback(
    (focus) => {
      if (buttonType === RadioButtonType.DESCRIPTION_NO_OUTLINE) setFocusVisible(focus)
    },
    [buttonType],
  )
  const [hoverVisible, setHoverVisible] = useState(false)

  const getButtonStyles = ({
    buttonType,
    hovered,
    readOnly,
    selected,
    highlight,
    selectedDescriptionReadOnly,
  }: GetButtonStylesArgs) => {
    let state = 'active'
    if (hovered) state = 'hovered'
    if (readOnly) state = 'readOnly'
    if (highlight) state = 'highlight'
    if (selected) state = 'selected'
    if (selectedDescriptionReadOnly) state = 'selectedDescriptionReadOnly' // temp state till the new redesign for q5
    const radioButtonStyles = getRadioButtonStyles(colors, isMobileSized)
    const styles = {
      ...radioButtonStyles[buttonType].base,
      ...radioButtonStyles[buttonType].sizes[size],
      ...radioButtonStyles[buttonType].states[state],
      ...buttonStyle,
    }
    return styles
  }

  const showCircle =
    buttonType === RadioButtonType.CIRCLE_TEXT_OUTLINE ||
    buttonType === RadioButtonType.DESCRIPTION_NO_OUTLINE ||
    buttonType === RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE ||
    buttonType === RadioButtonType.GRID
  const selectedDescriptionReadOnly =
    (buttonType === RadioButtonType.DESCRIPTION_OUTLINE || buttonType === RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE) &&
    selected &&
    readOnly
  return (
    <RadioButtonContainer
      onPress={() => onChange(value)}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={readOnly || disabled}
      hitSlop={{ top: 5, right: 24, bottom: 5, left: 24 }}
      accessibilityRole='radio'
      accessibilityChecked={selected}
      accessibilityState={{ checked: selected }}
      accessibilityDisabled={readOnly}
      focusable={focusable || selected}
      showFocusIndicator={buttonType !== RadioButtonType.DESCRIPTION_NO_OUTLINE}
      getFocusVisible={focusVisibleCallback}
      getHoverVisible={(hover) => setHoverVisible(hover)}
      testID={tID(`RadioButton-${value}`)}
      style={getButtonStyles({
        buttonType,
        selected,
        hovered: hoverVisible,
        readOnly,
        highlight,
        selectedDescriptionReadOnly,
      })}
      buttonType={buttonType}
      isYesNo={isYesNo}
      parentContainerWidth={parentContainerWidth}
      gridButtonsGap={gridButtonsGap}
      widthWrapToContent={widthWrapToContent}
    >
      {({ hovered }) => (
        <>
          {showCircle && (
            <RadioButtonFocused buttonType={buttonType} focused={focusVisible} image={!!ImageComponent}>
              <RadioButtonCircle
                selected={selected}
                focused={focusVisible}
                hovered={hoverVisible}
                readOnly={readOnly}
                error={error}
                buttonType={buttonType}
              >
                {selected && <RadioButtonFilledIn readOnly={readOnly} />}
              </RadioButtonCircle>
            </RadioButtonFocused>
          )}
          <RadioButtonContent buttonType={buttonType}>
            {ImageComponent && (
              <RadioButtonImageContainer buttonType={buttonType} size={size}>
                <RadioButtonImageTransition scaled={hovered || selected}>
                  <ImageComponent selected={selected} />
                </RadioButtonImageTransition>
              </RadioButtonImageContainer>
            )}
            <HeadingContainer useSelectedCheckmark={selectedCheckmark} testID={tID('RadioButton-headingContainer')}>
              {DecorativeIcon && (
                <DecorativeIconContainer testID={tID('RadioButton-decorativeIconContainer')}>
                  <DecorativeIcon />
                </DecorativeIconContainer>
              )}
              <RadioButtonText
                type={TextType.BODY}
                size={BodyTextSize.DEFAULT}
                buttonType={buttonType}
                selected={selected}
                highlight={highlight}
                readOnly={readOnly}
                error={error}
                badge={badge !== undefined}
                textColor={textColor}
              >
                {title}
              </RadioButtonText>
              {selected && selectedCheckmark && (
                <SessionCompletedCheckContainer>
                  <SessionCompletedCheck fillColor='white' backgroundColor={colors.backgroundHover} />
                </SessionCompletedCheckContainer>
              )}
            </HeadingContainer>
            {badge !== undefined && (
              <FieldBadge text={badge} selected={selected} disableHeadline={disableBadgeHeadline}></FieldBadge>
            )}
            {(buttonType === RadioButtonType.DESCRIPTION_NO_OUTLINE ||
              buttonType === RadioButtonType.DESCRIPTION_OUTLINE ||
              buttonType === RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE) && (
              <RadioButtonDescription
                type={TextType.BODY}
                size={BodyTextSize.DEFAULT}
                buttonType={buttonType}
                readOnly={readOnly}
                selected={selected}
              >
                {description}
              </RadioButtonDescription>
            )}
            {selected && NestedSelectField && (
              <NestedComponentContainer>
                <NestedSelectField {...nestedSelectFieldProps} />
              </NestedComponentContainer>
            )}
            {selected && NestedTimeField && (
              <NestedComponentContainer>
                <NestedTimeField {...nestedTimeFieldProps} />
              </NestedComponentContainer>
            )}
          </RadioButtonContent>
        </>
      )}
    </RadioButtonContainer>
  )
}

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  label,
  labelInfoPopover,
  subLabel,
  subLabelComponent,
  largeSubLabel,
  labelSecondary,
  labelAlignment,
  selectedValue,
  buttons,
  onChange,
  name,
  error,
  readOnly,
  showDescription,
  correctValueDetails,
  correctValue,
  onFocus = noop,
  onBlur = noop,
  isYesNo = false,
  buttonType = RadioButtonType.TEXT_OUTLINE,
  toolTipContent,
  toolTipTriggerConfig,
  active,
  accessibilityLabelledBy,
  widthWrapToContent,
  parentContainerWidth,
  baseInputStyle,
  buttonStyle,
  contextProps,
  size,
  selectedCheckmark,
  disabled,
  minLabel,
  maxLabel,
  customTextStyle,
  labelContainerStyle,
  isRadioButtonsFlexRowDirection,
}) => {
  const {
    colors,
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType
  const initialSelectedButtonIndex = buttons?.findIndex((button) => button.value === selectedValue)
  const initialFocusIndex = initialSelectedButtonIndex > -1 ? initialSelectedButtonIndex : 0
  const [focusIndex, setFocusIndex] = useState(initialFocusIndex)
  const { isMinWidthLaptop } = useMediaQuerySize()
  const radioContainerRef = useRef<any>(null)
  useAccessibilityFocus({ active, delay: 200, ref: radioContainerRef })
  const gridButtonsGap = isMinWidthLaptop ? 16 : 8
  const { formatMessage } = useIntl()

  const accessibilityAttr = accessibilityLabelledBy
    ? { accessibilityLabelledBy }
    : { accessibilityLabel: label ? label : '' }

  const setFocus = (index: number) => {
    setFocusIndex(index)
    if (!buttons[index].inactive) {
      onChange(buttons[index].value)
      radioContainerRef?.current?.children?.[index]?.focus()
    }
  }

  const focusNext = () => {
    if (focusIndex < buttons.length - 1) {
      setFocus(focusIndex + 1)
    } else {
      setFocus(0)
    }
  }

  const focusPrevious = () => {
    if (focusIndex === 0) {
      setFocus(buttons.length - 1)
    } else {
      setFocus(focusIndex - 1)
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    const keyPressed = event.key
    const keys = [KEYS.SPACE, KEYS.ARROW_UP, KEYS.ARROW_DOWN, KEYS.ARROW_LEFT, KEYS.ARROW_RIGHT]
    if (includes(keys, keyPressed)) {
      event.stopPropagation()
      event.preventDefault()
    }
    switch (keyPressed) {
      case KEYS.ARROW_UP:
        focusPrevious()
        break
      case KEYS.ARROW_DOWN:
        focusNext()
        break
      case KEYS.ARROW_LEFT:
        focusPrevious()
        break
      case KEYS.ARROW_RIGHT:
        focusNext()
        break
      default:
        break
    }
  }

  const [radioGroupWidth, setRadioGroupWidth] = useState(0)
  const onLayout = (e: LayoutChangeEvent) => {
    const { width } = e.nativeEvent.layout
    setRadioGroupWidth(width)
  }

  return (
    <BaseInput
      label={label}
      labelInfoPopover={labelInfoPopover}
      subLabel={subLabel}
      subLabelComponent={subLabelComponent}
      labelAlignment={labelAlignment}
      labelSecondary={labelSecondary}
      largeSubLabel={largeSubLabel}
      name={name}
      error={error}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      hideErrorPadding
      style={{
        opacity: radioGroupWidth === 0 ? 0 : undefined,
        ...baseInputStyle,
      }}
      contextProps={contextProps}
      customTextStyle={customTextStyle}
      labelContainerStyle={labelContainerStyle}
    >
      <RadioButtonsContainer
        // @ts-ignore next-line
        accessibilityRole={accessibilityLabelledBy && IS_WEB ? 'group' : AccessibilityRolesNative.RADIO_GROUP}
        onKeyDown={handleKeyPress}
        ref={radioContainerRef}
        buttonType={buttonType}
        gridButtonsGap={gridButtonsGap}
        flexRowDirection={
          (isYesNo && buttonType !== RadioButtonType.DESCRIPTION_NO_OUTLINE) ||
          buttonType === RadioButtonType.GRID ||
          buttonType === RadioButtonType.CIRCULAR_WITH_NUMBER ||
          isRadioButtonsFlexRowDirection
        }
        onLayout={onLayout}
        {...accessibilityAttr}
      >
        {buttons.map(
          (
            {
              label,
              value,
              highlight,
              description,
              badge,
              inactive,
              imageComponent,
              nestedTimeField,
              nestedTimeFieldProps,
              nestedSelectField,
              nestedSelectFieldProps,
              decorativeIcon,
              textColor,
              disableBadgeHeadline,
            },
            index,
          ) => {
            return (
              <RadioButton
                onChange={onChange}
                key={index}
                title={label}
                description={description}
                badge={badge}
                ImageComponent={imageComponent}
                value={value}
                selected={selectedValue === value}
                readOnly={readOnly || inactive}
                highlight={highlight}
                error={error}
                onFocus={() => {
                  setFocusIndex(index)
                  onFocus()
                }}
                focusable={index === focusIndex}
                onBlur={onBlur}
                isYesNo={isYesNo}
                buttonType={buttonType}
                parentContainerWidth={
                  parentContainerWidth && radioGroupWidth === 0 ? parentContainerWidth : radioGroupWidth
                }
                gridButtonsGap={gridButtonsGap}
                widthWrapToContent={widthWrapToContent}
                buttonStyle={buttonStyle}
                size={size}
                NestedTimeField={nestedTimeField}
                nestedTimeFieldProps={nestedTimeFieldProps}
                NestedSelectField={nestedSelectField}
                nestedSelectFieldProps={nestedSelectFieldProps}
                selectedCheckmark={selectedCheckmark}
                DecorativeIcon={decorativeIcon}
                textColor={textColor}
                disabled={disabled}
                disableBadgeHeadline={disableBadgeHeadline}
              />
            )
          },
        )}
      </RadioButtonsContainer>
      {showDescription && (
        <CorrectDescriptionContainer>
          <TopCorrectDescriptionContainer>
            <IconContainer testID={tID(`RadioButtons-correctIcon-${correctValue === selectedValue}`)}>
              {correctValue === selectedValue ? (
                <CheckIcon fillColor={colors.iconSuccess} size={24} />
              ) : (
                <XIconRound fillColor={colors.iconError} backgroundColor={colors.backgroundIcon} size={24} />
              )}
            </IconContainer>
            <CorrectDescriptionLabel
              size={SubheadSize.XSMALL}
              color={correctValue === selectedValue ? colors.textSuccess : colors.textError}
              text={
                correctValue === selectedValue
                  ? formatMessage({
                      defaultMessage: 'Correct Entry',
                      description: 'label that indicates the correct entry',
                    })
                  : formatMessage({
                      defaultMessage: 'Incorrect Entry',
                      description: 'label that indicates the incorrect entry',
                    })
              }
            />
          </TopCorrectDescriptionContainer>
          <BottomCorrectDescriptionContainer>
            <BodyText size={Size.SMALL} color={colors.textSecondary}>
              {correctValueDetails}
            </BodyText>
          </BottomCorrectDescriptionContainer>
        </CorrectDescriptionContainer>
      )}
      {(Boolean(minLabel) || Boolean(maxLabel)) && (
        <MinMaxLabelsContainer>
          {Boolean(minLabel) && (
            <BodyText
              text={minLabel}
              size={isMobileSized ? Size.CAPTION : Size.SMALL}
              color={colors.textSecondary}
              style={{ ...(isMobileSized && { fontWeight: 500 }) }}
            />
          )}
          {Boolean(maxLabel) && (
            <BodyText
              text={maxLabel}
              size={isMobileSized ? Size.CAPTION : Size.SMALL}
              color={colors.textSecondary}
              style={{ ...(isMobileSized && { fontWeight: 500 }) }}
            />
          )}
        </MinMaxLabelsContainer>
      )}
    </BaseInput>
  )
}

export const RadioGroupRFF: FunctionComponent<FieldRenderProps<RadioGroupValue>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error, active },
  buttons,
  label,
  labelInfoPopover,
  subLabel,
  subLabelComponent,
  labelSecondary,
  labelAlignment,
  largeSubLabel,
  readOnly,
  showDescription,
  correctValueDetails,
  correctValue,
  isYesNo = false,
  buttonType = RadioButtonType.CIRCLE_TEXT_OUTLINE,
  toolTipContent,
  toolTipTriggerConfig,
  accessibilityLabelledBy,
  baseInputStyle,
  buttonStyle,
  contextProps,
  disabled,
  minLabel,
  maxLabel,
  customTextStyle,
  labelContainerStyle,
}) => {
  return (
    <RadioGroup
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      label={label}
      labelInfoPopover={labelInfoPopover}
      subLabel={subLabel}
      subLabelComponent={subLabelComponent}
      labelSecondary={labelSecondary}
      labelAlignment={labelAlignment}
      largeSubLabel={largeSubLabel}
      selectedValue={value}
      buttons={buttons}
      name={name}
      error={touched && error}
      readOnly={readOnly}
      showDescription={showDescription}
      correctValueDetails={correctValueDetails}
      correctValue={correctValue}
      isYesNo={isYesNo}
      buttonType={buttonType}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      active={active}
      accessibilityLabelledBy={accessibilityLabelledBy}
      baseInputStyle={baseInputStyle}
      buttonStyle={buttonStyle}
      contextProps={contextProps}
      disabled={disabled}
      minLabel={minLabel}
      maxLabel={maxLabel}
      customTextStyle={customTextStyle}
      labelContainerStyle={labelContainerStyle}
    />
  )
}
