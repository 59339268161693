import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { LayoutChangeEvent, TextStyle, View, ViewStyle } from 'react-native'
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import { LinearGradient } from 'expo-linear-gradient'
import { isBoolean } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead, SubheadProps, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { tID } from '../../utils'

export type AccordionHandle = {
  open: () => void
  close: () => void
}

export type AccordionProps = {
  title: ReactNode
  content: ReactNode
  subtitle?: ReactNode
  iconComponent?: ReactNode
  testIDPrefix?: string
  titleColor?: string
  subtitleColor?: string
  backgroundColor?: string
  style?: ViewStyle
  titleStyle?: TextStyle
  titleProps?: Partial<SubheadProps>
  buttonStyle?: ViewStyle | Array<ViewStyle>
  contentInnerContainerStyle?: ViewStyle
  handleIsExpandedChanged?: (isOpen: boolean) => void
  onAccordionPress?: () => void
  onAccordianStateChanged?: (isOpen?: boolean) => void
  isExpanded?: boolean
  initiallyOpen?: boolean
  chevronColor?: string
  chevronSize?: number
  positionChevronTop?: boolean
  showGradient?: boolean
  CustomExpansionIcon?: React.ReactElement
  heightWhenCollapsed?: number
}

const Container = styled(View)<{ backgroundColor: string; height?: number }>(({ theme, backgroundColor, height }) => {
  const paddingVertical = theme.spacing['16px']
  const [paddingVerticalNumber, _] = paddingVertical.split('px')
  return { paddingVertical, backgroundColor, height: height ? height + 2 * Number(paddingVerticalNumber) : 'auto' }
})

const Button = styled(PressableOpacity)({
  borderRadius: 4,
})

const IconContainer = styled.View(({ theme }) => ({
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['16px'],
}))

const TitleContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
})

const TitleTextContainer = styled.View(({ theme }) => ({
  flexShrink: 1,
  ...(theme.breakpoints.isMobileSized && { marginRight: theme.spacing['8px'] }),
}))

const Subtitle = styled(BodyText)(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const ContentOuterContainer = styled(Animated.View)({
  position: 'relative',
  overflow: 'hidden',
  zIndex: -1,
})

const ContentInnerContainer = styled.View<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: 'absolute',
  width: '100%',
  bottom: theme.spacing['0px'],
  paddingTop: theme.spacing['16px'],
  ...(IS_WEB && isOpen ? { visibility: 'visible' } : { visibility: 'hidden' }),
}))

const ChevronContainer = styled(Animated.View)({
  marginLeft: 'auto',
})

const Gradient = styled(LinearGradient)`
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  height: 16px;
`

/**
 * A component to show/hide additional content
 */
export const Accordion = forwardRef<AccordionHandle, AccordionProps>(
  (
    {
      title,
      subtitle,
      content,
      iconComponent,
      testIDPrefix = 'Accordion',
      titleColor,
      subtitleColor,
      chevronColor,
      chevronSize,
      backgroundColor,
      handleIsExpandedChanged,
      onAccordionPress,
      onAccordianStateChanged,
      style,
      titleStyle,
      titleProps,
      buttonStyle,
      contentInnerContainerStyle,
      isExpanded,
      initiallyOpen = false,
      positionChevronTop,
      showGradient = true,
      CustomExpansionIcon,
      heightWhenCollapsed,
    },
    ref,
  ) => {
    const CLOSE_ANIMATION_DURATION_IN_MS = 300
    const OPEN_ANIMATION_DURATION_IN_MS = 400

    const isWithinGroup = isBoolean(isExpanded) && handleIsExpandedChanged
    const titleId = `${testIDPrefix}-title`
    const subtitleId = `${testIDPrefix}-subtitle`
    const contentId = `${testIDPrefix}-content`

    const { colors } = useTheme()
    const { formatMessage } = useIntl()
    const [isOpen, setIsOpen] = useState(initiallyOpen ?? isExpanded)
    const contentHeight = useRef<number>(0)

    const sharedHeight = useSharedValue(contentHeight.current || 0)
    const sharedRotation = useSharedValue(isExpanded ? -180 : 0)

    const animatedStyles = useAnimatedStyle(() => {
      'worklet'
      return {
        height: sharedHeight.value,
      }
    })

    const animatedIconStyles = useAnimatedStyle(() => {
      'worklet'
      return {
        transform: [{ rotateZ: `${sharedRotation.value}deg` }],
        ...(positionChevronTop && { top: 0, right: 0, position: 'absolute' }),
      }
    })

    const animationTiming = {
      open: {
        duration: OPEN_ANIMATION_DURATION_IN_MS,
        easing: Easing.bezier(0.53, 0.08, 0.04, 1),
      },
      close: {
        duration: CLOSE_ANIMATION_DURATION_IN_MS,
        easing: Easing.bezier(0.9, -0.01, 0.68, 0.87),
      },
    }

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true)
      },
      close: () => {
        setIsOpen(false)
      },
    }))

    useEffect(() => {
      isWithinGroup && isExpanded !== isOpen && setIsOpen(isExpanded)
    }, [isOpen, isExpanded, isWithinGroup])

    useEffect(() => {
      const newHeight = isOpen ? contentHeight.current : 0
      const newRotation = isOpen ? -180 : 0
      const timingOptions = isOpen ? animationTiming.open : animationTiming.close
      sharedHeight.value = withTiming(newHeight, timingOptions)
      sharedRotation.value = withTiming(newRotation, timingOptions)
    }, [animationTiming.close, animationTiming.open, isOpen, sharedHeight, sharedRotation])

    const onContentlayout = (e: LayoutChangeEvent) => {
      const height = e.nativeEvent.layout.height
      if (isOpen && Math.round(contentHeight.current) !== Math.round(height)) {
        // Set shared height here so that content properly flows on resize
        sharedHeight.value = height
      }
      contentHeight.current = height
    }

    const handleButtonPress = () => {
      onAccordionPress && !isOpen && onAccordionPress()
      if (isWithinGroup) {
        handleIsExpandedChanged(!isOpen)
      } else {
        onAccordianStateChanged && onAccordianStateChanged(!isOpen)
        setIsOpen(!isOpen)
      }
    }

    return (
      <Container
        backgroundColor={backgroundColor || colors.backgroundPrimary}
        height={heightWhenCollapsed}
        style={style}
      >
        <Button
          onPress={() => handleButtonPress()}
          testID={tID(titleId)}
          accessibilityRole='button'
          accessibilityLabel={formatMessage({
            defaultMessage: 'Expand content',
            description: 'button text expand content',
          })}
          accessibilityExpanded={isOpen}
          accessibilityControls={contentId}
          style={buttonStyle}
        >
          <TitleContainer>
            {Boolean(iconComponent) && <IconContainer>{iconComponent}</IconContainer>}
            <TitleTextContainer>
              <Subhead
                selectable={false}
                text={title}
                size={SubheadSize.SMALL}
                color={titleColor || colors.textPrimary}
                nativeID={titleId}
                wrap
                noRole
                style={{ ...(positionChevronTop && { marginRight: 32 }), ...titleStyle }}
                {...titleProps}
              />
              {subtitle && (
                <Subtitle
                  selectable={false}
                  text={subtitle}
                  size={BodyTextSize.DEFAULT}
                  color={subtitleColor || colors.textPrimary}
                  nativeID={subtitleId}
                />
              )}
            </TitleTextContainer>
            {CustomExpansionIcon ? (
              React.cloneElement(CustomExpansionIcon, { isOpen })
            ) : (
              <ChevronContainer style={animatedIconStyles} testID={tID('ChevronIconContainer')}>
                <ChevronIcon
                  fillColor={chevronColor || titleColor || colors.textPrimary}
                  size={chevronSize}
                  direction={ChevronIconDirection.DOWN}
                />
              </ChevronContainer>
            )}
            {showGradient && (
              <Gradient
                colors={[
                  backgroundColor || colors.backgroundPrimary,
                  `${backgroundColor || colors.backgroundPrimary}00`,
                ]}
              />
            )}
          </TitleContainer>
        </Button>
        <ContentOuterContainer style={animatedStyles} testID={tID(contentId)}>
          <ContentInnerContainer
            onLayout={onContentlayout}
            accessibilityElementsHidden={!isOpen}
            accessibilityHidden={!isOpen}
            accessibilityLabelledBy={titleId}
            nativeID={contentId}
            style={contentInnerContainerStyle}
            isOpen={isOpen}
          >
            {content}
          </ContentInnerContainer>
        </ContentOuterContainer>
      </Container>
    )
  },
)
