import { fromJS } from 'immutable'

import {
  BrightHorizonImage,
  CareImage,
  EYImage,
  GapBrightHorizonsImage,
  Headspace,
  LifeCare,
  NationwidePetImage,
  TuitionDiscountImage,
} from '@lyrahealth-inc/ui-core-crossplatform/src/assets/worklife'

import LegalAssistanceImage from '../../../images/microsite/LegalAssistance.png'

export const eyassist = () => {
  return fromJS({
    workLifeServices: [
      {
        identifiers: {
          id: 'legal&identity',
          dataTestId: 'workLife-selectLegal&identity',
        },
        title: 'Legal and ID Theft',
        img: LegalAssistanceImage,
        bullets: [
          'Locate an attorney for legal support at a reduced fee',
          'Document preparation',
          'Fraud resolution to help restore your identity',
          'Service provided by Lyra Health Partners',
        ],
        CTA: 'Visit Legal/ID Theft Support',
        PATH: 'https://clcmembers.net/launch/46100780-6095-11e9-ab48-8963789f0545',
      },
      {
        identifiers: {
          id: 'care@work',
          dataTestId: 'workLife-selectCare@work',
        },
        title: 'Care.com',
        img: LifeCare,
        bullets: [
          'No-cost self-directed search engine to locate available care providers',
          'Locate adult/child caregivers, pet sitters, housekeepers and more ',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: 'Find a Care.com provider',
        PATH: 'https://ey.care.com',
      },
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons Family Support',
        img: GapBrightHorizonsImage,
        bullets: [
          'Back-up care when your typical care provider is unavailable, and you need to work',
          'Locate child and adult care providers',
          'Center based or in-home care',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: ' Get Backup Care',
        PATH: 'https://backup.brighthorizons.com',
      },
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizonsTuition',
        },
        title: 'College Coach through Bright Horizons',
        img: TuitionDiscountImage,
        bullets: [
          'No cost support with essays, applications and interviews',
          'Financial advisement on paying for college',
          'Customized college searches',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: 'Visit College Coach',
        PATH: 'https://passport.brighthorizons.com',
      },
      {
        identifiers: {
          id: 'wellhub',
          dataTestId: 'workLife-selectWellhub',
        },
        title: 'Wellhub',
        img: Headspace,
        bullets: [
          'Access to 12,000 US fitness facilities at discounted prices',
          'Virtual 1:1 personal training and online group classes',
          '40+ digital wellness partner apps',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: ' Visit Wellhub',
        PATH: 'https://www.gympass.com/us/ey-us',
      },
      {
        identifiers: {
          id: 'perksAtWork',
          dataTestId: 'workLife-selectPerksAtWork',
        },
        title: 'Perks at Work Discount Program',
        img: EYImage,
        bullets: [
          'Discounts on tech, fitness, auto, clothing and more',
          'Access for designated family members',
          'Frequently changing savings and offers',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: 'Visit Perks at Work',
        PATH: 'https://www.perksatwork.com/login',
      },
      {
        identifiers: {
          id: 'includedHealth',
          dataTestId: 'workLife-selectIncludedHealth',
        },
        title: 'Included Health',
        img: CareImage,
        bullets: [
          'No cost, LGBTQ+ health care navigation platform',
          'Dedicated queer/trans-led care coordinators',
          'Assistance with finding vetted providers',
          'Support for gender-affirming surgery, starting a family, etc.',
          'Spouses/partners and dependents can register for their own account but must be 13 years or older',
        ],
        CTA: 'Visit Included Health',
        PATH: 'https://includedhealth.com/microsite/ey',
      },
      {
        identifiers: {
          id: 'eyNavigate',
          dataTestId: 'workLife-selectEyNavigate',
        },
        title: 'EY Navigate for Employee Financial Planning',
        img: EYImage,
        bullets: [
          'Hybrid digital/human financial planning service for EY individuals',
          'Dedicated financial planners',
          'Help building healthy financial habits',
          'Secure account aggregation and access',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: 'Visit EY Navigate',
        PATH: 'https://ey.eynavigate.com/',
      },
      {
        identifiers: {
          id: '2ndMD',
          dataTestId: 'workLife-select2ndMD',
        },
        title: '2nd.MD for Expert Medical Second Opinions',
        img: BrightHorizonImage,
        bullets: [
          'No cost, expert review on diagnoses, treatment options or surgery',
          'Available for video or telephonic conversation',
          'Can work with your medical and mental health physician as well',
          'Spouses, dependents and parents are eligible to use benefit',
          'Account registration must be created by EY member using their contact information',
        ],
        CTA: 'Visit 2nd.MD',
        PATH: 'https://www.2nd.md/login',
      },
      {
        identifiers: {
          id: 'dailyLife',
          dataTestId: 'workLife-selectDailyLife',
        },
        title: 'Daily Life Research and Caregiver Resources',
        img: NationwidePetImage,
        bullets: [
          'No-cost research for daily life needs and child/adult caregiving resources',
          'Tutoring - 24/7, Virtual OnDemand tutoring for K-12 and college',
          'Account registration must be created by EY member using their contact information',
          'New users sign up using your 7-digit GUI as the Member ID and set a personal password',
        ],
        CTA: 'Request daily life research or tutoring',
        PATH: 'https://ey.care.com',
      },
    ],
  })
}
