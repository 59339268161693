import React, { FunctionComponent } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Image as ExpoImage, ImageProps as ExpoImageProps, ImageStyle } from 'expo-image'
import styled from 'styled-components/native'

const ImageContainer = styled(ExpoImage)({ flex: 1 })

export const Image: FunctionComponent<ExpoImageProps & { ariaLabel?: string }> = ({
  testID,
  accessibilityIgnoresInvertColors,
  children,
  style,
  alt,
  ariaLabel,
  accessibilityLabel,
  ...rest
}) => {
  return (
    <View testID={testID} style={style as Partial<ViewStyle>}>
      <ImageContainer
        role='img'
        accessible
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        aria-label={ariaLabel ?? 'image'}
        accessibilityIgnoresInvertColors={accessibilityIgnoresInvertColors}
        {...rest}
        accessibilityLabel={alt ?? accessibilityLabel ?? ''}
        style={styles(style)}
      >
        {children}
      </ImageContainer>
    </View>
  )
}

const image = StyleSheet.create({
  imageContainer: {
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
})

const styles = (style?: StyleProp<ImageStyle> | undefined) => StyleSheet.flatten([style, image.imageContainer])
