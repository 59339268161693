import React, { FunctionComponent } from 'react'
import { ImageSourcePropType, ImageStyle } from 'react-native'

import styled from 'styled-components/native'

import handAndKeyUrl from '../../assets/handAndKey.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type HandAndKeyIllustrationProps = {
  width?: number
  style?: ImageStyle
}

const ImageContainer = styled(Image)<{ imageHeight?: string; imageWidth: string }>(({ imageHeight, imageWidth }) => ({
  height: imageHeight,
  width: imageWidth,
}))

export const HandAndKeyIllustration: FunctionComponent<HandAndKeyIllustrationProps> = ({ width = 219, style }) => {
  const heightInPixels = `${Math.floor(width / 1.5)}px`
  const widthInPixels = `${width}px`

  return (
    <ImageContainer
      imageHeight={heightInPixels}
      imageWidth={widthInPixels}
      testID={`${tID('HandAndKeyIllustration')}`}
      source={handAndKeyUrl as ImageSourcePropType}
      style={style}
    />
  )
}
