import React, { FunctionComponent } from 'react'

import { SessionOnboardingIconProps } from './SessionOnboardingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const SessionOnboardingIconSvg = withSuspense({
  component: React.lazy(() => import('./SessionOnboardingIconSvg')),
})

export const SessionOnboardingIcon: FunctionComponent<SessionOnboardingIconProps> = ({ width, height }) => {
  return <SessionOnboardingIconSvg width={width} height={height} />
}
