import { useMemo } from 'react'

import { LDFlagSet } from 'launchdarkly-react-client-sdk'
import { cloneDeep } from 'lodash-es'

import { LDOverrideFlags } from '../types'

const useOverrideFlags = (
  flags?: LDFlagSet,
  overrideFlags?: LDOverrideFlags,
  showDisabled: boolean = false,
): LDFlagSet => {
  return useMemo(() => {
    const currFlags = cloneDeep(flags ?? {})
    if (!currFlags) {
      return {}
    }
    if (!overrideFlags?.flags || (!overrideFlags.enabled && !showDisabled)) {
      return currFlags
    }

    Object.keys(overrideFlags.flags).forEach((flag) => {
      currFlags[flag] = overrideFlags.flags[flag]
    })
    return currFlags
  }, [flags, overrideFlags, showDisabled])
}

export default useOverrideFlags
