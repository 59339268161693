import React, { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { noop } from 'lodash-es'

import {
  CARE_OPTION,
  ExtendedPathwayOption,
  OnCareOptionModalOpenCloseProps,
  PathwayOptions,
} from '@lyrahealth-inc/shared-app-logic'

import { Link } from '../atoms'
import { BodyText } from '../atoms/bodyText/BodyText'
import { BodyTextSize } from '../styles'
import { tID } from '../utils'

const CARE_OPTION_MODAL_HEADERS = defineMessages({
  [CARE_OPTION.PARENT_COACHING]: {
    defaultMessage: 'Coaching for parents',
    description: 'Header of modal giving information about the coaching for parents program',
  },
  [CARE_OPTION.FAMILY_THERAPY]: {
    defaultMessage: 'Family therapy',
    description: 'Header of modal giving information about family therapy',
  },
  [CARE_OPTION.MEDICATION_CONSULTATION]: {
    defaultMessage: 'Get a medication consultation',
    description: 'Header for modal that has information about the medication consultation offering',
  },
})

const CARE_OPTION_MODAL_DESCRIPTIONS = defineMessages({
  [CARE_OPTION.PARENT_COACHING]: {
    defaultMessage:
      'Meet one-on-one with a parenting coach to improve your own mental wellbeing—and address challenging parenting situations like tantrums, navigating independence, screen time, and more.',
    description: 'Description of the parent coaching program',
  },
  [CARE_OPTION.FAMILY_THERAPY]: {
    defaultMessage:
      'Bring your family together to overcome challenges, foster healthier dynamics, or address the behavioral health needs of your young children. A therapist will work with you to identify goals, learn new communication styles, and more.',
    description: 'Description of family therapy',
  },
  [CARE_OPTION.MEDICATION_CONSULTATION]: {
    defaultMessage:
      'Understand if emotional wellness medication could help you start feeling like yourself again. Speak with a board-certified Lyra physician to evaluate treatment options and determine if emotional wellness medication might work for you. If you’re already taking a medication, see if it’s still the best fit for your needs.',
    description: 'text giving an overview of medication treatment option',
  },
})

const CARE_OPTION_MODAL_CTA_TEXT = defineMessages({
  [CARE_OPTION.PARENT_COACHING]: {
    defaultMessage: 'Find a coach',
    description: 'Label of button, cta to find a parenting coach',
  },
  [CARE_OPTION.FAMILY_THERAPY]: {
    defaultMessage: 'Find a therapist',
    description: 'Label of button, cta to find a therapist',
  },
  [CARE_OPTION.MEDICATION_CONSULTATION]: {
    defaultMessage: 'Book a consultation',
    description: 'Label of button, cta to book a medication consultation',
  },
})

interface UseGetCareOptionsModalContentProps {
  selectedPathwayOption?: ExtendedPathwayOption
  userAge?: number
  onCareOptionModalOpen: (props: OnCareOptionModalOpenCloseProps) => void
  onCareOptionModalClose: (props: OnCareOptionModalOpenCloseProps) => void
  onLearnMorePress: () => void
  // used to reset selected pathway option so that modal is only opened on initial page load
  resetSelectedPathwayOption?: () => void
}

export const useGetCareOptionsModalContent = ({
  selectedPathwayOption,
  userAge,
  onCareOptionModalOpen,
  onCareOptionModalClose,
  onLearnMorePress = noop,
  resetSelectedPathwayOption = noop,
}: UseGetCareOptionsModalContentProps) => {
  const { formatMessage } = useIntl()
  const boldTextValue = useMemo(() => {
    return {
      b: (chunks: string) => <BodyText bold>{chunks}</BodyText>,
    }
  }, [])

  const onProgramDetailsOpenClose = useCallback(
    (open: boolean, treatmentOption: CARE_OPTION) => {
      const props = { treatmentOption, age: userAge }
      if (open) {
        onCareOptionModalOpen(props)
      } else {
        onCareOptionModalClose(props)
      }

      // clear the initial selectedPathwayOption so that modal won't open again on reload from a refresh or back navigation
      resetSelectedPathwayOption()
    },
    [onCareOptionModalClose, onCareOptionModalOpen, resetSelectedPathwayOption, userAge],
  )

  return useMemo(() => {
    return {
      [CARE_OPTION.PARENT_COACHING]: {
        isModalOpenInitially: selectedPathwayOption === PathwayOptions.PARENTING_TOOLKIT,
        headerIllustration: 'CareOptionParentingSupportIcon',
        header: formatMessage(CARE_OPTION_MODAL_HEADERS[CARE_OPTION.PARENT_COACHING]),
        description: formatMessage(CARE_OPTION_MODAL_DESCRIPTIONS[CARE_OPTION.PARENT_COACHING]),
        programPoints: [
          {
            icon: 'BookSessionsIcon',
            description: formatMessage(
              {
                defaultMessage: '<b>Book</b> regular 45-minute sessions at a time that works for you.',
                description: 'Details on sessions offered for parent coaching',
              },
              boldTextValue,
            ),
          },
          {
            icon: 'PracticeSkillsIcon',
            description: formatMessage(
              {
                defaultMessage: '<b>Get</b> personalized care between sessions with guided practices.',
                description: 'Details on care offered between sessions for parent coaching',
              },
              boldTextValue,
            ),
          },
          {
            icon: 'ProgressIcon',
            description: formatMessage(
              {
                defaultMessage: '<b>Track</b> your progress and reach your parenting goals with your coach’s support.',
                description: 'Details on tracking progress throughout sessions for parent coaching',
              },
              boldTextValue,
            ),
          },
        ],
        ctaText: formatMessage(CARE_OPTION_MODAL_CTA_TEXT[CARE_OPTION.PARENT_COACHING]),
        onModalOpen: () => {
          onProgramDetailsOpenClose(true, CARE_OPTION.PARENT_COACHING)
        },
        onModalClose: () => {
          onProgramDetailsOpenClose(false, CARE_OPTION.PARENT_COACHING)
        },
      },
      [CARE_OPTION.FAMILY_THERAPY]: {
        isModalOpenInitially: selectedPathwayOption === PathwayOptions.FAMILY_THERAPY,
        headerIllustration: 'Family',
        header: formatMessage(CARE_OPTION_MODAL_HEADERS[CARE_OPTION.FAMILY_THERAPY]),
        description: formatMessage(CARE_OPTION_MODAL_DESCRIPTIONS[CARE_OPTION.FAMILY_THERAPY]),
        programPoints: [
          {
            icon: 'BookSessionsIcon',
            description: formatMessage(
              {
                defaultMessage: '<b>Book</b> regular 50-minute sessions at a convenient time.',
                description: 'Details on sessions offered for family therapy',
              },
              boldTextValue,
            ),
          },
          {
            icon: 'ProgressIcon',
            description: formatMessage(
              {
                defaultMessage: '<b>Track</b> your and your family’s progress with your therapist’s support.',
                description: 'Details on tracking progress throughout sessions for family therapy',
              },
              boldTextValue,
            ),
          },
        ],
        ctaText: formatMessage(CARE_OPTION_MODAL_CTA_TEXT[CARE_OPTION.FAMILY_THERAPY]),
        onModalOpen: () => {
          onProgramDetailsOpenClose(true, CARE_OPTION.FAMILY_THERAPY)
        },
        onModalClose: () => {
          onProgramDetailsOpenClose(false, CARE_OPTION.FAMILY_THERAPY)
        },
      },
      [CARE_OPTION.MEDICATION_CONSULTATION]: {
        isModalOpenInitially: selectedPathwayOption === PathwayOptions.MEDICATION_CONSULTATION,
        headerIllustration: 'MedicationConsultationIllustration',
        header: formatMessage(CARE_OPTION_MODAL_HEADERS[CARE_OPTION.MEDICATION_CONSULTATION]),
        description: formatMessage(CARE_OPTION_MODAL_DESCRIPTIONS[CARE_OPTION.MEDICATION_CONSULTATION]),
        programPoints: [
          {
            icon: 'TrendUpIcon',
            description: formatMessage({
              defaultMessage: 'Ongoing symptom tracking to help mitigate any side effects',
              description: 'Details on symptom tracking',
            }),
          },
          {
            icon: 'CalendarBookingIcon',
            description: formatMessage({
              defaultMessage: 'Easy access to prescription refills and follow-up appointments with the same physician',
              description: 'Details on easy access to prescription refills and follow-up appointments',
            }),
          },
          {
            icon: 'InfoIcon',
            description: formatMessage(
              {
                defaultMessage:
                  'If you’re on a controlled medication (Adderall, Xanax, etc.) and wish to continue, we can help you understand your options. {hideLearnMoreLink, select, yes {} other {<link>Learn more</link>}}',
                description: 'Footnote information for access to medication',
              },
              {
                link: (chunks: string) => (
                  <Link
                    text={chunks}
                    size={BodyTextSize.SMALL}
                    onPress={onLearnMorePress}
                    underline
                    testID={tID(`CareOption-medicationConsultation-learnMore`)}
                  />
                ),
                hideLearnMoreLink: onLearnMorePress === noop ? 'yes' : 'other',
              },
            ),
          },
        ],
        ctaText: formatMessage(CARE_OPTION_MODAL_CTA_TEXT[CARE_OPTION.MEDICATION_CONSULTATION]),
        onModalOpen: () => {
          onProgramDetailsOpenClose(true, CARE_OPTION.MEDICATION_CONSULTATION)
        },
        onModalClose: () => {
          onProgramDetailsOpenClose(false, CARE_OPTION.MEDICATION_CONSULTATION)
        },
        centerProgramPoints: true,
      },
    }
  }, [boldTextValue, formatMessage, onLearnMorePress, onProgramDetailsOpenClose, selectedPathwayOption])
}
