/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash-es'

import { useInitiateFeatureFlagging } from '@lyrahealth-inc/lyraweb-mobile/src/hooks/useInitiateFeatureFlagging/useInitiateFeatureFlagging.web'
import {
  areAllCustomerProgramsBHBExclusive,
  CustomerName,
  FetchStatus,
  INACTIVITY_WARNING_DURATION_MS,
  IS_PRODUCTION,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { type LDFlags } from '@lyrahealth-inc/shared-app-logic/src/features/flags/useFlags'
import { compatibilityUtils, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { ProductTypeContext, toJS, useActivity, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import ComingSoon from '../../common/components/comingSoon/ComingSoon'
import {
  INACTIVITY_CHECK_INTERVAL_MS,
  IS_SESSION_TIMER_DISABLED,
  USER_ACTIVITY_THROTTLE_MS,
} from '../../common/constants/appConstants'
import useAuthenticate from '../../common/hooks/useAuthenticate'
import useMixpanel from '../../common/hooks/useMixpanel'
import { closeOneTrustBanner, removeParentDomainCookies } from '../../common/utils/cookieUtils'
import { logToSumoLogic, SUMO_CATEGORY } from '../../common/utils/userUtils'
import {
  fetchDetectedCountry,
  setBypassComingSoonBeforeLaunch,
  setFetchDetectedCountryRequestStatus,
} from '../../data/appGlobals/appGlobalsActions'
import {
  getDetectedCountryISO,
  getDeviceUUID,
  getOktaBaseUrl,
  getOktaClientId,
} from '../../data/appGlobals/appGlobalsSelectors'
import { setTimeInactivityWarning } from '../../data/auth/authActions'
import { getTimeLastActive } from '../../data/auth/authSelectors'
import {
  getBeforeLaunch,
  getCustomerApproveWithNoEligibilityCheck,
  getCustomerName,
  getEssentialsDisabled,
  getHealthPlanExtensionEnabled,
  getHideDependentField,
  getIsCoachingEnabled,
  getIsCustomerOptedOutOfEligibilityCheck,
  getIsGoogleSSODisabled,
  getProgramCoverageBreakdown,
  getShouldSeeCareAdvocateRequestForm,
} from '../../data/customer/customerSelectors'
import { getCustomerCountryList, getSupportedCountries } from '../../data/customerCountry/customerCountryActions'
import {
  getCountryListForCustomerCountry,
  getSupportedCountryList,
} from '../../data/customerCountry/customerCountrySelectors'
import { setFeatureFlags } from '../../data/featureFlags/featureFlagActions'
import { getBaseApplicationData, getCsrf, getOktaSession, login, markUserAsStale } from '../../data/login/loginActions'
import { trackEventWithObj } from '../../data/mixpanel'
import { getUserRemainingTime } from '../../data/user/userActions'
import {
  getIsUserInternational,
  getIsUserLoggedIn,
  getIsUserTeen,
  getSelfSearchAvailable,
  getUserCountryCode,
  getUserDisplayLanguageOrDefault,
  getUserRemainingTime as getUserRemainingTimeSelector,
} from '../../data/user/userSelectors'
import UnsupportedBrowser from '../../errors/UnsupportedBrowser'
import { getIntakeFormOpen } from '../lyraTherapy/data/LyraTherapySelectors'
import { ONE_TRUST_COOKIE_BANNER_ROUTES, PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'
import { setSsoIdentityProvider, setSsoToken } from '../register/data/registerActions'
import { getEAPEligibility } from '../register/eligibility/data/eligibilityActions'
import { getIsEligibilityStatusInitialLoading } from '../register/eligibility/data/eligibilitySelectors'
import { appRouter_DO_NOT_USE } from '../routing/legacyRouterShim'
interface AppContainerProps extends Omit<ConnectedProps<typeof connector>, 'getOktaSession' | 'setFeatureFlags'> {
  getOktaSession: (baseUrl: string) => Promise<{ idp?: { id: string; type: string } }>
  setFeatureFlags: (flags: Partial<LDFlags>) => void
  essentialsDisabled: boolean
}

const AppContainer: React.FunctionComponent<AppContainerProps> = ({
  children,
  hasFetchedUser,
  getBaseApplicationData,
  getEAPEligibility,
  markUserAsStale,
  beforeLaunch,
  mixpanelToken,
  trackEventWithObj,
  user,
  login,
  getCsrf,
  getCustomerCountryList,
  customerCountryList,
  getSupportedCountries,
  supportedCountries,
  customerName,
  userCountryCode,
  getOktaSession,
  oktaBaseUrl,
  oktaClientId,
  isGoogleSSODisabled,
  fetchDetectedCountry,
  setFetchDetectedCountryRequestStatus,
  isUserLoggedIn,
  sessionRemainingTime,
  getUserRemainingTime,
  setTimeInactivityWarning,
  timeLastActive,
  videoSessionOpen,
  isLoadingEligibility,
  optOutOfEAPEligibilityCheck,
  isCustomerHPIExclusive,
  approveClaimsWithNoEligibilityCheck,
  isUserInternational,
  isIntakeFormOpen,
  healthPlanEnabled,
  isCoachingEnabled,
  customerHasAssertiveTriage,
  oktaAppleSSOClientId,
  oktaGoogleSSOClientId,
  setFeatureFlags,
  setBypassComingSoonBeforeLaunch,
  setSsoIdentityProvider,
  setSsoToken,
  essentialsDisabled,
  hideDependentField,
  hashedUserId,
}) => {
  const deviceUUID = useSelector(getDeviceUUID)
  const isProd = process.env.NODE_ENV === 'production'
  const detectedCountryISO = useSelector(getDetectedCountryISO)
  const selfSearchAvailable = useSelector(getSelfSearchAvailable)
  const isUserTeen = useSelector(getIsUserTeen)
  const userDisplayLanguage = useSelector(getUserDisplayLanguageOrDefault)
  const { productType } = useContext(ProductTypeContext)

  const isLaunchDarklyIdentified = useInitiateFeatureFlagging({
    user,
    hashedUserId,
    customerName,
    hasFetchedUser,
    country: userCountryCode || detectedCountryISO,
    healthPlanEnabled,
    customerHasAssertiveTriage,
    isCoachingEnabled,
    essentialsDisabled,
    selfSearchAvailable,
    isUserTeen,
    displayLanguage: userDisplayLanguage,
    hideDependentField,
    deviceUUID,
    isProd,
    productType,
    onFlags: useCallback(
      (flags: Partial<LDFlags>) => {
        setFeatureFlags(flags)
      },
      [setFeatureFlags],
    ),
    track: useCallback((userId, data) => {
      logToSumoLogic(IS_PRODUCTION ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG, userId, data)
    }, []),
  })
  const mixpanelInitialized = useMixpanel({ mixpanelToken, user })
  const getOktaSessionCallback = React.useCallback(() => getOktaSession(oktaBaseUrl), [getOktaSession, oktaBaseUrl])
  const flags = useFlags()
  const {
    isSessionTimerEnabled,
    requireEligibilityConfirmation,
    allowDemoBeforeLaunch,
    isRegistrationAndLoginDisabled,
  } = flags

  const [isSessionTimerDisabled, setSessionTimerDisabled] = useState(
    localStorage.getItem(IS_SESSION_TIMER_DISABLED) === 'true',
  )

  const [hasFetchedDetectedCountry, updateHasFetchedDetectedCountry] = useState(false)
  // we don't want Snap Engage to be tab-able for accessibility purposes

  useEffect(() => {
    if (user.id) {
      Sentry.setUser({ id: user.id })
    }
  }, [user.id])

  const isAuthenticating = useAuthenticate({
    login,
    trackEventWithObj,
    markUserAsStale,
    mixpanelInitialized,
    getOktaSession: isGoogleSSODisabled ? undefined : getOktaSessionCallback,
    navigate: appRouter_DO_NOT_USE.navigate,
    oktaClientId,
    oktaBaseUrl,
    oktaAppleSSOClientId,
    oktaGoogleSSOClientId,
    setSsoIdentityProvider,
    setSsoToken,
    userId: user?.id,
    customerId: customerName,
  })

  const sourceCategory = process.env.NODE_ENV === 'production' ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG

  const getCsrfToken = async (retryCount = 1) => {
    try {
      await getCsrf()
    } catch (error) {
      if (retryCount > 0) {
        logToSumoLogic(sourceCategory, user?.lyraId, {
          messsage: `Fetching CSRF token failed - ${error} - Retrying ${retryCount} time(s)`,
          type: 'error',
          error,
          sourceCategory:
            process.env.NODE_ENV === 'production' ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG,
        })
        await getCsrfToken(retryCount - 1)
      } else {
        logToSumoLogic(sourceCategory, user?.lyraId, {
          messsage: `CSRF token not set - ${error}`,
          type: 'error',
          error,
          sourceCategory:
            process.env.NODE_ENV === 'production' ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG,
        })
      }
    }
  }

  const [isFetchingCsrfToken] = useFetcher([getCsrfToken], [])

  useFetcher([[getBaseApplicationData, null, !hasFetchedUser]], [hasFetchedUser], {
    onFail: (error) => {
      console.error('Failed to fetch user', error)
    },
  })

  let shouldFetchEligibility = !optOutOfEAPEligibilityCheck && !isCustomerHPIExclusive && !isUserInternational

  // TODO: Temporary check for apple until https://lyrahealth.atlassian.net/browse/ACCOUNT-2764 is complete to determine if approveClaimsWithNoEligibilityCheck can be removed
  if (customerName !== CustomerName.APPLE) {
    shouldFetchEligibility = shouldFetchEligibility && !approveClaimsWithNoEligibilityCheck
  }
  shouldFetchEligibility = shouldFetchEligibility || !!requireEligibilityConfirmation?.isEnabled

  useFetcher(
    [[getEAPEligibility, { lyraId: user.lyraId }, !!user && shouldFetchEligibility === true, false /* cancellable */]],
    [hasFetchedUser, shouldFetchEligibility],
  )

  useFetcher([[getCustomerCountryList, [], isEmpty(customerCountryList)]], [], {
    onFail: () => {
      window.location.replace(`https://care.${(window as $TSFixMe).domainURL}/`)
    },
  })
  useFetcher([[getSupportedCountries, [], isEmpty(supportedCountries)]])

  useFetcher([[fetchDetectedCountry, null, !hasFetchedDetectedCountry]], [hasFetchedDetectedCountry], {
    onFail: () => {
      setFetchDetectedCountryRequestStatus(FetchStatus.ERROR)
    },
    onSuccess: () => setFetchDetectedCountryRequestStatus(FetchStatus.SUCCESS),
    onFinally: () => updateHasFetchedDetectedCountry(true),
  })

  useEffect(() => {
    removeParentDomainCookies()

    const toggleIsSessionTimerDisabled = (event: StorageEvent) => {
      if (event.key === IS_SESSION_TIMER_DISABLED) {
        setSessionTimerDisabled(event.newValue === 'true')
      }
    }

    if (!ONE_TRUST_COOKIE_BANNER_ROUTES.includes(window.location.pathname)) {
      closeOneTrustBanner()
    }

    addEventListener('storage', toggleIsSessionTimerDisabled)

    return () => {
      removeEventListener('storage', toggleIsSessionTimerDisabled)
    }
  }, [])

  // Round down session duration to nearest second to ensure FE doesn't time out after BE session
  const inactivityExpiration = sessionRemainingTime > 0 ? Math.floor(sessionRemainingTime / 1000) * 1000 : 0
  const { panResponder, timeInactivityWarning, lastInteraction, setActive } = useActivity(
    isUserLoggedIn && !isIntakeFormOpen && !(isSessionTimerDisabled || videoSessionOpen) ? inactivityExpiration : null,
    INACTIVITY_CHECK_INTERVAL_MS,
    INACTIVITY_WARNING_DURATION_MS,
    USER_ACTIVITY_THROTTLE_MS,
  )

  const shouldFetchUserDuration = !!isSessionTimerEnabled && isUserLoggedIn
  useFetcher([getUserRemainingTime, null, shouldFetchUserDuration], [shouldFetchUserDuration, lastInteraction])

  // Resets the user's activity when the user dismisses the inactivity modal
  useEffect(() => {
    if (timeLastActive) {
      setActive()
    }
  }, [timeLastActive, setActive])

  // Sets the timestamp of when the user should be warned of becoming inactive and triggers the inactivity modal to appear
  useEffect(() => {
    setTimeInactivityWarning(timeInactivityWarning)
  }, [setTimeInactivityWarning, timeInactivityWarning])

  useEffect(() => {
    if (allowDemoBeforeLaunch) {
      setBypassComingSoonBeforeLaunch(true)
    }
  }, [allowDemoBeforeLaunch, setBypassComingSoonBeforeLaunch])

  if (!compatibilityUtils.isCompatibleWithUnprefixedWebRTC) {
    return <UnsupportedBrowser />
  }

  // The SSO iframes for google and apple SSO, render in the main AppContainer
  // but they dont need to wait for any user info so we can skip loading
  const isInSSOIframe = window.location.pathname.includes(PAGE_ROUTES.SSO_REGISTRATION)

  if (
    (!isInSSOIframe &&
      (!hasFetchedUser ||
        !mixpanelInitialized ||
        isAuthenticating ||
        isFetchingCsrfToken ||
        !isLaunchDarklyIdentified)) ||
    (requireEligibilityConfirmation?.isEnabled && isLoadingEligibility)
  ) {
    return (
      <div style={{ marginTop: '50px', textAlign: 'center' }}>
        <LoadingIndicator size={45} />
      </div>
    )
  }

  // Used to enable automatic switching from coming soon page to full service based on Launch Date.
  if (beforeLaunch || isRegistrationAndLoginDisabled) {
    return <ComingSoon />
  }

  return <View {...panResponder?.panHandlers}>{children}</View>
}

const mapStateToProps = (state: $TSFixMe) => {
  const customerPrograms = getProgramCoverageBreakdown(state)?.toJS()
  return {
    user: state.get('user'),
    userCountryCode: getUserCountryCode(state),
    customerName: getCustomerName(state),
    mixpanelToken: state.getIn(['appGlobals', 'mixPanelToken']),
    hasFetchedUser: state.getIn(['login', 'hasFetchedUser'], false),
    beforeLaunch: getBeforeLaunch(state),
    customerCountryList: getCountryListForCustomerCountry(state),
    supportedCountries: getSupportedCountryList(state),
    oktaBaseUrl: getOktaBaseUrl(state),
    oktaClientId: getOktaClientId(state),
    isGoogleSSODisabled: getIsGoogleSSODisabled(state),
    isUserLoggedIn: getIsUserLoggedIn(state),
    timeLastActive: getTimeLastActive(state),
    sessionRemainingTime: getUserRemainingTimeSelector(state),
    videoSessionOpen: state.getIn(['lyraTherapy', 'video', 'sessionOpen']),
    isLoadingEligibility: getIsEligibilityStatusInitialLoading(state),
    optOutOfEAPEligibilityCheck: getIsCustomerOptedOutOfEligibilityCheck(state),
    isCustomerHPIExclusive: areAllCustomerProgramsBHBExclusive(customerPrograms),
    approveClaimsWithNoEligibilityCheck: getCustomerApproveWithNoEligibilityCheck(state),
    isUserInternational: getIsUserInternational(state),
    isIntakeFormOpen: getIntakeFormOpen(state),
    healthPlanEnabled: getHealthPlanExtensionEnabled(state),
    isCoachingEnabled: getIsCoachingEnabled(state),
    essentialsDisabled: getEssentialsDisabled(state),
    customerHasAssertiveTriage: getShouldSeeCareAdvocateRequestForm(state),
    hideDependentField: getHideDependentField(state),
    oktaAppleSSOClientId: state.getIn(['appGlobals', 'oktaAppleSSOClientId'], ''),
    oktaGoogleSSOClientId: state.getIn(['appGlobals', 'oktaGoogleSSOClientId'], ''),
    hashedUserId: state.getIn(['appGlobals', 'hashedLyraId'], ''),
  }
}

const connector = connect(mapStateToProps, {
  getBaseApplicationData,
  getEAPEligibility,
  trackEventWithObj,
  login,
  markUserAsStale,
  getCsrf,
  getCustomerCountryList,
  getSupportedCountries,
  getOktaSession,
  fetchDetectedCountry,
  setFetchDetectedCountryRequestStatus,
  getUserRemainingTime,
  setTimeInactivityWarning,
  setFeatureFlags,
  setBypassComingSoonBeforeLaunch,
  setSsoIdentityProvider,
  setSsoToken,
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: AppConta... Remove this comment to see the full error message
export default connector(toJS(AppContainer))
