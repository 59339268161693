import { createAsyncThunk } from '@reduxjs/toolkit'

import { WellnessCheckInRequestData, WellnessCheckInResultsData } from '@lyrahealth-inc/shared-app-logic'

import { getWellnessCheckInResults, postWellnessCheckInResult } from '../../../common/http/data/wellnessCheckIn'

export const getWellnessCheckInResultsData = createAsyncThunk<WellnessCheckInResultsData[], { lyraId: string }>(
  'GET_WELLNESS_CHECK_IN_RESULTS',
  async ({ lyraId }, { rejectWithValue }) => {
    try {
      const response = await getWellnessCheckInResults(lyraId)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const postWellnessCheckInResultsData = createAsyncThunk<
  WellnessCheckInResultsData,
  { lyraId: string; requestBody: WellnessCheckInRequestData }
>('POST_WELLNESS_CHECK_IN_RESULTS', async ({ lyraId, requestBody }, { rejectWithValue }) => {
  try {
    const response = await postWellnessCheckInResult(lyraId, requestBody)
    return response.data[0]
  } catch (error) {
    return rejectWithValue(error)
  }
})
