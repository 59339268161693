import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { useMediaQuerySize } from '../../hooks'
import { SelfCareWellnessTopicApiResponse } from '../../pages'
import { SelfCareWellnessTopicContent } from '../../pages/selfCareWellnessTopic/SelfCareWellnessTopic'
import { ThemeType, tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { Image } from '../image/Image'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'

export type SelfCareWellnessTopicCardProps = SelfCareWellnessTopicApiResponse & {
  onClick: (content: SelfCareWellnessTopicContent) => void
  isSelected: boolean
}

const ImageContainer = styled.View(({ theme }) => ({
  margin: `0 auto ${theme.spacing['8px']} auto`,
}))

const TouchableOpacityItem = styled(PressableOpacity)<{
  hoverIn?: boolean
  theme: ThemeType
  isSelected?: boolean
  isHidden?: boolean
}>(({ hoverIn, theme, isSelected, isHidden }) => ({
  backgroundColor: isSelected ? theme.colors.backgroundHighlightPrimary : theme.colors.backgroundPrimary,
  border: isSelected || hoverIn ? `1px solid ${theme.colors.borderHover}` : `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '16px',
  width: theme.breakpoints.isMinWidthDesktop ? '224px' : '100%',
  padding: theme.spacing['16px'],
  height: '100%',
  justifyContent: 'center',
  display: isHidden ? 'none' : 'flex',
}))

export const SelfCareWellnessTopicCard: FunctionComponent<SelfCareWellnessTopicCardProps> = ({
  title,
  imageUrl,
  onClick,
  isSelected,
  id,
  slug,
}) => {
  const IMAGE_WIDTH = 64
  const { isMinWidthTablet } = useMediaQuerySize()
  const [hoverIn, setHoverIn] = useState(false)
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  return (
    <TouchableOpacityItem
      onPress={() => onClick({ title, id, slug })}
      testID={tID(`SelfCareWellnessTopicCard`)}
      isSelected={isSelected}
      onHoverIn={() => setHoverIn(true)}
      onHoverOut={() => setHoverIn(false)}
      hoverIn={hoverIn}
      isHidden={title === ''}
    >
      <ImageContainer>
        <Image
          source={imageUrl as ImageSourcePropType}
          style={{
            width: IMAGE_WIDTH,
            height: IMAGE_WIDTH,
          }}
          contentFit='cover'
          accessibilityLabel={formatMessage({
            defaultMessage: 'Illustration of a person and their mind',
            description: 'Accessibility label for the self care wellness illustration',
          })}
          accessibilityIgnoresInvertColors
        />
      </ImageContainer>
      <BodyText
        numberOfLines={isMinWidthTablet ? 1 : 2}
        text={title}
        color={colors.textSecondary}
        textAlign={'center'}
      />
    </TouchableOpacityItem>
  )
}
