import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import butterflyAndFlowerUrl from '../../assets/butterflyAndFlower.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type ButterflyAndFlowerIllustrationProps = {
  height?: number
  width?: number
}

const ImageContainer = styled(Image)<{ imageHeight: string; imageWidth: string }>(({ imageHeight, imageWidth }) => ({
  height: imageHeight,
  width: imageWidth,
}))

/**
 * Illustration used for after session ends and user has no additional session booked
 */
export const ButterflyAndFlowerIllustration: FunctionComponent<ButterflyAndFlowerIllustrationProps> = ({
  height = 118,
  width = 124,
}) => {
  const { formatMessage } = useIntl()
  const heightInPixels = `${height}px`
  const widthInPixels = `${width}px`
  return (
    <ImageContainer
      imageHeight={heightInPixels}
      imageWidth={widthInPixels}
      testID={`${tID('ButterflyAndFlowerIllustration')}`}
      source={butterflyAndFlowerUrl as ImageSourcePropType}
      ariaLabel={formatMessage({
        defaultMessage: 'Illustration of a flower and a butterfly',
        description: 'Accessibility label for illustration',
      })}
    />
  )
}
