import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  PressableOpacity,
  ThemeType,
  tID,
  WellnessCheckInResultsDomainCardScore,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { ResultsLineIcon } from '../../atoms/icons/ResultsLineIcon'
import { WellnessCheckInResultsDomainCardScorePreview } from '../wellnessCheckInResultsDomainCardScorePreview/WellnessCheckInResultsDomainCardScorePreview'

export interface WellnessCheckInResultsDomainCardProps {
  title?: MessageDescriptor
  score: WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE
  isActive: boolean
  onPress: () => void
  domain: string
  isPreview: boolean
}

const CardContainer = styled(PressableOpacity)<{ theme: ThemeType; isActive: boolean; isHovered: boolean }>(
  ({ theme, isActive, isHovered }) => ({
    padding: theme.spacing['8px'],
    width: '224px',
    height: '58px',
    flexDirection: 'row',
    boxShadow: isActive || isHovered ? `0 2px 10px ${theme.colors.shadowLow}` : 'none',
    borderRadius: '8px',
    backgroundColor: theme.colors.backgroundPrimary,
  }),
)

const TextContainerWithScore = styled(View)({
  flex: 1,
})

const TextContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing['4px'],
}))

const IconContainer = styled(View)<{ theme: ThemeType; isActive: boolean }>(({ theme, isActive }) => ({
  marginRight: theme.spacing['12px'],
  opacity: isActive ? 1 : 0,
}))

export const WellnessCheckInResultsDomainCard: FunctionComponent<WellnessCheckInResultsDomainCardProps> = ({
  title,
  score,
  isActive,
  onPress,
  domain,
  isPreview,
}) => {
  const [isHoveredIn, setIsHoveredIn] = useState(false)

  return (
    <CardContainer
      testID={tID(`WellnessCheckInResultsDomainCard-${domain}`)}
      onPress={isPreview ? undefined : onPress}
      isActive={isActive}
      isHovered={isHoveredIn}
      onHoverIn={() => setIsHoveredIn(true)}
      onHoverOut={() => setIsHoveredIn(false)}
    >
      <IconContainer isActive={isActive}>
        <ResultsLineIcon />
      </IconContainer>
      <TextContainerWithScore>
        <TextContainer numberOfLines={1} text={<FormattedMessage {...title} />} size={BodyTextSize.SMALL} />
        {isPreview ? (
          <WellnessCheckInResultsDomainCardScorePreview />
        ) : (
          <WellnessCheckInResultsDomainCardScore score={score} />
        )}
      </TextContainerWithScore>
    </CardContainer>
  )
}
