import React from 'react'

import { WalmartEligibilityErrorMessage, WalmartEligibilityErrorMessageProps } from './WalmartEligibilityErrorMessage'
import { InlineErrorBanner } from '../../../molecules'

export interface WalmartEligibilityErrorBannerProps extends WalmartEligibilityErrorMessageProps {
  isDependent?: boolean
}

export const WalmartEligibilityErrorBanner: React.FC<WalmartEligibilityErrorBannerProps> = ({
  isDependent,
  ...rest
}) => {
  const text = <WalmartEligibilityErrorMessage {...rest} />

  return (
    <InlineErrorBanner
      text={text}
      testId={`EmployeeInfoFor${isDependent ? 'Dependent' : 'Employee'}Eligibility-tips`}
    />
  )
}
