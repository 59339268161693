import { useCallback, useEffect, useState } from 'react'
import { PanResponder, PanResponderInstance } from 'react-native'

import { useThrottledCallback } from '@lyrahealth-inc/shared-app-logic'

import { useInterval } from './useInterval'

/**
 * A hook to track the activity of the user
 * Code is a refactor of: https://snack.expo.io/SyGLEApZb
 *
 * This hook returns the PanResponderInstance which needs to be passed in the
 * react native component to track activity
 *
 * i.e
 *
 * <View {...panResponder.panHandlers}>
 *  {children}
 * </View>
 * @param inactivityTimeoutMs Time (ms) user can be inactive before the app timeouts
 * @param inactivityInterval Interval time for checking inactivity
 * @param inactivityWarningDuration Amount of time (ms) user should see a logout warning
 * @param userActivityThrottle Time (ms) to throttle calls to setActive on user events
 */
export const useActivity = (
  inactivityTimeoutMs: null | number,
  inactivityInterval = 500,
  inactivityWarningDuration = 60000,
  userActivityThrottle = 0,
): UseActivityReturn => {
  const [timeWentInactive, setTimeWentInactive] = useState<number | null>(null)
  const [timeInactivityWarning, setTimeInactivityWarning] = useState<number | null>(null)
  const [lastInteraction, setLastInteraction] = useState(Date.now())
  const [panResponder, setPanResponder] = useState<PanResponderInstance | null>(null)
  // by default, the clock will start ticking for tracking inactivity on mount
  const [shouldTick, setShouldTick] = useState(false)

  const setInactive = () => {
    setTimeWentInactive(Date.now())
    setShouldTick(false)
  }

  // track if the user's last interaction is past the allowed time for inactivity
  const setInactivityTimer = () => {
    const msSinceLastInteraction = Date.now() - lastInteraction
    if (inactivityTimeoutMs && msSinceLastInteraction >= inactivityTimeoutMs) {
      setInactive()
    }
    if (
      !timeInactivityWarning &&
      inactivityTimeoutMs &&
      msSinceLastInteraction >= inactivityTimeoutMs - inactivityWarningDuration
    ) {
      setTimeInactivityWarning(lastInteraction + (inactivityTimeoutMs - inactivityWarningDuration))
    }
  }

  const [onUserActivity] = useThrottledCallback(
    () => {
      setLastInteraction(Date.now())
    },
    userActivityThrottle,
    true,
  )

  const setActive = useCallback(() => {
    onUserActivity()
    // reset if user is active again
    if (timeWentInactive) {
      setTimeWentInactive(null)
    }
    setTimeInactivityWarning(null)

    setShouldTick(true)
  }, [onUserActivity, timeWentInactive])

  // once timeForInactivity has a value, we start tracking lastInteraction and timeWentInactive
  // this is similar to how useInterval works
  useEffect(() => {
    if (inactivityTimeoutMs) {
      setActive()
    }
  }, [inactivityTimeoutMs, setActive])

  useEffect(() => {
    // this handler is responsible for updating state for user being active
    const resetInactivityTimeout = () => {
      setActive()
      return false
    }
    // create the PanResponderInstance
    setPanResponder(
      PanResponder.create({
        onStartShouldSetPanResponder: resetInactivityTimeout,
        onMoveShouldSetPanResponder: resetInactivityTimeout,
        onStartShouldSetPanResponderCapture: resetInactivityTimeout,
        onMoveShouldSetPanResponderCapture: () => false,
        onPanResponderTerminationRequest: () => true,
        onShouldBlockNativeResponder: () => false,
      }),
    )
  }, [setActive])

  // tick within the inactivity interval
  useInterval(setInactivityTimer, shouldTick ? inactivityInterval : null)

  return {
    panResponder,
    timeWentInactive,
    timeInactivityWarning,
    lastInteraction,
    setActive,
  }
}

type UseActivityReturn = {
  panResponder: PanResponderInstance | null
  timeWentInactive: number | null
  /** Timestamp for when the user should be warned of the app timing out due to inactivity */
  timeInactivityWarning: number | null
  lastInteraction: number
  setActive: () => void
}
