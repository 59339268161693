import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface IllustrationBadgeProps {
  text: React.ReactNode
  icon: React.ReactNode
  testId?: string
  topOffset: number
  leftOffset: number
}

const IllustrationBadgeText = styled(View)<{
  theme: ThemeType
  textColor?: string
  topOffset?: number
  leftOffset?: number
}>(({ theme, textColor, topOffset, leftOffset }) => {
  return {
    height: 25,
    width: 25,
    flexShrink: 1,
    ...(textColor && { color: textColor }),
    backgroundColor: theme.colors.backgroundPrimary,
    border: `1px solid ${theme.colors.borderDefault}`,
    position: 'absolute',
    zIndex: 1,
    borderRadius: 20,
    top: topOffset,
    left: leftOffset,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export const IllustrationBadge: FunctionComponent<IllustrationBadgeProps> = ({
  text,
  icon,
  testId = 'IllustrationBadge',
  topOffset,
  leftOffset,
}) => {
  return (
    <View testID={tID(testId)}>
      <IllustrationBadgeText topOffset={topOffset} leftOffset={leftOffset}>
        {text}
      </IllustrationBadgeText>
      <View>{icon}</View>
    </View>
  )
}
