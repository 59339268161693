import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const NicotineQuitlineIllustration: FunctionComponent<Props> = ({ size = 125 }) => {
  return (
    <Svg width={size} height={Number(size) + 1} viewBox='0 0 45 46' fill='none'>
      <G clip-path='url(#clip0_297_1914)'>
        <Path
          d='M22.6578 0C19.5517 0 16.5381 0.532655 13.7005 1.58285C6.94495 4.08695 2.19552 9.63759 0.669586 16.8138C-0.338813 21.492 -0.209873 25.8191 1.06619 30.0359C2.69527 35.4211 6.69419 39.9625 12.0376 42.495C14.6902 43.7551 17.7377 44.4745 21.0937 44.6327C21.5249 44.6523 21.9633 44.663 22.3991 44.663C25.0917 44.663 27.8003 44.2939 30.4502 43.5665C30.6539 43.5159 30.8477 43.4634 31.0523 43.4029C33.4336 42.7058 35.5767 41.6031 37.4103 40.1296C39.4013 38.5388 41.0402 36.522 42.2762 34.1388C42.5199 33.6693 42.7529 33.1749 43.0099 32.5835C44.4238 29.3005 45.1067 25.6252 44.9858 21.9509C44.9288 20.3263 44.687 18.5993 44.269 16.8138C43.5808 13.9166 42.4585 11.192 41.0233 8.92975C40.6303 8.3224 40.2692 7.81198 39.9118 7.35313C37.3321 4.02559 33.3127 1.69045 28.2921 0.603794C26.4478 0.203636 24.5519 0.00088924 22.6587 0.00088924L22.6578 0Z'
          fill='white'
        />
        <Path
          d='M43.0561 22.0114C43.0009 20.4543 42.7626 18.8412 42.3874 17.2379C41.7738 14.6547 40.7654 12.1079 39.3826 9.92749C39.0616 9.43307 38.7326 8.95644 38.3751 8.49848C35.7821 5.15494 31.8525 3.29464 27.8305 2.42408C23.3505 1.45303 18.5869 1.7367 14.2803 3.3311C8.04936 5.63957 3.80768 10.6967 2.42403 17.2015C1.89226 19.6656 1.73664 22.4507 1.97496 25.1264H1.95629C2.09412 26.647 2.36889 28.1311 2.78061 29.4961C4.31099 34.5532 8.0298 38.5664 12.7668 40.8108C15.3322 42.0291 18.1991 42.6337 21.1132 42.7716C24.0904 42.9094 27.1138 42.5333 29.9087 41.7641C30.0918 41.7187 30.2661 41.6725 30.4493 41.6173C32.6386 40.9762 34.5443 39.9865 36.1751 38.6766C37.9527 37.2565 39.4182 35.47 40.5546 33.2807C40.8019 32.8041 41.0215 32.3283 41.2322 31.8419C42.5697 28.7358 43.1655 25.3647 43.0463 22.0212L43.0552 22.0123L43.0561 22.0114Z'
          fill='#FFE9E3'
        />
        <Mask id='mask0_297_1914' maskUnits='userSpaceOnUse' x='1' y='1' width='43' height='42'>
          <Path
            d='M43.0561 22.0114C43.0009 20.4543 42.7626 18.8412 42.3874 17.2379C41.7738 14.6547 40.7654 12.1079 39.3826 9.92749C39.0616 9.43307 38.7326 8.95644 38.3751 8.49848C35.7821 5.15494 31.8525 3.29464 27.8305 2.42408C23.3505 1.45303 18.5869 1.7367 14.2803 3.3311C8.04936 5.63957 3.80768 10.6967 2.42403 17.2015C1.89226 19.6656 1.73664 22.4507 1.97496 25.1264H1.95629C2.09412 26.647 2.36889 28.1311 2.78061 29.4961C4.31099 34.5532 8.0298 38.5664 12.7668 40.8108C15.3322 42.0291 18.1991 42.6337 21.1132 42.7716C24.0904 42.9094 27.1138 42.5333 29.9087 41.7641C30.0918 41.7187 30.2661 41.6725 30.4493 41.6173C32.6386 40.9762 34.5443 39.9865 36.1751 38.6766C37.9527 37.2565 39.4182 35.47 40.5546 33.2807C40.8019 32.8041 41.0215 32.3283 41.2322 31.8419C42.5697 28.7358 43.1655 25.3647 43.0463 22.0212L43.0552 22.0123L43.0561 22.0114Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_297_1914)'>
          <Path
            d='M22.4639 45.6999C34.8572 45.6999 44.9039 35.6532 44.9039 23.2599C44.9039 10.8667 34.8572 0.819946 22.4639 0.819946C10.0706 0.819946 0.0239258 10.8667 0.0239258 23.2599C0.0239258 35.6532 10.0706 45.6999 22.4639 45.6999Z'
            fill='#FFE9E3'
          />
        </G>
        <Path
          d='M36.6429 21.6601V25.1708C36.6429 25.6074 36.1663 25.9622 35.5785 25.9622H9.68918C9.1005 25.9622 8.62476 25.6074 8.62476 25.1708V21.6601C8.62476 21.2226 9.10139 20.8687 9.68918 20.8687H35.5785C36.1672 20.8687 36.6429 21.2226 36.6429 21.6601Z'
          fill='#FFA338'
        />
        <Path
          d='M36.6429 21.6601V25.1708C36.6429 25.6074 36.1662 25.9622 35.5785 25.9622H33.6924V20.8687H35.5785C36.1671 20.8687 36.6429 21.2226 36.6429 21.6601Z'
          fill='#E77B56'
        />
        <Path
          d='M23.5204 9.71586C23.2029 10.8523 23.1576 12.1195 23.4359 13.0887C23.6804 13.9415 24.1713 14.821 25.1521 15.0753C27.2152 15.6106 29.1066 15.0317 31.1474 15.9512C33.1882 16.8707 33.4265 18.8599 33.4265 18.8599C33.4265 18.8599 35.3997 14.0989 32.8396 12.6966C30.2795 11.2943 27.2872 12.4245 26.5242 10.9208C26.0841 10.0529 25.6083 8.74392 25.2695 8.40423C24.3109 7.44207 23.5204 9.71586 23.5204 9.71586Z'
          fill='white'
        />
        <Path
          d='M29.1342 36.4393C28.6309 36.4393 28.1453 36.1628 27.9026 35.6817L16.1406 12.3925C15.7973 11.7131 16.0703 10.8844 16.7497 10.5411C17.4291 10.1979 18.2579 10.4709 18.6011 11.1502L30.3631 34.4394C30.7063 35.1188 30.4333 35.9476 29.754 36.2908C29.5548 36.3913 29.3423 36.4393 29.1342 36.4393Z'
          fill='#00A4B3'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_297_1914'>
          <Rect width='45' height='45.6998' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
