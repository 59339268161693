import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const LyraSelectSelfCareValueSpotIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 96 97' fill='none'>
      <Mask
        id='mask0_2568_117579'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='97'
        height='96'
      >
        <Path
          d='M48.3609 0.629883C41.7344 0.629883 35.3053 1.76622 29.2517 4.00666C14.8397 9.34879 4.70752 21.1922 1.45216 36.4996C-0.69911 46.48 -0.424036 55.7112 2.29825 64.707C5.77367 76.1957 14.3048 85.884 25.7042 91.2868C31.3632 93.9749 37.8644 95.5097 45.0239 95.8473C45.944 95.8891 46.8793 95.9118 47.8088 95.9118C53.5531 95.9118 59.3316 95.1246 64.9848 93.5728C65.4193 93.4646 65.8328 93.3527 66.2692 93.2237C71.3495 91.7364 75.9214 89.384 79.8332 86.2406C84.0807 82.8468 87.577 78.5442 90.2139 73.4601C90.7337 72.4584 91.2307 71.4037 91.779 70.1421C94.7953 63.1382 96.2523 55.2976 95.9943 47.4589C95.8728 43.993 95.3568 40.3089 94.4652 36.4996C92.9969 30.3189 90.6028 24.5063 87.5409 19.6802C86.7024 18.3845 85.9322 17.2956 85.1696 16.3167C79.6662 9.21789 71.0915 4.2362 60.3807 1.91799C56.4462 1.06431 52.4016 0.63178 48.3628 0.63178L48.3609 0.629883Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_2568_117579)'>
        <Path
          d='M48.5809 51.9757C48.8865 48.8827 49.6541 46.5346 50.0388 45.6218C49.803 46.1801 47.372 45.3069 46.7874 45.1484C44.0753 51.0478 44.0263 69.2022 45.0692 75.6107C45.6128 78.5336 49.076 79.2982 48.828 76.0407C47.8869 71.4993 47.1476 66.509 48.5809 51.9757Z'
          fill='#127719'
        />
        <Path
          d='M54.913 57.4996C49.1268 61.315 46.9938 67.7481 46.6864 69.8962C46.66 70.0829 46.6864 70.3413 46.875 70.3658C46.9523 70.3771 47.0202 70.3186 47.0956 70.2979C51.5484 69.1663 58.634 66.2317 65.8856 54.02C65.9497 53.9125 66.0648 53.788 65.9761 53.6994C65.7291 53.739 61.4272 53.2034 54.9111 57.4978L54.913 57.4996Z'
          fill='#127719'
        />
        <Path
          d='M35.0228 56.4514C38.1271 60.8627 43.7813 64.8006 44.9544 63.8123C46.3519 62.6355 43.3551 57.2529 42.8496 56.5683C36.6221 48.121 31.8959 47.2629 31.3131 47.5477C30.0533 48.1663 34.0591 55.0821 35.0228 56.4514Z'
          fill='#127719'
        />
        <Path
          d='M46.2031 43.5797C45.5675 43.574 45.2658 43.491 45.2148 42.9781C45.7052 42.8309 45.8617 43.1459 46.2031 43.5797Z'
          fill='#FFF5DB'
        />
        <Path
          d='M47.2831 44.3943C46.9531 44.4113 46.6306 44.4188 46.5684 43.9605C46.8984 43.6682 47.0531 43.9662 47.2398 44.1529C47.3114 44.1944 47.3416 44.2453 47.3303 44.3019C47.319 44.3585 47.3039 44.3887 47.2831 44.3943Z'
          fill='#FFECB9'
        />
        <Path
          d='M38.332 37.0651C37.3192 34.6322 36.7403 32.1088 37.9058 29.7042C38.9997 27.4485 40.6159 25.621 43.0036 24.6912C45.0461 23.8954 47.6487 23.9897 49.1104 25.6248C50.6342 23.8897 53.3067 23.2786 55.4341 24.1783C57.5614 25.0779 58.9835 27.4184 58.8024 29.7211C60.3451 29.227 62.1123 30.0493 63.0006 31.4015C64.9318 34.3399 64.0831 38.7983 62.3235 41.5632C60.3055 44.7335 56.9089 46.9043 53.1803 47.3814C49.084 47.9076 42.7433 47.6342 39.5862 44.5694C38.2189 43.2417 37.34 41.467 36.8911 39.615C36.7478 39.0228 36.6497 38.3834 36.8779 37.8195C37.1061 37.2556 37.7719 36.8294 38.3339 37.0651H38.332Z'
          fill='#F47352'
        />
        <Path
          d='M50.9027 29.5988C52.0947 29.1575 53.511 29.9118 54.2032 31.017C55.3782 32.8917 53.0867 35.1624 51.1969 34.2571C49.9748 33.6706 48.9809 31.4225 49.9956 30.2457C50.2672 29.9307 50.5765 29.7214 50.9008 29.6007L50.9027 29.5988Z'
          fill='#FFD974'
        />
      </G>
    </Svg>
  )
}
