import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const refactor219: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you a founder or employee of a Refactor-backed company?',
        description: 'Asking if the user is a founder or employee of a company backed by Refactor',
      }),
  }
}
