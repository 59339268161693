import React, { FunctionComponent } from 'react'

import { ChildCareIllustrationProps } from './ChildCareIllustrationSvg'
import { withSuspense } from '../../utils/withSuspense'

const ChildCareIllustrationSvg = withSuspense({
  component: React.lazy(() => import('./ChildCareIllustrationSvg')),
})

export const ChildCareIllustration: FunctionComponent<ChildCareIllustrationProps> = ({ width, height }) => {
  return <ChildCareIllustrationSvg width={width} height={height} />
}
