import { defineMessage } from 'react-intl'

import {
  OPTIONS_TYPE,
  PreferenceMetadataConfig,
  RENDER_CONDITION_TARGETS,
  SHOULD_EXPAND_ACCORDION_CONDITION_TARGETS,
} from './types'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../constants'

const availabilitiesPreferenceCoaching: PreferenceMetadataConfig = {
  name: 'availabilities',
  title: MEMBER_PREFERENCE_LABELS.DAY_AND_TIME,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.flexible,
      id: MEMBER_PREFERENCE_IDS.FLEXIBLE,
      defaultValue: [MEMBER_PREFERENCE_IDS.FLEXIBLE],
      shouldRenderSubtitle: () => false,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.DAY_AND_TIME,
      id: MEMBER_PREFERENCE_IDS.DAY_AND_TIME,
      defaultValue: [],
      shouldRenderSubtitle: (val) => Array.isArray(val) && val.length !== 0,
    },
  ],
  optionType: OPTIONS_TYPE.RADIO,
  conditionalChildComponent: {
    componentName: 'AvailabilityCalendar',
    optionId: MEMBER_PREFERENCE_IDS.DAY_AND_TIME,
  },
  icon: 'MemberPreferencesAvailabilityIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'Select when you’d prefer to meet for your first 45 min appointment:',
    description:
      'Text prompting the user to determine their preferred date and time with a provider for their first 45 minute appointment',
  }),
  visibleFor: [S4C_PROGRAM_OPTION_FLOW.COACHING, S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS],
  renderConditions: [
    {
      target: RENDER_CONDITION_TARGETS.FEATURE_FLAG,
      fn: (condition) => condition === true,
    },
  ],
  expandConditions: [
    {
      target: SHOULD_EXPAND_ACCORDION_CONDITION_TARGETS.WHEN_USER_VISITS_PREFERENCES_FROM_CONNECTED_CALENDAR,
      fn: (condition) => condition === true,
    },
  ],
}

export default availabilitiesPreferenceCoaching
