import React, {
  forwardRef,
  ReactChild,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  KeyboardAvoidingView,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
} from 'react-native'

import { differenceInMonths } from 'date-fns'
import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import { BlockedChatView, BodyText, Link, Message, MessageAttachmentData } from '../..'
import { LoadingIndicator } from '../../atoms'
import { Divider } from '../../atoms/divider/Divider'
import { Avatar, avatarAltTextGeneric } from '../../atoms/icons/Avatar'
import { Image as ExpoImage } from '../../atoms/image/Image'
import { NewMessageIndicator } from '../../atoms/newMessageIndicator/NewMessageIndicator'
import { TypingIndicator } from '../../atoms/typingIndicator/TypingIndicator'
import { EmptyMessagesView } from '../../molecules/emptyMessagesView/EmptyMessagesView'
import { ChatHeader, CloseStyles } from '../../organisms/chatHeader/ChatHeader'
import { ChatInput } from '../../organisms/chatInput/ChatInput'
import { Messages } from '../../organisms/messages/Messages'
import { Flex1View } from '../../templates/content/CommonViews'
import { ActivityReviewInfo } from '../../ui-models/assignments/Assignments'
import { ThemeType, tID } from '../../utils'

const IS_WEB = Platform.OS === 'web'
const SCROLL_THRESHOLD = 50

export type TrackingChatMessageInfo = {
  hasProgramBookingLink: boolean
}

export type ChatProps = {
  displayName: string
  banner?: ReactChild | null
  chatHeaderTitle: string
  chatHeaderSubTitleMsg?: string
  liveSessionBadge: string
  onBack?: () => void
  messages: Message[]
  onSendMessage: (text: string) => void
  onInputChange: (text?: string) => void
  avatarImage?: string
  receiverTyping: boolean
  receiverMsgable: boolean
  receiverBlocked?: boolean
  showChevron?: boolean
  showNewMsgIndicator?: boolean
  scrollInfo?: ChatScrollInfo
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  onNewMsgPressed?: () => void
  unreadMsgCount?: number
  onChatBubblePressed?: (messageId: string, receiver: boolean, activityInfo?: ActivityReviewInfo) => void
  onLinkPress?: (link: string) => void
  closeStyle?: CloseStyles
  chatHeaderCentered?: boolean
  inputValue?: string
  conversationDateCreated?: string
  isLoading?: boolean
  inputActions?: ReactNode[]
  inputAttachments?: ReactNode[]
  markMessagesAsUnread?: () => void
  showMenu?: boolean
  shouldInvert?: boolean
  showClose?: boolean
  onChevronPressed?: () => void
  onContactCareNavigatorPressed?: () => void
  showBlockedChatView?: boolean
  sendOnEnter?: boolean
  onAttachmentPressed?: (attachment: MessageAttachmentData, item: Message) => void
  contactCarePhoneNumber?: string
  showHeaderDivider?: boolean
  hideChatHeader?: boolean
  expandedButton?: boolean
  showEmptyChatView?: boolean
  disableSend?: boolean
  onReachedMessagesEnd?: () => void
  isLoadingNextPage?: boolean
}

export type ChatHandle = {
  focusInput: () => void
}

export interface ChatScrollInfo {
  scrollToEnd: boolean
  animatedScroll: boolean
}

const TypingIndicatorContainer = styled(TypingIndicator)<{ receiverTyping: boolean }>(({ theme, receiverTyping }) => ({
  marginLeft: receiverTyping ? theme.spacing['16px'] : 0,
}))

const Container = styled(Flex1View)(({ theme }) => ({ backgroundColor: theme.colors.backgroundPrimary, flexShrink: 1 }))

const ReceiverTypingContainer = styled.View({ flexDirection: 'row' })

const NewMessageIndicatorContainer = styled.View<{ chatInputHeight: number }>(({ chatInputHeight }) => ({
  bottom: Platform.OS === 'web' ? '10px' : 24 + chatInputHeight,
  alignSelf: 'center',
  justifyContent: 'center',
  position: 'absolute',
}))

const AvatarContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
}))

const NoPreviousMessageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  color: `${theme.colors.textSecondary}`,
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
}))

const LoadingContainer = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
}))

const ChatInnerFlexContainer = styled.View({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const ChatMessagesContainer = styled(Flex1View)(({ theme }) => ({
  overflow: 'hidden',
  flexGrow: 1,
  flexShrink: 1,
  ...(IS_WEB && theme.breakpoints.isMobileSized && { height: '75vh' }),
}))

const ProviderUnavailableMessageContainer = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  flexDirection: 'row',
  padding: theme.spacing['8px'],
  maxHeight: IS_WEB ? '100px' : '130px',
}))

const UnavailableImageContainer = styled.View({
  overflow: 'hidden',
})

const EmptyChatView = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
}))

const Image = styled(ExpoImage)({
  width: '80px',
  height: '65px',
})

const UnavailableTextContainer = styled(Flex1View)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  flex: 1,
  marginLeft: theme.spacing['8px'],
  maxHeight: IS_WEB ? '100px' : '135px',
}))

const LoadingNextPageView = styled(Flex1View)({
  justifyContent: 'center',
  alignItems: 'center',
})
/**
 * This page is the thread conversation between a client and a provider
 * It consists of a header, a list of messages, and an input field for sending messages
 */
export const Chat = forwardRef<ChatHandle, ChatProps>(
  (
    {
      displayName,
      banner,
      chatHeaderSubTitleMsg,
      liveSessionBadge,
      onBack = noop,
      messages,
      onSendMessage,
      onInputChange,
      avatarImage,
      receiverTyping,
      receiverMsgable,
      showNewMsgIndicator,
      scrollInfo,
      onScroll,
      onNewMsgPressed,
      unreadMsgCount,
      onChatBubblePressed,
      onLinkPress,
      chatHeaderTitle,
      chatHeaderCentered,
      closeStyle,
      showClose = true,
      showChevron = false,
      inputValue,
      conversationDateCreated,
      isLoading,
      inputActions,
      inputAttachments,
      markMessagesAsUnread,
      showMenu = false,
      shouldInvert = true,
      onChevronPressed,
      receiverBlocked = false,
      onContactCareNavigatorPressed,
      showBlockedChatView = false,
      sendOnEnter,
      onAttachmentPressed,
      contactCarePhoneNumber,
      showHeaderDivider,
      hideChatHeader = false,
      expandedButton = false,
      showEmptyChatView = false,
      disableSend = false,
      onReachedMessagesEnd: onReachedChatEnd,
      isLoadingNextPage = false,
    },
    ref,
  ) => {
    const [chatInputHeight, setChatInputHeight] = useState(60)
    const scrollPosition = useRef(0)
    const [chatMessagesCount, setChatMessagesCount] = useState<number>(messages.length)
    const [markedMessagesAsUnread, setMarkedMessagesAsUnread] = useState(false)
    const chatInputRef = useRef<TextInput>(null)
    useImperativeHandle(
      ref,
      () => ({
        focusInput: () => {
          chatInputRef.current?.focus()
        },
      }),
      [],
    )
    useEffect(() => {
      if (Platform.OS === 'web') {
        setChatMessagesCount((prevState) => {
          if (messages?.length > prevState) {
            if (markedMessagesAsUnread && messages[0].authorType === 'patient') {
              setMarkedMessagesAsUnread(false)
            }
            return messages.length
          }
          return prevState
        })
      }
    }, [chatMessagesCount, markedMessagesAsUnread, messages, messages.length])

    useEffect(() => {
      if (
        Platform.OS === 'web' &&
        unreadMsgCount &&
        unreadMsgCount > 0 &&
        scrollPosition.current < SCROLL_THRESHOLD &&
        (markedMessagesAsUnread === false || !markMessagesAsUnread)
      ) {
        onNewMsgPressed && onNewMsgPressed()
      }
    }, [onNewMsgPressed, unreadMsgCount, markedMessagesAsUnread, markMessagesAsUnread])

    const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const scrollYPosition = event?.nativeEvent?.contentOffset?.y
      scrollPosition.current = scrollYPosition
      onScroll && onScroll(event)
    }

    const send = (text: string) => {
      if (!receiverMsgable) return
      onSendMessage(text)
    }

    const getReceiverView = () => {
      return receiverTyping ? (
        <ReceiverTypingContainer>
          {avatarImage && (
            <AvatarContainer>
              <Avatar
                details={{ src: avatarImage, displayName }}
                accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: displayName })}
              />
            </AvatarContainer>
          )}
          <TypingIndicatorContainer receiverTyping={receiverTyping} />
        </ReceiverTypingContainer>
      ) : null
    }

    const getNewMessageView = () => {
      return (IS_WEB &&
        scrollInfo &&
        showNewMsgIndicator &&
        unreadMsgCount &&
        scrollPosition.current &&
        scrollPosition.current > SCROLL_THRESHOLD) ||
        (!IS_WEB && scrollInfo && showNewMsgIndicator && unreadMsgCount) ? (
        <NewMessageIndicatorContainer chatInputHeight={chatInputHeight}>
          <NewMessageIndicator onPress={onNewMsgPressed} plural={unreadMsgCount !== 1} />
        </NewMessageIndicatorContainer>
      ) : null
    }

    const { formatMessage } = useIntl()
    const isConversationThirteenMonthsOld =
      conversationDateCreated && differenceInMonths(new Date(), new Date(conversationDateCreated)) > 13

    const emptyView = useMemo(
      () => <EmptyMessagesView avatarDetails={{ src: avatarImage || '', displayName }} displayName={displayName} />,
      [avatarImage, displayName],
    )
    if (isLoading) {
      return (
        <LoadingContainer>
          <LoadingIndicator size={45} />
        </LoadingContainer>
      )
    }

    const canSendMessages = !receiverBlocked && receiverMsgable
    const handleMarkMessagesAsUnread = () => {
      setMarkedMessagesAsUnread(true)
      markMessagesAsUnread && markMessagesAsUnread()
    }
    return (
      <Container testID={tID('Chat')}>
        <KeyboardAvoidingView
          behavior={Platform.select({ android: 'height', ios: 'padding' })}
          style={styles.keyboardAvoidingView}
        >
          <ChatInnerFlexContainer testID={tID('Chat-InnerFlexContainer')}>
            {!hideChatHeader && (
              <ChatHeader
                titleMsg={chatHeaderTitle}
                subTitleMsg={chatHeaderSubTitleMsg}
                textBadgeMsg={liveSessionBadge}
                onClosePress={onBack}
                closeStyle={closeStyle}
                titleCentered={chatHeaderCentered}
                showChevron={showChevron}
                showMenu={messages.length > 0 && showMenu}
                markMessagesAsUnread={handleMarkMessagesAsUnread}
                showClose={showClose}
                onChevronPressed={onChevronPressed}
                showDivider={showHeaderDivider}
              />
            )}
            {banner}
            {isConversationThirteenMonthsOld && (
              <NoPreviousMessageContainer testID={tID('Chat-noPreviousMsgs')}>
                <FormattedMessage
                  defaultMessage='Previous messages are not available. Messages are available for 13 months.'
                  description='A notice that tells the user older messages are not displayed'
                />
              </NoPreviousMessageContainer>
            )}
            {showEmptyChatView ? (
              <EmptyChatView />
            ) : !canSendMessages && showBlockedChatView ? (
              <BlockedChatView
                receiverBlocked={receiverBlocked}
                onContactCareNavigatorPressed={onContactCareNavigatorPressed}
                contactCarePhoneNumber={contactCarePhoneNumber}
              />
            ) : (
              <ChatMessagesContainer testID={tID('Chat-messages-container')}>
                <Messages
                  messages={messages}
                  onScroll={IS_WEB ? handleScroll : onScroll}
                  scrollInfo={scrollInfo}
                  listHeaderComponent={getReceiverView()}
                  onChatBubblePressed={onChatBubblePressed}
                  onLinkPress={onLinkPress}
                  NewMessageView={getNewMessageView()}
                  emptyMessagesView={emptyView}
                  shouldInvert={shouldInvert}
                  onAttachmentPressed={(attachment, item) =>
                    onAttachmentPressed && onAttachmentPressed(attachment, item)
                  }
                  onReachedEnd={onReachedChatEnd}
                  listFooterComponent={
                    isLoadingNextPage ? (
                      <LoadingNextPageView>
                        <LoadingIndicator size={24} topPadding={12} />
                      </LoadingNextPageView>
                    ) : null
                  }
                />
              </ChatMessagesContainer>
            )}

            <Divider />
            {canSendMessages && (
              <ChatInput
                ref={chatInputRef}
                onSendMessage={send}
                onInputChange={onInputChange}
                onInputHeightChange={setChatInputHeight}
                previousInputValue={inputValue}
                actions={inputActions}
                sendOnEnter={sendOnEnter}
                attachments={inputAttachments}
                expandedButton={expandedButton}
                disableSend={disableSend}
              />
            )}
            {!canSendMessages && (
              <ProviderUnavailableMessageContainer testID={tID('Chat-ProviderUnavailableMessageContainer')}>
                <UnavailableImageContainer>
                  <Image source={require('../../assets/resting_cat.png')} accessibilityIgnoresInvertColors />
                </UnavailableImageContainer>
                <UnavailableTextContainer>
                  <BodyText
                    text={
                      <FormattedMessage
                        defaultMessage='This message inbox is no longer being monitored. For assistance
            with booking appointments with a Lyra provider, please contact <link>the Lyra Care Navigator</link>'
                        description='Message when provider is blocked informing user to contact care navigator'
                        values={{
                          link: (text: string) => (
                            <Link
                              containerStyle={{ display: 'inline' }}
                              onPress={(e: any) => {
                                e.preventDefault()
                                onContactCareNavigatorPressed?.()
                              }}
                              text={text}
                            />
                          ),
                        }}
                      />
                    }
                  />
                </UnavailableTextContainer>
              </ProviderUnavailableMessageContainer>
            )}
          </ChatInnerFlexContainer>
        </KeyboardAvoidingView>
      </Container>
    )
  },
)

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
})
