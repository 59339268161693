import React, { FunctionComponent, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TextButton } from '../../atoms/textButton/TextButton'
import { Tooltip, TOOLTIP_TRIGGER_ICON } from '../../atoms/tooltip/Tooltip'
import { XButton } from '../../atoms/xButton/XButton'
import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { BenefitPreviewItem, BenefitPreviewItemProps } from '../benefitPreviewItem/BenefitPreviewItem'

export const ENTRY_CARD_DISMISSED_LOCAL_STORAGE_KEY = 'wasIntegratedBenefitsEntryCardDismissed'

type ValidBackgroundColors = 'white' | 'oatmeal'

export interface IntegratedBenefitsEntryCardProps {
  benefits: BenefitPreviewItemProps[]
  title?: React.ReactElement<typeof FormattedMessage>
  backgroundColor?: ValidBackgroundColors
  showXButton?: boolean
  showBorder?: boolean
  onPressCloseButton?: () => void
  onPressShowAllBenefits?: () => any
  onLoad?: () => void
  fullWidth?: boolean
  moreBenefitsText?: string
  moreBenefitsButtonTopPositioned?: boolean
}

const FencepostContainer = styled.View<{
  showDivider: boolean
  addBottomPadding: boolean
  addTopPadding: boolean
  theme: ThemeType
  containerBackgroundColor: ValidBackgroundColors
}>(({ showDivider, addBottomPadding, addTopPadding, containerBackgroundColor, theme: { colors, spacing } }) => ({
  borderBottomWidth: showDivider ? '2px' : 0,
  borderBottomColor:
    containerBackgroundColor === 'white'
      ? colors.components.integratedBenefitsEntryCard.fencepost.border
      : colors.borderDefault,
  paddingBottom: addBottomPadding ? spacing['16px'] : 0,
  paddingTop: addTopPadding ? spacing['16px'] : 0,
}))

const ToplineContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing['24px'],
}))

const TitleContainer = styled.View({
  flexDirection: 'row',
})

const TooltipContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['4px'],
  flexShrink: 1,
}))

const Card = styled.View<{
  theme: ThemeType
  backgroundColor: ValidBackgroundColors
  showBorder?: boolean
  fullWidth: boolean
}>(({ theme, backgroundColor, showBorder, fullWidth }) => ({
  backgroundColor: backgroundColor === 'white' ? theme.colors.backgroundPrimary : theme.colors.backgroundSection,
  borderRadius: theme.spacing['16px'],
  padding: theme.spacing['24px'],
  border: showBorder ? `1px solid ${theme.colors.borderDefault}` : 'none',
  flexGrow: 1,
  flexShrink: 1,
  ...(!fullWidth && { maxWidth: '544px' }),
}))

const ShowAllBenefitsButton = styled(TextButton)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['24px'],
  alignItems: 'flex-end',
}))

const ShowAllBenefitsButtonTop = styled(TextButton)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: 0,
  margin: 0,
  top: `-${spacing['48px']}`,
}))

export const IntegratedBenefitsEntryCard: FunctionComponent<IntegratedBenefitsEntryCardProps> = ({
  title,
  benefits,
  backgroundColor = 'white',
  showBorder = true,
  showXButton = true,
  onPressCloseButton = () => null,
  onPressShowAllBenefits,
  onLoad = () => null,
  fullWidth = false,
  moreBenefitsText,
  moreBenefitsButtonTopPositioned,
}) => {
  const { breakpoints } = useTheme() as ThemeType
  const { maxBenefitsToShowInExplorer } = useContext(AppContext)
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const MoreBenefitsButton =
    !breakpoints.isMobileSized && moreBenefitsButtonTopPositioned ? ShowAllBenefitsButtonTop : ShowAllBenefitsButton

  return (
    <Card
      testID={tID('IntegratedBenefitsEntryCard')}
      backgroundColor={backgroundColor}
      showBorder={showBorder}
      fullWidth={fullWidth}
    >
      {Boolean(title) && (
        <ToplineContainer>
          <TitleContainer>
            <Subhead size={SubheadSize.XSMALL} text={title} />
            <TooltipContainer>
              <Tooltip
                placement='top'
                triggerConfig={{
                  icon: TOOLTIP_TRIGGER_ICON.INFO,
                  size: 20,
                  isFilled: false,
                }}
                content={
                  <FormattedMessage
                    defaultMessage='Each benefit is selected based on the information you’ve shared about your background, feelings, and interests.'
                    description='Tooltip explanation of the listed benefits'
                  />
                }
                hoverEnabled={IS_WEB}
              />
            </TooltipContainer>
          </TitleContainer>
          {showXButton && <XButton onPress={onPressCloseButton} showExitText={false} />}
        </ToplineContainer>
      )}
      {benefits.length > 0 &&
        benefits.map((benefitPreviewItemProps, idx) =>
          maxBenefitsToShowInExplorer && idx < maxBenefitsToShowInExplorer ? (
            <FencepostContainer
              key={idx}
              containerBackgroundColor={backgroundColor}
              showDivider={idx < Math.min(maxBenefitsToShowInExplorer, benefits.length) - 1}
              addBottomPadding={idx < Math.min(maxBenefitsToShowInExplorer, benefits.length) - 1}
              addTopPadding={idx > 0}
            >
              <BenefitPreviewItem {...benefitPreviewItemProps} />
            </FencepostContainer>
          ) : null,
        )}
      {onPressShowAllBenefits !== undefined && (
        <MoreBenefitsButton
          fullWidth
          testID={tID('IntegratedBenefitsEntryCard-show_all_benefits_button')}
          textAlign='right'
          text={
            moreBenefitsText || (
              <FormattedMessage
                defaultMessage='Show other benefits'
                description='a link to the integrated benefits page'
              />
            )
          }
          onPress={onPressShowAllBenefits}
        />
      )}
    </Card>
  )
}
