import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from 'styled-components/native'

import {
  DEFAULT_LYRA_SELECT_MICROSITE_CONFIG,
  LyraSelectMicrositeConfig,
  MicrositeConfigSectionNames,
} from '@lyrahealth-inc/shared-app-logic'
import { ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { getLwMobileEnabled } from '../../data/customer/customerSelectors'

export const useUpdatedLyraSelectMicrositeConfig: () => LyraSelectMicrositeConfig = () => {
  const isMobileAppDisabled = !useSelector(getLwMobileEnabled)
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme() as ThemeType

  return useMemo(() => {
    const overrideHideSections = {
      [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: {
        hideSection: isMobileSized || isMobileAppDisabled,
        backgroundColor: undefined,
      },
      [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: {
        hideSection: isMobileAppDisabled,
        backgroundColor: colors.backgroundSecondary,
      },
      [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: {
        hideSection: isMobileAppDisabled,
        backgroundColor: undefined,
      },
      [MicrositeConfigSectionNames.CARE_NAVIGATOR]: {
        hideSection: false,
        backgroundColor: colors.backgroundSecondary,
      },
    }

    return {
      ...DEFAULT_LYRA_SELECT_MICROSITE_CONFIG,
      ...overrideHideSections,
    }
  }, [colors.backgroundSecondary, isMobileAppDisabled, isMobileSized])
}
