import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { PATHWAYS_TYPE } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  EmailIcon,
  FooterPhoneIcon,
  MessagingIcon,
  NoteIcon,
  PressableOpacity,
  Subhead,
  Tooltip,
  TOOLTIP_TRIGGER_ICON,
} from '../../../atoms'
import { PathwaysCareNavigatorIllustration } from '../../../atoms/illustrations/PathwaysCareNavigatorIllustration'
import { BodyTextSize, SubheadSize } from '../../../styles'
import { RowView } from '../../../templates'
import { ProductTypeContext, ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'

export interface PathwaysCareNavigatorSectionProps {
  onChatLivePress?: () => void
  onPressEmail?: () => void
  onContactUsPress?: () => void
  contactEmail?: string
  showLiveChat?: boolean
  isInternational?: boolean
  isLyrian?: boolean
  pathwaysType?: PATHWAYS_TYPE
  backgroundColor?: string
}

const SectionContainer = styled.View<{ theme: ThemeType; backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.colors.backgroundSecondary,
  paddingHorizontal: theme.spacing['24px'],
  alignItems: 'center',
  ...(theme.breakpoints.isMinWidthTablet && {
    flexDirection: 'row',
    paddingVertical: '124px',
    alignSelf: 'center',
    width: '100%',
    justifyContent: 'center',
  }),
  ...(!theme.breakpoints.isMinWidthTablet && {
    paddingVertical: '80px',
  }),
}))

const BodyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'column',
  marginTop: theme.spacing['24px'],
  gap: '8px',
  maxWidth: '620px',
  ...(theme.breakpoints.isMinWidthTablet && { flex: 1 }),
}))

const PathwaysCareNavigatorIllustrationContainer = styled(PathwaysCareNavigatorIllustration)(({ theme }) => ({
  alignSelf: 'center',
  ...(theme.breakpoints.isMinWidthTablet && {
    marginRight: '48px',
  }),
}))

const ItemsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  alignItems: 'center',
  gap: '16px',
  ...(theme.breakpoints.isMinWidthTablet && {
    alignItems: 'left',
    flexDirection: 'row',
    gap: '32px',
  }),
}))

const PressableOpacityContainer = styled(PressableOpacity)({
  flexDirection: 'row',
  gap: '4px',
})

const Row = styled(RowView)({
  alignItems: 'center',
  gap: '4px',
})

const BUTTON_VERSION = {
  US_VERSION: 'US_VERSION',
  OUS_VERSION: 'OUS_VERSION',
  LYRIAN_VERSION: 'LYRIAN_VERSION',
}

export const PathwaysCareNavigatorSection: FunctionComponent<PathwaysCareNavigatorSectionProps> = ({
  onChatLivePress = noop,
  onContactUsPress = noop,
  onPressEmail = noop,
  contactEmail,
  isInternational = false,
  showLiveChat = true,
  isLyrian,
  pathwaysType = PATHWAYS_TYPE.SINGLE,
  backgroundColor,
}) => {
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme()
  const { isLyraSelect } = useContext(ProductTypeContext)
  const { formatMessage } = useIntl()
  const isComingSoon = pathwaysType === PATHWAYS_TYPE.COMING_SOON

  const buttonVersion = isLyrian
    ? BUTTON_VERSION.LYRIAN_VERSION
    : isInternational
    ? BUTTON_VERSION.OUS_VERSION
    : BUTTON_VERSION.US_VERSION

  const items = {
    [BUTTON_VERSION.US_VERSION]: [
      {
        icon: <FooterPhoneIcon />,
        text: formatMessage({
          defaultMessage: 'Call',
          description: 'Link that allows user to call the phone number provided when they need to contact Lyra Health',
        }),
        onPress: () => onContactUsPress(),
        testIDSuffix: 'US-CallUs',
      },
      {
        icon: <EmailIcon />,
        text: contactEmail
          ? contactEmail
          : formatMessage({
              defaultMessage: 'Email',
              description: 'Link that allows user to email when they need to contact Lyra Health',
            }),
        onPress: () => onPressEmail(),
        testIDSuffix: 'US-Email',
      },
      ...(showLiveChat
        ? [
            {
              icon: <MessagingIcon fillColor={colors.iconActive} />,
              text: formatMessage({
                defaultMessage: 'Chat',
                description: 'Text in footer to chat for support',
              }),
              onPress: () => onChatLivePress(),
              testIDSuffix: 'US-ChatLive',
              tooltip: (
                <Tooltip
                  hoverEnabled
                  content={formatMessage({
                    defaultMessage: 'Mon–Fri: 6 AM–6 PM PT',
                    description:
                      'Message describing the day and time range for when live chat via the app is available to the user.',
                  })}
                  triggerConfig={{
                    icon: TOOLTIP_TRIGGER_ICON.QUESTION_MARK,
                    isFilled: false,
                    fillColor: colors.iconActive,
                    size: 20,
                  }}
                />
              ),
            },
          ]
        : []),
    ],
    [BUTTON_VERSION.OUS_VERSION]: [
      {
        icon: <FooterPhoneIcon />,
        text: formatMessage({
          defaultMessage: 'Contact',
          description: 'Link to contact page with phone numbers for the care navigator team',
        }),
        onPress: () => onContactUsPress(),
        testIDSuffix: 'OUS-ContactUs',
      },
      {
        icon: <MessagingIcon fillColor={colors.iconActive} />,
        text: formatMessage({
          defaultMessage: 'Chat',
          description: 'Text in footer to chat for support',
        }),
        onPress: () => onChatLivePress(),
        testIDSuffix: 'OUS-ChatLive',
      },
    ],
    [BUTTON_VERSION.LYRIAN_VERSION]: [
      {
        icon: <NoteIcon fillColor={colors.iconActive} />,
        text: formatMessage({
          defaultMessage: 'Help desk',
          description: 'Link to navigate user to Zendesk help page for Lyrians (Lyra employees)',
        }),
        onPress: () => onPressEmail(),
        testIDSuffix: 'Lyrians-HelpDesk',
      },
    ],
  }

  const descriptionText = isLyraSelect ? (
    <FormattedMessage
      defaultMessage='Whether you need help understanding your Lyra benefits, accessing your personalized care resources, or anything else—our Care Navigator team is always here to help.'
      description='Lyra select text describing care navigator support'
    />
  ) : (
    <FormattedMessage
      defaultMessage='Whether you need help understanding your Lyra benefits, getting matched to a mental health expert, or anything else—our support team and your dedicated Care Navigator are always here to help.'
      description='text describing care navigator support'
    />
  )

  return (
    <SectionContainer testID={tID('PathwaysCareNavigatorSection')} backgroundColor={backgroundColor}>
      <PathwaysCareNavigatorIllustrationContainer />
      <BodyContainer>
        <Subhead
          textAlign={isMinWidthTablet ? 'left' : 'center'}
          size={SubheadSize.LARGE}
          text={
            <FormattedMessage
              defaultMessage='Always here for you, always available'
              description='Header text to inform user of care navigator support'
            />
          }
        />
        <BodyText
          size={BodyTextSize.DEFAULT}
          textAlign={isMinWidthTablet ? 'left' : 'center'}
          wrap
          text={descriptionText}
        />
        {!isComingSoon && (
          <ItemsContainer>
            {items[buttonVersion].map((item, index) => {
              return (
                <Row
                  key={`PathwaysCareNavigatorItems-${index}`}
                  testID={tID(`PathwaysCareNavigatorItems-${item.testIDSuffix}`)}
                >
                  <PressableOpacityContainer onPress={item.onPress}>
                    {item.icon ? <View>{item.icon}</View> : null}
                    <BodyText text={item.text} color={colors.textActive} />
                  </PressableOpacityContainer>
                  {item.tooltip ? <View>{item.tooltip}</View> : null}
                </Row>
              )
            })}
          </ItemsContainer>
        )}
      </BodyContainer>
    </SectionContainer>
  )
}
