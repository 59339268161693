import { PHONE_TYPES } from 'features/calendar/types'
import { CountryCode } from 'libphonenumber-js'
import { isNil } from 'lodash-es'

import {
  PostAppointmentForAdultRequest,
  PostAppointmentForChildRequest,
  PostAppointmentRequest,
  ProviderInfo,
} from '../../../models'
import { ICAS_CONTACT_EMAIL } from '../../common/constants/constants'
import { COLLOQUIAL_STRINGS_FOR_TREATMENT_OPTIONS } from '../../common/constants/customerProgram'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { getProviderContactEmailAddress } from '../../common/utils/commonUtils'
import { formatPhoneNumberForDisplay } from '../../common/utils/stringUtils'
import { getPreferredContactInfo, getProviderPreferredContactType } from '../../providers/utils'

interface GetBookingRequestDataParams {
  provider: ProviderInfo
  patientId: PostAppointmentRequest['patient_id']
  patientFirstName: PostAppointmentRequest['patient_first_name']
  patientLastName: PostAppointmentRequest['patient_last_name']
  patientGender?: PostAppointmentRequest['patient_gender']
  patientLegalFirstName: PostAppointmentRequest['patient_legal_first_name']
  patientLegalLastName: PostAppointmentRequest['patient_legal_last_name']
  patientPreferredFirstName?: PostAppointmentRequest['patient_preferred_first_name']
  patientPreferredLastName?: PostAppointmentRequest['patient_preferred_last_name']
  patientDisplayLanguage?: PostAppointmentRequest['patient_display_language']
  selectedCareLanguage: PostAppointmentRequest['selected_care_language']
  patientEmail?: PostAppointmentRequest['patient_email']
  patientRequestMessage: PostAppointmentRequest['patient_message']
  patientCountry: CountryCode
  patientCountryName?: PostAppointmentRequest['patient_country_name']
  isForChild?: PostAppointmentRequest['appointment_for_child']
  primaryNeed?: PostAppointmentRequest['primary_need']
  appointmentType?: PostAppointmentRequest['appointment_type']
  modality?: PostAppointmentRequest['modality']
  customerName?: PostAppointmentRequest['customer_name']
  customerDisplayName?: PostAppointmentRequest['customer_display_name']
  lyraCode?: PostAppointmentRequest['lyra_code']
  patientPhone?: PostAppointmentRequest['patient_phone']
  patientDOB?: PostAppointmentForAdultRequest['patient_dob']
  childFirstName?: PostAppointmentForChildRequest['child_first_name']
  childLastName?: PostAppointmentForChildRequest['child_last_name']
  childDOB?: PostAppointmentForChildRequest['child_dob']
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE | null
  isInternationalUser: boolean
  requestId: PostAppointmentRequest['request_id']
  isEnhancedRiskProtocolEnabled?: boolean
  patientPhoneType?: PHONE_TYPES | null
  smsConsent?: boolean | null
  highRiskOUSAppointmentBookingEmailAddress?: PostAppointmentRequest['phoenix_team_email']
}

export function useGetBookingRequestData() {
  function getBookingRequestData({
    provider,
    patientId,
    patientFirstName,
    patientLastName,
    patientGender,
    patientLegalFirstName,
    patientLegalLastName,
    patientPreferredFirstName,
    patientPreferredLastName,
    patientDisplayLanguage,
    selectedCareLanguage,
    patientEmail,
    isForChild = false,
    patientRequestMessage,
    primaryNeed,
    modality,
    customerName,
    customerDisplayName,
    lyraCode,
    patientDOB,
    patientPhone,
    patientPhoneType,
    childFirstName,
    childLastName,
    childDOB,
    selectedTreatmentOption,
    patientCountry,
    isInternationalUser,
    patientCountryName,
    requestId,
    isEnhancedRiskProtocolEnabled = false,
    smsConsent,
    highRiskOUSAppointmentBookingEmailAddress,
  }: GetBookingRequestDataParams) {
    // we want to get the provider's preferred email for communication with patients and lyra
    const { email, phone: providerPhone } = getPreferredContactInfo(provider)
    const providerDisplayPhoneNumber = providerPhone
      ? formatPhoneNumberForDisplay(providerPhone, patientCountry)
      : undefined
    const patientDisplayPhoneNumber = formatPhoneNumberForDisplay(patientPhone, patientCountry)

    // if value is undefined BE will handle and replace with 'Not indicated'
    const treatmentOptionColloquialForm = selectedTreatmentOption
      ? COLLOQUIAL_STRINGS_FOR_TREATMENT_OPTIONS[selectedTreatmentOption]
      : selectedTreatmentOption
    const providerPreferredContact = getProviderPreferredContactType(email, providerPhone)
    const [providerDisplayFirstName, providerDisplayLastName] = provider.display_name.split(' ')
    const baseRequestData: PostAppointmentRequest = {
      provider_id: provider.lyra_id,
      provider_first_name: providerDisplayFirstName ?? provider.first_name,
      provider_last_name: providerDisplayLastName ?? provider.last_name,
      provider_email: !isNil(email) ? email : provider.email,
      provider_phone: providerPhone || undefined,
      provider_display_phone_number: providerDisplayPhoneNumber,
      provider_preferred_contact: providerPreferredContact,
      patient_id: patientId,
      patient_first_name: patientFirstName,
      patient_last_name: patientLastName,
      patient_gender: patientGender,
      patient_legal_first_name: patientLegalFirstName,
      patient_legal_last_name: patientLegalLastName,
      patient_preferred_first_name: patientPreferredFirstName,
      patient_preferred_last_name: patientPreferredLastName,
      patient_display_language: patientDisplayLanguage,
      selected_care_language: selectedCareLanguage,
      patient_email: patientEmail,
      patient_message: patientRequestMessage,
      appointment_for_child: isForChild,
      primary_need: primaryNeed,
      appointment_type: treatmentOptionColloquialForm,
      modality,
      customer_name: customerName,
      customer_display_name: customerDisplayName,
      lyra_code: lyraCode,
      patient_phone: patientPhone,
      patient_display_phone_number: patientDisplayPhoneNumber,
      provider_contact_email: getProviderContactEmailAddress({ isInternationalUser }),
      country_iso_code: patientCountry,
      patient_country_name: patientCountryName,
      request_id: requestId,
      is_international: isInternationalUser,
      is_enhanced_risk_protocol_enabled: isEnhancedRiskProtocolEnabled,
      patient_phone_type: patientPhoneType,
      patient_sms_consent: smsConsent,
      phoenix_team_email: highRiskOUSAppointmentBookingEmailAddress,
    }
    if (isInternationalUser) {
      baseRequestData.care_navigator_url = `mailto:${ICAS_CONTACT_EMAIL}`
    }
    if (isForChild) {
      const childData = {
        child_first_name: childFirstName,
        child_last_name: childLastName,
        child_dob: childDOB,
      }
      return {
        ...baseRequestData,
        ...childData,
      } as PostAppointmentForChildRequest
    }

    return {
      ...baseRequestData,
      patient_dob: patientDOB,
    } as PostAppointmentForAdultRequest
  }
  return { getBookingRequestData }
}
