import { useCallback } from 'react'

import { S4CHomebaseHeaderQ12025PayloadParams } from '../searchForCareExperimentTypes'
import { ExperimentPayload, S4CHomebaseHeaderQ12025ArmMapping } from '../types'
import { generateS4CHomebaseHeaderQ12025ExperimentPayload } from '../utils'

export type SendS4CHomebaseHeaderExperimentDataProps = Omit<
  S4CHomebaseHeaderQ12025PayloadParams,
  'arm' | 'client_lyra_id' | 'device_uuid'
>

export const useSendS4CHomebaseHeaderExperimentData = ({
  deviceUUID,
  lyraId,
  postExperimentEntrypoint,
  userInExperiment,
  searchForCareHeaderExperimentQ12025v2,
}: {
  deviceUUID: string
  lyraId: string
  postExperimentEntrypoint: (payload: ExperimentPayload) => void
  userInExperiment: boolean
  searchForCareHeaderExperimentQ12025v2?: boolean
}) => {
  return useCallback(
    ({
      action,
      search_id,
      triage_need,
      toolkit_topic,
      patient,
      has_started_search_for_self,
    }: SendS4CHomebaseHeaderExperimentDataProps) => {
      if (!userInExperiment) return

      const arm = searchForCareHeaderExperimentQ12025v2
        ? S4CHomebaseHeaderQ12025ArmMapping.EXPERIMENT
        : S4CHomebaseHeaderQ12025ArmMapping.CONTROL

      const experimentPayload = generateS4CHomebaseHeaderQ12025ExperimentPayload({
        client_lyra_id: lyraId,
        device_uuid: deviceUUID,
        arm,
        action,
        search_id,
        triage_need,
        toolkit_topic,
        patient,
        has_started_search_for_self,
      })

      // send experiment data to DS
      postExperimentEntrypoint(experimentPayload)

      return
    },
    [deviceUUID, lyraId, postExperimentEntrypoint, searchForCareHeaderExperimentQ12025v2, userInExperiment],
  )
}
