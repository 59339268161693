import React from 'react'
import { MessageDescriptor } from 'react-intl'

import {
  customerCodeValidationError,
  EligibilityField,
  EligibilityType,
  extendMetadata,
  GenerateCustomMetadata,
  GetCustomerCopyFunc,
} from '@lyrahealth-inc/shared-app-logic'

import { generateEligibilityTypeQuestions } from '../formMetadata/eligibilityMetadata'
import { generateStarbuckDependentEligibilityTypeMetadata } from './registration/starbucks/starbucksEligibilityDependentMetadata'
import { generateStarbucksEmployeeEligibilityMetadata } from './registration/starbucks/starbucksEligibilityEmployeeMetadata'
import { StarbucksEligibilityErrorBanner } from './registration/starbucks/StarbucksEligibilityErrorBanner'

export const generateStarbucksEligibilityMetadata: GenerateCustomMetadata = ({
  eligibilityInfo,
  isMobileSized,
  intl,
  colors,
}) => {
  const eligibilityTypeQuestions = generateEligibilityTypeQuestions(eligibilityInfo, intl)
  const employeeMetadata = extendMetadata(
    eligibilityTypeQuestions,
    generateStarbucksEmployeeEligibilityMetadata(eligibilityInfo, colors),
  )
  const dependentMetadata = extendMetadata(
    employeeMetadata,
    generateStarbuckDependentEligibilityTypeMetadata(eligibilityInfo, isMobileSized, colors),
  )
  return dependentMetadata
}

const getCustomFields = () => ({
  EmployeeStarbucksEligibilityErrorBanner: () => <StarbucksEligibilityErrorBanner isDependent={false} />,
  DependentStarbucksEligibilityErrorBanner: () => <StarbucksEligibilityErrorBanner isDependent />,
})

export const starbucks: GetCustomerCopyFunc = () => {
  return {
    generateRegistrationFormSubHeaderText: () =>
      'Let’s get you ready to register. All you’ll need is a few minutes and your Starbucks partner number.',
    generateEligibilityQuestionTitle: () => 'Are you a Starbucks partner?',
    modalityQuestionnaireText: {
      modalityLabelAddendums: {
        video: '',
      },
    },
    generateEmployeeEligibilityError: () => null, // return null to hide default eligibility tip
    shouldDisplayBCTProviderBenefits: false,
    eligibilityMetadata: {
      generateMetadata: generateStarbucksEligibilityMetadata,
      getCustomFields,
    },
    generateEligibilityCheckSubmissionErrors: (
      eligibilityType?: EligibilityType,
    ): {
      [fieldName: string]: MessageDescriptor
    } => {
      const isDependent = eligibilityType === 'dependent_other'
      if (isDependent) {
        return {
          [EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      } else {
        return {
          [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      }
    },
  }
}
