import React, { FunctionComponent } from 'react'

import { CalendarAvailableIllustrationProps } from './CalendarAvailableIllustrationSvg'
import { withSuspense } from '../../utils/withSuspense'

const CalendarAvailableIllustrationSvg = withSuspense({
  component: React.lazy(() => import('./CalendarAvailableIllustrationSvg')),
})

export const CalendarAvailableIllustration: FunctionComponent<CalendarAvailableIllustrationProps> = ({
  height,
  width,
}) => {
  return <CalendarAvailableIllustrationSvg width={width} height={height} />
}
