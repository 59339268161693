// @intl project:microsite

import React from 'react'

import { RootLayout } from '@lyrahealth-inc/ui-core'
import { GenericError } from '@lyrahealth-inc/ui-core-crossplatform'

import MicrositeIntlProvider from '../../../../features/microsite/MicrositeIntlProvider'

export default function ErrorFallback() {
  return (
    <MicrositeIntlProvider>
      <RootLayout>
        <GenericError onReloadPress={() => document.location.reload()} />
      </RootLayout>
    </MicrositeIntlProvider>
  )
}
