import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { Popover } from 'react-native-popper'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { InfoPopoverTriggerAction } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../constants'
import { ColumnView, Flex1View, RowView } from '../../templates/content/CommonViews'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { BodyText, Size } from '../bodyText/BodyText'
import { CloseIcon } from '../icons/CloseIcon'

/**
 * An information popover modal that can be passed content
 */
const HeaderRowView = styled(RowView)<{ hasHeaderText?: boolean; theme: ThemeType }>(
  ({ hasHeaderText, theme: { spacing } }) => ({
    justifyContent: 'space-between',
    alignItems: hasHeaderText ? 'flex-start' : 'center',
    marginBottom: spacing['4px'],
  }),
)

const Wrapper = styled(Flex1View)({
  flexDirection: 'row',
  alignItems: 'center',
})

const PopoverContentView = styled.View<{ styles?: { [key: string]: Dict }; theme: ThemeType }>(
  ({ styles = {}, theme: { colors, spacing } }) => ({
    padding: spacing['16px'],
    width: '388px',
    maxWidth: '95%',
    borderRadius: spacing['8px'],
    zIndex: 1,
    boxShadow: `0 2px 26px ${colors.shadowLow}`,
    backgroundColor: colors.backgroundPrimary,
    ...(styles && { ...styles.container }),
    ...(Platform.OS === 'android' && {
      elevation: '10',
      shadowColor: colors.backgroundTooltip,
    }),
  }),
)

const ContentContainer = styled.View<{ paddingRight: number; wrapContent?: boolean }>(
  ({ paddingRight = 0, wrapContent = false }) => ({
    paddingRight,
    ...(wrapContent && { flex: 1 }),
  }),
)

const FooterContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  paddingTop: spacing['16px'],
}))

const RowReverseView = styled.View`
  display: flex;
  flex-direction: row-reverse;
`

const CloseButtonContainer = styled(TouchableOpacity)<{ alignStart?: boolean }>(({ alignStart }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  ...(alignStart && { alignSelf: 'flex-start' }),
}))

const HeaderTextContainer = styled.View({
  flexShrink: 1,
  display: 'flex',
})

export const InfoPopover: FunctionComponent<InfoPopoverProps> = ({
  placement,
  header,
  popoverStyle = {},
  crossOffset,
  content,
  footerContent,
  trigger,
  offset,
  animationExitDuration = 500,
  shouldDisplayCloseButton = true,
  popoverTriggerAction = InfoPopoverTriggerAction.HOVER,
  closeIconColor,
  allowContentUnderCloseButton = false,
  allowContentInlineCloseButton = false,
  clickToClose,
  mode = 'single',
  unstyledWrapper,
  accessibilityLabel,
  shouldOverlapWithTrigger = false,
  isPopoverOpen,
  setIsPopoverOpen,
  onPopoverOpenChange = noop,
}) => {
  const { colors } = useTheme() as ThemeType

  const [isOpen, setIsOpen] = useState(isPopoverOpen)
  const handleSetIsOpen = useCallback(
    (isOpen: boolean) => {
      if (setIsPopoverOpen) {
        setIsPopoverOpen(isOpen)
      } else {
        setIsOpen(isOpen)
      }
    },
    [setIsPopoverOpen],
  )
  const infoPopoverRef = useRef(null)

  const handleClick = useCallback(
    (e) => {
      if (clickToClose) {
        e.stopPropagation()
        if (infoPopoverRef.current) {
          const infoPopoverRectangle = (infoPopoverRef.current as HTMLElement).children[0].getBoundingClientRect()
          const isClickInsideContainer =
            e.clientX > infoPopoverRectangle.left &&
            e.clientX < infoPopoverRectangle.right &&
            e.clientY > infoPopoverRectangle.top &&
            e.clientY < infoPopoverRectangle.bottom
          if (isClickInsideContainer) {
            handleSetIsOpen(false)
          }
        }
      }
    },
    [clickToClose, handleSetIsOpen],
  )

  // Combine the existing onPress with closing the Infopopover
  const combinedOnPress = (originalOnPress?: () => void) => () => {
    if (originalOnPress) {
      originalOnPress()
    }
    // Close the Infopopover on linked pressed
    handleSetIsOpen(false)
  }

  // Modify the footerContent to inject the combined onPress handler
  const modifyFooterContent = (element: React.ReactElement) => {
    return React.cloneElement(element, {
      onPress: combinedOnPress(element.props?.onPress),
    })
  }

  useEffect(() => {
    if (Platform.OS === 'web' && window.addEventListener && window.removeEventListener) {
      window.addEventListener('mousedown', handleClick, false)
      return () => {
        window.removeEventListener('mousedown', handleClick, false)
      }
    } else {
      return () => {}
    }
  }, [handleClick])

  const contentContainerPaddingRight =
    shouldDisplayCloseButton && !allowContentUnderCloseButton && !allowContentInlineCloseButton ? 36 : 0

  const PopoverWrapper = unstyledWrapper ? View : Wrapper
  const PopoverAlignmentView = allowContentInlineCloseButton ? RowReverseView : ColumnView

  return (
    <PopoverWrapper>
      <Popover
        on={popoverTriggerAction}
        isOpen={setIsPopoverOpen ? isPopoverOpen : isOpen}
        onOpenChange={(isOpen: boolean) => {
          handleSetIsOpen(isOpen)
          onPopoverOpenChange(isOpen)
        }}
        crossOffset={crossOffset}
        offset={offset}
        trigger={trigger}
        animationExitDuration={animationExitDuration}
        placement={placement}
        shouldCloseOnOutsideClick
        mode={mode}
        shouldOverlapWithTrigger={shouldOverlapWithTrigger}
      >
        {
          /* Required to alow close on outside click in mobile, but will break behavior on web */
          !IS_WEB && <Popover.Backdrop />
        }
        <Popover.Content accessibilityLabel={accessibilityLabel}>
          <PopoverContentView styles={popoverStyle} testID={tID('PopoverContentView')}>
            <PopoverAlignmentView>
              {(header || shouldDisplayCloseButton) && (
                <HeaderRowView hasHeaderText={!!header}>
                  <HeaderTextContainer>
                    <BodyText size={Size.DEFAULT} text={header} color={colors.textPrimary} />
                  </HeaderTextContainer>
                  {shouldDisplayCloseButton && (
                    <CloseButtonContainer
                      alignStart={allowContentInlineCloseButton}
                      onPress={() => {
                        handleSetIsOpen(false)
                      }}
                    >
                      <CloseIcon size={15} fillColor={closeIconColor || colors.iconDefault} />
                    </CloseButtonContainer>
                  )}
                </HeaderRowView>
              )}
              {content && (
                <ContentContainer
                  paddingRight={contentContainerPaddingRight}
                  wrapContent={allowContentInlineCloseButton}
                  ref={infoPopoverRef}
                >
                  {content}
                  {footerContent && <FooterContainer>{modifyFooterContent(footerContent)}</FooterContainer>}
                </ContentContainer>
              )}
            </PopoverAlignmentView>
          </PopoverContentView>
        </Popover.Content>
      </Popover>
    </PopoverWrapper>
  )
}

export type InfoPopoverProps = {
  placement: 'top' | 'bottom' | 'left' | 'right' | 'top left' | 'top right' | 'bottom left' | 'bottom right'
  header?: React.ReactNode | string
  popoverStyle?: { container?: Dict }
  content?: React.ReactElement
  footerContent?: React.ReactElement
  trigger: React.ReactElement
  animationExitDuration?: number
  offset?: number
  crossOffset?: number
  shouldDisplayCloseButton?: boolean
  popoverTriggerAction?: 'hover' | 'press' | 'longPress'
  closeIconColor?: string
  allowContentUnderCloseButton?: boolean
  allowContentInlineCloseButton?: boolean
  clickToClose?: boolean
  mode?: 'single' | 'multiple' // set to 'multiple' for Android modal to render correctly (see https://github.com/intergalacticspacehighway/react-native-popper/tree/main)
  unstyledWrapper?: boolean
  accessibilityLabel?: string
  shouldOverlapWithTrigger?: boolean
  isPopoverOpen?: boolean
  setIsPopoverOpen?: (isPopoverOpen: boolean) => void
  onPopoverOpenChange?: (value: boolean) => void
}
