import React, { FunctionComponent } from 'react'
import Svg, { Circle, G, Polyline } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export type ChartIconProps = {
  size?: number | string
  color?: string
}

export const ChartIcon: FunctionComponent<ChartIconProps> = ({ size = 180, color }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 180 180' fill='none' testID={tID('ChartIcon')}>
      <G fill='none' fillRule='evenodd' transform='translate(20 57)'>
        <Circle cx='134' cy='59' r='6' fill={color ?? colors.iconActive} />
        <Circle cx='6' cy='6' r='6' fill={color ?? colors.iconActive} />
        <Circle cx='49' cy='37' r='6' fill={color ?? colors.iconActive} />
        <Circle cx='91' cy='23' r='6' fill={color ?? colors.iconActive} />
        <Polyline stroke={color ?? colors.iconActive} strokeWidth='3' points='6 6 49 37.671 91 23 134 59' />
      </G>
    </Svg>
  )
}
