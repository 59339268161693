import { WellbeingResponse, WELLNESS_CHECK_IN_ENTRY_POINTS } from '@lyrahealth-inc/shared-app-logic'

export const SET_WELLNESS_CHECK_IN_ENTRY_POINT = 'SET_WELLNESS_CHECK_IN_ENTRY_POINT'
export const CLEAR_WELLNESS_CHECK_IN_ENTRY_POINT = 'CLEAR_WELLNESS_CHECK_IN_ENTRY_POINT'
export const SET_WELLNESS_CHECK_IN_FORM = 'SET_WELLNESS_CHECK_IN_FORM'
export const CLEAR_WELLNESS_CHECK_IN_FORM = 'CLEAR_WELLNESS_CHECK_IN_FORM'
export const SET_WELLNESS_CHECK_IN_RESULTS = 'SET_WELLNESS_CHECK_IN_RESULTS'
export const CLEAR_WELLNESS_CHECK_IN_RESULTS = 'CLEAR_WELLNESS_CHECK_IN_RESULTS'
export const SET_SHOULD_SAVE_WELLNESS_CHECK_IN_POST_LOGIN = 'SET_SHOULD_SAVE_WELLNESS_CHECK_IN_POST_LOGIN'

export const setWellnessCheckInEntryPoint = (entryPoint: WELLNESS_CHECK_IN_ENTRY_POINTS) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_WELLNESS_CHECK_IN_ENTRY_POINT,
      data: entryPoint,
    })
  }
}

export const clearWellnessCheckInEntryPoint = () => ({
  type: CLEAR_WELLNESS_CHECK_IN_ENTRY_POINT,
})

export const setWellnessCheckInForm = (values: object) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_WELLNESS_CHECK_IN_FORM,
      data: values,
    })
  }
}

export const clearWellnessCheckInForm = () => ({
  type: CLEAR_WELLNESS_CHECK_IN_FORM,
})

export const setWellnessCheckInResults = (values: WellbeingResponse) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_WELLNESS_CHECK_IN_RESULTS,
      data: values,
    })
  }
}

export const setShouldSaveWellnessCheckInPostLogin = (shouldSave: boolean) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_SHOULD_SAVE_WELLNESS_CHECK_IN_POST_LOGIN,
      data: shouldSave,
    })
  }
}

export const clearWellnessCheckInResults = () => ({
  type: CLEAR_WELLNESS_CHECK_IN_RESULTS,
})
