import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import thumbsUpUrl from '../../assets/thumbs-up-rating.png'
import { Image } from '../image/Image'

interface ThumbsUpIllustrationProps {
  size?: number
}

export const ThumbsUpIllustration: FunctionComponent<ThumbsUpIllustrationProps> = ({ size = 48 }) => {
  const { formatMessage } = useIntl()
  return (
    <Image
      source={thumbsUpUrl}
      accessibilityLabel={formatMessage({
        defaultMessage: 'Thumbs up icon',
        description: 'Accessibility label for thumbs up icon',
      })}
      accessibilityIgnoresInvertColors
      style={{ height: size, width: size }}
    />
  )
}
