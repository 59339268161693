import { micrositesMetadataList } from './constants'
import { CMS_MICROSITE_MESSAGE, CMS_MICROSITE_MESSAGE_KEY } from './messages/messages'
import { CMSMicrositeMetadata } from './types'
import { getGoodnessBaseUrl } from '../common/utils/commonUtils'
import { CustomPathwayOption } from '../pathways/constants'

export const getMicrositeUrl = (
  url: string,
  isWorkhubDisabled: boolean,
  isWorkhubEventsDisabled: boolean,
  displayLanguage?: string,
) => {
  const micrositeUrl = `${getGoodnessBaseUrl(displayLanguage)}${url}`
  const isWorkhubDisabledParam = isWorkhubDisabled ? 'x-disabled-work-hub=true' : ''
  const isWorkhubEventsDisabledParam = isWorkhubEventsDisabled ? 'x-disabled-work-hub-events=true' : ''
  const params = [isWorkhubDisabledParam, isWorkhubEventsDisabledParam].filter((item) => item).join('&')
  return `${micrositeUrl}${params ? `?${params}` : ''}`
}

export const getMicrositePathwayKey = (micrositeId: string, messageKey: CMS_MICROSITE_MESSAGE): CustomPathwayOption => {
  return `CustomPathway${micrositeId}${messageKey.charAt(0).toUpperCase()}${messageKey.slice(1)}`
}

export const getWebinarLink = (micrositeMetadata: CMSMicrositeMetadata) => {
  return micrositeMetadata.postMessageEvents.find((item) => item.key === CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR)
    ?.params?.webinarLink
}

/** For tests, when it doesn't matter which microsite we use, return the first one in our list */
export const getMicrositeFromList = () => {
  if (micrositesMetadataList.length < 1) {
    return null
  }
  return micrositesMetadataList[0]
}
