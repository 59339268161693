import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { AccessibilityInfo, findNodeHandle } from 'react-native'

import { IS_WEB } from '../constants'

/**
 * Returns a ref object which when bound to an element, will focus that
 * element in VoiceOver/TalkBack on its appearance
 */

interface useAccessibilityFocusProps {
  delay?: number
  active?: boolean
  ref?: MutableRefObject<any>
}
export const useAccessibilityFocus = ({
  delay = 500,
  active,
  ref,
}: useAccessibilityFocusProps): [MutableRefObject<any>, () => void] => {
  const newRef = useRef<any>(null)
  const selectedRef = ref || newRef

  const setFocus = useCallback(() => {
    setTimeout(() => {
      if (!IS_WEB) {
        if (selectedRef.current) {
          const focusPoint = findNodeHandle(selectedRef.current)
          if (focusPoint) {
            AccessibilityInfo.setAccessibilityFocus(focusPoint)
          }
        }
      }
      // Due to screen lifecycle, we need to put a minor delay, or React Navigation (or other) components may steal focus.
    }, delay)
  }, [delay, selectedRef])

  useEffect(() => {
    active && setFocus()
  }, [active, setFocus])

  return [selectedRef, setFocus]
}
