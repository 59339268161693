import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { Image } from '../image/Image'

/**
 * This component contains a couch illustration that is in the video session preview screen
 */

const WaitingRoomPNG = styled(Image)(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '241px' : '557px',
  height: theme.breakpoints.isMobileSized ? '141px' : '336px',
}))

export const WaitingRoom: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  return (
    <WaitingRoomPNG
      source={require('./../../assets/waitingRoom.png')}
      ariaLabel={formatMessage({
        defaultMessage: 'Illustration of a sofa in a waiting room',
        description: 'Accessibility label for illustration',
      })}
    />
  )
}
