import React, { FunctionComponent } from 'react'

import { MessageOnboardingIconProps } from './MessageOnboardingIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const MessageOnboardingIconSvg = withSuspense({
  component: React.lazy(() => import('./MessageOnboardingIconSvg')),
})

export const MessageOnboardingIcon: FunctionComponent<MessageOnboardingIconProps> = ({ width, height }) => {
  return <MessageOnboardingIconSvg width={width} height={height} />
}
