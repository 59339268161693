import React from 'react'
import { Linking } from 'react-native'

import { excludedServices, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { Link } from '../atoms/link/Link'

const benefitsPortal = 'https://pinterestbenefits.com'

/* eslint-disable formatjs/no-literal-string-in-jsx */
const benefitsLink = (
  <Link
    text='benefits portal'
    onPress={() => {
      Linking.openURL(benefitsPortal)
    }}
  />
)

const servicesNotCovered = `Services that are not covered include: ${excludedServices}. Please check with your health plan or ${benefitsLink} for coverage of these excluded services.`

export const pinterest: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    displaySessionLimit: false,
    legalNotice: <CoverageNotice sessionLimit={numberOfSessions} />,
    servicesNotCovered,
  }
}
