import { useSelector } from 'react-redux'

import { CostMessagingCoverageModalData } from '@lyrahealth-inc/shared-app-logic'
import { useGetCostMessagingInfo as useGetCostMessagingInfoShared } from '@lyrahealth-inc/ui-core-crossplatform'

import { useIsCostMessagingEnabled } from './useIsCostMessagingEnabled'
import {
  getCostMessagingModalText,
  getCustomerInfo,
  getDisplayVisitsPerIssuePerYear,
  getHideDependentField,
  getIsCustomerEAPExclusive,
  getIsCustomerEAPMeds,
  getIsCustomerHPIExclusive,
  getIsCustomerHPIMeds,
  getIsCustomerProgramEAPCoaching,
  getIsCustomerProgramEAPTherapy,
  getIsCustomerProgramHPITherapy,
  getNumberOfSessions,
  getNumberOfSessionsByProgram,
} from '../../data/customer/customerSelectors'
import { getIsProgramLevelSessionLimitEnabled } from '../../data/featureFlags/featureFlagSelectors'

/**
 * Hook to retrieve cost messaging information related to a customer's provided coverage
 */
export const useGetCostMessagingInfo = (): CostMessagingCoverageModalData => {
  const isCostMessagingEnabled = useIsCostMessagingEnabled()
  const costMessagingModalText = useSelector(getCostMessagingModalText)
  const isHPIExclusive = useSelector(getIsCustomerHPIExclusive)
  const isEAPExclusive = useSelector(getIsCustomerEAPExclusive)
  const isDependentDisabled = useSelector(getHideDependentField)
  const isCustomerEAPMeds = useSelector(getIsCustomerEAPMeds)
  const isCustomerHPIMeds = useSelector(getIsCustomerHPIMeds)
  const isCustomerProgramEAPCoaching = useSelector(getIsCustomerProgramEAPCoaching)
  const isCustomerProgramHPITherapy = useSelector(getIsCustomerProgramHPITherapy)
  const isCustomerProgramEAPTherapy = useSelector(getIsCustomerProgramEAPTherapy)
  const customerDisplayName = useSelector(getCustomerInfo)
  const numSessions = useSelector(getNumberOfSessions)
  const numberOfSessionByProgram = useSelector(getNumberOfSessionsByProgram)
  const isProgramLevelSessionLimitEnabled = useSelector(getIsProgramLevelSessionLimitEnabled)
  const displayVisitsPerIssuePerYear = useSelector(getDisplayVisitsPerIssuePerYear)

  return useGetCostMessagingInfoShared({
    isCostMessagingEnabled,
    costMessagingModalText,
    isHPIExclusive,
    isEAPExclusive,
    isDependentDisabled,
    isCustomerEAPMeds,
    isCustomerHPIMeds,
    isCustomerProgramEAPCoaching,
    isCustomerProgramHPITherapy,
    isCustomerProgramEAPTherapy,
    customerDisplayName,
    numSessions,
    numberOfSessionByProgram,
    isProgramLevelSessionLimitEnabled,
    displayVisitsPerIssuePerYear,
  })
}
