import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
}

export const LyraSelectPersonalizedCareIllustration: FunctionComponent<Props> = ({ size = 96 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 88 85' fill='none'>
      <Mask id='mask0_2582_4927' maskUnits='userSpaceOnUse' x='0' y='-3' width='88' height='88'>
        <Path
          d='M44.3071 -2.41113C38.2902 -2.41113 32.4525 -1.37932 26.9558 0.655031C13.8694 5.50577 4.66924 16.2597 1.71332 30.1591C-0.240069 39.2215 0.00970307 47.6035 2.48158 55.7719C5.63732 66.2038 13.3837 75.0009 23.7346 79.9068C28.873 82.3476 34.7762 83.7412 41.2772 84.0478C42.1126 84.0857 42.9618 84.1064 43.8059 84.1064C49.0218 84.1064 54.2687 83.3915 59.402 81.9824C59.7964 81.8843 60.172 81.7826 60.5681 81.6655C65.1812 80.315 69.3326 78.179 72.8845 75.3247C76.7413 72.2431 79.916 68.3363 82.3103 63.7198C82.7823 62.8103 83.2336 61.8526 83.7315 60.7071C86.4703 54.3474 87.7933 47.228 87.559 40.1104C87.4488 36.9632 86.9802 33.618 86.1706 30.1591C84.8373 24.547 82.6635 19.2691 79.8833 14.8869C79.1219 13.7103 78.4225 12.7216 77.7301 11.8327C72.7329 5.38691 64.9469 0.863461 55.2213 -1.24151C51.6487 -2.01667 47.9762 -2.40941 44.3089 -2.40941L44.3071 -2.41113Z'
          fill='#FFE9E3'
        />
      </Mask>
      <G mask='url(#mask0_2582_4927)'>
        <Path
          d='M18.2195 16.4296C19.5428 14.1044 18.6408 10.6761 20.2653 10.6158C21.8897 10.5555 20.8973 15.1758 22.4016 16.4614C23.9059 17.747 25.6505 17.074 25.5912 18.7866C25.5319 20.4992 23.1241 19.3072 22.0411 21.0816C20.9581 22.8561 21.7103 25.3686 20.3261 25.4908C18.942 25.613 19.2431 21.388 18.0993 20.8372C16.9555 20.2865 14.248 20.4388 14.248 19.185C14.248 17.3185 16.8962 18.7564 18.2195 16.4312V16.4296Z'
          fill='#FFC03C'
        />
        <Path
          d='M13.4855 36.3401C14.3484 34.9769 13.7589 32.9702 14.8171 32.933C15.8753 32.8958 15.2281 35.6036 16.2082 36.3568C17.1883 37.11 18.3246 36.7158 18.2855 37.72C18.2465 38.7243 16.6787 38.0251 15.9739 39.0647C15.269 40.1043 15.7581 41.5772 14.858 41.6479C13.9579 41.7186 14.1531 39.2432 13.4074 38.9215C12.6616 38.5997 10.9004 38.689 10.9004 37.9525C10.9004 36.859 12.6244 37.7015 13.4873 36.3382L13.4855 36.3401Z'
          fill='#FFC03C'
        />
        <Path
          d='M66.4959 43.8548C65.3633 45.5825 66.0756 48.1676 64.7198 48.1918C63.3641 48.216 64.2475 44.7605 63.007 43.7767C61.7665 42.7929 60.3029 43.2745 60.3736 41.9894C60.4442 40.7043 62.4379 41.6324 63.3622 40.3138C64.2865 38.9952 63.6895 37.1001 64.8463 37.0257C66.0031 36.9513 65.7018 40.1204 66.6484 40.5481C67.5951 40.9759 69.8565 40.8977 69.8417 41.8388C69.8193 43.2411 67.6285 42.1252 66.4959 43.8529V43.8548Z'
          fill='#FFC03C'
        />
        <Path
          d='M70.5565 30.4948C68.8511 29.2413 66.4036 30.1786 66.3237 28.5941C66.2437 27.0095 69.5932 27.9022 70.484 26.4126C71.3748 24.9229 70.8522 23.2323 72.0871 23.2621C73.322 23.2919 72.5167 25.6612 73.8204 26.6878C75.1223 27.7144 76.9151 26.9389 77.0341 28.2872C77.1531 29.6355 74.1031 29.4124 73.733 30.5375C73.3629 31.6627 73.534 34.3017 72.6302 34.3222C71.2855 34.3519 72.2619 31.7464 70.5584 30.4929L70.5565 30.4948Z'
          fill='#FFC03C'
        />
        <Path
          d='M57.084 29.9372C56.9109 30.9826 56.5595 32.0131 56.0195 32.9642C54.5247 35.5886 51.9689 38.7578 49.5176 41.2676C47.2258 43.615 45.0265 45.3861 43.8849 45.5745C42.1381 45.8626 25.1062 31.496 31.1675 23.6358C36.6219 16.5643 43.5815 25.1558 43.5815 25.1558C43.5815 25.1558 48.6794 18.6752 54.4682 22.6588C56.6846 24.1843 57.5571 27.1005 57.0823 29.9391L57.084 29.9372Z'
          fill='#F47352'
        />
        <Path
          d='M23.7349 79.9073C29.0314 82.4233 34.964 79.5128 39.6162 75.9434C40.3564 75.3755 41.0998 74.8986 41.8121 74.5802C47.4049 72.0815 55.4024 68.0952 59.1188 64.8197C60.969 63.1868 61.7123 61.5094 61.9434 60.0953C62.0137 59.6673 62.0358 59.2644 62.0277 58.8963C62.0157 58.3327 61.9373 57.8426 61.8429 57.461C61.6983 56.8722 61.7405 56.3395 61.8349 56.2233C61.9996 56.0238 62.1804 55.5957 62.323 55.0998C62.4114 54.788 62.4858 54.4509 62.53 54.1255C62.5344 54.0922 62.5383 54.0583 62.5422 54.0238C62.5453 53.9961 62.5485 53.968 62.5521 53.9395H62.548C62.6163 53.2984 62.6284 52.4209 62.538 52.1536C62.0338 50.6544 60.5311 50.0927 57.8773 51.0166C51.1777 53.4335 49.3583 53.6045 46.1617 53.9048C44.8955 54.0238 43.4132 54.1631 41.326 54.4703C41.0909 54.5124 40.8635 54.5531 40.6433 54.5925C36.8061 55.2787 35.1454 55.5757 32.8845 56.6107C32.8546 56.623 32.815 56.6444 32.7692 56.6691C32.2963 56.9244 31.1613 57.5371 33.1156 52.2214C35.2611 46.3911 33.6057 45.8971 32.0468 45.9552C30.4859 46.0153 21.3856 59.3883 19.4992 63.2817C19.4107 63.4648 19.3071 63.6804 19.1901 63.9252C16.374 69.8152 17.8354 77.1112 23.7349 79.9073Z'
          fill='#BC6A41'
        />
        <Path
          d='M62.0434 58.7832C62.0514 59.1544 62.0294 59.5586 61.9596 59.988C56.7616 61.0859 52.8781 65.054 47.9154 66.7349C45.1688 67.6637 42.5239 67.4169 39.6756 67.3509C36.5699 67.2809 33.7455 68.2972 31.8905 70.8176C31.4397 71.4297 30.4663 70.7496 30.9171 70.1375C32.5647 67.8989 35.1099 66.4628 37.9323 66.2335C41.0519 65.979 44.1096 66.7232 47.1654 65.7574C52.4372 64.0901 56.4544 59.8636 61.9876 58.7929C62.0075 58.789 62.0255 58.7871 62.0434 58.7851V58.7832Z'
          fill='#925333'
        />
        <Path
          d='M62.0437 57.5184C59.4028 56.5695 56.6139 57.822 54.3391 59.1127C51.7862 60.5562 49.2714 62.1409 46.1963 62.1314C45.422 62.1314 45.5561 60.9877 46.3244 60.9896C51.9283 61.0068 56.2337 54.536 62.0357 56.2983C61.9417 56.4129 61.8997 56.938 62.0437 57.5184Z'
          fill='#925333'
        />
        <Path
          d='M62.6016 53.998C62.5936 54.0626 62.5875 54.1252 62.5795 54.1859C62.5351 54.5147 62.4605 54.8552 62.3719 55.1703L61.4145 54.8219C55.5918 53.3345 50.2951 57.9103 44.6921 58.5914C43.9182 58.6873 43.7207 57.5247 44.4966 57.4308C50.4261 56.7086 55.6341 52.1348 61.8499 53.722C61.8539 53.722 61.8579 53.722 61.8619 53.7259C61.9063 53.7455 62.255 53.8571 62.6016 53.9999V53.998Z'
          fill='#925333'
        />
      </G>
    </Svg>
  )
}
