import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NativeSyntheticEvent, SafeAreaView, TextInput, TextInputKeyPressEventData } from 'react-native'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { DropdownMenu } from './DropdownMenu'
import { SearchNoResults } from './SearchNoResults'
import { TypeAheadCustomOptionsConfig } from './TypeAheadCustomOptionsConfig'
import { TypeAheadInput } from './TypeAheadInput'
import { ThemedStatusBar } from '../../../atoms/themedStatusBar/ThemedStatusBar'
import { tID } from '../../../utils'
import { Subhead, Size as SubheadSize } from '../../subhead/Subhead'
import { TextButton } from '../../textButton/TextButton'

const Container = styled(SafeAreaView)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  height: '100%',
}))

const InputContainer = styled.View(({ theme }) => ({
  padding: `0 ${theme.spacing['16px']}`,
}))

const HeaderContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 16px 8px 16px',
})

const MenuContainer = styled.View(({ theme }) => ({
  marginVertical: theme.spacing['16px'],
  flex: 1,
}))

const CancelButton = styled(TextButton)({
  paddingTop: '5px',
  top: 0,
  bottom: 0,
})

export const FullScreenTypeAhead = <T,>({
  value,
  inputValue,
  title,
  matches = [],
  onChange,
  onClose,
  isLoading,
  highlightValue,
  keyboardDelay = 0,
  placeholder,
  onKeyPress,
  optionSelected,
  backgroundColor,
  onOptionSelected,
  onPress,
  customOptionsConfig,
  singleSelectModeSelectedItemLabel,
  multiSelect = true,
  onDeleteItem,
  getFilteredOptions,
  getFilteredCustomOptions,
  accessibilityLabel,
  initialScrollIndex,
}: FullScreenTypeAheadProps<T>): JSX.Element => {
  const { colors } = useTheme()
  const inputRef = useRef<TextInput>(null)
  const [searchValue, setSearchValue] = useState(inputValue)
  const noResults = !!searchValue && !isLoading && !matches.length
  const scrollIndex = isEmpty(searchValue) ? initialScrollIndex : undefined

  useEffect(() => {
    setTimeout(() => inputRef?.current?.focus(), keyboardDelay)
  }, [keyboardDelay])

  const handleOnChangeText = (text: string) => {
    setSearchValue(text)
    onChange(text)
  }

  const handleMatchPress = (match: string, customMatch?: T) => {
    onChange(match)
    onOptionSelected(match, customMatch)
    onClose()
  }

  const handleCancelPress = () => {
    onChange('')
    onClose()
  }

  return (
    <Container testID={tID('typeAhead-fullscreen')} backgroundColor={backgroundColor || colors.backgroundSecondary}>
      <ThemedStatusBar defaultStyle='dark' />
      <HeaderContainer>
        <Subhead text={title} wrap size={SubheadSize.XSMALL} />
        <CancelButton
          text={<FormattedMessage defaultMessage='Cancel' description='Cancel button' />}
          onPress={handleCancelPress}
          testID={tID('typeAhead-fullscreen-closeButton')}
        />
      </HeaderContainer>
      <InputContainer>
        <TypeAheadInput
          placeholder={placeholder}
          value={value}
          inputValue={searchValue}
          onChange={handleOnChangeText}
          isLoading={isLoading}
          isFocused
          ref={inputRef}
          onKeyPress={onKeyPress}
          name='typeAhead-fullscreen-input'
          onPress={onPress}
          onDeleteItem={onDeleteItem}
          multiSelect={multiSelect}
          singleSelectModeSelectedItemLabel={singleSelectModeSelectedItemLabel}
          accessibilityLabel={accessibilityLabel}
        />
      </InputContainer>

      {noResults && <SearchNoResults />}

      <MenuContainer testID={tID('typeAhead-fullscreen-menu')}>
        <DropdownMenu
          options={getFilteredOptions()}
          customOptions={getFilteredCustomOptions()}
          onOptionPress={handleMatchPress}
          highlightValue={highlightValue}
          optionSelected={optionSelected}
          customOptionsConfig={customOptionsConfig}
          value={value}
          initialScrollIndex={scrollIndex}
        />
      </MenuContainer>
    </Container>
  )
}

export interface FullScreenTypeAheadProps<T> {
  matches: string[]
  value: string[]
  inputValue: string
  title?: string
  onChange: (text: string) => void
  onClose: () => void
  isLoading?: boolean
  highlightValue?: string
  keyboardDelay?: number
  placeholder?: string
  onKeyPress: (e?: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  optionSelected?: number
  backgroundColor?: string
  onOptionSelected: (option: string, customOption?: T) => void
  onPress: () => void
  customOptionsConfig?: TypeAheadCustomOptionsConfig<T>
  singleSelectModeSelectedItemLabel?: JSX.Element
  multiSelect?: boolean
  onDeleteItem: (itemToRemove: string) => void
  getFilteredOptions: () => string[]
  getFilteredCustomOptions: () => T[]
  accessibilityLabel?: string
  initialScrollIndex?: number
}
