import { useCallback, useMemo, useState } from 'react'

import { SumoLogLevel } from '../../common/utils/commonUtils'
import { useLyraIntl } from '../../intl/hooks/useLyraIntl'
import { isLanguageEnglish } from '../../intl/utils/isLanguageEnglish'
import { WellbeingResponse, WellnessCheckInResultsData } from '../types'
import { getWellnessCheckInParsedBody, showSocialCareSupport } from '../utils'

interface useWellnessCheckInArgs {
  isWellnessCheckInEnabled: boolean
  isWellnessCheckInBenefitsEntryPointEnabled: boolean
  essentialsDisabled: boolean
  isLoggedIn: boolean
  isUserInternational: boolean
  isUserAssumedInternational: boolean
  getWellnessCheckInResultsData: () => Promise<WellnessCheckInResultsData[]>
  postWellnessCheckInResult: (requestBody: WellbeingResponse) => Promise<WellnessCheckInResultsData>
  logToSumo: (data: { message: string; type: SumoLogLevel }) => void
}

export interface WellnessCheckInState {
  latestHasSocialCareSupport: boolean
  latestWellbeingResponse: WellbeingResponse | null | undefined
  error: Error | null
  latestWellnessCheckInResults?: WellnessCheckInResultsData
  wellnessCheckInResults?: WellnessCheckInResultsData[]
}

export const useWellnessCheckIn = ({
  isWellnessCheckInEnabled,
  isWellnessCheckInBenefitsEntryPointEnabled,
  essentialsDisabled,
  isLoggedIn,
  isUserInternational,
  isUserAssumedInternational,
  getWellnessCheckInResultsData,
  postWellnessCheckInResult,
  logToSumo,
}: useWellnessCheckInArgs) => {
  const { activeLanguage } = useLyraIntl()
  const isActiveLanguageEnglish = isLanguageEnglish(activeLanguage)
  const [loading, setLoading] = useState(false)
  const [wellnessCheckInState, setWellnessCheckInState] = useState<WellnessCheckInState>({
    latestWellbeingResponse: null,
    latestHasSocialCareSupport: false,
    error: null,
    latestWellnessCheckInResults: undefined,
    wellnessCheckInResults: undefined,
  })
  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational

  const showWellnessCheckIn = useMemo(() => {
    return isWellnessCheckInEnabled && !essentialsDisabled && isActiveLanguageEnglish && !isInternational
  }, [isWellnessCheckInEnabled, essentialsDisabled, isActiveLanguageEnglish, isInternational])

  /**
   * Handles getting the latest wellness check in results, parsing the response body since it is a string and converting it
   * to an object and setting the social care support boolean
   */
  const parseLatestWellnessCheckInState = useCallback(
    (wellnessCheckInResults: WellnessCheckInResultsData[]) => {
      let latestWellnessCheckInResults: {
        latestWellbeingResponse?: any
        latestHasSocialCareSupport: boolean
        error?: Error
      } = { latestWellbeingResponse: undefined, latestHasSocialCareSupport: false, error: undefined }
      if (wellnessCheckInResults?.length > 0) {
        latestWellnessCheckInResults.latestWellbeingResponse = wellnessCheckInResults[wellnessCheckInResults.length - 1]
      }
      if (latestWellnessCheckInResults) {
        try {
          const latestWellbeingResponseWithForm = getWellnessCheckInParsedBody(
            latestWellnessCheckInResults.latestWellbeingResponse,
          )
          latestWellnessCheckInResults = {
            ...latestWellnessCheckInResults,
            latestWellbeingResponse: latestWellbeingResponseWithForm.wellbeingResponse,
            latestHasSocialCareSupport: showSocialCareSupport(latestWellbeingResponseWithForm.socialCareSupport),
          }
        } catch (error) {
          logToSumo({
            message: error instanceof Error ? error.message : `Unable to parse wellbeing response string: ${error}`,
            type: 'ERROR',
          })
          latestWellnessCheckInResults = {
            latestWellbeingResponse: undefined,
            latestHasSocialCareSupport: false,
            error: error as Error,
          }
        }
      }
      return latestWellnessCheckInResults
    },
    [logToSumo],
  )

  const saveLatestCheckInResults = useCallback(
    async (wellnessCheckInStateResults) => {
      let state: WellnessCheckInState = { ...wellnessCheckInState }

      if (loading) return
      setLoading(true)
      console.debug('Saving wellness check in results from preview page after user has logged in directly')
      try {
        const wellnessCheckInResults = await postWellnessCheckInResult(wellnessCheckInStateResults)
        const latestWellnessCheckInState = parseLatestWellnessCheckInState([wellnessCheckInResults])
        state = {
          ...state,
          ...latestWellnessCheckInState,
        }
      } catch (error) {
        state = {
          ...state,
          latestWellbeingResponse: undefined,
          error: error as Error,
        }

        console.error(`Unable to save wellness check in results: ${error}`)
        logToSumo({
          message: error instanceof Error ? error.message : `Unable to save wellness check in results: ${error}`,
          type: 'ERROR',
        })
      } finally {
        setWellnessCheckInState(state)
        setLoading(false)
      }
    },
    [wellnessCheckInState, loading, postWellnessCheckInResult, parseLatestWellnessCheckInState, logToSumo],
  )

  /**
   * Gets the full latest wellness check in results and setting it in the state
   */
  const getLatestWellnessCheckInResults = useCallback(async () => {
    let state: WellnessCheckInState = { ...wellnessCheckInState }

    if (loading) return

    setLoading(true)
    try {
      const wellnessCheckInResults = await getWellnessCheckInResultsData()
      state.wellnessCheckInResults = wellnessCheckInResults
      let latestWellnessCheckInResults
      if (wellnessCheckInResults?.length > 0) {
        latestWellnessCheckInResults = wellnessCheckInResults[wellnessCheckInResults.length - 1]
      }
      const latestWellnessCheckInState = parseLatestWellnessCheckInState(wellnessCheckInResults)

      state = {
        ...state,
        ...latestWellnessCheckInState,
        latestWellnessCheckInResults,
      }
    } catch (error) {
      state = {
        ...state,
        latestWellbeingResponse: undefined,
        error: error as Error,
      }
      console.error(`Unable to get wellness check in results: ${error}`)
      logToSumo({
        message: error instanceof Error ? error.message : `Unable to get wellness check in results: ${error}`,
        type: 'ERROR',
      })
    } finally {
      setWellnessCheckInState(state)
      setLoading(false)
    }
  }, [wellnessCheckInState, loading, getWellnessCheckInResultsData, parseLatestWellnessCheckInState, logToSumo])

  return {
    loading,
    showWellnessCheckIn,
    showWellnessCheckInBenefitsEntryPoint: showWellnessCheckIn && isWellnessCheckInBenefitsEntryPointEnabled,
    getLatestWellnessCheckInResults,
    saveLatestCheckInResults,
    wellnessCheckInState,
  }
}
