import React, { FunctionComponent } from 'react'

import { FingerprintIconProps } from './FingerprintIconSvg'
import { withSuspense } from '../../utils/withSuspense'

const FingerprintIconSvg = withSuspense({
  component: React.lazy(() => import('./FingerprintIconSvg')),
})

export const FingerprintIcon: FunctionComponent<FingerprintIconProps> = ({ size }) => {
  return <FingerprintIconSvg size={size} />
}
